import React, {
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import {
  EngagementLib
} from '../../../lib/Engagement';
import {
  showAlert
} from '../../Dialogs/AlertDialog';
import Paper from '../../Layout/Wrappers/Paper';
import Loader from '../../Shared/Loader';
import {
  CandidateEngagementsDetails
} from './CandidateEngagementsDetails';
import Table from '../../Layout/Wrappers/Table';

function CandidateExpandedView(props) {
  const { model: candidate = {} } = props;
  // const { jobsPermitted } = candidate;
  const { t } = useTranslation();
  const [engagements, setEngagements] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [reset, setReset] = useState();
  async function fetchEngagements() {
    setLoading(true);
    await EngagementLib.getWhere({ candidateId: candidate.id })
      .then(engagements => {
        candidate.engagements = engagements;
        setEngagements(engagements);
      })
      .catch(message => {
        setError(message);
        showAlert({ message, severity: 'error' });
      });
    setLoading(false);
  }
  if (!error && !loading && !engagements) {
    fetchEngagements();
  }
  return (
    <Paper className="p-1" data-reset={reset}>
      {loading
        ? <Loader />
        : !!engagements?.length
          ? (
            <CandidateEngagementsDetails
              candidate={candidate}
              header={false}
              addEngagementToPermittedJobs={({ candidate, engagement }) =>
                Candidate.addEngagementToPermittedJobs({
                  candidate,
                  engagement,
                }).then(c => {
                  Core.showSuccess(
                    t('candidate updated successfully with added permitted job')
                  );
                  candidate.jobsPermitted = [...c.jobsPermitted];
                  setReset(engagements.length);
                })
              }
            />
          )
          : (
            <Table styled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>No engagements</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
    </Paper>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { CandidateExpandedView, CandidateExpandedView as default };

/* ============================================ */
