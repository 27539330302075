
/**
 * Parses a value to a boolean.
 * To facilite understanding of the logic in certain rules.
 */
export function YES(value) {
  return !!value;
}

/**
 * Parses a value to a negated-boolean.
 * To facilite understanding of the logic in certain rules.
 */
export function NOT(value) {
  return !value;
}

export function defaultTrue(value) {
  return (value === undefined) ? true : value;
}
