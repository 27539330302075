import Checkbox from '../../Layout/Wrappers/Checkbox';

export function IncludeSubmissionCheckbox({
  CandidateEditController,
  fieldId
}) {
  let { state: candidate = {} } = CandidateEditController;
  return (
    <Checkbox small
      checked={candidate.submissionIncludeFlags.includes(fieldId)}
      onChange={event => {
        let checked = event.target.checked;
        candidate.submissionIncludeFlags = candidate.submissionIncludeFlags || [];
        if (checked) {
          candidate.submissionIncludeFlags.push(fieldId);
        }
        else {
          candidate.submissionIncludeFlags = candidate.submissionIncludeFlags.filter(id => id !== fieldId);
        }
        candidate.submissionIncludeFlags = [
          ...new Set([
            ...candidate.submissionIncludeFlags,
          ])
        ].filter(v => !!v);
        CandidateEditController.setStateStore({
          submissionIncludeFlags: candidate.submissionIncludeFlags
        })
      }}
      labelProps={{ className: 'c-black-medium f-sm fw-400' }}
    >
      Include in submission
    </Checkbox>
  )
}
