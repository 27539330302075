import {
  Slider as MuiSlider
} from "@mui/material";
import {
  join
} from '../../../lib/Array.lib';
import useState from '../../../lib/hooks/useState.hook';
import Box from './Box';
import InputNumber from './InputNumber';

export default function Slider({
  name,
  value: propsValue,
  onChange = () => null,
  min,
  max,
  step,
  moneyFormat = false,
  className,
  style,
  size,
  debug = false,
  small = false,
  ...props
}) {
  const _defaultState = {
    value: propsValue
  };
  const [{ value }, _updateState] = useState(
    _defaultState,
    _defaultState
  );
  if (small) { size = 'small'; }
  const _wrapperProps = {
    className: join([
      'd-flex flex-align-left-center p-1 mt-1 w-100',
      className
    ], ' '),
    style,
    ...props
  }
  const _sliderProps = {
    name: `slider__${name}`,
    value: value || 0,
    onChange: async (event, value) => {
      await _updateState({ value });
      onChange(event, value);
    },
    min,
    max,
    step,
    size,
    className: 'flex-1 mr-2'
  }
  const _inputProps = {
    name: `input__${name}`,
    value,
    min,
    max,
    moneyFormat,
    onChange: async (value) => {
      value = parseFloat(value);
      await _updateState({ value });
      onChange(new Event('onSliderChange'), value);
    },
    controls: false,
    className: 'flex-1'
  }
  debug && console.debug(
    'Slider...',
    '\n', _sliderProps, _inputProps
  );
  return (
    <Box row w100 {..._wrapperProps} >
      <MuiSlider {..._sliderProps} />
      <InputNumber {..._inputProps} />
    </Box>
  );
}
