import {
  isRegExp
} from 'lodash';
import {
  escapeRegExp
} from './GenericTools.lib';
import {
  trim
} from './String.lib';

export function getParams({
  pattern,
  path,
  url
} = {}) {
  path = trim(path) || trim(pattern);
  url = trim(url || getLocation()).split('?')[0];
  if (path) {
    const escapedPattern = path.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(escapedPattern.replace(/:\w+/g, '(\\w+)'));
    const match = url.match(regex);
    if (match) {
      const params = {};
      const keys = path.match(/:\w+/g);
      keys.forEach((key, index) => {
        params[key.substring(1)] = match[index + 1];
      });
      return params;
    } else {
      return {};
    }
  }
  else {
    const segments = url.split('/').filter(seg => seg !== '');
    const params = {};
    segments.forEach((seg, index) => {
      params[index.toString()] = seg;
    });
    params.last = segments[segments.length - 1];
    return params;
  }
}

export function getLocation() {
  return trim(document.location.href);
}

export function getLocationNoQuery() {
  return trim(getLocation().split('?')[0]);
}

export function getSearch() {
  return trim(getPathname().split('?')[1]);
} window.getSearch = getSearch;

export function setLocation(url) {
  url = trim(url);
  if (!!url.match(/^(#|\/)/)) {
    url = `#/${url.replace(/#\//, '').replace(/^\//, '')}`;
  }
  return (document.location.href = url);
}

export function reloadLocation() {
  return document.location.reload();
}

export function getHash() {
  return trim(document.location.hash);
} window.getHash = getHash;

export function getPathname() {
  return getHash().replace('#', '');
} window.getPathname = getPathname;

export function getOrigin() {
  return trim(document.location.origin);
}

export function matchLocation(regExp, debug = false) {
  const _regExp = isRegExp(regExp)
    ? regExp
    : new RegExp(escapeRegExp(regExp), 'i');
  const location = getLocation();
  const result = !!location.match(_regExp);
  if (debug) {
    console.debug(
      'UrlLib__matchLocation',
      '\n', { regExp, location, result }
    );
  }
  return result;
}
