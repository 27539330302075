import { CardContent, CardHeader } from '@mui/material';
import moment from "moment";
import React, {
  Component,
  Fragment
} from "react";
import EmployerDetails from "../../components/Employers/Card/EmployerDetails";
import Checkbox from '../../components/Layout/Wrappers/Checkbox';
import Divider from '../../components/Layout/Wrappers/Divider';
import Button from '../../components/Layout/Wrappers/Button';
import IconButton from '../../components/Layout/Wrappers/IconButton';
import Link from '../../components/Layout/Wrappers/Link';
import TextField from '../../components/Layout/Wrappers/TextField';
import {
  MDASH,
  SCHEDULE_DAY__FRIDAY,
  SCHEDULE_DAY__MONDAY,
  SCHEDULE_DAY__THURSDAY,
  SCHEDULE_DAY__TUESDAY,
  SCHEDULE_DAY__WEDNESDAY
} from "../Constants";
import Core from "../Core";
import {
  DATE_FORMAT__DATE__US_LONG
} from '../Date.lib';
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_JOBS,
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__NONE_ID
} from "../Definition";
import Employer from "../Employer";
import {
  getShortURL,
  newModel
} from "../GenericTools.lib";
import {
  mapContactsToStrings
} from '../services/Email/Email.lib';
import {
  isValidEmail,
  Str,
  trim
} from '../String.lib';
import {
  getNewEmployerEmailTemplateStructure
} from './employerEmailTemplate.model';
import {
  mapJobs
} from "./job";
import {
  mapStarred
} from "./mapStarred.tool";
import NavLink from '../../components/Layout/Wrappers/NavLink';

export const MODEL_NAME_EMPLOYER = 'Employer';

const mdash = "—";

/**
 * This model is used to READ/SAVE values from DB
 */
const model = {
  /** REQUIRED */
  name: "",
  /** BASICS, step 1 */
  proxyName: "",
  addressStreet: "",
  addressCity: "",
  addressState: "CA",
  addressZip: "",
  addressCountry: "US",
  //employerStageTagId: "", // FK

  /** @prop {number} stage  Step 1, (Stage:TagId) */
  stage: 0,

  employeeCount: "", // NUMBER, default should not be zero.
  foundingYear: "", // 2018,
  totalFunding: 0, // 0,
  mostRecentFundingDate: "", // "2017-12-15",
  /** ENGINEERING, step 2 */
  teamCount: "", // NUMBER, default should not be zero.
  teamStructure: "",
  teamChallenge: "",
  visaTransfer: 0,
  //employerRemoteTagId: "", // FK
  remote: 0,
  remoteExtraInfo: "",
  relocation: 2,
  relocationExtraInfo: "",
  /** WHY US, step 3 */
  tagline: "",
  product: "",
  whyUs: "",
  perks: "",

  /** @prop {string} url Step 3 > Company URL */
  url: "",

  /** @prop {string} crunchbaseUrl  Step 3 > Crunchbase URL */
  crunchbaseUrl: "",

  additionalUrl: "",

  /** @prop {string} notables  Step 3 > Notable founders / employer / investors */
  notables: "",

  /** CONTACT step 4 */
  primaryContactName: "",
  primaryContactTitle: "",
  primaryContactEmail: "",
  primaryContactPhone: "",

  /** @prop {string} primaryContactLinkedin  Step 4 > Linkedin URL #CompanyLinkedinURL */
  primaryContactLinkedin: "",

  primaryContactNotes: "",
  /** PROCESS step 5 */

  /* µ TO DEPRECATE 2021-04-29 */
  ats: "",

  /* µ TO DEPRECATE 2021-04-29 */
  atsUrl: "",

  atsTypeId: ATS_TYPE__NONE_ID,
  atsApiProviders: {},

  scheduling: "",
  interviewProcess: "",
  additionalInfo: "",
  reference: "",
  internalSubmissionInstructions: "",
  recruitingAgencies: "",
  expectedResumeResponseTime: 48,
  expectedSchedulingResponseTime: 48,
  expectedScreenResponseTime: 48,
  expectedOnsiteResponseTime: 48,
  createdAt: null, // new Date().toISOString(), // MIXIN "2017-12-15T18:48:33.331Z",
  updatedAt: null, // new Date().toISOString(), // MIXIN "2017-12-15T18:48:33.331Z"
  closeDate: null, // "2017-12-15T18:48:33.331Z",
  /** PROCESS step 5, green block */
  additionalRecruitingAgencies: "",
  flagTagIds: [],
  confidential: false,
  employerHiringDifficulty: null,
  state: 2,

  /* related to story-3258 - {number:LocationTagId} */
  location: null,

  placementFee: "", // NUMBER
  guarantee: "", // NUMBER
  additionalContractInfo: "",
  publicNotes: "",
  internalNotes: "",
  /** NEW FIELDS */
  technicalSkills: [],
  flags: [],

  // {object[]} -array of question objects, see collection `PrescreenQuestionTemplate`, also see story_7715
  mustHaveQuestions: [],

  submissionMethod: 0,
  submissionMethods: [],

  /** */

  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [],

  // story-3927-m4 | 2021-09-02 Thu µ
  emailsList: [],
  excludeAutomatedEmails: 0,
  excludeAutomatedEmailsUntil: '',
  reminderEmailDays: [
    SCHEDULE_DAY__MONDAY,
    SCHEDULE_DAY__TUESDAY,
    SCHEDULE_DAY__WEDNESDAY,
    SCHEDULE_DAY__THURSDAY,
    SCHEDULE_DAY__FRIDAY
  ], // ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']

  intakeNote: "",

  /** @prop {string} careersPage Step 4 > Careers page */
  careersPage: "",

  // array of employerEmailTemplate see model file in folder
  emailTemplate: getNewEmployerEmailTemplateStructure(),

  // {object} - story_9481
  // ex. { [PROVIDER_TAG_ID]: { [VARIABLE_NAME]: {{ANY}} } }
  atsContext: {}

};

/**
 * This model is used to READ values from DB
 */
const extended = {
  ___model___: MODEL_NAME_EMPLOYER,
  id: null,
  ...model,
  /** includes */
  jobs: [],
  /** local fills */
  employerNotes: {},
  employerBlackList: [],
  employerSourceList: [],
  employerWhiteList: [],
  ___keys___: []
};

const atsGreenhouseHarvestModel = {
  apiName: "Greenhouse Harvest",
  apiKey: '',
  manualSubmissionUrl: '',
  onBehalfOfEmployerUserName: '',
  onBehalfOfEmployerUserId: null,
  sourceIdAgencyId: null,
  agencyName: '',
  agencyRecruiterName: ''
}

const atsGreenhouseIngestionModel = {
  apiName: "Greenhouse Ingestion",
  apiKey: '',
  manualSubmissionUrl: '',
  onBehalfOfEmployerUser: '',
}

const mapEmployer = item => {

  const employer = getEmployerModel({
    extended: true
  });

  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (employer[key] = item[key])
    );
    employer.id = item.id || item._id;
    employer._updatedAt = employer.updatedAt
      ? moment(employer.updatedAt).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;

    mapStarred({ model: employer, starredList: (item.employerStarreds || []) });

    employer.employerNotes = Object((item.employerNotes || [])[0]);
    employer.jobs = mapJobs(employer.jobs);

    /* mapping stuff */
    employer.name = employer.name || employer.proxyName;
    employer._name = String(employer.name).trim();
    employer._name_rating = employer._name;
    employer._starred = employer.starred ? "Starred: True" : "Starred: False";
    if (Core.isAdminOrCoordinator() && employer.employerHiringDifficulty) {
      employer._name_rating +=
        " (" +
        Definition.getLabel(
          "employerHiringDifficulty",
          employer.employerHiringDifficulty
        ) +
        ")";
    }

    employer._stringContacts = mapContactsToStrings(employer.emailsList);

    if (employer.jobs.length) {

      employer.jobRoles = [];
      employer.jobs.forEach(job => {
        job.roles.forEach(role => {
          if (!employer.jobRoles.includes(role)) {
            employer.jobRoles.push(role)
          }
        })
      });

    }

    employer._employeeCount = employer.employeeCount ? (
      <Fragment>
        ~{employer.employeeCount}
        &nbsp;employees&nbsp;&nbsp;&nbsp;
      </Fragment>
    ) : (
      <i>&mdash;</i>
    );

    employer._submissionMethod = Definition.getLabel('employerSubmissionMethod', employer.submissionMethod);

    employer._active = employer.jobs.filter(job =>
      Definition.test("state", job.state, /active/)
    ).length;
    employer._activeNum = Number(employer._active);
    employer._active = employer._active ? (
      employer._active + " Active"
    ) : (
      <i>&mdash;</i>
    );



    employer._jobsLengthNum = Number(employer.jobs.length);
    employer._jobsLength = employer.jobs.length ? (
      employer.jobs.length + " Jobs"
    ) : (
      <i>&mdash;</i>
    );

    employer._address = [
      employer.addressStreet,
      employer.addressCity,
      `${employer.addressState} ${employer.addressZip}`
    ]
      .filter(e => e && e.trim())
      .join(", ");

    /* set definition labels */
    /* for fill filter menus and autocomplete */
    Definition.set(employer, "employerHiringDifficulty");
    Definition.set(employer, "location");
    Definition.set(employer, "visa", "visaTransfer");
    Definition.set(employer, "stage");
    Definition.set(employer, "state");
    Definition.map(employer, "roles", "jobRoles");
    Definition.map(employer, "technicalSkills");
    Definition.map(employer, "flags");
    Definition.map(employer, 'employerSubmissionMethod', 'submissionMethods');

    employer._infoCmp = <EmployerInfo employer={employer} />;

    employer._urlCmp = (
      employer.url
        ? <Link>{getShortURL(employer.url)}</Link>
        : MDASH
    );
    employer._primaryContactLinkedinCmp = (
      employer.primaryContactLinkedin
        ? (
          <Link url={employer.primaryContactLinkedin}>
            {(
              trim(employer.primaryContactName) ||
              Str(employer.primaryContactLinkedin).replace(/\/$/, '').split('/').pop()
            )}
          </Link>
        )
        : MDASH
    );
    employer._primaryContactEmailCmp = (
      isValidEmail(employer.primaryContactEmail)
        ? (
          <Link url={`mailto:${employer.primaryContactEmail}`}>
            {trim(employer.primaryContactEmail)}
          </Link>
        )
        : MDASH
    );
    employer._crunchbaseUrlCmp = (
      employer.crunchbaseUrl
        ? (
          <Link url={employer.crunchbaseUrl}>
            {Str(employer.crunchbaseUrl).replace(/\/$/, '').split('/').pop()}
          </Link>
        )
        : MDASH
    );

    employer._careersPage = <EmployerCareersPage employer={employer} />;
    employer._rowOptionsCmp = <RowOptions employer={employer} />;
    employer.openDetails = em => {
      Employer.getBlackList(employer.id, employerBlackList => {
        employer.employerBlackList = employerBlackList;
        Employer.getWhiteList(employer.id, employerWhiteList => {
          employer.employerWhiteList = employerWhiteList;
          Core.openDrawer({
            style: {
              width: 900,
              minWidth: '60vw',
              maxWidth: 'calc(100vw - var(--containerMinMargin))',
            },
            content: (
              <>
                <EmployerDetails employer={employer} />
                <IconButton
                  style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
                  onClick={(ev) => Core.closeDrawer()}
                >
                  <i className="material-icons">arrow_forward_ios</i>
                </IconButton>
              </>
            )
          });
        });
      });
    };
    employer.delete = onSuccess => {
      const engagements = employer.engagements || [];
      Core.dialog.open({
        title: (
          <>{`Delete "${employer._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
            }`}</>
        ),
        message: "This action can't be undone.",
        style: { width: "320px" },
        actions: [
          <Button flat
            label="Cancel"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <Button flat
            label="Delete"
            className="button-flat-cyan"
            onClick={ev => {
              Core.dialog.close();
              Employer.delete(
                employer.id,
                onSuccess ? onSuccess : function () { }
              );
            }}
          />
        ]
      });
    };
    employer.checkStar = (checked, onSuccess) => {
      Employer.updateStarred(
        employer.id,
        employer.starredId,
        checked,
        response => {
          employer.starredId = response.id;
          employer.starred = response.starred;
          employer._starred = response.starred
            ? "Starred: True"
            : "Starred: False";
          employer.filters = {
            ...employer.filters,
            Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
          };
          onSuccess && onSuccess(response);
        }
      );
    };

    /* for autocomplete */
    employer.___keys___ = [
      employer._name,
      employer.proxyName,
      employer._jobRoles,
      employer._location,
      employer._visaTransfer,
      employer._technicalSkills,
      employer._flags,
      employer._stage,
      employer._state,
      employer._starred
    ]
      /* combine and split values */
      .join("; ")
      .split("; ")
      /* remove empty values */
      .filter(e => !!e && String(e).trim());
  }
  patchATS({ employer });
  return employer;
};

const mapEmployers = data => {
  return data.map(item => {
    const employer = mapEmployer(item);
    return {
      ...employer,
      filters: {
        Name: employer.name,
        Recent: moment(employer.updatedAt),
        Starred: ["Non Starred", "Starred"][~~Boolean(employer.starred)]
      }
    };
  });
};

class EmployerInfo extends Component {
  render() {
    const { employer } = this.props;
    return Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) ? (
      <NavLink to={`/employer/edit/${employer.id}`}>
        <b>{employer._name || MDASH}</b>
      </NavLink>
    ) : (
      <b
        style={{
          color: colors.black.common,
          fontWeight: 500
        }}
      >
        {employer.name || MDASH}
      </b>
    );
  }
}

export class EmployerCareersPage extends Component {
  render() {
    const { employer } = this.props;
    return (
      !!String(employer?.careersPage).trim()
        ? (
          <a href={employer.careersPage} target="_blank" rel="noreferrer" >
            {getShortURL(employer.careersPage)}
          </a>
        )
        : MDASH
    );
  }
}

export class Details extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      publicNotes: this.props.employer.publicNotes
    };
  }
  onChangeEmployerNotes = (ev, publicNotes) => {
    const { employer } = this.props;
    this.setState({ publicNotes });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Employer.update(employer.id, { publicNotes });
    }, 1000);
  };
  render() {
    const { employer } = this.props;
    return (
      <>
        {!!employer.jobs.length && (
          <>
            <CardHeader title="HIRING" />
            <CardContent className="contents">
              {Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })
                ? employer.jobs.map(job => (
                  <span
                    className="anchor"
                    key={job.id}
                    onClick={ev => Core.go({ ...this.props, to: `/job/view/${job.id}` })}
                  >
                    <li style={{ marginBottom: 16 }}>
                      {Definition.getLabel("roles", job.role)}
                      &nbsp;&mdash;&nbsp;
                      {Definition.getLabel("state", job.state)}
                    </li>
                  </span>
                ))
                : employer.jobs.map(job => (
                  <li>{Definition.getLabel("roles", job.role)}</li>
                ))}
            </CardContent>
          </>
        )}
        {!!employer._technicalSkills.length && (
          <>
            <Divider />
            <CardHeader title="TECHNOLOGY STACK" />
            <CardContent className="contents">
              {employer._technicalSkills}
            </CardContent>
            <Divider />
          </>
        )}
        <CardHeader title="NOTES" />
        <CardContent className="contents">
          <TextField
            name="employerNotes"
            rows={3}
            rowsMax={6}
            multiLine={true}
            value={this.state.publicNotes}
            onChange={this.onChangeEmployerNotes}
          />
        </CardContent>
        {Core.isAdminOrCoordinator() &&
          (!!employer.employerBlackList.length ||
            !!employer.employerWhiteList.length) && <Divider />}
        {Core.isAdminOrCoordinator() && !!employer.employerBlackList.length && (
          <div className="ui-g-6">
            <CardHeader
              avatar={<i className="material-icons">block</i>}
              title="BLACK LIST"
            />
            <CardContent>
              {employer.employerBlackList.map(item => (
                <li key={item.id}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </CardContent>
          </div>
        )}
        {Core.isAdminOrCoordinator() && !!employer.employerWhiteList.length && (
          <div className="ui-g-6">
            <CardHeader
              avatar={<i className="material-icons">done</i>}
              title="WHITE LIST"
            />
            <CardContent>
              {employer.employerWhiteList.map(item => (
                <li key={item.id}>
                  {item.firstName} {item.lastName}
                </li>
              ))}
            </CardContent>
          </div>
        )}
      </>
    );
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { employer } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          className="starred"
          checked={employer.starred}
          onCheck={(ev, checked) => {
            employer.checkStar(checked, res =>
              this.setState({ updated: true })
            );
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
        />
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: "pointer",
            fontWeight: 200,
            ...employer.openedColor,
            ...employer.rightArrow
          }}
          onClick={employer.openDetails}
        >
          chevron_right
        </i>
      </div>
    );
  }
}

/**
 * 
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @returns {object} A new model
 */
function getEmployerModel({
  extended: isExtendedRequired
} = {}) {
  return newModel(
    isExtendedRequired ? extended : model
  );
}

/**
 * 
 * @param {object} params
 * @param {number} atsTypeId Ats Type Tag id
 * @returns {object} new ATS model
 */
function getAtsProviderModel({ atsTypeId }) {

  const atsApiProvidersModel = {};

  atsApiProvidersModel[
    ATS_TYPE__GH_HARVEST_ID
  ] = atsGreenhouseHarvestModel;

  atsApiProvidersModel[
    ATS_TYPE__GH_INGESTION_ID
  ] = atsGreenhouseIngestionModel;

  return newModel(
    atsApiProvidersModel[atsTypeId] || {}
  );

}

/**
 * Patch for transition old ATS fields to new ones
 * 2021-04-29
 * 
 * This functions will modify employer object.
 * 
 * @param {object} params
 * @param {object} params.employers
 * 
 * @todo 
 * Review patch code after 2021-06-01
 * to determine utility of maintain it.
 * 
 * @see CandidateResumeSubmission component
 * 
 */
function patchATS({ employer }) {

  /* UPDATE atsTypeId */
  const {
    ats = '',
    atsTypeId,
  } = employer;
  const parsedATS = Number(ats);
  if (!!parsedATS && (!atsTypeId || atsTypeId === ATS_TYPE__NONE_ID)) {
    employer.atsTypeId = parsedATS;
  }

  /* UPDATE manualSubmissionUrl */
  const {
    atsUrl = '',
    atsApiProviders = {},
  } = employer;
  const atsProvider = (
    atsApiProviders[employer.atsTypeId]
    ||
    getAtsProviderModel(employer.atsTypeId)
  );
  if (
    !!atsUrl && !atsProvider.manualSubmissionUrl
  ) {
    atsProvider.manualSubmissionUrl = atsUrl;
    employer.atsApiProviders[
      employer.atsTypeId
    ] = atsProvider;
  }

}

export const EMPLOYER__PRIMARY_CONTACT_NOTES = "<p>Intake date:&nbsp;</p><p>10x10 intaker:&nbsp;</p><p>Employer attended:&nbsp;</p><p>10x10 Recruiters attended:&nbsp;</p><p>Employer SLA commitment: how firm</p><p>OK to text/call situations:&nbsp;</p><p>Employer contact: roles (founder, HM, responsible recruiter, coordinator, head recruiting, 2nd line manager, ..)</p>";

export const EMPLOYER__INTAKE_NOTE = "<p>Date:&nbsp;</p><p>10x10 intaker:&nbsp;</p><p>Recruiters:&nbsp;</p><h2><strong style=\"color: rgb(0, 150, 136);\" class=\"ql-size-small\">BACKGROUND</strong></h2><p>Company URL:&nbsp;</p><p>Company total headcount:&nbsp;</p><p>Engineering Team size:&nbsp;</p><p>Work Visa:&nbsp;</p><p>Location/remotely from/timezone:&nbsp;</p><p>Founding year/Stage/Total Funding/Most recent funding round:&nbsp;</p><p>Links/press releases:&nbsp;</p><p>Investors/notables:&nbsp;</p><p>Hiring Priorities:&nbsp;</p><p>Elevator Pitch:&nbsp;</p><ul><li><br></li></ul><p>Selling Points:&nbsp;</p><ul><li><br></li></ul><p>Note:&nbsp;</p><h2><strong style=\"color: rgb(0, 150, 136);\" class=\"ql-size-small\">JOB 1 - REQUIREMENT &amp; COMP</strong></h2><p>Company Tech Stack</p><ul><li><br></li></ul><p>Must Nice Have / Not</p><ul><li><br></li></ul><p>Comp: / remote / timezone</p><ul><li><br></li></ul><p>How many openings</p><ul><li><br></li></ul><p>Common rejection reasons:&nbsp;</p><ul><li><br></li></ul><p>Who approve the resumes &amp; SLA:&nbsp;</p><ul><li><br></li></ul><p>Interviewer,&nbsp;topics, and process (duration,video/phone):&nbsp;</p><ul><li><br></li></ul><p>Target companies/schools:&nbsp;</p><p>Note</p><ul><li><br></li></ul><h2><strong style=\"color: rgb(0, 150, 136);\" class=\"ql-size-small\">LOGISTICS (3m rule)</strong></h2><p>SLA escalation: how firm?&nbsp;We will text/call after 24hr.</p><p>Scheduling &amp; rejection process</p><ul><li><br></li></ul><p>Submission process?&nbsp;Greenhouse APIintegration</p><ul><li><br></li></ul><p>Contacts - CV review, interview, decision maker,and backup </p><ul><li><br></li></ul><p><span style=\"color: rgb(22, 17, 28);\">Note</p><ul><li><br></li></ul>";

export {
  atsGreenhouseHarvestModel,
  atsGreenhouseIngestionModel, getEmployerModel as default, getAtsProviderModel, getEmployerModel, mapEmployer,
  mapEmployers
};

