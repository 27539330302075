
export const CANDIDATE_UI = {
  CandidateNotes: {
    title: '10x10 auto summary',
    subtitle: "This note is based on your inputs and will be included in the candidate's application to the employer. Please go back to previous steps to make adjustments.",
    empty: (
      <>
        No items to summarize.<br />Please enter some information to get started.
      </>
    )
  },
  diversityCategories: {
    title: 'Underrepresented Group',
    subtitle: 'Optional and voluntary',
    tooltip: 'We support diversity and inclusion to create equitable futures and prohibit discrimination and harassment, and clients may use the optional information provided to increase representation and support diverse populations, but it is not used to make employment decisions.',
    placeholder: 'Can select multiple options'
  },
  emailsList: {
    title: 'Add additional recipients to email notification',
    tooltip: `If you want additional recruiters to also receive candidate updates via email, click on the pencil icon to add more recipients.`
  },
  explainRedFlags: {
    summaryTitle: `Context`,
    title: 'Context for red flags (e.g. short stints, employment gaps, contracting background)',
    tooltip: "Employers often ask about job changes and motivation to understand work history and career goals, assess fit, and gauge reliability and commitment. It's important to be honest, focus on the positives, avoid negative language, and align experiences and goals with the role and company culture when answering this question."
  },
  firstName: {
    candidateEditDiffParsedNameErrorMessage: 'Candidates name ({{CANDIDATE_NAME}}) does not match the value found by the parser in the candidates resume ({{PARSED_NAME}}). Please correct to avoid delays in candidate processing. If candidate prefers to go by a different first name, use the preferred name field to record how the candidate wants to be addressed if different from the resume.',
    resumeSubmissionDiffParsedNameErrorMessage: `Please ensure candidate's name matches the ATS submission. Candidate's name is stored in the clipboard that you can paste for ATS submission.`
  },
  jobsPermitted: {
    title: 'Jobs that candidate has APPROVED for submission'
  },
  maxCompanySize: {
    title: 'Maximum company size preference'
  },
  minCompanySize: {
    title: 'Minimum company size preference'
  },
  minimumSalary: {
    title: 'Minimum base annual cash compensation',
    subtitle: 'Skip if unknown. Exclude commission and bonus',
  },
  nickName: {
    title: 'Preferred name',
    subtitle: <>&nbsp;</>,
  },
  noteToAccountManager: {
    title: "Recruiter's note for 10x10 account team only",
    placeholder: 'Not included in the initial submission. Share only if requested by employer',
  },
  relevantYearsOfExp: {
    title: 'Relevant years of work experience',
    subtitle: `Don't count internship, part time, jobs in other roles or countries`
  },
  reasonsToLeaveLastJob: {
    summaryTitle: `Reasons for looking`,
    title: 'Reason for seeking new roles and top motivators',
    tooltip: 'Reason for leaving previous job and desired job qualities. Be specific, positive, and honest about their motivations and goals. Avoid criticism of their previous employer. Instead, focus on what they are looking for in a new opportunity and how it aligns with their career goals.'
  },
  resumeScrubbedUrl: {
    moreMenuLabel: 'View Scrubbed Resume'
  },
  salaryNote: {
    title: 'Additional compensation notes',
    subtitle: 'Currency, ideal base, bonus, commission, stock, total comp'
  },
  submissionNotes: {
    title: "Recruiter's general submission write-up (for all employers)",
    tooltip: (
      <>
        <b className='c-yellow'>Do NOT REPEAT the same information in 10x10 auto summary or job specific notes.</b><br />
        Provide job specific notes when you permit the job to avoid accidentally used for another job.<br />
        Please include compelling reasons to increase a candidate's chances of being invited to interview. E.g. prior notable companies, complex projects, awards, fast promotions, motivation, career goals, work style, strengths and weaknesses, etc.
      </>
    ),
    placeholder: `
Do NOT REPEAT the same information in 10x10 auto summary or job specific note. (e.g. comp, visa, linkedIn, why looking...)

Provide job specific note when you permit the job to avoid accidentally used for another job.

Please include compelling reasons to increase a candidate's chances of being invited to interview. E.g. prior notable companies, complex projects, awards, fast promotions, motivation, career goals, work style, strengths and weaknesses, etc.`.trim()
  },
  submissionNotesToEmployer: {
    title: 'Candidate submission write-up (edited by 10x10 account manager)'
  },
  tagLine: {
    title: 'Tagline (short elevator pitch)'
  },
  totalYearsOfExp: {
    title: 'Total years of work experience',

    // [2023-12-13][story_9765] YEARS is a dynamically composed value at render time.
    subtitle: 'Resume parser estimate: {{YEARS}} years'

  }
}
