import moment from 'moment';
import React from 'react';
import { getHTMLLinkString } from '../../lib/HTML.lib';
import cleanHtml from '../../lib/tools/cleanHtml';
import formatMoney from '../../lib/tools/formatMoney';

export function CrunchbaseOrganizationDetails({ organization = {}, essential = false }) {
  let CBOUnknownTitle = 'Crunchbase organization unknown';
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: (
          !!organization.id
            ? cleanHtml(`
                ${organization.name}&nbsp;
                ${organization.cb_url ? `&nbsp;|&nbsp;${getHTMLLinkString({ url: organization.homepage_url })}` : ''}
                ${essential ? '<br/>' : `
                  ${organization.status ? `&nbsp;|&nbsp;${(organization.status)}` : ''}
                  ${organization.founded_on ? `&nbsp;|&nbsp;founded: ${moment(organization.founded_on).format('MMM DD, YYYY')}` : ''}
                  ${organization.total_funding_usd ? `&nbsp;|&nbsp;total founding: $${formatMoney(organization.total_funding_usd)}` : ''}
                  ${organization.last_funding_on ? `&nbsp;|&nbsp;${moment(organization.last_funding_on).format('MMM DD, YYYY')}` : ''}<br/>
                  ${organization.employee_count_min ? `${(organization.employee_count_min)}` : ''}
                  ${organization.employee_count ? `&nbsp;-&nbsp;${(organization.employee_count)}` : ''}
                  ${(
                  (organization.employee_count_min || organization.employee_count) &&
                  (organization.city || organization.state || organization.country))
                  ? '&nbsp;|&nbsp;'
                  : ''}
                  ${[organization.city, organization.state, organization.country].filter(v => !!v).join(', ')}
                  ${(
                  organization.employee_count_min || organization.employee_count ||
                  organization.city || organization.state || organization.country)
                  ? '<br/>'
                  : ''}
                `}
                ${organization.short_description}
`)
            : CBOUnknownTitle
        )
      }}
    />
  );
}
