import {Component} from "react";

class And extends Component {

    render() {
        return '';
    }
}

export default And;
