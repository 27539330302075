import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  join
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import Employer from '../../../lib/Employer';
import useState from '../../../lib/hooks/useState.hook';
import {
  compileText
} from '../../../lib/String.lib';
import copyText from '../../../lib/tools/copyText.tool';
import {
  mapWrapper
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import IconStatus from '../../Layout/Wrappers/IconStatus';
import Menu from '../../Layout/Wrappers/Menu';

export default function TriggerWaitingEmployerSummary({
  acl = Core.isAdmin(),
  employerId = '',
  children,
  type = 'icon',
  wrapperProps = {},
  ...props
}) {
  const [state, updateState] = useState();
  if (NOT(employerId && acl)) { return null; }
  const statusIcon = IconStatus({
    ...state,
    loadingTitle: EmployerPendingsUI.TriggerWaitingEmployerSummary.IconButton.loadingTooltip
  });
  const _onClick = async (event) => {
    await updateState({
      loading: true,
      error: false
    });
    try {
      const result = [];
      const data = await Employer.getPendings({ employerId });
      data.processedJobs.forEach((processedJob) => {
        processedJob.processedEngagements.forEach((processedEngagement) => {
          processedEngagement.engagements.forEach((engagement) => {
            result.push(
              compileText(
                '{{CANDIDATE__FULLNAME}}{{RATING_FLAG}}, {{JOB_TITLE}} - {{ENGAGEMENT_LABEL}}{{SUBMITTED_LABEL}}',
                {
                  CANDIDATE__FULLNAME: engagement.candidateName,
                  RATING_FLAG: engagement.ratingFlag,
                  JOB_TITLE: engagement.jobTitle,
                  ENGAGEMENT_LABEL: join(
                    [
                      /**
                       * @note
                       * LABEL is built based on the `msg` template from...
                       * overriding `getEmployerProcessingModels`...
                       * for the `getEngagementPendings` endpoint.
                       * @see https://github.com/10By10/10by10-web-app/blob/19cc8f88ef8923c9b180ab88b2e0a225a33d48f0/common/Helpers/emails/getEmployerProcessingModels.function.js#L29
                       * @see https://github.com/10By10/10by10-web-app/blob/4fdcf659211aada54a06af4b38ca30a7b1d00fe3/common/Helpers/emails/employerTodoReminder.helper.js#L138
                       */
                      engagement.label,
                      (
                        engagement.agoDateB
                      ) ? (
                        // DAYS IF agoDate LESS THAN overdueDays
                        `${engagement.agoDateB}d ago`
                      ) : (
                        // DAYS (calculated from date)
                        !!engagement.agoDate && ` ${engagement.agoDate}d ago`
                      ),
                      // INTERVIEW DATE? format: D/M
                      engagement.date && `(${engagement.date})`
                    ],
                    ' '
                  ),
                  SUBMITTED_LABEL: (
                    (
                      // SUBMITTED DATE
                      engagement.submittedLabel
                    ) ? (
                      ` ${engagement.submittedLabel}`
                    ) : (
                      ''
                    )
                  ),
                }
              )
            );
          });
        });
      });
      const joinedResult = result.join('\n');
      await copyText(joinedResult);
      console.debug('Copied', joinedResult);
      Core.showSuccess('Copied!');
    }
    catch (error) {
      await updateState({ error });
    }
    await updateState({ loading: false });
  }
  mapWrapper({
    role: 'TriggerWaitingEmployerSummary',
    props: wrapperProps,
    assign: {
      ...props,
      onClick: _onClick,
      disabled: (state.loading),
      children: (
        (
          children
        ) ? (
          statusIcon || children
        ) : (
          type === 'icon'
        ) ? (
          (
            statusIcon
          ) ? (
            <IconButton icon={statusIcon} />
          ) : (
            <IconButton
              title={EmployerPendingsUI.TriggerWaitingEmployerSummary.IconButton.tooltip}
              icon='list'
            />
          )
        ) : (
          <Menu.Item divider>
            {EmployerPendingsUI.TriggerWaitingEmployerSummary.MenuItem.tooltip}
            {statusIcon}
          </Menu.Item>
        )
      )
    }
  });
  console.debug(
    'TriggerWaitingEmployerSummary',
    {
      state,
      wrapperProps,
      statusIcon
    }
  );
  return (
    <Box {...wrapperProps} />
  )
}
