import Core from '../../../lib/Core';
import { ACCOUNT_ACTION__EDIT_ACCOUNT } from '../../../lib/Definition';

export const moreMenuOptionsAccounts = [
  {
    acl: account => (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) || (Core.isRecruiter() && Core.isOnDev())),
    to: account => (`/account/edit/${account.id}`),
    label: 'Edit Account'
  }
];
