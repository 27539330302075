import moment from 'moment';
import {
  TEN_BY_TEN_ADMIN_CONTACT,
} from '../../lib/Constants';
import Core from '../../lib/Core';
import {
  TIMEZONE__LA
} from '../../lib/Date.lib';
import {
  mapCandidateInfoForCSV
} from '../../lib/models/mapCandidateInfo.tool';
import {
  mapContactsToStrings,
  sendSafeEmail
} from '../../lib/services/Email/Email.lib';
import {
  encodeBase64
} from '../../lib/String.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Menu from '../Layout/Wrappers/Menu';
import Loader from '../Shared/Loader';
import DownloadCSV, {
  convertToCSV
} from '../Tools/DownloadCSV';
import {
  Candidates__state,
  Candidates__updateState
} from './Candidates';

export function CandidatesExportCSV({
  acl = false,
  ...props
}) {

  if (!acl) { return null; }

  const state = Candidates__state();

  const _mapSourceFields = (candidate) => {
    candidate = { ...candidate };
    candidate.__exportDate = moment().format('MM-DD-YYYY');
    candidate.__exportOwner = Core.getUserName();
    return candidate;
  }

  const selectedCandidates = state.allCandidates.filter(
    (candidate) => !!candidate.__selected
  ).map(_mapSourceFields);

  const starredCandidates = state.allCandidates.filter(
    (candidate) => !!candidate.starred
  ).map(_mapSourceFields);

  return (
    <Box acl={Core.isTester()}>
      {(!!selectedCandidates.length || !!starredCandidates.length) && (
        <Menu
          name='export-to-csv'
          options={[
            {
              acl: !!selectedCandidates.length,
              id: `selected_${Date.now()}`,
              label: (
                <DownloadCSV
                  filename={`candidates__${moment().format('YYYY_MM_DD__HH_mm_ss')}.csv`}
                  data={selectedCandidates.map(mapCandidateInfoForCSV)}
                  props={{
                    onClick(event) {
                      exportCandidatesInfoAndSendEmail({
                        candidates: selectedCandidates,
                        subjectTemplate: `$user export $count selected candidates info CSV on $now`
                      });
                    }
                  }}
                  className='d-flex flex-align-left-center'
                >
                  <i className='material-icons c-inherit mr-1'>file_download</i>
                  Download selected candidates
                </DownloadCSV>
              ),
            },
            {
              acl: !!starredCandidates.length,
              id: `starred_${Date.now()}`,
              label: (
                <DownloadCSV
                  filename={`candidates__${moment().format('YYYY_MM_DD__HH_mm_ss')}.csv`}
                  data={starredCandidates.map(mapCandidateInfoForCSV)}
                  props={{
                    onClick(event) {
                      exportCandidatesInfoAndSendEmail({
                        candidates: starredCandidates,
                        subjectTemplate: `$user export $count starred candidates info CSV on $now`
                      });
                    }
                  }}
                  className='d-flex flex-align-left-center'
                >
                  <i className='material-icons c-inherit mr-1'>file_download</i>
                  Download starred candidates
                </DownloadCSV>
              ),
            }
          ]}
          renderSelected={
            ({ selected }) => (
              <Button
                startIcon={<i className='material-icons c-inherit'>file_download</i>}
                disabled={!!state.sendingEmail}
                className='mr-1 min-w-120'
                small
              >
                CSV<Loader loading={!!state.sendingEmail}>&nbsp;</Loader>
              </Button>
            )
          }
          disabled={!!state.sendingEmail}
        />
      )}
    </Box>
  );
}

export function exportCandidatesInfoAndSendEmail({
  candidates = [],
  subjectTemplate
}) {
  subjectTemplate = String(subjectTemplate || '');
  Candidates__updateState({ sendingEmail: true }, async () => {
    await sendSafeEmail({
      to: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]).join(','),
      cc: mapContactsToStrings([{ name: 'April Chang', email: 'april@10by10.io' }]).join(','),
      subject: `[ Export ] ${(
        subjectTemplate
          .replace('$user', Core.getUserName())
          .replace('$count', candidates.length)
          .replace('$now', moment().tz(TIMEZONE__LA).format('YYYY-MM-DD HH:mm (z)'))
      )}`,
      html: ``,
      attachments: [
        {
          filename: `candidates__${moment().format('YYYY_MM_DD__HH_mm_ss')}.csv`,
          binary: encodeBase64(convertToCSV(candidates.map(mapCandidateInfoForCSV))),
          mimeType: 'text/csv; charset=utf-8'
        }
      ]
    });
    Candidates__updateState({ sendingEmail: false });
  })
}
