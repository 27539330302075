import {
  compile
} from 'handlebars';
import {
  useRef,
  useState
} from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  HTML__IN_BLANK_LINE
} from '../../../lib/HTML.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import Box from './Box';
import Button from './Button';
import Divider from './Divider';
import {
  Html
} from './Html';
import IconButton from './IconButton';
import InputNumber from './InputNumber';
import Menu from './Menu';
import Message, {
  ErrorMessage,
  MESSAGE_TYPE__POPUP,
  WarningChipMessage,
  WarningMessage
} from './Message';
import MultipleSelect from './MultipleSelect';
import StyledTableCell from './StyledTabCell';
import Table from './Table';
import TextField from './TextField';

const MEM = {};
const CONFIG = {
  name: 'MyCustomTable',
  columnHeaders: {
    field1: 'Field One'
  },
  columnWidth: {
    field1: 200
  },
  cellHeight: 48,
  tableColors: {
    field1: '#d5c9a6',
  },
  requiredFieldLabel: 'This field is required',
  requiredFields: {
    field1: (value) => !!trim(value),
    field2: false,
  },
  uniqueFieldLabel: 'This field is duplicated',
  uniqueFields: {
    field1: true
  },
  cellRender: {
    field1: value => value,
  },
  fieldSet: {
    add: 'Add rule',
    noData: 'No rules yet. Click on the "Add" button to add a rule.'
  },
  saveConfirm: {
    title: 'Confirm Changes',
    message: `
      {{{BREAK_LINE}}}
      <div>
        {{#if TO_CREATE}}{{TO_CREATE}} records will be added.<br/>{{/if}}
        {{#if TO_UPDATE}}{{TO_UPDATE}} records will be updated.<br/>{{/if}}
        {{#if TO_DELETE}}{{TO_DELETE}} records will be deleted.<br/>{{/if}}
      </div>
      <div>
        Are you sure you want to continue?
      </div>
      {{{BREAK_LINE}}}
    `,
    onAcceptLabel: 'Confirm'
  },
};

/**
 * Material Table
 * @param {object} props_
 * @param {object} props_.config
 * @param {function} props_.getModel
 * @param {function} props_.fetchData
 * @param {function} props_.createRecord
 * @param {function} props_.updateRecord
 * @param {function} props_.deleteRecord
 * @param {JSX.Element} props_.topHeaders
 */
export default function MsTable({
  config = CONFIG,
  getModel = () => ({}),
  fetchData = async () => ([]),
  createRecord = async ({ record }) => ({}),
  updateRecord = async ({ id, record }) => ({}),
  deleteRecord = async ({ id }) => ({}),
  topHeaders,
  reference = self => ({}),
  ...props
}) {
  let [state, setState] = useState({});
  const self = useRef({});
  let { data = [], toUpdate = {}, toDelete = {}, toCreate = {}, errors = {}, busy } = state;
  let _toCreateCount = Object.keys(toCreate).length;
  let _toUpdateCount = Object.keys(toUpdate).length;
  let _toDeleteCount = Object.keys(toDelete).length;
  const _getNextOrder = () => (Math.max(...Arr(data).map((r, i) => r.order || i + 1).filter(v => !!v), 0) || 0) + 1;
  async function _updateState(update) {
    update = Object(update) === update ? update : {};
    if (update.data) {
      update.data = update.data
        .map((record, index) => {
          if ((typeof record.id === 'string') && !record.order) {
            let order = index + 1;
            record.order = order;
            toUpdate[record.id] = record;
            update.toUpdate = toUpdate;
          }
          return record;
        })
        .sort((a, b) => a.order - b.order);
      self.current.data = update.data;
    }
    setState(state => ({ ...state, ...update }));
    return await new Promise((resolve) => setTimeout(() => resolve(state)));
  }
  const _onAddRow = () => {
    let id = Date.now();
    let record = {
      id,
      ...getModel(),
      order: _getNextOrder()
    };
    toCreate[id] = record;
    _updateState({
      data: [...data, record],
      toCreate
    });
  };
  const _onChange = async (update) => {
    data = data.map((row) => {
      if (row.id === update.id) {
        Object.assign(row, update);
        if (typeof update.id === 'string') {
          toUpdate[update.id] = row;
        }
        else {
          toCreate[update.id] = row;
        }
      }
      return row;
    });
    console.debug('R1', update, data);
    await _updateState({
      data,
      toCreate,
      toUpdate,
    });
  };
  const _onDelete = async (id) => {
    if (typeof id === 'string') {
      toDelete[id] = true;
    }
    delete toCreate[id];
    delete toUpdate[id];
    await _updateState({
      data: data.filter((row) => row.id !== id),
      toCreate,
      toUpdate,
      toDelete,
    });
  };
  const _onSave = () => {
    showConfirm({
      title: config.saveConfirm.title,
      message: (
        <Html>
          {compile(config.saveConfirm.message)({
            BREAK_LINE: HTML__IN_BLANK_LINE,
            TO_CREATE: !!_toCreateCount ? _toCreateCount : '',
            TO_UPDATE: !!_toUpdateCount ? _toUpdateCount : '',
            TO_DELETE: !!_toDeleteCount ? _toDeleteCount : '',
          })}
        </Html>
      ),
      async onAccept() {
        await _updateState({ errors: [], busy: true });
        for (let id in toCreate) {
          let record = { ...toCreate[id] };
          delete record.id;
          await createRecord({ record })
            .then(async (response) => {
              console.debug('createRecord', response);
              delete toCreate[id];
              delete errors[id];
              await _updateState({ toCreate, errors });
            })
            .catch(async (error) => {
              errors[id] = error;
              await _updateState({ errors });
            });
        }
        for (let id in toUpdate) {
          let record = { ...toUpdate[id] };
          delete record.id;
          await updateRecord({ id, record })
            .then(async (response) => {
              console.debug('updateRecord', response);
              delete toUpdate[id];
              delete errors[id];
              await _updateState({ toUpdate, errors });
            })
            .catch(async (error) => {
              errors[id] = error;
              await _updateState({ errors });
            });
        }
        for (let id in toDelete) {
          await deleteRecord({ id })
            .then(async (response) => {
              console.debug('deleteRecord', response);
              delete toDelete[id];
              delete errors[id];
              await _updateState({ toDelete, errors });
            })
            .catch(async (error) => {
              errors[id] = error;
              await _updateState({ errors });
              Core.showError(error);
            });
        }
        await _updateState({ busy: false });
        if (!Object.keys(errors).length) {
          Core.showSuccess('All saved');
          await _fetchData();
        }
      },
      onAcceptLabel: config.saveConfirm.onAcceptLabel,
    });
  };
  const _fetchData = async () => {
    await _updateState({ fetchingData: true });
    await fetchData().then(async (data) => {
      let update = { fetchedData: true };
      update.data = (Array.isArray(data) ? data : [])
        .map((record, index) => {
          if (!record.order) {
            let order = index + 1;
            record.order = order;
            toUpdate[record.id] = record;
            update.toUpdate = toUpdate;
          }
          return record;
        })
        .sort((a, b) => a.order - b.order)
      await _updateState(update);
    })
      .catch(async (error) => {
        await _updateState({ fetchingError: error });
      });
    await _updateState({ fetchingData: false });
  };
  (async () => {
    if (!state.fetchingData && !state.fetchingError && !state.fetchedData) {
      _fetchData();
    }
  })();

  self.current.data = data;
  self.current.appendData = async ({ data: append, overwrite = record => record }) => {
    console.debug('appendData', self.current);
    let data = self.current.data;
    for (let record of Arr(append)) {
      await (
        new Promise((resolve) => {
          let id = Date.now();
          let mapped = { ...record };
          mapped.id = id;
          mapped.order = _getNextOrder();
          delete mapped.createdAt;
          delete mapped.updatedAt;
          toCreate[id] = overwrite(mapped) || mapped;
          data.push(mapped);
          console.debug('mapped', mapped);
          setTimeout(() => resolve(), 1);
        })
      );
    }
    await _updateState({ data, toCreate, fetchedData: true });
  };
  self.current.getData = async () => {
    console.debug('getData', self.current.data);
    return self.current.data;
  };
  reference(self.current);
  return (
    <Box column h100 w100 {...props}>
      <Box column flex1 scroll>
        <Table
          className='styled'
          style={{
            minWidth: '100%',
          }}
          size='small'
        >
          <Table.Head className='sticky-top' style={{ zIndex: 2 }}>
            {topHeaders}
            <Table.Row className='border-bottom'>
              {Object.entries(config.columnHeaders).map(([key, label], index) => {
                return (
                  <StyledTableCell
                    key={`${config.name}_table_head_cell_${key}`}
                    className={(index === 0) ? 'sticky-start' : ''}
                    style={{
                      minWidth: config.columnWidth[key],
                      maxWidth: config.columnMaxWidth,
                    }}
                  >
                    {label}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell
                className='sticky-end align-right'
                style={{
                  minWidth: config.columnWidth.actions,
                  maxWidth: config.columnMaxWidth,
                }}
              ></StyledTableCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((record, index) => {
              let { id } = record;
              let key = `${config.name}_table_body_tr_${id}`;
              const Row = (props) => {
                return (
                  <>
                    <Table.Row
                      style={{
                        position: 'relative',
                        maxHeight: 42,
                      }}
                      className='border-bottom'
                    >
                      {Object.entries(config.columnHeaders).map(([key, label], index) => {
                        let value = record[key];
                        return (
                          <StyledTableCell
                            key={`${config.name}_table_body_cell_${key}_${id}`}
                            className={index === 0 ? 'sticky-start' : ''}
                            style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}
                          >
                            {config.cellRender[key]({
                              name: `${config.name}_table_body_cell_${key}_${id}`,
                              value,
                              onChange(value) {
                                _onChange({ id, [key]: value });
                              },
                            })}
                            <WarningChipMessage
                              show={
                                config.requiredFields[key] instanceof Function && !config.requiredFields[key](value)
                              }
                              className='mt-1'
                            >
                              {config.requiredFieldLabel}
                            </WarningChipMessage>
                            <WarningChipMessage
                              show={
                                !!config.uniqueFields[key] && !!data.find(record => (record.id !== id) && (String(record[key]) === String(value)))
                              }
                              className='mt-1'
                            >
                              {config.uniqueFieldLabel}
                            </WarningChipMessage>
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell
                        className='sticky-end align-right pr-0'
                        style={{ borderBottom: errors[id] ? 'none' : '1px solid #c6c7c9' }}
                      >
                        <IconButton small
                          onClick={async (event) => await _onDelete(id)}
                          className='mr-1'
                          icon='delete'
                        />
                      </StyledTableCell>
                    </Table.Row>
                    {!!errors[id] && (
                      <Table.Row>
                        <StyledTableCell colSpan={Object.entries(config.columnHeaders).length + 1} className='p-0'>
                          <ErrorMessage className='m-05' style={{ maxWidth: '100%' }}>{errors[id]}</ErrorMessage>
                        </StyledTableCell>
                      </Table.Row>
                    )}
                  </>
                );
              };
              return <Row key={key} />;
            })}
          </Table.Body>
        </Table>
        <Message show={!!state.fetchingData}>Fetching data</Message>
        <WarningMessage show={!state.fetchingData && !state.fetchingError && !data.length}>
          {config.fieldSet.noData}
        </WarningMessage>
        <ErrorMessage show={!!state.fetchingError}>{state.fetchingError}</ErrorMessage>
        <Message show={!!state.busy} type={MESSAGE_TYPE__POPUP}>
          Be patient, please do not close the window or refresh the page!<br />
          Wait until process done.<br /><br />
          Remaining operations: {Number(_toCreateCount + _toUpdateCount + _toDeleteCount) || 0}
        </Message>
        <div
          onMouseOver={async (event) => {
            if (MEM.dragged) {
              let draggedRow = MEM.dragged;
              let draggedOrder = Number(draggedRow.order || 0);
              let targetOrder = data.length;
              data.splice(draggedOrder - 1, 1);
              data.splice(targetOrder - 1, 0, draggedRow);
              delete MEM.dragged;
              await _updateState({ data });
            }
          }}
          className='flex-1'
          style={{ minHeight: 48 }}
        >
          &nbsp;
        </div>
      </Box>
      <div className='bg-white' style={{ height: 48 }}>
        <Divider className='m-0' />
        <div className='d-flex p-1 space-between'>
          <Button outlined secondary
            onClick={_onAddRow}
            className='min-w-120'
          >
            {config.fieldSet.add}
          </Button>
          <Button primary
            onClick={_onSave}
            className='min-w-120'
            disabled={!!busy || !(_toCreateCount || _toUpdateCount || _toDeleteCount)}
          >
            Save
            {Core.isLocalHost() && (
              <>
                {!!_toCreateCount ? ` C:${_toCreateCount}` : ''}
                {!!_toUpdateCount ? ` U:${_toUpdateCount}` : ''}
                {!!_toDeleteCount ? ` D:${_toDeleteCount}` : ''}
              </>
            )}
          </Button>
        </div>
      </div>
    </Box >
  );
}

export function renderInput(type) {
  return (
    (
      {
        value: propsValue,
        onChange = Fun,
        ...props
      }
    ) => {
      const _default = { value: propsValue };
      const [{ value }, updateState] = useState(_default, _default);
      const _props = {
        value,
        async onChange(event) {
          await updateState({
            value: Obj(Obj(event).target).value || Str(event)
          })
        },
        onBlur(event) {
          onChange(event.target.value);
        },
        onKeyDown(event) {
          if (Str(event.key).match(/tab|enter/i)) {
            onChange(event.target.value);
          }
        }
      }
      return (
        (type === 'number')
          ? <InputNumber {..._props} />
          : <TextField disabledBlurExport {..._props} outlined />
      );
    }
  );
}

export function renderDropdown(options) {
  return (props) => (
    <Menu dropdown
      {...props}
      options={options}
    />
  );
}

export function renderMultiselect(data = []) {
  return ({ name, value, onChange }) => (
    <MultipleSelect name={name} data={Arr(data)} value={value} onChange={onChange} />
  );
}
