import Core from "../../Core";
import Http from "../../Http";

export function facets({
  path = 'Entity/_facets',
  query = { "fields": ["state"], "limit": 3, "query": "active" },
  mapper = results => results,
}) {
  let promise = Http.get(
    Core.getApi(path),
    { query: JSON.stringify(query) },
  ).then(mapper);
  promise.catch(exception => Core.failure({ source: 'facets.api.js', exception, params: { path, query } }));
  return promise;
}
