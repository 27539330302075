import {
  MDASH
} from '../../../lib/Constants';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';

export default function Dash({
  debug = false,
  acl = true,
  mx = false,
  className,
  wrapperProps = {},
  ...props
}) {

  if (!acl) { return null; }

  const _className = [
    'w-auto',
    className
  ];
  if (mx) { _className.push('mx-05'); }
  mapWrapper({
    role: 'Dash',
    props: wrapperProps,
    assign: {
      className: joinClassName(_className)
    },
    flags: { debug, mx }
  })
  return (<Box {...wrapperProps}>{MDASH}</Box>);
}
