import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import {
  joinClassName
} from '../Libraries/Theme.lib';

export default function Table({
  className = '',
  styled = false,
  ...props
}) {
  const _props = {
    ...props,
    className: joinClassName([
      styled && 'styled',
      className
    ])
  }
  return (
    <MuiTable {..._props} />
  );
}

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.Footer = TableFooter;
Table.Pagination = TablePagination;
