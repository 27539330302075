import {
  Component
} from "react";
import Box from '../../../../../Layout/Wrappers/Box';
import Button from '../../../../../Layout/Wrappers/Button';
import Dialog from '../../../../../Layout/Wrappers/Dialog';
import ChipForm from "./Chip";
import ComplexForm from "./ComplexForm";
import TextForm from "./Text";

const popupElementForms = [
  { componentName: ChipForm, btnLabel: 'Basic Chip Picker' },
  { componentName: TextForm, btnLabel: 'Custom Text Only' },
  { componentName: ComplexForm, btnLabel: 'Complex Structure', default: 'selected' },
];

const changeCurrentPopupBehaviorClass = "popupDialog";

export default class PopupAdElement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      mode: 'form',
      elementToBeSaved: null,
      SelectedFormComponent: ComplexForm
    }
  }

  finalStruct = () => {
    let { elementToBeSaved } = this.state;
    return elementToBeSaved;
  }

  handlerChoice = (formObj) => () => {
    this.setState({ mode: 'form', SelectedFormComponent: formObj.componentName })
  }

  handlerFormChange = (elementToBeSaved) => {
    this.setState({ elementToBeSaved });
  }

  render() {
    let { SelectedFormComponent, mode } = this.state;
    let { onCancel, onApply, priorityType, elementToEdit, extraInfo } = this.props;

    return (
      <Dialog open
        role='PopupAdElement'
        title={!priorityType &&
          <Box row w100>
            {popupElementForms.map((formObj, index) => {
              return (
                <Box row wAuto key={`popup_ad_element__forms__${index}_${formObj.btnLabel}`}>
                  <Box wAuto className='mx-1' acl={!!index}>|</Box>
                  <Button flat
                    style={formObj.componentName === SelectedFormComponent ? {
                      border: '1px solid blue',
                      padding: '5px',
                      lineHeight: '20px',
                      color: 'blue'
                    } : {}}
                    key={index}
                    onClick={this.handlerChoice(formObj)}
                  >
                    {formObj.btnLabel}
                  </Button>
                </Box>
              )
            })}
          </Box>
        }
        content={
          <Box column w100>

            {!!priorityType && ["chip", "chipGroup", "chipTag"].includes(priorityType) &&
              <ChipForm
                onUpdate={this.handlerFormChange}
                editable={true}
                updateAble={elementToEdit}
                dialogClass={changeCurrentPopupBehaviorClass}
                jobTags={extraInfo.jobTags}
              />
            }

            {!!priorityType && priorityType === "text" &&
              <TextForm
                onUpdate={this.handlerFormChange}
                editable={true}
                updateAble={elementToEdit}
              />
            }

            {!!priorityType && ["or", "and", "not"].includes(priorityType) &&
              <ComplexForm
                onUpdate={this.handlerFormChange}
                editable={true}
                updateAble={elementToEdit}
                dialogClass={changeCurrentPopupBehaviorClass}
                jobTags={extraInfo.jobTags}
              />
            }

            {!priorityType && mode === 'form' && !!SelectedFormComponent &&
              <SelectedFormComponent
                onUpdate={this.handlerFormChange}
                editable={true}
                dialogClass={changeCurrentPopupBehaviorClass}
                jobTags={extraInfo.jobTags}
              />
            }

          </Box>
        }
        actions={[
          <Button outlined minW120
            label="Cancel"
            onClick={onCancel}
          />,
          <Button primary minW120
            label="Apply"
            onClick={() => onApply(this.finalStruct())}
          />
        ]}
        className={changeCurrentPopupBehaviorClass}
        paperStyle={{ width: sizes.l7 }}
        alignLeft
      />
    );
  }
}
