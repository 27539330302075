import React, {
  Fragment,
  useState
} from "react";
import Core from "../../../lib/Core";
import mapCandidateSignals from '../../Candidates/CandidateSignalTags.lib';
import SignalChips, {
  SIGNAL_CHIPS__VARIANT_COMPACT
} from '../../Candidates/Forms/SignalChips';
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import LinkedinFormattedDate from '../../Shared/LinkedinFormattedDate';
import { Arr } from '../../../lib/Array.lib';
import Typography from '../../Layout/Wrappers/Typography';
import Box from '../../Layout/Wrappers/Box';
import { joinClassName } from '../../Layout/Libraries/Theme.lib';

export function WorkExperience(props) {

  const { candidate } = props;

  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  async function _fetchSignals() {
    candidate.__fetchingSignals = true;
    candidate.__errorFetchingSignals = false;
    await _updateState();
    Object.entries(await mapCandidateSignals({ candidate })).forEach(([key, value]) => candidate[key] = value);
    candidate.__fetchingSignals = false;
    candidate.__errorFetchingSignals = !candidate.__dicSignals;
    await _updateState({});
  }
  if (!candidate.__dicSignals && !candidate.__fetchingSignals && !candidate.__errorFetchingSignals) {
    _fetchSignals();
  }

  function _getEmploymentHistory(candidate) {
    let list = [];
    if (!!candidate.employmentHistories && candidate.employmentHistories.length !== 0) {
      candidate.employmentHistories.forEach((history) => {
        history.positionHistories.forEach((position) => {
          list.push(
            <PositionRow {...({
              candidate,
              history,
              position
            })} />
          )
        })
      })
    }
    else {
      Arr(candidate.jobsLinkedIn).forEach((obj, index) => {
        list.push(
          <div className={joinClassName(['mb-1', index !== 0 && 'mt-2'])}>
            <span className='c-black'>{obj.employer ? `${obj.employer}, ` : ''}</span>
            <span className='c-black-medium f-sm'>{obj.employerFrom ? `${obj.employerFrom} - ` : ''}</span>
            <span className='c-black-medium f-sm'>{obj.employerTo ? `${obj.employerTo}, ` : ''}</span>
            <span className='c-black'>{obj.title ? `${obj.title}` : ''}</span>
          </div>
        )
      })
    }
    return list;
  }
  return (
    <Card className="mb-05">
      <Typography strong large alignCenter blackDark
        className='m-1'
      >
        WORK EXPERIENCE
      </Typography>
      <Divider />
      <Box className='p-1'>
        {((cando) => {
          let history = _getEmploymentHistory(cando);
          return history.map((item, index) => {
            return (
              <Fragment key={index}>
                {item}
              </Fragment>
            )
          })
        })(candidate)}
      </Box>
    </Card>
  );
}

export default WorkExperience;

export function PositionRow(props) {
  let { candidate, history, position } = props;
  let organization = (candidate.__dicOrganizations || {})[(candidate.__dicSignals || {})[history.companyId]?.crunchbaseOrganizationId] || {};
  return (
    <div className='mb-1 mt-2'>
      {history.employerOrgName ? (
        <>
          <span
            className={organization.cb_url ? 'anchor' : 'c-black'}
            data-url={organization.cb_url}
            onClick={organization.cb_url ? event => Core.openPopUp(organization.cb_url, 1200) : undefined}
          >
            {history.employerOrgName}
          </span>
          {', '}
        </>
      ) : ''}
      {(history.positionHistories?.length === 1
        ? (
          <LinkedinFormattedDate
            startDateYear={history.positionHistories[0].startDateYear}
            startDateMonth={history.positionHistories[0].startDateMonth}
            endDateYear={history.positionHistories[0].endDateYear}
            endDateMonth={history.positionHistories[0].endDateMonth}
            currentlyEmployed={history.__hasPositionCurrentlyEmployed}
            durationYears={history.__durationYears}
            durationMonthsModule={history.__durationMonthsModule}
          />
        )
        : !!history.positionHistories?.length && (
          <LinkedinFormattedDate
            startDate={history.__startDate}
            endDate={history.__endDate}
            currentlyEmployed={history.__hasPositionCurrentlyEmployed}
            durationYears={history.__durationYears}
            durationMonthsModule={history.__durationMonthsModule}
          />
        )
      )}
      <span className=''>
        {String(position.title || '').trim() ? `, ${position.title}` : ''}
      </span>
      <div>
        {history.companyId && (
          <SignalChips
            employment={history}
            signalsHash={candidate.__dicSignals}
            variant={SIGNAL_CHIPS__VARIANT_COMPACT}
          />
        )}
      </div>
    </div>
  );
}
