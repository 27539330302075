import {
  Arr,
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../../lib/Boolean.lib';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Engagement from '../../../lib/Engagement';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  getEntities,
  isEngagement
} from '../../../lib/models/Models.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import {
  ListPipeController
} from '../List/ListPipe';
import {
  MatchPageController
} from '../MatchPage';
import onSelectMatch from './Tools/onSelectMatch.tool';

export const KEY__FIND_NEXT_TO_MATCH = 'findAndPreserveNextToMatch';
export const KEY__SCROLL_NEXT_MATCH = 'scrollNextToMatchAfterCurrentMatched';
export const KEY__MATCHES_LIST__FLAT_ARRAY = 'list_pipe__matches';
export const KEY__NEXT_TO_MATCH = 'ListPipe::nextToMatch';
export const KEY__CON__MATCH_PAGE = 'MatchPage';
export const KEY__CON__LIST_PIPE = 'controller_list_pipe';
export const KEY__CON__SINGLE_CARD = '___single_card___';
export const KEY__REF__SINGLE_CARD__MATCH_DECISION = '___matchDecisionDetails___';

export function getMatchEntities(source, debug = false) {
  const {
    profile,
    selectedMatch
  } = MatchPageController().state
  const entities = getEntities(source);
  entities.profile = profile;
  entities.selectedMatch = selectedMatch;
  if (matchLocation('/job/match')) {
    entities.match = entities.engagement?.candidate || entities.candidate;
    entities.candidate = entities.candidate || entities.match;
    entities.job = entities.job || entities.profile;
  }
  else if (matchLocation('/candidate/match')) {
    entities.match = entities.engagement?.job || entities.job;
    entities.candidate = entities.candidate || entities.profile;
    entities.job = entities.job || entities.match;
  }
  entities.employer = entities.job?.employer;
  entities.account = entities.candidate?.account;
  const result = {
    profile: entities.profile,
    match: entities.match,
    engagement: entities.engagement,
    selectedMatch: entities.selectedMatch,
    account: entities.account,
    candidate: entities.candidate,
    employer: entities.employer,
    job: entities.job,
    recruiter: entities.account,
  }
  debug && console.debug('getMatchEntities', result);
  return result;
}

export function autoSelectMatch() {
  const {
    params = {},
  } = MatchPageController().props;
  const {
    selectedMatch = {}
  } = getMatchEntities();
  if (YES(Core.getKeyValue(KEY__NEXT_TO_MATCH))) {
    Fun(Core.getKeyValue(KEY__SCROLL_NEXT_MATCH));
  }
  else {
    const toMatchGroup = ListPipeController().state.groups.find(
      (match) => (
        (match.type !== "engagement") &&
        (match.key === "toMatch")
      )
    );
    const firstMatch = toMatchGroup.items.slice(0, 1).pop();
    const autoSelected = Obj(
      (
        params.selected && ListPipeController().state.allMatches.find(
          (match) => (match.id === params.selected)
        )
      )
      ||
      firstMatch
      ||
      selectedMatch
    );
    if (!!autoSelected.id) {
      onSelectMatch(autoSelected);
    }
  }
}

export function handlerSkippedMatch() {
  MatchPageController().setState(
    (state) => {
      const {
        selectedMatch = {},
        skippedMatches = []
      } = state;
      if (selectedMatch.id) {
        state.skippedMatches = [
          ...new Set([
            ...skippedMatches,
            selectedMatch
          ])
        ];
      }
      return state;
    },
    () => autoSelectMatch()
  );
};

export function pushingToProfileOnThumbsDown(entities) {
  const {
    profile = {},
    match = {}
  } = getMatchEntities(entities);
  const matchId = match.id;
  profile.putDownJobs = Arr(profile.putDownJobs);
  profile.putDownJobs.push(matchId);
  profile.putDownJobs = Array.from(new Set(profile.putDownJobs));
  MatchPageController().setState(
    { profile },
    () => MatchPageController().props.strategy.putDownCallback(
      profile.id,
      profile.putDownJobs
    )
  );
}

export function jobContainConstraints(a, b, constraint) {
  const { job = {}, candidate = {} } = getMatchEntities({ a, b });
  // for permitted/declined/pitched
  if (
    Array.isArray(candidate[constraint]) &&
    candidate[constraint].includes(job.id)
  ) {
    return true;
  }
  return false;
}

export function isJobBlackListed(a, b) {
  const { job = {}, candidate = {} } = getMatchEntities({ a, b });
  const { account = {} } = candidate;
  const { employer = {} } = job;
  if (
    Arr(job.jobBlackList).map((account) => account.id).includes(account.id) ||
    Arr(employer.employerBlackList).map((account) => account.id).includes(account.id)
  ) {
    return true;
  }
  return false;
}

export function updateCandidate(candidate, update) {
  Candidate.update(
    candidate.id,
    update,
    () => Core.showSuccess("Saved Successfully!"),
    Core.showError
  );
};

export async function updateEngagement({ engagement, update }) {
  try {
    if (Core.isAdminOrCoordinator()) {
      if (Obj(engagement).id) {
        await Engagement.update(
          engagement,
          update
        );
      }
    }
  }
  catch (error) {
    Core.showError(error);
  }
};

export function profileHasEngagement(
  match = {}
) {
  const matchKey = MatchPageController().props.strategy.matchKey;
  const engagements = MatchPageController().state.engagements;
  const result = YES(
    engagements.find(
      (engagement) =>
        engagement[`${matchKey}Id`]
        ===
        match.id
    )
  );
  console.debug(
    'MatchListLib__profileHasEngagement...',
    '\n', { result, match, engagements }
  );
  return result;
};

export function isMatchEngageable(
  match = {}
) {
  const { profile = {} } = MatchPageController().state;
  const result = (
    YES(profile.id) &&
    YES(match.id) &&
    NOT(isEngagement(match)) &&
    NOT(profileHasEngagement(match)) &&
    NOT(isJobBlackListed(profile, match)) &&
    NOT(jobContainConstraints(profile, match, 'jobsDeclined')) &&
    NOT(ListPipeController().state.annotatorMode)
  );
  return result;
};

export function getMatches() {
  const {
    selectedMatches = [],
    selectedMatch = {}
  } = MatchPageController().state;
  const entitiesToMatch = (
    YES(selectedMatches.length)
      ? selectedMatches
      : YES(selectedMatch.id)
        ? [selectedMatch]
        : []
  );
  return sanitizeArr(entitiesToMatch);
};

export function onMatch(
  model = {},
  callback = () => null
) {
  console.debug(
    'MatchPage__onMatch...',
    '\n', { model }
  );
  const entitiesToMatch = getMatches();
  Fun(Core.getKeyValue(KEY__FIND_NEXT_TO_MATCH))([entitiesToMatch]);
  entitiesToMatch.forEach((match, index) => {
    model.statusNote = model[KEY__CON__SINGLE_CARD]?.state?.candoPrivateNoteText;
    MatchPageController().createSingleEngagementWithDis(match, model, callback);
  });
}

export function hasEngageableMatch() {
  const result = YES(
    getMatches().find(
      (match) => isMatchEngageable(match)
    )
  );
  console.debug(
    'MatchListLib__hasEngageableMatch...',
    '\n', { result }
  );
  return result;
};

