import { v4 as uuidv4 } from 'uuid';
import Core from "./Core";
import Debug from "./Debug.lib";
import { JWT__EXPIRED_URL } from './services/Accounts/Session.lib';
import { Obj } from './Object.lib';
import { Str } from './String.lib';
import { getLocation } from './URL.lib';

/**
 * Http Library
 */
const Http = {
  get,
  put,
  post,
  patch,
  delete: DELETE,
  errorMessage: {
    ocr: 'This resume is a scanned image and cannot be parsed. Please provide the original resume to support parsing for both 10 By 10 and Employers’ ATS (Lever and Greenhouse)'
  }
};
/**
 * Exports
 */
export { Http as default, request, get, put, post, patch, DELETE as delete };
/**
 * Http request
 * @param {String} method
 * @param {String} url
 * @param {Object} params
 * @return {Promise}
 */
function request(method, url, params) {
  params = params || {};
  let api = url;
  if (!!~url.indexOf("/api/")) {
    api = url.split("/api/")[1].split("/");
    api = `HTTP ${method} ${api[0]}${api[1] ? "/" + api[1] : ""}`;
  }
  return new Promise(function (resolve, reject) {
    var req = new XMLHttpRequest();
    const headers = [];
    if (!!params.headers) {
      Object.keys(params.headers).map(key =>
        headers.push([String(key), String(params.headers[key])])
      );
      delete params.headers;
    }
    if (method === "GET") {
      req.open(
        method,
        url +
        ((!!~url.indexOf("?") ? "&" : "?") +
          Object.keys(params)
            .map(key => {
              return key + "=" + encodeURIComponent(params[key]);
            })
            .join("&"))
      );
      setHandlers();
      const token = Core.getAccessToken();
      if (!!headers.length) {
        headers.map(item => req.setRequestHeader(item[0], item[1]));
        Core.log(headers);
      } else if (token) {
        req.setRequestHeader("Authorization", token);
      }
      Debug.time(api);
      req.send();
    } else {
      req.open(method, url);
      setHandlers();
      const token = params.token || Core.getAccessToken();
      if (!!token) {
        if (!!headers.length) {
          headers.map(item => req.setRequestHeader(item[0], item[1]));
        } else if (token) {
          req.setRequestHeader("Authorization", token);
        }
      }
      req.setRequestHeader("Content-type", "application/json; charset=UTF-8");
      try {
        Debug.time(api);
        req.send(JSON.stringify(params));
      } catch (ex) {
        console.error({ errorParams: params });
        console.error(ex);
        return reject(ex.message);
      }
    }
    function setHandlers() {
      // This is called even on 404 etc
      req.onload = function () {
        Debug.showTimeEnd(api);
        // so check the status

        switch (req.status) {
          case 200:
            try {
              const response = JSON.parse(req.responseText);
              if (response.error) {
                let error = '';
                if (typeof response.error === "string") {
                  error = response.error;
                }
                if (!error) { error = Object(response.error).message; }
                if (!error) {
                  console.debug('HTTP(200)>req.responseText', JSON.parse(req.responseText));
                  try {
                    error = JSON.parse(req.responseText);
                    error = `${error.data.error.message}. ${error.message}`;
                  }
                  catch {
                    error = req.responseText;
                  }
                }
                Core.failure({
                  source: 'HTTP.js(lib):onload(200):error',
                  exception: req.responseText,
                  params: { method, url, params, status: req.status },
                  omitUIMessage: true,
                  omitSentToSentry: true,
                });
                reject(error);
              } else {
                resolve(response);
              }
            } catch (ex) {
              reject(req.responseText);
            }
            break;
          case 204:
            resolve(req.response);
            break;
          case 401:
            // usually this fail is coming when token expires, so is not needed to log on sentry but redirect to login page and clean local expiry token from local storage
            let error = `Invalid Access`;
            try { error = String(JSON.parse(req.responseText).error?.message || error); } catch { }
            if (!!error.match(/invalid access token/i)) {
              error = `Your session has expired`;
            }
            if (Core.isEmployer()) {
              Core.logout({ redirect: String(JWT__EXPIRED_URL).replace('/#', '') });
            }
            else {
              const _logout = () => Core.logout({
                redirect: '/'
              });
              Core.showError(error, _logout);
              setTimeout(_logout, 3000);
            }
            break;
          case 400: case 403: case 404: case 406: case 422:
            if (getLocation().match(/jwt/i)) {
              reject(true);
            }
            else {
              let message = 'Unexpected error';
              try { message = Str(Obj(JSON.parse(req.responseText).error).message) || message; } catch { }
              message = (
                !!message.match(/OCR is required/i) ? Http.errorMessage.ocr : `${req.status} error - ${message}`
              );
              reject(message);
            }
            break;
          default:
            if (!/matching-score/i.test(req.responseURL)) {
              if (!(/change-password/i.test(req.responseURL) && req.status === 400)) {
                Core.failure({
                  source: 'HTTP.js(lib):onload(default)',
                  exception: req.responseText,
                  params: { method, url, params, status: req.status },
                  omitUIMessage: true,
                  omitSentToSentry: true,
                });
              }
            }
            let errorMessage;
            try {
              errorMessage = !!Object(JSON.parse(req.responseText).error).message;
            } catch (ex) {
              let contentType = req.getResponseHeader('Content-Type');
              errorMessage = `Bad format response(${contentType})`;
              console.debug(`Bad format response(${contentType})\n`, String(req.responseText));
            }
            let ticket = {
              source: `Http.js(lib):onload(default)`,
              uuid: uuidv4(),
              timestamp: new Date().toISOString(),
              user: Core.getUser().email,
              path: getLocation(),
              url,
              method,
              params: `<ul>${(
                Object.entries(params).map(([key, value]) => {
                  try { value = JSON.stringify(value); }
                  catch { value = String(value); }
                  return (
                    `<li>${key}: ${value.length >= 150 ? `${value.slice(0, 150)}...` : value}</li>`
                  );
                }).join('')
              )}</ul>`,
              code: req.status,
              status: req.statusText,
              response: req.responseText
            };
            require('./services/Email/Email.lib').sendSafeEmail({
              from: Core.getNewCandidateReceiverNotiFromEmail(),
              to: Core.getNewCandidateReceiverNotiToEmail(),
              subject: `FE: Unexpected Error | ${req.status} | ${req.statusText}`,
              html: (`
                ${Object.entries(ticket).map(([key, value]) => `<p>${key}: ${value}</p>`).join('')}
              `)
            });
            reject(errorMessage);
            break;
        }
      };
      // Handle network errors
      req.onerror = function (error) {
        Debug.showTimeEnd(api);
        console.warn("http:error", error);
        switch (req.status) {
          case 502:
            Core.failure({
              source: 'HTTP.js(lib):onerror(502)',
              exception: error,
              params: { method, url, params, status: req.status },
              omitUIMessage: true,
            });
            reject("502 Bad Gateway");
            break;
          default:
            Core.failure({
              source: 'HTTP.js(lib):onerror(default)',
              exception: error,
              params: { method, url, params, status: req.status },
              omitUIMessage: true,
            });
            reject("Network Failure");
            break;
        }
      };
    }
  });
}
/**
 * Http GET
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function get(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  else {
    params = params || {}
  }
  const promise = request("GET", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
      return response;
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http POST
 * @param {String} url
 * @param {Object} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function post(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("POST", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
      return response;
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http PATCH
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function patch(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("PATCH", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
      return response;
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http PUT
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
async function put(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("PUT", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
      return response;
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
/**
 * Http DELETE
 * @param {String} url
 * @param {String} params
 * @param {Function} onSuccess
 * @param {Function} onFail
 */
function DELETE(url, params, onSuccess, onFail) {
  if (params instanceof Function) {
    onFail = onSuccess;
    onSuccess = params;
    params = {};
  }
  const promise = request("DELETE", url, params);
  promise.then(
    response => {
      onSuccess instanceof Function && onSuccess(response);
      return response;
    },
    error => {
      onFail instanceof Function && onFail(error);
    }
  );
  return promise;
}
