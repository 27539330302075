import moment from "moment";
import {
  US_DATE_FORMAT
} from "../../../lib/Constants";
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_ACCOUNT
} from "../../../lib/Definition";
import {
  validateEmail
} from "../../../lib/GenericTools.lib";
import { EmailsListClass } from "../../Forms/EmailsList";
import Box from '../../Layout/Wrappers/Box';
import Col from "../../Layout/Wrappers/Col";
import NavLink from '../../Layout/Wrappers/NavLink';
import Row from "../../Layout/Wrappers/Row";
import Typography, { Label } from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';

/**
 * 
 * IF YES onSave is set in props
 * - it will be invoked on close the dialog.
 * - the dialog button to close will say "Save".
 * 
 * IF NO onSave is set in props
 * - the dialog button to close will say "Close".
 * 
 * @param {object} props 
 * @param {object} props.recruiter
 * @param {object} props.candidate
 * @param {function} props.onChange
 * @param {function} props.onSave
 * @param {string} props.className
 * @param {boolean} props.dot
 * @returns 
 */
export function EditCandidateRecruiters({
  recruiter = {},
  candidate = {},
  onChange = undefined,
  onSave = undefined,
  className = '',
  dot = ''
}) {
  let _content = (
    <Box column>
      <Box row>
        {
          candidate.emailsList.filter(
            ({ name, email }) => validateEmail(email)
          ).map(
            (c, index, arr) =>
              <Typography blackDark
                key={`${c.name}-${c.email}`}
                className='flex-no-wrap'
              >
                {!!index && ', '}
                {c.name || c.email}
              </Typography>
          )
        }
      </Box>
      {
        recruiter.id && (
          <Box column>
            <Typography blackDark>
              {
                Core.isAdmin({
                  action: ACCOUNT_ACTION__EDIT_ACCOUNT
                }) ? (
                  <NavLink to={`/account/edit/${recruiter.id}`}>
                    {recruiter._name}
                  </NavLink>
                ) : recruiter._name
              }
              &nbsp;(account owner - joined {
                moment(
                  recruiter.createdAt
                ).format(
                  US_DATE_FORMAT
                )
              })
            </Typography>
            {!!recruiter.emailsList.length && (
              <Box row>
                {
                  recruiter.emailsList.filter(
                    ({ name, email }) => validateEmail(email)
                  ).map(
                    (c, index, arr) =>
                      <Typography blackDark
                        key={`${c.name}-${c.email}`}
                      >
                        {!!index && ', '}
                        {c.name || c.email}
                      </Typography>
                  )
                }
              </Box>
            )}
          </Box>
        )
      }
    </Box>
  );
  let title = (
    <>
      <Label mr>
        {CANDIDATE_UI.emailsList.title}
        {dot &&
          <>
            &nbsp;<b style={{ color: 'orange' }}>*</b>
          </>
        }
      </Label>
    </>
  );
  return (
    onSave
      ? (
        <EmailsListClass
          name={'candidates_forms_recruiters__candidate_contacts'}
          title={title}
          info={
            CANDIDATE_UI.emailsList.tooltip
          }
          model={candidate}
          onChange={onChange}
          onSave={onSave}
          messageTypeTags={Definition.get('recruiterMessageType')}
          className={className}
        >
          {_content}
        </EmailsListClass>
      )
      : (
        <Row className={className || `m-0`}>
          <Col fullWidth className="p-0">
            <label className="mr-1">{title}</label>
            {_content}
          </Col>
        </Row>
      )
  );
};
