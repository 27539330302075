import React, {
  Component
} from 'react';
import Announcement from '../../lib/Announcement';
import Core from '../../lib/Core';
import {
  ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS
} from '../../lib/Definition';
import Store from '../../lib/Store';
import onReady from '../../lib/tools/onReady';
import NavLink from '../Layout/Wrappers/NavLink';

export default class AnnouncementBell extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      open: false,
      title: 'New Announcements',
      iconButtonName: 'notifications_active',
    };
  }
  checkForNewAnnouncements = () => {
    var lastMostRecentAnnouncementDate;
    if (!!Store.get('lastMostRecentAnnouncementDate')) {
      lastMostRecentAnnouncementDate = new Date(
        Store.get('lastMostRecentAnnouncementDate')
      );
    } else {
      // Random old date so any announcements would be most recent.
      lastMostRecentAnnouncementDate = new Date('01/01/1070');
    }
    Announcement.getMostRecent({}, (data) => {
      let mostRecentAnnouncementDate;
      if (!!data) {
        mostRecentAnnouncementDate = new Date(data[0].createdAt);
      }
      else {
        mostRecentAnnouncementDate = new Date(Date.now());
      }
      if (
        !lastMostRecentAnnouncementDate ||
        mostRecentAnnouncementDate.getTime() >
        lastMostRecentAnnouncementDate.getTime()
      ) {
        Store.set('lastMostRecentAnnouncementDate', mostRecentAnnouncementDate);
        onReady(this, 'IconButton').then((em) => {
          this.setState({
            title: 'New Announcements',
            iconButtonName: 'notifications_active',
          });
        });
      }
    });
  };
  render() {
    return (
      (
        !Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS })
      ) && (
        <NavLink
          title='Go to see announcements'
          icon={this.state.iconButtonName}
          to={'/announcements'}
        />
      )
    );
  }
}
