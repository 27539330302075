import Core from "./Core";
import Http from "./Http";
import Definition from "./Definition";

const CandidateSkillsAction = {
    getFilteredTypes: () => {
        return [
            "roles",
            "technicalSkills",
            "positiveSignals",
            "negativeSignals",
            "level",
            "technologyDomain",
            "platformRating"
        ];
    },

    getStructure: () => {
        return {
            candidateName: "",
            from: "",
            type: "",
            addedChip: [],
            deletedChip: [],
            candidateId: ""
        };
    },

    findChangesInChips: (a, b) => {
        if (Array.isArray(a) && Array.isArray(b)) {
            return a.filter(x => !b.includes(x));
        } else {
            return [a].filter(x => ![b].includes(x));
        }
    },

    processUpdate: (candidate, dirtyAttr) => {

        let finalState = [], structure = {};
        // const hasChipChanged = (Array.isArray(structure.deletedChip) && structure.deletedChip.length > 0) ||
            // (Array.isArray(structure.addedChip) && structure.addedChip.length > 0);
        const isPresentInAllowAttrs = (attr) => CandidateSkillsAction.getFilteredTypes().includes(attr.key);
        
        finalState = dirtyAttr
            .filter(attr => isPresentInAllowAttrs(attr))
            .map(attrVal => {
                structure = CandidateSkillsAction.getStructure();
                structure.candidateName = candidate._name;
                structure.from = Core.getSessionEmail();
                structure.type = attrVal.label;
                structure.addedChip = Definition.getLabels([attrVal.key], CandidateSkillsAction.findChangesInChips(attrVal.newState, attrVal.oldState));
                structure.deletedChip = Definition.getLabels([attrVal.key], CandidateSkillsAction.findChangesInChips(attrVal.oldState, attrVal.newState));
                structure.candidateId = candidate.id;

                return structure;
            });


        if (!!finalState.length) {
            CandidateSkillsAction.post(finalState);
        }
    },

    post: (data, success) => {
        Http.post(
            Core.getApi("CandidateSkillsAction/"),
            data,
            success
        );
    }
};

export default CandidateSkillsAction;
