import React from "react";
import InputNumber from '../Layout/Wrappers/InputNumber';
import {
  REACT_TABLE__INPUT_RANGE_NUMBER
} from "./useEnhancedReactTable.hook";
import Box from '../Layout/Wrappers/Box';

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
/** */
export function FilterNumberRangeColumn({
  column: {
    id: columnId,
    preFilteredRows,
    _minimumValue,
    _maximumValue,
  },
  filters = {},
  setFilter = () => { },
}) {

  let values = [];
  let columnFilters = filters[columnId] || {};
  values[0] = columnFilters['$gte'] ? columnFilters['$gte'] : values[0];
  values[1] = columnFilters['$lte'] ? columnFilters['$lte'] : values[1];

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[columnId] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[columnId] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[columnId], min);
      max = Math.max(row.values[columnId], max);
    });
    return [min, max];
  }, [columnId, preFilteredRows]);

  const _setFilters = values => {

    values = values.map(value => ((value === undefined) ? null : value));

    let mongoRange = {};

    if (values[0]) { mongoRange['$gte'] = values[0]; }
    if (values[1]) { mongoRange['$lte'] = values[1]; }

    setFilter({
      columnId,
      value: (values[0] || values[1]) ? mongoRange : undefined
    });
  }

  return (
    <Box row wAuto noWrap>
      <InputNumber
        min={_minimumValue || 0}
        max={values[1] ? values[1] - 1 : _maximumValue}
        value={values[0] || _minimumValue || 0}
        onChange={(value) => {
          value = !!value ? value : _minimumValue;
          _setFilters([value, values[1]]);
        }}
        className="mr-1"
        placeholder={`Min (${min})`}
        style={{
          width: REACT_TABLE__INPUT_RANGE_NUMBER,
        }}
      />
      <span className="mr-1">to</span>
      <InputNumber
        min={values[0] ? values[0] + 1 : _minimumValue || 0}
        max={_maximumValue}
        value={values[1] || _minimumValue || 0}
        type="number"
        onChange={(value) => {
          value = !!value ? value : _minimumValue;
          _setFilters([values[0], value]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: REACT_TABLE__INPUT_RANGE_NUMBER,
        }}
      />
    </Box>
  );
}
/** */