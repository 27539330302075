import moment from 'moment';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__US_SHORT,
  formatDateTime24hPT,
  isValidDate
} from '../../../lib/Date.lib';
import {
  copyString
} from '../../../lib/GenericTools.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  isEmptyString,
  Str
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  COLLECTION__TEMPLATES,
  createLoopbackRecord,
  deleteLoopbackRecord,
  getLoopbackRecord,
  updateLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import {
  REACT_TABLE__COLUMN_LG
} from '../../Home/useEnhancedReactTable.hook';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import Row from '../../Layout/Wrappers/Row';
import TaggedValue from '../../Layout/Wrappers/TaggedValue';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';
import {
  TemplateModel
} from './Template.model';

export const ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE = 'create_template';
export const ADMIN_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME = 'CANDIDATE__FIRSTNAME';
export const ADMIN_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME = 'MY__FIRSTNAME';

function OptionName({ option }) {
  return Str(
    (option.id === ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE)
      ? 'New Template'
      : option.name
  );
}

export function AdminManageTemplatesContent(props) {
  const defaultTemplate = TemplateModel({
    id: ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE,
    createdAt: null,
    updatedAt: null
  });
  const [{
    selected = {},
    templates = []
  }, _updateState] = useState({ selected: defaultTemplate });

  const isCreateSelected = selected.id === ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE;

  async function _createTemplate() {
    if (isEmptyString(selected.name)) {
      return Core.showWarning('Name is required');
    }
    if (isEmptyString(selected.templates.subject)) {
      return Core.showWarning('Subject is required');
    }
    if (isEmptyString(String(selected.templates.bodyHtml || '').replace(/<p>(<br *\/>)*<\/p>/gi, ''))) {
      return Core.showWarning('Body is required');
    }
    const record = TemplateModel(selected);
    delete record.id;
    delete record.createdAt;
    delete record.updatedAt;
    Object.assign(selected, Obj(
      await createLoopbackRecord({
        collection: COLLECTION__TEMPLATES,
        record
      }).catch(Core.showError)
    ));
    if (selected?.id) {
      await _readTemplates();
      await _updateState({ selected });
      Core.showSuccess('Template created');
    }
  }

  async function _readTemplates() {
    await _updateState({
      templates: Arr(
        await getLoopbackRecord({
          collection: COLLECTION__TEMPLATES,
          order: 'name ASC',
          mapper: (templates) => Arr(templates).map((template) => TemplateModel(template))
        }).catch(Core.showError)
      )
    });
  }

  async function _updateTemplate() {
    Object.assign(selected,
      Obj(
        await updateLoopbackRecord({
          collection: COLLECTION__TEMPLATES,
          id: selected.id,
          record: TemplateModel(selected)
        }).catch(Core.showError)
      )
    );
    if (selected?.id) {
      await _readTemplates();
      await _updateState({ selected });
      Core.showSuccess('Template updated');
    }
  }

  async function _deleteTemplate() {
    showConfirm({
      title: 'Delete template?',
      message: (
        <Typography strong className='f-xl' style={{ minHeight: 100 }}>
          {AppUI.undoneAction.message}
        </Typography>
      ),
      async onAccept() {
        if (
          Obj(await deleteLoopbackRecord({
            collection: COLLECTION__TEMPLATES,
            id: selected.id
          }).catch(Core.showError)).count !== undefined
        ) {
          await _readTemplates();
          await _updateState({ selected: defaultTemplate });
          Core.showSuccess('Template deleted');
        }
      },
      onAcceptLabel: 'Delete'
    });
  }

  async function _upsertTemplate() {
    if (isCreateSelected) {
      await _createTemplate();
    }
    else {
      await _updateTemplate();
    }
  }

  async function _setSelected(templateId, template) {
    template = (
      templates.find((_template) => (_template.id === templateId)) ||
      template
    );
    Object.assign(selected, TemplateModel(template));
    await _updateState({ selected });
  }

  function _tokenizeMacro(macro) {
    return `{{${macro || 'VARIABLE'}}}`;
  }

  const availableRows = Math.floor((window.innerHeight || document.documentElement.clientHeight) / 25) - 12;

  return (
    <>

      <Row>
        <Fieldset
          title='Name'
          subtitle='This is the key name of the template, which is used in the code'
        >
          <TextField
            value={Str(selected.name)}
            onChange={async event => {
              Object.assign(selected, { name: event.target.value });
              await _updateState({ selected });
            }}
          />
        </Fieldset>
        <Fieldset
          title='Select a Template'
          subtitle='The templates are used for automated emails. Please be cautious!'
        >
          <Menu className='w-100'
            name='adm_mng_select_templates'
            options={[
              defaultTemplate,
              ...templates
            ]}
            optionLabel='name'
            value={selected.id}
            onChange={_setSelected}
            renderSelected={
              ({ selected = {}, open }) => (
                <Button
                  className='tt-unset space-between w-100'
                  style={{ minWidth: REACT_TABLE__COLUMN_LG }}
                  variant='outlined'
                  size='small'
                  color={open ? 'inherit' : 'primary'}
                  endIcon={
                    <i className={`material-icons c-inherit`}>{open ? 'expand_less' : 'expand_more'}</i>
                  }
                  onClick={_readTemplates}
                >
                  <OptionName option={selected} />
                </Button>
              )
            }
            renderOption={({ option }) => {
              const isCreatedAtValid = isValidDate(option.createdAt);
              const isUpdatedAtValid = isValidDate(option.updatedAt);
              return (
                <Box row w100
                  title={(isCreatedAtValid || isUpdatedAtValid) && (
                    <>
                      <TaggedValue
                        tag='Created at'
                        value={isCreatedAtValid && formatDateTime24hPT(option.createdAt)}
                      />
                      <TaggedValue
                        tag='Updated at'
                        value={isUpdatedAtValid && formatDateTime24hPT(option.updatedAt)}
                      />
                    </>
                  )}
                >
                  <OptionName option={option} />
                  <div className='w-100 f-sm t-align-right c-black-medium'>
                    {isUpdatedAtValid
                      ? `${moment(option.updatedAt).format(DATE_FORMAT__DATETIME__US_SHORT)}`
                      : ''}
                  </div>
                </Box>
              );
            }}
          />
        </Fieldset>

      </Row>

      <Row className='mt-1'>
        <Fieldset title='Subject'>
          <TextField
            autoSize={{ minRows: 1, maxRows: 7 }}
            value={selected.templates.subject || ''}
            onChange={async event => {
              selected.templates.subject = event.target.value;
              await _updateState({ selected });
            }}
            small
          />
        </Fieldset>
      </Row>

      <Fieldset
        title='Body'
        wrapperProps={{ className: 'mt-1' }}
        fullWidth
      >
        <Box column w100>
          <TextField
            autoSize={{ minRows: 1, maxRows: availableRows }}
            value={selected.templates.bodyHtml}
            onChange={async event => {
              selected.templates.bodyHtml = event.target.value;
              await _updateState({ selected });
            }}
          />
          <div className='mt-1 d-flex flex-align-left-center'>
            <Button
              variant='contained'
              className='tt-unset min-w-160 mr-1'
              onClick={_upsertTemplate}
            >
              {isCreateSelected ? 'Create template' : `Update template`}
            </Button>
            <Button
              variant='outlined'
              color='error'
              className='tt-unset min-w-160'
              onClick={_deleteTemplate}
              disabled={isCreateSelected}
            >
              Delete
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              className='tt-unset min-w-160 ml-auto'
              onClick={(event) => _setSelected(defaultTemplate)}
              disabled={isCreateSelected}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Fieldset>

      {/** @todo Why this is d-none? */}
      <Fieldset
        title='Macros'
        subtitle='Use following macros in the Subject or Body fields of your email template:'
        wrapperProps={{ className: 'd-none' }}
        fullWidth
      >
        {[
          { token: _tokenizeMacro(ADMIN_MANAGE_TEMPLATES_TOKEN__CANDIDATE_FIRSTNAME) },
          { token: _tokenizeMacro(ADMIN_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME) },
        ].map(({ token }) => (
          <div
            key={`emp_mng_temp_cont_token_${token}`}
            className='d-flex flex-align-left-center'
            onClick={event => copyString(token)}
          >
            <span className='mr-1'>
              {token}
            </span>
            <IconButton icon='content_copy' />
          </div>
        ))}
      </Fieldset>

    </>
  );
}
