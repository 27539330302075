import Core from "../../Core";
import Http from "../../Http";

export async function autocomplete({ path = 'Entities/_autocomplete', query = '' }) {
  if (query.trim().length >= 2) {
    let promise = Http.get(
      Core.getApi(path),
      { query },
    )
      .then(response => {
        response = response.map(({ term }) => ({ id: term, label: term }));
        return response;
      });
    promise.catch(Core.showError);
    return promise;
  }
  else {
    return [];
  }
};
