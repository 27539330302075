import React from 'react';
import Candidate from '../../lib/Candidate';
import Company from '../../lib/Company';
import Core from '../../lib/Core';
import { WarningMessage } from '../Layout/Wrappers/Message';

export async function deleteCompany({ companyId, candidateId }) {
  return new Promise(async (resolve, reject) => {
    let candidates = await Candidate.getWhere({
      "employmentHistories.companyId": { $in: [companyId] }
    }).catch(Core.showError) || [];
    if (!!candidates.length && candidateId) {
      candidates = candidates.filter(c => c.id !== candidateId);
    }
    Core.showConfirm({
      title: 'Warning',
      content: (
        <div className='flex-column flex-align-left-top'>
          <WarningMessage className='m-0 w-100'>
            CAUTION - Remove company can not be undone.
          </WarningMessage>
          {!!candidates.length
            ? (
              <>
                <div className='mt-2'>
                  There are {candidates.length} other candidates have links to this company in their work experience and will be unlinked.
                </div>
                <div className='mt-2'>
                  Signals for other candidates linked to this company will not be changed. When these candidates' records are edited again, we will look up signals associated with all companies. If this company was deleted, no signals will be associated with the this company when Edit Candidate is SAVED.
                </div>
              </>
            )
            : (
              <div className='mt-1'>
                No candidate is linked to this company, it's safe to delete this company.
              </div>
            )}
        </div>
      ),
      async onAccept() {
        let response = await Company.delete(companyId).catch(Core.showError);
        if (response?.count === 1) { resolve(); }
        else { Core.showError(`Company(${companyId}) can not be deleted`); }
      },
      onAcceptLabel: 'Proceed to delete'
    });
  });
}
