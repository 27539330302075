import Page from '../Layout/Page';
import {
  ManagePrescreenQuestionsContent
} from './ManagePrescreenQuestionsContent';

export function ManagePrescreenQuestionsPage(props) {
  return (
    <Page title='Manage global prescreen question bank' paper noBack>
      <ManagePrescreenQuestionsContent />
    </Page>
  )
}
