import Slide from '@mui/material/Slide';
import {
  forwardRef
} from 'react';
import {
  mapWrapper
} from '../Libraries/Theme.lib';

const Transition = (
  {
    direction = 'up',
    wrapperProps = {},
    ...props
  },
  ref
) => {
  return (
    <Slide
      {...mapWrapper({
        role: 'Transition',
        props: wrapperProps,
        assign: {
          ...props,
          direction,
        }
      })}
      ref={ref}
    />
  );
}

export default forwardRef(Transition);
