import {
  Component
} from "react";
import JobRequirement from "../../Jobs/Edit/Forms/MustHave/JobRequirement";
import Card from '../../Layout/Wrappers/Card';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import {
  Html
} from '../../Layout/Wrappers/Html';
import InputFieldContent from "./InputFieldContent";

class Requirements extends Component {
  render() {
    let {
      job = {},
      candidate = {},
      saveJob,
      candidateResume,
      source
    } = this.props;
    return (
      <Card
        role='Requirements'
        className='my-05'
      >

        {!!job.id && !!candidate.id &&
          <JobRequirement onlyView
            candidate={candidate}
            job={job}
            mustHave={job.mustHave}
            niceToHave={job.niceToHave}
            candidateResume={candidateResume}
            source={source}
          />
        }

        <InputFieldContent
          label='New Technical Skills'
          contentKey="newTechSkill"
          selectedMatch={job}
          content={job.newTechSkill}
          saveJob={saveJob}
        />

        {[
          {
            key: 'candidateMust',
            label: 'Must have text'
          },
          {
            key: 'candidatePositives',
            label: 'Nice have text'
          },
          {
            key: 'candidateMustNot',
            label: 'Must not text'
          },
          {
            key: 'candidateNegatives',
            label: 'Nice not text'
          }
        ].map((el, index) => {
          return !!job[el.key] && (
            <Fieldset
              key={index}
              title={el.label}
            >
              <Html
                className='w-unset m-1 f-md fw-400 c-black-dark'
                value={job[el.key]}
              />
            </Fieldset>
          );
        })}

      </Card>
    );
  }
}

export default Requirements;