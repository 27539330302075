import {
  getShortURL
} from '../../GenericTools.lib';
import {
  Obj
} from '../../Object.lib';

export default function getEmployerAtsProvider({
  employer = {}
}) {
  const provider = Obj(employer.atsApiProviders[employer.atsTypeId]);
  const url = employer.atsUrl || provider.manualSubmissionUrl;
  return {
    ...provider,
    _name: provider.apiName || getShortURL(url),
    _url: url
  };
}
