import Core from "./Core";
import Http from "./Http";

const CandoPermittedJob = {

    post: (data, success) => {
        Http.post(
            Core.getApi("CandoPermittedJob"),
            data,
            success
        );
    },
    update: (id, data, success) => {
        Http.patch(
            Core.getApi("CandoPermittedJob/"+id),
            data,
            success
        );
    },
    get: (opts,cb) => {
        let {where, fields} = opts;
        where = Object(where);

        Http.get(
            Core.getApi("CandoPermittedJob"),
            {
                filter: JSON.stringify({where, fields})
            },
            function onSuccess(response) {
                cb(response);
            }
        );
    },
};

export default CandoPermittedJob;
