import React from 'react';
import { Arr, join } from '../../lib/Array.lib';
import Core from '../../lib/Core';
import { Obj } from '../../lib/Object.lib';
import { Str, trim } from '../../lib/String.lib';

const DEBUG = Core.debug('DownloadCSV');

export default function DownloadCSV({ id, filename = 'filename.csv', data = [], children, style = {}, className = '', props = {} }) {
  let csv = convertToCSV(data);
  return (
    <a
      id={id}
      {...props}
      href={`data:text/csv;charset=utf-8,${escape(csv)}`}
      download={filename}
      className={join([
        'decoration-none',
        className
      ], ' ')}
      style={style}
    >
      {children || 'CSV'}
    </a>
  )
}

/**
 * 
 * @param {object[]} array [ { fieldName1: value1, fieldName2: value2, ... }, ... ]
 * @returns 
 */
export function convertToCSV(array = []) {
  const table = {};
  array.forEach((item, row) => {
    Object.keys(item).forEach(column => {
      table[column] = Obj(table[column]);
      let value = item[column];
      if (value && !value.toString) { value = { ...value }; }
      value = value instanceof Function ? '[object Function]' : String(value);
      value = value.replace(/ {2,}|\r|\n/g, '');
      value = value.replace(/"/g, '\\"');
      value = `"${value}"`;
      table[column][row] = value;
    });
  });
  const headers = Object.keys(table).sort((a, b) => {
    a = Str(a); b = Str(b);
    if (a.match(/\d/) && b.match(/\d/)) {
      return a.localeCompare(b);
    }
    return 0;
  });
  let lines = [];
  headers.forEach((column, index) => {
    Object.keys(table[column]).forEach((row) => {
      lines[row] = Arr(lines[row]);
      lines[row][index] = table[column][row];
    });
  });
  lines.unshift(headers);
  lines = lines.map((line) => line.map((v) => trim(v)).join(','));
  if (DEBUG) {
    console.debug('convertToCSV:table', table);
    console.debug('convertToCSV:lines', lines);
  }
  return lines.join('\r\n');
}


export function isObject(objValue) {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}
