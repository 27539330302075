import React, {
  Component,
  Fragment
} from "react";
import Chip from '../../Layout/Wrappers/Chip';
import {
  getEvaluationChipStyle
} from '../Haves/v2/Evaluations.lib';

class CompanyPreference extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
  }

  getDisplayColor = () => {
    const {
      candidateCompanyPreferenceMin,
      candidateCompanyPreferenceMax,
      jobCompanyPreference
    } = this.props;

    if (!jobCompanyPreference) {
      return null;
    }

    if (!!candidateCompanyPreferenceMin && !!candidateCompanyPreferenceMax) {
      if (candidateCompanyPreferenceMin < jobCompanyPreference
        &&
        jobCompanyPreference < candidateCompanyPreferenceMax
      ) {
        return true;
      }
      return false;
    } else if (!!candidateCompanyPreferenceMin && !candidateCompanyPreferenceMax) {
      if (candidateCompanyPreferenceMin < jobCompanyPreference) {
        return true;
      }
      return false;
    } else if (!candidateCompanyPreferenceMin && !!candidateCompanyPreferenceMax) {
      if (jobCompanyPreference < candidateCompanyPreferenceMax) {
        return true;
      }
      return false;
    } else {
      return null;
    }

  }

  getDisplayStructureOfCompanyPreference = () => {
    const {
      candidateCompanyPreferenceMin,
      candidateCompanyPreferenceMax,
      jobCompanyPreference,
      source
    } = this.props;

    let label = null;

    if (source === 'candidate') {
      if (!!candidateCompanyPreferenceMin && !!candidateCompanyPreferenceMax) {
        label = `${candidateCompanyPreferenceMin} - ${candidateCompanyPreferenceMax}`;
      } else if (!!candidateCompanyPreferenceMin && !candidateCompanyPreferenceMax) {
        label = `${candidateCompanyPreferenceMin} +`;
      } else if (!candidateCompanyPreferenceMin && !!candidateCompanyPreferenceMax) {
        label = `- ${candidateCompanyPreferenceMax}`;
      }
    }
    else if (source === 'job') {
      if (!!jobCompanyPreference) {
        label = jobCompanyPreference;
      }
    }

    return label;
  }

  render() {

    const { candidateCompanyPreferenceMin, candidateCompanyPreferenceMax, jobCompanyPreference, source } = this.props;

    if (source === 'candidate' && !candidateCompanyPreferenceMin && !candidateCompanyPreferenceMax) {
      return null;
    }
    if (source === 'job' && !jobCompanyPreference) {
      return null;
    }

    const decision = this.getDisplayColor();

    return (
      <Fragment>
        <Chip
          className="slim-chip"
          variant="outlined"
          size="small"
          style={getEvaluationChipStyle(decision)}
          label={this.getDisplayStructureOfCompanyPreference()}
        />
      </Fragment>
    );
  }
}

export default CompanyPreference;