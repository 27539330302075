import {
  Component
} from "react";
import Box from '../../../../../Layout/Wrappers/Box';

const Number = [
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
  { name: 8 },
  { name: 9 },
  { name: 10 }
];

class NumberDropdown extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      number: this.props.atLeast,
      openNumberDD: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = document;
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        openNumberDD: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.atLeast !== prevProps.atLeast) {
      this.setState({ number: this.props.atLeast });
    }
  }

  openNumberDD = () => {
    let { openNumberDD } = this.state;
    this.setState({ openNumberDD: !openNumberDD });
  }

  handlerNumberOption = (number) => () => {
    const { handlerNumber } = this.props;
    this.setState({ number, openNumberDD: false }, () => {
      !!handlerNumber && handlerNumber(number);
    });
  }

  render() {
    const { number, openNumberDD } = this.state;
    const { onlyView } = this.props;
    const styleView = !!onlyView ? 'fc-button-color-view' : 'fc-button-color-secondary';
    const {
      className,
      style
    } = this.props;
    const wrapperProps = {
      className,
      style
    }
    return <Box row {...wrapperProps}>
      <span className="fc-dropdownmenu">
        {!!number &&
          <span
            className={`fc-dropdownmenu-button ${styleView}`}
            onClick={this.openNumberDD}
            onKeyDown={this.openNumberDD}
          >
            <span>{number}</span>
          </span>
        }
        {!onlyView && !!openNumberDD &&
          <span className="fc-dropdownmenu-contextmenu fc-dropdown-position">
            {Number.map((item, index) => {
              return <div
                className="fc-dropdownmenu-contextmenu-item"
                key={index}
                selected={index}
                onClick={this.handlerNumberOption(item.name)}
              >
                {item.name}
              </div>
            })}
          </span>
        }
      </span>&nbsp;&nbsp;
    </Box>
  }
}

export default NumberDropdown;
