import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MuiSwitch from '@mui/material/Switch';
import * as React from 'react';
import { NOT } from '../../../lib/Boolean.lib';
import {
  joinClassName,
  mapWrapperName,
  THEME__COLOR__DEFAULT,
  THEME__COLOR__PRIMARY,
  THEME__COLOR__SECONDARY
} from '../Libraries/Theme.lib';
import Typography from './Typography';

/**
 * @see https://mui.com/material-ui/api/switch/
 */
export default function Switch({
  children,
  label = children,
  checked = false,
  onChange = () => null,
  wrapperProps = {},
  switchProps = {},
  labelProps = {},
  className = '',
  style = {},
  acl = true,
  debug = false,
  secondary = false,
  small = false,
  size = 'medium',
  color = THEME__COLOR__DEFAULT,
  ...props
}) {
  if (!acl) { return null; }
  mapWrapperName('Form', wrapperProps);
  mapWrapperName('Switch', switchProps);
  mapWrapperName('Label', labelProps);
  checked = Boolean(checked);
  if (!!secondary) { color = THEME__COLOR__SECONDARY; }
  if (!!small) { size = 'small'; }
  color = checked ? THEME__COLOR__PRIMARY : color;
  Object.assign(wrapperProps, {
    className: joinClassName([
      NOT(className.match(/m-|mx-|ml-|mr-/)) && 'mr-1',
      className
    ]),
    style
  });
  Object.assign(switchProps, {
    ...props,
    color,
    size,
    checked,
    onChange,
    ___flags__: { secondary, small }
  });
  Object.assign(labelProps, {
    children: label,
    className: joinClassName([
      'tt-unset tt-none fw-500',
      (size === 'small') ? 'f-sm' : 'f-md'
    ]),
    style: {
      letterSpacing: 0.5
    },
    color
  });
  if (debug) {
    console.debug(wrapperProps);
    console.debug(labelProps, color);
    console.debug(switchProps);
  }
  return (
    <FormGroup {...wrapperProps}>
      <FormControlLabel
        control={<MuiSwitch {...switchProps} />}
        label={(<Typography {...labelProps} className='nowrap' />)}
        className='mx-0'
      />
    </FormGroup>
  );
}
