import {
  Typography
} from '@mui/material';
import {
  Component
} from "react";
import Core from "../../lib/Core";
import Definition from "../../lib/Definition";
import Store from "../../lib/Store";
import {
  getLocation
} from '../../lib/URL.lib';
import Divider from '../Layout/Wrappers/Divider';
import Navigate from '../Layout/Wrappers/Navigate';
import Paper, {
  getDefaultPaperStyle
} from '../Layout/Wrappers/Paper';
import CategoryBox from "./CategoryBox";
import TagBox from "./TagBox";

export default class TagManagement extends Component {
  constructor() {
    super(...arguments);
    this.state = { categories: [] };
    Store.set("path", getLocation());
    this.load();
  }
  load() {
    Definition.fetchAll().then(definitions => {
      console.debug({ definitions });
      this.setState(
        state => {
          const keys = Definition.getRes();
          const categories = Object.keys(keys)
            .sort()
            .map(key => keys[key]);
          state.categories = categories;
          return state;
        },
        then => window.scrollTo(0, 0)
      );
    });
  }
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    console.debug(
      'TagManagement...',
      '\n', this.state,
    );
    return (
      <Paper
        className='m-auto my-2 scroll-y'
        style={getDefaultPaperStyle({ maxHeight: 'calc(100% - 3rem)' })}
      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='c-purple m-0 p-2'>
          Tag management
        </Typography>
        <Divider />
        {this.state.categories.map((category, catIndex) => (
          <CategoryBox
            key={`tag_management__category__${catIndex}}`}
            category={category}
            tags={category.values}
            onUpdate={({ category, tags }) => {
              console.debug('tags', tags);
              this.setState(async state => {
                if (category) {
                  const update = {
                    key: category.key,
                    label: category.label,
                    open: category.open,
                    archived: category.archived,
                    groups: category.groups
                  };
                  state.categories[catIndex] = category;
                  await Definition.update(category.id, update);
                }
                if (tags) {
                  const update = {
                    values: tags
                  };
                  state.categories[catIndex].values = tags;
                  await Definition.update(category.id, update);
                }
                console.debug(
                  'TagManagement__CategoryBox__onUpdate...',
                  '\n', category,
                  '\n', tags,
                  '\n', state,
                );
                return state;
              });
            }}
            expanded={!!category.open}
          />
        ))}
        <div className='bg-main w-100 p-1'>
          <TagBox
            key={Core.getKey()}
            name="addNewCategory"
            placeholder="Add a New Category"
            onUpdate={tag => {
              Core.log({ tag });
              Definition.post({ label: tag.label }, res => this.load());
            }}
            new={true}
            divider={false}
          />
        </div>
      </Paper>
    );
  }
}
