import {
  Str
} from '../../../lib/String.lib';

export default function Link({
  acl = true,
  url,
  href,
  children,
  ...props
}) {
  if (!acl) { return null; }
  url = url || href || Str(children);
  return (
    <a href={url} target='_blank' rel='noreferrer' {...props}>
      {children || url}
    </a>
  );
}
