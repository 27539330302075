import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List as MuiList
} from '@mui/material';

export default function List({
  disablePadding = true,
  ...props
}) {
  const _props = {
    disablePadding,
    ...props
  }
  return (
    <MuiList {..._props} />
  );
}

List.Item = ListItem;
List.Item.Text = ListItemText;
List.Item.Icon = ListItemIcon;
