import _ from 'lodash';
import moment from 'moment';
import { NEGATIVE_COMPANY_SIGNAL_TAG_IDS, NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED, POSITIVE_COMPANY_SIGNAL_TAG_IDS, POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED } from '../../lib/Company';
import Core from '../../lib/Core';
import { NEGATIVE_SCHOOL_SIGNAL_TAG_IDS, NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED, POSITIVE_SCHOOL_SIGNAL_TAG_IDS, POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED } from '../../lib/School';

export const SIGNALS_TYPE__COMPANY = 'Company';
export const SIGNALS_TYPE__SCHOOL = 'School';

export function mapOrganizationSignals({
  signals = {},
  type = SIGNALS_TYPE__COMPANY,
  filteringPositiveSignals = [],
  filteringNegativeSignals = []
}) {
  if (type === SIGNALS_TYPE__COMPANY) {
    filteringPositiveSignals = [...POSITIVE_COMPANY_SIGNAL_TAG_IDS, ...POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_COMPANY_SIGNAL_TAG_IDS, ...NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
  }
  else if (type === SIGNALS_TYPE__SCHOOL) {
    filteringPositiveSignals = [...POSITIVE_SCHOOL_SIGNAL_TAG_IDS, ...POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS, ...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
  }
  signals = signals || {};
  signals.curated = signals.curated || {};
  signals.curated.positive = [
    ...new Set(signals.curated.positive || [])
  ].filter(id => filteringPositiveSignals.includes(id));
  signals.curated.negative = [
    ...new Set(signals.curated.negative || [])
  ].filter(id => filteringNegativeSignals.includes(id));
  signals.exclusions = signals.exclusions || {};
  signals.exclusions.positive = [...new Set(signals.exclusions.positive || [])];
  signals.exclusions.negative = [...new Set(signals.exclusions.negative || [])];
  signals.automated = signals.automated || {};
  signals.automated.entity = signals.automated.entity || {};
  signals.automated.tenure = signals.automated.tenure || {};
  signals.automated.positive = (signals.automated.positive || []);
  signals.automated.negative = (signals.automated.negative || []);
  signals.positive = [
    ...new Set([
      ...signals.curated.positive,
      ...signals.automated.positive.filter(
        id => !signals.exclusions.positive.includes(id)
      )
    ])
  ];
  signals.negative = [
    ...new Set([
      ...signals.curated.negative,
      ...signals.automated.negative.filter(
        id => !signals.exclusions.negative.includes(id)
      )
    ])
  ];
  return signals;
}

export function mapOrganizationTenureSignals({
  signals = {},
  type = SIGNALS_TYPE__COMPANY,
  filteringPositiveSignals = [],
  filteringNegativeSignals = []
}) {
  /** */
  if (type === SIGNALS_TYPE__COMPANY) {
    filteringPositiveSignals = [...POSITIVE_COMPANY_SIGNAL_TAG_IDS, ...POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_COMPANY_SIGNAL_TAG_IDS, ...NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
  }
  else if (type === SIGNALS_TYPE__SCHOOL) {
    filteringPositiveSignals = [...POSITIVE_SCHOOL_SIGNAL_TAG_IDS, ...POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS, ...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
  }
  /** */
  signals = signals || {};
  signals.curated = signals.curated || {};
  signals.curated.positive = [
    ...new Set(signals.curated.positive || [])
  ].filter(id => filteringPositiveSignals.includes(id));
  signals.curated.negative = [
    ...new Set(signals.curated.negative || [])
  ].filter(id => filteringNegativeSignals.includes(id));
  signals.exclusions = signals.exclusions || {};
  signals.exclusions.positive = [...new Set(signals.exclusions.positive || [])];
  signals.exclusions.negative = [...new Set(signals.exclusions.negative || [])];
  signals.automated = signals.automated || {};
  signals.automated.entity = signals.automated.entity || {};
  signals.automated.tenure = signals.automated.tenure || {};
  signals.automated.entity.positive = signals.automated.entity.positive || [];
  signals.automated.entity.negative = signals.automated.entity.negative || [];
  signals.automated.tenure.positive = signals.automated.tenure.positive || [];
  signals.automated.tenure.negative = signals.automated.tenure.negative || [];
  signals.positive = [
    ...new Set([
      ...signals.curated.positive,
      ...signals.automated.entity.positive.filter(
        id => !signals.exclusions.positive.includes(id)
      ),
      ...signals.automated.tenure.positive
    ])
  ];
  signals.negative = [
    ...new Set([
      ...signals.curated.negative,
      ...signals.automated.entity.negative.filter(
        id => !signals.exclusions.negative.includes(id)
      ),
      ...signals.automated.tenure.negative
    ])
  ];
  return signals;
}

let DEBUG_MAP_TENURE = Core.debug('DEBUG_MAP_TENURE');
export function mapTenure({
  companyId,
  positionHistories,
  schoolId,
  degrees
}) {
  const format = (year, month) => {
    year = year && _.padStart(`${year}`, 4, '0');
    month = month && _.padStart(`${month}`, 2, '0');
    return year && month ? `${year}-${month}` : year ? year : undefined;
  };
  let histories = positionHistories || degrees || [];
  let firstHistory = _.first(histories);
  let lastHistory = _.last(histories);
  let map = {};
  if (companyId) { map.companyId = companyId; }
  if (schoolId) { map.schoolId = schoolId; }
  map.tenureStart = format(
    lastHistory?.startDateYear,
    lastHistory?.startDateMonth
  ) || moment().format(`YYYY-MM`);
  map.tenureEnd = format(
    firstHistory?.endDateYear,
    firstHistory?.endDateMonth
  ) || moment().format(`YYYY-MM`);
  map.description = (
    histories
      .map(h => `${h.title || ''} ${h.description || ''}`.trim() || h.degreeMajor)
      .filter(v => !!v)
      .join('\n---\n')
  ).trim() || null;
  DEBUG_MAP_TENURE && console.debug(
    'DEBUG_MAP_TENURE',
    {
      companyId,
      positionHistories,
      schoolId,
      degrees
    },
    map
  );
  return map;
}
