import {
  useState
} from "react";
import {
  MDASH
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import {
  getEngagementUrl
} from "../../../lib/Engagement";
import {
  mapAccountEmployerDetails
} from '../../../lib/mappers/AccountEmployerDetails.mapper';
import {
  mapAccount
} from '../../../lib/models/account';
import {
  AccountEmployerDetailsQuery
} from '../../../lib/queries/AccountEmployerDetails.query';
import {
  COLLECTION__ACCOUNTS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  compileText
} from '../../../lib/String.lib';
import Button from '../../Layout/Wrappers/Button';
import {
  ErrorMessage
} from '../../Layout/Wrappers/Message';
import Paper from '../../Layout/Wrappers/Paper';
import Loader from "../../Shared/Loader";

export default function AccountEmployerDetails(props) {
  let { account } = props;
  const [state, setState] = useState({});
  let { busy, error, accountDetails } = state;
  const _updateState = async (update = { __updatedAt: Date.now() }) => new Promise((resolve) => {
    setState((prevState) => (update = { ...prevState, ...update }));
    setTimeout(() => resolve(update));
  });
  setTimeout(async () => {
    if (busy || error || accountDetails) { return; }
    try {
      await _updateState({ busy: true });
      await _updateState({
        accountDetails: await readLoopbackRecord({
          collection: COLLECTION__ACCOUNTS,
          mapper: mapAccount,
          ...(compileText(AccountEmployerDetailsQuery, { ACCOUNT_ID: account.id }))
        }),
        busy: false
      });
    }
    catch (error) {
      await _updateState({ error });
    }
  });
  const { offers = [], placements = [] } = mapAccountEmployerDetails(accountDetails);
  console.debug(
    'AccountEmployerDetails...',
    '\n', state
  );
  return (
    <>
      <Loader loading={!error && busy} />
      {accountDetails && (
        <Paper className="cursor-default p-2 c-gray">
          <div>
            {offers.length} offers
          </div>
          <ul>
            {offers.map(engagement => (
              <EngagementInfo
                key={`account_employer_details__offer__${engagement?.id}`}
                engagement={engagement}
              />))}
          </ul>
          <div>
            {placements.length} placements
          </div>
          <ul>
            {placements.map(engagement => (
              <EngagementInfo
                key={`account_employer_details__placement__${engagement?.id}`}
                engagement={engagement} />)
            )}
          </ul>
        </Paper >
      )}
      <ErrorMessage show={!!error}>
        {error}
      </ErrorMessage>
    </>
  );

}

export function EngagementInfo({ engagement }) {
  const { job, candidate } = engagement;
  return (
    <li>
      <Button flat small mr1
        onClick={(event) => {
          Core.openPopUp(getEngagementUrl({ engagement }), 1600);
        }}
        startIcon='visibility'
      >
        Engagement view
      </Button>
      <span
        className='anchor mr-1'
        onClick={(event) => {
          Core.openPopUp(Core.getPath(`job/edit/${job.id}`), 1600);
        }}
      >
        {job.jobTitle}
      </span>
      {MDASH}
      <span
        className='anchor ml-1'
        onClick={(event) => {
          Core.openPopUp(Core.getPath(`candidate/edit/${candidate.id}`), 1600);
        }}
      >
        {candidate._name}
      </span>
    </li>
  );
}
