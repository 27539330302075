import React, {
  Component
} from "react";
import Core from "../../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER
} from "../../../lib/Definition";
import Employer from "../../../lib/Employer";
import {
  getEmployerModel
} from "../../../lib/models/employer";
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsEmployers
} from '../../Home/3DotsMenu/moreMenuOptionsEmployers';
import Card from '../../Layout/Wrappers/Card';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Table from '../../Layout/Wrappers/Table';
import NavLink from '../../Layout/Wrappers/NavLink';

class EmployerCard extends Component {
  timeout;
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      ...getEmployerModel({ extended: true }),
      /** controller states */
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      /** load */
      ...this.props.model
    };
  }
  unexpandCard = event => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: "rotate(0deg)" }
    });
  };
  expandCard = event => {
    this.setState({
      expanded: true,
      CardStyle: { margin: "10px auto" },
      openedColor: { color: "#715EFF" },
      rightArrow: { transform: "rotate(180deg)" }
    });
    Employer.getBlackList(this.state.id, employerBlackList =>
      this.setState({ employerBlackList })
    );
    Employer.getWhiteList(this.state.id, employerWhiteList =>
      this.setState({ employerWhiteList })
    );
    setTimeout(st => Core.log(this.state));
  };
  onCheckStar = (ev, checked) => {
    const updateLocalList = response => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
        }
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Employer.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
    this.unexpandCard();
  };
  onChangeEmployerNotes = (ev, publicNotes) => {
    this.setState({ publicNotes });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      Core.updateEmployer(
        this.state.id,
        { publicNotes },
        null,
        response => true
      );
    }, 1000);
  };
  render() {
    const mapActiveJobs = load => {
      const active = this.state.jobs.filter(job =>
        Definition.test("state", job.state, /active/)
      ).length;
      return active ? active + " Active" : <i>&mdash;</i>;
    };
    return (
      <Card
        className="row-card employer scroll-x border-bottom"
        style={this.state.CardStyle}
      >
        {/** COLLAPSED VIEW */}
        <Table className="collapsed-view" style={{ marginBottom: 10 }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell
                title="Employer"
                className="first-item pl-2"
                style={{ width: 256 }}
              >
                {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) ? (
                  <NavLink
                    title="Go to Edit"
                    to={"/employer/edit/" + this.state.id}
                  >
                    <b
                      style={{
                        color: colors.black.common,
                        fontWeight: 500,
                        ...this.state.openedColor
                      }}
                    >
                      {this.state._name || <i>&mdash;</i>}
                    </b>
                  </NavLink>
                ) : (
                  <b
                    style={{
                      color: colors.black.common,
                      fontWeight: 500,
                      ...this.state.openedColor
                    }}
                  >
                    {this.state.name || <i>&mdash;</i>}
                  </b>
                )}
              </Table.Cell>
              <Table.Cell
                style={{ width: 256 }}
              >
                {Definition.getLabel("stage", this.state.stage) || (
                  <i>&mdash;</i>
                )}
              </Table.Cell>
              <Table.Cell
                style={{ width: 256 }}
              >
                {this.state.addressCity || <i>&mdash;</i>}
              </Table.Cell>
              <Table.Cell
                style={{ width: 256 }}
              >
                {mapActiveJobs()}
              </Table.Cell>
              <Table.Cell
                title="Options"
                className="no-truncate pr-0"
                rowSpan={2}
                style={{ width: 160 }}
              >
                <div className='d-flex flex-align-right-center px-1'>
                  <Checkbox
                    title="Click to Starred"
                    className="star"
                    checked={this.state.starred}
                    onCheck={this.onCheckStar}
                    checkedIcon={<i className="material-icons">star</i>}
                    uncheckedIcon={<i className="material-icons">star_border</i>}
                    iconStyle={this.state.openedColor}
                  />
                  <MoreOptionsMenu
                    row={{ original: { ...this.state } }}
                    context={{ config: { moreMenuOptions: moreMenuOptionsEmployers } }}
                  />
                  <i
                    className="material-icons"
                    style={{
                      width: 24,
                      height: 24,
                      margin: 0,
                      cursor: "pointer",
                      ...this.state.openedColor,
                      ...this.state.rightArrow
                    }}
                    onClick={(event) => this.state.openDetails()}
                  >
                    chevron_right
                  </i>
                </div>
              </Table.Cell>
            </Table.Row >
            <Table.Row>
              <Table.Cell className="first-col pl-2">
                {this.state._flags || <i>&mdash;</i>}
              </Table.Cell>
              <Table.Cell >
                {this.state.employeeCount ? (
                  <>
                    ~{this.state.employeeCount}
                    &nbsp;employees&nbsp;&nbsp;&nbsp;
                  </>
                ) : (
                  <i>&mdash;</i>
                )}
              </Table.Cell>
              <Table.Cell>
                {Definition.getLabel("state", this.state.state) || (
                  <i>&mdash;</i>
                )}
              </Table.Cell>
              <Table.Cell>
                {this.state.jobs.length ? (
                  this.state.jobs.length + " Jobs"
                ) : (
                  <i>&mdash;</i>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card>
    );
  }
}

export default EmployerCard;
