import {
  Component
} from 'react';
import Core from "../../../lib/Core";
import {
  joinKeyName,
  Str
} from '../../../lib/String.lib';
import downloadFile from "../../../lib/tools/downloadFile";
import formatURL from "../../../lib/tools/formatURL";
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import Link from '../../Layout/Wrappers/Link';

export default class ResumeLinks extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      sovrenData: []
    }
  }
  render() {
    const { resumes, onError, onDelete, onReparse } = this.props;
    return (
      <Box column w100>
        {!!resumes.length &&
          resumes.map((resume, index) => {
            return (
              <Box row w100
                key={joinKeyName([
                  'resume_links',
                  resume.url,
                  index
                ])}
                className='py-05'
              >
                <Box title='Download resume file'>
                  <IconButton
                    onClick={ev =>
                      downloadFile({
                        url: resume.url,
                        mimeType: "application/pdf",
                        onError: error => {
                          onError(error);
                        }
                      })
                    }
                    icon='file_download'
                  />
                </Box>
                <Box
                  acl={Core.isAdmin()}
                  title={resume.parseError || 'Re-parse resume'}
                >
                  <IconButton
                    onClick={ev => {
                      onReparse({
                        url: resume.url,
                        filename: decodeURIComponent(
                          Str(resume.url).split("/").pop()
                        )
                      });
                    }}
                    disabled={resume.parseError}
                    icon='redo'
                  />
                </Box>
                <Box title='Remove resume'>
                  <IconButton
                    onClick={event => onDelete(event, resume, index)}
                    icon='close'
                  />
                </Box>
                <Link href={formatURL(resume.url)} className='truncate'>
                  {decodeURIComponent(
                    String(resume.url)
                      .split("/")
                      .pop()
                  )}
                </Link>
              </Box>
            );
          })}
      </Box>
    )
  }
}