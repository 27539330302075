import {
  Component
} from "react";
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';
import Icon from '../../../Layout/Wrappers/Icon';

export default class And extends Component {
  render() {
    const { decision } = this.props;
    let text = (
      <Icon mr
        role='AndYes'
        icon='done'
        color={THEME__COLOR__SUCCESS}
      />
    );
    decision.forEach(dec => {
      if (dec.group === 0) {
        text = (
          <Icon mr
            role='AndNo'
            icon='close'
            color={THEME__COLOR__ERROR}
          />
        );
      }
    });
    return text;
  }
}
