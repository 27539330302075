import { isEqual } from 'lodash';
import {
  Component,
  Fragment
} from "react";
import { Arr } from '../../../lib/Array.lib';
import { NOT } from '../../../lib/Boolean.lib';
import {
  getCategoryKeys
} from "../../../lib/tools/getSpecificCategoryKey";
import Chips from "../../Forms/ChipsNew";
import Card from '../../Layout/Wrappers/Card';
import Chip from '../../Layout/Wrappers/Chip';
import Divider from '../../Layout/Wrappers/Divider';
import BasicChip from "../Haves/v1/Chip";
import {
  updateCandidate
} from '../Libraries/MatchList.lib';
import MatchSkills from "./MatchSkills";
import Typography from '../../Layout/Wrappers/Typography';
import Fieldset from '../../Layout/Wrappers/Fieldset';

class Qualifications extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      technicalSkills: Arr(
        this.props.candidate.technicalSkills
      ),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      NOT(
        isEqual(
          prevProps.candidate.technicalSkills,
          this.props.candidate.technicalSkills
        )
      )
    ) {
      this.setState({
        technicalSkills: Arr(
          this.props.candidate.technicalSkills
        )
      });
    }
  }

  skillsHandler = (technicalSkills) => {
    const { skillsHandler } = this.props;
    this.setState({
      technicalSkills,
    });
    !!skillsHandler && skillsHandler(technicalSkills, "technicalSkills");
  };

  renderTechnicalSkills = (technicalSkills) => {
    technicalSkills = Arr(technicalSkills);
    const { jobArgs, source } = this.props;
    const jobArgsTechnicalSkills = getCategoryKeys(
      { key: "technicalSkills" },
      jobArgs
    );
    const toMatchObj = { technicalSkills: jobArgsTechnicalSkills };
    let chipObj = { key: "technicalSkills" };
    let isMatched = null;
    const decision = technicalSkills.map((skill) => {
      chipObj.value = parseInt(skill);
      isMatched = BasicChip.isChipMatched(chipObj, toMatchObj);
      return !!isMatched
        ? isMatched
        : source && source === "candidateMatch"
          ? null
          : isMatched;
    });
    return (
      <Fieldset
        title={
          <>
            <strong className='mr-05'>10x10 Approved Chip</strong>
            <Typography sub>
              (Admin configured chip)
            </Typography>
          </>
        }
      >
        <Chips
          name="technicalSkills"
          values={technicalSkills}
          decisionArray={decision}
          onChange={(technicalSkills) => {
            this.skillsHandler(technicalSkills);
          }}
        />
      </Fieldset>
    );
  };

  rolesHandler = (roles) => {
    const { rolesHandler } = this.props;

    rolesHandler(roles);
  };

  renderRoles = (roles) => {
    if (typeof roles == "string") {
      if (roles === "") {
        return null;
      } else if (roles.includes(",")) {
        roles = roles.split(/[,]/);
      } else {
        roles = [roles];
      }
    }
    if (!Array.isArray(roles)) {
      roles = [];
    }
    return (
      <Fieldset row
        title='Roles'
      >
        {roles.map((role, index) => {
          return (
            <Chip
              key={`match__pipe__candidate__chip__${index}`}
              className="chip slim-chip"
              variant="outlined"
              size="small"
              label={role}
              style={{ color: "#454545" }}
            />
          );
        })}
      </Fieldset>
    );
  };

  moveToApprovedChips = (candidate, keyAttributeChanged, chipId) => {
    const existingTechSkills = this.state.technicalSkills || [];
    const newTechSkills = Array.from(new Set([...existingTechSkills, chipId]));
    updateCandidate(candidate, { technicalSkills: newTechSkills });
    this.setState({ technicalSkills: newTechSkills });
  };

  render() {
    const { candidate = {}, jobArgs, source } = this.props;
    const { technicalSkills } = this.state;
    return (!!candidate.id &&
      <Card
        role='QualificationsCard'
        className='my-05'
      >

        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          QUALIFICATIONS
        </Typography>
        <Divider />

        {this.renderRoles(candidate._roles)}

        {this.renderTechnicalSkills(technicalSkills)}

        <MatchSkills
          candidate={candidate}
          jobArgs={jobArgs}
          source={source}
          deleteIcon={true}
          onDelete={this.moveToApprovedChips}
        />

      </Card>
    );
  }
}

export default Qualifications;
