import moment from 'moment';
import {
  Arr
} from '../../lib/Array.lib';
import {
  MDASH
} from '../../lib/Constants';
import Core from '../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ACCOUNT_ANNOUNCEMENTS,
  DEFINITION_CATEGORY__STATE,
  STATE_ACTIVE
} from '../../lib/Definition';
import useState from '../../lib/hooks/useState.hook';
import {
  mapAccountInfoForCSV
} from '../../lib/mappers/AccountEmployerDetailsForCSV.mapper';
import {
  mapAccounts
} from '../../lib/models/account';
import {
  AccountEmployerDetailsForCSVQuery
} from '../../lib/queries/AccountEmployerDetailsForCSV.query';
import {
  ROLE__EMPLOYER_RECRUITER,
  ROLE__LIMITED_RECRUITER,
  ROLE__SYS_ADMIN, ROLE__TRUSTED_RECRUITER
} from '../../lib/services/Accounts/Session.lib';
import {
  COLLECTION__ACCOUNTS,
  COLLECTION__EMPLOYERS,
  COLLECTION__EMPLOYERS_BLACK_LISTS,
  COLLECTION__RECRUITER_WHITE_LISTS,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import ReactDialog from '../Forms/ReactDialog';
import mapStringsArrayToTagObjectArray from '../Forms/mapStringsArrayToTagObjectArray.tool';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Menu from '../Layout/Wrappers/Menu';
import MultipleSelect from '../Layout/Wrappers/MultipleSelect';
import {
  renderDropdown
} from '../Layout/Wrappers/StyledTable';
import DownloadCSV from '../Tools/DownloadCSV';

const CONFIG = {
  labels: {
    announcementType: 'Announcement type',
    employerId: 'Employer',
    role: 'Role',
    stateIds: 'States',
  },
  defaultEmployerOption: { id: 'all_employers', label: 'All employers' }
}

const D_CSV__ID = 'd_csv';

export default function ButtonDownloadAccountCSV(props) {
  let [state, updateState] = useState();
  let {
    // Announcement type // default to no filter<br />
    // Employer // default to all employers<br />
    // Role: Trusted, Limited, Employer recruiter // Default to Trusted
    announcementType = null,
    employerId = null,
    stateIds = [STATE_ACTIVE],
    roleIds = [ROLE__TRUSTED_RECRUITER],
    employers = [],
    accounts = [{ firstName: 'test' }],
  } = state;
  employers = Arr(employers).map(employer => ({ ...employer, label: employer.name }));

  const _openDialog = async event => {
    await updateState({
      employers: await readLoopbackRecord({
        collection: COLLECTION__EMPLOYERS,
        fields: ['id', 'name'],
        order: 'name ASC',
      })
    });
    ButtonDownloadAccountCSV.ReactDialog.open();
  }

  const _emulateAnchorClick = () => {
    let link = document.getElementById(D_CSV__ID);
    let event = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    link.dispatchEvent(event);
  }

  const _generateAndDownloadCSV = async () => {
    const __mapAccountIds = ({ accountId }) => accountId;
    const blackListedIds = [];
    if (employerId) {
      let blackList = Arr(await readLoopbackRecord({
        collection: COLLECTION__EMPLOYERS_BLACK_LISTS,
        where: { employerId },
        fields: ['accountId']
      }).catch(Core.showError)).map(__mapAccountIds);
      let whiteList = Arr(await readLoopbackRecord({
        collection: COLLECTION__RECRUITER_WHITE_LISTS,
        where: { employerId },
        fields: ['accountId']
      }).catch(Core.showError)).map(__mapAccountIds);
      blackListedIds.push(
        ...new Set(blackList.filter((element) => !whiteList.includes(element)))
      );
    }
    let where = {
      and: [
        !!announcementType && { announcements: { inq: [announcementType] } },
        !!blackListedIds.length && { id: { nin: blackListedIds } },
        !!roleIds.length && { role: { inq: roleIds } },
        !!stateIds.length && { state: { inq: stateIds } },
      ].filter(v => !!v)
    };
    let accounts = await readLoopbackRecord({
      collection: COLLECTION__ACCOUNTS,
      mapper: mapAccounts,
      where: !!where.and.length ? where : undefined,
      ...(AccountEmployerDetailsForCSVQuery)
    }).catch(Core.showError);
    if (!!Arr(accounts).length) {
      await updateState({ accounts });
      _emulateAnchorClick();
      /** @todo [ 2023-02-22 ][ MS: to include or remove ] */
      // copyText(convertToCSV(Arr(accounts).map(mapAccountInfoForCSV)));
    }
    else {
      Core.showWarning('No accounts found');
    }
  }

  return Core.isTester() && (
    <>
      <Button small minW80
        startIcon='file_download'
        onClick={_openDialog}
        className='c-white-hover'
      >
        CSV
      </Button>
      <DownloadCSV
        id={D_CSV__ID}
        filename={`accounts__${moment().format('YYYY_MM_DD__HH_mm_ss')}.csv`}
        data={Arr(accounts).map(mapAccountInfoForCSV)}
        className='d-none'
      />
      <ReactDialog
        title='Download CSV'
        reference={self => ButtonDownloadAccountCSV.ReactDialog = self}
        onAccept={_generateAndDownloadCSV}
        content={
          <Box column w100>

            <Fieldset
              title={CONFIG.labels.announcementType}
            >
              <Menu dropdown
                options={Definition.get(DEFINITION_CATEGORY__ACCOUNT_ANNOUNCEMENTS)}
                value={announcementType}
                onChange={async (announcementType) => updateState({ announcementType })}
              />
            </Fieldset>

            <Fieldset
              title={CONFIG.labels.employerId}
            >
              {renderDropdown(employers)({
                name: `home__button_download_csv__dropdown_employer`,
                value: employerId || MDASH,
                onChange: async (employerId) => updateState({ employerId })
              })}
            </Fieldset>

            <Fieldset
              title={CONFIG.labels.role}
            >
              <MultipleSelect
                name='home__button_download_account_csv__multiselect__roles'
                placeholder={MDASH}
                data={mapStringsArrayToTagObjectArray([
                  ROLE__SYS_ADMIN,
                  ROLE__TRUSTED_RECRUITER,
                  ROLE__LIMITED_RECRUITER,
                  ROLE__EMPLOYER_RECRUITER,
                ])}
                value={roleIds}
                onChange={async roleIds => updateState({ roleIds })}
                style={{ borderRadius: 12 }}
              />
            </Fieldset>

            <Fieldset
              title={CONFIG.labels.stateIds}
            >
              <MultipleSelect
                name='home__button_download_account_csv__multiselect__states'
                placeholder={MDASH}
                data={Definition.get(DEFINITION_CATEGORY__STATE)}
                value={stateIds}
                onChange={async stateIds => updateState({ stateIds })}
                style={{ borderRadius: 12 }}
              />
            </Fieldset>

          </Box>
        }
      />
    </>
  );
}
