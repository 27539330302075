import { useState } from "react";

export function useUpdateState(defaultState) {
  const [state, setState] = useState({});
  async function updateState(newState) {
    if (newState instanceof Function) { newState = newState(state); }
    let _state = { ...state, ...newState };
    setState(_state);
    return _state;
  }
  return {
    state,
    updateState,
  }
}
