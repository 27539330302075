import React, { useState } from 'react';
import Engagement from '../../lib/Engagement';
import Fieldset from '../Layout/Wrappers/Fieldset';
import RichTextBox from '../Layout/Wrappers/RichTextBox';

export function SubmissionNote(props) {
  let [state, setState] = useState({ engagement: props.engagement });
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let { engagement = {} } = state;
  let { submissionNote } = engagement;
  return !!engagement.id && (
    <Fieldset title='Submission note'>
      <RichTextBox
        value={submissionNote}
        onChange={async (submissionNote) => {
          let update = { submissionNote };
          Object.assign(engagement, update);
          await _updateState({ engagement });
          clearTimeout(SubmissionNote.timeout);
          SubmissionNote.timeout = setTimeout(() => Engagement.update(engagement, update), 3000);
        }}
      />
    </Fieldset>
  );
}
