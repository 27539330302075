import React from 'react';
import { withTranslation } from "react-i18next";
import {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID
} from '../../lib/Definition';

export const inOfficeRemoteFlagsWarningRender = withTranslation()(
  ({ filters = {}, t }) => {
    let _inOfficeRemoteFlags = filters['inOfficeRemoteFlags'] || [];
    let _candidateLocations = filters['candidateLocations'] || [];
    let _officeLocations = filters['officeLocations'] || [];
    if (
      !_inOfficeRemoteFlags.length &&
      (
        !!_candidateLocations.length ||
        !!_officeLocations.length
      )
    ) {
      return (
        <span className="c-red f-sm">
          {t('You must choose at least one flag to filter by location')}
        </span>
      );
    }
    return null;
  }
);

export const candidateLocationsWarningRender = withTranslation()(
  ({ filters = {}, t }) => {
    let _inOfficeRemoteFlags = filters['inOfficeRemoteFlags'] || [];
    let _candidateLocations = filters['candidateLocations'] || [];
    if (
      _inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID) &&
      !_candidateLocations.length
    ) {
      return (
        <span className="c-red f-sm">
          {t('You must set a location to filter by on-site/remote')}
        </span>
      );
    }
    return null;
  })
  ;

export const officeLocationsWarningRender = withTranslation()(
  ({ filters = {}, t }) => {
    let _inOfficeRemoteFlags = filters['inOfficeRemoteFlags'] || [];
    let _officeLocations = filters['officeLocations'] || [];
    if (
      (
        _inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
        _inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
      ) &&
      !_officeLocations.length
    ) {
      return (
        <span className="c-red f-sm">
          {t('You must set a location to filter by on-site/remote')}
        </span>
      );
    }
    return null;
  }
);
