import {
  Navigate as Redirect
} from 'react-router-dom';
import {
  mapWrapperName
} from '../Libraries/Theme.lib';

/**
 * @param {object} props  [default: "/" ]
 * @param {string} props.to  [default: true ]
 * @param {boolean} props.replace
 * @see https://reactrouter.com/en/6.24.1/components/navigate
 */
export default function Navigate({
  to = '/',
  replace = true,
  ...props
}) {
  const _wrapperProps = mapWrapperName(
    'Navigate',
    {
      ...props,
      replace,
      to
    }
  );
  return (
    <Redirect {..._wrapperProps} />
  );
}
