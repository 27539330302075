import React from 'react';
import Core from '../../lib/Core';
import {
  ACCOUNT_ACTION__LIST_ACCOUNTS,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS
} from '../../lib/Definition';
import {
  getLocation,
  matchLocation
} from '../../lib/URL.lib';
import AccessDenied from '../Shared/AccessDenied';
import ReactTable from './ReactTable';
import {
  configAccounts
} from './configAccounts';
import {
  configCandidates
} from './configCandidates';
import {
  configEmployers
} from './configEmployers';
import {
  configEngagements
} from './configEngagements';
import {
  configEvent
} from './configEvents';
import {
  configJobs
} from './configJobs';
import {
  configMatchDecisionTable
} from './configMatchDecisionTable';
import { ErrorMessage } from '../Layout/Wrappers/Message';

export default function ListPages(props) {
  const url = getLocation();
  const config = {
    '/v3/candidates': configCandidates,
    '/v3/jobs': configJobs,
    '/v3/employers': configEmployers,
    '/v3/accounts': configAccounts,
    '/accounts': configAccounts,
    '/v3/engagements': configEngagements,
    '/matcher': configMatchDecisionTable,
    '/events': configEvent,
  };
  const key = Object.keys(config).find((key) => matchLocation(key));
  if (
    (!!url.match('engagements') && !Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })) ||
    (!!url.match('candidates') && !Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })) ||
    (!!url.match('jobs') && !Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })) ||
    (!!url.match('employers') && !Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS })) ||
    (!!url.match('accounts') && !Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ACCOUNTS }))
  ) {
    return (<AccessDenied />);
  }
  return (
    <>
      {key &&
        <ReactTable
          {...props} // passing router context 
          config={config[key]} // entity's config file
        />
      }
      <ErrorMessage show={!key} className='m-2 mx-4'>
        This route has not a configuration file
      </ErrorMessage>
    </>
  );
}
