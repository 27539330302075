import React, {
  Component
} from "react";
import Core from "../../lib/Core";
import Job from "../../lib/Job";
import jobModel from "../../lib/models/job";
import Store from "../../lib/Store";
import {
  getLocation
} from '../../lib/URL.lib';
import styles from "../Layout/Libraries/Styles.dic";
import Page from '../Layout/Page';
import Col from "../Layout/Wrappers/Col";
import Divider from '../Layout/Wrappers/Divider';
import Navigate from '../Layout/Wrappers/Navigate';
import Paper from '../Layout/Wrappers/Paper';
import Row from "../Layout/Wrappers/Row";
import Snackbar from '../Layout/Wrappers/Snackbar';
import TextField from '../Layout/Wrappers/TextField';

const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailInvalid = "Email is invalid";
const emailError = "Please enter a valid email";

export default class ManageJobsPage extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      companiesCollection: [],
      snackbar: null
    };
    Job.getActives(jobs => {
      const companies = {};
      jobs.forEach(job => {
        companies[job.employer.id] = companies[job.employer.id] || [];
        companies[job.employer.id].push(job);
      });
      const collection = Object.keys(companies)
        .sort((a, b) =>
          companies[a][0].employer.name.localeCompare(
            companies[b][0].employer.name
          )
        )
        .map(id => <CompanyGroup key={id} jobs={companies[id]} />);
      Core.log({ jobs, collection });
      this.setState({ companiesCollection: collection });
    });
  }
  goBack = ev => Core.goBack(this.props);
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    return (
      <Page
        title="Manage Jobs Resume Submission Emails"
      >
        <div className="scroll-y p-4" style={{ height: 'calc(100vh - 64px)' }}>
          <Paper className="form" style={styles.Paper}>
            {this.state.companiesCollection}
          </Paper>
        </div>
        <Snackbar
          open={!!this.state.snackbar}
          message={this.state.snackbar || ""}
          className="snack-bar"
          autoHideDuration={6000}
          onClose={ev => this.setState({ snackbar: null })}
        />
      </Page>
    );
  }
}

class CompanyGroup extends Component {
  render() {
    const { jobs } = this.props;
    return (
      <>
        <Row>
          <Col fullWidth>
            <label>{jobs[0].employer.name}</label>
          </Col>
        </Row>
        <div className="normal-padding">
          {jobs.map(job => <JobElement key={job.id} model={job} />)}
        </div>
        <Divider />
      </>
    );
  }
}

class JobElement extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ...jobModel,
      ...this.props.model
    };
    Store.set("path", getLocation());
  }
  render() {
    return (
      <Row>
        <Col className="col2">
          <h4 className="cdarker">{this.state.jobTitle}</h4>
        </Col>
        <Col className="col10">
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName1"
                placeholder="Name"
                value={this.state.resumeSubmissionName1}
                onChange={(ev, resumeSubmissionName1) =>
                  this.setState({ resumeSubmissionName1 })
                }
              />
            </Col>
            <Col>
              <TextField disabledBlurExport
                name="resumeSubmissionEmail1"
                placeholder="Email"
                type="email"
                value={this.state.resumeSubmissionEmail1}
                onChange={(ev, resumeSubmissionEmail1) =>
                  this.setState({ resumeSubmissionEmail1, errorEmail1: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail1 &&
                    !emailRE.test(this.state.resumeSubmissionEmail1)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail1: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail1}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName2"
                placeholder="Name"
                value={this.state.resumeSubmissionName2}
                onChange={(ev, resumeSubmissionName2) =>
                  this.setState({ resumeSubmissionName2 })
                }
              />
            </Col>
            <Col>
              <TextField disabledBlurExport
                name="resumeSubmissionEmail2"
                placeholder="Email"
                type="email"
                value={this.state.resumeSubmissionEmail2}
                onChange={(ev, resumeSubmissionEmail2) =>
                  this.setState({ resumeSubmissionEmail2, errorEmail2: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail2 &&
                    !emailRE.test(this.state.resumeSubmissionEmail2)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail2: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail2}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName3"
                placeholder="Name"
                value={this.state.resumeSubmissionName3}
                onChange={(ev, resumeSubmissionName3) =>
                  this.setState({ resumeSubmissionName3 })
                }
              />
            </Col>
            <Col>
              <TextField disabledBlurExport
                name="resumeSubmissionEmail3"
                placeholder="Email"
                type="email"
                value={this.state.resumeSubmissionEmail3}
                onChange={(ev, resumeSubmissionEmail3) =>
                  this.setState({ resumeSubmissionEmail3, errorEmail3: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail3 &&
                    !emailRE.test(this.state.resumeSubmissionEmail3)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail3}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                name="resumeSubmissionName4"
                placeholder="Name"
                value={this.state.resumeSubmissionName4}
                onChange={(ev, resumeSubmissionName4) =>
                  this.setState({ resumeSubmissionName4 })
                }
              />
            </Col>
            <Col>
              <TextField disabledBlurExport
                name="resumeSubmissionEmail4"
                placeholder="Email"
                type="email"
                value={this.state.resumeSubmissionEmail4}
                onChange={(ev, resumeSubmissionEmail4) =>
                  this.setState({ resumeSubmissionEmail4, errorEmail4: "" })
                }
                onBlur={ev => {
                  if (
                    !!this.state.resumeSubmissionEmail4 &&
                    !emailRE.test(this.state.resumeSubmissionEmail4)
                  ) {
                    this.setState({
                      snackbar: emailInvalid,
                      errorEmail3: emailError
                    });
                    return false;
                  }
                }}
                errorText={this.state.errorEmail4}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
