import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EMPLOYER_PENDINGS
} from '../../../lib/Definition';
import Employer from '../../../lib/Employer';
import Job from '../../../lib/Job';
import {
  reloadLocation
} from '../../../lib/URL.lib';
import openEmployerPendingsUrlGeneratorDialog from '../../Engagements/Card/openEmployerPendingsUrlGeneratorDialog';
import Button from '../../Layout/Wrappers/Button';

export const moreMenuOptionsEmployers = [
  {
    to: employer => (`/employer/edit/${employer.id}`),
    label: 'Edit Employer',
    acl: employer => (Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) || (Core.isRecruiter() && Core.isOnDev())),
  },
  {
    label: 'Employer Portal',
    to: (employer) => (`/employer/pendings/?employerId=${employer.id}`),
    target: (employer) => '_blank',
    acl: (employer) => Core.isAdmin({ action: ACCOUNT_ACTION__EMPLOYER_PENDINGS }),
  },
  {
    acl: () => Core.isAdmin(),
    label: EmployerPendingsUI.urlGeneratorDialog.title,
    action: (employer) => openEmployerPendingsUrlGeneratorDialog({ employer }),
  },
  {
    label: <span className='c-red'>Delete</span>,
    action: employer => deleteEmployer(employer),
    acl: employer => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }),
  }
];

function deleteEmployer(employer) {
  Job.getWhere({ employerId: employer.id }).then(
    jobs =>
      Core.dialog.open({
        title: 'Confirm',
        message: `Delete "${employer._name}"${!!jobs.length
          ? ` and ${jobs.length} job${jobs.length === 1 ? "" : "s"
          }?`
          : ""
          }`,
        paperStyle: { width: 640 },
        actions: [
          <Button outlined secondary minW120
            onClick={Core.dialog.close}
          >
            Cancel
          </Button>,
          <Button error minW120
            onClick={
              ev =>
                Employer.delete(employer.id,
                  response => {
                    Core.showSuccess(
                      "Employer was deleted successfully"
                    );
                    reloadLocation();
                  },
                  error => {
                    if (!!error) {
                      Core.showWarning("Can not delete employer. Please contact support.");
                    }
                  }
                )
            }>
            Confirm
          </Button>
        ]
      })
  )
}