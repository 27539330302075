import React from "react";
import {
  showConfirm
} from '../../../components/Dialogs/AppConfirmationDialog';
import EmailPreview from "../../../components/Dialogs/EmailPreview";
import {
  Arr
} from '../../Array.lib';
import Core from "../../Core";
import { EMP_MSG_TYPE__ALL_ID, EMP_MSG_TYPE__REMINDER_ID } from '../../Definition';
import Streak from '../../Streak';
import {
  Str
} from '../../String.lib';
import TemplateLib from "../../Template.lib";
import {
  sendSafeEmail,
  updateReminderSentDates
} from "./Email.lib";
import { Obj } from '../../Object.lib';


export async function openEmployerTodoReminderEmailPreview({ employer }) {
  const { id: employerId } = employer;
  const _key = `openEmployerTodoReminderEmailPreview__${employerId}`;
  const _template = await TemplateLib.getBy({
    type: 'email',
    name: 'employer-todo-reminder'
  });
  const _processedEmailData = await TemplateLib.getRender({
    templateId: _template.id,
    employerId
  });
  const { errors = [], rendered = {}, mixins = {} } = _processedEmailData;
  console.debug({ errors, rendered, mixins, employerId });
  if (!errors.length) {
    const { subject = '', bodyHtml: body } = rendered;
    const _postProcessingTemplate = Arr(Str(body).match(/<!-- POST_PROCESSING >>> -->([\s\S]*?)<!-- <<< POST_PROCESSING -->/))[0];
    const _body = Str(body).replace(_postProcessingTemplate, '');
    const {
      from,
      emails = [],
      to = [],
      cc = [],
      bcc = [],
      engagements = []
    } = mixins;
    showConfirm({
      title: <>Employer Reminder</>,
      content: (
        <EmailPreview
          ref={(self) => (self && Core.setKeyValue(_key, self))}
          emails={emails}
          from={from}
          to={to}
          cc={cc}
          bcc={bcc}
          subject={subject}
          body={_body}
        />
      ),
      onAcceptLabel: <>Send</>,
      onAccept: async (event) => {
        try {
          const _emailParameters = Core.getKeyValue(_key).getParams();
          _emailParameters.html = `${Str(_postProcessingTemplate)}${Str(_emailParameters.html)}`;
          await sendSafeEmail({
            ..._emailParameters,
            source: _key
          }).then((response) => {
            Core.showSuccess('Email sent');
            if (!!response?.threadId) {
              const next = em => {
                if (!!engagements.length) {
                  const engagement = engagements.pop();
                  Streak.putEmailInBox(
                    {
                      boxKey: engagement.boxKey,
                      threadGmailId: response.threadId
                    },
                    response => {
                      updateReminderSentDates({
                        engagement,
                        next: () => { },
                        reminder: {
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'employer', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                        }
                      });
                    }
                  );
                }
              };
              next();
            }
          });
        }
        catch (error) {
          Core.showError(error);
        }
      },
      paperStyle: {
        width: 1200,
      }
    });
  }
  else {
    Core.showError(`${errors.map(n => n.message).join(', ')}`);
  }
}

export async function openJobTodoReminderEmailPreview({
  job,
  employer,
  engagements
}) {
  const { id: jobId, jobTitle } = job;
  const { id: employerId } = employer;
  const contact = Obj(
    [...Arr(job.emailsList), ...Arr(employer.emailsList)].find((contact) => {
      if (contact.accountId) {
        if (
          contact.to.includes(EMP_MSG_TYPE__REMINDER_ID) ||
          contact.to.includes(EMP_MSG_TYPE__ALL_ID)
        ) { return true; }
        if (
          contact.cc.includes(EMP_MSG_TYPE__REMINDER_ID) ||
          contact.cc.includes(EMP_MSG_TYPE__ALL_ID)
        ) { return true; }
        if (
          contact.bcc.includes(EMP_MSG_TYPE__REMINDER_ID) ||
          contact.bcc.includes(EMP_MSG_TYPE__ALL_ID)
        ) { return true; }
      }
      return false;
    })
  );
  const _key = `openJobTodoReminderEmailPreview__${jobId}`;
  const _processedEmailData = await TemplateLib.getRender({
    templateName: 'employer-todo-reminder',
    employerId,
    jobId,
    accountId: contact.accountId
  });
  const { errors = [], rendered = {}, mixins = {} } = _processedEmailData;
  if (!errors.length) {
    const { bodyHtml: body } = rendered;
    const _postProcessingTemplate = Arr(Str(body).match(/<!-- POST_PROCESSING >>> -->([\s\S]*?)<!-- <<< POST_PROCESSING -->/))[0];
    const _body = Str(body).replace(_postProcessingTemplate, '');
    const {
      from,
      emails = [],
      to = [],
      cc = [],
      bcc = [],
      engagements = []
    } = mixins;
    showConfirm({
      title: <>Job Reminder</>,
      content: (
        <EmailPreview
          ref={(self) => (self && Core.setKeyValue(_key, self))}
          emails={emails}
          from={from}
          to={to}
          cc={cc}
          bcc={bcc}
          subject={`${jobTitle} - 10x10 Pending Candidates Who Need Your Attention`}
          body={_body}
        />
      ),
      onAcceptLabel: <>Send</>,
      onAccept: async (event) => {
        try {
          const _emailParameters = Core.getKeyValue(_key).getParams();
          _emailParameters.html = `${Str(_postProcessingTemplate)}${Str(_emailParameters.html)}`;
          await sendSafeEmail({
            ..._emailParameters,
            source: _key
          }).then(response => {
            Core.showSuccess('Email sent');
            if (!!response?.threadId) {
              const next = em => {
                if (!!engagements.length) {
                  const engagement = engagements.pop();
                  Streak.putEmailInBox(
                    {
                      boxKey: engagement.boxKey,
                      threadGmailId: response.threadId
                    },
                    response => {
                      updateReminderSentDates({
                        engagement,
                        next: () => { },
                        reminder: {
                          date: new Date().toISOString(), // date - time reminder was set
                          source: 'employer', // source of email, e.g. job, employer, recruiter, agency, null (for candidate)
                        }
                      });
                    }
                  );
                }
              };
              next();
            }
          });
        }
        catch (error) {
          Core.showError(error);
        }
      },
      paperStyle: {
        width: 1200,
      },
    });
  }
  else {
    Core.showError(`${errors.map(n => n.message).join(', ')}`);
  }
}
