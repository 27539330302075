import {
  uniqueId
} from 'lodash';
import {
  STAGE_REVIEW
} from '../../../../dictionaries/Engagement.dic';
import Engagement from '../../../../lib/Engagement';
import getJobOwnershipTextAndColor from '../../../../lib/GetJobOwnershipTextAndColor';
import {
  Obj
} from '../../../../lib/Object.lib';
import Chip from '../../../Layout/Wrappers/Chip';
import {
  getMatchEntities,
  isJobBlackListed
} from '../MatchList.lib';
import { joinKeyName } from '../../../../lib/String.lib';

export default function getOwnershipChip(props) {

  const {
    job = {},
    candidate = {},
  } = getMatchEntities(props);

  const {
    engagements = [],
    jobOwnerships = []
  } = candidate;

  // GET label and color ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
  const isJobBlacklisted = isJobBlackListed(candidate, job);
  let { color, label } = getJobOwnershipTextAndColor(
    jobOwnerships,
    job.employerId,
    candidate.id
  );
  color = isJobBlacklisted ? colors.red.dark : color;
  label = isJobBlacklisted ? 'UnavailableToCando' : label;
  const results = [label];

  // GET priorSubText  ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
  let priorSubText = '';
  const order = Engagement.stageOrder;
  const engagement = Obj(
    engagements.find(
      (engagement) =>
        Obj(engagement.job).employerId === job.employerId &&
        order.indexOf(engagement.stage) >= order.indexOf(STAGE_REVIEW)
    )
  );
  const isStrongConflictWithEngagement = jobOwnerships.find(
    (objM) => {
      return (
        objM.isPotentialStrongConflict &&
        !!objM.conflictedEmployers.find(
          (obj) =>
            !!obj.hasEngagement &&
            obj.employerId === job.employerId
        )
      );
    }
  );
  if (!!engagement.id) {
    priorSubText = 'Prior Submission*';
  }
  if (!!isStrongConflictWithEngagement) {
    priorSubText = !!priorSubText.length
      ? priorSubText + '^'
      : 'Prior Submission^';
  }
  if (!!priorSubText.length) {
    results.push(priorSubText);
  }
  return results.map(
    (label) => (
      <Chip slim
        key={
          joinKeyName(
            [
              'ownership_chip',
              `job_${job.id}`,
              `candidate_${candidate.id}`,
              `label_${label}`,
              uniqueId()
            ]
          )
        }
        style={{ backgroundColor: color }}
        className='c-white px-1 mr-05 mb-05 f-md'
        /** 
         * @note 
         * Do not remove this attribute.
         * It is used to extract the label value.
         * @see ownerShipChipLabel on SingleCandidateCard & SingleJobCard.
         */
        data-label={label}
      >
        {label}
      </Chip>
    )
  );

};