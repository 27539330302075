import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Core from '../../lib/Core';
import { getLocationNoQuery, setLocation } from '../../lib/URL.lib';
import { REACT_TABLE__DEFAULT_PAGE_SIZE, REACT_TABLE__INITIAL_PAGE } from "./useEnhancedReactTable.hook";
import { useUpdateState } from './useUpdateState.hook';

export const URI_COMMA_CHAR = '~';
export const URI_COLON_CHAR = '_';

export function encodeUrlArray(arr) {
  return `${URI_COMMA_CHAR}${arr.join(URI_COMMA_CHAR)}`;
}

export function encodeUrlObject(obj) {
  return encodeUrlArray(Object.keys(obj).map(key => `${key}${URI_COLON_CHAR}${obj[key]}`));
}

export function newUrlState() {
  return (
    {
      globalSearchValue: [],
      filters: {},
      sort: {},
      page: REACT_TABLE__INITIAL_PAGE,
      pageSize: REACT_TABLE__DEFAULT_PAGE_SIZE,
      resume: undefined,
      looseMatch: undefined,
      blacklisted: undefined,
      _updatedAt: undefined,
    }
  );
}

export function useUrlQuery({ context, onUpdate = console.debug }) {

  const SEARCH_KEY = 's';
  const PAGE_KEY = 'p';
  const PAGE_SIZE_KEY = 'l';
  const SORT_KEY = 'o';
  const RESUME_KEY = 'cv';
  const LOOSE_MATCH_KEY = 'looseMatch';
  const BLACKLISTED_KEY = 'blacklisted';
  const UPDATE_KEY = 'u';

  const { pathname, search } = useLocation();

  let current = `${pathname}${search}`;

  const { state, updateState } = useUpdateState(newUrlState());
  const [prev, setPrev] = useState('');

  // sample: http://localhost:3001/#/pro/engagements?p=1&l=10&sort=~firstName_1~lastName_-1

  // this is triggered only when url change
  // this reconstruct the state based in query params
  useEffect(() => {
    if (current !== prev) {
      try {
        let query = new URLSearchParams(search);
        let _state = newUrlState();
        for (var key of query.keys()) {
          let value = query.get(key);
          if (!value) { continue; }
          let valueIsArray = value.includes(URI_COMMA_CHAR);
          let keyIsSearch = (key === SEARCH_KEY);
          let keyIsPage = (key === PAGE_KEY);
          let keyIsPageSize = (key === PAGE_SIZE_KEY);
          let keyIsSort = (key === SORT_KEY);
          let keyIsResume = (key === RESUME_KEY);
          let keyIsLooseMatch = (key === LOOSE_MATCH_KEY);
          let keyIsBlacklisted = (key === BLACKLISTED_KEY);
          let keyIsDataUpdate = (key === UPDATE_KEY);
          // console.debug('pair', key, value);
          if (keyIsSearch || valueIsArray) {
            value = value.split(URI_COMMA_CHAR).filter(v => !!v).map(v => !isNaN(v) ? Number(v) : v);
          }
          if (keyIsDataUpdate) {
            _state._updatedAt = value;
          }
          else if (keyIsSearch) {
            _state.globalSearchValue = value;
          }
          else if (keyIsSort) {
            value.forEach(value => {
              value = value.split(URI_COLON_CHAR);
              _state.sort[value[0]] = parseInt(value[1]);
            });
          }
          else if (keyIsResume) {
            _state.resume = value.match(/true/i) ? true : value.match(/false/i) ? false : undefined;
          }
          else if (keyIsLooseMatch) {
            _state.looseMatch = value.match(/true/i) ? true : value.match(/false/i) ? false : undefined;
          }
          else if (keyIsBlacklisted) {
            _state.blacklisted = value.match(/true/i) ? true : value.match(/false/i) ? false : undefined;
          }
          else if (keyIsPage) {
            _state.page = parseInt(value);
            if (!_state.page || _state.page < 1) {
              _state.page = 1;
            }
          }
          else if (keyIsPageSize) {
            _state.pageSize = parseInt(value);
            _state.pageSize = (
              (_state.pageSize > 200)
                ? 200
                : (_state.pageSize < 1)
                  ? 1
                  : _state.pageSize
            );
          }
          else {
            try {
              if (!valueIsArray) {
                value = JSON.parse(value);
              }
              if (!valueIsArray || !!value.length) {
                _state.filters[key] = value;
              }
              else {
                delete _state.filters[key];
              }
            }
            catch {
              console.debug('failed trying to parse filter', key, value);
            }
          }
        }

        // console.debug(_state);

        updateState(_state);
        onUpdate(_state);

      }
      catch (ex) {
        Core.showError(`Fail to parse state from url`);
      }
    }
    setPrev(current);
  }, [pathname, current, prev, updateState, state, search, onUpdate]);

  function setSearchQuery(searchQuery = '') {
    setLocation(`${getLocationNoQuery()}${searchQuery}`);
  }

  // this change the url query params based in the state purposed
  function updateUrlState(newState) {
    if (newState instanceof Function) { newState = newState(state); }
    let query = new URLSearchParams('');
    let _state = { ...state, ...newState };
    let {
      filters = {},
      globalSearchValue = [],
      page = REACT_TABLE__INITIAL_PAGE,
      pageSize = REACT_TABLE__DEFAULT_PAGE_SIZE,
      sort = {},
      resume,
      looseMatch,
      blacklisted,
      _updatedAt
    } = _state;
    if (_updatedAt) {
      query.set(UPDATE_KEY, _updatedAt);
    }
    if (globalSearchValue.length) {
      query.set(SEARCH_KEY, globalSearchValue.join(URI_COMMA_CHAR));
    }
    query.set(PAGE_KEY, page);
    query.set(PAGE_SIZE_KEY, pageSize);
    Object.keys(filters).forEach(key => {
      let _keyFilters;
      if (Array.isArray(filters[key])) {
        _keyFilters = encodeUrlArray(filters[key]);
        if (_keyFilters.length > 1) {
          query.set(key, _keyFilters);
        }
      }
      else {
        _keyFilters = JSON.stringify(filters[key]);
        query.set(key, _keyFilters)
        if (_keyFilters) {
          query.set(key, _keyFilters);
        }
      }
    });
    let _sort = encodeUrlObject(sort);
    if (_sort.length > 1) {
      query.set(SORT_KEY, _sort);
    }
    if (resume !== undefined) {
      query.set(RESUME_KEY, resume);
    }
    if (looseMatch !== undefined) {
      query.set(LOOSE_MATCH_KEY, looseMatch);
    }
    if (blacklisted !== undefined) {
      query.set(BLACKLISTED_KEY, blacklisted);
    }
    setLocation(`${getLocationNoQuery()}?${query.toString()}`);
    return _state;
  }

  // to set updateUrlState from current live instance hook in a global context.
  Core.setKeyValue('updateUrlState', updateUrlState);

  let urlQueryContext = { urlState: state, updateUrlState, setSearchQuery };

  // console.debug('current', current, prev);

  return urlQueryContext;

}
