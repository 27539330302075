import {
  Arr
} from '../../../../lib/Array.lib';
import {
  NOT
} from '../../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__TECHNICAL_SKILLS
} from '../../../../lib/Definition';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  COLLECTION__EMPLOYERS,
  readLoopbackRecord
} from '../../../../lib/services/BE/loopback.api';

const cache = {};

export async function fetchEmployerSkills({
  employerId
}) {
  if (NOT(employerId)) { return {}; }
  if (NOT(cache[employerId])) {
    cache[employerId] = Obj(
      await readLoopbackRecord({
        collection: COLLECTION__EMPLOYERS,
        where: { id: employerId },
        limit: 1,
        fields: ['technicalSkills']
      })
    );
  }
  return cache[employerId];
}

export async function fetchTagIdEmployerSkills({
  employerId
}) {
  return Arr(
    Obj(
      await fetchEmployerSkills({ employerId })
    ).technicalSkills
  );
}

export async function fetchTagLabelEmployerSkills({
  employerId
}) {
  return Definition.getLabels(
    DEFINITION_CATEGORY__TECHNICAL_SKILLS,
    await fetchTagIdEmployerSkills({ employerId })
  );
}
