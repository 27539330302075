import moment from 'moment';
import {
  useRef,
  useState
} from 'react';
import {
  TIMEZONE__LA
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  SUGGESTION_TYPE__SCHOOL
} from '../../../lib/Crunchbase.lib';
import Definition, {
  DEFINITION_CATEGORY__UNDERGRADUATE_DEGREE
} from '../../../lib/Definition';
import {
  setCandidateSchool
} from '../../../lib/models/candidate';
import {
  evalDefaultDegree
} from '../../../lib/School';
import {
  trim
} from '../../../lib/String.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorChipMessage,
  InfoChipMessage
} from '../../Layout/Wrappers/Message';
import Row from "../../Layout/Wrappers/Row";
import TextField from '../../Layout/Wrappers/TextField';
import YearsDropdown from "../../Shared/YearsDropdown";
import {
  evalDateTroubles
} from '../CandidateHistories.lib';

export default function EducationDegree({
  index = -1,
  model = {},
  onChange = () => null,
  onDelete = () => null,
  getContext = () => ({})
}) {

  const busy = useRef();
  let [state, setState] = useState({ degree: model });
  async function _updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }

  let {
    degree,
    showDegreeError,
    showTroubleDateError
  } = state;

  let {
    candidate = {},
    education = {},
    attemptToClose
  } = getContext();

  let {
    degreeName,
    degreeMajor,
    startDateMonth,
    startDateYear,
    endDateMonth,
    endDateYear
  } = degree;

  let elementId = `degree-${degree.id}`;

  function _onChange(degree) {
    if (
      evalDefaultDegree({
        candidate,
        education,
        degree
      })
    ) {
      setCandidateSchool({
        candidate,
        education,
        degree
      });
    }
    onChange(degree);
  }

  async function _evalDegree(degreeName) {
    return _updateState({
      showDegreeError: !trim(degreeName),
    });
  }

  async function _evalDates(degree) {
    return _updateState({
      showTroubleDateError: evalDateTroubles(degree)
    });
  }

  async function _scrollIntoView() {
    return document.getElementById(elementId)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  async function _evalErrors() {
    await _evalDegree(degreeName);
    await _evalDates(degree);
  }

  if (attemptToClose && !busy.current) {
    busy.current = true;
    _evalErrors().then(async () => {
      if (
        !!showDegreeError ||
        !!showTroubleDateError
      ) {
        await _scrollIntoView();
      }
      busy.current = false;
    });
  }

  let __listOfDegrees = Definition.get('undergraduateDegree');

  return (
    <Box column className='rounded bg-main mr-1 mb-1 py-05'>

      <Fieldset
        title='Degree'
        dot='red'
        actionBar={
          <>
            {(
              evalDefaultDegree({
                candidate,
                education,
                degree
              })
                ? (
                  <InfoChipMessage color='secondary' icon={false}>
                    Most relevant
                  </InfoChipMessage>
                )
                : (
                  degreeName
                    ? (
                      <Box
                        title={degreeName ? 'Set this degree as most relevant' : 'Choose a degree to enable this button'}
                      >
                        <Button small
                          onClick={async event => {
                            if (candidate.undergraduateSchool) {
                              Core.showConfirm({
                                title: 'Confirm',
                                content: (
                                  <Box column w100 className='f-lg'>
                                    <div>
                                      Another degree is currently set as the candidate’s most relevant degree:
                                    </div>
                                    <strong>
                                      {[candidate._undergraduateDegree, candidate.undergraduateMajor, candidate.undergraduateSchool].filter(v => !!v).join(' - ')}
                                    </strong>
                                    <div>
                                      Do you wish to update this candidate’s most relevant degree?
                                    </div>
                                  </Box>
                                ),
                                async onAccept() {
                                  setCandidateSchool({
                                    candidate,
                                    education,
                                    degree
                                  });
                                  await _updateState({ degree });
                                  _onChange(degree);
                                },
                                onAcceptLabel: 'Update'
                              });
                            }
                            else {
                              setCandidateSchool({
                                candidate,
                                education,
                                degree
                              });
                              await _updateState({ degree });
                              _onChange(degree);
                            }
                          }}
                          disabled={!degreeName}
                        >
                          Set as most relevant
                        </Button>
                      </Box>
                    ) : (
                      <></>
                    )
                )
            )}
            <IconButton
              title='Remove degree'
              onClick={onDelete}
              icon='delete'
            />
          </>
        }
      >
        <Menu dropdown
          value={
            Definition.getId('undergraduateDegree', degreeName) ||
            degreeName
          }
          renderSelected={({ selected }) => {
            let tag = __listOfDegrees.find(({ id }) => id === selected.id);
            return (tag
              ? tag.label
              : selected.label
            );
          }}
          onChange={async (undergraduateDegreeId) => {
            education.__forceAutomaticallyDerive = true;
            degree.degreeName = Definition.getLabel(
              DEFINITION_CATEGORY__UNDERGRADUATE_DEGREE,
              undergraduateDegreeId
            );
            await _updateState({ degree });
            await _evalDegree(degree.degreeName);
            _onChange(degree);
          }}
          onClose={async event => await _evalDegree(degreeName)}
          options={__listOfDegrees}
        />
        <ErrorChipMessage show={!!showDegreeError} className='mt-1'>
          Degree is a required field
        </ErrorChipMessage>
      </Fieldset>

      <Fieldset
        title='Major'
      >
        <TextField
          role='EducationDegreeMajor'
          value={degreeMajor}
          onChange={async event => {
            education.__forceAutomaticallyDerive = true;
            degree.degreeMajor = event.target.value;
            await _updateState({ degree });
          }}
          onBlur={() => _onChange(degree)}
        />
      </Fieldset>

      <Row>

        <Fieldset
          title='Start date'
        >
          <YearsDropdown
            month={startDateMonth}
            year={startDateYear}
            onChangeHandler={async (startDateMonth, startDateYear) => {
              education.__forceAutomaticallyDerive = true;
              degree.startDateMonth = startDateMonth;
              degree.startDateYear = startDateYear;
              degree.startDate = (
                degree.startDateYear
                  ? moment(
                    `${degree.startDateYear}${degree.startDateMonth ? `-${String(degree.startDateMonth).padStart(2, '0')}` : ''}`
                  ).tz(TIMEZONE__LA).toISOString()
                  : null
              );
              await _updateState({ degree });
              await _evalDates(degree);
              _onChange(degree);
            }}
            onClose={async event => await _evalDates(degree)}
          />
        </Fieldset>

        <Fieldset
          title='End date'
        >
          <YearsDropdown
            month={endDateMonth}
            year={endDateYear}
            onChangeHandler={async (endDateMonth, endDateYear) => {
              education.__forceAutomaticallyDerive = true;
              degree.endDateMonth = endDateMonth;
              degree.endDateYear = endDateYear;
              degree.endDate = (
                degree.endDateYear
                  ? moment(
                    `${degree.endDateYear}${degree.endDateMonth ? `-${String(degree.endDateMonth).padStart(2, '0')}` : ''}`
                  ).tz(TIMEZONE__LA).toISOString()
                  : null
              );
              await _updateState({ degree });
              await _evalDates(degree);
              _onChange(degree);
            }}
            onClose={async event => await _evalDates(degree)}
            type={SUGGESTION_TYPE__SCHOOL}
          />
        </Fieldset>

      </Row>
      <ErrorChipMessage show={!!showTroubleDateError}>
        End date can’t be earlier than start date
      </ErrorChipMessage>


    </Box>
  );
}
