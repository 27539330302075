import { capitalize } from 'lodash';
import moment from 'moment';
import dig from "object-dig";
import { SOVREN_PARSER_URL } from "../lib/Constants";
import Core from "./Core";
import Definition from './Definition';
import Http from "./Http";
import { Str } from './String.lib';
import Base64 from './base64';
import formatURL from './tools/formatURL';

export const RESUME_TYPE__CV = 'curriculum_vitae';
export const RESUME_TYPE__LINKEDIN_PDF = 'linkedin_pdf';

const getData = async (filter, onSuccess) => {
  return Http.get(
    Core.getApi("SovrenData/"),
    { filter: JSON.stringify(filter) },
    onSuccess
  );
};

const normalizeEntityName = (name) => {
  const normalizeWord = word => {
    if (word.includes('-')) { return word.split(/-/).map(normalizeWord).join('-'); }
    const formattedName = capitalize(word);
    const mcFormattedName = formattedName.replace(/\bMc(\w)/g, (match, p1) => "Mc" + p1.toUpperCase());
    const macFormattedName = mcFormattedName.replace(/\bMac(\w)/g, (match, p1) => "Mac" + p1.toUpperCase());
    const apostropheFormattedName = macFormattedName.replace(/(O|D)(’|')(\w)/g, (match, p1, p2, p3) => `${p1}'${capitalize(p3)}`);
    const parenthesisFormattedName = apostropheFormattedName.replace(/\((\w+)\)/g, (match, p1) => `(${capitalize(p1)})`);
    return parenthesisFormattedName;
  }
  return Str(name).split(/ |_/).map(normalizeWord).join(' ');
};
// window.normalizeEntityName = normalizeEntityName;

const SovrenData = {
  get: getData,

  post: (data, success) => {
    Http.post(
      Core.getApi("SovrenData/"),
      data,
      success
    );
  },

  update: (id, data) => {
    Http.patch(
      Core.getApi("SovrenData/" + id),
      data,
      response => {

      }
    )
  },

  destroyData: async ({ resume = {}, candidate = {}, callback = sovrenData => { } }) => {
    console.debug(`SovrenData.destroyData`, resume.url);
    if (!candidate.sovrenDataId) {
      console.debug(`NO found sovrenDataId in candidate`);
      return callback();
    }
    return Http.delete(
      Core.getApi(`SovrenData/_deleteResume/${candidate.sovrenDataId}`),
      { candidateId: candidate.id }
    ).catch(Core.showError).then((result) => callback(candidate.sovrenDataId));
  },

  getAccountInfo: (cb) => {

    const headers = {
      "Accept": "application/json",
      "Sovren-AccountId": "16174178",
      "Sovren-ServiceKey": "PGlFJgq2nVOGVVdUokFAbc8EUpnsQRWc2MOg9pVS"
    };

    Http.get(
      `${SOVREN_PARSER_URL}/account`,
      { headers },
      (response) => {
        cb(response);
      }
    );
  },

  /**
   * 
   * @param {object} response Response from api SovrenData._parseResume
   * @returns {promise} parsedResume
   * @createdAt 2022-03-15 - Modified from on previous code
   */
  mapParseResumeResponse: (response, resumeType) => {
    const responseData = response;
    const responseSummary = response.userArea;
    const getContactDetails = () => {

      // Use OR for diff formats because there are already some records saved with wrong DB name format
      let linkedInURL = formatURL(responseData.linkedInURL || responseData.linkedInUrl || '');
      let gitHubURL = formatURL(responseData.gitHubURL || responseData.githubUrl || '');

      let phone = responseData.phone || '';
      let email = responseData.email || '';

      /** @todo Deprecated, remove after CB release | 2022-08-31 µ */
      /** * /
      const baseArray = dig(responseData, "contactInfo", "ContactMethod");
      if (!!baseArray) {
        // Search for contact information we need
        baseArray.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            if ((key === "Telephone" || key === "Mobile") && phone === "") {
              // save the first phone number we find, ignore Fax, Pager and TTYTDD numbers
              phone = obj[key].FormattedNumber;
            } else if (key === "InternetEmailAddress" && email === "") {
              // save the first email address we find
              email = obj[key];
            } else if (/linked/i.test(obj[key]) && linkedInURL === '') {
              linkedInURL = formatURL(obj[key]);
            } else if (/github/i.test(obj[key]) && gitHubURL === '') {
              gitHubURL = formatURL(obj[key]);
            }
          });
        });
      }
      /** */
      return { gitHubURL, email, linkedInURL, phone };
    };
    /**
     *  Pulls urls identified by sovren parser.  Returns a string of concatenated urls
     */
    const getOtherUrls = () => {
      const baseArray = dig(
        responseSummary,
        "sov:ResumeUserArea",
        "sov:ReservedData",
        "sov:Urls",
        "sov:Url"
      );
      let otherLinks = [];
      let stackoverflowUrl = "";

      if (!!baseArray) {
        baseArray.forEach((obj) => {
          if (/stackoverflow/i.test(obj)) {
            stackoverflowUrl = formatURL(obj);
          }
          if (
            !/linkedin/i.test(obj) &&
            !/github/i.test(obj) &&
            !/stackoverflow/i.test(obj)
          ) {
            otherLinks.push(obj);
          }
        });
      }
      return { otherLinks: otherLinks.join(","), stackoverflowUrl };
    };

    /**
     *  Returns a string that can be "current", a date in the format "Year" or "Month Year", or null
     *  Dates have their time set to 00:00:00.000Z (UTC) using moment.utc
     *  This way the Date will be correct regardless of the user's time zone
     */
    const toDate = (type, date, format = "MMMM YYYY") => {
      if (!date || date === "notKnown" || date === "notApplicable") {
        return null;
      } else if (type === "StringDate") {
        return "current";
      } else {
        let newDate = null;
        switch (type) {
          case "Year":
          case "YearMonth":
          case "AnyDate":
            newDate = moment.utc(date).format(format);
            break;
          default:
        }
        return !!newDate ? newDate : null;
      }
    };

    const toMonthYear = (type, date) => {
      if (!date) {
        return null;
      }

      if (type === "YearMonth") {
        let split = date.split("-");
        return { month: +split[1], year: +split[0] };
      } else if (type === "Year") {
        return { month: null, year: +date };
      } else if (type === "AnyDate" && moment(date).isValid()) {
        let split = date.split("-");
        return { month: +split[1], year: +split[0] };
      } else {
        return { month: null, year: null };
      }
    };

    const getCurrentEmployerDetails = () => {
      let lastEmployer = Object(
        (dig(responseData, "employmentHistory", "EmployerOrg") || [])[0]
      );

      if (!lastEmployer) {
        return {
          currentEmployer: "",
          currentTitle: "",
          currentEmployerFrom: "",
          currentEmployerTo: "",
          yearsOfExperience: "",
          currentlyEmployed: "",
        };
      }

      let currentEmployer = normalizeEntityName(lastEmployer.EmployerOrgName);
      let currentTitle =
        dig(lastEmployer, "PositionHistory", [0], "Title") || ""; //job title
      let currentEmployerFrom =
        toDate(
          "YearMonth",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "YearMonth")
        ) ||
        toDate(
          "Year",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "Year"),
          "YYYY"
        ) ||
        toDate(
          "StringDate",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "StringDate")
        ) ||
        toDate(
          "AnyDate",
          dig(lastEmployer, "PositionHistory", [0], "StartDate", "AnyDate")
        ) ||
        "";
      let currentEmployerTo =
        toDate(
          "YearMonth",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "YearMonth")
        ) ||
        toDate(
          "Year",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "Year"),
          "YYYY"
        ) ||
        toDate(
          "StringDate",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "StringDate")
        ) ||
        toDate(
          "AnyDate",
          dig(lastEmployer, "PositionHistory", [0], "EndDate", "AnyDate")
        ) ||
        "";

      // Let's get the parser total months of experience
      let parserTotalMonthsOfExp = null;
      let adjustedTotalYearsOfExp = null;
      let yearsOfExperience = null;
      let monthsOfExp =
        dig(
          responseSummary,
          "sov:ResumeUserArea",
          "sov:ExperienceSummary",
          "sov:MonthsOfWorkExperience"
        ) || "";

      if (!!monthsOfExp) {
        parserTotalMonthsOfExp = parseInt(monthsOfExp);
        adjustedTotalYearsOfExp =
          Math.round((parserTotalMonthsOfExp / 12) * 10) / 10; // Round to the nearest 0.1 year
        yearsOfExperience = adjustedTotalYearsOfExp;
      }

      let currentlyEmployed = 2;

      if (currentEmployerTo === "current") {
        currentlyEmployed = 1;
      }

      return {
        currentEmployer,
        currentTitle,
        currentEmployerFrom,
        currentEmployerTo,
        yearsOfExperience,
        currentlyEmployed,
        parserTotalMonthsOfExp,
        adjustedTotalYearsOfExp,
      };
    };

    const getCurrentSchoolDetails = () => {
      let lastEducation = Object(
        dig(responseData, "educationHistory", "SchoolOrInstitution", [0])
      );

      if (!lastEducation) {
        return {
          degreeType: "",
          undergraduateDegree: "",
          undergraduateMajor: "",
          undergraduateSchool: "",
          graduationYear: "",
        };
      }

      let lastDegree = dig(lastEducation, "Degree", [0]);

      if (!lastDegree) {
        return {
          degreeType: "",
          undergraduateDegree: "",
          undergraduateMajor: "",
          undergraduateSchool: "",
          graduationYear: "",
        };
      }

      const degreeType = lastDegree["@degreeType"];
      let undergraduateDegree =
        dig(
          lastDegree,
          "UserArea",
          "sov:DegreeUserArea",
          "sov:NormalizedDegreeName"
        ) || "";
      undergraduateDegree = Definition.getId(
        "undergraduateDegree",
        undergraduateDegree
      );

      let undergraduateMajor = (Object((lastDegree.DegreeMajor || [])[0])
        .Name || [])[0];
      let graduationYear =
        toDate("YearMonth", dig(lastDegree, "DegreeDate", "YearMonth")) ||
        toDate("Year", dig(lastDegree, "DegreeDate", "Year"), "YYYY") ||
        toDate("StringDate", dig(lastDegree, "DegreeDate", "StringDate")) ||
        toDate("AnyDate", dig(lastDegree, "DegreeDate", "AnyDate")) ||
        "";
      let undergraduateSchool = normalizeEntityName(
        dig(
          lastEducation,
          "UserArea",
          "sov:SchoolOrInstitutionTypeUserArea",
          "sov:NormalizedSchoolName"
        ) || ""
      );
      return {
        degreeType,
        undergraduateDegree,
        undergraduateMajor,
        undergraduateSchool,
        graduationYear,
      };
    };

    const getEmploymentHistory = () => {
      let employmentHistory = dig(
        responseData,
        "employmentHistory",
        "EmployerOrg"
      );

      if (!employmentHistory) {
        return [];
      }

      return employmentHistory.map((experience, index) => {
        let timestampTemporary = Math.random().toString(36);

        return {
          id: index,
          companyId: experience.companyId,
          employerOrgName: normalizeEntityName(
            dig(
              experience,
              "UserArea",
              "sov:EmployerOrgUserArea",
              "sov:NormalizedEmployerOrgName"
            )
          ),
          isCurrentEmployer: (dig(experience, "PositionHistory") || []).some(
            (xp) => xp["@currentEmployer"] === "true"
          ),
          timestampTemporary,
          positionHistories: (dig(experience, "PositionHistory") || []).map(
            (position, index) => {
              // Get the startDate String
              let startDate = null;
              let startDateString =
                toDate("YearMonth", dig(position, "StartDate", "YearMonth")) ||
                toDate("Year", dig(position, "StartDate", "Year"), "YYYY") ||
                toDate(
                  "StringDate",
                  dig(position, "StartDate", "StringDate")
                ) ||
                toDate("AnyDate", dig(position, "StartDate", "AnyDate"));

              // get the date from the date string
              if (!!startDateString && startDateString !== "current") {
                startDate = moment.utc(startDateString); // creates a date in UTC to avoid timezone changes
              } else {
                startDate = null;
              }

              // Get the endDate String
              let endDate = null;
              let endDateString =
                toDate("YearMonth", dig(position, "EndDate", "YearMonth")) ||
                toDate("Year", dig(position, "EndDate", "Year"), "YYYY") ||
                toDate("StringDate", dig(position, "EndDate", "StringDate")) ||
                toDate("AnyDate", dig(position, "EndDate", "AnyDate"));

              // Check the currently employed status
              let currentlyEmployed = 2;

              if (!!endDateString && endDateString !== "current") {
                endDate = moment.utc(endDateString); // creates a date in UTC to avoid timezone changes
              } else {
                currentlyEmployed = 1;
                endDate = null;
              }

              let startYearMonthObject = toMonthYear(
                "YearMonth",
                dig(position, "StartDate", "YearMonth")
              ) ||
                toMonthYear("Year", dig(position, "StartDate", "Year")) ||
                toMonthYear(
                  "StringDate",
                  dig(position, "StartDate", "StringDate")
                ) ||
                toMonthYear(
                  "AnyDate",
                  dig(position, "StartDate", "AnyDate")
                ) || { month: null, year: null };

              let endYearMonthObject = toMonthYear(
                "YearMonth",
                dig(position, "EndDate", "YearMonth")
              ) ||
                toMonthYear("Year", dig(position, "EndDate", "Year")) ||
                toMonthYear(
                  "StringDate",
                  dig(position, "EndDate", "StringDate")
                ) ||
                toMonthYear("AnyDate", dig(position, "EndDate", "AnyDate")) || {
                month: null,
                year: null,
              };

              return {
                id: index,
                positionType: position["@positionType"],
                title: dig(
                  position,
                  "UserArea",
                  "sov:PositionHistoryUserArea",
                  "sov:NormalizedTitle"
                ),
                description: position.Description,
                startDate,
                endDate,
                startDateMonth: startYearMonthObject.month,
                startDateYear: startYearMonthObject.year,
                endDateMonth: endYearMonthObject.month,
                endDateYear: endYearMonthObject.year,
                currentlyEmployed,
                isSelfEmployed: dig(
                  position,
                  "UserArea",
                  "sov:PositionHistoryUserArea",
                  "sov:IsSelfEmployed"
                ),
              };
            }
          ),
        };
      });
    };

    const getEducationHistory = () => {
      let educationHistory = dig(
        responseData,
        "educationHistory",
        "SchoolOrInstitution"
      );
      if (!educationHistory) {
        return [];
      }
      return educationHistory.map((education, index) => {
        return {
          id: index,
          schoolId: education.schoolId,
          timestampTemporary: Math.random().toString(36),
          schoolName: normalizeEntityName(
            dig(
              education,
              "UserArea",
              "sov:SchoolOrInstitutionTypeUserArea",
              "sov:NormalizedSchoolName"
            )
          ),
          schoolType: dig(education, "@schoolType"),
          postalAddress: {
            countryCode: dig(education, "PostalAddress", "CountryCode"),
            region: dig(education, "PostalAddress", "CountryCode"),
            municipality: dig(education, "PostalAddress", "Municipality"),
          },
          degrees: (dig(education, "Degree") || []).map((degree, index) => {
            const startDate = (degree.DatesOfAttendance || [])[0] || {};
            const degreeType = degree["@degreeType"];

            let degreeStartDate = null;
            let startDateString =
              toDate("YearMonth", dig(startDate, "StartDate", "YearMonth")) ||
              toDate("Year", dig(startDate, "StartDate", "Year"), "YYYY") ||
              toDate("StringDate", dig(startDate, "StartDate", "StringDate")) ||
              toDate("AnyDate", dig(startDate, "StartDate", "AnyDate"));
            // now get a date from the string
            if (!!startDateString && startDateString !== "current") {
              degreeStartDate = moment.utc(startDateString); // creates a date in UTC to avoid timezone changes
            } else {
              degreeStartDate = null;
            }

            let startYearMonthObject = toMonthYear(
              "YearMonth",
              dig(startDate, "StartDate", "YearMonth")
            ) ||
              toMonthYear("Year", dig(startDate, "StartDate", "Year")) ||
              toMonthYear(
                "StringDate",
                dig(startDate, "StartDate", "StringDate")
              ) ||
              toMonthYear(
                "AnyDate",
                dig(startDate, "StartDate", "AnyDate")
              ) || { month: null, year: null };

            let degreeEndDate = null;
            let endDateString =
              toDate("YearMonth", dig(degree, "DegreeDate", "YearMonth")) ||
              toDate("Year", dig(degree, "DegreeDate", "Year"), "YYYY") ||
              toDate("StringDate", dig(degree, "DegreeDate", "StringDate")) ||
              toDate("AnyDate", dig(degree, "DegreeDate", "AnyDate"));

            // now get a date from the string
            if (!!endDateString && endDateString !== "current") {
              degreeEndDate = moment.utc(endDateString); // creates a date in UTC to avoid timezone changes
            } else {
              degreeEndDate = null;
            }

            let endYearMonthObject = toMonthYear(
              "YearMonth",
              dig(degree, "DegreeDate", "YearMonth")
            ) ||
              toMonthYear("Year", dig(degree, "DegreeDate", "Year")) ||
              toMonthYear(
                "StringDate",
                dig(degree, "DegreeDate", "StringDate")
              ) ||
              toMonthYear("AnyDate", dig(degree, "DegreeDate", "AnyDate")) || {
              month: null,
              year: null,
            };

            // Bob is inclined to use DegreeType but Omair thinks DegreeType might not always be available.
            // We need to give it time and see what is better

            return {
              id: index,
              degreeType,
              degreeName: dig(
                degree,
                "UserArea",
                "sov:DegreeUserArea",
                "sov:NormalizedDegreeName"
              ),
              degreeMajor:
                (Object((degree.DegreeMajor || [])[0]).Name || [])[0] || "",
              degreeMinor:
                (Object((degree.DegreeMinor || [])[0]).Name || [])[0] || "",
              startDate: degreeStartDate,
              endDate: degreeEndDate,
              startDateMonth: startYearMonthObject.month,
              startDateYear: startYearMonthObject.year,
              endDateMonth: endYearMonthObject.month,
              endDateYear: endYearMonthObject.year,
            };
          }),
        };
      });
    };

    let firstName = normalizeEntityName(
      dig(responseData, "contactInfo", "PersonName", "GivenName")
    );
    let lastName = normalizeEntityName(
      dig(responseData, "contactInfo", "PersonName", "FamilyName")
    );
    let country = dig(
      responseSummary,
      "sov:ResumeUserArea",
      "sov:Culture",
      "sov:Country"
    );
    let averageMonthsPerEmployer = dig(
      responseSummary,
      "sov:ResumeUserArea",
      "sov:ExperienceSummary",
      "sov:AverageMonthsPerEmployer"
    );

    if (!!country) {
      country = country.toUpperCase();
    } else {
      country = "parser-not-found";
    }

    console.log({ employmentHistory: getEmploymentHistory() });
    console.log({ educationHistory: getEducationHistory() });
    console.debug({
      dddd: {
        country,
        firstName,
        lastName,
        ...getContactDetails(),
        currentlyEmployed: 0,
        averageMonthsPerEmployer,
        employmentHistories: getEmploymentHistory(),
        educationHistories: getEducationHistory(),
        ...getCurrentEmployerDetails(),
        ...getCurrentSchoolDetails(),
        otherLinks: getOtherUrls(),
      },
    });

    const { otherLinks, stackoverflowUrl } = getOtherUrls();

    return (
      (resumeType === RESUME_TYPE__LINKEDIN_PDF)
        ? {
          employmentHistories: getEmploymentHistory(),
          educationHistories: getEducationHistory(),
        }
        : {
          country,
          firstName,
          lastName,
          ...getContactDetails(),
          currentlyEmployed: 0,
          averageMonthsPerEmployer,
          employmentHistories: getEmploymentHistory(),
          educationHistories: getEducationHistory(),
          ...getCurrentEmployerDetails(),
          ...getCurrentSchoolDetails(),
          otherLinks,
          stackoverflowUrl,
        }
    );
  },

  /**
   * 
   * @param {object} options
   * @param {string} options.resumeUrl
   * @param {blob} options.resume
   * @param {string} options.resumeType
   * @param {date} options.revisionDate
   * @returns {promise} {rawSovren, parsedResume}
   */
  async parseResume({
    resumeUrl,
    resume,
    revisionDate,
    resumeType = RESUME_TYPE__CV
  }) {
    let promise = new Promise(async (resolve, reject) => {
      if (resumeUrl) {
        await Http.post(
          Core.getApi("SovrenData/_parseResume"),
          {
            resumeUrl,
            revisionDate,
            resumeType
          }
        ).then(response => {
          resolve({
            rawSovren: response,
            parsedResume: SovrenData.mapParseResumeResponse(response, resumeType)
          });
        }).catch(message => {
          reject(message)
        });
      }
      else {
        let reader = new FileReader();
        reader.onload = async event => {
          await Http.post(
            Core.getApi("SovrenData/_parseResume"),
            {
              resumeBase64: Base64.encodeArray(event.target.result),
              revisionDate,
              resumeType
            }
          ).then(response => {
            resolve({
              rawSovren: response,
              parsedResume: SovrenData.mapParseResumeResponse(response, resumeType)
            });
          }).catch(message => {
            reject(message)
          });
        }
        reader.readAsArrayBuffer(resume);
      }
    });
    return promise;
  }

};

export default SovrenData;