//https://github.com/10By10/MatchingML/issues/49
// look at above url to see the preference
//Computer degree (3) > STEM computer related (16)
//Company: Top tier tech company (24) > Strong Tech Company (20) > Tech Company (31)
//School: Elite University (21) > Top Ranked (23) > Strong (26) > Ranked (22)
//Github: Impressive Github (29) > Great Github (27) > Strong Github (28) > Github

const DEGREE = {3: 10,16: 9};
const COMPANY = {24:10, 20:9, 31:8};
const SCHOOL = {21:10, 23:9, 26:8, 22:7};
const GITHUB = {29:10, 27:9, 28:8,7:7};
const UNICORN = {34:10, 18:9};

const checkRating = (jobId, candidateIds, type, priority) => {
    let rating = false;
    // if(!priority && jobId===23){
    //     debugger
    // }

    candidateIds.forEach(cand=> {
        if(Object.keys(type).map(key=>parseInt(key)).includes(cand) && !rating){
            if(!!priority){
                rating = type[jobId] >= type[cand];
            }
            else{
                rating = type[jobId] <= type[cand];
            }
        }

    });

    return rating;
}
// [23, 24, 26, 3, 30, 4, 5, 6, 7]
const result = (jobId, candidateIds, priority=null) => {
    if(Object.keys(DEGREE).map(key=>parseInt(key)).includes(jobId)){
        return checkRating(jobId, candidateIds, DEGREE, priority);
    }
    else if(Object.keys(COMPANY).map(key=>parseInt(key)).includes(jobId)){
        return checkRating(jobId, candidateIds, COMPANY, priority);
    }
    else if(Object.keys(SCHOOL).map(key=>parseInt(key)).includes(jobId)){
        return checkRating(jobId, candidateIds, SCHOOL, priority);
    }
    else if(Object.keys(GITHUB).map(key=>parseInt(key)).includes(jobId)){
        return checkRating(jobId, candidateIds, GITHUB, priority);
    } else if(Object.keys(UNICORN).map(key=>parseInt(key)).includes(jobId)){
        return checkRating(jobId, candidateIds, UNICORN, priority);
    } else {
        return !!candidateIds.includes(jobId) ? true : false;
    }
}

const PositiveSignals = {
    result
}

export default PositiveSignals;