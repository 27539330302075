import {
  Component
} from "react";
import Box from '../../../../../Layout/Wrappers/Box';
import IconButton from '../../../../../Layout/Wrappers/IconButton';
import ChipElement from "./ChipElement";
import ComplexElement from "./ComplexElement";
import PopupAdElement from "./PopupAdElement";
import TextElement from "./TextElement";

const ElementComponents = {
  chip: ChipElement,
  text: TextElement,
  complex: ComplexElement
};

class PrintSingleMustHave extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      element: this.props.element,
      showNewPopup: false,
      elementToEdit: this.props.element
    };
  }

  handlerEditedItem = (element) => {
    this.setState({
      showNewPopup: true,
      priorityType: element.type,
      elementToEdit: element
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.element !== this.props.element) {
      this.setState({
        element: this.props.element
      });
    }
  }

  onUpdate = (newElement) => {
    const { onDone, element } = this.props;
    let updatedElement = {
      ...element,
      ...newElement
    };

    this.setState(
      {
        element: updatedElement,
        showNewPopup: false
      },
      () => {
        !!onDone && onDone(updatedElement);
      }
    );
  };

  onElementUpdate = (newElement) => {
    const { onDone, element } = this.props;
    let updatedElement = {
      ...element,
      ...newElement
    };

    this.setState(
      {
        element: updatedElement
      },
      () => {
        !!onDone && onDone(updatedElement);
      }
    );
  };

  renderElementComponent = (el, type) => {
    let { elementToEdit } = this.state;
    const { onlyView, extraInfo } = this.props;
    type = !type ? "complex" : type === "chipTag" ? "chip" : type;
    let ElementComponent = ElementComponents[type];
    return (
      <ElementComponent
        element={el}
        elementToEdit={elementToEdit}
        onApply={this.onElementUpdate}
        onlyView={onlyView}
        toMatchWith={extraInfo.toMatchWith}
        candidateResume={extraInfo.candidateResume}
        pageSource={extraInfo.source}
      />
    );
  };

  render() {
    let { element, showNewPopup, elementToEdit, priorityType } = this.state;
    let { handlerDeleteItem, onlyView, extraInfo } = this.props;

    let type = element.type;

    if (["or", "and", "not"].includes(type)) {
      type = "complex";
    }

    if (type === "chipGroup") {
      type = "chip";
    }

    return (
      <Box row w100 noWrap scrollX
        role='SingleMustHave'
        className='flex-align-left-top'
        style={{}}
      >

        <IconButton acl={!onlyView}
          onClick={() => handlerDeleteItem(element)}
          icon='cancel'
          color='error'
        />

        <IconButton acl={!onlyView}
          onClick={() => this.handlerEditedItem(element)}
          icon='edit'
          className='mr-1'
          primary
        />

        <Box row wrap flex1>
          {this.renderElementComponent(element, type)}
        </Box>

        <Box acl={!!showNewPopup}>
          <PopupAdElement
            onCancel={() => {
              this.setState({ showNewPopup: false });
            }}
            onApply={this.onUpdate}
            priorityType={priorityType}
            elementToEdit={elementToEdit}
            extraInfo={extraInfo}
          />
        </Box>

      </Box>
    );
  }
}

export default PrintSingleMustHave;
