import { useState } from 'react';
import { getConfiguration, upsertConfiguration } from '../../Configuration.lib';
import Core from '../../Core';

const MEM = {};
let errors = [];

export default async function fetchConfigs() {
  errors = [];
  if (!Core.isAdmin()) { return null; }
  return fetchEducationConfig();
}

export async function fetchStreakConfig(name) {
  errors = [];
  MEM.streakPipelines = await getConfiguration({ key: 'streakPipelines' }).catch(errors.push);
  if (!MEM.streakPipelines?.id) {
    MEM.streakPipelines = await upsertConfiguration({ key: 'streakPipelines', value: {} }).catch(errors.push);
  }
  return (MEM.streakPipelines?.value || {})[name];
}

export async function fetchEducationConfig() {
  errors = [];
  MEM.candidateEducation = await getConfiguration({ key: 'candidateEducation' }).catch(errors.push);
  if (!MEM.candidateEducation?.id) {
    MEM.candidateEducation = await upsertConfiguration({ key: 'candidateEducation', value: getEducationConfigDefaultModel() }).catch(errors.push);
  }
  return MEM.candidateEducation?.value || getEducationConfigDefaultModel();
}

export function getEducationConfiguration() {
  return MEM.candidateEducation?.value || getEducationConfigDefaultModel();
}

export function useEducationConfig() {
  let [state, setState] = useState({});
  async function updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }
  async function setError(error) {
    return updateState({ error, loading: false, configuration: null });
  }
  let {
    configuration,
    loading: loadingConfig = false,
    error: errorConfig = false
  } = state;
  async function fetchConfig() {
    await updateState({ loading: true });
    let configuration = await getConfiguration({ key: 'candidateEducation' }).catch(setError);
    if (!configuration?.id || !!state.error) {
      return await updateState({
        loading: false,
        error: 'Configuration not found'
      });;
    }
    await updateState({
      configuration,
      loading: false,
      error: false
    });
  }
  async function updateConfig(update) {
    configuration = configuration || { key: 'candidateEducation', value: getEducationConfigDefaultModel() };
    configuration.value = {
      ...configuration.value,
      ...update
    }
    configuration = await upsertConfiguration(configuration).catch(setError);
    if (!configuration?.id || !!state.error) { return; }
    await updateState({
      configuration,
      loading: false,
      error: false
    });
  }
  MEM.candidateEducation = configuration;
  return {
    configuration: configuration?.value,
    loadingConfig,
    errorConfig,
    fetchConfig,
    updateConfig
  }
}

export function getEducationConfigDefaultModel() {
  return {
    exclusionList: [
      'Coursera',
      'Udemy',
      'Online',
      'On-line',
      'Certificate'
    ],
    stemList: [
      'Electrical',
      '^EE$',
      'Math',
      'Statistics',
      'Physics',
      'Mechanical Engineering'
    ],
    computerList: [
      'Computer',
      'Software'
    ],
    bootcampList: [
      'App Academy',
      'AppAcademy',
      'Bloc',
      'Boot Camp',
      'Bootcamp',
      'BrainStation',
      'Coding Boot Camp',
      'Coding Dojo',
      'Correlation One',
      'Epicodus',
      'Flatiron School',
      'Full-Stack',
      'Galvanize',
      'General Assembly',
      'Hack Reactor',
      'Hackbright',
      'Hackbright Academy',
      'Insight Data Science',
      'Ironhack',
      'Kickstart Coding',
      'Lambda School',
      'Magnimind Academy',
      'Online',
      'Tech Talent South',
      'The Tech Academy',
      'Thinkful',
      'Switchup',
      'Zero to deep learning',
    ]
  }
}