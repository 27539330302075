export const Store = {
  set: function storage(key, value) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Store
      var cache = [];
      localStorage.setItem(
        key,
        JSON.stringify(value, function(key, value) {
          if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Circular reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        })
      );
      cache = null; // Enable garbage collection
      // Retrieve
      return localStorage.getItem(key);
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  },
  get: function storage(key) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Retrieve
      const result = localStorage.getItem(key);
      try {
        if (result) {
          return JSON.parse(result);
        } else {
          return result;
        }
      } catch (ex) {
        console.warn(ex, result);
        return result;
      }
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  },
  remove: function storage(key) {
    // Check browser support
    if (typeof Storage !== "undefined") {
      // Retrieve
      return localStorage.removeItem(key);
    } else {
      console.warn("Sorry, your browser does not support Web Storage...");
      return;
    }
  }
};
export default Store;
