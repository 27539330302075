import Box from '../components/Layout/Wrappers/Box';
import Button from '../components/Layout/Wrappers/Button';
import Divider from '../components/Layout/Wrappers/Divider';
import {
  Html
} from '../components/Layout/Wrappers/Html';
import Typography from '../components/Layout/Wrappers/Typography';
import Core from "./Core";
import Definition, {
  PLATFORM_RATING__A_PLUS,
  PLATFORM_RATING__A_TOP,
  PLATFORM_RATING__B_STRONG,
  PLATFORM_RATING__C_GOOD,
  PLATFORM_RATING__D_STRETCH,
  PLATFORM_RATING__E,
  POSITIVE_SIGNALS__COMPUTER_DEGREE,
  POSITIVE_SIGNALS__ELITE_UNIVERSITY,
  POSITIVE_SIGNALS__FOUNDING_TEAM,
  POSITIVE_SIGNALS__GITHUB,
  POSITIVE_SIGNALS__GREAT_GITHUB,
  POSITIVE_SIGNALS__HACKATHON_WINNER,
  POSITIVE_SIGNALS__IMPRESSIVE_GITHUB,
  POSITIVE_SIGNALS__PROMOTION,
  POSITIVE_SIGNALS__RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__STARTUP_EXPERIENCE,
  POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE,
  POSITIVE_SIGNALS__STRONG_GITHUB,
  POSITIVE_SIGNALS__STRONG_TECH_COMPANY,
  POSITIVE_SIGNALS__STRONG_UNIVERSITY,
  POSITIVE_SIGNALS__TECH_COMPANY,
  POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY,
  POSITIVE_SIGNALS__UNICORN_STARTUP,
  POSITIVE_SIGNALS__WON_AWARD,
  POSITIVE_SIGNALS__YCOMBINATOR_STARTUP,
  ROLES__GROUP__TECHNICAL_ID,
  STATE_ACTIVE
} from "./Definition";
import Engagement from "./Engagement";
import {
  NOT
} from './GenericTools.lib';
import Http from "./Http";
import Job from "./Job";
import {
  Obj,
  safeStringify
} from './Object.lib';
import Streak from './Streak';
import {
  joinKeyName,
  Str,
  trim
} from './String.lib';
import {
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION
} from "../dictionaries/Engagement.dic";
import {
  getCandidateModel,
  mapCandidate,
  mapCandidates,
  MODEL_NAME_CANDIDATE
} from "./models/candidate";
import {
  mapEngagements
} from "./models/engagement";
import copyHtml from "./tools/copyHtml";
import getStateModel from "./tools/getStateModel";

const DEBUG_DEGREE_ROLE_MATCH = Core.debug('degreeRoleMatch');
const DEBUG__CANDIDATE_CACHE = Core.debug('DEBUG__CANDIDATE_CACHE');

// Cache dictionary for candidate
const candidateCache = {};

/**
 * Stores a value in the candidateCache dictionary.
 * 
 * IF json param is defined it is stringified and used as key to store the value to the cache.
 * 
 * @param {object} options
 * @param {string} options.key
 * @param {object} options.json
 * @param {object} options.value
 * @returns The value stored in cache.
 */
const setCache = ({ key, json, value }) => {
  if (json) { key = safeStringify(json); }
  return candidateCache[key] = value;
};

/**
 * Returns stored value in the candidateCache dictionary.
 * 
 * IF json param is defined it is stringified and used as key to obtain the value from the cache.
 * 
 * @param {object} options
 * @param {string} options.key
 * @param {object} options.json
 * @returns The value stored in cache.
 */
const getCache = ({ key, json }) => {
  if (json) { key = safeStringify(json); }
  if (DEBUG__CANDIDATE_CACHE) {
    console.debug('getCache', key, candidateCache[key]);
    console.debug('getCache:candidateCache:keys', Object.keys(candidateCache));
  }
  return candidateCache[key];
};

/**
 * Removes a stored value in the candidateCache dictionary.
 * 
 * IF json param is defined it is stringified and used as key to remove the value from the cache.
 * 
 * @param {object} options
 * @param {string} options.key
 * @param {object} options.json
 */
const removeCache = ({ key, json }) => {
  if (json) { key = safeStringify(json); }
  delete candidateCache[key];
};

/**
 * Removes all stored values in the candidateCache dictionary.
 * 
 * @returns The candidateCache dictionary.
 */
const cleanCache = () => {
  Object.keys(candidateCache).map(key => ({ key })).forEach(removeCache);
  return candidateCache;
};

/**
 * Original commonQuery
 * ToDo - commonQuery pulls too much related data; replace commonQuery where it is used
 */
const commonQuery = {
  include: ["account", { engagements: [{ job: "employer" }, "candidate"] }]
};

const commonQuery2 = {
  include: [
    "account",
    { engagements: { job: "employer" } },
    {
      relation: "candidateStarreds",
      scope: {
        where: { accountId: Core.getUserId() },
      },
    },
  ],
};

const menus = [
  // Roles(dc)
  { label: "Roles", key: "roles", field: "_rolesKeys", multiple: true },
  // Technology(dc)
  {
    label: "Technology",
    key: "technicalSkills",
    field: "_technicalSkillsKeys",
    multiple: true,
  },
  // Visa(h)
  {
    label: "Visa",
    key: "visa",
    field: "_visa",
    // inputType: "radio",
    options: {
      "Citizen Only": false,
      // 'Green Card': false,
      "Citzen and Green Card Only": false,
      "Visa Support Unknown": false,
      "Will Sponsor New H1": false,
      "Will Transfer H1": false,
    },
    mappings: {
      "Citizen Only": ["Citizen", "Visa Status Unknown"],
      "Citzen and Green Card Only": [
        "Citizen",
        "Green Card",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Will Transfer H1": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Will Sponsor New H1": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "Needs New Visa Sponsor",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "L1",
        "H4 EAD",
        "J-1",
        "O-1",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
      "Visa Support Unknown": [
        "Citizen",
        "Green Card",
        "Needs TN (Canada-Mexico)",
        "Needs H1B1/E-3 (Chile-Singapore-Australia)",
        "Needs H1B Visa Transfer",
        "Needs New Visa Sponsor",
        "OPT/CPT/F-1",
        "H1-B with I140 Approved",
        "L1",
        "H4 EAD",
        "J-1",
        "O-1",
        "Visa Status Unknown",
        "No Sponsorship Required",
      ],
    },
  },
  /* epic-3038-story-3083 - 2021-06-17 µ */
  /* epic-3038-story3330-m2 - 2021-07-01 µ */
  // Remote Options(s-mj)
  {
    label: "Remote Options",
    key: "inOfficeRemoteFlags",
    definitionKey: "inOfficeRemote",
    field: "_inOfficeRemoteFlagsKeys",
    prefix: "Remote",
    multiple: true,
  },
  /* This is needed for filtering results story-3083-M1-4 2021-06-17 µ */
  /* Moved to main menu epic-3038-story-3275 2021-06-30 µ */
  /* epic-3038(new locations)-story-3578 | 2021-07-29 Thu µ */
  // WFH Locations(s-mj)
  {
    label: "WFH Locations",
    key: "candidateLocations",
    definitionKey: "location",
    field: "_candidateLocations",
    prefix: "WFH",
    multiple: true,
  },
  /* This is needed for filtering results story-3083 2021-06-17 µ */
  // Office Locations(s-mj)
  {
    label: "Office Locations",
    matchingLabel: "In Office Locations",
    key: "officeLocations",
    definitionKey: "location",
    field: "_officeLocationsKeys",
    prefix: "Office",
    multiple: true,
  },
  // Stage(h)
  {
    label: "Stage",
    key: "stage",
    field: "_desiredStage",
    multiple: true,
    options: {
      "Late Stage Startup": false,
      Public: false,
      Seed: false,
      "Series A": false,
      "Series B": false,
      "Series C": false,
      "Series D+": false,
    },
    mappings: {
      "Late Stage Startup": ["Late Stage Startup", "Unknown"],
      Public: ["Public", "Unknown"],
      "Series A": ["Series A", "Unknown"],
      "Series B": ["Series B", "Unknown"],
      "Series C": ["Series C", "Unknown"],
      "Series D+": ["Series D+", "Unknown"],
    },
  },
];

const more = [
  // Platform Rating(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "Platform Rating",
      key: "platformRating",
      field: "_platformRating",
    }
    : {},
  // Diversity(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "Diversity",
      key: "diversity",
      field: "_diversity",
      prefix: "Diversity: ",
    }
    : {},

  /**
   * @todo
   * review to cleanup
   * ask to BB
   * 2021-08-03 Tue µ
   */
  // Core.isAdminOrCoordinator()
  // ? {
  //     label: "Introduced",
  //     key: "introduced",
  //     field: "_introduced",
  //     options: [{id:1,label:'InDraft'},{id:2, label:'Active'}]
  // }
  // : {},

  // State(dc)
  Core.isAdminOrCoordinator()
    ? {
      label: "State",
      key: "state",
      field: "_state",
    }
    : {},

  // With Engagements(h)
  Core.isAdminOrCoordinator()
    ? {
      label: "With Engagements",
      key: "withEngagements",
      field: "_withEngagements",
      options: [
        { id: 1, label: "Yes" },
        { id: 2, label: "No" },
      ],
    }
    : {},

  /**
   * @todo
   * review to cleanup
   * ask to BB
   * 2021-08-03 Tue µ
   */
  //  {
  //    label: "Desired Stage",
  //    key: "stage",
  //    field: "_desiredStage"
  //  }

  /** This is needed for filtering results story-3083 2021-06-17 µ */
  // Desired employment type(dc)
  {
    label: "Desired employment type",
    key: "desiredEmploymentType",
    field: "_desiredEmploymentTypes",
  },
];

/** @todo to review, what means this comment here? | 2021-08-03 Tue µ  */
// Needed so that the min salary shows up for recruiters

const listTabs = ["Name", "Introduced", "Recent", "Recruiter", "Starred"];
const listTabsWithSort = [
  { title: "Name", sort: { firstName: 1 } },
  { title: "Introduced", sort: { introduced: -1 } },
  { title: "Recent", sort: { updatedAt: -1 } },
  { title: "Recruiter", sort: "" },
  { title: "Starred", match: { candidateStarreds: { $ne: [] } } },
];
const listTabsRecruiters = ["Name", "Recent", "Starred"];
const listTab = Core.isAdminOrCoordinator() ? "Introduced" : "Name";

const Candidate = {
  name: MODEL_NAME_CANDIDATE,
  menus,
  more,
  listTabs,
  listTabsWithSort,
  listTabsRecruiters,
  listTab,
  columns: [
    {
      headers: [
        {
          label: "Candidate",
          key: "_infoCmp",
          sortKey: "_name",
          filter: false,
          allFilters: false,
          order: 18,
          collapsed: true,
        },
        {
          label: "Technical Skills",
          key: "_technicalSkills",
          multiple: true,
          order: 4,
          collapsed: true,
        },
        {
          label: "Engagements",
          key: "_engagementsCmp",
          sortKey: "_engagementsLength",
          reverseSort: true,
          filter: false,
          order: 7,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
      style: { minWidth: 256 },
    },
    {
      headers: [
        {
          label: "Roles",
          key: "_roles",
          order: 3,
          multiple: true,
          collapsed: false,
        },
        {
          label: "Positive Signals",
          key: "_positiveSignals",
          order: 15,
          multiple: true,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Recruiter",
          key: "_recruiterCmp",
          sortKey: "_recruiterName",
          order: 13,
          collapsed: true,
        },
        {
          label: "Negative Signals",
          key: "_negativeSignals",
          order: 16,
          multiple: true,
          collapsed: true,
          admin: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        { label: "Visa", key: "_visa", order: 7, collapsed: false },
        {
          label: "Work Locations",
          key: "_officeLocations",
          multiple: true,
          order: 8,
          collapsed: false,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Introduced",
          key: "_introduced",
          sortKey: "introduced",
          reverseSort: true,
          collapsed: true,
          filter: false,
          allFilters: false,
        },
        {
          label: "Minimum Years of Experiences",
          key: "_years",
          sortKey: "yearsOfExperience",
          order: 6,
          collapsed: false,
        },
        {
          label: "Tags",
          key: "_tags",
          sortKey: "_tags",
          order: 16,
          collapsed: false,
          multiple: true,
        },
        {
          label: "Minimum Salary",
          key: "_minimumSalary",
          sortKey: "minimumSalary",
          order: 11,
          collapsed: true,
        },
      ],
      selected: 0,
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: false,
          collapsed: true,
        },
        {
          label: "State",
          key: "_state",
          visible: false,
          order: 1,
          collapsed: true,
          admin: true,
          acl: {
            defaultValues: ["Active", "active"],
            roles: ["SysAdmin"],
          },
        },
        {
          label: "Candidate Platform Rating",
          key: "_platformRating",
          visible: false,
          order: 2,
          collapsed: true,
          admin: true,
        },
        {
          label: "Level",
          key: "_level",
          visible: false,
          order: 5,
          collapsed: true,
        },
        {
          label: "Duplicate",
          key: "isDuplicate",
          visible: false,
          order: 6,
          collapsed: true,
        },
        {
          label: "Work from home partial week",
          key: "_workRemotly",
          visible: false,
          order: 10,
          collapsed: true,
        },
        {
          label: "Agency",
          key: "_companyName",
          visible: false,
          order: 12,
          collapsed: true,
          admin: true,
        },
        {
          label: "Diversity",
          key: "_diversity",
          visible: false,
          order: 14,
          collapsed: true,
          admin: true,
        },
        {
          label: "Latest Stage",
          key: "_latestStage",
          visible: false,
          order: 17,
          collapsed: true,
        },
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" },
    },
  ],
  degreeRoleMatch({ candidate }) {
    const technicalRoles = Definition.getGroupObj('roles', ROLES__GROUP__TECHNICAL_ID)?.ids || [];
    let {
      roles = [],
      positiveSignals = [],
      yearsOfExperience: relevantYearsOfExperience
    } = candidate;
    let match = true;
    let roleIsTechnical = !!roles.find(role => technicalRoles.includes(role));
    if (roleIsTechnical) {
      if (
        !(
          positiveSignals.includes(POSITIVE_SIGNALS__COMPUTER_DEGREE) ||
          positiveSignals.includes(POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE)
        ) ||
        (relevantYearsOfExperience < 4)
      ) {
        match = false;
      }
    }
    DEBUG_DEGREE_ROLE_MATCH && console.debug('degreeRoleMatch', {
      roles, technicalRoles, roleIsTechnical, positiveSignals, relevantYearsOfExperience, match
    });
    return match;
  },
  /**
   * If component true, it returns calculated platform rating label.
   * -  If candidate PR is different to calculated PR, it adds a red message.
   *
   * If component false, it returns the calculated PR ID.
   *
   * @param {Object} candidate Usually candidate state (memory data)
   * @param {Boolean} component Indicates if returns ID (false) or Component (true)
   */
  calculatePlatformRating({ candidate, component = false }) {
    /** * /
    {
      1: "A - Top",
      2: "B - Strong",
      3: "C - Good",
      4: "D - Stretch",
      5: "A+",
      6: "E"
    }
    /** */
    const labels = Definition.getAllLabels("platformRating");

    const { positiveSignals: candoPositiveSignal = [] } = candidate;

    const _DEGREE_ROLE_MATCH = Candidate.degreeRoleMatch({ candidate });

    const _STRONG_EXPERIENCE = (
      (
        candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_UNIVERSITY) &&
        _DEGREE_ROLE_MATCH
      ) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_GITHUB)
    );

    const _STARTUP_EXPERIENCE =
      candoPositiveSignal.includes(POSITIVE_SIGNALS__UNICORN_STARTUP) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__YCOMBINATOR_STARTUP) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__STARTUP_EXPERIENCE) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__FOUNDING_TEAM);

    const _POSITIVE_SIGNALS =
      candoPositiveSignal.includes(POSITIVE_SIGNALS__PROMOTION) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__HACKATHON_WINNER) ||
      candoPositiveSignal.includes(POSITIVE_SIGNALS__WON_AWARD);

    /** A+ */
    const A_PLUS = (
      candoPositiveSignal.includes(POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY) &&
      (
        candoPositiveSignal.includes(POSITIVE_SIGNALS__IMPRESSIVE_GITHUB) ||
        (
          candoPositiveSignal.includes(POSITIVE_SIGNALS__ELITE_UNIVERSITY) &&
          _DEGREE_ROLE_MATCH
        )
      ) &&
      PLATFORM_RATING__A_PLUS
    );

    /** A - Top */
    const A = (
      (
        /** ANY OF THESE */
        (
          candoPositiveSignal.includes(POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY) ||
          candoPositiveSignal.includes(POSITIVE_SIGNALS__IMPRESSIVE_GITHUB) ||
          (
            candoPositiveSignal.includes(POSITIVE_SIGNALS__ELITE_UNIVERSITY) &&
            _DEGREE_ROLE_MATCH
          )
        ) ||
        /** 2 OF THESE */
        (
          /** EDUCATION OR GITHUB */
          (
            Number(
              (
                candoPositiveSignal.includes(POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY) &&
                _DEGREE_ROLE_MATCH
              ) ||
              candoPositiveSignal.includes(POSITIVE_SIGNALS__GREAT_GITHUB)
            ) +
            Number(
              candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_TECH_COMPANY)
            ) +
            Number(
              candoPositiveSignal.includes(POSITIVE_SIGNALS__UNICORN_STARTUP) ||
              candoPositiveSignal.includes(POSITIVE_SIGNALS__YCOMBINATOR_STARTUP)
            )
          ) >= 2
        ) ||
        /** 3 OF THESE */
        (
          /** EDUCATION OR GITHUB */
          (
            Number(
              _STRONG_EXPERIENCE
            ) +
            Number(
              candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_TECH_COMPANY)
            ) +
            Number(_STARTUP_EXPERIENCE) +
            Number(_POSITIVE_SIGNALS)
          ) >= 3
        )
      ) &&
      PLATFORM_RATING__A_TOP
    );

    /** B - Strong */
    const B = (
      (
        /** ANY OF THESE */
        candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_TECH_COMPANY) ||
        _STRONG_EXPERIENCE ||
        candoPositiveSignal.includes(POSITIVE_SIGNALS__STARTUP_EXPERIENCE) ||
        /** 2 OF THESE */
        (
          Number(
            candoPositiveSignal.includes(POSITIVE_SIGNALS__RANKED_UNIVERSITY) &&
            _DEGREE_ROLE_MATCH
          ) +
          Number(candoPositiveSignal.includes(POSITIVE_SIGNALS__STRONG_GITHUB)) +
          Number(candoPositiveSignal.includes(POSITIVE_SIGNALS__PROMOTION)) +
          Number(candoPositiveSignal.includes(POSITIVE_SIGNALS__HACKATHON_WINNER)) +
          Number(candoPositiveSignal.includes(POSITIVE_SIGNALS__WON_AWARD))
        ) >= 2
      ) &&
      PLATFORM_RATING__B_STRONG
    );

    /** C - Good */
    const C = (
      (
        candoPositiveSignal.includes(POSITIVE_SIGNALS__TECH_COMPANY) ||
        (
          candoPositiveSignal.includes(POSITIVE_SIGNALS__RANKED_UNIVERSITY) &&
          _DEGREE_ROLE_MATCH
        ) ||
        candoPositiveSignal.includes(POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE) ||
        candoPositiveSignal.includes(POSITIVE_SIGNALS__COMPUTER_DEGREE) ||
        candoPositiveSignal.includes(POSITIVE_SIGNALS__GITHUB) ||
        _POSITIVE_SIGNALS
      ) &&
      PLATFORM_RATING__C_GOOD
    );

    /** D - Stretch */
    const D = PLATFORM_RATING__D_STRETCH;

    /** E */
    const E = PLATFORM_RATING__E;

    const calculatedPR = Str(A_PLUS || A || B || C || D || E);

    let result = null;

    if (!!component) {
      if (candidate.platformRating === 0) {
        result = (
          <Typography sub warning role='Note'>
            NOTE: The platform calculate and set the value to
            &nbsp;{labels[calculatedPR]}
          </Typography>
        );
      }
      else if (+candidate.platformRating !== +calculatedPR) {
        result = (
          <Typography sub error role='Warning'>
            WARNING: The platform's suggested rating is {labels[calculatedPR]}
          </Typography>
        );
      }
    }
    else {
      result = calculatedPR;
    }

    console.debug(
      'calculatePlatformRating...',
      '\n', component,
      '\n', candidate.platformRating,
      '\n', calculatedPR,
      '\n', result
    );

    return result;

  },
  cleanCache,

  getMyPdfUrl: (candidate) => {
    if (!candidate.resumes || !Array.isArray(candidate.resumes)) {
      return "";
    }

    let resume = candidate.resumes.find((el) => /.pdf/.test(el.url));

    if (!!candidate.resumePdfUrl) {
      return candidate.resumePdfUrl;
    } else {
      if (!resume) {
        resume = candidate.resumes[0];
      }

      return resume.url;
    }
  },

  getActives: (recruiterId, cb) => {
    /**
      “active” means:
      - anyone with introduced date <= 6 months
      - anyone with open (state) engagements
      - anyone with engagements pass Over/Hire/Guarantee stage.
     */
    const SIX_MONTH = 6 * 30 * 24 * 60 * 60 * 1000;
    cb = cb instanceof Function ? cb : function () { };
    const cache = getCache({ key: 'actives' });
    if (cache) { setTimeout((st) => cb(cache)); }
    else {
      const candidates = {};
      const results = [];
      let where = {};
      if (recruiterId === -1) {
        where = {};
      } else {
        where = { accountId: recruiterId };
      }
      Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({
            ...commonQuery,
            where: {
              ...where,
              introduced: {
                gt: new Date(Date.now() - SIX_MONTH).toISOString(),
              },
            },
          }),
        },
        function onSuccess(response) {
          response.forEach((cand) => {
            if (!candidates[cand.id]) {
              candidates[cand.id] = true;
              results.push(cand);
            }
          });
          Http.get(
            Core.getApi("Engagements"),
            {
              filter: JSON.stringify({
                where: {
                  or: [
                    { state: "Open" },
                    {
                      or: [
                        { stage: "Over" },
                        { stage: "Hire" },
                        { stage: "Guanrantee" },
                      ],
                    },
                  ],
                },
                fields: { candidateId: true },
                include: {
                  candidate: [
                    "account",
                    { engagements: { job: "employer" } },
                    {
                      relation: "candidateStarreds",
                      scope: {
                        where: {
                          ...where,
                        },
                      },
                    },
                  ],
                },
              }),
            },
            function onSuccess(engagements) {
              if (!!engagements.length) {
                engagements.forEach((eng) => {
                  if (!candidates[eng.candidate.id]) {
                    candidates[eng.candidate.id] = true;
                    results.push(eng.candidate);
                  }
                });
              }
              if (!!results.length) {
                cb(setCache({ key: 'actives', value: mapCandidates(results) }));
              }
              else { cb([]); }
            }
          );
        }
      );
    }
  },
  getAll: (cb, filter, included = true) => {
    cb = cb instanceof Function ? cb : function () { };
    const cache = getCache({ json: { key: 'all_candidates', filter } });
    if (cache) { setTimeout((st) => cb(cache)); }
    else {
      let commonQuery = included ? commonQuery2 : {};
      Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({ ...commonQuery, ...filter }),
        },
        function onSuccess(response) {
          cb(setCache({ json: { key: 'all_candidates', filter }, value: mapCandidates(response) }));
        },
        function onFailure(failure) {
          Core.failure({
            title: <div className="text-capitalize">Sorry Something Went Wrong<br />Fetching Candidates<br />Try again later</div>,
            message: failure,
            exception: failure
          });
          cb([]);
        }
      );
    }
  },

  getActiveCandidates: (success, params) => {
    const cache = getCache({ json: { key: 'active_candidates', params } });
    if (cache) {
      setTimeout((st) => success(cache));
      return cache;
    }
    return Http.get(
      Core.getApi("Candidates/active-candidates"),
      {
        params: JSON.stringify({ ...params }),
      },
      response => {
        if (!!response.length) {
          success(setCache({ json: { key: 'active_candidates', params }, value: mapCandidates(response) }));
        }
        else { success([]); }
      }
    );
  },

  getWhere: async (where, cb, opts = {}) => {
    cb = cb instanceof Function ? cb : function () { };
    where = where ? { where: Obj(where) } : {};
    const cache = getCache({ json: { where, opts } });
    if (cache) {
      setTimeout((st) => cb(cache));
      return cache;
    } else {
      return Http.get(
        Core.getApi("Candidates"),
        {
          filter: JSON.stringify({
            ...commonQuery,
            ...where,
            ...opts,
          }),
        },
        function onSuccess(response) {
          cb(setCache({ json: { where, opts }, value: mapCandidates(response) }));
        }
      ).then(mapCandidates);
    }
  },
  get: async (candidateId, cb, onFailCb = null, params) => {
    cb = cb instanceof Function ? cb : function () { };
    const cache = getCache({ json: { candidateId, params } });
    if (cache && !params?.skipCache) {
      console.debug(
        'CandidateLib__get...',
        '\n', { candidateId, cache }
      );
      setTimeout((st) => cb(cache));
      return cache;
    } else {
      const commonQueryOverride = params?.commonQuery || commonQuery;
      // console.debug(`Candidate.get:query`, candidateId, commonQueryOverride);
      return Http.get(
        Core.getApi("Candidates/" + candidateId),
        {
          filter: JSON.stringify({ ...commonQueryOverride })
        },
        function onSuccess(response) {
          cb(setCache({ json: { candidateId, params }, value: mapCandidate(response) }));
        },
        function onFail(response) {
          !!onFailCb && onFailCb(response);
        }
      ).then(mapCandidate);
    }
  },
  post: (candidate, success, fail) => {
    Candidate.cleanCache();
    Engagement.cleanCache();

    if (candidate.country === "parser-not-found") {
      candidate.country = "";
    }

    if (candidate.platformRating === 0) {
      candidate.platformRating = Candidate.calculatePlatformRating({ candidate });
    }

    Http.post(
      Core.getApi("Candidates"),
      getStateModel(candidate, getCandidateModel()),
      success,
      fail
    );
  },

  /**
   * 
   * @param {string} candidateId 
   * @param {object} candidate ---update for candidate, NOTE: it could be just a portion of the record---
   * @param {function} success 
   * @param {function} failure 
   * @returns 
   */
  update: (candidateId, candidate, success = (response) => null, failure = () => { }) => {
    Candidate.cleanCache();
    Engagement.cleanCache();

    if (candidate.country === "parser-not-found") {
      candidate.country = "";
    }

    if (candidate.platformRating === 0) {
      candidate.platformRating = Candidate.calculatePlatformRating({ candidate });
    }

    if (Array.isArray(candidate.employmentHistories)) {
      candidate.employmentHistories = candidate.employmentHistories.map(employment => {
        Object.keys(employment).forEach(key => {
          if (String(key).includes('__')) {
            delete employment[key];
          }
        });
        return employment;
      });
    }

    let candidateModel = getCandidateModel();
    let candidateState = getStateModel(candidate, candidateModel);

    return Http.patch(
      Core.getApi("Candidates/" + candidateId),
      candidateState,
      success,
      failure
    );
  },
  updateStarred: (candidateId, starredId, starred, success) => {
    Candidate.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi("CandidatedStarred/" + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi("CandidatedStarred"),
        {
          candidateId,
          starred,
          accountId: Core.getUserId(),
        },
        success
      );
    }
  },
  getNames: (success) => {
    Http.get(
      Core.getApi("Candidates"),
      {
        filter: JSON.stringify({
          fields: { id: true, firstName: true, lastName: true },
        }),
      },
      (response) => success(response)
    );
  },

  getOwnedPermittedJobs: (opts, success) => {
    Http.get(
      Core.getApi("Candidates/cando-owned-permitted-jobs"),
      opts.params,
      (response) => success(response)
    );
  },

  candoInConflictInPermittedJobOwnership: (opts, success) => {
    Http.get(
      Core.getApi("Candidates/conflicted-candos-permitted-jobs-status"),
      opts.params,
      (response) => success(response)
    );
  },

  /*
   * Make sure URLs include the appropriate location (e.g. linkedin or github).
   * Otherwise set them to null
   */
  processLinkedinGithubUrls: (candidate) => {
    let linkedinUrl = "";
    let githubUrl = "";

    try {
      linkedinUrl = candidate.linkedInURL.match(/linkedin.+\w/g).pop();
    } catch (e) { }

    /*
     *  Need to make sure the url is more than a generic url
     */
    if (!!linkedinUrl && linkedinUrl.split("/").length <= 2) {
      /*
       *  if there isn't more than linkedin.com/in/ then set to null
       */
      linkedinUrl = "";
    }

    try {
      githubUrl = candidate.gitHubURL.match(/github.+\w/g).pop();
    } catch (e) { }

    /*
     *  Need to make sure the url is more than a generic url
     */
    if (!!githubUrl) {
      /*
       *  Check if there is more to the url than github.com or github.io
       */
      if (githubUrl.match(/github.com/g)) {
        // check that the url is github.com/<username>/
        if (githubUrl.split("/").length <= 1) {
          githubUrl = "";
        }
      } else if (githubUrl.match(/github.io/g)) {
        // check that the url is <username>.github.io
        if (githubUrl.split(".").length <= 2) {
          githubUrl = "";
        }
      } else {
        // url doesn't include github.com or github.io
        githubUrl = "";
      }
    }

    return { githubUrl, linkedinUrl };
  },

  getPotentialDuplicated: (candidate, success) => {

    Http.get(
      Core.getApi("Candidates/duplicated"),
      {
        candidate: JSON.stringify({
          id: candidate.id,
          firstName: (candidate.firstName || "").trim(),
          lastName: (candidate.lastName || "").trim(),
          email: (candidate.email || "").trim(),
          phone: (candidate.phone || "").trim(),
          linkedInURL: (candidate.linkedInURL || "").trim(),
          gitHubURL: (candidate.gitHubURL || "").trim(),
        }),
      },
      function onSuccess(response) {
        success(
          mapCandidates(response.filter((can) => can.id !== candidate.id))
        );
      }
    );
  },

  getPotentialDuplicatedWithOwnerships: async (candidate) => {
    const { linkedinUrl, githubUrl } = Candidate.processLinkedinGithubUrls(
      candidate
    );
    const cacheKey = joinKeyName([
      'getPotentialDuplicatedWithOwnerships',
      candidate.id.toString()
    ]);
    let cacheValue = getCache({ key: cacheKey });
    if (cacheValue === undefined) {
      cacheValue = Http.get(
        Core.getApi("Candidates/duplicated-ownerships"),
        {
          candidate: JSON.stringify({
            id: candidate.id,
            firstName: (candidate.firstName || "").trim(),
            lastName: (candidate.lastName || "").trim(),
            email: (candidate.email || "").trim(),
            phone: (candidate.phone || "").trim(),
            linkedInURL: (linkedinUrl || "").trim(),
            gitHubURL: (githubUrl || "").trim(),
          }),
        }
      );
      setCache({ key: cacheKey, value: cacheValue });
    }
    return cacheValue;
  },

  delete: (candidateId, success, fail = null) => {
    Candidate.cleanCache();
    Engagement.cleanCache();
    Engagement.getWhere({ candidateId }, (response) => {
      // Core.log({ response });
      Http.delete(Core.getApi("Candidates/" + candidateId), success, fail);
      const next = (em) => {
        setTimeout((st) => {
          if (!!response.length) {
            const eng = response.pop();
            // Core.log({ eng });
            Http.delete(Core.getApi("Engagements/" + eng.id), (response) => {
              eng.boxKey && Streak.deleteBox({ boxKey: eng.boxKey });
            });
            next();
          }
        });
      };
      next();
    });
  },

  getCount: (success, filter = {}) => {
    Http.get(
      Core.getApi("Candidates/count"),
      { where: JSON.stringify(filter) },
      (response) => success(response.count)
    );
  },

  sendNewCandidateEmail: (params, success, failure, noDialog) => {
    const { from, to, cc, subject, html } = params;
    const send = (ev) => {
      Core.dialog.close();
      Http.post(
        Core.getApi("Candidates/sendNewCandidateEmail"),
        params,
        (response) => {
          success
            ? success(response)
            : !noDialog && Core.showSuccess("Email sent to Candidate.");
        },
        (error) => {
          failure
            ? failure(error)
            : Core.failure({
              source: 'Candidate.js(lib)>sendNewCandidateEmail',
              exception: error,
              params,
              omitUIMessage: noDialog,
            });
        }
      );
    };
    if (noDialog) {
      send();
    }
    else {
      Core.dialog.open({
        title: "Email Preview",
        content: (
          <>
            <Box>
              From: {from}
              <br />
              To: {to}
              <br />
              Cc: {cc}
              <br />
              Subject: {subject}
              <br />
            </Box>
            <Divider />
            <Html value={html} />
          </>
        ),
        actions: [
          <Button flat
            label="Cancel"
            primary={true}
            onClick={Core.dialog.close}
          />,
          <Button flat label="Send" primary={true} onClick={send} />,
        ],
      });
    }
  },

  isStale: (candidateId, candoUpdatedAt, successCb) => {
    Candidate.get(candidateId, (cando) => {
      console.log({ candoUpdatedAt, latest: cando.updatedAt })
      if (candoUpdatedAt !== cando.updatedAt) {
        successCb({
          isStale: true
        })
      }

      successCb({ isStale: false });
    }, () => {

    }, { skipCache: true, commonQuery: { fields: ['updatedAt'] } })
  },
  getMenu({ key }) {
    return menus.find(n => n.key === key);
  },
  getMoreMenu({ key }) {
    return more.find(n => n.key === key);
  },

  search: ({
    query = {
      "filters": [
        {
          "state": STATE_ACTIVE,
          // "candidateStarreds.starred":true,
          // "candidateStarreds.accountId":"604672f6ea71851aa4b719a0",
        }
      ],
      "sort": { "firstName": 1, "lastName": 1 },
      "skip": 0,
      "limit": 10,
      "query": "",
      "associations": [
        'account',
        'candidateStarreds',
        'engagements.job.employer'
      ]
    },
    onSuccess = () => { }
  } = { onSuccess() { } }) => {
    const mapResponse = response => {
      if (response[0]) {
        response = response[0];
        response.query = query;
        response.results = mapCandidates(response.results);
      }
      else {
        response = {
          query,
          results: []
        }
      }
      return response;
    }
    return Http.get(
      Core.getApi('Candidates/_search'),
      { query: JSON.stringify(query) },
      response => onSuccess(mapResponse(response))
    ).then(mapResponse);
  },

  suggestions: async ({ query = '' }) => {
    if (query.trim().length >= 2) {
      return Http.get(
        Core.getApi('Candidates/_autocomplete'),
        { query },
      ).then(response => response.map(({ term }) => term));
    }
    else {
      return [];
    }
  },

  fetchEngagements({ candidateId, cb }) {
    let where = {};
    if (Core.isAdminOrCoordinator()) {
      where = { candidateId };
    } else {
      where = { candidateId, state: "Open" };
    }
    Engagement.cleanCache();
    return Engagement.getWhere(where).then(mapEngagements);
  },
  bulkCopy(candidate) {
    Candidate.fetchEngagements({ candidateId: candidate.id }).then(
      engagements => {
        const contents = [];
        engagements.forEach(eng => {
          if ((eng.stage === STAGE_CONFIRMATION) && (eng.state === ENGAGEMENT__STATE_OPEN)) {
            // Core.log({ job: Job.getPreview(eng.job) });
            contents.push(Job.getPreview(eng.job));
          }
        });
        if (!!contents.length) {
          copyHtml("<p>" + contents.join("</p><p>") + "</p>")
            .then(em => {
              Core.log("Copy email command was successful");
              Core.showSuccess("Copied!");
            })
            .catch(ex => {
              Core.log("Oops, unable to copy");
              Core.showError("Fail copy!");
            });
        } else {
          Core.showWarning("There are no Engagements on Confirmation stage");
        }
      }
    )
  },

  addEngagementToPermittedJobs({ candidate, engagement }) {
    const { id, jobsPermitted } = candidate;
    return Candidate
      .update(id, { jobsPermitted: [...jobsPermitted, engagement.jobId] })
      .then(mapCandidate);
  },

  proxy: (source = 'Candidate.proxy') => {
    console.debug(`Candidate.proxy:${source}`);
    return Candidate;
  }

};

export default Candidate;

export async function deleteResumeFile({ resume }) {
  const arr = String(resume.url).split('/');
  const filename = arr.pop();
  const container = arr.pop() && arr.pop();
  console.debug(`Proceed to delete original file (${resume.url})`);
  return Core.deleteFile(container, filename).then(console.debug).catch(Core.showError);
}

export async function fetchCandidateResumeText(options) {
  const { candidate, reset = false } = Obj(options);
  if (!candidate.resumeTxtUrl) { return ''; }
  const _resumeTxtUrl = trim(candidate.resumeTxtUrl);
  const _resumeText = getCache({ key: _resumeTxtUrl });
  if (_resumeText && NOT(reset)) {
    return _resumeText;
  }
  else if (NOT(fetchCandidateResumeText.busy)) {
    try {
      fetchCandidateResumeText.busy = true;
      candidate._resumeText = await (await fetch(_resumeTxtUrl)).text();
      setCache({ key: _resumeTxtUrl, value: candidate._resumeText });
      fetchCandidateResumeText.busy = false;
      return trim(candidate._resumeText);
    }
    catch (error) { console.error(error); }
  }
  else {
    return new Promise((resolve) => setTimeout(async () =>
      resolve(fetchCandidateResumeText(options))
    ));
  }
}
