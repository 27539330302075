import employerSourceList from "../employerSourceList";

const getRemovedEmployerIds = (accountId, cb) => {      // used by admin account edit
    employerSourceList.getEmployerIds(
        {
          where: {
            and : [
                {accountId:accountId},
                {endDate:{neq: null}}
            ]
          },
          fields: ["employerId"]
        },
        (response => {
            cb(response);
        })
    );
}

const getAllEmployersOfAdmin = (accId, cb) => {              // used by recruiter job listing
    employerSourceList.getEmployerIds(
        {
          where: {
            and : [
                {accountId:accId}
            ]
          },
          fields: ["employerId", "id", "startDate", "endDate"]
        },
        (response => {
            cb(response);
        })
    );
}

const deleteRecruiterEmployerByAdmin = (empId, accountId, data, cb) => {
    // POST OR PATCH ENTRY
    employerSourceList.postOrPatchEmployer(      // used by admin account edit
        empId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const resumeRecruiterEmployerByAdmin = (empId, accountId, data, cb) => {
    // POST OR PATCH ENTRY
    employerSourceList.patchEmployerByResume(      // used by admin account edit
        empId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const removeEmployerByAdmin = (empId, accountId, data={}, cb) => {
    employerSourceList.removeEmployer(      // used by recruiter account edit
        empId,
        accountId,
        data,
        (response => {
            cb(response);
        })
    );
}

const toExport = {
    getRemovedEmployerIds,
    deleteRecruiterEmployerByAdmin,
    getAllEmployersOfAdmin,
    resumeRecruiterEmployerByAdmin,
    removeEmployerByAdmin
}

export default toExport;