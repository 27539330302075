import { Popover as MuiPopover } from '@mui/material';

export default function Popover({
  open = false,
  anchorEl = null,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  onClose = () => null,
  ...props
}) {
  const _props = {
    open,
    anchorEl,
    onClose: (event) => {
      console.debug('event', event);
      onClose(event);
    },
    anchorOrigin,
    ...props
  }
  return (<MuiPopover {..._props} />);
}
