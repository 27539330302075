import {
  Radio as RadioAntD
} from 'antd';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  joinKeyName
} from '../../../lib/String.lib';
import {
  mapWrapper
} from '../Libraries/Theme.lib';
import Wrapper from './Wrapper';

/**
 * 
 * @see https://4x.ant.design/components/radio/
 */
export default function StyledRadio({
  size = 'small',
  options = [],
  value: propsValue = null,
  onChange = Fun,
  renderOption = Fun,
  // --- //
  component = RadioAntD.Group,
  wrapperProps = {},
  ...props
}) {
  const [state, updateState] = useStateObserved({
    observed: {
      value: propsValue
    }
  });
  return (
    <Wrapper
      {...mapWrapper({
        role: 'StyledRadio',
        props: wrapperProps,
        assign: {
          ...props,
          component,
          size,
          value: state.value,
          onChange: async (event) => {
            const value = event.target.value;
            console.debug('CHANGE', event, value);
            await updateState({ value });
            onChange(value);
          },
          buttonStyle: 'outlined',
          children: options.map((option, index) => (
            <RadioAntD.Button
              key={joinKeyName([
                'styled_radio_option',
                option.id,
                index,
              ])}
              value={option.id}
            >
              {option.label || renderOption(option)}
            </RadioAntD.Button>
          ))
        }
      })}
    />
  )
}
