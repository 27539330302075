
import InputNumber from '../Layout/Wrappers/InputNumber';

export function FilterNumber({
  column: {
    id: columnId,
    _minimumValue,
    _maximumValue,
  },
  filters = {},
  setFilter = () => { },
}) {
  function _onChange(value) {
    setFilter({
      columnId,
      value: value ? value : undefined
    });
  }
  return (
    <div className="d-flex">
      <InputNumber
        min={_minimumValue}
        max={_maximumValue}
        className="mr-1"
        style={{ width: 120 }}
        value={filters[columnId] || _minimumValue || 0}
        onChange={(value) => {
          value = !!value ? value : _minimumValue;
          _onChange(value);
        }}
      />
    </div>
  );
}
