import {
  List
} from "@mui/material";
import React, {
  Component
} from "react";
import {
  withTranslation
} from "react-i18next";
import { MDASH } from '../../../lib/Constants';
import Core from "../../../lib/Core";
import {
  SALARY_CURRENCIES
} from '../../../lib/Currency.lib';
import Definition from "../../../lib/Definition";
import {
  trim
} from '../../../lib/String.lib';
import Chips from "../../Forms/Chips";
import Fieldset from '../../Layout/Wrappers/Fieldset';
import InputNumber from '../../Layout/Wrappers/InputNumber';
import StepHeader from '../../Forms/StepHeader';
import Box from '../../Layout/Wrappers/Box';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Col from "../../Layout/Wrappers/Col";
import Menu from '../../Layout/Wrappers/Menu';
import {
  ErrorChipMessage
} from '../../Layout/Wrappers/Message';
import RadioGroup from '../../Layout/Wrappers/RadioGroup';
import Row from "../../Layout/Wrappers/Row";
import TextField from '../../Layout/Wrappers/TextField';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CANDIDATE_SUMMARY__INCLUDE_FLAGS
} from '../SubmissionNotes.lib';
import {
  IncludeSubmissionCheckbox
} from './IncludeSubmissionCheckbox';
import LocationSection from "./LocationSection";
import Typography from '../../Layout/Wrappers/Typography';

/* CONSTANT =================================== */

const UPTO_TECHNICAL_SKILL = 5;

/* MAIN CLASS ================================= */

class Match extends Component {
  constructor(args) {
    super(args);
    this.state = {
      availableStages: Definition.get('stage')
    }
  }

  technicalSkillsSubHeading = () => {
    const { techSkillsInNotes, techSkillsInResume } = this.props.parent.state;
    let subHeading = (
      Definition.get("technicalSkills")
        .filter(tech => (
          techSkillsInNotes.includes(tech.id) ||
          techSkillsInResume.includes(tech.id)
        ))
        .map(res => res.label)
        .sort()
    );
    if (!!subHeading.length) {
      return (
        <span>
          Found the following skills in resume or LinkedIn: {subHeading.join(", ")}
        </span>
      );
    }
    return null;
  };

  render() {
    const CandidateEditController = this.props.parent;
    const { state: candidate = {} } = CandidateEditController;
    const availableStages = this.state.availableStages;
    let { desiredEmploymentTypes = [], } = CandidateEditController.state;
    const desiredEmploymentTypeTags = Definition.get("desiredEmploymentType");
    const { t } = this.props;
    return (
      <List>

        <StepHeader {...this.props} />

        {/** 2A & 2B */}
        {/** WORK VISA */}
        <Row style={{ marginBottom: '15px' }}>
          <Fieldset
            title={
              <>
                US work visa&nbsp;
                <b style={{ color: 'orange' }}>*</b>
              </>
            }
          >
            <Menu dropdown
              name="visa"
              value={CandidateEditController.state.visa}
              onChange={(visa) => {
                CandidateEditController.setStateStore({
                  visa,
                  key: 'visa',
                  errorVisa: ''
                });
              }}
              error={!!CandidateEditController.state.errorVisa}
              options={Definition.get("visaCandidate")}
            />
            <span className='c-red f-sm'>
              {trim(CandidateEditController.state.errorVisa)}
            </span>
          </Fieldset>
          <Fieldset
            title={
              <>
                {t('candidate.desiredEmploymentTypes.label')}&nbsp;
                <b style={{ color: "orange" }}>*</b>
              </>
            }
          >
            {desiredEmploymentTypeTags.map((item) => (
              <Checkbox
                key={`candidates__forms__match__desiredEmploymentTypeTags__${item.id}`}
                checked={desiredEmploymentTypes.includes(item.id)}
                onCheck={(event) => {
                  CandidateEditController.setStateStore({
                    desiredEmploymentTypes: !!event.target.checked
                      ? [...desiredEmploymentTypes, item.id]
                      : desiredEmploymentTypes.filter((n) => n !== item.id),
                    key: "desiredEmploymentTypes",
                  });
                }}
                label={item.label}
              />
            ))}
          </Fieldset>
        </Row>

        {/** 2C */}
        {/** LOCATION */}
        <LocationSection
          CandidateEditController={CandidateEditController}
          BasicsController={this}
        />

        <Row className='mt-3'>
          <Col fullWidth className='c-black-medium f-md'>
            <b>
              10x10 promotes transparent and ethical recruiting practices. To build trust between employers, candidates, and our platform, we prohibit recruiters from suggesting that candidates increase their desired salary or inflating salary expectations.
            </b>
          </Col>
        </Row>

        {/** 2D1 */}
        {/** minimumSalary */}
        <Row className="mt-1">
          <Fieldset
            title={CANDIDATE_UI.minimumSalary.title} dot='orange'
            subtitle={<b>{CANDIDATE_UI.minimumSalary.subtitle}</b>}
            fullWidth={false}
          >
            <InputNumber
              className="flex-1 mr-1"
              value={CandidateEditController.state.minimumSalary || null}
              min={0}
              max={500000}
              onChange={value => {
                let minimumSalary = parseFloat(value) || '';
                CandidateEditController.setStateStore({
                  minimumSalary,
                  key: 'minimumSalary',
                  errorSalaryConfidence: false
                });
              }}
              units={
                <RadioGroup
                  name='candidate_edit__salary_currency'
                  options={SALARY_CURRENCIES}
                  value={String(candidate.salaryCurrency)}
                  onChange={(event, value) => {
                    CandidateEditController.setStateStore({
                      salaryCurrency: value || '',
                      key: 'salaryCurrency'
                    });
                  }}
                  optionProps={{ className: 'tt-unset' }}
                />
              }
            />
          </Fieldset>
          <Fieldset
            title={CANDIDATE_UI.salaryNote.title}
            subtitle={CANDIDATE_UI.salaryNote.subtitle}
            fullWidth={false}
          >
            <TextField
              name="salaryNote"
              value={CandidateEditController.state.salaryNote}
              onChange={(event) =>
                CandidateEditController.setStateStore({
                  salaryNote: event.target.value,
                  key: 'salaryNote'
                })
              }
              multiline
              maxRows={5}
              fullWidth
              variant='standard'
            />
          </Fieldset>
        </Row>

        <Row>
          <Col>
            <div className='d-flex flex-align-left-center'>
              <RadioGroup
                name='candidate_edit__salary_confidence'
                options={Definition.get('salaryConfidence')}
                value={String(candidate.salaryConfidence)}
                onChange={(event, value) => {
                  CandidateEditController.setStateStore({
                    salaryConfidence: Number(value) || '',
                    key: 'salaryConfidence',
                    errorSalaryConfidence: false
                  });
                }}
                optionProps={{ className: 'tt-unset min-w-80' }}
              />
            </div>
            <ErrorChipMessage show={!!candidate.errorSalaryConfidence}>
              {candidate.errorSalaryConfidence}
            </ErrorChipMessage>
          </Col>
          <Col>
            <IncludeSubmissionCheckbox
              CandidateEditController={CandidateEditController}
              fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.minimumSalary}
            />
          </Col>
        </Row>

        <Row>
          <Fieldset
            title='Level'
            fullWidth={false}
          >
            <Menu dropdown
              name="level"
              value={CandidateEditController.state.level}
              onChange={(level) => {
                CandidateEditController.setStateStore({
                  level,
                  key: 'level'
                });
              }}
              options={Definition.get("level")}
            />
          </Fieldset>
          <Fieldset
            title='Desired stage'
            fullWidth={false}
          >
            <Menu multiple
              value={CandidateEditController.state.desiredStage}
              onChange={(desiredStage) => {
                CandidateEditController.setStateStore({
                  desiredStage,
                  key: 'desiredStage'
                });
              }}
              options={availableStages}
            />
          </Fieldset>
        </Row>

        {/** 2D3 */}
        {/** minCompanySize & maxCompanySize */}
        <Row>
          <Fieldset
            title={CANDIDATE_UI.minCompanySize.title}
          >
            <InputNumber
              min={0}
              max={10000}
              step={100}
              units='People'
              value={CandidateEditController.state.minCompanySize}
              onChange={(minCompanySize) =>
                CandidateEditController.setStateStore({
                  minCompanySize,
                  key: 'minCompanySize'
                })
              }
            />
          </Fieldset>
          <Fieldset
            title={CANDIDATE_UI.maxCompanySize.title}
          >
            <InputNumber
              min={0}
              max={10000}
              step={100}
              units='People'
              value={CandidateEditController.state.maxCompanySize}
              onChange={(maxCompanySize) =>
                CandidateEditController.setStateStore({
                  maxCompanySize,
                  key: 'maxCompanySize'
                })
              }
            />
          </Fieldset>
        </Row>

        {/** 2E */}
        {/** TECHNICAL SKILLS */}
        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="technicalSkills"
              label={(
                <div className='flex-row'>
                  <label className='mr-1'>
                    Proficient skills want to be interviewed in&nbsp;
                    <b style={{ color: 'orange' }}>*</b>
                  </label>
                  <Typography sub
                    className='flex-row tt-none tt-unset'
                  >
                    <Box mr>{MDASH}</Box>
                    Select up to 5 top strengths
                  </Typography>
                </div>
              )}
              sub={this.technicalSkillsSubHeading()}
              values={CandidateEditController.state.technicalSkills}
              onChange={technicalSkills => {
                technicalSkills = (
                  Core.isAdmin()
                    ? technicalSkills
                    : technicalSkills.slice(0, UPTO_TECHNICAL_SKILL)
                );
                CandidateEditController.setStateStore({ technicalSkills, key: 'technicalSkills' })
              }}
              items={(
                Definition.get("technicalSkills")
                  .filter(tech => (
                    !CandidateEditController.state.techSkillsInNotes.includes(tech.id) &&
                    !CandidateEditController.state.techSkillsInResume.includes(tech.id)
                  ))
              )}
              additionalItems={(
                Definition.get("technicalSkills")
                  .filter(tech => (
                    CandidateEditController.state.techSkillsInNotes.includes(tech.id) ||
                    CandidateEditController.state.techSkillsInResume.includes(tech.id)
                  ))
              )}
              selectedTitle={(
                <strong style={{ fontSize: '14px' }}>
                  Most expert areas (select no more than {UPTO_TECHNICAL_SKILL})
                </strong>
              )}
              groupingTitle={(
                <strong style={{ fontSize: '14px' }}>
                  Skills found in resume and linkedIn
                </strong>
              )}
              allSkillsTitle={(
                <strong style={{ fontSize: '14px' }}>Additional Skills</strong>
              )}
            />
          </Col>
        </Row>

        {/** 2F */}
        {/** industry | technologyDomain */}
        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="industry"
              label="Preferred Industry"
              values={CandidateEditController.state.industry}
              onChange={
                (industry) => CandidateEditController.setStateStore({ industry, key: 'industry' })
              }
            />
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="technologyDomain"
              label="Domain Focus"
              values={CandidateEditController.state.technologyDomain}
              onChange={
                (technologyDomain) =>
                  CandidateEditController.setStateStore({ technologyDomain, key: 'technologyDomain' })
              }
            />
          </Col>
        </Row>

      </List>
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(Match);

/* ============================================ */
