import React from "react";
import Chip from "./Chip";
import ChipGroup from "./ChipGroup";
import ChipTag from "./ChipTag";
import And from './And';
import Not from './Not';
import Or from './Or';

const result = (element, toMatchWith, candidateResume) => {
  const args = element.args;
  let result = args.map(chip => {
    if (chip.type === 'chipGroup') {
      return !!ChipGroup.result(chip, toMatchWith) ? { group: 1 } : { group: 0 };
    } else if (chip.type === 'chipTag') {
      return !!ChipTag.result(chip, candidateResume) ? { group: 1 } : { group: 0 };
    } else if (chip.type === 'chip') {
      return !!Chip.isChipMatched(chip, toMatchWith) ? { group: 1 } : { group: 0 };
    }
    return {};
  });
  if (element.type === 'and') {
    return <And decision={result} />;
  } 
  else if (element.type === 'or') {
    return <Or atLeast={element.atLeast || 1} decision={result} />;
  } 
  else if (element.type === 'not') {
    return <Not decision={result} />;
  }
  return null;
}

const Complex = {
  result
}

export default Complex;
