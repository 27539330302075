import Definition from '../../../lib/Definition';
import {
  Str
} from '../../../lib/String.lib';
import Chip from '../../Layout/Wrappers/Chip';
import CompanyPreference from './CompanyPreference';
import LocationDetails from './LocationDetails';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__CD_REQUESTER
} from '../List/MatchLocationChips';
import Stage from './Stage';

export default function CandidateHeaderChips({
  candidate = {},
  job = {}
}) {
  const {
    employer = {}
  } = job;
  return (
    <div className='p-05'>
      {!!candidate.visa &&
        <Chip
          className="slim-chip"
          variant="outlined"
          size="small"
          label={
            Definition.getLabel(
              'visaCandidate',
              candidate.visa
            )
          }
        />
      }
      {
        Definition.getLabels('roles', job.roles).map(
          (role, index) => (
            <Chip
              key={index}
              className="slim-chip"
              variant="outlined"
              size="small"
              label={role}
            />
          )
        )
      }
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`${Str(candidate.yearsOfExperience)} yrs`}
      />
      <MatchLocationChips
        requester={MATCH_LOCATION_CHIPS__CD_REQUESTER}
        candidate={candidate}
        job={job}
      />

      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={`>$${Str(candidate.minimumSalary)}`}
      />
      <Chip
        className="slim-chip"
        variant="outlined"
        size="small"
        label={
          !!candidate.id && !!candidate.platformRating && candidate._platformRating
        }
      />
      <CompanyPreference
        candidateCompanyPreferenceMin={candidate.minCompanySize}
        candidateCompanyPreferenceMax={candidate.maxCompanySize}
        jobCompanyPreference={employer.employeeCount}
        source="candidate"
      />
      <Stage
        candidateStage={candidate.desiredStage}
        jobStage={employer.stage}
        source="candidate"
      />
      <LocationDetails
        candidateText={candidate.locationDetails}
        jobText={
          job.remoteExtraInfo
            ? job.remoteExtraInfo
            : employer.remoteExtraInfo
        }
        source="candidate"
      />
    </div>
  );
};