import {
  Input
} from 'antd';
import {
  useState
} from 'react';
import { joinClassName } from '../Libraries/Theme.lib';

export default function StyledTextArea({
  value = '',
  onChange = () => null,
  className = '',
  ...props
}) {

  const [_value, _setValue] = useState(value);
  return (
    <Input.TextArea
      value={_value || ''}
      onChange={(event) => _setValue(event.target.value)}
      onBlur={(event) => _value !== value && onChange(_value || null)}
      onPressEnter={(event) => _value !== value && onChange(_value || null)}
      autoSize={{ minRows: 1, maxRows: 7 }}
      className={joinClassName(['styled', className])}
    />
  );
}
