import MuiClickAwayListener from '@mui/material/ClickAwayListener';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiMenuList from '@mui/material/MenuList';
import MuiPopper from '@mui/material/Popper';
import {
  debounce,
  isString
} from 'lodash';
import {
  useRef
} from 'react';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../../lib/Boolean.lib';
import {
  MDASH
} from '../../../lib/Constants';
import {
  MATERIAL_ICON_NAME__MORE_VERT
} from '../../../lib/constants/MaterialIconName.dic';
import Core from '../../../lib/Core';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  isNullOrUndefined,
  Str,
  uniqueId
} from '../../../lib/String.lib';
import {
  hasNoSize,
  hasNoWidth,
  joinClassName,
  mapWrapper,
  mapWrapperName
} from '../Libraries/Theme.lib';
import Box from './Box';
import Button from './Button';
import Dash from './Dash';
import Icon from './Icon';
import IconButton from './IconButton';
import Message, {
  ErrorChipMessage
} from './Message';
import MultipleSelect from './MultipleSelect';
import {
  PLACEMENT__BOTTOM
} from './StyledTooltip';
import TreeSelect from './TreeSelect';
import Typography from './Typography';
import Wrapper from './Wrapper';

export const OPTION_TYPE__HEADER = 'Header';
export const KEY__MENU__CURRENT = 'reference__wrapper_menu__current';

/**
 * 
 * @param {any} props.icon  [true|string] Enables the icon-menu.
 * @param {boolean} props.dropdown  Enables the dropdown.
 * 
 * @param {boolean} props.filter  
 * > Enables selectors with overlay behavior ( tree | multiple ).
 * > - For this to work properly,
 * >   it is important not to wrap this kind of menu in a scrollable container,
 * >   as it can cause incorrect offsets.
 * 
 * @param {boolean} props.tree  
 * > Enables the tree-selector. 
 * > - Additionally behaviors...
 * > - multiple!==false: onChange:([...values])=>null
 * > - multiple===false: onChange:([value])=>null
 * 
 * @param {boolean} props.multiple  Enables the multi-selector.
 * @param {boolean} props.small  Related to size.
 * @param {any[]} props.options  [{ id, label, onClick = (option) => null }]
 * @param {any} props.value  [commonly:string] Auto-controlled.
 * @param {function} props.onChange  (value) => null
 * @param {function} props.renderSelected  ({ selected, open }) => null
 * @param {function} props.renderOption  ({ option }) => null
 * @returns 
 * @see https://mui.com/material-ui/api/menu/  [icon, dropdown]
 * @see https://ant.design/components/select/  [multiple]
 * @see https://ant.design/components/tree-select/  [tree]
 */
export default function Menu({
  name: propsName = `icon_menu__${Date.now()}`,
  // ---- /
  renderSelected: ActionComponent = null,
  renderOption: OptionComponent = null,
  // ---- /
  open: propsOpen = false,
  anchorEl: propsAnchorEl = null,
  value: propsValue = '',
  // ---- /
  onChange = (value, option, previousOption) => null,
  onClick = (event, open) => null,
  onClose = (event) => null,
  // ---- /
  title = '',
  titleStyle = {},
  placement = PLACEMENT__BOTTOM,
  // ---- /
  treeDefaultExpandedIds = [],
  // ---- /
  className = '',
  style = {},
  // ---- /
  options = [],
  optionLabel = 'label',
  optionMapper = (option) => option,
  optionLabelMapper = (option, defaultElement) => option.label || defaultElement,
  // ---- /
  children = null,
  content = null,
  paperStyle,
  menuListStyle,
  // ---- /
  acl = true,
  label = undefined,
  icon = false,
  variant = null,
  small = false,
  size = 'medium',
  error = '',
  // ---- /
  debug = false,
  filter = false,
  filterMenuWidth = 480,
  multiple = null,
  tree = false,
  dropdown = false,
  disabled: menuDisabled = false,
  avoidNullOption = false,
  // ---- /
  wrapperProps = {},
  actionWrapperProps = {},
  actionElementProps = {},
  actionElement = null,
  menuProps = {},
  menuElement = null,
  // ---- /
  ...props
}) {

  const _defaultState = {
    value: propsValue,
    open: propsOpen,
    anchorEl: propsAnchorEl
  }
  const [{
    value,
    open,
    anchorEl
  }, _updateState] = useState(_defaultState, _defaultState);

  let id = useRef(uniqueId());

  if (!acl) { return null; }

  mapWrapper({
    role: 'Menu__ActionWrapper',
    props: actionWrapperProps,
    assign: {
      row: true
    }
  });

  options = sanitizeArr(options);
  const _isStringArray = (typeof options[0] === 'string');
  const _options = options.map((option) => {
    if (_isStringArray) {
      option = { id: option, label: option };
    }
    else {
      option = {
        ...option,
        label: (
          option[optionLabel] ||
          option.label ||
          option.name ||
          option._name ||
          option.description
        ),
        id: (
          option.id ||
          option.key ||
          option.label
        )
      };
      option = optionMapper(option);
      if (option.type === OPTION_TYPE__HEADER) {
        option.disabled = true;
      }
    }
    return option;
  });

  const _getSelected = (value) => Obj(
    _options.find(({ id }) => (id === value))
  );

  const _onClick = async (event) => {
    if (!filter) { event.stopPropagation(); }
    setCurrentMenu({
      id: id.current,
      anchorEl: event.currentTarget || event.target
    });
    await _updateState({ open: !open });
    onClick(event, true);
  };

  const _onClose = (option) => async (event) => {
    if (!filter) { event.stopPropagation(); }
    const _update = { open: !open };
    if (option) {
      if (option.type === OPTION_TYPE__HEADER) {
        return false;
      }
      await _updateState(_update);
      Fun(option.onClick)(event);
      if (option.id !== value) {
        _update.value = option.id;
        setCurrentMenu({ id: id.current, anchorEl: null });
        onChange(option.id, option, _getSelected(value));
      }
    }
    else {
      await _updateState(_update);
    }
    onClose(event);
  };

  if (small) { size = 'small'; }

  const _anchorEl = anchorEl || getCurrentMenu().anchorEl;
  const _isOpen = open;
  const _selectedOption = _getSelected(value);

  if (filter) {

    // FILTER TREE
    if (tree) {
      mapWrapper({
        role: 'MenuTree',
        props: menuProps,
        assign: props
      });
      menuElement = (
        <TreeSelect
          {...menuProps}
          treeDefaultExpandedIds={treeDefaultExpandedIds}
          open
          data={options}
          value={value}
          onChange={onChange}
          disabled={menuDisabled}
          style={{ width: filterMenuWidth }}
          dropdownStyle={{
            width: filterMenuWidth,
            maxHeight: 'var(--matchPipeHeightV1)'
          }}
          autoFocus
        />
      );
    }

    // FILTER MULTIPLE
    else {
      mapWrapper({
        role: 'MenuMultiple',
        props: menuProps,
        assign: props
      });
      menuElement = (
        <MultipleSelect
          {...menuProps}
          open
          data={_options}
          value={value}
          onChange={onChange}
          disabled={menuDisabled}
          className='styled'
          style={{ width: filterMenuWidth }}
          dropdownStyle={{
            width: filterMenuWidth,
            maxHeight: 'var(--matchPipeHeightV1)'
          }}
          autoFocus
        />
      );
    }

    actionElement = (
      <Menu.Item small role='Menu__ActionElement'
        label={label}
        endIcon='arrow_drop_down'
        className="f-sm"
        onClick={_onClick}
      />
    );

    menuElement = (
      <MuiClickAwayListener onClickAway={
        debounce(
          async (event) => {
            console.debug(
              'CLICK_AWAY',
              getCurrentMenu().id,
              id.current,
              event.target.id
            );
            if (
              (getCurrentMenu().id !== id.current)
              ||
              !Str(event.target.id).match(/Menu__ActionElement/)
            ) {
              await _updateState({ open: false });
            }
          }
        )
      }>
        <MuiPopper
          open={_isOpen}
          anchorEl={_anchorEl}
          placement='bottom-start'
        >
          <Box column wAuto className='bg-white'>
            {menuElement}
          </Box>
        </MuiPopper>
      </MuiClickAwayListener>
    );

  }

  // TREE
  else if (tree) {
    Object.assign(actionElementProps, props);
    actionElement = (
      <TreeSelect
        {...actionElementProps}
        popupMatchSelectWidth
        treeDefaultExpandedIds={
          treeDefaultExpandedIds
        }
        data={options}
        value={value}
        onChange={onChange}
        disabled={menuDisabled}
        multiple={multiple !== null ? multiple : true}
        className={joinClassName([
          'styled',
          className
        ])}
        style={style}
        dropdownStyle={
          {
            maxHeight: 'var(--matchPipeHeightV1)'
          }
        }
      />
    );
  }

  // MULTIPLE
  else if (multiple) {
    Object.assign(actionElementProps, props);
    actionElement = (
      <MultipleSelect
        {...actionElementProps}
        popupMatchSelectWidth
        data={_options}
        value={value}
        onChange={onChange}
        disabled={menuDisabled}
        className={joinClassName([
          'styled',
          className
        ])}
        style={style}
      />
    )
  }

  else {

    actionWrapperProps.onClick = _onClick;

    // ICON
    if (icon) {
      actionElement = (
        <IconButton
          {...actionElementProps}
          icon={(icon === true) ? MATERIAL_ICON_NAME__MORE_VERT : icon}
        />
      );
      content = children;
    }

    // DROPDOWN
    else if (dropdown) {
      if (NOT(avoidNullOption)) {
        _options.unshift({ id: null, label: <Dash /> });
      }
      actionElement = (
        <Button
          {...actionElementProps}
          className={
            joinClassName([
              'tt-unset space-between bg-white selector w-100',
              _isOpen && 'selector-focused',
            ])
          }
          variant='outlined'
          color={_isOpen ? 'primary' : 'gray'}
          endIcon={_isOpen ? 'expand_less' : 'expand_more'}
          disabled={menuDisabled}
        >
          {optionLabelMapper(_getSelected(value), <Dash />)}
        </Button>
      );
    }

    // RENDER
    else if (ActionComponent instanceof Function) {
      actionElement = (
        <ActionComponent
          {...actionElementProps}
          selected={_selectedOption}
          open={_isOpen}
        />
      );
    }

    // WITH CHILDREN
    else if (children) {
      actionElement = (
        <Button flat small
          {...actionElementProps}
        >
          {children}
        </Button>
      );
    }

    // MENU
    mapWrapper({
      role: 'Menu',
      props: menuProps,
      assign: {
        ...props,
        open: _isOpen,
        anchorEl: _anchorEl,
        onClose: _onClose(null),
        MenuListProps: {
          style: {
            paddingTop: 0,
            paddingBottom: 0,
            ...menuListStyle
          }
        },
        PaperProps: {
          style: {
            maxHeight: 'calc(100vh - 2rem)',
            maxWidth: 'calc(100vw - 2rem)',
            zIndex: 99999,
            width: style.width,
            minWidth: Obj(_anchorEl).clientWidth || filterMenuWidth,
            marginTop: 5,
            ...paperStyle
          }
        },
        children: (
          /** 
           * @note
           * Don't avoid the wrapping element, the MuiMenu doesn't accept a Fragment as a child. 
           */
          <Box column w100>
            {content}
            <Message show={NOT(content) && NOT(_options.length)}>
              Empty list
            </Message>
            {_options.map(({
              acl = true,
              title = '',
              label = '',
              icon = '',
              disabled: optionDisabled = false,
              ...option
            }, index) => {
              const _itemProps = {
                ...option,
                title,
                label,
                icon,
                disabled: optionDisabled = menuDisabled || false,
                onClick: _onClose(option),
                className: joinClassName([
                  'c-purple',
                  (
                    (option.type === OPTION_TYPE__HEADER) &&
                    'bg-main c-black-medium f-lg fw-500 f-italic opacity-1'
                  )
                ]),
                divider: true,
                children: (
                  isNullOrUndefined(option.id)
                    ? (MDASH)
                    : (OptionComponent instanceof Function)
                      ? (<OptionComponent option={option} />)
                      : (
                        <Box row w100>
                          {icon &&
                            <Box className='mr-1'>
                              {icon}
                            </Box>
                          }
                          <Box row w100>
                            {label || <Dash />}
                          </Box>
                        </Box>
                      )
                )
              };
              return ((acl === undefined) || (acl === true)) && (
                <Menu.Item {..._itemProps} key={`${propsName}__${index}`} />
              );
            })}
          </Box>
        )
      }
    });
    menuElement = (
      <MuiMenu {...menuProps} />
    );

  }

  mapWrapper({
    role: 'Menu',
    props: wrapperProps,
    assign: {
      title,
      titleStyle,
      placement,
      className: joinClassName([
        (
          (
            (
              hasNoWidth({
                className,
                style
              })
            ) && (
              icon || ActionComponent || filter
            )
          ) ? (
            'w-auto'
          ) : (
            'w-100'
          )
        ),
        className
      ]),
      style
    }
  });

  if (debug) {
    console.debug(wrapperProps);
    console.debug(actionWrapperProps);
    console.debug(menuProps);
    console.debug('actionElement', actionElement);
    console.debug('menuElement', menuElement);
  }

  return (
    <Box column role='Menu' {...wrapperProps}>
      <Box role='Menu__ActionElement' {...actionWrapperProps}>
        {actionElement}
      </Box>
      <Box role='Menu__DropdownElement' column>
        {menuElement}
      </Box>
      <ErrorChipMessage show={YES(error)} className='m-05'>
        {error}
      </ErrorChipMessage>
    </Box>
  );
};

export function MenuItem({
  component = MuiMenuItem,
  title,
  titleStyle,
  placement,
  // ---- //
  label,
  children = label,
  startIcon = false,
  endIcon = false,
  className = '',
  style = {},
  // ---- //
  small = undefined,
  jobTitle = undefined,
  createdAt = undefined,
  // ---- //
  ...props
}) {
  if (startIcon) {
    if (isString(startIcon)) { startIcon = <Icon icon={startIcon} className='no-pointer-events' />; }
    children = <>{startIcon}{children}</>;
  }
  if (endIcon) {
    if (isString(endIcon)) { endIcon = <Icon icon={endIcon} className='no-pointer-events' />; }
    children = <>{children}{endIcon}</>;
  }
  if (isString(children)) {
    children = <Typography className='statement no-pointer-events'>{children}</Typography>
  }
  const _wrapperProps = mapWrapperName(
    'MenuItem',
    {
      ...props,
      component,
      title,
      titleStyle,
      placement,
      children,
      className: joinClassName([
        hasNoSize({ className, style }) && 'f-md',
        className
      ]),
      style,
    }
  );
  return (
    <Wrapper {..._wrapperProps} />
  );
}
Menu.Item = MenuItem;

export function MenuList({
  ...props
}) {
  const _props = {
    ...props
  }
  return (
    <MuiMenuList {..._props} />
  );
}
Menu.List = MenuList;

export function setCurrentMenu({
  id = uniqueId(),
  anchorEl = null
}) {
  return Core.setKeyValue(KEY__MENU__CURRENT, { id, anchorEl });
}

export function getCurrentMenu() {
  return Obj(Core.getKeyValue(KEY__MENU__CURRENT));
}
