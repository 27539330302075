import {
  ErrorMessage
} from '../components/Layout/Wrappers/Message';
import {
  getPathname,
  setLocation
} from '../lib/URL.lib';

export default function NoMatch({ location }) {
  console.debug(
    'NoMatch...',
    '\n', location
  );
  setTimeout(st => (setLocation('/')), 15000);
  return (
    <ErrorMessage
      className='vh-100 vw-100 m-0 flex flex-align-center'
    >
      No match for {getPathname()}
    </ErrorMessage>
  );
};
