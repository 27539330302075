import {
  Component
} from "react";
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate from "../../../lib/Candidate";
import Core from '../../../lib/Core';
import {
  Obj
} from '../../../lib/Object.lib';
import githubGraphqlGetUser from "../../../lib/services/GitHub/githubGraphqlGetUser";
import {
  matchLocation
} from '../../../lib/URL.lib';
import Box from '../../Layout/Wrappers/Box';
import {
  LoadingMessage
} from '../../Layout/Wrappers/Message';
import {
  MatchPageController
} from '../MatchPage';
import CandidateHeader from './CandidateHeader';
import CandidateSkills from "./CandidateSkills";
import EducationHistory from "./EducationHistory";
import Qualifications from "./Qualifications";
import SubmissionHistory from "./SubmissionHistory";
import WorkExperience from "./WorkExperience";
import WriteUps from "./WriteUps";

class CandidatePipe extends Component {
  state = {
    gitHubStats: null,
    ready: this.props.source === 'candidateMatch'
  };

  componentDidMount() {
    this.getGithubStats();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.candidate?.id !== this.props.candidate?.id) {
      this.getGithubStats();
      if (
        matchLocation(/job\/match/i)
      ) {
        this.fetchCandidate();
      }
      else {
        this.setState({ ready: true });
      }
    }
  }

  fetchCandidate() {
    const {
      candidate = {}
    } = this.props;
    this.setState(
      {
        busy: true,
        ready: false
      },
      async () => {
        try {
          Object.assign(
            candidate,
            await Candidate.get(
              candidate.id,
              null,
              null,
              {
                commonQuery: {
                  include: ['account']
                }
              }
            )
          )
          this.setState(
            {
              candidate,
              busy: false,
              ready: true
            },
            () => {
              MatchPageController().loadProfile();
            }
          );
        }
        catch (error) {
          Core.showError('Failed to fetch cando details');
          this.setState({
            error: false
          });
        }
      }
    );
  }

  getGithubStats() {
    const {
      candidate = {}
    } = this.props;
    const { gitHubURL = '' } = candidate;
    if (gitHubURL) {
      githubGraphqlGetUser({ gitHubURL, candidate }).then(gitHubStats => {
        this.setState(
          { gitHubStats },
          () => {
            MatchPageController().loadProfile();
          });
      });
    }
  }

  render() {
    // console.debug('CandidatePipe', this.props);
    const {
      candidate = {},
      job = {}
    } = this.props;
    const {
      gitHubStats = {},
      busy = false,
      ready = false
    } = this.state;
    const {
      skillsHandler,
      saveContentCandidate,
      source,
    } = this.props;
    const jobArgs = [];
    if (!!Obj(job.mustHave).args) {
      jobArgs.push(...job.mustHave.args);
    }
    if (!!Obj(job.niceToHave).args) {
      jobArgs.push(
        ...jobArgs,
        ...job.niceToHave.args
      );
    }
    return !!candidate.id && (
      <>

        <LoadingMessage show={busy}>
          Loading candidate details...
        </LoadingMessage>

        {!!candidate.id && ready &&
          <Box column w100>

            <Box className='sticky-top bg-main border-bottom'>
              <CandidateHeader
                candidate={candidate}
                job={job}
              />
            </Box>

            <Box column w100 className='px-05'>

              <Qualifications
                candidate={candidate}
                skillsHandler={skillsHandler}
                jobArgs={jobArgs}
                source={source}
              />

              <CandidateSkills
                data={
                  [
                    {
                      label: <div><strong>Technology Domain</strong></div>,
                      type: "technologyDomain",
                      chips: Arr(candidate.technologyDomain)
                    },
                    {
                      label: <div><strong>Positive Signals</strong></div>,
                      type: "positiveSignals",
                      chips: Arr(candidate.positiveSignals)
                    },
                    {
                      label: <div><strong>Negative Signals</strong></div>,
                      type: "negativeSignals",
                      chips: Arr(candidate.negativeSignals)
                    }
                  ]
                }
                jobArgs={jobArgs}
                skillsHandler={skillsHandler}
                source={source}
                candidate={candidate}
                githubStatsData={gitHubStats ? gitHubStats.singleRowHtml : null}
              />

              {!!job.id &&
                <WriteUps
                  candidate={candidate}
                  saveContentCandidate={saveContentCandidate}
                  job={job}
                />
              }

              <WorkExperience
                candidate={candidate}
              />

              <EducationHistory
                candidate={candidate}
              />

              <SubmissionHistory
                candidate={candidate}
              />

            </Box>

          </Box>
        }
      </>
    );
  }
}

export default CandidatePipe;