import {
  Collapse
} from '@mui/material';
import moment from 'moment';
import {
  Component
} from 'react';
import {
  withTranslation
} from 'react-i18next';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import Definition, {
  ACCOUNT_ACTION__EDIT_JOB,
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID
} from '../../../lib/Definition';
import LocationLib from '../../../lib/DefinitionLocation.lib';
import Engagement from '../../../lib/Engagement';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import Job from '../../../lib/Job';
import Store from '../../../lib/Store';
import {
  getParams,
  getSearch
} from '../../../lib/URL.lib';
import {
  ENGAGEMENT__STATE_CLOSED,
  REJECTION_REASON__10X10__NO_MATCH,
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION,
  STATUS_E_10X10,
  STATUS_W_EMPLOYER_FEEDBACK
} from '../../../dictionaries/Engagement.dic';
import {
  hoverDisplay
} from '../../../lib/models/engagement';
import {
  extended as extendedModel,
  model as jobModel
} from '../../../lib/models/job';
import cleanHtml from '../../../lib/tools/cleanHtml';
import copyHtml from '../../../lib/tools/copyHtml';
import getEngagementsByState from '../../../lib/tools/getEngagementsByState';
import MoreOptionsMenu from '../../Home/3DotsMenu/MoreOptionsMenu';
import {
  moreMenuOptionsJobs
} from '../../Home/3DotsMenu/moreMenuOptionsJobs';
import Box from '../../Layout/Wrappers/Box';
import Card from '../../Layout/Wrappers/Card';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Chip from '../../Layout/Wrappers/Chip';
import Divider from '../../Layout/Wrappers/Divider';
import IconButton from '../../Layout/Wrappers/IconButton';
import NavLink from '../../Layout/Wrappers/NavLink';
import Table from '../../Layout/Wrappers/Table';
import Typography from '../../Layout/Wrappers/Typography';
import JobDetails from './JobDetails';

class JobCard extends Component {
  references = {};
  timeout;
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      /** model */
      ...extendedModel,
      /** controller states */
      expanded: false,
      openedColor: null,
      CardStyle: null,
      rightArrow: {},
      checked: this.props.checked || false,
      boxes: [],
      /** load */
      ...this.props.model,
      engagement: this.props.engagement,
      index: this.props.index,
      engagements: this.props.model.engagements || [],
      recruiterJobIds:
        !!this.props.extraInfo && !!this.props.extraInfo.recruiterJobIds
          ? this.props.extraInfo.recruiterJobIds
          : [],
      engagementStructure: [],
      engagementStructureLoader: true,
    };
    if (this.props.checked) {
      this.parent.cards[this.state.id] = this;
    }
    this.lookForSearchSection(1000);
  }

  componentDidMount() {
    this.lookForSearchSection(500);
  }

  /**
   * Look into location for a search string
   *
   * then scroll into view
   *
   * if match search string with
   *
   * some JobCard element reference
   *
   * ex. /job/view/:id?{{SECTION}}
   * ex. "insider-scoop" | "interview-process"
   * ex. JOB_SECTION__RESUME_FEEDBACK | JOB_SECTION__INTERVIEW_FEEDBACK
   */
  lookForSearchSection = (delay = 100) => {
    const { state } = this;
    const { id: jobId } = state;

    const section = getSearch().toLowerCase();

    if (!!jobId && !!section.trim().length) {
      setTimeout(() => {
        const sectionAnchor = Core.getKeyValue(section);
        if (!!sectionAnchor) {
          sectionAnchor.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          });
        }
      }, delay);
    }
  };

  //may be used when clicked in card and need to show data in expanded
  fetchEngagements = (cb) => {
    Engagement.getWhere({ jobId: this.state.id }, (res) => {
      this.setState(
        {
          engagements: res,
        },
        () => {
          !!cb && cb(res);
        }
      );
    });
  };

  isRecruiterHasAssignedThisJob = () => {
    const { recruiterJobIds, id } = this.state;
    const desiredObject = recruiterJobIds.find(
      (job) => job.jobId === id.toString()
    );
    let formatDate = '';

    if (!!desiredObject && 'jobId' in desiredObject) {
      formatDate = new Date(desiredObject.startDate);
      formatDate = `${formatDate.getMonth() + 1
        }/${formatDate.getDate()}/${formatDate.getFullYear()}`;
      return (
        <span>
          <Chip slim
            backgroundColor={colors.green.common}
          >
            <Typography
              className='c-white'
            >
              My Sourcing
            </Typography>
          </Chip>
          &nbsp;&nbsp;({formatDate})
        </span>
      );
    }
    return null;
  };

  profiles = () => {
    let ret = {};
    let { engagements } = this.state;

    ret.no = () => engagements.filter((engagement) =>
      (engagement.stage === STAGE_REVIEW) &&
      /e -/i.test(engagement.status)
    );

    ret.yes = () => {
      let filtered = engagements.filter((engagement) => NOT(
        [
          STAGE_CONFIRMATION,
          STAGE_SUBMISSION,
          STAGE_REVIEW
        ].includes(engagement.stage)
      ));
      let hash = {
        Screen: 1,
        Onsite: 2,
        Offer: 3,
        Guarantee: 4,
        Hire: 5,
        End: 6,
      };
      filtered.sort((a, b) => {
        return (
          new Date(b.submitted).getTime() * hash[b.stage] -
          new Date(a.submitted).getTime() * hash[a.stage]
        );
      });
      return filtered;
    };

    ret.mayBe = () =>
      engagements.filter((engagement) =>
        (engagement.stage === STAGE_REVIEW) &&
        (engagement.status === STATUS_W_EMPLOYER_FEEDBACK)
      );

    ret.summary = () => {
      let onsite = engagements.filter((engagement) =>
        [
          STAGE_ONSITE,
          STAGE_OFFER,
          STAGE_GUARANTEE,
          STAGE_HIRE,
          STAGE_END
        ].includes(engagement.stage)
      );
      let screens = engagements.filter((engagement) =>
        (engagement.stage === STAGE_SCREEN)
      );
      let reviewed = [...ret.yes(), ...ret.no()];
      let submitted = [...reviewed, ...ret.mayBe()];
      return (
        <div>
          {submitted.length} submitted | {reviewed.length} reviewed |{' '}
          {screens.length} screens & after (
          {parseInt(
            ((screens.length + onsite.length) * 100) / reviewed.length
          )}
          %) | {onsite.length} onSites & after (
          {parseInt((onsite.length * 100) / reviewed.length)}%)
        </div>
      );
    };

    const printSingle = (eng) => {
      let update = eng;
      let candidate = eng.candidate;
      let isRejected = eng.state === 'Closed';
      let rejectedLabel = isRejected ? 'REJECTED' : '';
      let isAliveLabel = isRejected ? '' : '*';
      let rejectionReasonDetails = [
        eng.rejectionReason,
        eng.rejectionReasonAdditionalInfo,
      ]
        .filter((el) => !!el)
        .join(' - ');
      return cleanHtml(
        '<div>' +
        isAliveLabel +
        String(eng.stage) +
        ' | ' +
        String(eng.status) +
        ' | ' +
        [
          [
            moment(update.submitted).format('MM/DD/YY'),
            `<a href="/#/candidate/edit/${candidate.id}" target="_blank">${candidate._name}</a>`,
            Definition.getLabel('platformRating', candidate.platformRating),
          ]
            .filter((e) => !!e && !!String(e).trim().length)
            .join(', '),
          Definition.getLabels(
            'positiveSignals',
            candidate.positiveSignals
          ).join(', '),
          Definition.getLabels(
            'negativeSignals',
            candidate.negativeSignals
          ).join(', '),
          candidate.tagLine,
        ]
          .filter((e) => !!e && !!String(e).trim().length)
          .join('; ') +
        '<strong> - ' +
        rejectedLabel +
        '</strong>' +
        '.</div>' +
        '<div style="font-size:11px;">' +
        rejectionReasonDetails +
        '</div>'
      );
    };

    ret.print = (array) => {
      return array.map((el) => printSingle(el)).join('');
    };

    return ret;
  };

  unexpandCard = (event) => {
    this.setState({
      expanded: false,
      CardStyle: null,
      openedColor: null,
      rightArrow: { transform: 'rotate(0deg)' },
    });
  };

  updateField = (engagement, field, eventStatus, callback) => {
    if (Core.isAdminOrCoordinator()) {
      if (engagement) {
        Engagement.update(
          engagement,
          field,
          (res) => {
            this.setState({ engagement: res });
          },
          Core.showError
        );
      } else {
        //create engagement with specific data and close it
        !!this.props.externalRelationAssociation &&
          this.props.externalRelationAssociation(field, eventStatus, callback);
      }
    }

    this.setState({ pickedMatchedStrength: null });
  };

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (prevState.open !== this.props.open) {
    }
  }

  expandCard = (event) => {
    const { engagementStructure } = this.state;
    if (
      Core.isAdmin() &&
      !!Object(this.props.extraInfo).displayEngagements &&
      !engagementStructure.length
    ) {
      Engagement.getStructure(
        `?groupBy=recruiter&gid=${this.state.id}`,
        (response) => {
          this.setState({
            engagementStructure: response.res,
            engagementStructureLoader: false,
          });
        }
      );
    }
    this.fetchEngagements();
    this.setState(
      {
        expanded: true,
        CardStyle: { margin: '10px auto' },
        openedColor: { color: '#715EFF' },
        rightArrow: { transform: 'rotate(180deg)' },
      },
      (then) => Core.log({ jobCardState: this.state })
    );
  };
  handleToggle = (event) => {
    const { jobId } = getParams({ pattern: '/job/view/:jobId' });
    if (jobId) {
      if (this.state.expanded) {
        return this.unexpandCard();
      }
      else {
        return this.expandCard();
      }
    }
    return this.state.openDetails();
  };
  onCheckStar = (event, checked) => {
    event.stopPropagation();
    this.unexpandCard();
    const updateLocalList = (response) => {
      const state = {
        id: this.state.id,
        starredId: response.id,
        starred: response.starred,
        filters: {
          ...this.state.filters,
          Starred: ['Non Starred', 'Starred'][~~Boolean(response.starred)],
        },
      };
      this.setState(state);
      this.parent.updateItem(state);
    };
    Job.updateStarred(
      this.state.id,
      this.state.starredId,
      checked,
      updateLocalList
    );
  };
  getEditJobModel() {
    const newJobModel = {};
    Object.keys(jobModel).forEach((key) => {
      if (
        typeof this.state[key] === 'boolean' ||
        key === 'visaTransfer' ||
        !!this.state[key]
      ) {
        newJobModel[key] = this.state[key];
      }
    });
    delete newJobModel.id;
    newJobModel.state = Definition.getId('state', 'Draft');
    Core.log({ newJobModel });
    return newJobModel;
  }
  getPreview = (type) => Job.getPreview(this.state, type);

  renderAdditionalNotes = () => {
    const { mustHaveQuestions } = this.state;

    let collection = [
      //        {key:'additionalNotes',limited:true,label:'Must Have',visible: (!!this.state.additionalNotes && !!!this.state.candidateMust)},
      {
        key: 'candidateMust',
        limited: true,
        label: 'Must Have',
        visible: !!this.state.candidateMust,
      },
      {
        key: 'candidateMustNot',
        limited: true,
        label: 'Must NOT Have',
        visible: !!this.state.candidateMustNot,
      },
      {
        key: 'candidatePositives',
        limited: true,
        label: 'Strongly Preferred (Positives)',
        visible: !!this.state.candidatePositives,
      },
      {
        key: 'candidateNegatives',
        limited: true,
        label: 'Unlikely to Hire (Negatives)',
        visible: !!this.state.candidateNegatives,
      },
    ];

    const collectionHtml = collection.map((item, index) => {
      return (
        item.visible && (
          <div
            key={`collectionHtml-${index}`}
            ref={(target) => (this[`${item.key}View`] = target)}
          >
            <Card.Header title={<>{item.label}</>} />
            <Card.Content className="contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state[item.key],
                }}
              />
            </Card.Content>
          </div>
        )
      );
    });

    if ((mustHaveQuestions || []).length) {
      collectionHtml.push(
        <div
          key={collectionHtml.length}
          ref={(target) => (this[`jobQuestionsView`] = target)}
        >
          <Card.Header
            title={
              <>IMPORTANT: Must Answer Pre-screen Questions for Submission</>
            }
          />
          <Card.Content className="contents">
            <div>
              <ul>
                {(mustHaveQuestions || []).map((q) => (
                  <li>{q.question}</li>
                ))}
              </ul>
            </div>
          </Card.Content>
        </div>
      );
    }

    return collectionHtml;
  };

  modalUpdateStatusDefaultValues = (selectedStrength) => {
    const cbUpdateField = this.updateField;

    if (!!this.state.engagement) {
      let matchStrength =
        this.state.pickedMatchedStrength || this.state.engagement.matchStrength;
      return {
        ...this.state.engagement,
        engagement: this.state.engagement,
        matchStrength,
        cbUpdateField,
      };
    } else {
      if (!selectedStrength) {
        selectedStrength = this.myDefaultMatchStrength();
      }
      return {
        stage: STAGE_CONFIRMATION,
        state: ENGAGEMENT__STATE_CLOSED,
        status: STATUS_E_10X10,
        rejectionReason: REJECTION_REASON__10X10__NO_MATCH,
        matchStrength: selectedStrength,
        cbUpdateField,
      };
    }
  };

  myDefaultMatchStrength = () => {
    let unspecified = Engagement.unSpecifiedStrength();
    let unspecifiedId = '';

    if (!!unspecified) {
      unspecifiedId = unspecified.id;
    }

    const fromCache = !this.props.engagement
      ? Store.get(
        Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
      )
      : null;
    return (
      this.state.pickedMatchedStrength ||
      fromCache ||
      (this.state.engagement
        ? this.state.engagement.matchStrength
          ? this.state.engagement.matchStrength
          : unspecifiedId
        : unspecifiedId)
    );
  };

  getHighlightStyle = (key) => {
    const record = {
      warning: { backgroundColor: 'yellow' },
    };
    return record[key];
  };

  staticRowDisplayColor = () => {
    let obj = {};
    let candidate = this.props.parentModel;
    let job = this.state;

    obj.getSalaryColor = ((candidate, job) => () => {
      if (!candidate.minimumSalary || !job.salaryMax) {
        return '';
      }

      let color;

      if (candidate.minimumSalary <= job.salaryMax) {
        color = 'green';
      } else if (candidate.minimumSalary <= 1.15 * job.salaryMax) {
        color = 'grey';
      } else if (candidate.minimumSalary <= 1.4 * job.salaryMax) {
        color = 'grey';
      } else {
        color = 'red';
      }

      return color;
    })(candidate, job);

    obj.getVisaColor = ((candidate, job) => () => {
      let menu = Candidate.menus.find((obj) => obj.key === 'visa');
      let myMappings = menu.mappings[job._visaTransfer] || [];
      return myMappings.includes(candidate._visa) ? 'green' : 'red';
    })(candidate, job);

    obj.getLocationColor = ((candidate, job) => () => {
      let hasMatched = job._locations.split(',').some((label) => {
        let regx = new RegExp(label, 'i');
        return regx.test(candidate._workLocationIds);
      });
      return hasMatched ? 'green' : 'red';
    })(candidate, job);

    obj.getYearsXp = ((candidate, job) => () => {
      let color = 'red';
      let diff =
        parseFloat(job.minYearsOfExperience) -
        parseFloat(candidate._yearsOfExperienceForCalc);
      let diffPercent = diff / parseInt(job.minYearsOfExperience);

      if (diff <= 0) {
        color = 'green';
      } else if (diff > 0 && diffPercent <= 0.4) {
        color = 'grey';
      }

      return color;
    })(candidate, job);
    return obj;
  };

  staticRowDisplayTag = (jobAtr, candAtr, color) => {
    if (!jobAtr || !candAtr) {
      return jobAtr;
    }

    return (
      <span
        style={{
          backgroundColor: `${color} !important`,
          color: 'white !important',
          padding: '5px !important',
        }}
      >
        {jobAtr}
      </span>
    );
  };

  handlerPopup = () => {
    this.setState({
      mustHavesDialog: true,
    });
    this.fetchEngagements();
  };

  renderRecruiterNames = () => {
    const { extraInfo } = this.props;
    const names =
      !!extraInfo && !!extraInfo.jobIdHashedWithAccount
        ? extraInfo.jobIdHashedWithAccount
        : '';
    if (!!names.length) {
      return <label>{names}</label>;
    }
    return null;
  };

  render() {
    const JobCardController = this;

    const { t } = this.props;

    const { state } = this;

    const job = state;

    const {
      inOfficeRemoteFlags = [],
      officeLocations = [],
    } = state;

    let _officeLocationsLabels = '';
    if (
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
    ) {
      /* epic-3038(new locations)-story-3689-m2 | 2021-08-05 Thu µ */
      _officeLocationsLabels = LocationLib.getLocationsString({
        locations: officeLocations,
      });
    }

    const _locationsString = [
      inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID) &&
      'Remote',
      _officeLocationsLabels,
    ]
      .filter((v) => !!v)
      .join(' | ')
      .trim();

    var engaged = false;
    if (/match/i.test(this.parent.parent.name)) {
      engaged = this.props.onCheck && !!this.props.engagement;
    }

    const strengthMatchHighlight =
      !this.props.engagement &&
      this.state.checked &&
      !!Store.get(
        Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
      ) &&
      Engagement.negativeStrengths().includes(
        Store.get(
          Engagement.getCacheKeyJobMatch(this.props.candidateId, this.state.id)
        )
      );

    const highlightRowWith =
      (strengthMatchHighlight && this.getHighlightStyle('warning')) || {};

    const jobTitle_ = !!this.state._jobTitle ? (
      <>{this.state._jobTitle}</>
    ) : (
      <i>&mdash;</i>
    );
    const jobTitle = Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
      <NavLink
        title={`${job.jobTitle} | Job Title (hiring target): Go to Edit`}
        onClick={(e) => e.stopPropagation()}
        to={'/job/edit/' + this.state.id}
      >
        <b className="no-truncate p-0" style={{ width: '100%' }}>
          {jobTitle_}
        </b>
      </NavLink>
    ) : (
      jobTitle_
    );
    const employerName = (
      <>
        {!!this.state.employer._name ? (
          <>
            {this.state.employer._name_rating}
            {this.state.employer.confidential
              ? ` (Confidential - DO NOT SOURCE)`
              : ''}
          </>
        ) : (
          <i>&mdash;</i>
        )}
      </>
    );
    const roleName = !!this.state.roles.length ? (
      Definition.getLabels('roles', this.state.roles).join('-')
    ) : (
      <i>&mdash;</i>
    );
    const recent = Core.getFromNow(this.state.filters.Recent);
    const recruiterSourceName = Core.isAdmin()
      ? this.renderRecruiterNames()
      : null;

    const _onClickCopyJob = (event) => {
      event.stopPropagation();
      JobCardController.unexpandCard();
      copyHtml(JobCardController.getPreview('short'))
        .then((em) => {
          Core.log('Copy email command was successful');
          Core.showSuccess(t('copy.successful'));
        })
        .catch((ex) => {
          Core.log('Oops, unable to copy');
          Core.showError('Fail copy!');
        });
    };

    const _toggleCard = (event) => this.handleToggle(event);

    const { jobId } = getParams({ pattern: '/job/view/:jobId' });

    return (
      <>
        <Card
          className="row-card job scroll-x"
          style={{ ...highlightRowWith, ...this.state.CardStyle }}
        >
          {/** COLLAPSED VIEW */}
          <Table
            id={`job_card__table__${job.id}`}
            className={`collapsed-view my-1`}
            style={{ ...highlightRowWith }}
          >
            <Table.Body>
              {/* COLLAPSED VIEW R1 */}
              <Table.Row
                style={{
                  opacity: engaged ? 0.4 : 1,
                  width: 256
                }}
              >
                {/* R1-4C0 | CHECKBOX FOR CANDIDATE MATCH VIEW */}
                {!!this.props.onCheck && (
                  <Table.Cell
                    title="Check to Select for Matching"
                    className="ui-job-card-r1-4c0"
                    style={{
                      width: 36,
                      textAlign: 'right',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    rowSpan={4}
                  >
                    <Checkbox
                      checked={engaged || this.state.checked}
                      onClick={(e) => e.stopPropagation()}
                      onCheck={(event, checked) => {
                        this.setState({ checked }, (then) => {
                          this.props.onCheck(this.state.id, checked, this);
                        });
                      }}
                      /** disabled checkbox if job is engaged with candidate */
                      disabled={engaged}
                      iconStyle={this.state.openedColor}
                    />
                  </Table.Cell>
                )}

                {/* R1C1 | ROLE */}
                <Table.Cell
                  className="first-item ui-job-card-r1c1 no-truncate p-0"
                  style={{ width: 256 }}
                >
                  {this.parent.state.tab === 'Recent'
                    ? recent
                    : this.parent.state.tab === 'Employer'
                      ? employerName
                      : this.parent.state.tab === 'Role'
                        ? roleName
                        : jobTitle}
                </Table.Cell>

                {/* R1C2 | JOB TITLE */}
                <Table.Cell
                  className="mid-col ui-job-card-r1c2 no-truncate p-0"
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: 256 }}
                >
                  {this.parent.state.tab === 'Recent'
                    ? jobTitle
                    : this.parent.state.tab === 'Employer'
                      ? jobTitle
                      : this.parent.state.tab === 'Role'
                        ? jobTitle
                        : employerName}
                </Table.Cell>

                {/* R1C3 | EMPLOYER NAME */}
                <Table.Cell
                  className="mid-col ui-job-card-r1c3 no-truncate p-0"
                  style={{ width: 256 }}
                >
                  {this.parent.state.tab === 'Recent'
                    ? employerName
                    : this.parent.state.tab === 'Employer'
                      ? roleName
                      : this.parent.state.tab === 'Role'
                        ? employerName
                        : roleName}
                </Table.Cell>

                {/* R1C4 | LOCATIONS */}
                <Table.Cell
                  title={_locationsString}
                  className="mid-col ui-job-card-r1c4 no-truncate p-0"
                  style={{ width: 256 }}
                >
                  {_locationsString || <i>&mdash;</i>}
                </Table.Cell>

                {/* R1C5 | EMPLOYEES */}
                <Table.Cell
                  title={`${this.state.employer.employeeCount} | Employee Count`}
                  className="mid-col ui-job-card-r1c5 no-truncate p-0"
                >
                  {this.state.employer.employeeCount ? (
                    <>
                      ~{this.state.employer.employeeCount}
                      &nbsp;employees&nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    <i>&mdash;</i>
                  )}
                </Table.Cell>

                {/* R1C6 | EXPERIENCE */}
                <Table.Cell
                  title="Experience"
                  className="mid-col ui-job-card-r1c6 no-truncate p-0"
                  style={{ width: 256 }}
                >
                  {!!this.state.minYearsOfExperience ? (
                    <>
                      {this.state.minYearsOfExperience}+ years
                      &nbsp;&nbsp;&nbsp;
                    </>
                  ) : (
                    <i>&mdash;</i>
                  )}
                </Table.Cell>

                {/* R1-4C7 | OPTIONS */}
                <Table.Cell
                  // title="Options"
                  className="ui-job-card-r1-4c7 no-truncate pr-0"
                  rowSpan={4}
                  style={{ width: 160 }}
                >
                  <Box row wAuto alignRight className='px-1'>

                    <IconButton
                      title={t('jobs.card.copyButton.ariaLabel.label')}
                      icon='content_copy'
                      iconStyle={this.state.openedColor}
                      onClick={_onClickCopyJob}
                    />

                    <Checkbox
                      title="Click to Starred"
                      checked={this.state.starred}
                      onCheck={this.onCheckStar}
                      checkedIcon='star'
                      icon='star_border'
                      iconStyle={this.state.openedColor}
                    />

                    <MoreOptionsMenu
                      row={{ original: { ...this.state } }}
                      context={{ config: { moreMenuOptions: moreMenuOptionsJobs } }}
                    />

                    <IconButton
                      title='Click to expand'
                      icon={!jobId ? 'chevron_right' : !!this.state.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}
                      iconStyle={this.state.openedColor}
                      onClick={_toggleCard}
                    />

                  </Box>
                </Table.Cell>
              </Table.Row>

              {/* COLLAPSED VIEW ROW 02 */}
              <Table.Row>
                {/* R2C1 | RECRUITER OWNERSHIP? */}
                <Table.Cell
                  title={!Core.isAdminOrCoordinator() ? 'My Sourcing' : null}
                  className="ui-job-card-r2c1 no-truncate p-0"
                >
                  {!Core.isAdminOrCoordinator()
                    ? this.isRecruiterHasAssignedThisJob()
                    : null}
                </Table.Cell>

                {/* R2C2 | FLAGS */}
                <Table.Cell
                  title="Flags"
                  className="mid-col ui-job-card-r2c2 no-truncate p-0"
                >
                  {this.state.flags
                    .map((id) => Definition.getLabel('flags', id))
                    .join(', ') || <i>&mdash;</i>}
                </Table.Cell>

                {/* R2C3 | VISA */}
                <Table.Cell
                  title="Visa"
                  className="mid-col ui-job-card-r2c3 no-truncate p-0"
                >
                  {this.state._visaTransfer || <i>&mdash;</i>}
                </Table.Cell>

                {/* R2C4-6 | TECHNICAL SKILLS AND ML SCORE? */}
                <Table.Cell
                  title={
                    !!this.state.engagement
                      ? hoverDisplay(this.state.engagement)
                      : `Technical Skills ${this.state._agnosticTechnicalSkillsJobCard}`
                  }
                  className="mid-col ui-job-card-r2c4-6 no-truncate p-0"
                  style={{ wordSpacing: 3 }}
                  colSpan={3}
                >
                  {this.state._agnosticTechnicalSkillsJobCard || <i>&mdash;</i>}
                </Table.Cell>
              </Table.Row>

              {/* COLLAPSED VIEW ROW 03 | ENGAGEMENTS STATS */}
              {!!Object(this.props.extraInfo).displayEngagements &&
                this.state.engagements.length ? (
                <Table.Row>
                  {/* R3C2-6 */}
                  <Row2Col1
                    key={this.state.id}
                    job={this.state}
                  />
                </Table.Row>
              ) : null}

              {/* COLLAPSED VIEW ROW 04 | RECRUITER SOURCE */}
              {Core.isAdmin() && !!recruiterSourceName && (
                <Table.Row>
                  {/* R4C1-6 */}
                  <Table.Cell
                    title="Source Recruiters"
                    colSpan={6}
                    className="pr-0 ui-job-card-r4c1"
                  >
                    <div className="overflow-auto">
                      Source&nbsp;:&nbsp;&nbsp;{recruiterSourceName}
                    </div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider />

          {/** EXPANDED VIEW - only visible on `/job/view/:id` route */}
          {jobId && (
            <Collapse className="expanded-view p-0" in={jobId && !!this.state.expanded}>

              <JobDetails job={this.state} />

              <div
                className="col12 normal-padding cursor-pointer inline-blocks purples-over"
                style={{ margin: '0 0 -8px 0' }}
                onClick={(event) => {
                  try {
                    this.parent.scroller.scrollTo(
                      0,
                      document.getElementById(`job_card__table__${job.id}`).offsetTop - 80
                    );
                  } catch (ex) {
                    console.warn(ex);
                  }
                  this.handleToggle(event);
                }}
              >
                <span className="anchor">Show Less</span>
                <i className="material-icons c-purple">arrow_drop_up</i>
              </div>

            </Collapse>
          )}

        </Card>
      </>
    );
  }
}

class Row2Col1 extends Component {
  render() {
    const { job } = this.props;
    const engagements = job.engagements;
    let { render } = getEngagementsByState.getEngagementByGroup(
      engagements,
      job
    );
    const componentShowMore = (
      <>
        <span className="show-more anchor">
          {job.expanded ? 'Show Less' : 'Show More'}
        </span>
        <i
          className="material-icons anchor"
          style={{ ...job.rightArrow, textDecoration: 'none' }}
        >
          arrow_drop_down
        </i>
      </>
    );

    const componentEngagements = (
      <div>
        {render.map((grp, index) => {
          return (
            <div key={index} title={`${grp}`}>
              {grp}
            </div>
          );
        })}
        <br />
      </div>
    );

    return (
      <Table.Cell
        onClick={this.props.onClick}
        className="first-col v-align-mid ui-job-card-r3c1-6"
        style={{
          overflow: 'none',
          whiteSpace: 'nowrap',
        }}
        colSpan={6}
      >
        {Core.isAdminOrCoordinator() ? componentEngagements : componentShowMore}
      </Table.Cell>
    );
  }
}

export default withTranslation()(JobCard);
