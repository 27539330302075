import MuiBox from '@mui/material/Box';
import { isString } from 'lodash';
import React from 'react';
import {
  hasNoAlignment,
  hasNoSize,
  joinClassName,
  mapWrapper,
  THEME__SIZE__LARGE,
  THEME__SIZE__MEDIUM,
  THEME__SIZE__SMALL
} from '../Libraries/Theme.lib';
import Wrapper from './Wrapper';

export default function Box({
  component = MuiBox,
  title = '',
  children,
  className = '',
  style = {},
  // ---- //
  acl = true,
  display = true,
  debug = false,
  column = false,
  row = false,
  wrap = false,
  noWrap = false,
  relative = false,
  scroll = false,
  scrollX = false,
  scrollY = false,
  alignLeft = false,
  alignCenter = false,
  alignRight = false,
  spaceBetween = false,
  small = false,
  size = THEME__SIZE__MEDIUM,
  large = false,
  flex1 = false,
  hAuto = false,
  wAuto = false,
  w100 = false,
  h100 = false,
  mr = false,
  // ---- //
  paperStyle = undefined,
  fullWidth = undefined,
  yearsOfExperience = undefined,
  jobId = undefined,
  // ---- //
  wrapperProps = {},
  ...props
}) {
  if (!acl) { return null; }
  if (small) { size = THEME__SIZE__SMALL; }
  if (large) { size = THEME__SIZE__LARGE; }
  if (fullWidth) { w100 = true; }

  const _className = [
    flex1 && 'flex-1',
    column && 'flex-column',
    row && 'flex-row',
    hAuto && 'h-auto',
    h100 && 'h-100',
    (
      (
        w100
      ) ? (
        'w-100'
      ) : (
        wAuto
      ) && (
        'w-auto'
      )
    ),
    (
      (
        alignLeft
      ) ? (
        'flex-align-left-center'
      ) : (
        alignCenter
      ) ? (
        'flex-align-center'
      ) : (
        alignRight
      ) ? (
        'flex-align-right-center'
      ) : (
        spaceBetween
      ) ? (
        'space-between'
      ) : (
        hasNoAlignment({ className, style })
      ) && (
        (row && 'flex-align-left-center')
        ||
        (column && 'flex-align-left-top')
      )
    ),
    (
      (
        (size === THEME__SIZE__SMALL)
      ) ? (
        'f-sm'
      ) : (
        (size === THEME__SIZE__LARGE)
      ) ? (
        'f-lg'
      ) : (
        hasNoSize({ className, style })
      ) && (
        'f-md'
      )
    ),
    wrap && 'flex-wrap',
    noWrap && 'flex-no-wrap',
    relative && 'relative',
    scroll && 'scroll',
    scrollX && 'scroll-x',
    scrollY && 'scroll-y',
    (display === false) && 'd-none',
    mr && `mr-${isString(mr) ? mr : 1}`,
    className,
  ];

  mapWrapper(
    {
      role: 'Box',
      props: wrapperProps,
      assign: {
        ...props,
        component,
        title,
        children,
        className: joinClassName(_className),
        style,
      }
    }
  );

  return acl && (
    <Wrapper {...wrapperProps} />
  );
}

export const RefBox = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} />
));
