import Core from '../../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from '../../../../lib/Definition';
import EngagementDisagreement from '../../../../lib/EngagementDisagreement';
import {
  QUERY__MATCH_DECISIONS
} from '../../../../lib/queries/MatchDecisions.query';
import {
  compileText
} from '../../../../lib/String.lib';
import annotatorActions from '../../../../lib/tools/annotatorActions';
import getMisMatchThumbsAction from '../../../../lib/tools/getMisMatchThumbsAction';
import {
  ListPipeController
} from '../../List/ListPipe';

export default async function sendEmailOnAnnotatorAndMatcherConflict({
  engagement = {},
  matchStrength,
  disagreementObj
}) {
  if (!!ListPipeController().state.annotatorMode) {
    if (!disagreementObj.engagementId) {
      return Core.showError(
        `Missing disagreement's engagement id (${disagreementObj.engagementId})`,
        new Error()
      );
    }
    const getMatcherEmail = !!engagement.id
      ? engagement.matchedByWho.email
      : null;
    const getMatcherMatchStrength = !!engagement.id
      ? Definition.getLabel(
        DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
        engagement.matchStrength
      )
      : null;
    const newMatchStrength = matchStrength;
    return EngagementDisagreement.get(
      (resp) => {
        let result = {};
        let oldNotEqualsNew = false;

        if (resp.length > 1) {
          for (let i = 0; i < resp.length; i++) {
            let target = resp[i];
            result = getMisMatchThumbsAction.getMisMatch({
              engagement,
              annotatorMatchStrength: newMatchStrength,
              oldMatchStrength: target.matchDecision,
              checkOldState: false,
            });

            if (result.newMS !== result.oldMS) {
              oldNotEqualsNew = true;
              break;
            }
          }
        } else {
          let oldMatchStrength = !!engagement.id
            ? engagement.matchStrength
            : null;

          result = getMisMatchThumbsAction.getMisMatch({
            engagement,
            annotatorMatchStrength: newMatchStrength,
            oldMatchStrength,
            checkOldState: true,
          });

          if (result.newMS !== result.oldMS) {
            oldNotEqualsNew = true;
          }
        }
        if ((!!engagement.id || resp.length > 1) && oldNotEqualsNew) {
          annotatorActions.sendAnnotatorEmail({
            annotatorMatchStrength: newMatchStrength,
            matcherMatchStrength: getMatcherMatchStrength,
            matcherEmail: getMatcherEmail,
            disagreementObj,
            engagement,
          });
        }
      },
      compileText(
        QUERY__MATCH_DECISIONS,
        { ENGAGEMENT_ID: disagreementObj.engagementId }
      )
    );
  }
  return null;
}
