import {
  isNumber
} from 'lodash';
import {
  trim
} from '../String.lib';

/**
 * Fixes the HTML content generated by Quill editor to handle nested lists and adjust the list item styling 
 * based on indentation levels.
 *
 * @param {string} html - The HTML string generated by Quill editor.
 * @returns {string} - The fixed HTML string with proper list nesting and styles.
 */
export default function fixQuillHtml(html) {
  // Create a DOMParser to parse the input HTML string.
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    trim(html),
    'text/html'
  );

  // Select all ordered and unordered lists in the parsed document.
  const lists = doc.querySelectorAll('ol, ul');

  lists.forEach(list => {
    // Convert the children (list items) into an array for easier manipulation.
    const items = Array.from(list.children);

    // Array to keep track of nested child lists.
    let childrenList = [];

    items.forEach((item) => {
      // Get the indent level from the item's class (e.g., ql-indent-1).
      const indent = parseInt(item.className.match(/ql-indent-(\d+)/)?.[1] || '0');

      if (indent) {
        // If the indent is deeper than the current nested list, create a new nested list.
        if (indent > childrenList.length) {
          childrenList.unshift(document.createElement(list.tagName)); // Create new list (ol/ul).

          // Append the newly created list to the previous one or to the main list.
          if (childrenList[1]) {
            childrenList[1].appendChild(childrenList[0]);
          }
          else {
            list.appendChild(childrenList[0]);
          }
        }

        // If the indent is less, remove the last nested list from the stack.
        if (indent < childrenList.length) {
          childrenList.shift();
        }

        // Remove the class related to the indentation and reset the item class.
        item.classList.remove(`ql-indent-${indent}`);
        item.removeAttribute('class');

        // Get the first letter of the item's text content
        const firstLetter = item.textContent.trim().charAt(0);

        if (!!list.tagName.match(/ol/i)) {
          // Intercalate the list style types based on the first letter's case
          const listStyleTypes = (
            (
              (firstLetter === firstLetter.toUpperCase()) ||
              isNumber(firstLetter)
            ) ? (
              ['lower-alpha', 'lower-roman', 'decimal']
            ) : (
              ['upper-alpha', 'upper-roman', 'decimal']
            )
          );

          // Apply the list style type based on the indent level
          item.style['list-style-type'] = listStyleTypes[(indent - 1) % listStyleTypes.length];
        }

        // Append the item to the current nested list.
        childrenList[0].appendChild(item);
      }
      else {
        // If no indent, reset the childrenList and append the item to the main list.
        childrenList = [];
        list.appendChild(item);
      }
    });
  });

  // Return the fixed HTML content as a string.
  return doc.body.innerHTML;
}
