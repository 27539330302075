import useState from '../../lib/hooks/useState.hook';
import { THEME__COLOR__SUCCESS } from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Dialog from '../Layout/Wrappers/Dialog';
import Icon from '../Layout/Wrappers/Icon';
import Typography from '../Layout/Wrappers/Typography';

export default function SuccessDialog({
  Controller = {},
  reference = 'successDialog',
  icon = 'check_circle',
  iconColor = THEME__COLOR__SUCCESS,
  actions = [],
  successDialog = {},
  ...props
}) {
  const _default = { open: false, message: '' };
  const [{
    open = false,
    message = '',
  }, updateState] = useState(_default);

  Object.assign(Controller, {
    [reference]: Object.assign(successDialog,
      {
        async open(message) {
          await updateState({ open: true, message });
        },
        async close(event) {
          await updateState(_default);
        }
      }
    )
  });

  return (
    <Dialog
      open={open}
      message={
        <Box column wAuto alignCenter className='m-1'>
          <Icon
            color={iconColor}
            style={{ fontSize: 72 }}
            icon={icon}
          />
          <Typography large blackDark className='mt-2'>
            {message}
          </Typography>
        </Box>
      }
      actions={actions}
      onClose={successDialog.close}
      paperStyle={{ minWidth: 400 }}
    />
  );

};
