import {
  Select as AntDSelect
} from "antd";
import {
  useState
} from "react";
import AppUI from '../../../dictionaries/AppUI.dic';
import { joinClassName } from '../Libraries/Theme.lib';

const { Option } = AntDSelect;

/**
 * @todo look and change MultipleSelect implementations to use "<Menu multiple/>"
 */
export default function MultipleSelect({
  name,
  placeholder = 'Select multiple items',
  data = [],
  value,
  multiple = 'multiple',
  onChange = () => null,
  onSearch = () => null,
  onClose = () => null,
  onDeselect = () => null,
  popupMatchSelectWidth,
  optionLabelProp,
  maxTagCount = 'responsive',
  showOnData = false,
  dropdownStyle,
  className = '',
  style = {},
  ...props
}) {

  const [prev, setPrev] = useState();
  const [open = false, setOpen] = useState();

  function useDropdownVisibleChange(open) {
    return {
      _onDropdownVisibleChange: (open) => {
        open ? onSearch('') : onClose(value);
        console.debug('_onDropdownVisibleChange', open);
        setOpen(open);
      },
    }
  }
  const { _onDropdownVisibleChange } = useDropdownVisibleChange();

  if ((JSON.stringify(value) !== JSON.stringify(prev)) && !MultipleSelect.busy) {
    MultipleSelect.busy = true;
    onSearch('');
    setPrev(value);
    MultipleSelect.busy = false;
  }

  const children = data.map(({ id, label }, index) => (
    <Option
      key={`option+${name}.${id}(required-by-react)`}
      value={id}
      label={label}
    >
      {label}
    </Option>
  ));

  // console.debug({ data, value });

  function _onChange(value) {
    console.debug('onChange', value);
    onChange(value);
  }

  function _onSearch(value) {
    console.debug('onSearch', value);
    onSearch(value);
  }

  function _onDeselect(_value) {
    console.debug('onDeselect', value.filter(v => v !== _value), open);
    onDeselect(value.filter(v => v !== _value), open);
  }

  return (showOnData ? !!data.length : true) && (
    <AntDSelect
      {...props}
      mode={multiple}
      allowClear
      value={value}
      onChange={_onChange}
      onSearch={_onSearch}
      onDropdownVisibleChange={_onDropdownVisibleChange}
      onDeselect={_onDeselect}
      showSearch
      className={joinClassName(['styled', className])}
      style={{ width: '100%', ...style }}
      placeholder={placeholder || AppUI.placeholder.multiple}
      optionLabelProp={optionLabelProp || 'label'}
      optionFilterProp="children"
      filterOption={(input, option) => {
        if (typeof option.children === 'string') {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      }}
      maxTagCount={maxTagCount}
      disabled={false}
      virtual={false}
      popupMatchSelectWidth={popupMatchSelectWidth}
      dropdownStyle={
        {
          margin: -10,
          borderRadius: 4,
          zIndex: 10000,
          ...dropdownStyle
        }
      }
      notFoundContent={<span>No options</span>}
    >
      {children}
    </AntDSelect>
  )
}
