import {
  useRef
} from 'react';
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import {
  isValidDate
} from '../../lib/Date.lib';
import Engagement, {
  getEngagementUrl
} from '../../lib/Engagement';
import globalErrorHandler from '../../lib/Error/globalErrorHandler.fun';
import {
  Fun
} from '../../lib/Function.lib';
import useState from '../../lib/hooks/useState.hook';
import {
  Obj
} from '../../lib/Object.lib';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import DatePickerRange from '../Layout/Wrappers/DatePickerRange';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Icon from '../Layout/Wrappers/Icon';
import Link from '../Layout/Wrappers/Link';
import {
  PLACEMENT__TOP_START
} from '../Layout/Wrappers/StyledTooltip';
import {
  AdminToolsController
} from './Tools';

export default function ToolSyncStreakWithEngagement(props) {
  const [{
    dateRange = [],
    engagements = []
  }, updateState] = useState();
  const mem = useRef({});
  const {
    busy = false,
    ready = false,
    results = false,
    DatePickerRangeController = Obj()
  } = mem.current;
  const _cleanResults = async (event) => {
    mem.current.results = false;
    await updateState({ dateRange: [] });
    Fun(AdminToolsController().cleanResults)();
    Fun(DatePickerRangeController.reset)();
  }
  const _setResults = (value) => {
    return Fun(AdminToolsController().setResults)(value);
  }
  const _fetchEngagements = async (values = []) => {
    mem.current.busy = true;
    try {
      mem.current.ready = false;
      if (
        isValidDate(values[0]) &&
        isValidDate(values[1])
      ) {
        await updateState({
          engagements: await readLoopbackRecord({
            collection: COLLECTION__ENGAGEMENTS,
            where: {
              createdAt: {
                between: values
              }
            },
            fields: ['id']
          }).then((results = []) => {
            _setResults(
              `Engagements: ${results.length}`
            );
            return Arr(results);
          })
        });
        mem.current.results = true;
        mem.current.ready = true;
      }
      else {
      }
    }
    catch (error) {
      globalErrorHandler(error);
    }
    mem.current.busy = false;
    return updateState({ dateRange: values });
  }
  const _queueEngagementUpdates = async () => {
    mem.current.ready = false;
    for (const engagement of engagements) {
      try {
        await Engagement.update(engagement, { lastAction: Date.now() });
        _setResults(
          <>
            ✅ Engagement <Link url={getEngagementUrl({ engagement })}>${engagement.id}</Link> sync queued
          </>
        );
      }
      catch (error) {
        _setResults(
          <>
            ❌ Engagement <Link url={getEngagementUrl({ engagement })}>${engagement.id}</Link> update error: {error}
          </>
        );
      }
    }
    mem.current.results = true;
    _setResults(`${engagements.length} engagements processed`);
  }
  return (
    <Fieldset
      title="Sync Engagement's Streak boxes"
      subtitle='Select a date range for engagements'
      info='The range date is applied to "createdAt"'
    >
      <DatePickerRange v2
        values={dateRange}
        onChange={_fetchEngagements}
        reference={(api) => (mem.current.DatePickerRangeController = api)}
      />
      <Box alignRight className='mt-1'>
        <Button outlined mr1 minW120
          acl={!!results}
          onClick={_cleanResults}
        >
          Clean results
        </Button>
        <Button primary disabled={NOT(ready) || busy}
          className='w-50'
          onClick={_queueEngagementUpdates}
        >
          Synchronize
          <Icon mr
            acl={NOT(ready)}
            title='Select a date range first'
            placement={PLACEMENT__TOP_START}
            icon='help_outlined'
            style={{ position: 'absolute', right: 5 }}
          />
        </Button>
      </Box>
    </Fieldset>
  );
}
