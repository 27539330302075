import EmailPreview from '../../../components/Dialogs/EmailPreview';
import Button from '../../../components/Layout/Wrappers/Button';
import Core from '../../Core';
import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  Fun
} from '../../Function.lib';
import {
  Obj
} from '../../Object.lib';
import {
  sendSafeEmail
} from '../../services/Email/Email.lib';

const KEY__CAVE_DIALOG = 'cave_dialog';

/**
 * Opens a dialog to preview and send an email, with options for handling email responses.
 *
 * @param {Object} params - Parameters for opening the CAVE dialog.
 * @param {Object} [params.engagement={}] - The engagement object containing details like boxKey.
 * @param {Function} [params.generator=Fun] - Function that generates the email preview content.
 * @param {boolean} [params.waitForEmailResponse=true] - Determines whether to wait for an email response before resolving.
 * @returns {Promise<string>} - A promise that resolves to 'send' or 'cancel' based on user action.
 */
export default function openDialogCAVE({
  engagement = {},
  generator = Fun,
  waitForEmailResponse = true,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      Core.dialog.open({
        title: <>Represent Email</>,
        content: (
          <EmailPreview
            ref={(self) => Obj(Core.setKeyValue(KEY__CAVE_DIALOG, self))}
            {...Obj(await generator({ engagement }))}
          />
        ),
        actions: [
          <Button outlined minW120
            onClick={(event) => {
              resolve('cancel');
            }}
          >
            Cancel
          </Button>,
          <Button primary minW120
            onClick={async (event) => {
              try {
                const params = Core.getKeyValue(KEY__CAVE_DIALOG).getParams();
                if (waitForEmailResponse) {
                  Core.showLoader(
                    <>
                      Processing, please wait until it finishes...
                    </>
                  );
                  await sendSafeEmail({
                    ...params,
                    source: 'CandidateEmail.lib.js',
                    boxKey: engagement.boxKey,
                  });
                  Core.showSuccess('Email sent');
                }
                else {
                  sendSafeEmail({
                    ...params,
                    source: 'CandidateEmail.lib.js',
                    boxKey: engagement.boxKey,
                  });
                }
                resolve('send');
              }
              catch (error) {
                throw new Error(error);
              }
            }}
          >
            Send
          </Button>
        ],
        paperStyle: {
          width: 1200,
        }
      });
    }
    catch (error) {
      globalErrorHandler(error);
      reject(error);
    }
  });
}
