import {
  useRef
} from "react";
import {
  join
} from '../../../lib/Array.lib';
import Candidate from "../../../lib/Candidate";
import {
  MDASH
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE
} from '../../../lib/Definition';
import {
  validateURL
} from "../../../lib/GenericTools.lib";
import {
  getPersonName
} from '../../../lib/Object.lib';
import {
  openMessageEmailPreview
} from "../../../lib/services/Email/Email.lib";
import DoubleContentDrawer from "../../Candidates/ResumePdf/DoubleContentDrawer";
import TextCVContentDrawer from "../Job/TextCVContentDrawer";
import MatchDrawer from "../List/MatchDrawer";
import CandidateHeaderChips from './CandidateHeaderChips';

export default function CandidateHeader({
  candidate = {},
  job = {}
}) {
  const {
    account: recruiter = {}
  } = candidate;
  const textCVDrawer = useRef(null);
  const doubleContentDrawer = useRef(null);
  const _handleDrawer = (ref) => (event) => {
    if (ref === "textCV") {
      textCVDrawer.current.handleOpen();
    }
    if (ref === "doubleContentDrawer") {
      doubleContentDrawer.current.handleOpen();
    }
  }
  const _candidateName = getPersonName(candidate);
  const _recruiterName = getPersonName(recruiter);
  return !!candidate.id && (
    <div className='flex-column py-05' style={{ minHeight: 120 }}>
      <div className='mt-1 p-05'>
        <strong>
          {
            (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) ? (
              <a href={`#/candidate/edit/${candidate.id}`} target="_blank" rel="noreferrer">{_candidateName}</a>
            ) : (
              _candidateName
            )
          }
        </strong>
        <span className='f-sm c-black-medium'> - submitted by </span>
        <span className="f-md">{_recruiterName}</span>
        <div>
          <span className='c-black-medium f-sm' title='Candidate Recruiters'>{join(candidate?._contactNames)}</span>
        </div>
      </div>
      <div className="d-flex flex-align-left-center f-sm p-05" >
        {!!job.id && !!candidate.email &&
          <span className="anchor" onClick={e => openMessageEmailPreview({
            candidateId: candidate.id,
            recruiterId: candidate.accountId,
            jobId: job.id,
            employerId: job.employerId
          })}>Email</span>
        }
        {validateURL(candidate.linkedInURL) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={e => Core.openPopUp(candidate.linkedInURL, 1200)}>LinkedIn</span>
          </>
        }
        {validateURL(candidate.gitHubURL) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={e => Core.openPopUp(candidate.gitHubURL, 1200)}>Github</span>
          </>
        }
        {validateURL(candidate.stackoverflowUrl) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={e => Core.openPopUp(candidate.stackoverflowUrl, 1200)}>Stackoverflow</span>
          </>
        }
        {validateURL(candidate.resumePdfUrl) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={e => Core.openPopUp(Candidate.getMyPdfUrl(candidate), 1200)}>CV</span>
          </>
        }
        {!!job.id && validateURL(candidate.resumeTxtUrl) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={_handleDrawer("textCV")}>Skills</span>
          </>
        }
        {!!job.id && validateURL(candidate.resumePdfUrl) && validateURL(candidate.resumeTxtUrl) &&
          <>
            <span className='mx-1'>{MDASH}</span>
            <span className="anchor" onClick={_handleDrawer("doubleContentDrawer")}>Text CV/CV</span>
          </>
        }
      </div>
      {!!job.id &&
        <CandidateHeaderChips
          candidate={candidate}
          job={job}
        />
      }
      {!!job.id &&
        <MatchDrawer
          ref={textCVDrawer}
          content={
            <TextCVContentDrawer
              candidate={candidate}
              job={job}
            />
          }
        />
      }
      {!!job.id &&
        <MatchDrawer
          ref={doubleContentDrawer}
          content={
            <DoubleContentDrawer
              candidate={candidate}
              job={job}
            />
          }
        />
      }
    </div>
  );
}
