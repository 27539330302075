import {
  ROLE__EMPLOYER_RECRUITER
} from '../../../lib/services/Accounts/Session.lib';
import AccountEmployerDetails from './AccountEmployerDetails';
import AccountRecruiterDetails from './AccountRecruiterDetails';

export default function AccountDetails(props) {
  let { account, model } = props;
  account = account || model;
  return (
    (account.role === ROLE__EMPLOYER_RECRUITER)
      ? <AccountEmployerDetails account={account} />
      : <AccountRecruiterDetails account={account} />
  );
}

