import {
  Arr
} from '../../../../lib/Array.lib';
import Definition from '../../../../lib/Definition';
import {
  Obj
} from '../../../../lib/Object.lib';


export function evaluateChipGroup({ have, candidate }) {
  let defRaw = Definition.getRawDef(have.key || 'technicalSkills');
  let groupEntry = Obj(Arr(defRaw.groups).find((group) => group.id === have.value));
  const tagIds = Arr(groupEntry.ids);
  if (!!have.category && (have.category === 'technicalSkills')) {
    return have.match = !![
      ...Arr(candidate[have.category]),
      ...Arr(candidate['_strongTechnicalSkills']),
      ...Arr(candidate.techSkillsInResume),
    ].find((tagId) => tagIds.includes(tagId));
  }
  return have.match = !!Arr(
    candidate[!!have.key ? have.key : 'technicalSkills']
  ).find((tagId) => tagIds.includes(tagId));
}
