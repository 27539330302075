import React, { useState } from "react";
import Core from "../../lib/Core";
import Job from "../../lib/Job";
import copyHtml from "../../lib/tools/copyHtml";
import { getLocation } from '../../lib/URL.lib';
import Button from '../Layout/Wrappers/Button';
import Menu from '../Layout/Wrappers/Menu';

function bulkCopy({ t, selected, type }) {
  if (!selected || !selected.length) {
    Core.showWarning("Please select at least 1 job to copy. The selected jobs’ description will be copied to paste elsewhere");
    return;
  }
  let body = '';
  selected.forEach(model => {
    body += Job.getPreview(model, type);
    body += "<br/><hr/>";
  });
  console.debug('body', body);
  copyHtml(`
    ${body}
  `)
    .then((em) => {
      Core.log("Copy email command was successful");
      Core.showSuccess(
        t('jobs.copyButton.successful')
      );
    })
    .catch((ex) => {
      Core.log("Oops, unable to copy");
      Core.showError("Fail copy!");
    });
}

export default function ButtonBulkCopy({ enhancedReactTableContext, style, className }) {
  const [anchorEl = null, setAnchorEl] = useState();
  const { t, rows } = enhancedReactTableContext;
  const selected = rows.filter(row => row.isSelected).map(row => row.original);
  function onClick(type) {
    return event => {
      setAnchorEl(null);
      bulkCopy({ t, selected, type });
    }
  }
  return (
    getLocation().match('/v3/jobs')
      ? (
        <div
          className={className}
          style={style}
        >
          <Button primary small minW120
            className="text-truncate"
            disabled={!selected.length}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            COPY JOB(S)
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={event => setAnchorEl(null)}
            options={[
              { id: 'long_jd', label: 'Long JD', onClick: onClick('large') },
              { id: 'short_jd', label: 'Short JD', onClick: onClick('short') }
            ]}
          />
        </div>
      )
      : null
  );
}
