
import {
  Checkbox as MuiCheckbox
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import * as React from 'react';
import { join } from '../../../lib/Array.lib';
import { NOT } from '../../../lib/GenericTools.lib';
import { Obj } from '../../../lib/Object.lib';
import {
  THEME__COLOR__PRIMARY,
  THEME__COLOR__SECONDARY
} from '../Libraries/Theme.lib';
import Box from './Box';
import Icon from './Icon';
import Typography from './Typography';

/**
 * 
 * @param {object} props
 * @param {boolean} props.checked
 * @param {function} props.onChange  (event,value) => null
 * @param {object} props.wrapperProps
 * @param {string} props.label
 * @param {object} props.labelProps
 * @param {object} props.tooltip
 * @param {object} props.placement  [tooltip:placement] PLACEMENT__*
 * @returns {JSX.Element}
 * @see https://mui.com/material-ui/api/checkbox/
 */
const Checkbox = React.forwardRef(({
  acl = true,
  label,
  labelProps,
  wrapperProps,
  inputProps,
  checkboxProps,
  children,
  tooltip,
  title,
  placement,
  checked,
  onCheck,
  onChange,
  color,
  primary,
  icon,
  uncheckedIcon,
  checkedIcon,
  iconStyle,
  className,
  style,
  size = 'medium',
  small,
  ...props
}, ref) => {

  if (!acl) { return null; }

  title = title || tooltip;
  label = label || children;
  checked = !!checked;
  onChange = onChange || onCheck || (async () => null);
  color = color || ((primary === true) ? THEME__COLOR__PRIMARY : THEME__COLOR__SECONDARY);
  icon = icon || uncheckedIcon;
  if (small) {
    size = 'small';
  }
  if (icon) {
    icon = <Icon icon={icon} style={iconStyle} />;
  }
  if (checkedIcon) {
    checkedIcon = <Icon icon={checkedIcon} style={iconStyle} />;
  }
  const _wrapperProps = {
    className: join([
      'nowrap truncate',
      NOT(icon) && 'mr-auto',
      className
    ], ' '),
    style,
    size,
    ...wrapperProps
  };
  const _labelProps = {
    ...labelProps,
    size,
    className: join(['f-md', Obj(labelProps).className], ' '),
  };
  const _props = {
    checked,
    onChange: (event) => onChange(event, !!event.target.checked),
    icon,
    checkedIcon,
    size,
    ...inputProps,
    ...checkboxProps,
    ...props
  };
  _props.className = join(['mr-1 my-05', small ? 'icon16' : 'icon24', _props.className], ' ');
  const _Component = (
    label ? (
      <FormGroup {..._wrapperProps}>
        <FormControlLabel
          control={<MuiCheckbox ref={ref} {..._props} />}
          label={<Typography {..._labelProps}>{label}</Typography>}
          className='tt-unset min-w-120 m-0 mr-1'
        />
      </FormGroup >
    ) : (
      <MuiCheckbox ref={ref} {..._props} {..._wrapperProps} />
    )
  );
  return (
    title ? (
      <Box title={title} placement={placement} {..._wrapperProps}>
        {_Component}
      </Box>
    ) : (
      _Component
    )
  );
});
export default Checkbox;
