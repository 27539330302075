import {
  Avatar
} from '@mui/material';
import React from 'react';
import {
  join
} from '../../../lib/Array.lib';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';

export function PendingCard({ model, large, className, style, children, titleClassName, avatarClassName }) {
  return (
    <div
      id='pending_card'
      className={join(['w-100 d-flex', model.selected ? 'bg-white-light' : '', className], ' ')}
      style={style}
    >
      <div
        id='pending_card__main'
        className='flex-1 p-1 d-flex'
        style={{ maxWidth: '100%' }}
      >
        {typeof model.image !== 'string' ? model.image : (
          <Avatar
            id='pending_card__avatar'
            src={model.image}
            className={`mr-1 bg-cyan-light ${large ? 'f-lg' : 'f-sm'} ${avatarClassName || ''}`.trim()}
            style={{ width: large ? 48 : 24, height: large ? 48 : 24 }}
          >
            {model.abbr}
          </Avatar>
        )}
        <div className='flex-1' style={{ width: 'calc(100% - 80px)' }}>
          <div className='flex-column'>
            <strong
              id='pending_card__title'
              className={join([
                large ? 'f-xl' : 'f-md',
                titleClassName
              ], ' ')}
            >
              {model.title}
            </strong>
            <div
              id='pending_card__subtitle'
              className='w-100'
            >
              {model.customSubtitle}
            </div>
            <small
              id='pending_card__sub1'
              className={join([
                'w-100 truncate statement',
                large ? 'f-lg' : 'f-sm'
              ], ' ')}
            >
              {model.sub1}
            </small>
            <small
              id='pending_card__sub2'
              className={join([
                'w-100 truncate c-black-medium',
                large ? 'f-md' : 'f-sx'
              ], ' ')}
            >
              {model.sub2}
            </small>
            <span
              id='pending_card__description'
              className={joinClassName([
                'w-100 c-gray-common',
                large ? 'f-lg' : 'f-sm'
              ])}
            >
              {model.description}
            </span>
            {children}
          </div>
        </div>
      </div>
      {model.selected && (
        <div
          id='pending_card__arrow'
          className='d-flex flex-align-center'
          style={{
            width: 16,
            backgroundColor: model.selected ? '#929292' : 'transparent'
          }}
        >
          <i className='material-icons c-white'>arrow_forward_ios</i>
        </div>
      )}
    </div >
  );
}
