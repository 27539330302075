import axios from 'axios';
import {
  NOT
} from '../../../../lib/Boolean.lib';
import {
  joinKeyName,
  trim
} from '../../../../lib/String.lib';
import getRemoteCandidateResumePdfUrl from './getRemoteCandidateResumePdfUrl.fun';
import getCandidateResumePdfAnnotations from './processCandidateResumePdfAnnotations.fun';

const cache = {};
export async function fetchCandidateResumePdfHighlightsFile({
  candidate = {},
  jobId,
  employerId,
}) {
  const url = getRemoteCandidateResumePdfUrl(candidate);
  const key = joinKeyName([url, jobId, employerId]);
  if (cache[key] === 'pending') { return null; }
  if (NOT(cache[key])) {
    try {
      cache[key] = 'pending';
      const endpoint = (
        trim(process.env.REACT_APP_FMS_API_URL) +
        '/get_highlighted_resume/'
      );
      const requestConfig = {
        method: 'POST',
        url: endpoint,
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
        data: {
          resume_url: url,
          annotations: await getCandidateResumePdfAnnotations({
            jobId,
            employerId,
          })
        }
      };
      const response = await axios.request(requestConfig);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      cache[key] = URL.createObjectURL(blob);
    }
    catch (error) {
      cache[key] = null;
      throw new Error(error);
    }
  }
  return cache[key];
}

export function getLocalUrlCandidateResumePdfHighlights({
  candidate = {},
  jobId,
  employerId,
}) {
  const url = getRemoteCandidateResumePdfUrl(candidate);
  const key = joinKeyName([url, jobId, employerId]);
  if (cache[key] === 'pending') { return ''; }
  return trim(cache[key]);
}
