import {
  Route,
  Routes
} from "react-router-dom";
import {
  EmployerManageTemplatesPage
} from '../components/Employers/EmployerManageTemplates/EmployerManageTemplatesPage';
import EmployerPendings from '../components/Employers/EmployerPendings/EmployerPendings';
import EmployerSettings from '../components/Employers/EmployerSettings';
import Navigate from '../components/Layout/Wrappers/Navigate';
import Login from '../components/Login/Login';

const EmployerUser = (props) => {
  console.debug('Employer routes');
  return (
    <Routes>

      {/** COMMON SECTIONS */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Login />} />
      <Route path="/employer/pendings" element={<EmployerPendings />} />
      <Route path="/employer/settings/:employerId" element={<EmployerSettings />} />
      <Route path="/employer/manage/templates/:employerId" element={<EmployerManageTemplatesPage />} />

      {/* DEFAULT */}
      <Route path='*' element={<Login />} />

    </Routes>
  );
}
export default EmployerUser;
