import React, {
  useState
} from 'react';
import {
  ENGAGEMENT__STATE_OPEN
} from '../../../dictionaries/Engagement.dic';
import {
  getLoopbackRecord
} from '../../../lib/services/BE/loopback.api';


export function CandidateEngagementsInfo(props) {
  let { candidateId, onLoad = async () => null } = props;
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  async function fetchEngagementsInfo() {
    await _updateState({ fetching: true, error: false });
    state.engagements = await getLoopbackRecord({
      collection: 'Engagements',
      where: {
        candidateId,
        state: ENGAGEMENT__STATE_OPEN
      },
      fields: ['id']
    }).catch(console.debug);
    if (Array.isArray(state.engagements)) {
      await _updateState(state);
      onLoad(state);
    }
    else {
      await _updateState({ error: true });
    }
    await _updateState({ fetching: false });
  }
  if (candidateId && !state.fetching && !state.error && !state.engagements) {
    fetchEngagementsInfo();
  }
  return (
    <></>
  );
}
