import _ from 'lodash';
import {
  useState
} from 'react';
import {
  join
} from '../../lib/Array.lib';
import {
  getBookmarkModel,
  mapBookmark
} from '../../lib/models/account';
import {
  getPathname,
  getSearch
} from '../../lib/URL.lib';
import {
  joinClassName
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import IconButton from '../Layout/Wrappers/IconButton';
import NavLink from '../Layout/Wrappers/NavLink';
import TextField from '../Layout/Wrappers/TextField';
import Typography from '../Layout/Wrappers/Typography';

function Bookmark({
  enhancedReactTableContext: {
    context: { location },
    updateUrlState,
  },
  bookmark = getBookmarkModel(),
  upsertBookmark,
  deleteBookmark,
  showNewBookmark,
  setShowNewBookmark,
}) {
  const isNewBookmark = (showNewBookmark !== undefined);
  const [label, setLabel] = useState();
  const [pathname, setPathname] = useState();
  const [search, setSearch] = useState();
  const _bookmark = mapBookmark({
    createdAt: bookmark.createdAt,
    label: (label !== undefined)
      ? label
      : isNewBookmark
        ? bookmark.createdAt
        : bookmark.label,
    pathname: (pathname !== undefined)
      ? pathname
      : isNewBookmark
        ? decodeURIComponent(getPathname())
        : bookmark.pathname,
    search: (search !== undefined)
      ? search
      : isNewBookmark
        ? decodeURIComponent(getSearch())
        : bookmark.search,
  });
  const updated = !_.isEqual(bookmark, _bookmark);
  const _mapBookmarkUrl = () => {
    return join([
      '/' + _bookmark.pathname
        .replace(/^\//, '')
        .split('?')[0]
        .replace(/\/$/, '')
        .replace(/v3\/v1\//, 'pro/'),
      _bookmark.search
    ], '?')
  }
  console.debug(
    'Bookmark...',
    '\n', _bookmark
  );
  return (isNewBookmark && !showNewBookmark) ? null : (
    <Box row w100 scrollX className={
      joinClassName([
        'pl-1 border-bottom',
        isNewBookmark ? 'bg-yellow-light' : ''
      ])
    }>
      {isNewBookmark ? (
        <Box row style={{ width: 'calc(100vw - 2rem)' }}>
          <Typography bold mr>
            Label
          </Typography>
          <TextField
            className="mr-2"
            value={_bookmark.label}
            onChange={(event, value) => setLabel(event.target.value)}
            style={{ width: 640 }}
          />
          <Typography bold mr>
            Pathname
          </Typography>
          <TextField
            className="mr-2"
            value={_bookmark.pathname}
            onChange={(event, value) => setPathname(event.target.value)}
          />
          <Typography bold mr>
            Search
          </Typography>
          <TextField
            className="mr-1"
            value={_bookmark.search}
            onChange={(event, value) => setSearch(event.target.value)}
          />
        </Box>
      ) : (
        <NavLink to={_mapBookmarkUrl()} exact>
          {_bookmark.label}
        </NavLink>
      )}
      <Box className="ml-auto">
        {isNewBookmark && (
          <IconButton
            className={updated ? 'bg-yellow-light mr-1' : 'mr-1'}
            onClick={(event) => {
              upsertBookmark({ bookmark: _bookmark });
              setShowNewBookmark(false);
              setLabel(undefined);
              setPathname(undefined);
              setSearch(undefined);
            }}
            disabled={!(upsertBookmark && updated)}
          >
            <i className="material-icons">save</i>
          </IconButton>
        )}
        {deleteBookmark && (
          <IconButton
            className="mr-1"
            onClick={(event) => {
              deleteBookmark(_bookmark);
              updateUrlState({ _updatedAt: new Date().toISOString() });
            }}
          >
            <i className="material-icons">delete</i>
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export { Bookmark, Bookmark as default };
