import FuzzySearch from 'fuzzy-search';
import {
  isEqual,
  sortBy
} from 'lodash';
import {
  Component
} from 'react';
import loader from '../../assets/images/loader.gif';
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import Definition from '../../lib/Definition';
import {
  joinKeyName,
  Str
} from '../../lib/String.lib';
import {
  THEME__COLOR__DEFAULT,
  THEME__COLOR__SECONDARY,
  THEME__VARIANT__FILLED,
  THEME__VARIANT__OUTLINED
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Chip from '../Layout/Wrappers/Chip';
import Dialog from '../Layout/Wrappers/Dialog';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import TextField from '../Layout/Wrappers/TextField';

class Chips extends Component {
  constructor() {
    super(...arguments);
    let items =
      this.props.items && this.props.items.length
        ? this.props.items
        : Definition.get(this.props.name);
    let additionalItems =
      this.props.additionalItems && this.props.additionalItems.length
        ? this.props.additionalItems
        : [];
    items = sortBy(items, (o) => o.label);
    //items.sort((a,b) => (a.label.toLowerCase()  - b.label.toLowerCase()));
    this.state = {
      selected: [],
      items,
      additionalItems,
      search: '',
      open: false,
    };
  }
  open = async (ev) => {
    const { hidePopupContent, onClickAddChipEvent = async () => null } = this.props;
    !!hidePopupContent && hidePopupContent();
    await onClickAddChipEvent();
    this.setState((state) => {
      state.open = true;
      this.setSelected(state);

      return state;
    });
  };

  componentDidMount() {
    this.setState((state) => {
      this.setSelected(state);
      return state;
    });
  }

  /** @todo look for all componentDidUpdate in product and use isEqual consistently to eval updates  */
  componentDidUpdate(prevProps) {

    if (NOT(isEqual(prevProps.items, this.props.items))) {
      this.setState({ items: this.props.items });
    }

    if (NOT(isEqual(prevProps.additionalItems, this.props.additionalItems))) {
      this.setState({ additionalItems: this.props.additionalItems });
    }

    if (NOT(isEqual(prevProps.values, this.props.values))) {
      this.setState({ selected: this.props.values }, () => {
        this.setState((state) => {
          this.setSelected(state);
          return state;
        });
      });
    }

  }

  setSelected = (state) => {
    state.selected = [];
    let propsValues = this.props.values.map((o) => {
      return typeof o === 'object' ? o : String(o);
    });
    if (!!state.additionalItems.length) {
      state.additionalItems.forEach((item) => {
        let found =
          typeof item === 'object'
            ? !!propsValues.find((v) =>
              typeof v === 'object'
                ? (String(v.id) === String(item.id) ||
                  String(v.label) === String(item.label)) &&
                v.key === item.key
                : !!~propsValues.indexOf(String(item.id))
            )
            : !!~propsValues.indexOf(String(item.id));
        if (found) {
          state.selected.push({ ...item });
          item.selected = true;
        } else {
          delete item.selected;
        }
      });
    }
    state.items.forEach((item) => {
      let found =
        typeof item === 'object'
          ? !!propsValues.find((v) =>
            typeof v === 'object'
              ? (String(v.id) === String(item.id) ||
                String(v.label) === String(item.label)) &&
              v.key === item.key
              : !!~propsValues.indexOf(String(item.id))
          )
          : !!~propsValues.indexOf(String(item.id));
      if (found) {
        state.selected.push({ ...item });
        item.selected = true;
      } else {
        delete item.selected;
      }
    });
  };

  apply = (ev) => {
    const { displayPopupContent } = this.props;
    !!displayPopupContent && displayPopupContent();
    this.setState((state) => {
      state.open = false;
      this.props.onChange &&
        this.props.onChange(
          state.selected.map((item) => item.id),
          state.selected
        );
      return state;
    });
  };

  findValue = (id) => {
    let item = {};
    let { items, additionalItems } = this.props;

    if (typeof id === 'object' && !!id.id) {
      if (items) {
        item =
          items.find(
            (item) =>
              (item.id === id.id || item.label === id.label) &&
              item.key === id.key
          ) || {};
      }

      if (!item.id && !!additionalItems && !!additionalItems.length) {
        item =
          additionalItems.find(
            (item) =>
              (item.id === id.id || item.label === id.label) &&
              item.key === id.key
          ) || {};
      }
    }

    if (typeof id === 'string') {
      if (items) {
        item = items.find((item) => item.id === id) || {};
      }
    }

    return item ? item.label : 'notFound';
  };

  chipOption = (model, index, selected) => {
    const onClickHandler = (model, selected) => (ev) => {
      if (selected) {
        this.setState(
          (state) => {
            state.selected = state.selected.filter(
              (item) => item.id !== model.id
            );

            if (!!state.items.find((item) => item.id === model.id)) {
              delete state.items.find((item) => item.id === model.id).selected;
            }

            if (!!state.additionalItems.find((item) => item.id === model.id)) {
              delete state.additionalItems.find((item) => item.id === model.id)
                .selected;
            }

            return state;
          },
          () => {
            if (!!this.props.withOutDialog) {
              this.props.onChange &&
                this.props.onChange(
                  this.state.selected.map((item) => item.id),
                  this.state.selected
                );
            }
          }
        );
      } else {
        if (!this.state.selected.find((item) => item.id === model.id)) {
          this.setState(
            (state) => {
              state.selected.push(model);
              state.items.find((item) => item.id === model.id).selected = true;
              return state;
            },
            () => {
              if (!!this.props.withOutDialog) {
                this.props.onChange &&
                  this.props.onChange(
                    this.state.selected.map((item) => item.id),
                    this.state.selected
                  );
              }
            }
          );
        }
      }
    };
    return !!this.props.chipOptionRenderer ? (
      this.props.chipOptionRenderer(
        model,
        index,
        selected,
        onClickHandler(model, selected)
      )
    ) : (
      <Chip
        key={
          joinKeyName([
            this.props.name,
            'chip_option',
            index,
            model.label
          ])
        }
        color={(!selected && model.selected) || selected ? THEME__COLOR__SECONDARY : THEME__COLOR__DEFAULT}
        variant={selected ? THEME__VARIANT__FILLED : THEME__VARIANT__OUTLINED}
        onClick={onClickHandler(model, selected)}
        label={model.label}
      />
    );
  };

  chipAdditionalOption = (model, index, selected) => {
    return (
      <Chip
        key={
          joinKeyName([
            this.props.name,
            'chip_additional_option',
            index,
            model.label
          ])
        }
        color={(!selected && model.selected) || selected ? THEME__COLOR__SECONDARY : THEME__COLOR__DEFAULT}
        variant={selected ? THEME__VARIANT__FILLED : THEME__VARIANT__OUTLINED}
        onClick={(ev) => {
          if (selected) {
            this.setState(
              (state) => {
                state.selected = state.selected.filter(
                  (item) => item.id !== model.id
                );
                delete state.additionalItems.find(
                  (item) => item.id === model.id
                ).selected;
                return state;
              },
              () => {
                if (!!this.props.withOutDialog) {
                  !!this.props.onChange &&
                    this.props.onChange(
                      this.state.selected.map((item) => item.id),
                      this.state.selected
                    );
                }
              }
            );
          }
          else {
            if (!this.state.selected.find((item) => item.id === model.id)) {
              this.setState(
                (state) => {
                  state.selected.push(model);
                  state.additionalItems.find(
                    (item) => item.id === model.id
                  ).selected = true;
                  return state;
                },
                () => {
                  if (!!this.props.withOutDialog) {
                    !!this.props.onChange &&
                      this.props.onChange(
                        this.state.selected.map((item) => item.id),
                        this.state.selected
                      );
                  }
                }
              );
            }
          }
        }}
      >
        {model.label}
      </Chip>
    );
  };

  mainHtmlWithDialog = () => {
    return (
      <Dialog
        title={this.props.heading || this.props.label}
        open={this.state.open}
        onClose={() => this.setState({ open: false })}
        actions={
          [
            <Button outlined minW120
              label="Cancel"
              onClick={(ev) => {
                const { displayPopupContent } = this.props;
                !!displayPopupContent && displayPopupContent();
                this.setState({ open: false });
              }}
            />,
            <Button primary minW120
              label="Apply"
              onClick={this.apply}
            />
          ]
        }
        paperStyle={{ width: 720 }}
        actionBar={
          <Box row w100
            acl={!this.props.withOutDialog}
          >
            <Icon xl mr icon='search'/>
            <TextField disabledBlurExport required autoFocus
              name="searcher"
              placeholder="Separate search terms by comma or space"
              value={this.state.search}
              onChange={(event, search) => this.setState({ search })}
            />
            {!!this.state.search.length && (
              <IconButton
                icon='cancel'
                onClick={(ev) => this.setState({ search: '' })}
              />
            )}
          </Box>
        }
      >
        {this.mainHtml()}
      </Dialog>
    );
  };

  mainHtml = () => {
    return (
      <Box column wAuto flex1 scrollY
        role='Chips__MainHtml'
      >

        {this.props.topRow}

        <Box row w100 noWrap
          acl={!!this.props.withOutDialog}
        >
          <Icon xl mr icon='search'/>
          <TextField disabledBlurExport required autoFocus
            name="searcher"
            placeholder="Separate search terms by comma or space"
            value={this.state.search}
            onChange={(event, search) => this.setState({ search })}
          />
          {!!this.state.search.length && (
            <IconButton
              icon='cancel'
              onClick={(ev) => this.setState({ search: '' })}
            />
          )}
        </Box>

        <Fieldset
          acl={!!this.state.selected.length}
          title={this.props.selectedTitle}
          endDivider
        >
          <Box row w100 wrap className='flex-align-left-top'>
            {this.state.selected.map((item, index) =>
              item.type === 'chipGroup'
                ? this.chipAdditionalOption(item, index, true)
                : this.chipOption(item, index, true)
            )}
          </Box>
        </Fieldset>

        {
          !!this.props.isPartialLoadingComp &&
          this.props.isPartialLoadingComp()
        }

        <Fieldset
          acl={!!this.state.additionalItems.length}
          title={this.props.groupingTitle}
          endDivider
        >
          <Box row w100 wrap className='flex-align-left-top'>
            {(!!this.state.search.length
              ? ((em) => {
                const result = [];
                const words = this.state.search
                  .trim()
                  .split(/,\s*|\s|\||;/)
                  .filter((term) => !!term.trim());
                words.forEach((word) => {
                  new FuzzySearch(this.state.additionalItems, ['label'], {
                    caseSensitive: false,
                  })
                    .search(word)
                    .forEach(
                      (item) =>
                        !result.find(
                          (resItem) => item.label === resItem.label
                        ) && result.push(item)
                    );
                });
                return result;
              })()
              : this.state.additionalItems
            ).map((item, index) => this.chipAdditionalOption(item, index))}
          </Box>
        </Fieldset>

        <Box className='mt-1'>
          {
            (
              !!this.props.isLoading
            ) ? (
              <div className="not-found inline-blocks">
                Loading&nbsp;
                <img alt="loading..." height="14" src={loader} />
              </div>
            ) : (
              <Fieldset
                title={
                  !!this.state.items.length &&
                  !!this.props.allSkillsTitle &&
                  this.props.allSkillsTitle
                }
              >
                <Box row w100 wrap className='flex-align-left-top'>
                  {
                    Arr(
                      !!this.state.search.length
                        ?
                        (
                          () => {
                            const result = [];
                            const words = Str(this.state.search).trim()
                              .split(/,\s*|\s|\||;/)
                              .filter(
                                (term) => !!term.trim()
                              );
                            words.forEach((word) => {
                              new FuzzySearch(this.state.items, ['label'], {
                                caseSensitive: false,
                              }).search(word)
                                .forEach(
                                  (item) => !result.find(
                                    (resItem) => item.label === resItem.label
                                  ) && result.push(item)
                                );
                            });
                            return result;
                          }
                        )()
                        :
                        this.state.items
                    ).map((item, index) => this.chipOption(item, index))
                  }
                </Box>
              </Fieldset>
            )
          }
        </Box>

      </Box>
    );
  };

  onDelete = (id) => (ev) => {
    this.props.onChange &&
      this.props.onChange(
        this.props.values.filter((_id) => _id !== id),
        this.props.values.filter((_id) => _id !== id)
      );
    this.props.onRemoveChip && this.props.onRemoveChip(id);
  };


  render() {

    const useDialog = NOT(this.props.withOutDialog);

    return (
      <>

        <Box acl={useDialog}>
          {
            (
              (
                !this.props.title
              ) ? (
                <Fieldset
                  role='Chips'
                  title={this.props.label}
                  subtitle={this.props.sub}
                >
                  <Box row className="mt-05">
                    <Box row wrap
                      role='ChipsBar'
                      className="w-100 py-1"
                    >
                      {!this.props.hideExternalValues &&
                        this.props.values.map((id, index) => {
                          let value = this.props.commonCase
                            ? this.findValue(id)
                            : Definition.getLabel(this.props.name, id);
                          const _key = joinKeyName([
                            this.props.name,
                            'tag',
                            index,
                            id,
                          ]);
                          return !!this.props.chipRenderer ? (
                            this.props.chipRenderer(
                              _key,
                              id,
                              value,
                              this.onDelete(id)
                            )
                          ) : (
                            <Chip secondary
                              onDelete={this.onDelete(id)}
                              key={_key}
                            >
                              {value}
                            </Chip>
                          );
                        })
                      }
                      <Chip outlined primary
                        onClick={this.open}
                      >
                        + Add
                      </Chip>
                    </Box>
                  </Box>
                </Fieldset>
              ) : (
                <Button small
                  onClick={this.open}
                  className='mr-1 statement'
                  acl={!!this.props.title}
                >
                  {this.props.title}
                </Button>
              )
            )
          }
          {this.mainHtmlWithDialog()}
        </Box>

        <Box acl={NOT(useDialog)}>
          {this.mainHtml()}
        </Box>

      </>
    );
  }
}

export default Chips;
