import {
  Arr
} from '../../lib/Array.lib';
import Core from "../../lib/Core";
import {
  Obj
} from '../../lib/Object.lib';
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';
import useState from '../../lib/hooks/useState.hook';

export const APP_CONFIRMATION_DIALOG__KEY = 'AppConfirmationDialog';

export default function AppConfirmationDialog() {
  const [_state, _updateState, _setState] = useState({ open: false });
  const _onOpen = async (options) => {
    await _onUnSet();
    console.debug(`${APP_CONFIRMATION_DIALOG__KEY} _onOpen :options\n`, options);
    const {
      title,
      message,
      content,
      onAccept = async () => null,
      onAcceptLabel,
      onCancel = async () => null,
      onCancelLabel,
      paperStyle = {},
      actionBar,
      actionOptions = [],
      displayActions = true,
      ...props
    } = Obj(options);
    await _updateState({
      open: true,
      title,
      message,
      content,
      onAccept: async (event) => {
        await _onUnSet();
        await onAccept();
      },
      onAcceptLabel,
      onCancel,
      onCancelLabel,
      paperStyle,
      actionBar,
      actionOptions,
      displayActions,
      ...props
    });
  };
  const _onUnSet = async () => {
    return new Promise((resolve) => {
      console.debug(`${APP_CONFIRMATION_DIALOG__KEY} _onUnSet`);
      _setState({});
      setTimeout(resolve);
    });
  };
  const _onCancel = async () => {
    let _cancel = _state.onCancel instanceof Function ? _state.onCancel : (async () => null);
    await _onUnSet();
    console.debug(`${APP_CONFIRMATION_DIALOG__KEY} _onCancel :_state\n`, _state);
    await _cancel();
  };
  Core.setKeyValue(APP_CONFIRMATION_DIALOG__KEY, {
    open: _onOpen,
    cancel: _onCancel,
    unSet: _onUnSet,
  });
  const {
    onAcceptLabel = 'Ok',
    onCancelLabel = 'Cancel',
    dialogId = `app-confirmation-dialog-title-${Date.now()}`,
    actionBar = (<></>),
    actionOptions = [],
    displayActions = true,
  } = _state;
  return (
    <Dialog
      onClose={_onUnSet}
      open={_state.open}
      name={dialogId}
      title={_state.title}
      message={_state.message}
      content={_state.content}
      actionBar={actionBar}
      actions={Arr(
        displayActions && (
          !!actionOptions.length ? (
            actionOptions.map((o, index) => {
              return (
                <Button
                  variant={o.variant || 'outlined'}
                  color={o.color || 'secondary'}
                  onClick={async event => {
                    await _onUnSet();
                    (o.onClick instanceof Function) && o.onClick();
                  }}
                  style={{ minWidth: 120 }}
                  {...(o.buttonProps || {})}
                >
                  {o.label || ''}
                </Button>
              );
            })
          ) : (
            [
              <Button outlined
                onClick={_onCancel}
                className='min-w-120'
              >
                {onCancelLabel}
              </Button>,
              <Button primary autoFocus
                onClick={_state.onAccept}
                className='min-w-120'
              >
                {onAcceptLabel}
              </Button>
            ]
          )
        )
      )}
      paperStyle={_state.paperStyle}
    />
  );
}

export function showConfirm({
  title,
  message,
  content,
  onAccept = () => null,
  onAcceptLabel,
  onCancel,
  onCancelLabel,
  paperStyle,
  actionBar,
  actionOptions,
  displayActions
} = {}) {
  try {
    Core.getKeyValue('AppConfirmationDialog').open({
      title,
      message,
      content,
      onAccept,
      onAcceptLabel,
      onCancel,
      onCancelLabel,
      paperStyle,
      actionBar,
      actionOptions,
      displayActions
    });
  } catch {
    console.debug(`${title}\n\n${message}`);
  }
}

export async function hideConfirm() {
  try { await Core.getKeyValue(APP_CONFIRMATION_DIALOG__KEY).unSet(); } catch { }
}

export async function cancelConfirm() {
  try { await Core.getKeyValue(APP_CONFIRMATION_DIALOG__KEY).cancel(); } catch { }
}
