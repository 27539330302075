import React from "react";
import {
  matchSkillWithText
} from '../../../Candidates/SubmissionNotes.lib';
import Icon from '../../../Layout/Wrappers/Icon';
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';

const result = (element, toMatchWith) => {
  let isLabelInText = [];
  const valueArray = element.value.split(",");

  if (!!toMatchWith && !!toMatchWith.length) {
    isLabelInText = valueArray.map(val => {
      return !!matchSkillWithText(val, toMatchWith);
    });
  }
  return !!isLabelInText.includes(true);
}

const resultWithDecision = (element, toMatchWith) => {
  let decision = { group: 0 };
  if (!!toMatchWith && !!toMatchWith.length) {
    decision = !!result(element, toMatchWith) ? { group: 1 } : { group: 0 };
  }
  return finalDecision([decision]);
}

const finalDecision = (decision) => {
  let text = (
    <Icon mr
      role='ChipTagNo'
      icon='close'
      color={THEME__COLOR__ERROR}
    />
  );
  decision.forEach(dec => {
    if (dec.group > 0) {
      text = (
        <Icon mr
          role='ChipTagYes'
          icon='done'
          color={THEME__COLOR__SUCCESS}
        />
      );
    }
  });
  return text;
}

const ChipTag = {
  result,
  resultWithDecision
}

export default ChipTag;
