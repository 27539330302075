import Core from "./Core";
import Http from "./Http";

const debug = Core.debug('ConfigurationLib');

export function getConfigurationModel(extended = false) {
  let model = {
    key: null, // string
    value: {} // json
  };
  let extendedModel = {
    id: null, // string
    ...model
  }
  return extended ? extendedModel : model;
}

export async function upsertConfiguration(configuration) {
  return (
    configuration.id
      ? Http.patch(
        Core.getApi(`Configurations/${configuration.id}`),
        mapConfigurationBack(configuration)
      ).then(mapConfiguration)
      : Http.post(
        Core.getApi('Configurations'),
        mapConfigurationBack(configuration)
      ).then(mapConfiguration)
  );
}

export async function getConfigurations({ where, include, fields, limit }) {
  include = include || {};
  where = where || {};
  fields = fields || {};
  return Http.get(
    Core.getApi('Configurations'),
    {
      filter: JSON.stringify({ where, include, fields, limit }),
    }
  ).then(mapConfigurations);
}

export async function getConfiguration({ key, id }) {
  if (!key && !id) { return null; }
  return Http.get(
    Core.getApi('Configurations'),
    {
      filter: JSON.stringify({
        where: key ? { key } : { id },
        limit: 1
      }),
    }
  ).then(configurations => configurations[0]).then(mapConfiguration);
}

export function mapConfiguration(configuration) {
  configuration = configuration || {};
  configuration = {
    id: configuration.id || configuration._id || '', // string
    key: configuration.key || '', // string
    value: configuration.value || {} // json
  };
  debug && console.debug('mapConfiguration', configuration);
  return configuration;
}

export function mapConfigurations(configurations) {
  return configurations.filter(v => !!v).map(mapConfiguration);
}

export function mapConfigurationBack(configuration) {
  configuration = configuration || {};
  configuration = {
    key: configuration.key || '', // string
    value: configuration.value || {} // json
  };
  return configuration;
}

const ConfigurationLib = {
  upsert: upsertConfiguration,
  get: async options => (options.key || options.id) ? getConfiguration : getConfigurations
};

export default ConfigurationLib;
