import {
  Component
} from "react";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../../lib/Array.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Definition, {
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__STATE_ID,
  LOCATION__CALIFORNIA,
  LOCATION__UNITED_STATES,
} from "../../../../lib/Definition";
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  trim
} from '../../../../lib/String.lib';
import countries from "../../../../static/countries.json";
import states from "../../../../static/states.json";
import StepHeader from '../../../Forms/StepHeader';
import {
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../../Jobs/Card/JobDetails';
import Box from '../../../Layout/Wrappers/Box';
import Col from "../../../Layout/Wrappers/Col";
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import Icon from '../../../Layout/Wrappers/Icon';
import InputNumber from '../../../Layout/Wrappers/InputNumber';
import Menu from '../../../Layout/Wrappers/Menu';
import Row from "../../../Layout/Wrappers/Row";
import TextField from '../../../Layout/Wrappers/TextField';

class Basics extends Component {
  render() {
    const EmployerEditController = this.props.parent;
    const { candidateLocations } = EmployerEditController.state;
    const locationTags = Definition.get("location");
    const _firstJobId = Obj(Arr(Obj(EmployerEditController.state).jobs)[0]).id;
    return (
      <Box column w100 className="p-1">

        <StepHeader {...this.props} />

        <Box row w100>

          <Fieldset
            title={
              <>
                Employer Name
              </>
            }
            dot='red'
          >
            <TextField
              name="name"
              value={EmployerEditController.state.name}
              onChange={(ev, name) => {
                if (
                  EmployerEditController.state.employers.find(
                    (employer) =>
                      employer.id !== EmployerEditController.state.id &&
                      String(employer.name).toLowerCase() ===
                      String(name).toLowerCase()
                  )
                ) {
                  EmployerEditController.setState({
                    name,
                    snackbar: "Name should be unique",
                    errorName: "Choose another name",
                  });
                }
                else {
                  EmployerEditController.setState({ name, errorName: "" });
                }
              }}
              errorText={EmployerEditController.state.errorName}
            />
          </Fieldset>

          {!!EmployerEditController.state.id && (
            <Fieldset
              title={
                <>Anonymous Employer Name for Limited Recruiter</>
              }
              info={AppUI.publicInfo.tooltip}
              infoIconName={MATERIAL_ICON_NAME__EYE}
              infoProps={{
                onClick: openJobView({
                  jobId: _firstJobId,
                  sectionName: JOB_SECTION__SUMMARY
                })
              }}
            >
              <TextField
                name="proxyName"
                value={EmployerEditController.state.proxyName}
                disabled
                small
              />
            </Fieldset>
          )}

        </Box>

        <Row className='mt-3'>
          <Col>
            <label>Address</label>
            <TextField
              name="addressStreet"
              value={EmployerEditController.state.addressStreet}
              onChange={(ev, addressStreet) =>
                EmployerEditController.setState({ addressStreet })
              }
            />
          </Col>
          <Col>
            <label>City</label>
            <TextField
              name="addressCity"
              value={EmployerEditController.state.addressCity}
              onChange={(ev, addressCity) =>
                EmployerEditController.setState({
                  addressCity,
                  errorAddressCity: "",
                })
              }
              fullWidth
              errorText={EmployerEditController.state.errorAddressCity}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <label>State</label>
            <Menu dropdown
              name="addressState"
              value={EmployerEditController.state.addressState}
              onChange={(addressState) =>
                EmployerEditController.setState({ addressState })
              }
              options={states}
            />
          </Col>
          <Col>
            <label>Zip</label>
            <TextField
              name="addressZip"
              value={EmployerEditController.state.addressZip}
              onChange={(ev, addressZip) =>
                EmployerEditController.setState({ addressZip })
              }
              onFocus={(ev) => ev.target.select()}
            />
          </Col>
        </Row>

        <Row>
          <Fieldset
            title={
              <>Country</>
            }
            fullWidth={false}
          >
            <Menu dropdown
              name="addressCountry"
              value={EmployerEditController.state.addressCountry}
              onChange={(addressCountry) =>
                EmployerEditController.setState({ addressCountry })
              }
              options={countries}
            />
          </Fieldset>
          <Fieldset
            title={
              <>Stage</>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            fullWidth={false}
          >
            <Menu dropdown
              name="stage"
              value={EmployerEditController.state.stage}
              onChange={(stage) =>
                EmployerEditController.setState({
                  stage,
                  errorEmployerStage: "",
                })
              }
              error={EmployerEditController.state.errorEmployerStage}
              options={Definition.get("stage")}
            />
          </Fieldset>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <label>
              Hiring permanent (full-time) employees only in these locations
            </label>
            <Menu tree multiple={false}
              layers={[
                LOC_TYPE__COUNTRY_ID,
                LOC_TYPE__STATE_ID,
                LOC_TYPE__METRO_AREA_ID,
                LOC_TYPE__METRO_AREA_SEC_ID,
                LOC_TYPE__CITY_ID
              ]}
              treeDefaultExpandedIds={[
                LOCATION__UNITED_STATES,
                LOCATION__CALIFORNIA
              ]}
              value={candidateLocations}
              onChange={(candidateLocation) => {
                EmployerEditController.setState({ candidateLocations: [candidateLocation] });
              }}
              options={locationTags}
              className="my-1"
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col>
            <label>How many people work at the company?</label>
            <InputNumber
              name="minCompanySize"
              min="1"
              value={EmployerEditController.state.employeeCount}
              onChange={(employeeCount) => {
                EmployerEditController.setState({
                  employeeCount,
                  errorEmployeeCount: !trim(employeeCount) ? 'Please enter to get better match' : ''
                });
              }}
              errorText={EmployerEditController.state.errorEmployeeCount}
            />
          </Col>
        </Row>

        <Row>
          <Fieldset
            title={
              <>Founding Year</>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            fullWidth={false}
          >
            <TextField
              name="foundingYear"
              type='number'
              value={EmployerEditController.state.foundingYear}
              onChange={(event, foundingYear) =>
                EmployerEditController.setState({ foundingYear })
              }
              onFocus={(ev) => ev.target.select()}
            />
          </Fieldset>

          <Fieldset row noWrap
            title={
              <>Total Funding Raised</>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            fullWidth={false}
            contentWrapper={{
              className: 'flex-align-left-center'
            }}
          >
            <Icon icon='attach_money' large />
            <TextField
              name="totalFunding"
              value={EmployerEditController.state.totalFunding}
              onChange={(ev, totalFunding) =>
                EmployerEditController.setState({ totalFunding })
              }
              onFocus={(ev) => ev.target.select()}
            />
          </Fieldset>

        </Row>

        <Row className='mt-3'>
          <Col>
            <label>Most Recent Funding Round</label>
            <TextField
              name="mostRecentFundingDate"
              value={EmployerEditController.state.mostRecentFundingDate}
              onChange={(ev, mostRecentFundingDate) =>
                EmployerEditController.setState({ mostRecentFundingDate })
              }
            />
          </Col>
        </Row>

      </Box>
    );
  }
}
export default Basics;
