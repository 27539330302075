import React, {
  Component
} from "react";
import Menu from '../../../../../Layout/Wrappers/Menu';
import Fieldset from '../../../../../Layout/Wrappers/Fieldset';

class CategoryDropdown extends Component {
  render() {
    const { onSave, category } = this.props;
    return (
      <Fieldset
        title='Pick a Category'
      >
        <Menu dropdown
          name="category"
          value={category || 'technicalSkills'}
          onChange={(category) => onSave('category', category)}
          style={{
            width: 180
          }}
          paperStyle={{
            maxWidth: 180,
            maxHeight: 500
          }}
          required
          options={[
            'technicalSkills',
            'workExperience',
            'education',
            'others'
          ].map((label, id) => ({ id, label }))}
        />
      </Fieldset>
    );
  }
}

export default CategoryDropdown;
