export function getEducationModel({
  id = new Date().toISOString(),
  isDefault = false,
  degrees = [],
  __newRecord = false,
  __schemeVersion = 2
} = {}) {
  return {
    id,
    degrees,
    isDefault,
    __newRecord,
    __schemeVersion,

    schoolId: undefined,
    schoolName: undefined,

    // Signals are arrays, but be undefined is required
    positiveSignalsTags: undefined,
    negativeSignalsTags: undefined,

    rankingMeta: {}, // school?
    schoolRanking: undefined,
    postalAddress: undefined,
    initialDegree: undefined,
    degreeOtherValue: undefined,

  };
}