import Box from '../Layout/Wrappers/Box';
import Typography from '../Layout/Wrappers/Typography';

const EmployerUI = {
  crunchbaseUrl: {
    title: 'Company Crunchbase URL',
    titleShort: 'Crunchbase'
  },
  intakeNotes: {
    warning: (
      <Box w100 className='py-05'>
        <Typography strong error mr>
          Warning
        </Typography>
        <Typography sub strong>
          If you have Employer/Job open in another window, refresh this one before saving. Otherwise, they can overwrite each other.
        </Typography>
      </Box>
    )
  },
  internalSubmissionInstructions: {
    title: 'RC Logistics - Contacts / Submission / Scheduling / Interview / Follow ups',
    subtitle: 'Recruiting Coordinator instructions: Contact role/phone/calendar,  Submission & scheduling steps, who does which interviews, follow up tips'
  },
  primaryContactEmail: {
    title: 'Company contact email',
    titleShort: 'Email'
  },
  primaryContactLinkedin: {
    title: 'Company LinkedIn URL',
    titleShort: 'LinkedIn'
  },
  notables: {
    title: 'Notable founders / employees / investors',
    titleShort: 'Notables'
  },
  teamCount: {
    title: 'How many engineers work at the company?'
  },
  teamStructure: {
    title: 'Team structure and process'
  },
  url: {
    title: 'Company URL',
    titleShort: 'URL'
  }
};
export default EmployerUI;
