
import moment from "moment";
import {
  formatDate
} from '../../../lib/Date.lib';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  REACT_TABLE__INPUT_RANGE_DATE
} from '../../Home/useEnhancedReactTable.hook';
import Box from './Box';
import DatePicker from './DatePicker';
import Menu from './Menu';
import Typography from './Typography';

export const DATE_FORMAT = `MM-DD-YYYY`;

export default function DatePickerRange({
  v2 = false,
  values = [],
  onChange = Fun,
  reference = Fun
}) {

  const yearOptions = generateYearOptions(new Date().getFullYear());

  const [state, updateState] = useState({
    yearOption: moment(yearOptions[1].id).toISOString(),
    monthOption: null
  });

  const monthOptions = [
    { id: 1, label: '1-3 month' },
    { id: 2, label: '4-6 month' },
    { id: 3, label: '1-6 month' },
  ];

  const _onChange = (index) => (value) => {
    values = values.map(value => ((value === undefined) ? null : value));
    values[index] = value;
    onChange(values);
  };

  const _onChangeOption = (yearOption) => async (monthOption) => {
    await updateState({ yearOption, monthOption });
    switch (monthOption) {
      case 1:
        onChange([
          moment(yearOption).toISOString(),
          moment(yearOption).add(3, 'months').subtract(1, 'day').toISOString()
        ]);
        break;
      case 2:
        onChange([
          moment(yearOption).add(3, 'months').toISOString(),
          moment(yearOption).add(6, 'months').subtract(1, 'day').toISOString()
        ]);
        break;
      case 3:
        onChange([
          moment(yearOption).toISOString(),
          moment(yearOption).add(6, 'months').subtract(1, 'day').toISOString()
        ]);
        break;
      default:
        onChange([]);
        break;
    }
  }

  const _reset = async () => {
    await _onChangeOption(state.yearOption)(null);
  }

  reference({
    reset: _reset
  });

  console.debug(
    'DatePickerRange...',
    '\n', values
  );

  return (
    (
      v2 === true
    ) ? (
      <Box column w100 scrollX>
        <Box row noWrap>
          <Menu dropdown
            options={yearOptions}
            value={state.yearOption}
            onChange={async (value) => _onChangeOption(value)(state.monthOption)}
            className='mr-1 min-w-120'
          />
          <Menu dropdown
            disabled={!state.yearOption}
            options={monthOptions}
            value={state.monthOption}
            onChange={_onChangeOption(state.yearOption)}
            className='min-w-120'
          />
        </Box>
        <Box row noWrap>
          <Typography sub
            className='m-05'
          >
            Selected Range:
          </Typography>
          <Typography bold
            className='m-05'
          >
            {formatDate(values[0])} - {formatDate(values[1])}
          </Typography>
        </Box>
      </Box>
    ) : (
      <Box row noWrap scroll>
        <DatePicker
          placeholder={`${moment().subtract(185, 'days').format(DATE_FORMAT)}`}
          value={values[0]}
          onChange={_onChange(0)}
          className="mr-1"
          style={{
            minWidth: REACT_TABLE__INPUT_RANGE_DATE
          }}
        />
        <Typography mr>to</Typography>
        <DatePicker
          placeholder={`${moment().add(1, 'days').format(DATE_FORMAT)}`}
          value={values[1]}
          onChange={_onChange(1)}
          className="mr-1"
          style={{
            minWidth: REACT_TABLE__INPUT_RANGE_DATE
          }}
        />
      </Box>
    )
  );
}

export function generateYearOptions(baseYear = new Date().getFullYear()) {
  const options = [];
  for (let i = -9; i <= 0; i++) {
    const year = baseYear + i;
    // First half (H1)
    options.push({
      id: moment(`${year}-01-01`).toISOString(),
      label: `${year} H1`,
    });
    // Second half (H2)
    options.push({
      id: moment(`${year}-07-01`).toISOString(),
      label: `${year} H2`,
    });
  }
  return options.reverse();
};
