import {
  joinClassName,
  mapWrapper
} from './Libraries/Theme.lib';
import AppBar from './Wrappers/AppBar';
import Box from './Wrappers/Box';
import Paper, {
  getDefaultPaperStyle
} from './Wrappers/Paper';

export default function Page({
  title,
  contentRight,
  children,
  paperStyle = {},
  paperClassName = '',
  appBar,
  paper = false,
  noBack = false,
  showBack = !noBack,
  wrapperProps = {},
  paperProps = {},
  className = '',
  ...props
}) {
  return (
    <Box {...mapWrapper({
      role: 'Page',
      props: wrapperProps,
      assign: {
        ...props,
        column: true,
        className: joinClassName([
          'vh-100 vw-100 bg-main',
          className
        ])
      }
    })}>
      {
        appBar ||
        <AppBar
          role='PageAppBar'
          showBack={showBack}
          title={title}
          contentRight={contentRight}
        />
      }
      {paper ? (
        <Box role='PageContent' className="w-100 scroll-y" style={{ height: 'var(--paperHeight)' }}>
          <Paper {...mapWrapper({
            role: 'PagePaper',
            props: paperProps,
            assign: {
              style: {
                ...getDefaultPaperStyle(),
                ...paperProps.style,
                ...paperStyle
              },
              className: joinClassName([
                'p-1 h-100 flex-col m-auto scroll-y',
                paperProps.className,
                paperClassName
              ])
            }
          })}>
            {children}
          </Paper>
        </Box>
      ) : (
        <Box role='PageContent' className="flex-1 w-100 h-100 scroll-y">
          {children}
        </Box>
      )}
    </Box>
  )
}
