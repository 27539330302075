import {
  NavLink as ReactRouterDomNavLink
} from 'react-router-dom';
import Core from '../../../lib/Core';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  trim
} from '../../../lib/String.lib';
import {
  matchLocation,
  reloadLocation
} from '../../../lib/URL.lib';
import Icon from './Icon';
import IconButton from './IconButton';
import Menu from './Menu';
import {
  mapWrapperName,
  joinClassName
} from '../Libraries/Theme.lib';
import Wrapper from './Wrapper';
import Typography from './Typography';

/**
 * @see https://reactrouter.com/en/main/components/nav-link
 */
export default function NavLink({
  component = ReactRouterDomNavLink,
  to,
  exact = false,
  className = '',
  title,
  titleStyle,
  placement,
  children,
  label = '',
  path = '',
  acl = true,
  icon = false,
  main = false,
  menu = false,
  divider = true,
  decoration = false,
  noHover = false,
  noSelected = false,
  reload = false,
  ...props
}) {
  if (!acl) { return null; }
  to = trim(to) || trim(path) || trim(label).toLowerCase();
  children = children || label;
  const _isSelected = noSelected || matchLocation(to);
  const _yesHover = NOT(noHover);
  const _noDecoration = NOT(decoration);
  const _wrapperClassName = [
    _yesHover && 'c-purple-hover',
    _noDecoration && 'decoration-none',
    className
  ];
  const _menuClassName = ['flex-row flex-align-left-center py-1 px-2'];
  const _iconClassName = ['c-inherit'];
  const _labelClassName = ['c-inherit-all'];
  if (main || menu) {
    if (main) {
      _wrapperClassName.push('flex-column flex-align-center m-1 mx-5');
      _labelClassName.push('f-sm mt-05');
    }
    else if (menu) {
      if (divider) {
        _menuClassName.push('border-bottom');
      }
      if (icon) {
        _iconClassName.push('mr-3');
      }
    }
    if (_isSelected) {
      _wrapperClassName.push('c-purple fw-700');
    }
    else if (menu) {
      _wrapperClassName.push('c-black-dark fw-700');
    }
    else {
      _wrapperClassName.push('c-black-medium fw-700');
    }
  }
  else {
    _wrapperClassName.push('inline-block c-purple f-md fw-500');
  }
  children = (
    <Typography className={joinClassName(_labelClassName)}>
      {children}
    </Typography>
  );
  if (icon) {
    if (label) {
      children = (
        <>
          <Icon
            icon={icon}
            className={joinClassName(_iconClassName)}
          />
          {children}
        </>
      );
    }
    else {
      _iconClassName.push('c-black-medium');
      children = (
        <IconButton
          icon={icon}
          className={joinClassName(_iconClassName)}
        />
      );
    }
  }
  if (menu) {
    children = (
      <Menu.Item
        className={joinClassName(_menuClassName)}
      >
        {children}
      </Menu.Item>
    );
  }
  const _wrapperProps = mapWrapperName(
    'NavLink',
    {
      ...props,
      component,
      to: exact ? to : Core.getLink(to),
      className: joinClassName(_wrapperClassName),
      title,
      titleStyle,
      placement,
      children
    }
  );
  if (reload) {
    _wrapperProps.onClick = (event) => setTimeout(() => reloadLocation())
  }
  return (
    <Wrapper {..._wrapperProps} />
  );
}
