import {
  Input
} from 'antd';
import moment from 'moment';
import {
  useState
} from 'react';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATE__ISO,
  DATE_FORMAT__DATE__US_SHORT,
  isValidDate
} from '../../../lib/Date.lib';
import Engagement from '../../../lib/Engagement';
import {
  validateEmail
} from '../../../lib/GenericTools.lib';
import {
  trim
} from '../../../lib/String.lib';
import getHoldModel from '../../../lib/models/hold.model';
import {
  REACT_TABLE__COLUMN_SM
} from '../../Home/useEnhancedReactTable.hook';
import Button from '../../Layout/Wrappers/Button';
import DatePicker from '../../Layout/Wrappers/DatePicker';
import IconButton from '../../Layout/Wrappers/IconButton';
import StyledTooltip from '../../Layout/Wrappers/StyledTooltip';

const { TextArea } = Input;
const DATE_FORMAT = `YYYY-MM-DD`;

export default function HoldStatusConfig({ engagement }) {
  engagement.hold = getHoldModel({ prefill: true, endDate: engagement.hold?.endDate, note: engagement.hold?.note });
  const [state, setState] = useState(engagement.hold);
  Core.setKeyValue('HoldStatusConfigRef', {
    getState: () => state,
  });
  let {
    startDate,
    endDate,
    note,
    byWho
  } = state;
  return (
    <>
      <div className='d-flex flex-align-left-center mb-1'>
        <strong className="mr-1 c-cyan-darker nowrap" style={{ width: 120 }}>Start Date</strong>
        <span className='c-black-medium f-sm w-100'>{startDate ? moment(startDate, DATE_FORMAT).format(DATE_FORMAT) : ''}</span>
        <DatePicker
          className='w-100 d-none'
          value={startDate ? moment(startDate, DATE_FORMAT) : null}
          popupStyle={{ zIndex: 9999 }}
          onChange={value => {
            let _state = { ...state };
            _state.startDate = value;
            setState(_state);
          }}
          format={DATE_FORMAT}
          placeholder='Type or pick a date'
          disabled
        />
      </div>
      <div className='d-flex flex-align-left-top mb-1'>
        <strong className="mr-1 c-cyan-darker nowrap" style={{ width: 120 }}>End Date</strong>
        <DatePicker
          value={endDate ? moment(endDate, DATE_FORMAT) : null}
          format={DATE_FORMAT}
          placeholder='Type or pick a date'
          className='w-100'
          onChange={value => {
            let _state = { ...state };
            _state.endDate = value;
            setState(_state);
          }}
          popupStyle={{ zIndex: 9999 }}
        />
      </div>
      <div className='d-flex flex-align-left-top mb-1'>
        <strong className="mr-1 c-cyan-darker" style={{ width: 120 }}>Note</strong>
        <TextArea
          className='w-100'
          placeholder="Type here"
          value={note}
          onChange={event => {
            let value = event.target.value;
            let _state = { ...state };
            _state.note = value;
            setState(_state);
          }}
          autoSize
        />
      </div>
      <div className='d-flex flex-align-left-center d-none'>
        <div className='mr-1' style={{ width: 120 }}>
          <strong className="c-cyan-darker nowrap">By Who</strong>
          <span className='c-black-medium f-sm'>(email)</span>
        </div>
        <span className='c-black-medium f-sm w-100'>{byWho}</span>
        <div className='d-flex flex-column w-100 d-none'>
          <TextArea
            className='w-100'
            placeholder="Type here"
            value={byWho}
            onChange={event => {
              let value = event.target.value;
              let _state = { ...state };
              _state.byWho = value;
              setState(_state);
            }}
            disabled
            autoSize
          />
          {!validateEmail(byWho || '') && (
            <span className='f-sm c-red ml-1'>Invalid email!</span>
          )}
        </div>
      </div>
    </>
  );
}

export function HoldStatusActionItem({ engagement, onChange: onDone }) {
  return (
    <StyledTooltip
      title={(
        isValidDate(engagement.hold?.endDate)
          ? `Hold${(
            engagement.hold?.byWho
              ? ` by ${engagement.hold.byWho}`
              : ''
          )
          }${(
            isValidDate(engagement.hold?.startDate)
              ? ` since ${moment(engagement.hold.startDate).format(DATE_FORMAT__DATE__ISO)}`
              : ''
          )}${(
            trim(engagement.hold?.note)
              ? ` - ${engagement.hold.note}`
              : ''
          )}${(
            isValidDate(engagement.hold?.endDate)
              ? ` until ${moment(engagement.hold.endDate).format(DATE_FORMAT__DATE__US_SHORT)}`
              : ''
          )}`
          : 'Edit hold date'
      )}
      placement='right-end'
      className='date-picker pointer d-flex flex-align-center f-sm'
      style={
        {
          width: REACT_TABLE__COLUMN_SM,
          height: '1.4rem'
        }
      }
      onClick={
        () => {
          openHoldStatusConfig({
            engagement,
            onDone
          });
        }
      }
    >
      <span>Hold:&nbsp;</span>
      <span className='c-black-medium mr-1'>
        {engagement.hold?.endDate ? moment(engagement.hold.endDate).format(DATE_FORMAT__DATE__US_SHORT) : ''}
      </span>
      <IconButton
        className='icon16'
        icon='edit'
      />
    </StyledTooltip>
  );
}

export function openHoldStatusConfig({ engagement = {}, onDone }) {
  if (!!engagement.id) {
    Core.dialog.open({
      title: <>Hold Status: {engagement.candidate._name} | {engagement.job._name}</>,
      message: (
        <HoldStatusConfig engagement={engagement} />
      ),
      actions: [
        <Button
          key={`action-button-cancel`}
          variant='outlined'
          color='secondary'
          onClick={Core.dialog.close}
        >
          Cancel
        </Button>,
        <Button
          key={`action-button-save`}
          variant='contained'
          color='primary'
          onClick={async ev => {
            Core.dialog.close();
            let hold = Core.getKeyValue('HoldStatusConfigRef').getState();
            if (!validateEmail(hold.byWho)) {
              hold.byWho = null;
            }
            if (onDone instanceof Function) {
              onDone({ hold, holdDate: hold.endDate });
            }
            else {
              await Engagement.update(engagement, { hold, holdDate: hold.endDate }).catch(Core.showError);
            }
          }}
        >
          Save
        </Button>
      ],
      paperStyle: { width: 720, },
    });
  }
}