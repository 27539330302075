import {
  uniqueId
} from 'lodash';
import React from 'react';
import Core from '../../lib/Core';
import {
  getNavigationList
} from '../../routes/Main.routes';
import Button from '../Layout/Wrappers/Button';
import Drawer from '../Layout/Wrappers/Drawer';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import List from '../Layout/Wrappers/List';
import NavLink from '../Layout/Wrappers/NavLink';
import {
  PLACEMENT__RIGHT
} from '../Layout/Wrappers/StyledTooltip';
import useState from '../../lib/hooks/useState.hook';

export default function MainSideMenu(props) {
  const [{ open = false }, _updateState] = useState();
  const _closeDrawer = async (event) => await _updateState({ open: false });
  const _toggleDrawer = async (event) => await _updateState({ open: !open });
  return (
    <>
      <IconButton
        title='Open menu'
        onClick={_toggleDrawer}
        icon='menu'
      />
      <Drawer
        open={open}
        PaperProps={{ style: { minWidth: 360 } }}
        onClose={_toggleDrawer}
      >
        <div className='flex-column bg-purple-common'>
          <Button flat alignLeft
            title='Exit menu'
            placement={PLACEMENT__RIGHT}
            onClick={_closeDrawer}
            icon={<Icon icon='arrow_back' className='c-inherit' />}
            label='10by10'
            className='flew-row flex-align-left-center c-white c-white-hover'
            fullWidth
          />
          <div className='p-1 px-2 mb-2'>
            <div className='f-xxxl c-white fw-500'>
              {Core.getUserName()}
            </div>
            <div className='f-md c-white fw-400'>
              {Core.getSessionEmail()}
            </div>
          </div>
        </div>
        <List onClick={_closeDrawer}>
          {getNavigationList({ extended: true }).map((option) => (
            <NavLink menu divider={false}
              {...option} key={`main_side_menu__option__${(option.label || uniqueId())}`}
            />
          ))}
        </List>
      </Drawer>
    </>
  );
}
