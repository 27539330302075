import {
  Component
} from "react";
import {
  Arr
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import {
  LOC_TYPE__ALIAS_ID
} from "../../lib/Definition";
import {
  NOT
} from '../../lib/GenericTools.lib';
import {
  joinKeyName
} from '../../lib/String.lib';
import Box, {
  RefBox
} from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Chip from '../Layout/Wrappers/Chip';
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import Fieldset from '../Layout/Wrappers/Fieldset';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import TextField from '../Layout/Wrappers/TextField';
import TagBox from "./TagBox";

const KEY__CATEGORY_BOX = 'category_box';

class CategoryBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      tags: props.tags,
      open: !!props.expanded,
      newGroup: this.getNewGroup(),
    };
  }

  getNewGroup = () => {
    return { name: "", ids: [], id: new Date().getTime() };
  };

  save = (event) => {

    const {
      onUpdate = () => { }
    } = this.props;

    if (event.category) {
      this.setState({ category: event.category },
        () => onUpdate({ category: event.category })
      )
    }
    else if (this.state.changes) {
      this.setState(
        {
          changes: false,
          edit: false,
          category: this.state.category || { ...this.props.category },
        },
        () => {
          onUpdate({
            category: this.state.category,
          });
        }
      );
    }
    setTimeout((st) => this.props.onBlur && this.props.onBlur(event));
  };

  handleClosePopup = () => {
    this.setState({ addNewCategory: false });
  };

  handlerCreateGroup = () => {
    const category = { ...this.state.category };
    let groups = category.groups || [];
    let action = this.state.groupAction;
    let group;

    if (action === "create") {
      groups.push(this.state.newGroup);
    } else {
      group = this.state.newGroup;
      let currentIndex = groups.findIndex(
        (gr) => gr.id === this.state.newGroup.id
      );
      groups[currentIndex] = group;
    }

    category.groups = groups;
    this.setState({ newGroup: this.getNewGroup() });
    this.save({ category });
  };

  handlerDeleteGroup = (group) => {
    const category = { ...this.state.category };
    let groups = category.groups || [];
    let newGroup = this.state.newGroup;

    category.groups = groups.filter((group) => group.id !== newGroup.id);
    this.save({ category });
  };

  render() {
    const category = this.state.category;
    const tags = Arr(this.state.tags);
    const newTagBoxKey = `category${category.key}AddNewTag`;
    const icon =
      !this.state.category.archived &&
      this.state.category.key &&
      (!!this.state.open ? (
        <i className="material-icons">keyboard_arrow_up</i>
      ) : (
        <i className="material-icons">keyboard_arrow_down</i>
      ));

    let { addNewCategory, newGroup } = this.state;

    const _setReference = (name) => (reference) => {
      if (reference) {
        Core.setKeyValue(
          joinKeyName([
            KEY__CATEGORY_BOX,
            this.state.category.key,
            name
          ]),
          reference
        );
      }
    }

    const _getReference = (name) => {
      return Core.getKeyValue(joinKeyName([
        KEY__CATEGORY_BOX,
        this.state.category.key,
        name
      ]));
    }

    const _scrollToTop = () => {
      _getReference('top').scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }

    const _scrollToBottom = () => {
      _getReference('bottom').scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }

    const {
      onUpdate = () => { }
    } = this.props;

    return (
      <div className="flex-column">

        <div className="flex-row flex-align-left-center p-1 w-100">

          <div className="flex-row flex-align-left-center flex-1">
            {/** LEFT */}
            {NOT(this.state.edit) &&
              <div className='flex-row flex-align-left-center'>
                <Button flat
                  title={category.label}
                  label={
                    <div className='flex-1 flex-align-left-center truncate'>
                      {category.label}
                    </div>
                  }
                  icon={icon}
                  onClick={(event) => {
                    /** collapse/expand toggle */
                    const category = { ...this.state.category };
                    category.open = !category.open;
                    this.save({ category });
                  }}
                  disabled={this.state.category.archived}
                  className='mr-1 flex-row flex-align-left-center'
                  style={{ width: 320 }}
                />
                <small className="c-black-medium">{category.key}</small>
              </div>
            }

            {/** EDITING CATEGORY */}
            {this.state.edit &&
              <>
                <small className="c-black-medium mr-1">Label:</small>
                <TextField small
                  name={`${category.key}-label`}
                  placeholder={"label"}
                  value={this.state.category.label}
                  onChange={(ev, change) => {
                    this.setState((state) => {
                      state.category.label = change;
                      state.changes = true;
                      return state;
                    });
                  }}
                  disabled={this.state.category.archived}
                  className='mr-1'
                />
                <small className="c-black-medium mr-1">Key: </small>
                <TextField small
                  name={`${category.key}-key`}
                  placeholder={"key"}
                  value={category.key}
                  onChange={(ev, change) => {
                    this.setState((state) => {
                      state.category.key = change;
                      state.changes = true;
                      return state;
                    });
                  }}
                  disabled={this.state.category.archived}
                  className='mr-1'
                />
              </>
            }
          </div>

          {/** ACTION BAR */}
          {!/categories/i.test(this.state.category.key) && (
            <div className="flex-row flex-align-left-center">

              {/** CREATE GROUP ACTION */}
              {
                NOT(category.archived) &&
                <>
                  <IconButton
                    onClick={(ev) => {
                      this.setState({
                        addNewCategory: true,
                        groupAction: "create",
                        newGroup: this.getNewGroup(),
                      });
                    }}
                  >
                    <i className="material-icons">group</i>
                  </IconButton>
                  <Dialog
                    paperStyle={{ width: 512 }}
                    open={addNewCategory}
                    onClose={this.handleClosePopup}
                    title='Add/Edit Group'
                    content={
                      <>
                        <Fieldset title='Name Of Group'>
                          <TextField small
                            id="standard-full-width"
                            value={newGroup.name}
                            onChange={(event) => {
                              newGroup.name = event.target.value;
                              this.setState({ newGroup });
                            }}
                          />
                        </Fieldset>
                        <Fieldset title='Associated Labels'>
                          <Menu multiple
                            value={newGroup.ids}
                            onChange={(value) => {
                              newGroup.ids = value;
                              this.setState({ newGroup });
                            }}
                            options={this.state.category.values}
                          />
                        </Fieldset>
                      </>
                    }
                    actions={[
                      <Button outlined minW120
                        onClick={this.handleClosePopup}
                      >
                        Cancel
                      </Button>,
                      <Button primary minW120
                        onClick={this.handlerCreateGroup}
                      >
                        Save
                      </Button>
                    ]}
                  />
                </>
              }

              {/** SAVE ACTION */}
              {
                NOT(category.archived) && (
                  this.state.changes
                    ? (
                      <IconButton onClick={this.save}>
                        <i className="material-icons">save</i>
                      </IconButton>
                    )
                    : (
                      <IconButton
                        onClick={(ev) => this.setState({ edit: !this.state.edit })}
                      >
                        <i className="material-icons">mode_edit</i>
                      </IconButton>
                    )
                )
              }

              {/** ARCHIVE ACTION */}
              {
                (category.archived) &&
                <IconButton
                  onClick={(ev) => {
                    const category = { ...this.state.category };
                    category.archived = false;
                    this.save({ category });
                  }}
                >
                  <i className="material-icons">unarchive</i>
                </IconButton>
              }
              {NOT(this.props.new) && NOT(category.archived) && (
                <IconButton
                  onClick={(ev) => {
                    const category = { ...this.state.category };
                    category.archived = true;
                    this.save({ category });
                  }}
                >
                  <i className="material-icons">archive</i>
                </IconButton>
              )}
            </div>
          )}

        </div>

        {/** GROUPS */}
        <div className="d-flex flex-align-left-center px-2 wrap bg-cyan-a">
          {Arr(category.groups).map((group, index) => (
            <Chip outlined
              key={joinKeyName([
                'category_box',
                'group',
                group.name,
                index
              ])}
              label={group.name}
              className="m-05"
              onClick={(ev) => {
                this.setState({
                  addNewCategory: true,
                  newGroup: group,
                  groupAction: "edit",
                });
              }}
            />
          ))}
        </div>

        {/** TAGS */}
        {
          !!this.state.category.open &&
            !this.state.category.archived &&
            this.state.category.key ? (
            <Box column
              className="m-1 bg-black-lighter border-1"
            >
              <Fieldset
                title='Tags'
                className="py-1"
                actionBar={
                  <>
                    <IconButton
                      title='Scroll to first tag'
                      icon='vertical_align_top'
                      onClick={_scrollToTop}
                    />
                    <IconButton
                      title='Scroll to last tag'
                      icon='vertical_align_bottom'
                      onClick={_scrollToBottom}
                    />
                  </>
                }
              >
                <Box scrollY w100
                  className="bg-black-lighter border-1"
                  style={{ maxHeight: 360 }}
                >
                  <RefBox ref={_setReference('top')} />
                  {tags.map((tag, tagIndex) => {
                    const tagUKey = `${category.key}_${tagIndex}`;
                    return (
                      <TagBox
                        category={this.state.category}
                        key={tagUKey}
                        name={tagUKey}
                        ref={(tf) => (this[tagUKey] = tf)}
                        tag={tag}
                        onUpdate={(tag) => {
                          const update = [...tags];
                          update[tagIndex] = tag;
                          console.debug('onUpdate', tag);
                          onUpdate({
                            category,
                            tags: update,
                          });
                        }}
                        onArchived={(tag) => {
                          const update = [...tags];
                          update[tagIndex] = tag;
                          console.debug('onArchive', tag, update);
                          const {
                            id,
                            type,
                            parentId,
                          } = tag;
                          function removeLocationFromAlias({
                            locationId,
                            aliasTag,
                          }) {
                            aliasTag.aliasLocationIds = (
                              aliasTag.aliasLocationIds || []
                            );
                            const locationIndex = aliasTag.aliasLocationIds.indexOf(locationId);
                            if (!!~locationIndex) {
                              aliasTag.aliasLocationIds.splice(locationIndex, 1);
                            }
                          }
                          function setParentUpToChild({
                            locationTag,
                            parentId,
                            grandParentId,
                          }) {
                            if (locationTag.parentId === parentId) {
                              locationTag.parentId = grandParentId || null;
                            }
                          }
                          if (type !== LOC_TYPE__ALIAS_ID) {
                            update.forEach((locationTag, locationTagIndex) => {
                              if (locationTag.type === LOC_TYPE__ALIAS_ID) {
                                removeLocationFromAlias({
                                  aliasTag: locationTag,
                                  locationId: id
                                });
                              }
                              if (locationTag.type !== LOC_TYPE__ALIAS_ID) {
                                setParentUpToChild({
                                  locationTag,
                                  parentId: id,
                                  grandParentId: parentId
                                })
                              }
                            });
                          }
                          onUpdate({
                            category,
                            tags: update,
                          });
                        }}
                      />
                    );
                  })}
                  <RefBox ref={_setReference('bottom')} />
                </Box>
              </Fieldset>

              {/** ADD NEW TAG */}
              <Fieldset
                title='Add a new Tag'
              >
                <TagBox isNew
                  name="addNewTag"
                  ref={(tf) => (this[newTagBoxKey] = tf)}
                  placeholder="Add a New Tag"
                  onUpdate={(tag) => {
                    const update = [...tags];
                    tag.id = update.length + 1;
                    update.push(tag);
                    this.setState(
                      { tags: update },
                      () => {
                        onUpdate({
                          category,
                          tags: update,
                        });
                        _scrollToBottom();
                      }
                    );
                  }}
                />
              </Fieldset>

            </Box>
          ) : (
            <Divider />
          )
        }
      </div >
    );
  }
}
export default CategoryBox;
