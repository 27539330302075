import { useState } from "react";
import { autocomplete } from '../../lib/services/BE/autocomplete.api';

export function useAutocomplete(autocompletePath) {
  const [suggestions, setSuggestions] = useState();
  async function fetchSuggestions(value = '') {
    let promise = autocomplete({ path: autocompletePath, query: String(value) });
    promise.then(setSuggestions);
    return promise;
  }
  return {
    suggestions,
    fetchSuggestions
  };
}
