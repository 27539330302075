
import moment from 'moment';
import React, {
  useState
} from 'react';
import AccountLib from '../../../lib/Account';
import {
  NEGATIVE_COMPANY_SIGNAL_TAG_IDS,
  NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED,
  POSITIVE_COMPANY_SIGNAL_TAG_IDS,
  POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED
} from '../../../lib/Company';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition from '../../../lib/Definition';
import {
  NEGATIVE_SCHOOL_SIGNAL_TAG_IDS,
  NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED,
  POSITIVE_SCHOOL_SIGNAL_TAG_IDS,
  POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED
} from '../../../lib/School';
import {
  mapOrganizationTenureSignals,
  SIGNALS_TYPE__COMPANY, SIGNALS_TYPE__SCHOOL
} from '../OrganizationSignals.lib';
import Chip from '../../Layout/Wrappers/Chip';
import Button from '../../Layout/Wrappers/Button';

const DEBUG_SIGNAL_CHIPS = Core.debug('DEBUG_SIGNAL_CHIPS');

export const SIGNAL_CHIPS__VARIANT_FULL = 'Full';
export const SIGNAL_CHIPS__VARIANT_COMPACT = 'Compact';

export default function SignalChips({
  employment,
  education,
  signalsHash,
  onChange,
  className = '',
  variant = SIGNAL_CHIPS__VARIANT_FULL
}) {
  let history = employment || education;
  let [state, setState] = useState({});
  async function updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let {
    account,
    expanded = false
  } = state;
  if (!signalsHash) { return ''; }
  let signalTags = mapOrganizationTenureSignals({
    signals: signalsHash[employment
      ? history.companyId
      : education && history.schoolId
    ],
    type: employment ? SIGNALS_TYPE__COMPANY : education && SIGNALS_TYPE__SCHOOL
  });
  let positiveSignalTags = Definition.getTags({
    categoryKey: 'positiveSignals',
    tagIds: [
      ...new Set([
        ...(
          (variant === SIGNAL_CHIPS__VARIANT_FULL)
            ? (
              employment
                ? [
                  ...POSITIVE_COMPANY_SIGNAL_TAG_IDS,
                  ...POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED
                ]
                : education && [
                  ...POSITIVE_SCHOOL_SIGNAL_TAG_IDS,
                  ...POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED
                ]
            )
            : (
              history.positiveSignalsTags || []
            )
        )
      ])
    ],
    sortByArray: true
  }).map(tag => {
    tag.checked = history.positiveSignalsTags?.includes(tag.id);
    return tag;
  });
  let negativeSignalTags = Definition.getTags({
    categoryKey: 'negativeSignals',
    tagIds: [
      ...new Set([
        ...(
          (variant === SIGNAL_CHIPS__VARIANT_FULL)
            ? (
              employment
                ? [
                  ...NEGATIVE_COMPANY_SIGNAL_TAG_IDS,
                  ...NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED
                ]
                : education && [
                  ...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS,
                  ...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED
                ]
            )
            : (
              history.negativeSignalsTags || []
            )
        )
      ])
    ],
    sortByArray: true
  }).map(tag => {
    tag.checked = history.negativeSignalsTags?.includes(tag.id);
    return tag;
  });
  let manuallyOverriddenAt = history.manuallyOverriddenAt;
  let manuallyOverriddenBy = history.manuallyOverriddenBy;
  if (manuallyOverriddenBy && !account) {
    AccountLib.get(manuallyOverriddenBy).then(account => updateState({ account }));
  }
  function _onChange(update) {
    onChange(update);
  }
  DEBUG_SIGNAL_CHIPS && console.debug(
    'DEBUG_SIGNAL_CHIPS\n',
    {
      history,
      signalsHash,
      signalTags,
      positiveSignalTags,
      historyPositiveSignalIds: history.positiveSignalsTags,
      historyNegativeSignalIds: history.negativeSignalsTags,
    }
  );

  let selectedPositiveSignals = positiveSignalTags.filter(tag => !!tag.checked);
  let selectedNegativeSignals = negativeSignalTags.filter(tag => !!tag.checked);
  let hasSelectedSignals = !!(selectedPositiveSignals.length + selectedNegativeSignals.length);

  return (
    <div className={`${className}`.trim()}>
      {(variant === SIGNAL_CHIPS__VARIANT_FULL) && hasSelectedSignals && (
        <div className='c-black-medium mt-1 f-sm fw-500'>Selected</div>
      )}
      <div className='d-flex flex-align-left-center flex-wrap pl-1'>
        {selectedPositiveSignals.map((tag, index) => {
          return (
            <SignalChip
              key={`signal-chip-selected-positive-${tag.id}`}
              tag={tag}
              history={history}
              signalTags={signalTags}
              signalsKey='positive'
            />
          );
        })}
        {selectedNegativeSignals.filter(tag => !!tag.checked).map(tag => {
          return (
            <SignalChip
              key={`signal-chip-selected-negative-${tag.id}`}
              tag={tag}
              history={history}
              signalTags={signalTags}
              signalsKey='negative'
            />
          );
        })}
      </div>
      {(variant === SIGNAL_CHIPS__VARIANT_FULL) && (expanded || !hasSelectedSignals) && (
        <div className='pt-1'>
          <div className='c-black-medium mt-1 f-sm fw-500'>Modify signals</div>
          <div className='d-flex flex-align-left-center flex-wrap pl-1'>
            {positiveSignalTags.map((tag, index) => {
              return (
                <SignalChip
                  key={`signal-chip-positive-${tag.id}`}
                  tag={tag}
                  history={history}
                  signalTags={signalTags}
                  signalsKey='positive'
                  onChange={(variant === SIGNAL_CHIPS__VARIANT_FULL) && _onChange}
                />
              );
            })}
            {negativeSignalTags.map(tag => {
              return (
                <SignalChip
                  key={`signal-chip-negative-${tag.id}`}
                  tag={tag}
                  history={history}
                  signalTags={signalTags}
                  signalsKey='negative'
                  onChange={(variant === SIGNAL_CHIPS__VARIANT_FULL) && _onChange}
                />
              );
            })}
          </div>
        </div>
      )}
      {(variant === SIGNAL_CHIPS__VARIANT_FULL) && hasSelectedSignals && (
        <div>
          <Button flat
            className='tt-unset c-black-medium f-sm mt-1'
            onClick={event => {
              updateState({ expanded: !expanded });
            }}
          >
            {expanded ? 'Hide' : 'Select signals >'}
          </Button>
        </div>
      )}
      {(!!positiveSignalTags.length || !!negativeSignalTags.length) && manuallyOverriddenAt && manuallyOverriddenBy && account && (
        <div className='mt-1 mb-1 pl-1 c-gray-common f-sm'>
          {`Tags modified by ${account?._name} at ${moment(manuallyOverriddenAt).format('M/D/YYYY')}`}
        </div>
      )}
    </div>
  )
}

function SignalChip({ tag, history, signalTags, signalsKey, onChange }) {
  let historySignalsKey = (signalsKey === 'positive') ? 'positiveSignalsTags' : (signalsKey === 'negative') && 'negativeSignalsTags';
  let bgColorClass = (signalsKey === 'positive') ? 'bg-green-lighter c-black-medium' : (signalsKey === 'negative') && 'bg-red-lighter c-black-medium';
  return historySignalsKey && (
    <Chip
      label={tag.label}
      size='small'
      className={`mr-1 mt-1 ${!!tag.checked ? bgColorClass : 'bg-unset dashed c-aaa'}`}
      icon={<TenureIcon tag={tag} tenure={signalTags.automated.tenure} signals={signalTags.automated.tenure[signalsKey]} />}
      onClick={(onChange instanceof Function) ? () => {
        if (!!tag.checked) {
          history[historySignalsKey] = history[historySignalsKey].filter(id => id !== tag.id);
        }
        else {
          history[historySignalsKey].push(tag.id);
        }
        history.manuallyOverriddenAt = moment().toISOString();
        history.manuallyOverriddenBy = Core.getUserId();
        onChange(history);
      } : null}
    />
  );
}

function TenureIcon({ tag, tenure, signals = [] }) {
  signals = signals || [];
  let start = tenure.start ? moment(tenure.start.replace(/T.*$/, '')).format('M/D/YYYY') : MDASH;
  let end = tenure.end ? moment(tenure.end.replace(/T.*$/, '')).format('M/D/YYYY') : MDASH;
  return (
    signals.includes(tag.id)
      ? <i className='material-icons c-inherit f-lg ml-1' title={`From tenure ${start} - ${end}`}>calendar_month</i>
      : null
  );
}
