import Typography from '../components/Layout/Wrappers/Typography';

const AppUI = {
  askTechnicalExpert: (
    <i>Tricky, ask technical expert to configure</i>
  ),
  disabled: 'Disabled',
  placeholder: {
    text: 'Type to edit',
    autocomplete: 'Type to search',
    multiline: 'Type to edit',
    tree: 'Type or click to select a location',
    multiple: 'Can select multiple options',
    number: 'Type a number',
    filterBar: 'Enter a Keyword'
  },
  publicInfo: {
    tooltip: 'Visible by recruiter'
  },
  errorOnMlService: (
    <Typography className="text-capitalize">
      Sorry, something went wrong<br />with the matching score service
    </Typography>
  ),
  success: {
    email: 'Email sent'
  },
  unexpectedError: {
    message: 'Sorry, something went wrong!'
  },
  undoneAction: {
    message: 'This action cannot be undone.'
  }
};
export default AppUI;
