import moment from "moment";
import React, {
  Component
} from "react";
import Core from "../../lib/Core";
import HistoryLog from "../../lib/HistoryLog";
import Col from "../Layout/Wrappers/Col";
import IconButton from '../Layout/Wrappers/IconButton';
import Row from "../Layout/Wrappers/Row";

class HistoryMenu extends Component {
  openDrawer = ev => {
    const groupList = HistoryLog.getGroupList();
    Core.openDrawer({
      style: { width: 375 },
      content: (
        <>
          <Row>
            <Col fullWidth>
              <h1>History</h1>
            </Col>
          </Row>
          <Row>
            <Col fullWidth />
          </Row>
          <Row>
            <Col fullWidth>
              <label>Accounts</label>
              {groupList.accounts.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Candidates</label>
              {groupList.candidates.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Employers</label>
              {groupList.employers.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>Jobs</label>
              {groupList.jobs.map(itm => (
                <p
                  key={Core.getKey()}
                  className="anchor"
                  onClick={ev => HistoryLog.go(itm)}
                >
                  <span>{itm.label}</span> -{" "}
                  <span>{moment(itm.date).format("MM/DD HH:mm")}</span>
                </p>
              ))}
            </Col>
          </Row>
        </>
      )
    });
  };
  render() {
    return (
      <IconButton
        title='Open history'
        onClick={this.openDrawer}
        icon='history'
      />
    );
  }
}
export default HistoryMenu;
