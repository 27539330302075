import moment from 'moment';
import { padWithZeros } from '../../String.lib';

export function toLinkedinDateString({ date, month, year, currentDate, currentYear } = {}) {
  // console.warn('toLinkedinDateString', { date, month, year, currentDate, currentYear });
  return (
    currentDate
      ? moment().format('MMM YYYY')
      : currentYear
        ? moment().format('YYYY')
        : (!year && !date)
          ? ''
          : date
            ? moment(date).format('MMM YYYY')
            : month ? `${moment(`${year}-${padWithZeros(month, 2)}`).format('MMM YYYY')}` : ''
  );
}
