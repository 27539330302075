import { CSS_RED_RGB } from '../Constants';
import { joinHTML } from '../HTML.lib';

/**
 * story_9847, story_10383
 * 
 * @param {string} NOT HTML
 * @param {string} MAYBE HTML
 * @param {string} YES HTML
 */
export const TEMPLATE__MATCH_REJECTION_HAVES = joinHTML(`
  {{#if REQUIRED.length}}
    <strong>Required</strong>
    {{{REQUIRED}}}
    <br/>
  {{/if}}
  {{#if DESIRABLE.length}}
    <strong>Desirable</strong>
    {{{DESIRABLE}}}
    <br/>
  {{/if}}
`);

/**
 * @param {string} OPERATOR PlainText
 * @param {string} CONDITION PlainText
 * @param {string} LABELS HTML
 * @param {string} INSTRUCTION HTML
 */
export const TEMPLATE__MATCH_REJECTION_HAVES__SINGLE_LINE = joinHTML(`
  <div style="color: {{{COLOR}}};">
    {{{ICON}}}{{OPERATOR}}{{CONDITION}}{{{LABELS}}}{{{INSTRUCTION}}}{{SOURCE}}
    {{#if COMMENT}}&nbsp;<span style="color: ${CSS_RED_RGB};">({{COMMENT}})</span>{{/if}}
  </div>
`);
