import {
  Divider as MuiDivider
} from '@mui/material';

export default function Divider({
  acl = true,
  ...props
}) {
  if (!acl) { return null; }
  const _props = { ...props };
  return (
    <MuiDivider {..._props} />
  );
}
