import moment from 'moment';
import {
  NOT
} from '../../lib/Boolean.lib';
import Definition from '../../lib/Definition';
import Box from '../Layout/Wrappers/Box';
import Chip from '../Layout/Wrappers/Chip';
import {
  DATE_FORMAT
} from './FilterDateRangeColumn';
import {
  REACT_TABLE__DEFAULT_PAGE_SIZE,
  REACT_TABLE__INITIAL_PAGE
} from './useEnhancedReactTable.hook';
import {
  newUrlState
} from './useUrlQuery.hook';

export default function GlobalChipsBar({
  enhancedReactTableContext: {
    t,
    allColumns = [],
    filters = {},
    globalSearchValue = [],
    page = REACT_TABLE__INITIAL_PAGE,
    pageSize = REACT_TABLE__DEFAULT_PAGE_SIZE,
    sort = {},
    resume,
    looseMatch,
    blacklisted,
    _updatedAt,
    updateUrlState = () => null,
    setFilter,
  },
  style,
  className,
}) {

  const columns = {};

  allColumns.forEach(column => columns[column.id] = column);

  // console.debug('allColumns', columns);

  const list = [];

  Object.keys(filters).forEach((columnId, index) => {
    // list.push(<span key={`${columnId}-${index}`} className="mr-1">{columnId}:</span>);
    let column = columns[columnId] || {};
    let {
      _categoryKey: categoryKey
    } = column;
    let columnFilter = filters[columnId];

    // Filter contains an ARRAY of values
    if (Array.isArray(columnFilter)) {
      columnFilter.forEach(value => {
        let _value = categoryKey ? Definition.getLabel(categoryKey, value) : value;
        const label = (
          <>
            <span>{column.Header}: </span>
            <span>{_value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              setFilter({ columnId, value: columnFilter.filter(v => v !== value) });
            }
          })
        );
      });
    }
    // Filter contains an OBJECT
    else if (Object(columnFilter) === columnFilter) {
      if (columnFilter['$lte']) {
        let value = columnFilter['$lte'];
        value = (Number(value) === value) ? value : moment(value).format(DATE_FORMAT);
        const label = (
          <>
            <span>{column.Header}: </span>
            <span>lte {value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              delete columnFilter.$lte;
              setFilter({ columnId, value: Object.keys(columnFilter).length ? columnFilter : undefined });
            }
          })
        );
      }
      if (columnFilter['$gte']) {
        let value = columnFilter['$gte'];
        value = (Number(value) === value) ? value : moment(value).format(DATE_FORMAT);
        const label = (
          <>
            <span>{column.Header}: </span>
            <span>gte {value}</span>
          </>
        );
        list.push(
          renderChip({
            columnId,
            value,
            label,
            columnFilter,
            onDelete: () => {
              console.debug('You clicked the delete icon.');
              delete columnFilter.$gte;
              setFilter({ columnId, value: Object.keys(columnFilter).length ? columnFilter : undefined });
            }
          })
        );
      }
    }
    // Filter contains a NUMBER
    else if (!isNaN(columnFilter)) {
      let _value = categoryKey ? Definition.getLabel(categoryKey, columnFilter) : columnFilter;
      const label = (
        <>
          <span>{column.Header}: </span>
          <span>{_value}</span>
        </>
      );
      list.push(
        renderChip({
          columnId,
          value: columnFilter,
          label,
          columnFilter,
          onDelete: () => {
            console.debug('You clicked the delete icon.');
            setFilter({ columnId, value: undefined });
          }
        })
      );
    }
  });

  if (list.length) {
    list.unshift(
      renderChip({
        columnId: 'resetFilters',
        value: 'resetFilters',
        label: 'Reset Filters',
        styled: false,
        onClick: () => {
          updateUrlState({
            ...newUrlState(),
            globalSearchValue,
            resume,
            looseMatch,
            blacklisted
          });
        }
      })
    );
  }

  return (
    <Box row flex1 noWrap scrollX>
      {list}
    </Box>
  );
}

function renderChip({
  columnId,
  value,
  label,
  columnFilter,
  onClick,
  onDelete,
  styled = true
}) {
  return (
    <Chip
      key={`chip-${columnId}-${value}`}
      styled={styled}
      outlined={NOT(onClick)}
      primary={!!onClick}
      icon={!!onClick && 'filter_list_off'}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      className='c-white-all'
    />
  );
}
