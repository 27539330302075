
export const MODEL_NAME__COMPANY = 'Company';
export const MODEL_NAME__COMPANY_PLURAL = 'Companies';
export default function getCompanyModel() {
  return {
    ___model___: MODEL_NAME__COMPANY,
    id: null,
    name: null,
    alternativeNames: [],
    acquiredBy: null,
    vc: [],
    meta: {
      ranking: 0,
      numberOfEmployees: 0,
      revenue: null,
      sector: null,
      industry: null,
    },
    signalTags: {
      curated: {
        positive: [],
        negative: [],
        updatedAt: null,
        updatedBy: null,
      },
      automated: {
        positive: [],
        negative: [],
        crunchbaseOrganizationUpdatedAt: null,
      },
    },
    crunchbaseOrganizationId: null,
  };
}
