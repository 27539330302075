import React from 'react';
import {
  Fun
} from '../Function.lib';
import {
  flattenObject,
  Obj,
  stringify
} from '../Object.lib';

/**
 * React useState hook wrapper with observed state.
 * 
 * @param {object} props           Object containing the parameters.
 * @param {object} props.state     [optional] The usual state used by the original useState.
 * @param {object} props.observed  [optional] Typically, to observe component props changes.
 * @param {function} props.onChange [optional] Callback function to handle changes to the observed state.
 * @returns {any[]}
 * 
 * @returned state - Current state.
 * @returned updateState - Promise, to make a partial state update.
 * @returned setState - Function, to overwrite the full state.
 * 
 * @see https://react.dev/reference/react/useState
 */
export default function useStateObserved({
  state: initialState,
  observed: observedState,
  onChange = Fun,
}) {
  const _initialState = Obj(initialState);
  const _observedState = Obj(observedState);
  const { debug = false } = _observedState;

  if (observedState) {
    Object.assign(_observedState, {
      ___observed___: stringify(flattenObject(_observedState)),
      ..._observedState
    });
    Object.assign(_initialState, _observedState);
  }

  const [{ ___observed___, ..._state }, _setState] = React.useState(_initialState);

  const _updateState = async (update = { ___updatedAt___: Date.now() }) => {
    return new Promise((resolve) => {
      _setState((prevState) => ({ ...prevState, ...update }));
      setTimeout(() => resolve(update));
    });
  };

  if (observedState) {
    setTimeout(async () => {
      if (___observed___ !== _observedState.___observed___) {
        if (debug) {
          console.debug(
            'useState: change observed',
            '\n', ___observed___,
            '\n', _observedState.___observed___
          );
        }
        await _updateState(_observedState);
        onChange(_observedState);
      }
    });
  }

  return [_state, _updateState, _setState];
}
