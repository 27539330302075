import {
  compile
} from 'handlebars';
import moment from 'moment';
import AppUI from '../../../dictionaries/AppUI.dic';
import Core from '../../../lib/Core';
import {
  getPacificTimeIsoString,
  isValidDate
} from '../../../lib/Date.lib';
import {
  copyString,
  NOT
} from '../../../lib/GenericTools.lib';
import {
  FirstWord,
  isEmptyString
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
  createLoopbackRecord,
  deleteLoopbackRecord,
  getLoopbackRecord,
  updateLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';

export const EMPLOYER_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE = 'create_template';
export const EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME = 'CANDIDATE__NICKNAME';
export const EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME = 'CANDIDATE__FIRSTNAME';
export const EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME = 'CANDIDATE__LASTNAME';
export const EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME = 'MY__FIRSTNAME';
export const EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME = 'EMPLOYER__NAME';

const DEFAULT_CREATE_OPTION = {
  id: null,
  label: 'Create New Template'
};

export function getNewTemplateName() {
  return compile(`Template - {{USER__NAME}} - {{DATE}}`)({
    USER__NAME: FirstWord(Core.getUserName()),
    DATE: moment().format('YY/MM/DD')
  });
}

export function getTemplateSamples({ employerId }) {
  return ([
    {
      id: 0,
      employerId,
      name: getNewTemplateName() + ' (sample)',
      subject: `${EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME} message:`,
      body: `Hi ${EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME}`,
      createdBy: Core.getUserId(),
      createdAt: getPacificTimeIsoString()
    }
  ]);
}

export function EmployerManageTemplatesContent(props) {
  const [{
    selected = {},
    data = []
  }, _updateState] = useState();
  const { employerId } = props;
  const _isCreateSelected = NOT(selected.id);
  const _fetchData = async () => {
    const templates = await getLoopbackRecord({
      collection: COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
      where: { employerId },
      include: ['account'],
      /** @todo [ 2022-12-21 ][ MS: filter fields from account ] */
    });
    await _updateState({
      data: templates.length ? templates : getTemplateSamples({ employerId })
    });
  }
  function _tokenizeMacro(macro) {
    return `{{${macro || 'VARIABLE'}}}`;
  }
  return (
    <Box column h100>

      <Box flex1>
        <Fieldset
          title='Templates'
          sub='Templates are shared across your whole company'
        >
          <Menu dropdown
            name='emp_mng_select_templates'
            options={data}
            value={selected.id}
            onChange={async (value, option) => await _updateState({ selected: option })}
            onClick={_fetchData}
            renderOption={({ option }) => (
              <div style={{ minWidth: 224 }}>
                <div>
                  {option.name}
                </div>
                <div className='f-sm c-black-medium t-align-right'>
                  {`${option.account?.firstName || ""} ${option.account?.lastName || ''}`.trim()}
                  {isValidDate(option.createdAt)
                    ? ` - ${moment(option.createdAt).format('MM/DD HH:mm z')}`
                    : ''}
                </div>
              </div>
            )}
          />

        </Fieldset>

        <Fieldset
          title='Name'
        >
          <TextField
            value={selected.name || ''}
            onChange={async (event) => {
              Object.assign(selected, { name: event.target.value });
              await _updateState({ selected });
            }}
          />
        </Fieldset>

        <Fieldset
          title='Subject'
        >
          <TextField
            value={selected.subject || ''}
            onChange={async (event) => {
              Object.assign(selected, { subject: event.target.value });
              await _updateState({ selected });
            }}
          />
        </Fieldset>

        <Fieldset
          title='Body'
        >
          <RichTextBox
            value={selected.body || '<p><br/></p>'}
            onChange={async body => {
              Object.assign(selected, { body });
              await _updateState({ selected });
            }}
          />
        </Fieldset>
      </Box>

      <Box row hAuto
        className='mt-3 flex-align-left-bottom'
      >

        <Box column className='mr-auto'>
          <Typography bold large className='tt-unset'>
            Use these macros in the Subject or Body fields of your email template:
          </Typography>
          <div className='c-black-medium'>
            {[
              /** @note BB requested to hide EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME */
              { token: _tokenizeMacro(EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME) },
              { token: _tokenizeMacro(EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME) },
              { token: _tokenizeMacro(EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME) },
              { token: _tokenizeMacro(EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME) }
            ].map(({ token }) => (
              <div className='d-flex flex-align-left-center' key={`emp_mng_temp_cont_token_${token}`}>
                <span className='mr-1'>
                  {token}
                </span>
                <IconButton onClick={event => copyString(token)}>
                  <i className='material-icons c-inherit'>content_copy</i>
                </IconButton>
              </div>
            ))}
          </div>
        </Box>

        <Box row>
          <Button
            variant='contained'
            className='tt-unset min-w-160 mr-1'
            onClick={async event => {
              if (_isCreateSelected) {
                if (isEmptyString(selected.name)) {
                  return Core.showWarning('Name is required');
                }
                if (isEmptyString(selected.subject)) {
                  return Core.showWarning('Subject is required');
                }
                if (isEmptyString(String(selected.body || '').replace(/<p>(<br *\/>)*<\/p>/gi, ''))) {
                  return Core.showWarning('Body is required');
                }
                Object.assign(selected,
                  await createLoopbackRecord({
                    collection: COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
                    record: {
                      name: selected.name || '',
                      subject: selected.subject || '',
                      body: selected.body || '',
                      employerId,
                      accountId: Core.getUserId()
                    }
                  }).catch(Core.showError) || {}
                );
                if (selected?.id) {
                  await _fetchData();
                  await _updateState({ selected });
                  Core.showSuccess('Template created');
                }
              }
              else {
                Object.assign(selected,
                  await updateLoopbackRecord({
                    collection: COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
                    id: selected.id,
                    record: {
                      name: selected.name || '',
                      subject: selected.subject || '',
                      body: selected.body || '',
                      employerId
                    }
                  }).catch(Core.showError) || {}
                );
                if (selected?.id) {
                  await _fetchData();
                  await _updateState({ selected });
                  Core.showSuccess('Template updated');
                }
              }
            }}
          >
            {_isCreateSelected ? 'Create template' : `Update template`}
          </Button>
          <Button
            variant='outlined'
            color='error'
            className='tt-unset min-w-160'
            onClick={event => {
              showConfirm({
                title: 'Delete template?',
                message: (
                  <Typography strong className='f-xl' style={{ minHeight: 100 }}>
                    {AppUI.undoneAction.message}
                  </Typography>
                ),
                async onAccept() {
                  let response = await deleteLoopbackRecord({
                    collection: COLLECTION__EMPLOYER_EMAIL_TEMPLATES,
                    id: selected.id
                  }).catch(Core.showError) || {};
                  if (response?.count !== undefined) {
                    await _fetchData();
                    await _updateState({ selected: DEFAULT_CREATE_OPTION });
                    Core.showSuccess('Template deleted');
                  }
                },
                onAcceptLabel: 'Delete'
              });
            }}
            disabled={_isCreateSelected}
          >
            Delete
          </Button>
        </Box>

      </Box>

    </Box>
  );
}
