import {
  debounce,
  isEqual
} from 'lodash';
import {
  Component
} from "react";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import {
  CellMeasurer,
  CellMeasurerCache
} from "react-virtualized/dist/commonjs/CellMeasurer";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import List from "react-virtualized/dist/commonjs/List";
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  ENGAGEMENT__ACTIVE_STAGES,
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  REJECTION_REASON__10X10__NO_MATCH,
  STAGE_CONFIRMATION,
  STAGE_REVIEW,
  STAGE_SUBMISSION,
  STATUS_W_10X10
} from '../../../dictionaries/Engagement.dic';
import {
  Arr,
  sanitizeArr
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import {
  MLM__HARDCODED_ID, MLM__NO_MODEL_ID
} from "../../../lib/Definition";
import {
  Fun
} from '../../../lib/Function.lib';
import {
  NOT,
  YES
} from '../../../lib/GenericTools.lib';
import Job from "../../../lib/Job";
import {
  Obj
} from '../../../lib/Object.lib';
import {
  trim
} from '../../../lib/String.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import Box from '../../Layout/Wrappers/Box';
import Message, {
  LoadingMessage
} from '../../Layout/Wrappers/Message';
import {
  autoSelectMatch,
  getMatchEntities,
  isJobBlackListed,
  KEY__CON__LIST_PIPE,
  KEY__FIND_NEXT_TO_MATCH,
  KEY__MATCHES_LIST__FLAT_ARRAY,
  KEY__NEXT_TO_MATCH,
  KEY__SCROLL_NEXT_MATCH
} from '../Libraries/MatchList.lib';
import getJobLabels from '../Libraries/Tools/getJobLabels.tool';
import onSelectMatch from '../Libraries/Tools/onSelectMatch.tool';
import {
  MatchPageController
} from '../MatchPage';
import ListHeader from "./ListHeader";
import MatchGroupsConfig from './MatchGroupsConfig.dic';

const DEBUG = Core.debug('ListPipe');

const cellMeasurerCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 350,
});

export default class ListPipe extends Component {
  constructor() {
    super(...arguments);
    const HARD_CODED_MODEL = (Core.isLocalHost() ? MLM__NO_MODEL_ID : MLM__HARDCODED_ID);
    this.state = {
      allMatches: [],
      engagements: [],
      groups: [],
      countForThumbBarHeading: {},
      annotatorMode: !!MatchPageController().props.params.selected,
      doneMLScore: false,
      arrayWithScore: [],
      isGroupingComplete: false,
      currentMlModel: HARD_CODED_MODEL,
    };
    this.matches = [];
    this.groupBreaker = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !isEqual(prevProps.matches, this.props.matches)
    ) {
      this.setState(
        {
          allMatches: this.props.matches,
        },
        () => {
          this.makeGroupings();
          this.resizeAll();
        }
      );
    }

    if (
      prevProps.engagements
      !==
      this.props.engagements
      ||
      prevProps.engagements.length
      !==
      this.props.engagements.length
    ) {
      this.setState(
        {
          engagements: this.props.engagements,
        },
        () => {
          this.makeGroupings();
          this.resizeAll();
        }
      );
    }

    if (
      prevProps.skippedMatches.length
      !==
      this.props.skippedMatches.length
    ) {
      this.makeGroupings();
      this.resizeAll();
    }

    if (prevProps.profile?.id !== this.props.profile?.id) {
      this.makeGroupings();
    }

    if (
      prevProps.selectedMatch?.id !== this.props.selectedMatch?.id
    ) {
      this.setState({ selectedMatch: this.props.selectedMatch });
    }

  }

  /** 
   * @note 
   * This is just an interface.
   * The actual "infiniteList" becomes from a reference.
   * Look up for it.
   */
  infiniteList = {
    recomputeRowHeights: Fun(),
    scrollToRow: Fun()
  };

  resizeIndex(index) {
    cellMeasurerCache.clear(index, 0);
    this.infiniteList.recomputeRowHeights(index);
  }

  resizeAll = debounce(() => {
    cellMeasurerCache.clearAll();
    this.infiniteList.recomputeRowHeights();
  });

  scrollToIndex = (key) => {
    if (
      !!Object.keys(this.groupBreaker).length &&
      !this.state.annotatorMode
    ) {
      const getIndex = !!this.groupBreaker.hasOwnProperty(key)
        ? this.groupBreaker[key]
        : null;
      console.debug(
        'scrollToIndex',
        '\n', key, this.groupBreaker, getIndex, cellMeasurerCache.rowHeight(getIndex || 0)
      );
      if (getIndex !== null) {
        this.infiniteList.scrollToRow(getIndex);
      }
    }
  };

  toggleAnnotatorMode = () => {
    let sectionHeaders = Array.from(
      document.getElementsByClassName("list-card-section")
    );
    sectionHeaders.forEach((header) => {
      header.classList.toggle("disable");
    });
  };

  handleAnnotatorMode = (e) => {
    this.setState((state) => {
      state.annotatorMode = !state.annotatorMode;
      return state;
    }, () => {
      this.toggleAnnotatorMode();
      this.resizeAll();
    });
  };

  processMlScoresJobs = async (matches) => {
    try {
      if (!this.state.doneMLScore) {
        const { profile = {} } = getMatchEntities();
        const results = await Job.getMlMatchingScoreBulkCandoMatch(
          {
            params: {
              candidateId: profile.id,
              jobIds: matches.map((match) => match.id),
              currentMlModel: this.state.currentMlModel
            },
            apiMethod: 'get_jobs_scores'
          }
        )
        matches.forEach((match, index) => {
          const result = Obj(Arr(results).find(
            (result) => (result.item_id === match.id)
          ));
          if (result.match) {
            match.mlScore = result.match.score;
            match.mlScoreObject = result.match;
          }
        });
      }
    }
    catch (error) {
      console.error(error);
      Core.showError(AppUI.errorOnMlService);
    }
    this.setState({ doneMLScore: true });
  };

  processMlScoresCandos = async (matches) => {
    try {
      if (!this.state.doneMLScore) {
        const { profile = {} } = getMatchEntities();
        const results = await Job.getMlMatchingScoreBulkCandoMatch(
          {
            params: {
              jobId: profile.id,
              candoIds: matches.map((match) => match.id),
              currentMlModel: this.state.currentMlModel
            },
            apiMethod: 'get_candos_scores'
          }
        );
        matches.forEach((match) => {
          const result = Obj(Arr(results).find(
            (result) => (result.item_id === match.id))
          );
          if (result.match) {
            match.mlScore = result.match.score;
            match.mlScoreObject = result.match;
          }
        });
      }
    }
    catch (error) {
      console.error(error);
      Core.showError(AppUI.errorOnMlService);
    }
    this.setState({ doneMLScore: true });
  };

  processMLScoreSingle = async (params, apiMethod, callback) => {
    try {
      const { currentMlModel } = this.state;
      callback(
        await Job.getMlMatchingScoreBulkCandoMatch(
          {
            params: {
              ...params,
              currentMlModel
            },
            apiMethod
          }
        )
      );
    }
    catch (error) {
      console.error(error);
      Core.showError(
        <div className="text-capitalize">
          Sorry Something Went Wrong<br />With The Matching Score Service
        </div>
      );
    }
  };

  sortMatches = (matches) => {
    const {
      profile = {}
    } = this.props;
    const getBucket = (matches) => (type) => {
      const filteredByType = (
        !!type
          ?
          matches.filter(
            (match) => {
              let myLabels = getJobLabels({ profile, match });
              return myLabels.includes(type);
            }
          )
          :
          matches
      );
      const permittedPositives = filteredByType.filter(
        (match) => Obj(match.mlScoreObject).match === "submission"
      );
      const permittedNegatives = filteredByType.filter(
        (match) => Obj(match.mlScoreObject).match !== "submission"
      );
      const permittedPositivesSorted = permittedPositives.sort(
        (a, b) => parseFloat(b.mlScore) - parseFloat(a.mlScore)
      );
      const permittedNegativesSorted = permittedNegatives.sort(
        (a, b) => parseFloat(b.mlScore) - parseFloat(a.mlScore)
      );
      return [...permittedPositivesSorted, ...permittedNegativesSorted];
    };
    const getBucketWithObjects = getBucket(matches);
    const knownGroupsJobs = [
      ...getBucketWithObjects("Permitted"),
      ...getBucketWithObjects("Awaiting"),
      ...getBucketWithObjects("Declined"),
      ...getBucketWithObjects("BlackListed"),
    ];
    const knownGroupsObjectIds = knownGroupsJobs.map(
      (match) => match.id
    );
    const unknownGroupsObjects = matches.filter(
      (match) => !knownGroupsObjectIds.includes(match.id)
    );
    return [...knownGroupsJobs, ...getBucket(unknownGroupsObjects)()];
  };

  makeGroupings = () => {
    this.setState(
      {
        isGroupingComplete: false
      },
      async () => {
        let {
          allMatches = [],
          engagements = [],
        } = this.state;
        const {
          skippedMatches = [],
          profile = {},
          params,
          matchKey,
          shouldShowBlacklisted,
        } = this.props;
        const skippedMatchIds = sanitizeArr(
          skippedMatches
        ).map(
          (skip) => (skip.id)
        );
        let objectIdsWithEng = [];
        let jobIdsInPuDownCand = [];
        let joinOfEngWithSkip = [];
        let selectedJobByQueryParam = {};
        let countForThumbBarHeading = {};

        if (!!profile) {
          if (!shouldShowBlacklisted) {
            allMatches = allMatches.filter(
              (match) => !isJobBlackListed(profile, match)
            );
          }
          if (matchLocation(/job\/match/i)) {
            const selectedFilterJobId = this.state.selectedFilterJobId;
            if (selectedFilterJobId) {
              allMatches = allMatches.filter(
                (match) => Arr(match.engagements).find(
                  ({ jobId }) => (jobId === selectedFilterJobId)
                )
              );
            }
          }
        }

        objectIdsWithEng = engagements.map((engagement) => engagement[`${matchKey}Id`]);

        if (!!profile && Object(profile.putDownJobs).length) {
          jobIdsInPuDownCand = profile.putDownJobs;
        }

        if (!!Object(params).selected) {
          selectedJobByQueryParam = allMatches.find(
            (match) => match.id === params.selected
          );
        }

        joinOfEngWithSkip = [
          ...objectIdsWithEng,
          ...skippedMatchIds,
          ...jobIdsInPuDownCand,
          Object(selectedJobByQueryParam).id,
        ];

        let sortedMatches = this.sortMatches(
          allMatches.filter((match) => !joinOfEngWithSkip.includes(match.id))
        );

        let engToSubmit = [],
          engToProcess = [],
          engInProgress = [],
          engInActive = [],
          engNoMatch = [];

        // ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ //
        if (
          (
            this.state.currentMlModel !==
            MLM__NO_MODEL_ID
          ) &&
          sortedMatches.length
        ) {
          if (matchKey === 'candidate') {
            await this.processMlScoresCandos(sortedMatches);
          }
          else {
            await this.processMlScoresJobs(sortedMatches);
          }
        }
        else {
          allMatches.forEach((match) => {
            match.mlScore = undefined;
            match.mlScoreObject = {};
          });
          if (!this.props.isFetchingEntitiesToMatch) {
            this.setState({ doneMLScore: true });
          }
        }
        // ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ //

        const _stages = [...ENGAGEMENT__ACTIVE_STAGES];

        engagements.forEach((engagement) => {
          if (
            (engagement.state === ENGAGEMENT__STATE_OPEN) &&
            (engagement.stage === STAGE_SUBMISSION)
          ) {
            engToSubmit.push(engagement);
          }
          else if (
            (engagement.state === ENGAGEMENT__STATE_OPEN) &&
            (engagement.stage === STAGE_CONFIRMATION) &&
            new RegExp(STATUS_W_10X10).test(engagement.status)
          ) {
            engToProcess.push(engagement);
          }
          else if (
            (engagement.state === ENGAGEMENT__STATE_OPEN) &&
            (
              (
                (engagement.stage === STAGE_CONFIRMATION) &&
                NOT(new RegExp(STATUS_W_10X10).test(engagement.status))
              ) ||
              (
                _stages.indexOf(STAGE_REVIEW) <=
                _stages.indexOf(engagement.stage)
              )
            )
          ) {
            engInProgress.push(engagement);
          }
          else if (
            (engagement.state === ENGAGEMENT__STATE_CLOSED) &&
            (
              (
                _stages.indexOf(STAGE_SUBMISSION) <=
                _stages.indexOf(engagement.stage)
              ) ||
              (
                (engagement.stage === STAGE_CONFIRMATION) &&
                (engagement.rejectionReason !== REJECTION_REASON__10X10__NO_MATCH)
              )
            )
          ) {
            engInActive.push(engagement);
          }
          else if (
            (engagement.state === ENGAGEMENT__STATE_CLOSED) &&
            (engagement.stage === STAGE_CONFIRMATION) &&
            (engagement.rejectionReason === REJECTION_REASON__10X10__NO_MATCH)
          ) {
            engNoMatch.push(engagement);
          }
        });

        let type = "job";
        let selectedEng = !!Object(selectedJobByQueryParam).id
          ? selectedJobByQueryParam
          : null;
        let mainLabel = "matches";

        if (objectIdsWithEng.includes(Object(selectedJobByQueryParam).id)) {
          type = "engagement";
          selectedEng = engagements.find(
            (eng) => eng[`${matchKey}Id`] === selectedJobByQueryParam.id
          );
          mainLabel = "engagements";
        }

        const groups = [
          {
            label: MatchGroupsConfig.selected.label,
            [mainLabel]: !!selectedEng ? [selectedEng] : [],
            items: !!selectedEng ? [selectedEng] : [],
            type: type,
            key: MatchGroupsConfig.selected.key,
          },
          {
            label: MatchGroupsConfig.toMatch.label,
            items: sortedMatches,
            type: "job",
            key: MatchGroupsConfig.toMatch.key,
            // Jobs in black list.  Label = blacklist
            // P2 - Jobs has engagement with this employer.  label = "other role"
            // P2 sort, no label first, then other role, then blacklist
          },
          {
            label: MatchGroupsConfig.toProcess.label,
            items: [...engToSubmit, ...engToProcess],
            type: "engagement", // && status == W-10* (10x10*, 10by10*)
            key: MatchGroupsConfig.toProcess.key,
          },
          {
            label: MatchGroupsConfig.jobInProgress.label,
            items: engInProgress,
            type: "engagement",
            key: MatchGroupsConfig.jobInProgress.key,
            // (state == open && stage == confirmation && (status != W-10*))
            // OR (state == open && stage >= Review)
            // P2 - sort by stage,
          },
          {
            label: MatchGroupsConfig.inActive.label,
            items: engInActive,
            type: "engagement",
            key: MatchGroupsConfig.inActive.key,
            // OR state == closed && stage >= submission (includes review, screen, onsite....)
            // OR state == closed && stage == confirmation && rejectionReason != "10x10 - No Match"
            // P2 - sort by stage,
          },
          {
            label: MatchGroupsConfig.noMatch.label,
            items: engNoMatch,
            type: "engagement",
            key: MatchGroupsConfig.noMatch.key,
            // and rejectionReason == "10x10 - No Match"
          }
        ];

        let nonGrouped = groups
          .filter((g) => g.type === "engagement")
          .map((group) => group.items)
          .flat()
          .map((eng) => eng.id);

        const debugged = engagements.filter(
          (eng) => !nonGrouped.includes(eng.id)
        );

        groups.push({
          label: MatchGroupsConfig.debug.label,
          // ANY LEFT OVER ENGAGEMENTS.  SHOULD NOT HAVE ANY.  IF THERE IS ANY, PUT IN THIS GROUP
          // TO_REMOVE_2019_OCT leave this comment
          engagements: debugged,
          items: debugged,
          type: "engagement",
        });
        // Thumb bar heading Data
        countForThumbBarHeading[MatchGroupsConfig.toMatch.key] = {
          label: MatchGroupsConfig.toMatch.heading,
          key: MatchGroupsConfig.toMatch.key,
          count: sortedMatches.length,
        };
        countForThumbBarHeading[MatchGroupsConfig.toProcess.key] = {
          label: MatchGroupsConfig.toProcess.heading,
          key: MatchGroupsConfig.toProcess.key,
          count: engToSubmit.length + engToProcess.length,
        };
        countForThumbBarHeading[MatchGroupsConfig.jobInProgress.key] = {
          label: MatchGroupsConfig.jobInProgress.heading,
          key: MatchGroupsConfig.jobInProgress.key,
          count: engInProgress.length,
        };
        countForThumbBarHeading[MatchGroupsConfig.inActive.key] = {
          label: MatchGroupsConfig.inActive.heading,
          key: MatchGroupsConfig.inActive.key,
          count: engInActive.length,
        };
        countForThumbBarHeading[MatchGroupsConfig.noMatch.key] = {
          label: MatchGroupsConfig.noMatch.heading,
          key: MatchGroupsConfig.noMatch.key,
          count: `${engNoMatch.length}`,
        };

        // One dimensional items from groups
        const selectedByParam = !!selectedEng ? [selectedEng] : [];
        this.matches = [
          ...selectedByParam,
          ...sortedMatches,
          ...engToSubmit,
          ...engToProcess,
          ...engInProgress,
          ...engInActive,
          ...engNoMatch,
          ...debugged,
        ];
        // Data contains keys and indices
        let counter = 0;
        groups
          .filter((group) => !!group.items.length > 0)
          .forEach((group) => {
            this.groupBreaker[group.key] = counter;
            counter += group.items.length;
          });
        if (!!engagements) {
          this.setState(
            {
              groups,
              countForThumbBarHeading,
              isGroupingComplete: true
            },
            () => autoSelectMatch()
          );
        }
      }
    );
  };

  /**
   * 
   * @param {number} currentMlModel
   */
  handlerMLScoreMode = (currentMlModel) => {
    const mlScoreMode = (currentMlModel !== MLM__NO_MODEL_ID);
    this.setState(
      {
        currentMlModel,
        isGroupingComplete: !mlScoreMode,
        doneMLScore: !mlScoreMode,
      },
      () => {
        this.makeGroupings();
        this.resizeAll();
      }
    );
  };

  setHeadingAtSpecificIndex = (index) => {
    let label = null;
    Object.keys(this.groupBreaker).forEach((group) => {
      if (this.groupBreaker[group] === index) {
        label = !!MatchGroupsConfig[group]
          ? MatchGroupsConfig[group].label
          : null;
      }
    });
    return label;
  };

  isRowLoaded = ({ index }) => {
    return !!this.matches[index];
  };

  fetchFeed = ({ startIndex, stopIndex }) => {
    return this.matches;
  };

  render() {
    Core.setKeyValue(KEY__CON__LIST_PIPE, this);

    const {
      countForThumbBarHeading,
      isGroupingComplete = false,
      doneMLScore = false,
    } = this.state;

    let {
      profile = {},
      selectedMatch = {},
      handlerOnSelectBox,
      createSingleEngagement,
      afterMainUpdateEngagement,
      selectedMatches,
      engagements,
      handlerJobMatch,
      renderListCard,
      renderEngagementCard,
      matchKey,
      fetchMatches,
      isFetchingEntitiesToMatch,
    } = this.props;

    const _findNextToMatch = (entitiesToMatch = []) => {
      const matches = Arr(Core.getKeyValue(KEY__MATCHES_LIST__FLAT_ARRAY));
      let currentIndex = 0;
      entitiesToMatch.forEach(itemToMatch => {
        let itemIndex = matches.findIndex(item => item.id === itemToMatch.id);
        if (currentIndex < itemIndex) {
          currentIndex = itemIndex;
        }
      });
      let nextIndex = currentIndex + 1;
      nextIndex = (
        (
          nextIndex <= 0
        ) ? (
          0
        ) : (
          nextIndex >= matches.length
        ) ? (
          matches.length - 1
        ) : (
          nextIndex
        )
      );
      const nextToMatch = matches[nextIndex];
      DEBUG && console.debug('NEXT', entitiesToMatch, nextToMatch, matches);
      Core.setKeyValue(KEY__NEXT_TO_MATCH, nextToMatch);
    };

    const _scrollToNextMatch = debounce(() => {
      try {
        const matches = Arr(Core.getKeyValue(KEY__MATCHES_LIST__FLAT_ARRAY));
        let nextToMatch = Core.getKeyValue(KEY__NEXT_TO_MATCH);
        if (nextToMatch) {
          if (nextToMatch.___model___ === 'Engagement') {
            matches.find((item, index) => {
              if (item.___model___ === 'Engagement') {
                let lastIndex = index - 1;
                if (lastIndex >= 0) {
                  nextToMatch = matches[lastIndex];
                }
                return true;
              }
              return false;
            });
          }
          const nextIndex = matches.findIndex(item => item.id === nextToMatch.id);
          DEBUG && console.debug('SCROLL', nextIndex, nextToMatch);
          this.infiniteList.scrollToRow(nextIndex);
          nextToMatch && onSelectMatch(nextToMatch);
        }
      }
      catch (exception) {
        console.warn(exception);
      }
    }, 300);

    Core.setKeyValue(KEY__MATCHES_LIST__FLAT_ARRAY, this.matches);
    Core.setKeyValue(KEY__FIND_NEXT_TO_MATCH, _findNextToMatch);
    Core.setKeyValue(KEY__SCROLL_NEXT_MATCH, _scrollToNextMatch);

    const _isReady = YES(
      NOT(isFetchingEntitiesToMatch) &&
      YES(isGroupingComplete) &&
      (
        (this.state.currentMlModel === MLM__NO_MODEL_ID) ||
        YES(doneMLScore)
      )
    )

    const _hasMatches = YES(
      this.matches.length
    );

    const height = (
      matchLocation(/v3/i)
        ? 'var(--matchListHeightV3)'
        : 'var(--matchListHeightV1)'
    );

    console.debug({
      isFetchingEntitiesToMatch,
      isGroupingComplete,
      currentMlModel: this.state.currentMlModel,
      doneMLScore,
      _isReady
    });

    return (
      <Box column w100 role='ListPipe'>

        <Box role='ListPipeHeader'
          className='sticky-top bg-main'
        >
          <ListHeader
            acl={!!profile.id}
            profile={profile}
            selectedMatch={selectedMatch}
            engagements={engagements}
            fetchMatches={fetchMatches}
            callbackJobMatch={handlerJobMatch}
            selectedMatches={selectedMatches}
            createSingleEngagement={createSingleEngagement}
            matchKey={matchKey}
            countForThumbBarHeading={countForThumbBarHeading}
            scrollToIndex={this.scrollToIndex}
          />
        </Box>

        <Box column w100 role='ListPipeContent'
          acl={_isReady && _hasMatches}
          className='px-05'
          style={{ height }}
        >
          <LoadingMessage show={NOT(_isReady)}>
            Loading...
          </LoadingMessage>
          <Message show={_isReady && NOT(_hasMatches)}>
            No items to show !!!
          </Message>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.fetchFeed}
            rowCount={this.matches.length}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => {
                  return (
                    <List
                      rowCount={this.matches.length} //
                      width={width}
                      height={height}
                      rowHeight={cellMeasurerCache.rowHeight}
                      deferredMeasurementCache={cellMeasurerCache}
                      onRowsRendered={onRowsRendered}
                      ref={
                        (self) => !!self && (this.infiniteList = self)
                      }
                      rowRenderer={({ style, key, index, parent }) => {
                        return (
                          <CellMeasurer
                            key={key}
                            cache={cellMeasurerCache}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                            rowCount={this.matches.length}
                          >
                            {({ measure, registerChild }) => {
                              const {
                                profile = {},
                                match = {},
                                engagement = {}
                              } = getMatchEntities([this.matches[index]]);
                              const labelHeading = this.setHeadingAtSpecificIndex(index);
                              let element = null;
                              if (!!engagement.id) {
                                // ENGAGEMENT
                                element = renderEngagementCard({
                                  type: 'engagement',
                                  // entities
                                  engagement,
                                  profile,
                                  match,
                                  isSelected: (
                                    selectedMatch.id
                                    ===
                                    engagement.id
                                  ),
                                  // ref functions
                                  handlerOnSelectBox,
                                  selectedMatches,
                                  createSingleEngagement,
                                  afterMainUpdateEngagement,
                                  // virtualized keys
                                  key,
                                  index,
                                  style,
                                  measure,
                                  registerChild,
                                  //heading
                                  label: labelHeading,
                                });
                              }
                              else {
                                // ENTITY TO MATCH
                                element = renderListCard({
                                  type: trim(match.___model___).toLowerCase(),
                                  // entities
                                  profile,
                                  match,
                                  isSelected: (
                                    selectedMatch.id
                                    ===
                                    match.id
                                  ),
                                  // ref functions
                                  handlerOnSelectBox,
                                  selectedMatches,
                                  createSingleEngagement,
                                  afterMainUpdateEngagement,
                                  // virtualized keys
                                  key,
                                  index,
                                  style,
                                  measure,
                                  registerChild,
                                  //heading
                                  label: labelHeading,
                                });
                              }
                              return (element)
                            }}
                          </CellMeasurer>
                        );
                      }}
                      overscanRowCount={3}
                      className="virtualized-list"
                    />
                  );
                }}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Box>
      </Box>
    );
  }
}

export function ListPipeController() {
  return Obj(Core.getKeyValue(KEY__CON__LIST_PIPE));
}
