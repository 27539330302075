import {
  Component
} from "react";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Chips from "../../../Forms/Chips";
import StepHeader from '../../../Forms/StepHeader';
import Col from "../../../Layout/Wrappers/Col";
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import InputNumber from '../../../Layout/Wrappers/InputNumber';
import List from '../../../Layout/Wrappers/List';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import {
  JOB_SECTION__FEEDBACK_LOG,
  JOB_SECTION__QUALIFICATION_NOTES,
  JOB_SECTION__TECHNICAL_SKILLS,
  openJobView
} from '../../Card/JobDetails';
import JobFeedbacks from '../Forms/JobFeedbacks';

class Requirements extends Component {
  render() {
    const { parent: JobEditController = {} } = this.props;
    let { state: job = {} } = JobEditController;
    return (
      <List>

        <StepHeader {...this.props} />

        <Row>
          <Fieldset
            title={
              <>Minimum Years of Experience</>
            }
            subtitle={
              <>Leave blank if unknown, do not guess</>
            }
          >
            <InputNumber
              name="minYearsOfExperience"
              type="number"
              min="0"
              max="100"
              value={job.minYearsOfExperience}
              onChange={(minYearsOfExperience) => {
                JobEditController.setState({ minYearsOfExperience });
              }}
              className='w-100'
            />
          </Fieldset>
          <Fieldset
            title={
              <>Ideal Years of Experience</>
            }
            subtitle={<>&nbsp;</>}
          >
            <InputNumber
              name="idealMinExperience"
              type="number"
              min="0"
              max="100"
              value={job.idealMinExperience}
              onChange={(idealMinExperience) => {
                JobEditController.setState({ idealMinExperience });
              }}
              className='w-100'
            />
          </Fieldset>
          <Fieldset
            title='Maximum Years of Experience'
            subtitle={<>&nbsp;</>}
          >
            <InputNumber
              name="idealMaxExperience"
              type="number"
              min="0"
              max="100"
              value={!!job.idealMaxExperience ? job.idealMaxExperience : ""}
              onChange={(idealMaxExperience) => {
                JobEditController.setState({ idealMaxExperience });
              }}
              prefix='to'
              units='years'
              className='flex-1'
            />
          </Fieldset>
        </Row>

        <Fieldset
          title={
            <>Technical skills</>
          }
          subtitle={
            <>Key skills for recruiters to match candidates for this job</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: job.id,
              sectionName: JOB_SECTION__TECHNICAL_SKILLS
            })
          }}
        >
          <Chips
            name="technicalSkills"
            values={job.technicalSkills}
            onChange={technicalSkills => JobEditController.setState({ technicalSkills })}
          />
        </Fieldset>

        <Row className='mt-3'>
          <Col fullWidth>
            <Chips
              name="experience"
              label="Experience"
              values={job.experience}
              onChange={experience => JobEditController.setState({ experience })}
            />
          </Col>
        </Row>

        <Fieldset
          title={
            <>Insider Scoop</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: job.id,
              sectionName: JOB_SECTION__QUALIFICATION_NOTES
            })
          }}
        >
          <RichTextBox
            name="qualificationNotes"
            value={job.qualificationNotes}
            onChange={qualificationNotes => {
              JobEditController.setState({
                qualificationNotes
              });
            }}
          />
        </Fieldset>

        <Fieldset
          title={
            <>Feedback</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: job.id,
              sectionName: JOB_SECTION__FEEDBACK_LOG
            })
          }}
          subtitle={
            <>Summary is visible to the recruiting partner. Feedback log is visible to employers</>
          }
        >
          <JobFeedbacks
            job={job}
            onChange={async (update) => new Promise((resolve) => JobEditController.setState(
              (state) => Object.assign(state, update), resolve)
            )}
            className='m-0'
          />
        </Fieldset>

      </List>
    );
  }
}

export default Requirements;
