import moment from "moment";
import React from "react";
import {
  OPTION_YES
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import {
  EMPLOYMENT_HISTORIES__PRESENT
} from '../CandidateHistories.lib';
import {
  removeMappedSignalsFlag
} from '../CandidateSignalTags.lib';
import { padWithZeros } from '../../../lib/String.lib';
import {
  WarningMessage
} from '../../Layout/Wrappers/Message';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import TextField from '../../Layout/Wrappers/TextField';

const DEBUG_JOB_HOPPER = Core.debug('DEBUG_JOB_HOPPER');

/* METHODS ==================================== */

/**
 * Returns true,
 * if finds an maximum of consecutive occurrences
 * of less of a minimum position duration
 * with different employers
 *
 * @param {object} params
 * @param {object} params.resume - parsed resume
 * @param {object[]} params.resume.employmentHistories
 * @param {object[]} params.resume.employmentHistories[].positionHistories
 * @param {number} params.maxJumps - max amount of jumps between employments
 * @param {number} params.minDuration - min position duration
 * @param {number} params.maxEmployersToReview - max of last jobs to review
 * @param {number} params.maxYearsToReview - max years old employment startDate to review
 * @param {number} params.minCurrentEmploymentDuration - minimum years working in current employment to discard it as jobHopper
 * @param {number} params.escapePositionTypes - the positions matching with these keywords will be ignored for calculation
 * @param {number} params.escapeTitles - the positions matching with these keywords will be ignored for calculation
 */
export function isCandidateJobHopper({
  candidate = {},
  maxJumps = 2,
  minDuration = 1,
  maxEmployersToReview = 5,
  maxYearsToReview = 7,
  minCurrentEmploymentDuration = 2.5,
  escapePositionTypes = ['internship'],
  escapeTitles = ['teaching assistant', 'ta', 'volunteer']
}) {

  let candidateIsJobHopper = false;
  let escapePositionTypesRegExp = `(^|\\s+)(${escapePositionTypes.join('|')})($|\\s+)`;
  let escapeJobTitlesRegExp = `(^|\\s+)(${escapeTitles.join('|')})($|\\s+)`;
  let { employmentHistories = [], jobHopDisputeParser = false } = candidate;
  let countEmployers = 0;
  let countJumps = 0;

  DEBUG_JOB_HOPPER && console.debug(
    'DEBUG_JOB_HOPPER:params\n',
    {
      candidate,
      maxJumps,
      minDuration,
      maxEmployersToReview,
      maxYearsToReview,
      minCurrentEmploymentDuration,
      escapePositionTypes,
      escapeJobTitles: escapeTitles
    }
  );

  if (jobHopDisputeParser || !(Core.isAdmin() || Core.isRecruiter())) { return false; }

  employmentHistories.find(employmentHistory => {
    let { positionHistories = [], isCurrentEmployer } = employmentHistory;

    // FILTERING valid positions 
    // ---if position startDate is oldest than `maxYearsToReview` this position will be ignored---
    // ---if position match with keywords this position will be ignored---
    positionHistories = positionHistories.filter(
      ({
        positionType = '',
        title = '',
        startDateYear,
        startDateMonth,
      }) => {
        let scapedByKeyword = (
          positionType?.match(new RegExp(escapePositionTypesRegExp, 'i')) ||
          title?.match(new RegExp(escapeJobTitlesRegExp, 'i'))
        );
        const _from = `${startDateYear}${startDateMonth ? `-${padWithZeros(startDateMonth, 2)}` : ''}`;
        let allowed = (
          (moment().diff(_from, 'years') <= maxYearsToReview) &&
          !scapedByKeyword
        );
        if (DEBUG_JOB_HOPPER && !allowed) { console.debug(`JobHopper - Position discarded: "${title}"`); }
        return allowed;
      }
    );

    // BREAK if not valid positions
    if (!positionHistories.length) { return false; }

    DEBUG_JOB_HOPPER && console.debug('DEBUG_JOB_HOPPER\n', employmentHistory);

    /** */
    // GETTING minimum position startDate
    let startDate = Math.min(
      ...positionHistories.map(({ startDateMonth, startDateYear, }) => {
        let stringDate = `${startDateYear}${startDateMonth ? `-${String(startDateMonth).padStart(2, '0')}` : ''}`;
        return startDateYear && moment(stringDate).tz('America/Los_Angeles').toDate().getTime();
      })
    );

    // GETTING minimum position endDate
    let endDate = Math.max(
      ...positionHistories.map(({ endDateMonth, endDateYear, endDate, currentlyEmployed }) => {
        let stringDate = `${endDateYear}${endDateMonth ? `-${String(endDateMonth).padStart(2, '0')}` : ''}`;
        return (
          ((currentlyEmployed === OPTION_YES) || !endDate || (endDate === EMPLOYMENT_HISTORIES__PRESENT))
            ? new Date().getTime()
            : endDateYear && moment(stringDate).tz('America/Los_Angeles').toDate().getTime()
        );
      })
    );

    // CALCULATING duration
    let duration = moment(endDate).diff(startDate, 'years', true);
    /** */

    // BREAK if is current employer and duration is oldest than minCurrentEmploymentDuration, escape calc for current
    if (isCurrentEmployer) {
      if (duration >= minCurrentEmploymentDuration) {
        candidateIsJobHopper = false;
        return true;
      }
      else {
        return false;
      }
    }

    // SUM jumps
    if (duration < minDuration) {
      countJumps += 1;
    }

    // BREAK if max jumps reached
    if (countJumps >= maxJumps) {
      candidateIsJobHopper = true;
      return true;
    }

    // BREAK if max employers reached
    if (countEmployers >= ++maxEmployersToReview) {
      return true;
    }

    return false;

  });

  DEBUG_JOB_HOPPER && console.debug('DEBUG_JOB_HOPPER:result\n', candidateIsJobHopper);

  return candidateIsJobHopper;

}

/**
 * Functional Component
 *
 * "Is Candidate a Job Hopper?"
 *
 * - Only available for Admins and Recruiters
 *
 * @param {object} props_
 * @param {object} props_.CandidateEditController CandidateEdit component (parent)
 * @param {object} props_.basicsController  Basics component (child)
 *
 * @see isCandidateJobHopper
 */
export default function JobHopperFieldset(props) {
  const {
    /**
     * CandidateEdit component controller
     * (parent of Basics)
     */
    CandidateEditController
  } = props;
  const {
    /** candidate state */
    state: candidate,
    /** function to update candidate state */
    setStateStore,
  } = CandidateEditController;
  // candidate state fields
  const {
    jobHopReason4Emp,
    jobHopReason4Us,
    jobHopDisputeParser = false,
    __flagCandidateIsJobHopper
  } = candidate;
  return (
    (__flagCandidateIsJobHopper || jobHopDisputeParser) ?
      (
        <WarningMessage className='rounded m-1'>
          {/** RED TEXT */}
          <div className="d-flex flex-align-left-center f-sm w-100">
            <div className='mr-1' style={{ width: 450 }}>
              WARNING: resume parser detects <span className='fw-700'>job hopping</span> {`(2+ out of last 5 jobs < 1y)`}
            </div>
            {/** CHECKBOX */}
            <div className='flex-1'>
              <Checkbox
                name="jobHopDisputeParser"
                label='Check here if parser is wrong (no short stints)'
                labelProps={{ className: 'f-sm c-inherit' }}
                checked={jobHopDisputeParser}
                onChange={event => {
                  let checked = event.target.checked;
                  removeMappedSignalsFlag();
                  setStateStore({
                    jobHopDisputeParser: checked,
                    key: "jobHopDisputeParser"
                  });
                }}
                color="primary"
              />
            </div>
          </div>

          {jobHopDisputeParser && (
            <div className='f-sm'>

              {/** TEXTFIELD 1 */}
              <div className="d-flex flex-align-left-center py-1">
                <span className="w-100 d-flex justify-start">
                  Reasons to be included in the employer submission:
                </span>
                <TextField
                  name="jobHopReason4Emp"
                  type="text"
                  variant="standard"
                  placeholder="for example: company shut down"
                  value={jobHopReason4Emp}
                  onChange={event => {
                    setStateStore({ jobHopReason4Emp: event.target.value });
                  }}
                  color="primary"
                  fullWidth
                />
              </div>

              {/** TEXTFIELD 2 */}
              <div className="d-flex flex-align-left-center py-1">
                <span className="w-100 d-flex justify-start">
                  Reasons to be shared with employer only as needed:
                </span>
                <TextField
                  name="jobHopReason4Us"
                  type="text"
                  variant="standard"
                  placeholder="for example: disagree with company direction"
                  value={jobHopReason4Us}
                  onChange={event => {
                    setStateStore({ jobHopReason4Us: event.target.value });
                  }}
                  color="primary"
                  fullWidth
                />
              </div>

            </div>
          )}
        </WarningMessage>
      )
      : null
  );
}
