import { Arr } from '../Array.lib';
import Definition from '../Definition';
import { getEngagementUrl } from '../Engagement';
import { YES } from '../GenericTools.lib';
import { Obj } from '../Object.lib';
import {
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_PAID
} from '../../dictionaries/Engagement.dic';

export function mapAccountInfoForCSV(account) {
  console.debug('mapAccountInfoForCSV:account', account);
  let {
    firstName,
    lastName,
    _announcements,
    email,
    phone,
    _state,
    role,
    linkedInUrl,
    _createdAt,
    employers = [],
    companyName,
    leftCompanyFlag,
    newCompanyName
  } = account;
  let Row = {
    'First name': firstName,
    'Last name': lastName,
    'Announcements': _announcements,
    'Email': email,
    'Phone': phone,
    'State': _state,
    'Role': role,
    'LinkedIn': linkedInUrl,
    'Creation date': _createdAt,
    'Company name': companyName,
    'Left company flag': YES(leftCompanyFlag) ? 'Yes' : '',
    'New company name': newCompanyName
  };
  const employer = Obj(employers[0]);
  if (employer.id) {
    Row['Employer name'] = employer._name;
    Row['Employer URL'] = employer.url;
    Row['Careers Page'] = employer.careersPage;
    Row['Employer LinkedIn'] = employer.primaryContactLinkedin;
    Row['Stage'] = Definition.getLabel('stage', employer.stage);
    Row['Crunchbase'] = employer.crunchbaseUrl;
    Row['Notables'] = employer.notables;
    const placements = [];
    const offers = [];
    const { jobs } = employer;
    Arr(jobs).forEach((job) => {
      Arr(job.engagements).forEach((engagement, index) => {
        if ([
          STAGE_GUARANTEE,
          STAGE_HIRE,
          STAGE_PAID
        ].includes(engagement.stage)) {
          placements.push(getEngagementInfo({ engagement }));
        }
        else if ([
          STAGE_OFFER
        ].includes(engagement.stage)) {
          offers.push(getEngagementInfo({ engagement }));
        }
      })
    });
    const _setColumnN = (colName) => (value, index) => {
      Row[`${colName} ${index + 1}`] = value;
    };
    placements.forEach(_setColumnN('Placement'));
    offers.forEach(_setColumnN('Offer'));
  }
  console.debug('mapAccountInfoForCSV:Row', Row);
  return Row;
}

function getEngagementInfo({ engagement }) {
  const { candidate } = engagement;
  return `${candidate._name} - ${getEngagementUrl({ engagement })}`;
}
