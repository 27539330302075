import Core from "./Core";
import Http from "./Http";

const employerSourceList = {
    getEmployerIds: (opts, success) => {             // used by admin account edit
        let {include, where, fields} = opts;
        if(!include){
            include = {};
        }
        if(!where){
            where = {};
        }
        if(!fields){
            fields = {};
        }

        Http.get(
            Core.getApi(`employerSourceLists`),
            {
                filter: JSON.stringify({include,where,fields}),
            },
            function onSuccess(response) {
                success(response);
            }
        );
    },
    postOrPatchEmployer: (empId, accountId, otherFields, success) => {        // used by admin account edit
        Http.get(
            Core.getApi(`employerSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{employerId:empId}]}},{fields:['id']}),
            },
            function onSuccess(response) {
                if(response.length > 0){
                    employerSourceList.patchEmployer(response[0].id, otherFields, success);
                }
            }
        );
    },
    patchEmployer: (id, otherFields, success) => {        // used by admin account edit
        const data = {endDate: new Date(), who: otherFields.who};
        Http.patch(
            Core.getApi(`employerSourceLists/${id}`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    patchEmployerByResume: (empId, accountId, otherFields, success) => {        // used by admin account edit
        Http.get(
            Core.getApi(`employerSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{employerId:empId}]}},{fields:['id']}),
            },
            function onSuccess(response) {
                if(response.length > 0){
                    employerSourceList.resumeEmployer(response[0].id, otherFields, success);
                }
            }
        );
    },
    resumeEmployer: (id, otherFields, success) => {
        const data = {startDate: new Date(), endDate: null, who: otherFields.who};
        Http.patch(
            Core.getApi(`employerSourceLists/${id}`),
            data,
            function onSuccess(response) {
                success(response);
            }
        );
    },
    removeEmployer: (empId, accountId, data={}, success) => {
        Http.get(
            Core.getApi(`employerSourceLists`),
            {
                filter: JSON.stringify({where:{and:[{accountId:accountId},{employerId:empId}]}})
            },
            function onSuccess(response) {
                if(response.length > 0){
                    employerSourceList.removeEmployerPermanently(response[0].id, success);
                }
            }
        );
    },
    removeEmployerPermanently: (id, cb) => {
        Http.delete(
            Core.getApi(`employerSourceLists/${id}`),
            function onSuccess(response) {
                cb(response);
            }
        );
    }
}

export default employerSourceList;