import Box from '../Layout/Wrappers/Box';
import Typography from '../Layout/Wrappers/Typography';

export default function StepHeader(props) {
  let { title = '', subtitle = '' } = props;
  return (
    <Box column w100 className='m-1 mt-2'>
      <Typography blackDark strong className='f-xl'>
        {title}
      </Typography>
      <Typography acl={!!subtitle} sub>
        {subtitle}
      </Typography>
    </Box>
  );
}
