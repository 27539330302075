import {
  mapJobs
} from '../models/job';
import {
  COLLECTION__JOBS
} from '../services/BE/loopback.api';

const QUERY__CANDIDATE_JOB_CHOICES = {
  collection: COLLECTION__JOBS,
  fields: [
    'id',
    'employerId',
    'state',
    'jobTitle',
    'addressCity',
    'role',
    'roles',
    'mustHave',
    'niceToHave',
    'employerId'
  ],
  include: [
    {
      relation: 'employer',
      scope: {
        fields: ['id', 'name']
      }
    }
  ],
  order: 'jobTitle DESC',
  mapper: mapJobs
};

export default QUERY__CANDIDATE_JOB_CHOICES;
