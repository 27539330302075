import Core from '../../../../lib/Core';
import Employer from '../../../../lib/Employer';
import { NOT } from '../../../../lib/GenericTools.lib';
import Job from '../../../../lib/Job';
import { MODEL_NAME_EMPLOYER } from '../../../../lib/models/employer';
import { MODEL_NAME_JOB } from '../../../../lib/models/job';
import { trim } from '../../../../lib/String.lib';
import AppUI from '../../../../dictionaries/AppUI.dic';
import useState from '../../../../lib/hooks/useState.hook';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import { Html } from '../../../Layout/Wrappers/Html';
import IconButton from '../../../Layout/Wrappers/IconButton';
import RichTextBox from '../../../Layout/Wrappers/RichTextBox';
import EmployerUI from '../../EmployerUI.dic';


/**
 * 
 * @param {object} props
 * @param {object} props.entity  [{___model___,id,.internalSubmissionInstructions}]
 * @param {string} props.title  [ReactJSXElement]
 * @param {boolean} props.autoSave  [default:true]
 * @param {boolean} props.disabled  [default:false]
 * @param {boolean} props.readOnly  [default:false]
 * 
 * @returns 
 */
export default function InternalSubmissionInstructions({
  entity,
  title = EmployerUI.internalSubmissionInstructions.title,
  subtitle = EmployerUI.internalSubmissionInstructions.subtitle,
  onChange = async (update) => null,
  autoSave = true,
  disabled = false,
  readOnly = false,
  ...props
}) {
  const [_state, _updateState] = useState();
  const _value = (
    _state.internalSubmissionInstructions !== undefined
      ? _state.internalSubmissionInstructions
      : entity.internalSubmissionInstructions
  );
  const _trimValue = trim(_value);
  const _isPendingToSave = autoSave && (_trimValue !== entity.internalSubmissionInstructions);
  const _onChange = async (internalSubmissionInstructions) => {
    const _update = { internalSubmissionInstructions };
    if (NOT(autoSave)) { Object.assign(entity, _update); }
    await _updateState(_update);
  };
  const _onDone = async (event) => {
    try {
      if (_trimValue === entity.internalSubmissionInstructions) { return; }
      const _update = { internalSubmissionInstructions: _trimValue };
      Object.assign(entity, _update);
      await onChange(_update);
      if (NOT(autoSave)) { return; }
      if (entity.___model___ === MODEL_NAME_EMPLOYER) {
        await Employer.update(entity.id, _update);
      }
      else if (entity.___model___ === MODEL_NAME_JOB) {
        await Job.update(entity.id, _update);
      }
      await _updateState();
    }
    catch (error) {
      Core.showError(error);
    }
  }
  setTimeout(async () => {
    if (_state.prevValue !== entity.internalSubmissionInstructions) {
      await _updateState({
        internalSubmissionInstructions: entity.internalSubmissionInstructions,
        prevValue: entity.internalSubmissionInstructions
      });
    }
  });
  return (NOT(readOnly) || (readOnly && _trimValue)) && (
    <Fieldset
      {...props}
      title={title}
      subtitle={subtitle}
      actionBar={
        _isPendingToSave && (
          <IconButton
            color='secondary'
            onClick={_onDone}
          >
            <i className='material-icons c-inherit icon16'>save</i>
          </IconButton>
        )
      }
    >
      {readOnly
        ? (
          <div
            className='scroll rounded border-1 p-1'
            style={{ maxHeight: 128 }}
          >
            <Html value={entity.internalSubmissionInstructions} />
          </div>
        ) : (
          <RichTextBox
            name="internalSubmissionInstructions"
            placeholder={AppUI.placeholder.multiline}
            value={_value}
            onChange={_onChange}
            onBlur={_onDone}
          />
        )}

    </Fieldset>
  );
}
