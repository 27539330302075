import {
  CardActions,
  CardContent,
  CardHeader,
  Card as MuiCard,
} from '@mui/material';

export default function Card({
  ...props
}) {
  const _props = {
    ...props
  }
  return (
    <MuiCard {..._props} />
  );
}

Card.Actions = CardActions;
Card.Content = CardContent;
Card.Header = CardHeader;
