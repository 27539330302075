export const getColorsOnBorder = (source, toMatchWith, complexMatch, chipMatch) => {
    let borderColor = "";
    
    if(!toMatchWith){
        borderColor = "1px solid #888888";
    } else if(!!complexMatch || !!chipMatch){
        borderColor = "1px solid green";
    } else if(source === 'jobMatch'){
        borderColor = "1px solid #888888";
    } else if(!complexMatch && !chipMatch){
        borderColor = "2px solid red";
    }
    
    return borderColor;
}