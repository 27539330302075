import React from "react";
import {
  join
} from '../../../lib/Array.lib';

export default function Col({
  flex1,
  alignRight,
  className,
  style,
  fullWidth,
  children,
  ...props
}) {
  alignRight = alignRight ? { textAlign: "right" } : {};
  return (
    <div
      className={join([
        'col px-1',
        fullWidth ? 'w-100' : flex1 ? 'flex-1' : 'w-50',
        className
      ], ' ')}
      style={{
        ...alignRight,
        ...style
      }}
      {...props}
    >
      {children}
    </div>
  );
}
