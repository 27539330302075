import moment from "moment";
import React, {
  useEffect,
  useRef,
  useState
} from "react";
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_PAID,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Engagement from "../../../lib/Engagement";
import {
  COLLECTION__CANDIDATES,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  showAlert
} from '../../Dialogs/AlertDialog';
import Paper from '../../Layout/Wrappers/Paper';
import Typography from '../../Layout/Wrappers/Typography';
import Loader from "../../Shared/Loader";

export default function AccountRecruiterDetails(props) {
  let { account } = props;
  const accountId = useRef();
  const [candidates, setCandidates] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  async function fetchCandidates() {
    setLoading(true);
    setTimeout(async () => {
      await readLoopbackRecord({
        collection: COLLECTION__CANDIDATES,
        where: { accountId: account.id },
        fields: ['id']
      })
        .catch(message => {
          setError(message);
          showAlert({ message, severity: 'error' });
        })
        .then(candidates => {
          account.candidates = candidates;
          setCandidates(candidates);
        });
      accountId.current = account.id;
      setLoading(false);
    });
  }
  if (!error && !loading && !candidates) {
    fetchCandidates();
  }
  const [engagements, setEngagements] = React.useState();
  function fetchEngagements() {
    setLoading(true);
    setTimeout(async () => {
      let _engagements = [];
      let candidatesBatch = [];
      let callCount = 0;
      await Promise.allSettled(
        candidates.map(async ({ id }, index) => {
          if ((candidatesBatch.length === 100) || (index === candidates.length - 1)) {
            if (candidates.length === 1) {
              candidatesBatch.push(id)
            }
            let _candidateBatch = [...candidatesBatch];
            candidatesBatch = [];
            console.debug(_candidateBatch.length, index, candidates.length - 1, callCount++);
            await Engagement.getWhere(
              { candidateId: { inq: _candidateBatch } }, undefined,
              { fields: ['id', 'stage', 'state', 'status'] },
              false,
              { source: `Accounts__Card__AccountDetails` }
            ).then(engagements => {
              _engagements = [..._engagements, ...engagements];
            });
          }
          else {
            candidatesBatch.push(id);
          }
          return candidatesBatch;
        })
      ).catch(message => {
        setError(message);
        showAlert({ message, severity: 'error' });
      });
      account.engagements = _engagements;
      setEngagements(_engagements);
      setLoading(false);
    });
  }
  if (!error && !loading && !engagements && candidates) {
    fetchEngagements();
  }
  useEffect(() => {
    if (!loading && candidates && !!accountId.current && !!account?.id && accountId.current !== account?.id) {
      setLoading(true);
      setTimeout(async () => {
        setCandidates();
        setEngagements();
        setLoading(false);
      });
    }
  }, [account, candidates, error, loading])
  const modelIsAnAccount = (account.___model___ === 'Account');
  const alternativeCmp = <Loader />;
  if (modelIsAnAccount && candidates && engagements) {
    const totalCandidates = candidates.length;
    const totalEngagements = engagements.length;
    const closedEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_CLOSED)
    ).length;
    const confirmationEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_CONFIRMATION)
    ).length;
    const reviewEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_REVIEW)
    ).length;
    const screenEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SCREEN)
    ).length;
    const onsiteEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_ONSITE)
    ).length;
    const guaranteeEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_GUARANTEE)
    ).length;
    const offerEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_OFFER)
    ).length;
    const submissionEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SUBMISSION)
    ).length;
    const hireEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_HIRE)
    ).length;
    const submissions = sanitizeArr(
      candidates.map(
        (candidate) => (
          candidate.introduced
            ? moment(candidate.introduced).toDate().getTime()
            : 0
        )
      )
    );
    const totalOffers = engagements.filter(
      (engagement) => [
        STAGE_OFFER,
        STAGE_GUARANTEE,
        STAGE_HIRE,
        STAGE_PAID
      ].includes(engagement.stage)
    ).length;
    const totalPlacements = engagements.filter(
      (engagement) => [
        STAGE_HIRE,
        STAGE_PAID
      ].includes(engagement.stage)
    ).length;
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format("MM/DD/YY")
      : 0;
    const componentEngagements = (
      <Paper className="cursor-default p-2 c-black-medium">
        <Typography title='Total of Candidates'>{totalCandidates} candidates</Typography>
        &nbsp;|&nbsp;
        <Typography title="Total of Engagements">{totalEngagements} engagements</Typography>
        &nbsp;|&nbsp;
        <Typography title="Total of Offers (+offer)">{totalOffers} offers</Typography>
        &nbsp;|&nbsp;
        <Typography title="Total of Placements (+hire)">{totalPlacements} placements</Typography>
        <br />
        {loading ? <Loader /> : (
          engagements.length
            ? (
              <>
                <Typography title="Engagements Closed status">
                  Closed ({closedEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Review stage">
                  Review ({reviewEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Onsite stage">
                  On Site ({onsiteEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Offer stage">
                  Offer ({offerEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Guarantee stage">
                  Guarantee ({guaranteeEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Screen stage">
                  Screen ({screenEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Confirmation stage">
                  Confirmation ({confirmationEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Submission stage">
                  Submission ({submissionEngagements})
                </Typography>
                &nbsp;|&nbsp;
                <Typography title="Engagements Open and Hire stage">
                  Hire ({hireEngagements})
                </Typography>
                <br />
                <Typography title={`Last submission: ${lastSubmissionDate}`}>
                  {!!lastSubmission ? `Last submission date: ${lastSubmission}` : ""}
                </Typography>
              </>
            )
            : ''
        )}
      </Paper >
    );
    return (Core.isAdminOrCoordinator() && modelIsAnAccount) ? componentEngagements : alternativeCmp;
  }
  return alternativeCmp;
}
