import moment from 'moment';
import { MDASH } from '../Constants';

export const MODEL_NAME__EVENT = 'Event';

export function getEventModel({
  extended: requiredExtended,
} = {}) {
  const model = {
    'source': '',
    'modelName': '',
    'modelInstanceId': null,
    'actor': {},
    'eventName': '',
    'fieldName': '',
    'previousValue': null,
    'value': null,
    'context': {}
  };

  const extended = {
    ___model___: MODEL_NAME__EVENT,
    id: null,
    ...model,
    updatedAt: null,
    createdAt: null,
  };

  return requiredExtended ? extended : model;
}

export function mapEvent(item) {
  const _event = getEventModel({
    extended: true,
  });

  if (item) {

    Object.keys(_event).forEach(
      (key) => !!item[key] && (_event[key] = item[key])
    );

    _event.id = item.id || item._id;

    _event._updatedAt = _event.updatedAt
      ? moment(_event.updatedAt).format('MM-DD-YYYY')
      : MDASH;

    _event._createdAt = _event.createdAt
      ? moment(_event.createdAt).format('MM-DD-YYYY')
      : MDASH;

  }

  return _event;
}

export function mapEvents(data = []) {
  return data.map(mapEvent);
}
