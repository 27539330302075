
import { Input } from 'antd';
import React from "react";

export default function FilterStringColumnNoFacet({
  column: {
    id: columnId,
  },
  filters = {},
  setFilter = () => { },
}) {
  return (
    <div className="d-flex">
      <Input
        className="mr-1"
        style={{ width: 120 }}
        value={(filters[columnId] || {}).$regex?.pattern?.split('|') || undefined}
        onChange={(event) => {
          let value = event.target.value;
          setFilter({
            columnId,
            value: value.length ? { '$regex': { pattern: value, option: 'i' } } : undefined
          });
        }}
      />
    </div>
  );
}
