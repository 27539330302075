import {
  Icon
} from '@mui/material';
import {
  compile
} from 'handlebars';
import {
  cloneDeep
} from 'lodash';
import {
  useRef
} from 'react';
import AppUI from '../../dictionaries/AppUI.dic';
import PrescreenQuestionsSectionUI, {
  PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER,
  PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER,
  PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN
} from '../../dictionaries/PrescreenQuestionsSectionUI.dic';
import {
  Arr,
  join
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import {
  NOT
} from '../../lib/GenericTools.lib';
import {
  Obj
} from '../../lib/Object.lib';
import {
  isEmptyString,
  isNullOrUndefined
} from '../../lib/String.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../lib/constants/MaterialIconName.dic';
import useState from '../../lib/hooks/useState.hook';
import {
  mapEmployer,
  MODEL_NAME_EMPLOYER
} from '../../lib/models/employer';
import {
  MODEL_NAME_JOB
} from '../../lib/models/job';
import {
  COLLECTION__EMPLOYERS,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__EDITABLE,
  TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__READ_ONLY
} from '../../lib/templates/CandidateSubmission.template';
import {
  JOB_SECTION__MUST_HAVE_QUESTIONS,
  openJobView
} from '../Jobs/Card/JobDetails';
import {
  joinClassName
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Divider from '../Layout/Wrappers/Divider';
import Dropdown from '../Layout/Wrappers/Dropdown';
import Fieldset from '../Layout/Wrappers/Fieldset';
import IconButton from '../Layout/Wrappers/IconButton';
import InputNumber from '../Layout/Wrappers/InputNumber';
import Message, {
  ErrorMessage,
  WarningChipMessage,
  WarningMessage
} from '../Layout/Wrappers/Message';
import StyledTableCell from '../Layout/Wrappers/StyledTabCell';
import StyledTextArea from '../Layout/Wrappers/StyledTextArea';
import {
  PLACEMENT__TOP
} from '../Layout/Wrappers/StyledTooltip';
import Table from '../Layout/Wrappers/Table';
import Typography from '../Layout/Wrappers/Typography';
import Wrapper from '../Layout/Wrappers/Wrapper';
import AnswerJobsQuestions from './AnswerJobsQuestions';
import {
  PrescreenQuestionSelector
} from './PrescreenQuestionSelector';
import {
  fetchQuestionFromBank,
  mapQuestionsAnswers
} from './PrescreenQuestions.lib';

const MEM = {};

/**
 * 
 * @param {React.Component} Controller  [ classComponent: EmployerEditController | JobEditController ]
 * @returns 
 */
export function PrescreenQuestionsEdit({
  Controller = {},
  ...props
}) {
  const { current = {} } = useRef({});

  const entity = Controller.state;
  const isEmployer = entity.___model___ === MODEL_NAME_EMPLOYER;
  const isJob = entity.___model___ === MODEL_NAME_JOB;

  const _sortData = (update = {}) => {
    Object.assign(update, {
      data: Arr(update.data).map(
        (record, index) => {
          if (!record.order) {
            let order = index + 1;
            record.order = order;
          }
          return record;
        }
      ).sort(
        (a, b) => a.order - b.order
      )
    })
    return update;
  }

  const [{ data = [], ...state }, _updateState] = useState(
    _sortData({ data: entity[PrescreenQuestionsSectionUI.dataFieldName] })
  );
  clearTimeout(current.timeout);
  current.timeout = setTimeout(() => {
    if (
      NOT(
        current.busy ||
        current.error ||
        current.readyQuestions
      )
    ) {
      _fetchGlobalQuestions();
    }
  });

  const _fetchGlobalQuestions = async () => {
    current.busy = true;
    try {
      await _updateState({
        globalQuestions: await fetchQuestionFromBank()
      });
      current.readyQuestions = true;
    }
    catch (error) {
      Core.showError(current.error = error);
    }
    current.busy = false;
    await _updateState();
  }

  async function _updateController(update) {
    return await new Promise((resolve) => {
      Controller.setState(state => ({ ...state, ...update }), resolve);
    });
  }

  async function _updateData(update) {
    await _updateState(
      _sortData(update)
    );
    await _updateController({ [PrescreenQuestionsSectionUI.dataFieldName]: update.data });
  }

  const _onAddRow = async (
    record = {
      id: Date.now(),
      question: null,
      answerMandatory: false,
      questionApplyTo: null,
      answerApplyTo: PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER.id,
      autoIncludeInWriteup: false,
      instruction: null,
      author: Core.getUser()?.email || Core.getUserName(),
    }
  ) => {
    record = cloneDeep(record);
    if (data.find((r) => r.id === record.id)) {
      record.id = Date.now() + 1;
    }
    if (isEmployer) {
      record.askedBy = null;
    }
    if (isJob) {
      record.order = data.length + 1;
    }
    await _updateData({
      data: [...data, record]
    });
    const div = document.getElementById(PrescreenQuestionsSectionUI.scrollToDivId);
    div.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const _onChange = async (update) => {
    await _updateData({
      data: data.map(
        (row) => {
          if (row.id === update.id) {
            Object.assign(row, update);
          }
          return row;
        }
      )
    });
  };

  const _onDelete = async (id) => {
    await _updateData({
      data: data.filter(
        (row) => (row.id !== id)
      )
    });
  };

  const _stringToBoolean = (value) => (value === 'Yes' ? true : value === 'No' ? false : null);
  const _booleanToString = (value) => (value === true ? 'Yes' : value === false ? 'No' : null);


  const _ReadOnlyData = [
    ...Arr(state.globalQuestions)
  ];

  if (isJob) {
    _ReadOnlyData.push(
      ...Arr(Obj(entity.employer).mustHaveQuestions).sort((a, b) => a.order - b.order)
    );
  }

  const _MapOfAskedBy = {};
  [
    PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN,
    PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER
  ].forEach(o => _MapOfAskedBy[o.id] = o.label);

  return (
    <Fieldset column
      {...props}
      role='PrescreenQuestionsSection'
      title={PrescreenQuestionsSectionUI.fieldSet.title}
      subtitle={isEmployer ? PrescreenQuestionsSectionUI.fieldSet.subtitleEmployer : PrescreenQuestionsSectionUI.fieldSet.subtitleJob}
      info={AppUI.publicInfo.tooltip}
      infoIconName={MATERIAL_ICON_NAME__EYE}
      infoProps={{
        onClick: openJobView({
          jobId: isJob ? entity.id : isEmployer ? Obj(Arr(entity.jobs)[0]).id : null,
          sectionName: JOB_SECTION__MUST_HAVE_QUESTIONS
        })
      }}
    >
      <Box column flex1 scrollX
        style={{ maxHeight: 480 }}
      >
        <Table styled
          style={{
            minWidth: '100%',
          }}
          size='small'
        >
          <Table.Head className='sticky-top' style={{ zIndex: 2 }}>
            <Table.Row>
              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.order }}>
                {PrescreenQuestionsSectionUI.columnHeaders.order}
              </StyledTableCell>

              <StyledTableCell className='sticky-start' style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.question }}>
                {PrescreenQuestionsSectionUI.columnHeaders.question}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.askedBy }}>
                {PrescreenQuestionsSectionUI.columnHeaders.askedBy}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.answerMandatory }}>
                {PrescreenQuestionsSectionUI.columnHeaders.answerMandatory}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.questionApplyTo }}>
                {PrescreenQuestionsSectionUI.columnHeaders.questionApplyTo}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.answerApplyTo }}>
                {PrescreenQuestionsSectionUI.columnHeaders.answerApplyTo}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.autoIncludeInWriteup }}>
                {PrescreenQuestionsSectionUI.columnHeaders.autoIncludeInWriteup}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.instruction }}>
                {PrescreenQuestionsSectionUI.columnHeaders.instruction}
              </StyledTableCell>

              <StyledTableCell style={{ minWidth: PrescreenQuestionsSectionUI.columnWidth.author }}>
                {PrescreenQuestionsSectionUI.columnHeaders.author}
              </StyledTableCell>

              <StyledTableCell className='sticky-end align-right'>
                Actions
              </StyledTableCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {_ReadOnlyData.map(
              ({
                id,
                order,
                question,
                answerMandatory,
                questionApplyTo,
                answerApplyTo,
                autoIncludeInWriteup,
                instruction,
                askedBy,
                author,
              }) => (
                <Table.Row key={`pre_scr_qsn_sec_row_ro_${id}_${question}_${author}`}>
                  <StyledTableCell>
                    {order || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell className='sticky-start'>
                    {question || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell>
                    {_MapOfAskedBy[askedBy] || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell>
                    {_booleanToString(answerMandatory)}
                  </StyledTableCell>

                  <StyledTableCell>
                    {questionApplyTo || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell>
                    {answerApplyTo || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell>
                    {_booleanToString(autoIncludeInWriteup)}
                  </StyledTableCell>

                  <StyledTableCell>
                    {instruction || '⸺'}
                  </StyledTableCell>

                  <StyledTableCell>{author || '⸺'}</StyledTableCell>

                  <StyledTableCell className='sticky-end align-right'>
                    {'⸺'}
                  </StyledTableCell>
                </Table.Row>
              )
            )}
            {data.map(
              (record) => {
                let {
                  id,
                  order,
                  question,
                  answerMandatory,
                  questionApplyTo,
                  answerApplyTo,
                  autoIncludeInWriteup,
                  instruction,
                  askedBy,
                  author,
                } = record;
                let handle = `${PrescreenQuestionsSectionUI.name}_table_body_tr_${id}_control`;
                const Row = (props) => {
                  const [dragStatus, setDragStatus] = useState('standby');
                  let dragging = dragStatus === 'dragging';
                  let dragged = dragStatus === 'dragged';
                  return (
                    <Table.Row
                      onMouseOver={async (event) => {
                        if (MEM.dragged) {
                          let draggedRow = MEM.dragged;
                          let targetRow = record;
                          let draggedOrder = Number(draggedRow.order || 0);
                          let targetOrder = Number(targetRow.order || 0);
                          targetOrder = draggedOrder < targetOrder ? targetOrder - 1 : targetOrder;
                          data.splice(draggedOrder - 1, 1);
                          data.splice(targetOrder - 1, 0, draggedRow);
                          delete MEM.dragged;
                          await _updateState({ data });
                          setDragStatus('standby');
                        }
                      }}
                      style={{
                        position: 'relative',
                        zIndex: dragging ? 2 : 1,
                        opacity: dragging ? 0.75 : 1,
                        display: dragged ? 'none' : ''
                      }}
                    >
                      <StyledTableCell>
                        <InputNumber
                          value={order}
                          onBlur={(event) => _onChange({ id, order: event.target.value })}
                          onPressEnter={(event) => _onChange({ id, order: event.target.value })}
                        />
                      </StyledTableCell>

                      <StyledTableCell className='sticky-start'>
                        <StyledTextArea
                          value={question}
                          onChange={(value) => _onChange({ id, question: value })}
                        />
                        <WarningChipMessage show={isEmptyString(question)} className='mt-1'>
                          {PrescreenQuestionsSectionUI.requiredFieldLabel}
                        </WarningChipMessage>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Dropdown
                          name='askedBy'
                          data={PrescreenQuestionsSectionUI.askedBy.data}
                          value={askedBy}
                          onChange={(value) => _onChange({ id, askedBy: value })}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Dropdown
                          name='answerMandatory'
                          data={['Yes', 'No']}
                          value={_booleanToString(answerMandatory)}
                          onChange={(value) => _onChange({ id, answerMandatory: _stringToBoolean(value) })}
                        />
                        <WarningChipMessage show={isNullOrUndefined(answerMandatory)} className='mt-1'>
                          {PrescreenQuestionsSectionUI.requiredFieldLabel}
                        </WarningChipMessage>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Dropdown
                          name='questionApplyTo'
                          data={PrescreenQuestionsSectionUI.questionApplyTo.data}
                          value={questionApplyTo}
                          onChange={(value) => _onChange({ id, questionApplyTo: value })}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <Dropdown
                          name='answerApplyTo'
                          data={PrescreenQuestionsSectionUI.answerApplyTo.data}
                          value={answerApplyTo}
                          onChange={(value) => _onChange({ id, answerApplyTo: value })}
                        />
                        <WarningChipMessage show={isNullOrUndefined(answerApplyTo)} className='mt-1'>
                          {PrescreenQuestionsSectionUI.requiredFieldLabel}
                        </WarningChipMessage>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Dropdown
                          name='autoIncludeInWriteup'
                          data={['Yes', 'No']}
                          value={_booleanToString(autoIncludeInWriteup)}
                          onChange={(value) => _onChange({ id, autoIncludeInWriteup: _stringToBoolean(value) })}
                        />
                        <WarningChipMessage show={isNullOrUndefined(autoIncludeInWriteup)} className='mt-1'>
                          {PrescreenQuestionsSectionUI.requiredFieldLabel}
                        </WarningChipMessage>
                      </StyledTableCell>

                      <StyledTableCell>
                        <StyledTextArea
                          value={instruction}
                          onChange={(value) => _onChange({ id, instruction: value })}
                          autoSize={{ minRows: 1, maxRows: 5 }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>{author || '⸺'}</StyledTableCell>

                      <StyledTableCell
                        className='sticky-end align-right'
                      >
                        <div className='d-flex flex-align-right-center nowrap'>
                          <IconButton small
                            onClick={(event) => _onDelete(id)}
                            icon='delete'
                          />
                          <Icon className='mr-1 d-none' style={{ cursor: 'move' }} id={handle}>
                            <i className='material-icons c-inherit'>drag_handle</i>
                          </Icon>
                        </div>
                      </StyledTableCell>
                    </Table.Row>
                  );
                }
                return <Row key={`pre_scr_qsn_sec_row_${id}`} />;
              }
            )}
          </Table.Body>
        </Table>
        <Message show={!!state.fetchingData}>Fetching data</Message>
        <WarningMessage show={!state.fetchingData && !state.fetchingError && !data.length}>
          {PrescreenQuestionsSectionUI.fieldSet.noData}
        </WarningMessage>
        <ErrorMessage show={!!state.fetchingError}>{state.fetchingError}</ErrorMessage>
        <div id={PrescreenQuestionsSectionUI.scrollToDivId}></div>
      </Box>
      <Box
        className='bg-white'
      >
        <Divider className='m-0' />
        <div className='d-flex py-1'>
          <div>
            <Button
              variant='contained'
              color='primary'
              onClick={(event) => _onAddRow()}
              className='min-w-120 mr-1'
              size='small'
            >
              {PrescreenQuestionsSectionUI.fieldSet.add}
            </Button>
          </div>
          <div className='mr-1'>
            <PrescreenQuestionSelector onChange={_onAddRow} />
          </div>
          <Button flat
            onClick={(event) => Core.openPopUp(`/manage/prescreen-questions`)}
            className='tt-unset c-black-medium'
            size='small'
            startIcon={<i className='material-icons c-inherit'>launch</i>}
          >
            {PrescreenQuestionsSectionUI.manageQuestionsBankButtonLabel}
          </Button>
        </div>
      </Box>
    </Fieldset>
  );
}

function getAskedByLabel(askedBy) {
  return (
    (askedBy === PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.id)
      ? PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.label
      : (askedBy === PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.id)
        ? PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.label
        : null
  );
}

/**
 * Used on Match Pages > Candidate and Job PipeList
 * - name must be a static unique string, it is used to forward reference
 * - reference must be a function receiving dictionary of public methods for this component, this is not same as ref of react class components
 * 
 * @param {object} props 
 * @param {string} props.name
 * @param {function} props.reference
 * @param {object} props.candidate
 * @param {object} props.job 
 * @param {function} props.wrapper - Functional component
 * @param {object} props.propWrapper - Props to apply to wrapper
 * @param {object} props.propWrapper - Props to apply to content
 * @param {boolean} props.divider
 * @param {function} props.onChange
 * @param {boolean} props.editable
 * @returns 
 */
export function PrescreenQuestionsReadOnly({
  acl = true,
  name,
  candidate = {},
  job = {},
  wrapper: PropsWrapper = Wrapper,
  wrapperProps = {},
  divider = false,
  onChange = async () => null,
  editable = true,
  className,
  ...props_
}) {
  candidate = Obj(candidate);
  job = Obj(job);
  const { current = {} } = useRef({});
  const [{
    globalQuestions = []
  }, _updateState] = useState();
  if (!job.id || !candidate.id) { acl = false; }
  if (!acl) { return null; }
  clearTimeout(current.timeout);
  current.timeout = setTimeout(() => {
    if (
      NOT(
        current.busy ||
        current.error ||
        current.readyQuestions
      )
    ) {
      _fetchGlobalQuestions();
    }
    if (
      job.employerId &&
      current.readyQuestions &&
      NOT(
        current.busy ||
        current.error ||
        current.readyEmployer
      )
    ) {
      _fetchEmployer();
    }
  });
  const _fetchGlobalQuestions = async () => {
    current.busy = true;
    try {
      await _updateState({
        globalQuestions: await fetchQuestionFromBank()
      });
      current.readyQuestions = true;
    }
    catch (error) {
      Core.showError(current.error = error);
    }
    current.busy = false;
    await _updateState();
  }
  const _fetchEmployer = async () => {
    current.busy = true;
    try {
      Object.assign(job, {
        employer: await readLoopbackRecord({
          collection: COLLECTION__EMPLOYERS,
          where: {
            id: job.employerId
          },
          limit: 1,
          mapper: mapEmployer
        })
      });
      current.readyEmployer = true;
    }
    catch (error) {
      Core.showError(current.error = error);
    }
    current.busy = false;
  }
  const {
    answers = {},
    missedQuestions = {}
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs: [job]
  });
  const _answers = Object.values(answers).map(item => {
    let { question, answer, answerMandatory, askedBy } = item;
    askedBy = getAskedByLabel(askedBy);
    return (
      <li key={`pre_screen_questions_read_only_section_${question}_${answer}`}>
        <div className='f-md mr-1'>
          {answerMandatory ? <b className='c-red mr-1'>*</b> : ''}
          {question}
          {askedBy && <i className='f-sm c-black-medium'>&nbsp;{askedBy}</i>}
        </div>
        <ul>
          <li>
            <div className='f-sm c-black-medium'>{answer}</div>
          </li>
        </ul>
      </li>
    );
  });
  const _missedQuestions = Object.values(missedQuestions).map((item, index) => {
    let { question, answerMandatory, askedBy } = item;
    askedBy = getAskedByLabel(askedBy);
    return (
      <li key={`pre_screen_questions_read_only_section_missed_answers_${question}__${index}`}>
        <div className='f-md mr-1'>
          {answerMandatory ? <b className='c-red mr-1'>*</b> : ''}
          {question}
          {askedBy && <i className='f-sm c-black-medium'>&nbsp;{askedBy}</i>}
        </div>
      </li>
    );
  });
  return (!!_answers.length || !!_missedQuestions.length) && (
    <PropsWrapper
      {...wrapperProps}
      className={joinClassName([className])}
    >

      <Typography alignCenter
        title={!candidate.jobsPermitted.includes(job.id) ? 'Job not permitted' : 'Edit answers'}
        placement={PLACEMENT__TOP}
        className='m-1'
      >
        <Typography strong large blackDark mr>
          {PrescreenQuestionsSectionUI.readOnlySection.title}
        </Typography>
        <IconButton small primary
          onClick={event => AnswerJobsQuestions[name].open()}
          disabled={!candidate.jobsPermitted.includes(job.id)}
          icon='edit'
        />
      </Typography>
      {divider && <Divider />}

      <div
        className={join([
          'w-unset m-1 f-md fw-400 c-black-dark',
          'py-2 px-1',
          editable && 'rounded outlined'
        ], ' ')}
      >
        <ul className='m-0'>
          {_answers}
          {!!_missedQuestions.length && _missedQuestions}
        </ul>
      </div>
      <AnswerJobsQuestions
        name={`${name}__prescreen_questions_section__read_only__answer_jobs_questions`}
        reference={self => (AnswerJobsQuestions[name] = self)}
        candidate={candidate}
        jobIds={[job.id]}
        onAcceptLabel='Save'
        onAccept={({ jobsPermittedAnswers }) => async (event) => {
          console.debug('onAccept', jobsPermittedAnswers);
          await onChange({ jobsPermittedAnswers });
        }}
      />
    </PropsWrapper>
  )
}

/**
 * Used on CandidateResumeSubmission "Submission Notes"
 * 
 * @param {*} options 
 * @returns 
 */
export function formatHtmlJobsPermittedAnswers(options) {
  let { globalQuestions, candidate, job } = options; job = Obj(job); candidate = Obj(candidate);
  let {
    answers = {},
    missedQuestions = {}
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs: [job]
  });
  const mapSecondaryText = (value) => `<i style="font-size: 0.75rem; color: #767779">${value}</i>`;
  const mapAnswer = (redStar = false) => (answer) => (`
    <li style="font-size: 0.875rem;">
      ${redStar && answer.answerMandatory ? '<b style="color: #ff0000;">*</b>&nbsp;' : ''}
      ${answer.question}:&nbsp;
      ${mapSecondaryText(answer.answer)}
    </li>`
  );
  const mapQuestion = (question) => (`
    <li style="font-size: 0.875rem;">
      ${question.answerMandatory ? '<b style="color: #ff0000;">*</b>&nbsp;' : ''}
      ${question.question}
      ${question.askedBy ? `&nbsp;${mapSecondaryText(getAskedByLabel(question.askedBy))}` : ''}
    </li>
  `);
  answers = Object.values(answers);
  let askedBy10x10 = answers.filter(answer => answer.askedBy === PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.id);
  let askedByOther = answers.filter(answer => answer.askedBy !== PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.id);
  missedQuestions = Object.values(missedQuestions);
  return {
    readOnly: compile(TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__READ_ONLY)({
      MISSED_QUESTIONS: join(missedQuestions.map(mapQuestion), ''),
      BREAK_LINE: !!missedQuestions.length && !!askedBy10x10.length,
      ASKED_BY_10X10: join(askedBy10x10.map(mapAnswer(true)), '')
    }),
    editable: compile(TEMPLATE__CANDIDATE_SUBMISSION__JOB_NOTES__EDITABLE)({
      ASKED_BY_OTHER: join(askedByOther.map(mapAnswer(false)), '')
    }),
  };
}
