/**
 * https://github.com/10By10/MatchingML/issues/49
 * look at above url to see the preference
 * Computer degree (3) > STEM computer related (16)
 * Company: Top tier tech company (24) > Strong Tech Company (20) > Tech Company (31)
 * School: Elite University (21) > Top Ranked (23) > Strong (26) > Ranked (22)
 * Github: Impressive Github (29) > Great Github (27) > Strong Github (28) > Github
 * 
 * @todo [2024-01-11][story_9847][MS] consolidate "PositiveSignals.result" with this file.
 */

import {
  POSITIVE_SIGNALS__COMPUTER_DEGREE,
  POSITIVE_SIGNALS__ELITE_UNIVERSITY,
  POSITIVE_SIGNALS__GITHUB,
  POSITIVE_SIGNALS__GREAT_GITHUB,
  POSITIVE_SIGNALS__IMPRESSIVE_GITHUB,
  POSITIVE_SIGNALS__RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__STARTUP_EXPERIENCE,
  POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE,
  POSITIVE_SIGNALS__STRONG_GITHUB,
  POSITIVE_SIGNALS__STRONG_TECH_COMPANY,
  POSITIVE_SIGNALS__STRONG_UNIVERSITY,
  POSITIVE_SIGNALS__TECH_COMPANY,
  POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY,
  POSITIVE_SIGNALS__UNICORN_STARTUP
} from '../../../../lib/Definition';

const DEGREE = {
  [POSITIVE_SIGNALS__COMPUTER_DEGREE]: 10,
  [POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE]: 9
};
const COMPANY = {
  [POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY]: 10,
  [POSITIVE_SIGNALS__STRONG_TECH_COMPANY]: 9,
  [POSITIVE_SIGNALS__TECH_COMPANY]: 8
};
const SCHOOL = {
  [POSITIVE_SIGNALS__ELITE_UNIVERSITY]: 10,
  [POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY]: 9,
  [POSITIVE_SIGNALS__STRONG_UNIVERSITY]: 8,
  [POSITIVE_SIGNALS__RANKED_UNIVERSITY]: 7
};
const GITHUB = {
  [POSITIVE_SIGNALS__IMPRESSIVE_GITHUB]: 10,
  [POSITIVE_SIGNALS__GREAT_GITHUB]: 9,
  [POSITIVE_SIGNALS__STRONG_GITHUB]: 8,
  [POSITIVE_SIGNALS__GITHUB]: 7
};
const UNICORN = {
  [POSITIVE_SIGNALS__UNICORN_STARTUP]: 10,
  [POSITIVE_SIGNALS__STARTUP_EXPERIENCE]: 9
};

/**
 * 
 * @param {object} options 
 * @param {number} options.tagId
 * @param {number[]} options.tagIds
 * @param {boolean} options.priority
 * @returns {boolean}
 */
export function evaluatePositiveSignal({ tagId, tagIds, priority = false }) {
  return (
    !![
      DEGREE,
      COMPANY,
      SCHOOL,
      GITHUB,
      UNICORN
    ].find((signalType) =>
      tagIds.find((_tagId) => (
        (signalType[_tagId]) &&
        ((priority === true)
          ? (signalType[tagId] >= signalType[_tagId])
          : (signalType[tagId] <= signalType[_tagId])
        )
      ))
    ) ||
    !!tagIds.includes(tagId)
  );
}
