// CAVE

export const TEMPLATE__CAVE__RECRUITER__SUBJECT = `{{MATCH_DECISION}}: {{CANDIDATE__FULLNAME}} submitted to {{JOB_TITLE}} at {{EMPLOYER}}`;

export const TEMPLATE__RECRUITER__CAVE__BODY = `
Hi {{RECRUITER__NAME}},
<br/><br/>

{{#if YES}}
  <div>
    Good fit! {{CANDIDATE__FULLNAME}} was submitted to {{{JOB_LINKS}}}.
  </div>
  <br/>
  Thank you for your hard work! Let’s refine the strengths even further in the next round.
  <br/><br/>
{{/if}}

{{#if STRONG_YES}}
  <div>
    Great find! {{CANDIDATE__FULLNAME}} was submitted to {{{JOB_LINKS}}}.
  </div>
  <br/>
  <b>
    Keep up the great work—looking forward to more candidates like this!
  </b>
  <br/><br/>
{{/if}}

{{#if WEAK_YES}}
  <div>
    {{CANDIDATE__FULLNAME}} was submitted to {{{JOB_LINKS}}}.
  </div>
  <br/>
  Appreciate your effort. This candidate brings some strengths, though not an ideal match overall. Let’s tighten up the criteria for better fits.
  <br/><br/>
{{/if}}

Match Rating: {{MATCH_DECISION}} - Assessment (AI-assisted):
<br/><br/>

{{{HAVES}}}

{{{SIGNATURE}}}
`;
