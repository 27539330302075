import React from "react";
import MultipleSelect from "../Layout/Wrappers/MultipleSelect";
import { REACT_TABLE__COLUMN_XXXL } from "./useEnhancedReactTable.hook";
import { useFacets } from "./useFacets.hook";

export function FilterDefaultColumn({
  column,
  column: {
    id: columnId,
    preFilteredRows
  },
  context: { config: { facetsPath }, },
  totalCount,
  filters = {},
  setFilter = () => { },
  latestQuery = {},
}) {
  const {
    suggestions,
    fetchSuggestions,
  } = useFacets(facetsPath);
  return (
    <>
      <MultipleSelect
        name="filter"
        className="m-0"
        placeholder="Select multiple items"
        data={suggestions}
        value={filters[columnId]}
        onChange={(value) => {
          setFilter({
            columnId,
            value: value.length ? value : undefined
          });
        }}
        onSearch={value => fetchSuggestions({
          column,
          filters: latestQuery.filters,
          value
        })}
        popupMatchSelectWidth={REACT_TABLE__COLUMN_XXXL}
      />
    </>
  );
}
