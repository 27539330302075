import React from "react";
import {
  EMPLOYER_SUBMISSION_METHOD__ATS
} from '../../../lib/Definition';
import {
  makeAtsSubmissionNotes,
  sendToATS
} from "../../../lib/services/ATS/ATS.lib";
import Col from "../../Layout/Wrappers/Col";
import Row from "../../Layout/Wrappers/Row";
import AtsSubmitButton from "./AtsSubmitButton";
import TextField from '../../Layout/Wrappers/TextField';

/* METHODS ==================================== */

function ATSPreviewBlock(props) {

  const {
    candidate = {},
    selectedJob: job = {},
    rawSubmissionNotes,
    emailParams = {},
    copyAts
  } = props;

  const {
    employer = {},
    submissionMethods = [],
  } = job;

  /** ATS params */
  const {
    id: employerId,
    name: employerName = '',
    atsUrl,
    atsTypeId,
    atsApiProviders = {},
  } = employer;

  const atsApiProvider = atsApiProviders[atsTypeId] || {};

  const {
    manualSubmissionUrl = '',
  } = atsApiProvider;

  const submissionNotes = makeAtsSubmissionNotes({
    candidate,
    rawSubmissionNotes
  })

  const [state, setState] = React.useState({});
  function updateState(newState) {
    setState({ ...state, ...newState });
  }

  const publicSubmissionNotes = (
    state.publicSubmissionNotes ||
    submissionNotes.public
  );

  const privateSubmissionNotes = (
    state.privateSubmissionNotes ||
    submissionNotes.private
  );

  function onChangeInput(field) {
    return (event, value) => {
      const update = {};
      update[field] = value;
      updateState(update);
    }
  }

  async function onClickSubmit(apiName) {
    const result = await sendToATS({
      apiName,
      candidate,
      job,
      submissionNotes: {
        public: publicSubmissionNotes,
        private: privateSubmissionNotes,
      },
      emailParams,
    });
    console.debug('onClickSubmit', { result });
    return result;
  }

  return (
    <div className="bg-main rounded m-2 pb-1">
      <Row>
        <Col fullWidth>
          <label className='tt-unset'>ATS submission notes</label>
          <small>PUBLIC</small>
          <TextField
            name="publicSubmissionNotes"
            value={publicSubmissionNotes}
            onChange={onChangeInput("publicSubmissionNotes")}
            fullWidth
            multiline
            rows={5}
            maxRows={11}
            variant="filled"
            className="my-1"
          />
        </Col>
      </Row>
      <Row>
        <Col fullWidth>
          <label>&nbsp;</label>
          <small>PRIVATE</small>
          <TextField
            name="privateSubmissionNotes"
            value={privateSubmissionNotes}
            onChange={onChangeInput("privateSubmissionNotes")}
            fullWidth
            multiline
            rows={5}
            maxRows={11}
            variant="filled"
            className="my-1"
          />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex" fullWidth>
          <AtsSubmitButton
            atsTypeId={atsTypeId}
            employerName={employerName}
            employerId={employerId}
            submissionMethods={submissionMethods}
            manualSubmissionUrl={manualSubmissionUrl || atsUrl}
            onClick={onClickSubmit}
            disabled={candidate.sending || !job.submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__ATS)}
            candidate={candidate}
            copyAts={copyAts}
          />
        </Col>
      </Row>
    </div>
  );
}

/* EXPORTS ==================================== */

export {
  ATSPreviewBlock, ATSPreviewBlock as default
};

/* ============================================ */
