import queryString from "query-string";
import {
  Component,
  Fragment
} from "react";
import Core from "../../lib/Core";
import {
  EMP_MSG_TYPE__REMINDER_ID
} from "../../lib/Definition";
import Engagement from "../../lib/Engagement";
import {
  Obj
} from '../../lib/Object.lib';
import Store from "../../lib/Store";
import {
  compileText,
  decodeBase64
} from '../../lib/String.lib';
import {
  getLocation,
  getParams,
  getSearch,
  reloadLocation
} from '../../lib/URL.lib';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STATUSES,
  STAGE_CONFIRMATION
} from "../../dictionaries/Engagement.dic";
import {
  listTab,
  listTabs
} from "../../lib/models/engagement";
import QUERY__ENGAGEMENTS from '../../lib/queries/Engagements.query';
import {
  mapEmailsListToRecipients
} from "../../lib/services/Email/Email.lib";
import onReady from "../../lib/tools/onReady";
import FilterControl from "../FilterControl/FilterControl";
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Dialog from '../Layout/Wrappers/Dialog';
import Menu from '../Layout/Wrappers/Menu';
import {
  LoadingMessage
} from '../Layout/Wrappers/Message';
import Paper from '../Layout/Wrappers/Paper';
import Snackbar from '../Layout/Wrappers/Snackbar';
import List from "../List/List";
import CalendarEvent from "./CalendarEvent";
import EngagementCard from "./Card/EngagementCard";
import ContactsAndATS from "./ContactsAndATS";
import InterviewDetails from "./InterviewDetails";

export const ENGAGEMENTS_STAGES__ONLY_CONFIRMATION = 'only_confirmation';
export const ENGAGEMENTS_STAGES__EXCLUDE_CONFIRMATION = 'exclude_confirmation';
export const ENGAGEMENTS_STAGES__ALL_STAGES = 'all_stages';

export default class Engagements extends Component {
  data;
  constructor() {
    super(...arguments);
    this.state = {
      snackBarMessage: "",
      snackBarOpen: false,
      showActives: true,
      showInactives: false,
      right: true,
      selected: [],
      wantBulkUpdate: false,
      processingBulkUpdate: false,
      bulkUpdateResponse: [],
      engagements: [],
      stageFilter: ENGAGEMENTS_STAGES__ALL_STAGES
    };
    this._setReducedFilters = true;
    Store.set("path", getLocation());
  }

  componentDidMount() {
    this.reloadData = (ev) => this.loadData();
    this.loadData();
  }

  componentDidUpdate(preProps) {
    if (preProps.path !== this.props.path) {
      reloadLocation();
    }
  }

  loadData = async () => {
    this.List.restart();
    const params = queryString.parse(getSearch());
    const engagementId = (
      getParams({ pattern: '/engagement/view/:engagementId' }).engagementId ||
      getParams({ pattern: '/engagement/represent/:engagementId' }).engagementId
    );
    const where = (
      engagementId
        ? { id: engagementId }
        : this.state.showActives && this.state.showInactives
          ? {}
          : this.state.showActives
            ? { state: "Open" }
            : this.state.showInactives
              ? { state: "Closed" }
              : { state: "undefined" }
    );
    if (!engagementId) {
      if (this.state.stageFilter === ENGAGEMENTS_STAGES__EXCLUDE_CONFIRMATION) {
        where.stage = { neq: STAGE_CONFIRMATION }
      }
      else if (this.state.stageFilter === ENGAGEMENTS_STAGES__ONLY_CONFIRMATION) {
        where.stage = STAGE_CONFIRMATION
      }
    }
    try {
      if (params.o) {
        const _parsedParams = JSON.parse(decodeBase64(params.o || ''));
        where.candidateId = _parsedParams.candidateId;
      }
    }
    catch (error) {
      console.warn(error);
    }
    const filters = {
      ...params,
      ...compileText(QUERY__ENGAGEMENTS, { USER: Core.getUserId() })
    };
    await Engagement.getWhere(
      where,
      (engagements) => {
        this.setState({ engagements });
        if (!!engagementId) {
          onReady(this, "List").then(async (em) => {
            const _engagements = engagements
              .filter((eng) => eng.id === engagementId)
              .map((eng) => {
                eng.expanded = true;
                return eng;
              });
            this.List.setItems(_engagements);
          });
        }
        else {
          onReady(this, "filterControl").then((em) => {
            this.filterControl.setItems(engagements);
          });
        }
      },
      filters
    );
  }

  updateField = (engagement, update, callback = () => { }) => {
    const { selected } = this.state;

    if (/end/i.test(update.stage)) {
      update.open = false;
    }

    if (selected.length) {

      // This is the bulk operation flow | 2021-08-16 Mon µ
      selected.forEach((card) => {
        const { state: engagement } = card;
        Engagement.update(
          engagement,
          update,
          response => {

            engagement.checked = true;

            this.List.updateItem({ ...engagement, ...update }
              //, { reload: true }
            );

            // story-3778-m2 | 2021-08-13 Fri µ
            // The reload is not re-rendering the card component for that we must update the card state.
            card.setState({ ...engagement, ...update });

          },
          failure => Core.showError(`failed to update engagement with id = ${engagement.id}`)
        );
      });

    } else {

      Engagement.update(
        engagement,
        update,
        response => callback(response),
        failure => Core.showError(`failed to update engagement with id = ${engagement.id}`)
      );

    }

  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };

  handlerBulkUpdate = () => {
    let {
      selectedStatusForBulkUpdate,
      selected,
      engagements,
      selectedStatusForRejectionReason,
    } = this.state;

    if (!selectedStatusForBulkUpdate) {
      Core.showWarning("You didn't pick any status");
      return;
    }

    if (!selected.length) {
      Core.showWarning("no engagements are selected, please select at least 1 engagement");
      return;
    }

    if (window.confirm("Are you sure")) {
      this.setState(
        { processingBulkUpdate: true, bulkUpdateResponse: [] },
        () => {
          let payload = selected.map((card) => {

            const { state: engagement } = card;

            let p = {
              id: engagement.id,
              status: selectedStatusForBulkUpdate
            };

            if (!!selectedStatusForRejectionReason) {
              p["rejectionReason"] = selectedStatusForRejectionReason;
            }

            return p;

          });

          Engagement.updateMany(
            payload,
            (response) => {
              let bulkUpdateResponse = response.res;

              bulkUpdateResponse.forEach((resEng) => {
                this.updateEngagementInArray(
                  engagements,
                  resEng.id,
                  Object(payload.find((eng) => eng.id === resEng.id))
                );
              });

              this.setState(
                {
                  engagements,
                  processingBulkUpdate: false,
                  bulkUpdateResponse,
                },
                () => {
                  this.filterControl.setItems(engagements);
                }
              );

              this.setState({ wantBulkUpdate: false });
            },
            () => {
              this.setState({ processingBulkUpdate: false });
              Core.showError("something went wrong");
            }
          );
        }
      );
    }
  };

  updateEngagementInArray = (engagements, engId, data) => {
    engagements.forEach((engagement) => {
      if (engagement.id === engId) {
        Object.keys(data).forEach((attr) => {
          engagement[attr] = data[attr];
        });
      }
    });

    return engagements;
  };

  render() {
    const {
      engagements = [],
      selected,
      selectedStatusForBulkUpdate,
      processingBulkUpdate,
      bulkUpdateResponse,
      selectedStatusForRejectionReason,
    } = this.state;
    const { employer = {} } = Obj(engagements[0]);
    const engagementId = (
      getParams({ pattern: '/engagement/view/:engagementId' }).engagementId ||
      getParams({ pattern: '/engagement/represent/:engagementId' }).engagementId
    );
    setEngagementsController(this);
    return (
      <Box column h100 w100>

        <FilterControl
          acl={!engagementId}
          Controller={this}
          menus={Engagement.menus}
          more={Engagement.more}
          onChange={(filtered) => this.List.setItems(filtered)}
          toolBarLeft={
            Core.isAdminOrCoordinator() &&
            !engagementId && (
              <div className='d-flex flex-align-left-center'>
                <Checkbox
                  label={<span className='f-sm c-black-medium'>Active Engagements</span>}
                  checked={this.state.showActives}
                  onChange={event => {
                    this.List && this.List.restart();
                    this.setState({ showActives: event.target.checked }, (then) => this.loadData());
                  }}
                  className='mr-2 d-none'
                />
                <Checkbox
                  label={<span className='f-sm c-black-medium'>Inactive Engagements</span>}
                  checked={this.state.showInactives}
                  onChange={event => {
                    this.List && this.List.restart();
                    this.setState({ showInactives: event.target.checked }, (then) =>
                      this.loadData()
                    );
                  }}
                  className='mr-2 d-none'
                />
                <Menu
                  options={[
                    {
                      id: ENGAGEMENTS_STAGES__ONLY_CONFIRMATION,
                      label: 'Only confirmation'
                    },
                    {
                      id: ENGAGEMENTS_STAGES__EXCLUDE_CONFIRMATION,
                      label: 'Exclude confirmation'
                    },
                    {
                      id: ENGAGEMENTS_STAGES__ALL_STAGES,
                      label: 'All stages'
                    }
                  ]}
                  value={this.state.stageFilter}
                  onChange={(stageFilter) => {
                    this.List && this.List.restart();
                    this.setState({ stageFilter }, (then) =>
                      this.loadData()
                    );
                  }}
                  renderSelected={
                    ({ selected = {}, open }) => (
                      <Button
                        className='tt-unset space-between min-w-120'
                        variant='contained'
                        size='small'
                        color={open ? 'inherit' : 'secondary'}
                        endIcon={
                          <i className={`material-icons c-inherit`}>{open ? 'expand_less' : 'expand_more'}</i>
                        }
                      >
                        {selected.label}
                      </Button>
                    )
                  }
                />
              </div>
            )
          }
          toolBarRight={
            <Fragment>
              {!!(this.state.selected || []).length && false && (
                <Button flat
                  label={`Update  ( ${this.state.selected.length} ) Engagements`}
                  className="list-add-new"
                  onClick={() => this.setState({ wantBulkUpdate: true })}
                />
              )}
            </Fragment>
          }
        />

        <Box column h100 w100 scrollY>

          <List
            ref={(List) => (this.List = List)}
            tabs={listTabs}
            tab={listTab}
            disableTabs={!!engagementId}
            name="Engagement"
            card={EngagementCard}
            parent={this}
            onCheck={(id, checked, card) => {
              let selected = [...this.state.selected];

              if (!checked) {
                selected = selected.filter(card => card.state.id !== id);
              } else {
                selected.push(card);
              }

              let engagements = this.updateEngagementInArray(
                this.state.engagements,
                id,
                { checked }
              );

              this.setState({ selected, engagements });
            }}
          >
            {!!engagementId && employer.id && (
              <div className="d-flex flex-align-center py-2 px-0">
                <div className="d-flex flex-align-left-top">
                  <div className='w-50 mr-2'>
                    <Paper className="align-left py-2">
                      <ContactsAndATS
                        engagement={this.state.engagements[0]}
                        employerTodoReminderRecipients={
                          mapEmailsListToRecipients({
                            emailsList: employer.emailsList,
                            messageTypes: [EMP_MSG_TYPE__REMINDER_ID],
                            accountType: 'Employer Additional Contact',
                            primaryContact: {
                              name: employer.primaryContactName,
                              email: employer.primaryContactEmail,
                              accountType: 'Employer Contact'
                            }
                          })
                        }
                      />
                    </Paper>
                  </div>
                  <Paper className="align-left w-50 p-2">
                    <InterviewDetails
                      engagement={this.state.engagements[0]}
                    />
                  </Paper>
                </div>
              </div>
            )}
          </List>

          <CalendarEvent
            ref={(self) => (this.CalendarEvent = self)}
            parent={this}
          />

          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            onClose={this.hideMessage}
          />

          <Dialog
            title="Update Status"
            actions={[
              <Button outlined minW120
                label="Cancel"
                onClick={() => this.setState({ wantBulkUpdate: false })}
              />,
              <Button primary minW120
                label="Submit"
                onClick={() => this.handlerBulkUpdate()}
              />,
            ]}
            paperStyle={{ width: 800 }}
            open={this.state.wantBulkUpdate}
            scroll={"body"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {!processingBulkUpdate && (
              <>
                You are going to update these ( {selected.length} ) engagements:
                {Array.isArray(selected) &&
                  selected.map((card, index) => {
                    const { state: engagement } = card;
                    return (
                      <p key={`engagements-selected-${engagement.id}`}>
                        {!!bulkUpdateResponse.length ? (
                          bulkUpdateResponse
                            .map((e) => e.id)
                            .includes(engagement.id) ? (
                            <i className='material-icons'>check</i>
                          ) : (
                            <i className='material-icons'>cancel</i>
                          )
                        ) : (
                          ""
                        )}
                        <strong style={{ fontSize: "12px" }}>
                          - {engagement._name} => {engagement._jobTag}
                        </strong>{" "}
                        &nbsp;&nbsp;
                        <span
                          className="anchor"
                          onClick={() => {
                            let engagements = this.updateEngagementInArray(
                              this.state.engagements,
                              engagement.id,
                              { checked: false }
                            );
                            this.setState(
                              {
                                engagements,
                                selected: selected.filter(
                                  selectedEng =>
                                    (selectedEng.id !== engagement.id)
                                ),
                              },
                              () => {
                                this.filterControl.setItems(engagements);
                              }
                            );
                          }}
                        >
                          remove
                        </span>
                      </p>
                    );
                  })}
                <div className="">
                  Please pick Status from dropdown:&nbsp;&nbsp;
                  <Menu dropdown
                    name="status"
                    value={selectedStatusForBulkUpdate}
                    onChange={(selectedStatusForBulkUpdate) => {
                      this.setState({
                        selectedStatusForBulkUpdate,
                        bulkUpdateResponse: [],
                      });
                    }}
                    options={[...ENGAGEMENT__ACTIVE_STATUSES]}
                  />
                  <div className="Modal-block">
                    Rejection Reason
                    <Menu dropdown
                      name="rejectionReason"
                      value={selectedStatusForRejectionReason}
                      onChange={(selectedStatusForRejectionReason) => {
                        this.setState({
                          selectedStatusForRejectionReason,
                          bulkUpdateResponse: [],
                        });
                      }}
                      options={[...ENGAGEMENT__ACTIVE_REJECTION_REASONS]}
                    />
                  </div>
                </div>
              </>
            )}


          </Dialog>

          <LoadingMessage show={processingBulkUpdate}>
            Processing...
          </LoadingMessage>

        </Box>

      </Box>
    );
  }
}

export const KEY__ENGAGEMENTS_CONTROLLER = 'engagements_controller';
export function setEngagementsController(controller) {
  return Core.setKeyValue(KEY__ENGAGEMENTS_CONTROLLER, controller);
}
export function EngagementsController(controller) {
  return Obj(Core.getKeyValue(KEY__ENGAGEMENTS_CONTROLLER));
}
