import {
  Arr
} from '../../../lib/Array.lib';
import {
  POSITIVE_SIGNALS__ACQUIRED_STARTUP,
  POSITIVE_SIGNALS__FORTUNE_1000,
  POSITIVE_SIGNALS__PRE_SEED_EXPERIENCE,
  POSITIVE_SIGNALS__PUBLIC_COMPANY,
  POSITIVE_SIGNALS__SEED_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_A_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_B_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_C_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_D_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_E_PLUS_EXPERIENCE,
  POSITIVE_SIGNALS__STARTUP_EXPERIENCE
} from '../../../lib/Definition';
import {
  matchLocation
} from '../../../lib/URL.lib';

/**
 * Filters out specific positive signals for candidate summary depending on the current location.
 * 
 * If the current location matches '/candidate/create/' or '/candidate/edit/', the function filters out
 * certain tags based on whether they are related to pre-seed or series A to E experiences, or to 
 * Fortune 1000 companies.
 * 
 * @param {Array<{id: string}>} [positiveSignalTags=[]] - Array of positive signal tags, each tag being an object with an `id` property.
 * @returns {Array<{id: string}>} - The filtered array of positive signal tags.
 * @see https://app.shortcut.com/10by10/story/11123
 */
export default function filterPositiveSignalsForCandidateSummary(positiveSignalTags = []) {
  if (
    matchLocation('/candidate/create/') ||
    matchLocation('/candidate/edit/')
  ) {
    const hasPreSeedOrSeriesAtoE = Arr(positiveSignalTags).find((tag) => {
      return (
        [
          POSITIVE_SIGNALS__PRE_SEED_EXPERIENCE,
          POSITIVE_SIGNALS__SEED_EXPERIENCE,
          POSITIVE_SIGNALS__SERIES_A_EXPERIENCE,
          POSITIVE_SIGNALS__SERIES_B_EXPERIENCE,
          POSITIVE_SIGNALS__SERIES_C_EXPERIENCE,
          POSITIVE_SIGNALS__SERIES_D_EXPERIENCE,
          POSITIVE_SIGNALS__SERIES_E_PLUS_EXPERIENCE
        ].includes(tag.id)
      )
    });
    const hasFortune1000 = Arr(positiveSignalTags).find(
      (tag) => (tag.id === POSITIVE_SIGNALS__FORTUNE_1000)
    );
    positiveSignalTags = Arr(positiveSignalTags).filter(
      (tag) => {
        if (
          // "If the employer list of positive tags include pre-seed and the Series A, B, C... tags, 
          // don't show the Acquired Startup and Startup experience in the list"
          (
            hasPreSeedOrSeriesAtoE &&
            [
              POSITIVE_SIGNALS__ACQUIRED_STARTUP,
              POSITIVE_SIGNALS__STARTUP_EXPERIENCE
            ].includes(tag.id)
          ) ||
          // "If the employer list of positive tags shows Fortune 1000, don't show Public company"
          (
            hasFortune1000 &&
            (tag.id === POSITIVE_SIGNALS__PUBLIC_COMPANY)
          )
        ) {
          return false;
        }
        return true;
      }
    );
  }
  return positiveSignalTags;
}
