import Core from "./Core";
import Http from "./Http";
import {
  model as announcementModel,
  mapAnnouncements
} from "./models/announcement";

import getStateModel from "./tools/getStateModel";

const cache = {};
const commonQuery = {
  include: [
    {
      job: [
        {
          relation: "employer",
          scope: {
            include: ["employerBlackList", "employerSourceList"]
          }
        },
        "jobSourceList"
      ]
    },
    "employer"
  ]
};

const numOfDaysMostRecent = 90;

const Announcement = {
  cleanCache: em =>
    Object.keys(cache).forEach(key => {
      delete cache[key];
    }),
  getMostRecent: (where, cb) => {
    cb = cb instanceof Function ? cb : function() {};
    Http.get(
      Core.getApi("Announcements"),
      {
        filter: JSON.stringify({
          ...commonQuery,
          where: {
            createdAt: {
              gt: new Date(
                new Date().getTime() - numOfDaysMostRecent * 86400 * 1000
              )
            }
          },
          order: "createdAt DESC"
        })
      },
      function onSuccess(response) {
        cb(mapAnnouncements(response));
      },
      function onFailure(error) {
        cb([]);
      }
    );
  },
  post: (announcement, success, failure) => {
    Announcement.cleanCache();
    Http.post(
      Core.getApi("Announcements"),
      getStateModel(announcement, announcementModel),
      success,
      failure
    );
  },
  update: (announcementId, update, success, failure) => {
    Announcement.cleanCache();
    Http.patch(
      Core.getApi("Announcements/" + announcementId),
      getStateModel(update, announcementModel),
      success,
      failure
    );
  },
  delete: (announcementId, success) => {
    Announcement.cleanCache();
    Http.delete(Core.getApi("Announcements/" + announcementId), success);
  }
};

export default Announcement;
