import Core from '../../../lib/Core';
import {
  matchLocation,
  setLocation
} from '../../../lib/URL.lib';
import {
  mapWrapperName
} from './Theme.lib';

export default function Router({
  redirect = false,
  routes = [],
  ...props
}) {
  try {
    const route = routes.find(
      (
        {
          path,
          acl = true,
          render: Component = (props) => null,
          ...route
        }
      ) => (
        (
          (acl === true) &&
          (path && !!matchLocation(path))
        )
      )
    );
    // console.debug('Router', routes, props, route);
    if (route) {
      const { render: Component = (props) => null, ..._props } = route;
      const _wrapperProps = mapWrapperName(
        'Router',
        {
          ...props,
          ..._props
        }
      );
      return <Component {..._wrapperProps} />
    }
  }
  catch (error) {
    Core.showError(error);
  }
  if (redirect) {
    setLocation(
      Core.getPath(
        (redirect === true)
          ? 'jobs'
          : redirect)
    );
  }
  return null;
}
