import {
  useState
} from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import StickyNote from '../../Layout/Wrappers/StickyNote';
import {
  fetchMatchDecisions
} from '../Libraries/MatchDecisions.lib';
import MatchDecisionMaker from './MatchDecisionMaker';

export default function MatchDecisions(props) {
  let { engagement, children } = props;
  const NOTE_KEY = `engagement__other_match_decisions__${engagement.id}`;
  const [decisions, setDecisions] = useState([]);
  const _fetch = async () => setDecisions(
    Arr(
      await fetchMatchDecisions({ engagement }).catch(Core.showError)
    ).map((matchDecisionTableRecord) => ({ matchDecisionTableRecord }))
  );
  return (
    <StickyNote
      title={children}
      dialogTitle={<div className='w-100 truncate'>Match decisions for {engagement._name}</div>}
      styleDialog={{ minWidth: 512, maxWidth: 'calc(50vw - 720px)' }}
      reference={self => Core.setKeyValue(NOTE_KEY, self)}
      className='mr-1'
      minimized
      onMaximized={_fetch}
      position={{ top: 72, left: 320 }}
    >
      <div className='px-2 w-100'>
        {decisions.map((props) => (<MatchDecisionMaker {...props} />))}
      </div>
    </StickyNote>
  );
}
