import moment from 'moment';
import {
  Component
} from 'react';
import {
  join
} from '../../../lib/Array.lib';
import {
  FULLDAY_MILL_SECOND_REFERENCE,
  FULLDAY_SECOND_REFERENCE,
  MDASH,
  TIMEZONE__LA
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATETIME__ISO,
  DATE_FORMAT__DATETIME__US_LONG,
  DATE_FORMAT__DATETIME__US_SHORT,
  DATE_FORMAT__DATE__ISO,
  DATE_FORMAT__DATE__US_LONG,
  DATE_FORMAT__DATE__US_SHORT,
  DATE_FORMAT__TIME__ISO,
  DATE_TYPE__DATE,
  DATE_TYPE__DATE_TIME,
  formatDateTime24hPT,
  getISOString,
  isValidDate
} from '../../../lib/Date.lib';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  compileText
} from '../../../lib/String.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import IconButton from '../../Layout/Wrappers/IconButton';
import {
  PLACEMENT__LEFT
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';

const mdash = '—';
class CustomPicker extends Component {
  constructor() {
    super(...arguments);
    this.parent = this.props.parent.parent;
    this.state = {};
  }
  render() {
    const stage = this.parent.state.stage;
    let {
      value,
      field,
      streakField,
      label,
      onChange,
      isFullday,
      title,
      readOnly = false,
      shortFormat = false,
      width
    } = this.props.args;
    value = value ? moment.tz(value, TIMEZONE__LA) : false;
    const pickers = this.parent.pickers;
    const updateValue = date => {
      if (Core.isAdminOrCoordinator()) {
        try {
          const update = {};
          const newDate = moment(date);
          update[field] = newDate.toISOString();
          Core.log('update', update);
          this.parent.updateField(update, null, update => {
            if (onChange instanceof Function) {
              onChange({}, date);
            }
          });
        } catch (ex) {
          console.error(ex);
        }
      }
      this.removeOnDismiss = false;
    };
    const deleteValue = ev => {
      const update = {};
      update[field] = '';
      this.parent.updateField(update);
    };
    const DEFAULT_INPUT_WIDTH = 75;
    const sizes = {
      [DATE_TYPE__DATE]: (shortFormat
        ? 60
        : DEFAULT_INPUT_WIDTH
      ),
      [DATE_TYPE__DATE_TIME]: (shortFormat
        ? 90
        : 120
      ),
      true: DEFAULT_INPUT_WIDTH
    }
    const renderFormats = {
      [DATE_TYPE__DATE]: (shortFormat
        ? DATE_FORMAT__DATE__US_SHORT
        : DATE_FORMAT__DATE__US_LONG
      ),
      [DATE_TYPE__DATE_TIME]: (shortFormat
        ? DATE_FORMAT__DATETIME__US_SHORT
        : DATE_FORMAT__DATETIME__US_LONG
      ),
    }
    const isoFormats = {
      [DATE_TYPE__DATE]: DATE_FORMAT__DATE__ISO,
      [DATE_TYPE__DATE_TIME]: DATE_FORMAT__DATETIME__ISO
    }

    const isInterviewField = /screen\d|onsite\d/i.test(field);
    const isHomeworkField = /homework/i.test(field);

    const dateType = (
      (isInterviewField && NOT(isFullday))
        ? DATE_TYPE__DATE_TIME
        : DATE_TYPE__DATE
    );
    const dateISOFormat = isoFormats[dateType];
    const renderDateFormat = renderFormats[dateType];

    const labelWidth = 64;
    const inputWidth = sizes[isInterviewField || isHomeworkField] || sizes[dateType];
    const removeIconWidth = 24;
    const pickerHeight = 24;
    const pickerWidth = width ? (width - 20) : (labelWidth + inputWidth + removeIconWidth);

    const renderedRemoveIcon = Core.isAdminOrCoordinator() && (
      <div className='d-flex flex-align-center' style={{ width: removeIconWidth }}>
        <IconButton
          title={`Remove ${label || streakField}`}
          className={join([
            'btn-remove icon16',
            NOT(isInterviewField) && 'none'
          ], ' ')}
          onClick={ev => {
            ev.preventDefault();
            deleteValue();
          }}
        >
          <i className='material-icons'>
            remove_circle_outline
          </i>
        </IconButton>
      </div>
    );

    const endAdornment = (
      <label className='picker_adornment f-sm'>
        {isValidDate(value) ? moment(value).format(renderDateFormat) : MDASH}
      </label>
    )

    return (
      <Box
        title={(
          title || (value
            ? compileText('{{LABEL}}: {{DATE}}', {
              LABEL: label || streakField,
              DATE: formatDateTime24hPT(value)
            })
            : ''
          )
        )}
        placement={PLACEMENT__LEFT}
        className={
          join([
            NOT(readOnly) && 'date-picker',
            'd-flex flex-align-left-center nowrap mb-05'
          ], ' ')
        }
        style={
          {
            width: pickerWidth,
            height: pickerHeight
          }
        }
      >
        {label && (
          <label
            className={join([
              'f-sm nowrap',
              readOnly && 'c-gray-medium'
            ], ' ')}
            style={{ width: labelWidth }}
          >
            {label}:
          </label>
        )}
        {readOnly ? (
          <label
            className='c-gray-medium f-sm'
            style={{ width: inputWidth }}
          >
            {moment(value).format(DATE_FORMAT__DATE__US_LONG)}
          </label>
        ) : isInterviewField ? (
          <>
            <TextField
              name={`datetime_local_${field}`}
              inputRef={self => (pickers[field] = self)}
              type={dateType}
              value={moment(value).format(dateISOFormat)}
              variant='standard'
              onChange={event => {
                let date = event.target.value;
                if (!date) {
                  event.target.focus();
                  setTimeout(st => {
                    !pickers[field].value && deleteValue();
                  });
                  return;
                }
                date = moment.tz(date, TIMEZONE__LA).toDate();
                if (isFullday) {
                  date.setSeconds(
                    FULLDAY_SECOND_REFERENCE,
                    FULLDAY_MILL_SECOND_REFERENCE
                  );
                }
                else {
                  date.setSeconds(0);
                }
                if (!!date && !String(date).match(/invalid/i)) { updateValue(date); }
              }}
              className='card-picker-date-tf'
              style={{ width: inputWidth }}
              InputProps={{
                className: 'm-0 p-0',
                width: inputWidth,
                endAdornment
              }}
            />
            {renderedRemoveIcon}
          </>
        ) : (
          value ? (
            <>
              <TextField
                name={`${stage}${Core.getKey()}`}
                type={DATE_TYPE__DATE}
                variant='standard'
                size='small'
                ref={self => (pickers[field] = self)}
                value={moment(value).format(dateISOFormat)}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value) {
                    let date = join([
                      moment(event.target.value).format(DATE_FORMAT__DATE__ISO), 'T',
                      moment().tz(TIMEZONE__LA).format(DATE_FORMAT__TIME__ISO)
                    ], '');
                    updateValue(date);
                  }
                  else {
                    deleteValue();
                  }
                }}
                title={label || streakField}
                placeholder={mdash}
                disabled={Core.isRecruiter()}
                className='card-picker-date-tf'
                style={{ width: inputWidth }}
                InputProps={{
                  className: 'm-0 p-0',
                  width: inputWidth,
                  endAdornment
                }}
              />
              {renderedRemoveIcon}
            </>
          ) : (
            <Button
              variant='text'
              size='small'
              className='p-0 px-4 flex-1'
              style={{ height: '1.2rem' }}
              onClick={(event) => {
                const date = getISOString();
                const update = {};
                update[field] = date;
                this.parent.setState(update, then => {
                  updateValue(date);
                });
              }}
              disabled={Core.isRecruiter()}
            >
              {mdash}
            </Button>
          )
        )
        }
      </Box>
    );
  }
}

export default CustomPicker;
