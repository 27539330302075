import {
  IconButton as MuiIconButton
} from '@mui/material';
import {
  hasNoWidth,
  joinClassName,
  mapWrapper,
  THEME__COLOR__DEFAULT,
  THEME__COLOR__PRIMARY,
  THEME__COLOR__SECONDARY,
  THEME__SIZE__MEDIUM,
  THEME__SIZE__SMALL
} from '../Libraries/Theme.lib';
import Icon from './Icon';
import Wrapper from './Wrapper';

/**
 * @see https://mui.com/material-ui/api/icon-button/
 */
export default function IconButton({
  component = MuiIconButton,
  // ---- //
  icon = null,
  iconStyle = {},
  iconClassName = '',
  // ---- //
  children = icon,
  color = THEME__COLOR__DEFAULT,
  size = THEME__SIZE__MEDIUM,
  className = '',
  style = iconStyle,
  // ---- //
  acl = true,
  debug = false,
  primary = false,
  secondary = false,
  small = false,
  // ---- //
  wrapperProps = {},
  iconProps = {},
  ...props
}) {
  if (!acl) { return null; }
  if (primary) { color = THEME__COLOR__PRIMARY; }
  else if (secondary) { color = THEME__COLOR__SECONDARY; }
  if (small) { size = THEME__SIZE__SMALL; }
  const _className = [
    hasNoWidth({ className, style }) && 'w-auto',
    className
  ]
  if (icon) {
    mapWrapper({
      role: 'Icon',
      props: iconProps,
      assign: {
        icon,
        color,
        size,
        className: joinClassName([
          iconProps.className,
          iconClassName
        ])
      },
      flags: { primary, small }
    });
    children = <Icon {...iconProps} />;
  }
  mapWrapper({
    role: 'IconButton',
    props: wrapperProps,
    assign: {
      ...props,
      component,
      children,
      color,
      size,
      className: joinClassName(_className),
      style,
    },
    flags: { debug, primary, small }
  });
  return (
    <Wrapper {...wrapperProps} />
  );
}
