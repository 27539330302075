import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import Loader from '../../Shared/Loader';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import Box, {
  RefBox
} from './Box';
import Button from './Button';
import Typography from './Typography';

export default function Stepper({
  acl = true,
  toolBar = null,
  headers = [],
  views = [],
  step: propsStep = 1,
  onChange = (step) => null,
  footerBar = null,
  ...props
}) {
  const defaultState = { step: propsStep };
  const [{ 
    step = 1
  }, updateState] = useStateObserved({
    state: defaultState,
    observed: defaultState,
    onChange: stepperScrollToTop,
  });
  const _onChange = (step) => async (event) => {
    await updateState({ step });
    onChange(step);
    stepperScrollToTop();
  }
  if (!acl) { return null; }
  Stepper.next = async () => _onChange(step <= views.length ? step + 1 : views.length)();
  Stepper.previous = async () => _onChange(step > 0 ? step - 1 : 0)();
  return (
    <Box column h100 w100
      role='Stepper'
    >

      <Box row w100
        role='StepperHeader'
        className='flex-align-left-top border-bottom'
      >

        <Box row wAuto
          role='StepperControl'
          className="stepper-control mt-1 ml-1 mr-auto"
        >

          <Box className="conector" style={{ width: 'calc(100% - 96px)' }} />

          <Box row wAuto>
            {
              headers.map(
                (header, index) => {
                  const _currentStep = +index + 1;
                  const _className = [
                    'flex-column w-auto mr-2',
                    (_currentStep === step) && 'active',
                    (_currentStep < step) ? 'step before' : 'step after'
                  ];
                  return (
                    <Button flat key={`stepper__step_${_currentStep}`}
                      className={joinClassName(_className)}
                      onClick={_onChange(_currentStep)}
                    >
                      <Typography className="icon">{_currentStep}</Typography>
                      <Typography className="title">{header}</Typography>
                    </Button>
                  );
                }
              )
            }
          </Box>

        </Box>

        <Box row wAuto role='StepperToolBar' alignRight className='p-1'>
          {toolBar}
        </Box>

      </Box>

      <Box column h100 w100 scrollY role='StepperContent'>
        <RefBox id='StepperContentTop' />
        {views[step - 1] || <Loader className='m-2' />}
      </Box>

      <Box row w100 role='StepperFooter' className='bg-white p-1 border-top'>
        {footerBar}
      </Box>

    </Box>
  );
}

/**
 * Scrolls the element with the ID 'StepperContentTop' into view smoothly.
 * 
 * This function locates the HTML element with the ID 'StepperContentTop' and, if it exists, 
 * scrolls it into view using a smooth scrolling animation.
 * 
 * @returns {void}
 */
export function stepperScrollToTop() {
  const stepperContentTop = document.getElementById('StepperContentTop');
  console.debug('STEPPER__SCROLL_TO_TOP', stepperContentTop);
  if (stepperContentTop) {
    stepperContentTop.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
