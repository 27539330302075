import React from 'react';
import Core from '../../lib/Core';
import Definition from '../../lib/Definition';
import {
  Obj
} from '../../lib/Object.lib';
import {
  Str
} from '../../lib/String.lib';
import copyHtml from '../../lib/tools/copyHtml';
import StickyNote from '../Layout/Wrappers/StickyNote';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Chip from '../Layout/Wrappers/Chip';
import IconButton from '../Layout/Wrappers/IconButton';
import {
  CANDIDATE_UI
} from './CandidateUI.dic';
import CandidateNotes, {
  CANDIDATE_NOTES__DICTIONARY_KEY
} from './Forms/CandidateNotes';
import Box from '../Layout/Wrappers/Box';
import { Label } from '../Layout/Wrappers/Typography';

export default function CandidateSummaryStickyNote(props) {
  let { candidate, reference } = props;
  let { positiveSignals = [], negativeSignals = [] } = candidate;
  const mapCheck = t => { t.checked = true; return t; }
  let positiveSignalsTags = Definition.getTags({ categoryKey: 'positiveSignals', tagIds: positiveSignals }).map(mapCheck);
  let negativeSignalsTags = Definition.getTags({ categoryKey: 'negativeSignals', tagIds: negativeSignals }).map(mapCheck);
  return (
    <StickyNote
      title='Candidate summary'
      styleDialog={{ maxWidth: sizes.m6 }}
      show={!!candidate.firstName}
      reference={reference}
      className='mr-1'
      minimized={Core.isAdmin()}
    >
      <Fieldset
        title={(
          <Box row w100>
            <Label>
              {CANDIDATE_UI.CandidateNotes.title}
            </Label>
            <IconButton
              onClick={(event) => {
                copyHtml(Str(Obj(Core.getKeyValue(CANDIDATE_NOTES__DICTIONARY_KEY))[candidate.id]))
                  .then(() => Core.showSuccess('Copied to the clipboard'));
              }}
              color='secondary'
            >
              <i className='material-icons c-inherit'>content_copy</i>
            </IconButton>
          </Box>
        )}
        subtitle={
          <>
            This summary is based on your inputs and will be included in the candidate's application to the employer. Please review and make any necessary changes before submitting the application.
          </>
        }
      >
        <CandidateNotes
          candidate={candidate}
          refresh={Date.now()}
        />
      </Fieldset>
      {Core.isAdmin() && (
        <>
          {!!positiveSignalsTags.length && (
            <Fieldset
              title='Positive Signals'
              startDivider
            >
              {positiveSignalsTags.map(signalTag => (
                <SignalChip tag={signalTag} signalsKey='positive' key={`candidate_edit_app_bar_positive_signal_${signalTag.id}`} />
              ))}
            </Fieldset>
          )}
          {!!negativeSignalsTags.length && (
            <Fieldset
              title='Negative Signals'
              startDivider
            >
              {negativeSignalsTags.map(signalTag => (
                <SignalChip tag={signalTag} signalsKey='negative' key={`candidate_edit_app_bar_negative_signal_${signalTag.id}`} />
              ))}
            </Fieldset>
          )}
        </>
      )}
    </StickyNote>
  );
}

function SignalChip({ tag, signalsKey, onChange }) {
  let bgColorClass = (signalsKey === 'positive') ? 'bg-green-lighter c-black-medium' : (signalsKey === 'negative') && 'bg-red-lighter c-black-medium';
  return (
    <Chip
      label={tag.label}
      size='small'
      className={`mr-1 mt-1 ${!!tag.checked ? bgColorClass : 'bg-unset dashed c-gray-common'}`}
    />
  );
}
