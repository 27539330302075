import { Button } from '@mui/material';
import { compile } from 'handlebars';
import dig from "object-dig";
import { useState } from "react";
import { Arr, join } from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Definition, {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__LEVER_API_ID,
  DEFINITION_CATEGORY__ATS_TYPES,
  EMPLOYER_SUBMISSION_METHOD__ATS
} from "../../../lib/Definition";
import { tools } from "../../../lib/GenericTools.lib";
import { Obj } from '../../../lib/Object.lib';
import { COLLECTION__SOVREN_DATA, readLoopbackRecord } from '../../../lib/services/BE/loopback.api';
import downloadFile from '../../../lib/tools/downloadFile';
import { ErrorMessage, WarningChipMessage } from '../../Layout/Wrappers/Message';
import { CANDIDATE_UI } from '../CandidateUI.dic';

/* METHODS ==================================== */

/**
 *
 * @param {*} props
 * @returns
 */
function AtsSubmitButton(props) {

  const [sendingATS, setSendingATS] = useState(false);
  const [isSubmitToAtsFailed, setSubmitToAtsFailed] = useState(false);
  const [sovrenRecord, setSovrenRecord] = useState();

  const {
    atsTypeId,
    employerName,
    submissionMethods,
    manualSubmissionUrl,
    onClick: onClickCallback = async () => { },
    disabled = false,
    candidate,
    copyAts = (prepend = '') => { }
  } = props;

  const is10x10Employer = !!employerName.match(
    /10\s*(?:x|by)\s*10/i
  );

  const isAtsSubmitDisabled = (
    sendingATS || (
      Core.isOnDev() && !is10x10Employer
    )
  );

  const isAtsManuallyAvailable = (
    submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__ATS) &&
    (
      ![
        ATS_TYPE__GH_HARVEST_ID,
        ATS_TYPE__GH_INGESTION_ID,
        ATS_TYPE__LEVER_API_ID
      ].includes(atsTypeId) ||
      isSubmitToAtsFailed
    )
  );

  const isAtsLeverApiSelected = (
    atsTypeId === ATS_TYPE__LEVER_API_ID
  );

  const isAtsGhHarvestSelected = (
    atsTypeId === ATS_TYPE__GH_HARVEST_ID
  );

  const isAtsGhIngestionSelected = (
    atsTypeId === ATS_TYPE__GH_INGESTION_ID
  );

  const isSubmitToLeverAvailable = (
    Core.isAdminOrCoordinator() &&
    !isAtsManuallyAvailable &&
    isAtsLeverApiSelected
  );

  const isSubmitToGhHarvestAvailable = (
    Core.isAdminOrCoordinator() &&
    !isAtsManuallyAvailable &&
    isAtsGhHarvestSelected
  );

  const isSubmitToGhIngestionAvailable = (
    Core.isAdminOrCoordinator() &&
    !isAtsManuallyAvailable &&
    isAtsGhIngestionSelected
  );

  const apiName = (
    isSubmitToGhHarvestAvailable
      ? 'harvest'
      : isSubmitToGhIngestionAvailable
        ? 'ingestion'
        : Definition.getLabel(DEFINITION_CATEGORY__ATS_TYPES, atsTypeId) || ''
  );

  function onClickSendATS(apiName) {
    return async event => {
      setSendingATS(true);
      setSubmitToAtsFailed(false);
      const result = await onClickCallback(apiName);
      if (!result || !!result?.errors) {
        setSubmitToAtsFailed(true);
      }
      setSendingATS(false);
    }
  }

  (async () => {
    if (!AtsSubmitButton.busy && !Obj(sovrenRecord).id && isAtsManuallyAvailable && candidate.sovrenDataId) {
      AtsSubmitButton.busy = true;
      setSovrenRecord(
        await readLoopbackRecord({
          collection: COLLECTION__SOVREN_DATA,
          where: { id: candidate.sovrenDataId },
          fields: ['id', 'contactInfo'],
          limit: 1
        })
      );
      AtsSubmitButton.busy = false;
    }
  })();

  return (
    <div className="mt-1">
      <div className='nowrap'>
        {!!(isSubmitToGhHarvestAvailable || isSubmitToGhIngestionAvailable || isSubmitToLeverAvailable) && (
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={onClickSendATS(apiName)}
            disabled={disabled || isAtsSubmitDisabled}
            className='nowrap mr-1'
          >
            {(sendingATS
              ? "SENDING..."
              : `Submit to ATS(${apiName})`)}
          </Button>
        )}
        {isAtsManuallyAvailable && (
          <Button
            className={"hint--top hint--rounded nowrap mr-1"}
            variant='contained'
            color='secondary'
            size='small'
            aria-label={
              !manualSubmissionUrl ? 'Missing manual submission url' : `Take to the ATS URL`
            }
            onClick={async event => {
              const doManualOperation = (prepend) => {
                if (!!Arr(candidate.resumes).length) {
                  downloadFile({
                    url: candidate.resumes[0].url,
                    mimeType: "application/pdf",
                    onError: (error) => {
                      this.setState({
                        snackbar: error,
                      });
                    },
                  });
                }
                copyAts(prepend);
                window.open(
                  tools.withHttp(manualSubmissionUrl),
                  "_blank"
                );
              }
              if (Obj(sovrenRecord).id) {
                const CANDIDATE_NAME = join([candidate.firstName, candidate.lastName], ' ');
                const PARSED_NAME = join([
                  dig(sovrenRecord, 'contactInfo', 'PersonName', 'GivenName'),
                  dig(sovrenRecord, 'contactInfo', 'PersonName', 'FamilyName'),
                ], ' ');
                if (PARSED_NAME && (PARSED_NAME.toLowerCase() !== CANDIDATE_NAME.toLowerCase())) {
                  Core.showWarning(
                    compile(CANDIDATE_UI.firstName.resumeSubmissionDiffParsedNameErrorMessage)({
                      CANDIDATE_NAME,
                      PARSED_NAME
                    }),
                    () => doManualOperation(CANDIDATE_NAME)
                  )
                }
                else { doManualOperation(); }
              }
              else { doManualOperation(); }
            }}
            disabled={disabled || !manualSubmissionUrl}
          >
            Manual Submit to ATS ({apiName})
          </Button>
        )}
      </div>
      <div className="d-flex flex-column px-05">
        <WarningChipMessage show={isAtsSubmitDisabled && !sendingATS} className='mt-05'>
          Submit to ATS is disabled on development environments for non 10x10 employers.
        </WarningChipMessage>
        <WarningChipMessage show={isAtsManuallyAvailable && !manualSubmissionUrl} className='mt-05'>
          Manual submission URL is missing!
        </WarningChipMessage>
        <ErrorMessage show={isSubmitToAtsFailed} className='mt-05'>
          <small>
            Submit to ATS failed!
          </small><br />
          <small>
            Please review the ATS settings on Employer/Process.
          </small>
        </ErrorMessage>
      </div>
    </div>
  );
}

/* DICTIONARIES =============================== */



/* EXPORTS ==================================== */

export {
  AtsSubmitButton, AtsSubmitButton as default
};

/* ============================================ */