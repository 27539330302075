import {
  ENGAGEMENT__INFO_STATUSES,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_ONSITE,
  STAGE_PENDING,
  STAGE_SCREEN
} from '../../../dictionaries/Engagement.dic';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import {
  MATERIAL_ICON_NAME__CLOSE
} from '../../../lib/constants/MaterialIconName.dic';
import {
  isEnded,
  isOnHold,
  isWaiting10x10,
  isWaitingCandidate,
  isWaitingDate,
  isWaitingEmployer,
} from '../../../lib/Engagement';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import { hoverDisplay } from '../../../lib/models/engagement';
import {
  REACT_TABLE__COLUMN_SM
} from '../../Home/useEnhancedReactTable.hook';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Dialog from '../../Layout/Wrappers/Dialog';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  PLACEMENT__LEFT_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';

export default function StatusDropdown({
  statuses = [],
  engagement = {},
  onChange = Fun
}) {
  const _defaultState = {
    statusNote: engagement.statusNote
  }
  const [{
    open = false,
    statusNote = '',
  }, _updateState] = useState(_defaultState);

  const _getStatusNote = async (status) => {
    let _statusNote = statusNote;

    // set empty status note if...
    if (
      // status is not valid
      NOT(
        ENGAGEMENT__INFO_STATUSES.includes(
          status ||
          engagement.status
        )
      ) ||
      // or if status changed and status note was not updated
      (
        status &&
        (status !== engagement.status) &&
        (_statusNote === engagement.statusNote)
      )
    ) {
      _statusNote = '';
    }

    await _updateState({ statusNote: _statusNote });
    return _statusNote;
  }

  const _onOpenChange = (open) => async (event) => await _updateState({
    open
  });

  const _onChangeStatus = (status) => async (event) => {
    await _updateState({ open: false });
    const statusNote = await _getStatusNote(status);
    onChange({
      status,
      statusNote
    });
  }

  const _onChangeStatusNote = async (event) => await _updateState({
    statusNote: event.target.value
  });

  const _groups = [
    {
      title: 'Employer',
      filters: [
        isWaitingEmployer
      ],
      list: []
    },
    {
      title: 'Candidate',
      filters: [
        isWaitingCandidate
      ],
      list: []
    },
    {
      title: (
        [
          STAGE_PENDING,
          STAGE_SCREEN,
          STAGE_ONSITE,
          STAGE_GUARANTEE,
          STAGE_HIRE
        ].includes(engagement.stage)
          ? 'Date | 10x10'
          : '10x10'
      ),
      filters: [
        isWaitingDate,
        isWaiting10x10
      ],
      sorter: (a, b) => +isWaitingDate(b) - +isWaitingDate(a),
      list: []
    },
    {
      title: 'Hold',
      filters: [
        isOnHold
      ],
      list: []
    },
    {
      title: 'End',
      filters: [
        isEnded
      ],
      list: []
    }
  ];
  sanitizeArr(statuses).forEach((_status) => {
    const _group = _groups.find((_group) => {
      return _group.filters.find((_filter) => _filter(_status));
    });
    if (_group) {
      _group.list.push(_status);
    }
  });

  return (
    <>
      <Button outlined inherit small
        title={hoverDisplay(engagement, true)}
        placement={PLACEMENT__LEFT_START}
        className='f-sm tt-unset py-0 px-1 overflow-hidden nowrap mb-05'
        style={{ height: 24, width: REACT_TABLE__COLUMN_SM, maxWidth: '100%' }}
        onClick={_onOpenChange(true)}
      >
        {engagement.status}
      </Button>
      <Dialog
        onClose={_onChangeStatus()}
        title={
          <Box row noWrap
            className='mr-auto'
          >
            <Box row noWrap className='flex-align-left-bottom mr-auto truncate'>
              <Typography strong className='f-xl'>{engagement._name}</Typography>
              <Typography sub cyanDark className='flex-align-left-bottom'>
                &nbsp;{MDASH}&nbsp;
                {engagement.stage}
                &nbsp;{MDASH}&nbsp;
                {engagement.status}
              </Typography>
            </Box>
            <IconButton onClick={_onOpenChange(false)} className='icon24'>
              <i className='material-icons'>{MATERIAL_ICON_NAME__CLOSE}</i>
            </IconButton>
          </Box>
        }
        content={
          <>
            <Fieldset
              title='Type a note'
              nextToTitle={
                <Typography sub>
                  (just for w-info/details)
                </Typography>
              }
              dot={
                NOT(ENGAGEMENT__INFO_STATUSES.includes(engagement.status))
                  ? 'red'
                  : 'black-medium'
              }
            >
              <TextField disabledBlurExport autoFocus small multiline
                value={statusNote}
                onChange={_onChangeStatusNote}
              />
            </Fieldset>

            <Fieldset
              title='Select a new status'
            >
              <Box row w100>
                {_groups.map((_group, index) => {
                  return (
                    <Box column
                      key={`status-group-${_group.title}-${index}`}
                      className='bg-white rounded m-1 mt-0'
                      style={{ width: 300 }}
                    >
                      <strong className='p-2'>
                        {_group.title}
                      </strong>
                      <Divider />
                      <div className='scroll-y' style={{ minHeight: 250 }}>
                        {_group.list.sort(_group.sorter).map((status) => {
                          return (
                            <Menu.Item
                              key={`status-group-${_group}-${index}-${status}`}
                              onClick={_onChangeStatus(status)}
                            >
                              {status}
                            </Menu.Item>
                          );
                        })}
                      </div>
                    </Box>
                  );
                })}
              </Box>
            </Fieldset>
          </>
        }
        paperStyle={{
          width: 1600,
          maxWidth: 'calc(100vw - 2rem)'
        }}
        open={open}
      />
    </>
  );
}
