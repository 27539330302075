import moment from 'moment';
import AppUI from '../../dictionaries/AppUI.dic';
import {
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_ONSITE, STATUS_W_SCREEN
} from '../../dictionaries/Engagement.dic';
import Core from '../Core';
import getInterviewLabel from '../Engagement/getInterviewLabel.fun';
import globalErrorHandler from '../Error/globalErrorHandler.fun';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../services/BE/loopback.api';
import {
  openMessageEmailPreview
} from '../services/Email/Email.lib';
import appendStreakNote from '../Streak/appendStreakNote.fun';
import {
  compileText,
  Str
} from '../String.lib';

export default async function openCandidateTipEmailPreview({
  engagement = {}
}) {
  try {
    Object.assign(
      engagement,
      await readLoopbackRecord({
        collection: COLLECTION__ENGAGEMENTS,
        where: { id: engagement.id },
        fields: [
          'id',
          'stage',
          'status',
          'boxKey',
          'screen1',
          'screen2',
          'screen3',
          'onsite1',
          'onsite2',
        ],
        limit: 1
      })
    );
    if (
      [
        STAGE_REVIEW,
        STAGE_SCREEN,
        STAGE_ONSITE
      ].includes(engagement.stage) &&
      [
        STATUS_W_CANDIDATE_SUBMIT_FORM,
        STATUS_W_SCREEN,
        STATUS_W_ONSITE
      ].includes(engagement.status)
    ) {
      const interviewLabel = Str(getInterviewLabel({ engagement }));
      const type = {
        [STATUS_W_CANDIDATE_SUBMIT_FORM]: 'Homework',
        [STATUS_W_SCREEN]: interviewLabel,
        [STATUS_W_ONSITE]: interviewLabel,
      }[engagement.status];
      const context = {
        DATE: moment().format('MM/DD'),
        TYPE: type,
        TYPE__LOWERCASE: Str(type).toLowerCase()
      };
      Core.hideMessage();
      openMessageEmailPreview({
        title: compileText('Send {{TYPE__LOWERCASE}} tip', context),
        ...engagement,
        recruiterId: engagement.candidate.accountId,
        employerId: engagement.job.employerId,
        engagementId: engagement.id,
        toCandidate: true,
        filterSnippets: 'tip',
        body: `Hi ${engagement.candidate.nickName || engagement.candidate.firstName}<br/><br/>`,
        onSuccess: async ({ response }) => {
          Core.showSuccess(AppUI.success.email);
          if (
            [
              STATUS_W_CANDIDATE_SUBMIT_FORM,
              STATUS_W_SCREEN,
              STATUS_W_ONSITE
            ].includes(engagement.status)
          ) {
            await appendStreakNote({
              boxKey: engagement.boxKey,
              note: compileText('{{DATE}} - {{TYPE}} tip sent', context)
            });
          }
        }
      });
    }
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
