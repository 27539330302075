import { CSS_RED_RGB } from '../Constants';
import { joinHTML } from '../HTML.lib';

/**
 * @param {string} CANDIDATE_NAME  [plaintext]
 * @param {string} EMPLOYER_NAME  [plaintext]
 * @param {string} JOB_TITLE  [plaintext][IF:" ({{JOB_TITLE}})"]
 * @param {boolean} IS_ON_CONF_OR_SUBMISSION  regarding to engagement.stage
 * @param {boolean} IS_ON_REVIEW_OR_INTERVIEW  regarding to engagement.stage
 * @param {string} STAGE  [plaintext] engagement.stage
 * @param {string} USER  [plaintext] user.fullname
 */
export const TEMPLATE__REJECTION_EMAIL__SUBJECT = joinHTML(`
  {{#if IS_ON_CONF_OR_SUBMISSION}}
    ( Update ) - {{CANDIDATE_NAME}}, for {{EMPLOYER_NAME}}{{JOB_TITLE}} - Doesn't meet current requirements for submission
  {{else if IS_ON_REVIEW_OR_INTERVIEW}}
    ( Update ) - {{CANDIDATE_NAME}}, has been rejected by {{EMPLOYER_NAME}}{{JOB_TITLE}}
  {{else}}
    ( Update ) - {{CANDIDATE_NAME}}, for {{EMPLOYER_NAME}}{{JOB_TITLE}}, has been rejected by {{USER}} at {{STAGE}} stage
  {{/if}}
`);

/**
 * @param {string} STAGE plaintext – Engagement.stage
 * @param {string} CANDIDATE_NAME plaintext – Candidate.firstName + Candidate.lastName
 * @param {string} EMPLOYER_NAME plaintext – Employer.name
 * @param {string} JOB_TITLE plaintext – Job.jobTitle
 * @param {boolean} IS_ON_CONF_OR_SUBMISSION regarding to Engagement.stage
 * @param {boolean} IS_ON_REVIEW_OR_INTERVIEW regarding to Engagement.stage
 * @param {string} INSIDER_SCOOP_URL plaintext – URL of job > view > insider scoop section
 * @param {string} FEEDBACK_URL plaintext – url of job > view > feedback section
 * @param {string} FEEDBACK_TITLE plaintext
 */
export const TEMPLATE__REJECTION_EMAIL__BODY = joinHTML(`
  Hi there,<br/>
  {{#if IS_ON_CONF_OR_SUBMISSION}}
    Thanks for your submission. The profile doesn't meet current requirements and will not be submitted to {{EMPLOYER_NAME}}{{JOB_TITLE}}.
    <br/><br/>
    {{#if ADDITIONAL_INFO}}
      <div>
        <strong>Note</strong> - <span style="color: ${CSS_RED_RGB}">{{ADDITIONAL_INFO}}</span>
      </div>
      <br/>
    {{/if}}
    {{#if RECRUITER__BAD_MATCH}}
      <div>
        Please refer to the auto-rejection reason from our system.&nbsp;
        <span style="color: ${CSS_RED_RGB}">Decisions are made by humans</span>, up to 3 people when uncertain, not robots.
      </div>
      <br/>
      {{{RECRUITER__BAD_MATCH}}}
    {{/if}}
    <div>
      For refining your search, please see aggregated feedback from all candidates submitted to get broader context (not specific to your candidates) in the <a href="{{INSIDER_SCOOP_URL}}" target="_blank" rel="noopener noreferrer">Job's Insider Scoop</a> section.
    </div>
  {{else}}
    <div>
      {{CANDIDATE_NAME}}, who applied to {{EMPLOYER_NAME}}{{JOB_TITLE}} has been rejected at {{STAGE}} stage.
    </div>
    {{#if IS_ON_REVIEW_OR_INTERVIEW}}
      <div>
        Click <a href="{{FEEDBACK_URL}}" target="_blank" rel="noopener noreferrer">here</a> to see all the known rejection reasons associated with this role under "{{FEEDBACK_TITLE}}".
      </div>
    {{/if}}
  {{/if}}
`);
