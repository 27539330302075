import copy from "copy-to-clipboard";
import _ from 'lodash';
import moment from "moment";
import * as R from 'ramda';
import {
  Component,
  Fragment
} from "react";
import {
  EMPLOYMENT_HISTORIES__PRESENT
} from '../../components/Candidates/CandidateHistories.lib';
import {
  filterInvalidCandidateHistorySignals
} from '../../components/Candidates/CandidateSignalTags.lib';
import {
  CandidateEngagementsDetails
} from '../../components/Candidates/Card/CandidateEngagementsDetails';
import {
  SIGNALS_TYPE__COMPANY,
  SIGNALS_TYPE__SCHOOL
} from '../../components/Candidates/OrganizationSignals.lib';
import {
  CANDIDATE_SUMMARY__INCLUDE_FLAGS
} from '../../components/Candidates/SubmissionNotes.lib';
import {
  DATE_FORMAT
} from "../../components/Home/FilterDateRangeColumn";
import Button from '../../components/Layout/Wrappers/Button';
import Checkbox from '../../components/Layout/Wrappers/Checkbox';
import IconButton from '../../components/Layout/Wrappers/IconButton';
import Menu from '../../components/Layout/Wrappers/Menu';
import {
  PRESCREEN_QUESTIONS__VERSION,
  mapQuestionAnswer
} from '../../components/PrescreenQuestions/PrescreenQuestions.lib';
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  Arr,
  join
} from '../Array.lib';
import Candidate from "../Candidate";
import formatCandidateJobFilterKey from '../Candidate/formatCandidateJobFilterKey.fun';
import {
  MDASH,
  OPTION_NO,
  OPTION_YES,
  TIMEZONE__LA
} from "../Constants";
import Core from "../Core";
import {
  SALARY_CURRENCY__USD
} from '../Currency.lib';
import {
  DATE_FORMAT__DATE__US_LONG
} from '../Date.lib';
import Definition, {
  DEFINITION_CATEGORY__DIVERSITY_CATEGORIES
} from "../Definition";
import Engagement from "../Engagement";
import FilterControlLib from "../FilterControl";
import {
  NOT,
  getUnitTestingItemId,
  newModel,
  validateEmail
} from "../GenericTools.lib";
import Job from "../Job";
import {
  Obj
} from '../Object.lib';
import {
  evalDefaultDegree
} from '../School';
import {
  openMessageEmailPreview
} from "../services/Email/Email.lib";
import {
  toLinkedinDateString
} from '../services/LinkedIn/LinkedIn.lib';
import cleanHtml from "../tools/cleanHtml";
import copyHtml from "../tools/copyHtml";
import downloadFile from "../tools/downloadFile";
import formatMoney from "../tools/formatMoney";
import {
  setLocation
} from '../URL.lib';
import {
  mapAccount
} from "./account";
import {
  mapEngagements
} from "./engagement";
import {
  mapStarred
} from "./mapStarred.tool";

export const MODEL_NAME_CANDIDATE = 'Candidate';

const DEBUG__CANDIDATE_CREATE = Core.debug('CANDIDATE_CREATE');

let DEBUG_CANDIDATE_MAP = false;

const mdash = "—";

const modelMatchExclusion = {
  excludeUntil: '', // <iso-date-string>
  reasons: [],      // <int: new `Definition` tag category (matchExclusionReason) with values: `New Job`, `Stay at Job`, and `Pause`, optional>
  note: '',         // <free text, optional>
  updatedBy: '',    // <accountId>
  updatedAt: ''     // <iso-date-string>
}

const model = {

  // REQUIRED ----

  /** @property {string} firstName */
  firstName: '',

  /** @property {string} lastName */
  lastName: '',

  /** @property {string} email */
  email: '',

  /** @property {number} yearsOfExperience  [default:""] AC doesn't want to display zero as default value. */
  yearsOfExperience: '',

  ...(
    DEBUG__CANDIDATE_CREATE
      ? {
        firstName: `__CANDIDATE__`,
        lastName: `${Date.now()}`,
        email: `recruiter+${Date.now()}@10by10.io`,
        yearsOfExperience: 1,
      }
      : {}
  ),

  // ---- REQUIRED

  phone: "",

  // candidate recruiter contacts
  emailsList: [],

  /** basics step 1 important */
  gitHubURL: "",
  linkedInURL: "",
  stackoverflowUrl: "",
  /** basics step 1 */
  nickName: "",
  country: "",

  // {number}:{tagId} -old field. -diversity tag management definition.
  diversity: 0,

  // {number[]}:{tagId[]} - current field. - diversityCategories tag management definition. - story_5484.
  diversityCategories: [],

  currentlyEmployed: 0,
  currentEmployer: "",
  currentTitle: "",
  otherLinks: "", // URLs separated by linebreaks
  resumes: "", // URLs
  jobHopReason4Emp: "",
  jobHopReason4Us: "",
  jobHopDisputeParser: false,
  /** match step 2 */
  undergraduateDegree: "",
  undergraduateSchool: "",
  undergraduateMajor: "",
  level: 0,
  roles: [],
  technicalSkills: [],
  tagLine: "",
  recruiterRating: 0, // "Can the candidate explain skills/experience clearly?"
  platformRating: 0,
  platformRatingNotes: '',
  visa: 0,
  parserTotalMonthsOfExp: null,
  adjustedTotalYearsOfExp: null,
  minCompanySize: "", // NUMBER, default should not be zero.
  maxCompanySize: "", // NUMBER, default should not be zero.

  minimumSalary: 0,

  // {number:tagId} - Category: salaryConfidence; [ 2023-04-27 ][ MS: story_8909 ]
  salaryConfidence: null,

  // {string} [2023-08-10][story_9467]
  salaryCurrency: SALARY_CURRENCY__USD,

  desiredSalary: 0,
  salarySharableFlag: false,
  salaryNote: "",
  industry: [],
  employmentHistories: [],
  educationHistories: [],
  technologyDomain: [],
  experienceNotes: "",
  degreeNotes: "",
  motivationNotes: "",
  positiveSignals: [], // map from automatic and manual signals
  positiveSignalsManual: [],
  negativeSignals: [], // map from automatic and manual signals
  negativeSignalsManual: [],
  jobsLinkedIn: [],
  schoolsLinkedIn: [],
  currentEmployerFrom: "",
  currentEmployerTo: "",
  tags: {},
  draftEmailSentAt: "",
  _status: '',

  // {string[]} - array of jobIds
  jobsPermitted: [],

  /** * /
    New field 2023-01-18 | old: permittedJobsAnswers
    {object} - hash list of jobIds contained an nested a custom nested structures
    // questionObject: {id,question,instruction,askedBy,answerMandatory,questionApplyTo,answerApplyTo,order,author,autoIncludeInWriteup}
    // answerObject: {questionId,question,instruction,answer,askedBy,order,answerMandatory,autoIncludeInWriteup}
    example:
    { 
      globalAnswers: {[questionId]:answerObject},
      employerAnswers: {[employerId]:{answers:{[questionId]:answerObject}}}, 
      jobAnswers: {[jobId]:{answers:{[questionId]:answerObject}}},
    }
    see mapJobsPermittedAnswers
  /** */
  jobsPermittedAnswers: {},

  // Old field 2023-01-18 to deprecate | new: jobsPermittedAnswers
  // {object} - hash list of jobIds contained an array of answer objects
  // {[jobId]:[{ jobId, qId, question, ans }]}
  permittedJobsAnswers: {},

  graduationYear: "",
  jobsPitched: [],
  jobsDeclined: [],
  resumePdfUrl: "",
  resumeJsonUrl: "",
  resumeTxtUrl: "",
  htmlResumeUrl: "",
  resumeScrubbedUrl: "",
  isOwnedRightNow: undefined, //keep it undefined as I have if statements based upon it
  /** recruiter step 3 */
  accountId: "", // FK, recruiterId.
  relationship: 0,
  acceptOffer: 0,
  otherCompanyInterviews: "",
  bestTimeForCalls: "",
  candidatePreferIds: [], // [NUMBER]
  reasonsToLeaveLastJob: "",
  explainRedFlags: "",
  publicNotes: "",
  jobsHaveCandidatePermission: "",
  jobsWaitingCandidatePermission: "",
  jobsCandidateDeclined: "",
  duplicatedLevel: "",
  duplicatedFrom: "",
  averageMonthsPerEmployer: "",

  // {string} -HTML string for RTB
  submissionNotes: '',

  /** recruiter step 3 green block >>> */
  submissionNotesToEmployer: "",
  //candidateStateTagId: "", // FK
  privateNotes: "",
  /** <<< recruiter step 3 green block */
  holdDate: null,
  wakeUpDate: null,
  searchConfig: null,
  expirationDate: null, // "2018-01-19T15:43:52.163Z",
  closeDate: null, // "2017-12-18T20:51:55.157Z",
  introduced: null, // Date,
  createdAt: null, // MIXIN, "2017-12-15T18:48:33.331Z",
  updatedAt: null, // MIXIN, "2017-12-15T18:48:33.331Z"
  state: 5,
  desiredStage: [],
  /** misc */
  isDuplicate: false,
  /** disabled edition fields */
  jobTypeIds: [], // [NUMBER]
  putDownJobs: [],
  candidateSkills: [],
  techSkillsInNotes: [],
  techSkillsInResume: [],
  posSignalsInResume: [],

  /* epic-3038(new locations)-story-3330-M2 | 2021-07-01 Thu µ */
  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  workRemotly: 0,             // old field | remotelyWork(TagId)
  inOfficeRemoteFlags: [],    // new field | inOfficeRemote(TagId)

  /* epic-3038(new locations) 2021-06-11 Fri µ */
  desiredEmploymentTypes: [], // new field | desiredEmploymentType(TagId) - this is to match with Job.jobType

  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  workLocationIds: [],        // old field | locationCandidate(TagId)
  officeLocations: [],        // new field | location(TagId)

  /* epic-3038(new locations)-story-3459 | 2021-07-14 Wed µ */
  /* epic-3038(new locations)-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [],     // new field

  /* epic-3038(new locations)-story-3385 | 2021-07-15 Thu µ */
  locationDetails: "",        // old field(it was just defined on FE)

  // story-3869 | 2021-08-30 Mon µ
  matchExclusions: [
    modelMatchExclusion
  ], // modelMatchExclusions

  calendarBookingLink: '', // {string}

  employmentGapReason4Emp: '', // {string}
  employmentGapReason4Us: '', // {string}
  employmentGapDisputeParser: false, // {boolean}

  sovrenDataId: '', // {string} Foreign Key(FK) pointing to a SovrenData collection record

  // {string} -HTML string for RTB
  noteToAccountManager: '',

  // {number[]} -array of number ids, look for CANDIDATE_SUMMARY__INCLUDE_FLAGS
  submissionIncludeFlags: [
    CANDIDATE_SUMMARY__INCLUDE_FLAGS.minimumSalary,
    CANDIDATE_SUMMARY__INCLUDE_FLAGS.reasonsToLeaveLastJob,
    CANDIDATE_SUMMARY__INCLUDE_FLAGS.locationDetails

    // [2023-12-13][story_9765] "uncheck the checkbox by default for Candidate's calendar booking link"
    // CANDIDATE_SUMMARY__INCLUDE_FLAGS.calendarBookingLink,

  ]

};

const extended = {
  ___model___: MODEL_NAME_CANDIDATE,
  id: null,
  ...model,
  /** includes */
  recruiter: {},
  engagements: [],
  /** local use */
  engaged: [],
  potentialDuplicatedCandidateList: [],
  starred: false,
  /** mapping */
  _name: "",
  _platformRating: "",
  _visa: "",
  _years: "",
  _tags: "",
  _minimumSalary: "",
  _companiesString: "",
  _boxesCount: "",
  _boxesCountStr: "",
  _technicalSkills: "",
  _positiveSignals: "",
  _negativeSignals: "",
  _officeLocations: "",
  _workOrAdditionalLocations: "",
  _undergraduateDegree: "",
  _isDraft: false,  //keep it false as this is used by default for new record and have if checks
  ___keys___: []
};

const parser = {
  objective: "",
  summary: "",
  technology: "",
  experience: "",
  education: "",
  skills: "",
  languages: "",
  cources: "",
  projects: "",
  links: "",
  contacts: "",
  positions: "",
  profiles: "",
  awards: "",
  honors: "",
  additional: "",
  certification: "",
  interests: "",
  github: {
    name: "",
    location: "",
    email: "",
    link: "",
    joined: "",
    company: ""
  },
  linkedin: {
    summary: "",
    name: "",
    positions: [],
    languages: [],
    skills: [],
    educations: [],
    volunteering: [],
    volunteeringOpportunities: []
  },

  skype: "",
  name: "",
  email: "",
  phone: ""
};

export function mapMatchExclusion(candidate) {
  candidate._matchExclusionsReasons = candidate.matchExclusions
    .map(({ reasons }) => Definition.getLabels('matchExclusionReason', reasons).join(', '))
    .join(', ');
  candidate._matchExclusionsUntil = candidate.matchExclusions
    .map(({ excludeUntil }) => excludeUntil ? moment(excludeUntil).format(DATE_FORMAT) : excludeUntil)
    .filter(v => !!v)
    .join(', ');
}

export function mapMinimumSalary(candidate) {
  candidate._minimumSalary = formatMoney(candidate.minimumSalary, 0);
  candidate._minimumSalary =
    candidate._minimumSalary !== "0"
      ? `${formatMoney(candidate.minimumSalary, 0)} ${candidate.salaryCurrency}`
      : MDASH;
  return candidate._minimumSalary;
}

export function mapCandidateVisa(candidate) {
  Definition.set(candidate, "visaCandidate", "visa");
  return candidate._visa;
}

export function mapCandidateTechSkills(candidate) {
  Definition.map(candidate, "technicalSkills");
  return candidate._technicalSkills;
}

function mapOldIncludeSummaryFlag(candidate) {
  if (candidate.salarySharableFlag) {
    candidate.submissionIncludeFlags = [
      ...new Set([
        ...(candidate.submissionIncludeFlags || []),
        CANDIDATE_SUMMARY__INCLUDE_FLAGS.minimumSalary
      ])
    ].filter(v => !!v);
    candidate.salarySharableFlag = null;
  }
}

/**
 * 
 * @param {object} model - JSON based on BE Candidate model.
 * @returns {object} JSON based on FE Candidate BASIC model plus the ID.
 */
export function mapBasicCandidate(model) {
  model = Obj(model);
  const candidate = { id: null, ...getCandidateModel({ extended: false }) };
  Object.keys(candidate).forEach(
    key => !!model[key] && (candidate[key] = model[key])
  );
  return candidate;
}

const mapCandidate = (item) => {
  const candidate = getCandidateModel({ extended: true });
  const mdash = "—";
  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (candidate[key] = item[key])
    );

    candidate.id = item.id || item._id;
    candidate.submissionNotes = cleanHtml(candidate.submissionNotes);
    candidate.posSignalsInResume = item.posSignalsInResume;
    candidate.isOwnedRightNow = item.isOwnedRightNow;

    mapOldIncludeSummaryFlag(candidate);

    if (Core.isProduction() || Core.isStaging()) {
      (candidate.resumes || []).forEach(resume => {
        let existingPath = resume.url || "";
        let relativePath = existingPath.trim().match(/Import.+\w/g);
        if (!!relativePath && Array.isArray(relativePath)) {
          let newCompletePath = `${Core.getApi()}/${relativePath.pop()}`;
          resume.url = newCompletePath;
        }
      });
    }

    if ((!candidate.introduced || candidate.introduced === "") && candidate.draftEmailSentAt) {
      candidate._status = "InDraft";
      candidate._introducedStatus = "introducedInDraft";
    } else {
      candidate._status = "Active";
      candidate._introducedStatus = "introducedActive";
    }

    mapMatchExclusion(candidate);
    mapJobsPermittedAnswers(candidate);

    if (!!candidate.jobsLinkedIn.length) {
      candidate._companiesString = candidate.jobsLinkedIn.filter(el => !!el.rankingMeta).map(job => {
        let cName = job.employer;
        let acquiredBy = Object(job.rankingMeta).acquiredBy;
        let acquiredByLabel = !!acquiredBy ? `acquired by ${acquiredBy}` : '';
        let tags = '';
        let pSignals = job.positiveSignalsTags;

        if (Array.isArray(pSignals)) {
          tags = pSignals.map(id => Definition.getLabel('positiveSignals', id)).join(' - ');
        }

        let labels = [cName, acquiredByLabel, tags].filter(el => !!el).join(' - ');
        return `Worked at ${labels}`;
      }).join(',');
    }

    mapStarred({ model: candidate, starredList: (item.candidateStarreds || []) });

    // map recruiter used on common loopback endpoints | 2021-09-16 Thu µ
    candidate.recruiter = mapAccount(item.account);

    // map account to be used in v3 | 2021-09-16 Thu µ
    candidate.account = mapAccount(item.account);

    candidate.engagements = mapEngagements(item.engagements);

    /*
     * Remove the whitespace and http:// or https:// from url for comparison
     */
    candidate._linkedInURL = candidate.linkedInURL;
    try {
      candidate._linkedInURL = candidate.linkedInURL.trim().match(/linked.+\w/g).pop();
    } catch (e) {
    }

    /*
     *  Remove the whitespace, the trailing / and http:// and https:// from the url for comparison
     *  We can't use the same expression as we did for linkedin because there are 2 github
     *  URL styles github.com/<something> and <something>.github.io.
     *
     *  NOTE - do not combine multiple statements in one try/catch as if the first one fails
     *  the additional instructions are not executed.
     */
    candidate._gitHubURL = candidate.gitHubURL;

    try {
      candidate._gitHubURL = candidate.gitHubURL.trim().replace(/http+s?:\/\//g, "").replace(/\/*$/, "");
    } catch (e) {
    }

    candidate._isDraft = candidate.id && !candidate.introduced;
    /* mapping stuff */
    candidate._starred = candidate.starred ? "Starred: True" : "Starred: False";
    candidate._engagementsLength = Number(candidate.engagements.length);
    mapCandidateName(candidate, MDASH);
    candidate._recruiterName = candidate.recruiter._name;
    candidate._companyName = candidate.recruiter.companyName;
    candidate._name_rating = candidate._name;
    if (Core.isAdminOrCoordinator() && candidate.platformRating) {
      candidate._name_rating +=
        " (" +
        String(Definition.getLabel("platformRating", candidate.platformRating))
          .slice(0, 2)
          .trim() +
        ")";
    }

    let linkdInTechnicalChips = [];
    if (!!candidate.jobsLinkedIn.length) {
      linkdInTechnicalChips = candidate.jobsLinkedIn.filter(job => !!job.chips).map(job => job.chips).flat();
    }
    candidate._strongTechnicalSkills = Array.from(new Set([
      ...candidate.techSkillsInNotes,
      ...linkdInTechnicalChips
    ]));

    if (!candidate.yearsOfExperience || parseInt(candidate.yearsOfExperience) <= 0) {
      let currentYear = (new Date()).getFullYear();
      let mine = parseInt(candidate.graduationYear);
      candidate.yearsOfExperience = (currentYear - mine) || '';
    }

    candidate._yearsOfExperienceForCalc = (
      +candidate.yearsOfExperience || 0
    );
    candidate._yearsOfExperienceForCalc = (
      (candidate._yearsOfExperienceForCalc > -1)
        ? candidate._yearsOfExperienceForCalc
        : 0
    );

    candidate._years = (candidate.yearsOfExperience && candidate.yearsOfExperience > -1)
      ? `${candidate.yearsOfExperience} years`
      : mdash;
    mapMinimumSalary(candidate);
    candidate._boxesCount = candidate.engagements.filter(
      eng => !!eng.boxKey
    ).length;
    candidate._boxesCountStr =
      !!candidate._boxesCount && `${candidate._boxesCount} engagements`;

    const bestTimeForCalls =
      String(candidate.bestTimeForCalls).split(" - ") || [];
    const bestTimeFrom = moment(bestTimeForCalls[0], "hh:mma");
    const bestTimeTo = moment(bestTimeForCalls[1], "hh:mma");
    candidate._bestTimeFrom = null;
    candidate._bestTimeTo = null;
    if (bestTimeFrom.isValid()) {
      candidate._bestTimeFrom = bestTimeFrom.toDate();
    }
    if (bestTimeTo.isValid()) {
      candidate._bestTimeTo = bestTimeTo.toDate();
    }

    if (!candidate.workLocationIds.length) {
      candidate.workLocationIds.push(0);
    }
    candidate._allBayArea = [...candidate.workLocationIds].find(id => id === 1);
    candidate._officeLocations = candidate._allBayArea
      ? "All Bay Area"
      : Definition.getLabels("locationCandidate", [
        ...candidate.workLocationIds
      ]).filter(el => !!el).join(", ");
    /*
        Set Combination of _officeLocations with additional location details
        _workOrAdditionalLocations holds both of their values
        this key is been displayed in candidate card as work locations
     */
    candidate._workOrAdditionalLocations = candidate.locationDetails
      ? candidate._officeLocations.concat(", ").concat(candidate.locationDetails)
      : candidate._officeLocations;
    candidate._officeLocationsEmailTemplate = ("Unspecified" === candidate._officeLocations) ? "" : candidate._officeLocations;
    candidate._introduced = candidate.introduced
      ? moment(candidate.introduced).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;

    candidate._updatedAt = candidate.updatedAt
      ? moment(candidate.updatedAt).format(DATE_FORMAT__DATE__US_LONG)
      : mdash;


    let degreeLabel = Definition.getLabel('undergraduateDegree', candidate.undergraduateDegree);
    const array1ForUnified = [degreeLabel, candidate.undergraduateMajor];
    const arrayFinalForUnified = [array1ForUnified.join(' ').trim(), candidate.undergraduateSchool].filter(el => el.length);
    candidate._degreeUnifiedString = `${arrayFinalForUnified.join(' @ ')}`;

    // labels without prefix for v3
    candidate.__diversity = Definition.getLabel("diversity", candidate.diversity);
    candidate._diversity = "Diversity: " + candidate.__diversity;

    candidate.__diversityCategories = join(
      Definition.getLabels(DEFINITION_CATEGORY__DIVERSITY_CATEGORIES, candidate.diversityCategories)
    );
    candidate._diversityCategories = join(
      Definition.getLabels(DEFINITION_CATEGORY__DIVERSITY_CATEGORIES, candidate.diversityCategories)
        .map(v => `Diversity: ${v}`)
    );

    /* set definition labels */
    /* for fill filter menus and autocomplete */

    // if (
    //   candidate.state !== 4 &&
    //   moment(candidate.introduced) > moment().subtract(6, "months")
    // ) {
    //   candidate.state = 1;
    // }

    candidate.withEngagements = !!candidate.engagements.length ? 'Yes' : 'No';
    candidate._withEngagements = !!candidate.engagements.length ? 'Yes' : 'No';


    Definition.set(candidate, "state");
    candidate._newState = `state${candidate._state}`;

    Definition.set(candidate, "level");
    mapCandidateVisa(candidate);
    Definition.set(candidate, "platformRating");
    Definition.set(candidate, "offerAcceptance", "acceptOffer");
    Definition.set(candidate, "recruiterRating");

    // category     // candidate.field
    Definition.set(candidate, "relationShip", "relationship");

    /** @todo REVIEW TO DEPRECATED 2021-06-30 µ */
    Definition.set(candidate, "remotelyWork", "workRemotly");

    Definition.set(candidate, "undergraduateDegree");

    /* "Remote work preference" */
    /* epic-3038(new locations)-story-3330-m2 - 2021-07-01 µ */
    /* sets candidate._inOfficeRemoteFlags and candidate._inOfficeRemoteFlagsKeys */
    Definition.map(candidate, "inOfficeRemote", "inOfficeRemoteFlags");

    // labels without prefix for v3
    candidate.__inOfficeRemoteFlags = candidate._inOfficeRemoteFlags;

    /* sets the menu-prefix on each keyword
        contained in candidate._inOfficeRemoteFlags
        to include them into candidate.___keys___ */
    candidate._inOfficeRemoteFlags = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "inOfficeRemoteFlags" }),
      itemLabels: candidate._inOfficeRemoteFlags
    });

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    // Definition.set(candidate, "location", "candidateLocation");
    Definition.map(candidate, "location", "candidateLocations");

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    Definition.map(candidate, "desiredEmploymentType", "desiredEmploymentTypes");

    /* NEW FIELDS 2021-06-14 µ story-3053 */
    /* "Desired Locations" */
    Definition.map(candidate, "location", "officeLocations");

    // labels without prefix for v3
    candidate.__candidateLocations = candidate._candidateLocations;

    candidate._candidateLocations = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "candidateLocations" }),
      itemLabels: candidate._candidateLocations
    });

    // labels without prefix for v3
    candidate.__officeLocations = candidate._officeLocations;

    /* NEW LOCATIONS 2021-06-24 µ story-3083 M4 */
    /** set menu.prefix */
    candidate._officeLocations = FilterControlLib.getItemValues({
      menu: Candidate.getMenu({ key: "officeLocations" }),
      itemLabels: candidate._officeLocations
    });

    Definition.map(candidate, "roles", "roles");
    mapCandidateTechSkills(candidate);
    Definition.map(candidate, "industry");
    Definition.map(candidate, "technologyDomain");
    Definition.map(candidate, "positiveSignals");
    Definition.map(candidate, "negativeSignals");
    Definition.map(candidate, "jobType", "jobTypes");
    Definition.map(candidate, "contactPreference", "candidatePreferIds");
    Definition.map(candidate, "locationCandidate", "workLocationIds");
    Definition.map(candidate, "stage", "desiredStage");

    candidate._tags = [];
    candidate._myTags = [];

    if (candidate.tags[Core.getUserRole()]) {
      if (Core.isAdmin()) {
        const tagObj = candidate.tags;
        const roles = Reflect.ownKeys(candidate.tags);
        let tags = roles.map(r => Reflect.ownKeys(tagObj[r]).map(id => tagObj[r][id].tags));
        if (candidate.id === "5c5f4b223d3e4ccd41f92204") {
          //debugger
        }

        candidate._tags = tags.flat(2);
        candidate._myTags = R.pathOr([], ['tags', Core.getUserRole(), Core.getUserId(), 'tags'])(candidate);
      } else {
        candidate._tags = R.pathOr([], ['tags', Core.getUserRole(), Core.getUserId(), 'tags'])(candidate);
        candidate._myTags = candidate._tags;
      }
    }

    candidate._infoCmp = <CandidateInfo candidate={candidate} />;
    candidate._recruiterCmp = <RecruiterInfo candidate={candidate} />;
    candidate._engagementsCmp = <EngagementsInfo candidate={candidate} />;
    candidate._additionalInfoCmp = <AdditionalInfo candidate={candidate} />;
    candidate._rowCheckCmp = <RowCheckbox candidate={candidate} />;
    candidate._rowOptionsCmp = <RowOptions candidate={candidate} />;
    candidate._latestStage =
      Engagement.stageOrder[
      Math.max.apply(
        null,
        candidate.engagements.map(eng =>
          Engagement.stageOrder.indexOf(eng.stage)
        )
      )
      ];

    candidate.copyString = str => {
      if (
        copy(str, {
          debug: true,
          message: "Press #{key} to copy"
        })
      ) {
        Core.showSuccess(`${str} copied!`);
      } else {
        Core.showError("Fail copy!");
      }
    };
    candidate.bulkCopy = em => {
      const engagements = candidate.engagements || [];
      const contents = [];
      engagements.forEach(eng => {
        if (
          (eng.stage === STAGE_CONFIRMATION) &&
          (eng.state === ENGAGEMENT__STATE_OPEN)
        ) {
          contents.push(Job.getPreview(eng.job));
        }
      });
      if (!!contents.length) {
        copyHtml("<p>" + contents.join("</p><p>") + "</p>")
          .then(em => {
            Core.log("Copy email command was successful");
            Core.showSuccess("Copied!");
          })
          .catch(ex => {
            Core.log("Oops, unable to copy");
            Core.showError("Fail copy!");
          });
      } else {
        Core.showWarning("There are no Engagements on Confirmation stage");
      }
    };

    candidate.openMessage = ev => {

      openMessageEmailPreview({
        candidateId: candidate.id,
        recruiterId: candidate.accountId,
      });

    };

    candidate.openEngagementsDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: '80vw',
          maxWidth: "calc(100vw - var(--containerMinMargin))",
        },
        content: candidate.getEngagementDetails({ isDrawer: true }),
      });
    };

    candidate.getEngagementDetails = (props) => <CandidateEngagementsDetails candidate={candidate} {...props} />;
    candidate.delete = onSuccess => {
      const engagements = candidate.engagements || [];
      Core.dialog.open({
        title: (
          <>{`Delete "${candidate._name}"${!!engagements.length
            ? ` and ${engagements.length} engagement${engagements.length === 1 ? "" : "s"
            }?`
            : ""
            }`}</>
        ),
        message: "This action can't be undone.",
        style: { width: "320px" },
        actions: [
          <Button flat
            label="Cancel"
            className="button-white-cyan"
            onClick={ev => {
              Core.dialog.close();
            }}
          />,
          <Button flat
            label="Delete"
            className="button-flat-cyan"
            onClick={ev => {
              Core.dialog.close();
              Candidate.delete(
                candidate.id,
                onSuccess ? onSuccess : function () { }
              );
            }}
          />
        ]
      });
    };
    candidate.checkStar = (checked, onSuccess) => {
      Candidate.updateStarred(
        candidate.id,
        candidate.starredId,
        checked,
        response => {
          candidate.starredId = response.id;
          candidate.starred = response.starred;
          candidate._starred = response.starred
            ? "Starred: True"
            : "Starred: False";
          candidate.filters = {
            ...candidate.filters,
            Starred: ["Non Starred", "Starred"][~~Boolean(response.starred)]
          };
          onSuccess && onSuccess(response);
        }
      );
    };
    candidate.select = (checked, onSuccess) => {
      candidate.selected = checked;
      onSuccess && onSuccess(checked);
    };
    candidate.goMatch = ev => setLocation("/#/candidate/match/" + candidate.id);
    candidate.goEdit = ev => setLocation("/#/candidate/edit/" + candidate.id);

    candidate._contactNames = candidate.emailsList.filter(contact => validateEmail(contact.email)).map(contact => contact.name);

    /* for autocomplete */
    candidate.___keys___ = [
      /* Jira Ticket Ticket VER-20: Always include unspecified, unknow in the search results. */
      // "Visa Status Unknown",
      candidate._name,
      candidate.recruiter._name,
      candidate.recruiter.companyName,
      candidate.jobTitle,
      candidate._diversityCategories,

      /** @todo TO CLEANUP - 2021-06-28 µ * /
      candidate._officeLocations, // this is needed to get it works the location menu.
      /** */

      /** @todo REVIEW TO DEPRECATE - 2021-06-28 µ */
      candidate._workLocationIds,

      candidate._workOrAdditionalLocations,   // Combination of work locations and additional locations
      candidate._visa, // this is needed to get it works the visa menu.
      candidate._platformRating,
      candidate._roles, // this is needed to get it works the role menu.
      candidate._technicalSkills, // this is needed to get it works the technology menu.
      candidate._positiveSignals,
      candidate._withEngagements,
      candidate._tags,
      candidate._negativeSignals,
      candidate._industry,
      candidate._technologyDomain,
      candidate._jobTypes,
      candidate._starred,
      candidate._state,
      candidate._newState,
      // candidate._status,
      candidate._introducedStatus,
      candidate._introduced,
      candidate._desiredStage,

      /** New fields 2021-06-14 µ */
      candidate._desiredEmploymentTypes,
      candidate._candidateLocation,
      candidate._officeLocations,

      /* epic-3038(new locations)-story-3330-M2 - 2021-07-01 µ */
      candidate._inOfficeRemoteFlags,
      ...candidate._contactNames,

      ...Arr(candidate.engagements).map(formatCandidateJobFilterKey)

    ]
      /* combine and split values */
      .join(",")
      .split(",")
      /* remove empty values */
      .filter((s = '') => !!s.trim())
      .map(s => s.trim());

    /* epic-3038(new locations)-story-3652-m2 | 2021-08-03 Tue µ */
    if (NOT(candidate.desiredEmploymentTypes.length)) {
      Definition.get('desiredEmploymentType').forEach(tag =>
        candidate.___keys___.push(tag.label)
      );
    }

  }

  normalizeCandidateExperience({ candidate });
  normalizeCandidateEducation({ candidate });

  /* epic-3038(new locations)-story-3573-m2 | 2021-07-28 Wed µ */
  candidate.__unitTestingItemId = getUnitTestingItemId(candidate.firstName);

  return candidate;

};

const mapCandidates = data => {
  return (data || []).map(item => {
    const candidate = mapCandidate(item);
    return {
      ...candidate,
      filters: {
        Name: candidate.lastName.length ? candidate.lastName[0].toUpperCase() : MDASH,
        Introduced: candidate.introduced,
        Recent: moment(candidate.updatedAt),
        Recruiter: candidate.recruiter.lastName,
        Starred: ["Non Starred", "Starred"][~~Boolean(candidate.starred)]
      }
    };
  });
};

export function mapCandidateName(candidate, defaultValue) {
  const candidateName = join([candidate.firstName, candidate.lastName], ' ');
  Object.assign(candidate, { _name: candidateName || defaultValue })
  return candidate._name;
}

export function mapJobsPermittedAnswers(candidate) {
  if (candidate.jobsPermittedAnswers.version !== PRESCREEN_QUESTIONS__VERSION) {
    let globalAnswers = {};
    let employerAnswers = {};
    let jobAnswers = {};
    Object.values(candidate.permittedJobsAnswers).forEach(answers => {
      answers.forEach(answer => {
        jobAnswers[answer.jobId] = Obj(jobAnswers[answer.jobId]);
        jobAnswers[answer.jobId].answers = Obj(jobAnswers[answer.jobId].answers);
        jobAnswers[answer.jobId].answers[answer.qId] = mapQuestionAnswer({
          question: { id: answer.qId, question: answer.question },
          answer: { answer: answer.ans }
        });
      });
    });
    candidate.jobsPermittedAnswers = {
      version: PRESCREEN_QUESTIONS__VERSION,
      globalAnswers,
      employerAnswers,
      jobAnswers
    }
  }
  return candidate.jobsPermittedAnswers;
}

export function unsetCandidateSchool({ candidate, education = {} }) {
  delete candidate.undergraduateMajor;
  delete candidate.undergraduateSchool;
  delete candidate.graduationYear;
  delete candidate.undergraduateDegree;
  delete candidate._undergraduateDegree;
  // used for sorting
  education.isDefault = false;
}

export function setCandidateSchool({ candidate, education, degree }) {
  if (!education.schoolName) { return; }
  degree = degree || _.first(education.degrees || []) || {};
  candidate.undergraduateMajor = degree.degreeMajor;
  candidate.undergraduateSchool = education.schoolName;
  candidate.graduationYear = degree.endDateYear;
  candidate.undergraduateDegree = Definition.getId('undergraduateDegree', degree.degreeName);
  if (candidate.undergraduateDegree) {
    Definition.set(candidate, 'undergraduateDegree');
  }
  else if (degree.degreeName) {
    candidate._undergraduateDegree = degree.degreeName;
  }
  candidate.educationHistories = candidate.educationHistories.map(e => {
    e.isDefault = false;
    return e;
  });
  // used for sorting
  education.isDefault = true;
}

/**
 * Map some attributes into candidate and employment.
 * This is not triggering re-render or setting component status.
 * @param {object} options
 * @param {object} options.candidate
 * @param {object} options.employment
 * @returns {void}
 */
export function unsetCandidateEmployer({ candidate, employment, position }) {
  console.debug('UNSET', { candidate, employment, position });
  delete candidate.currentEmployerTo;
  delete candidate.currentEmployerFrom;
  delete candidate.currentlyEmployed;
  delete candidate.currentEmployer;
  delete candidate.currentTitle;
  if (
    employment &&
    position &&
    position.currentlyEmployed !== OPTION_YES
  ) {
    employment.isCurrentEmployer = false;
  }
}

/**
 * Map some attributes into candidate and employment.
 * This is not triggering re-render or setting component status.
 * @param {object} options
 * @param {object} options.candidate
 * @param {object} options.employment
 * @param {object} options.position
 * @returns {void}
 */
export function setCandidateEmployer({ candidate, employment, position }) {
  if (!employment?.employerOrgName || !position?.title) { return; }
  candidate.employmentHistories = candidate.employmentHistories.map(_employment => {
    _employment.isCurrentEmployer = (!!_employment.id && _employment.id === employment.id);
    return _employment;
  });
  position = position || _.first(employment.positionHistories || []) || {};
  candidate.currentEmployerTo = toLinkedinDateString({ month: position.endDateMonth, year: position.endDateYear });
  candidate.currentEmployerFrom = toLinkedinDateString({ month: position.startDateMonth, year: position.startDateYear });
  candidate.currentlyEmployed = position.currentlyEmployed; // OPTION_UNKNOWN | OPTION_YES | OPTION_NO
  candidate.currentEmployer = employment.employerOrgName;
  candidate.currentTitle = position.title;
  if (position.currentlyEmployed === OPTION_YES) {
    employment.isCurrentEmployer = true;
  }
}

/**
 * Normalize employment histories data and calc employments duration
 * 
 * @param {object} options
 * @param {object} options.candidate
 */
export function normalizeCandidateExperience({ candidate, filterInvalidSignals = true }) {

  let NOW = moment();

  candidate.__totalExperienceDurationMonths = 0;

  candidate.employmentHistories = [...(candidate.employmentHistories || [])].map(
    employment => {

      delete employment.__hasPositionCurrentlyEmployed;

      if (filterInvalidSignals && !Core.debug('NO_FILTER_INVALID_SIGNALS')) {
        filterInvalidCandidateHistorySignals({
          history: employment,
          type: SIGNALS_TYPE__COMPANY
        });
      }

      employment.__duration = 0;

      employment.positionHistories = [
        ...(employment.positionHistories || [])
      ].map(
        position => {

          position.startDate = position.startDateYear ? moment(
            `${position.startDateYear}${position.startDateMonth ? `-${String(position.startDateMonth).padStart(2, '0')}` : ''}`
          ).tz(TIMEZONE__LA).toISOString() : null;

          position.endDate = position.endDateYear ? moment(
            `${position.endDateYear}${position.endDateMonth ? `-${String(position.endDateMonth).padStart(2, '0')}` : ''}`
          ).tz(TIMEZONE__LA).toISOString() : null;


          position.currentlyEmployed = position.currentlyEmployed || (!position.endDate ? OPTION_YES : OPTION_NO);

          if (position.currentlyEmployed === OPTION_YES) {
            position.endDate = EMPLOYMENT_HISTORIES__PRESENT;
            position.endDateYear = null;
            position.endDateMonth = null;
            employment.__hasPositionCurrentlyEmployed = OPTION_YES;
          }

          if (position.startDate && position.endDate) {
            if (position.endDate === EMPLOYMENT_HISTORIES__PRESENT) {
              employment.__duration += moment().diff(position.startDate, 'years', true);
            }
            else {
              employment.__duration += moment(position.endDate).diff(position.startDate, 'years', true);
            }
          }

          if (
            !candidate.currentEmployer?.trim() &&
            !candidate.currentTitle?.trim()
          ) {
            if (
              employment.employerOrgName &&
              (position.currentlyEmployed === OPTION_YES)
            ) {
              setCandidateEmployer({ candidate, employment, position });
            }
          }

          return position;

        }
      );

      // GETTING minimum position startDate
      let startDates = employment.positionHistories.map(({ startDateMonth, startDateYear, }) => {
        let stringDate = `${startDateYear}${startDateMonth ? `-${String(startDateMonth).padStart(2, '0')}` : ''}`;
        return startDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime();
      });
      employment.__startDate = Math.min(...startDates);

      // GETTING maximum position endDate
      let endDates = employment.positionHistories.map(({ endDateMonth, endDateYear, endDate, currentlyEmployed }) => {
        let stringDate = `${endDateYear}${endDateMonth ? `-${String(endDateMonth).padStart(2, '0')}` : ''}`;
        return (
          !endDate
            ? null
            : ((currentlyEmployed === OPTION_YES) || !endDate || (endDate === EMPLOYMENT_HISTORIES__PRESENT))
              ? new Date().getTime()
              : endDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime()
        );
      }).filter(v => !!v);
      employment.__endDate = Math.max(...endDates);

      // CALCULATING duration
      employment.__naturalDuration = moment(employment.__endDate).diff(employment.__startDate, 'years', true);
      employment.__durationYears = Math.floor(employment.__duration);
      employment.__durationMonthsModule = Math.round((employment.__duration % 1) * 12);

      employment.__calculatedAt = NOW.toISOString();

      candidate.__totalExperienceDurationMonths += Math.round((employment.__duration) * 12) || 0;
      candidate.__calculatedAt = NOW.toISOString();

      return employment;

    });

  if (candidate.__totalExperienceDurationMonths && candidate.employmentHistories.length) {
    candidate.averageMonthsPerEmployer = (candidate.__totalExperienceDurationMonths / candidate.employmentHistories.length);
    DEBUG_CANDIDATE_MAP && console.debug('DEBUG_CANDIDATE_MAP:average\n', JSON.stringify({
      average: candidate.averageMonthsPerEmployer,
      months: candidate.__totalExperienceDurationMonths,
      histories: candidate.employmentHistories.length,
      calc: (candidate.__totalExperienceDurationMonths / candidate.employmentHistories.length)
    }, null, 2));
  }

}

/**
 * Normalize employment histories data and calc employments duration
 * 
 * @param {object} options
 * @param {object} options.candidate
 */
export function normalizeCandidateEducation({ candidate, filterInvalidSignals = true }) {

  candidate.educationHistories = [...(candidate.educationHistories || [])].map(
    education => {

      if (filterInvalidSignals && !Core.debug('NO_FILTER_INVALID_SIGNALS')) {
        filterInvalidCandidateHistorySignals({
          history: education,
          type: SIGNALS_TYPE__SCHOOL
        });
      }

      education.degrees = [
        ...(education.degrees || [])
      ].map(
        degree => {

          degree.startDate = degree.startDateYear ? moment(
            `${degree.startDateYear}${degree.startDateMonth ? `-${String(degree.startDateMonth).padStart(2, '0')}` : ''}`
          ).tz(TIMEZONE__LA).toISOString() : null;

          degree.endDate = degree.endDateYear ? moment(
            `${degree.endDateYear}${degree.endDateMonth ? `-${String(degree.endDateMonth).padStart(2, '0')}` : ''}`
          ).tz(TIMEZONE__LA).toISOString() : null;

          if (
            evalDefaultDegree({
              candidate,
              education,
              degree
            })
          ) {
            setCandidateSchool({
              candidate,
              education,
              degree
            });
          }

          return degree;

        }
      );

      // GETTING minimum position startDate
      let startDates = education.degrees.map(({ startDate, startDateMonth, startDateYear, }) => {
        let stringDate = `${startDateYear}${startDateMonth ? `-${String(startDateMonth).padStart(2, '0')}` : ''}`;
        return !startDate ? null : startDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime();
      });
      education.__startDate = Math.min(...startDates);

      // GETTING maximum position endDate
      let endDates = education.degrees.map(({ endDateMonth, endDateYear, endDate }) => {
        let stringDate = `${endDateYear}${endDateMonth ? `-${String(endDateMonth).padStart(2, '0')}` : ''}`;
        return (
          (!endDate)
            ? null
            : endDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime()
        );
      }).filter(v => !!v);
      education.__endDate = Math.max(...endDates);

      return education;

    });

}

class CandidateInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="inline-blocks">
        <IconButton
          title="Copy Candidate email"
          className="icon16"
          onClick={ev => candidate.copyString(candidate.email)}
        >
          <i className="material-icons">mail</i>
        </IconButton>
        &nbsp;
        {!!candidate.phone ? (
          <IconButton
            title="Copy Candidate phone"
            className="icon16"
            onClick={ev => candidate.copyString(candidate.phone)}
          >
            <i className="material-icons">phone</i>
          </IconButton>
        ) : (
          <div style={{ width: 16, height: 16 }} />
        )}
        &nbsp;
        {!!candidate.resumes.length ? (
          (resume => {
            return (
              <IconButton
                className="icon16"
                onClick={ev =>
                  downloadFile({
                    url: resume.url,
                    mimeType: "application/pdf",
                    onError: error => Core.showFailure(error)
                  })
                }
              >
                <i className="material-icons">file_download</i>
              </IconButton>
            );
          })(candidate.resumes[0])
        ) : (
          <div style={{ width: 16, height: 16 }} />
        )}
        &nbsp;
        <a href={"/#/candidate/edit/" + candidate.id}>
          <b>{candidate._name_rating || <i>&mdash;</i>}</b>
        </a>
      </div>
    );
  }
}
class RecruiterInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="inline-blocks no-wrap">
        {!!candidate.recruiter.email ? (
          <Fragment>
            <IconButton
              title="Copy Recruiter email"
              iconStyle={candidate.openedColor}
              className="icon16"
              onClick={ev => candidate.copyString(candidate.recruiter.email)}
            >
              <i className="material-icons">mail</i>
            </IconButton>
            &nbsp;
            <span title="Recruiter">{candidate.recruiter._name}</span>
          </Fragment>
        ) : (
          <i>&mdash;</i>
        )}
        {!!candidate.recruiter.companyName && (
          <span title="Agency">
            &nbsp;-&nbsp;
            {candidate.recruiter.companyName}
          </span>
        )}
      </div>
    );
  }
}
class AdditionalInfo extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="inline-blocks">
        <span title="Minimum Years of Experience">{candidate._years}</span>
        &nbsp;&nbsp;&nbsp;
        <span title="Minimum Salary">{candidate._minimumSalary}</span>
      </div>
    );
  }
}
class EngagementsInfo extends Component {
  render() {
    const { candidate } = this.props;
    const engagements = candidate.engagements;

    const reviewEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_REVIEW)
    ).length;
    const screenEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SCREEN)
    ).length;
    const onsiteEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_ONSITE)
    ).length;

    const confEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_CONFIRMATION)
    ).length;
    const inactiveEngagements = engagements.filter(
      eng => (eng.state === ENGAGEMENT__STATE_CLOSED)
    ).length;

    const submissions = engagements
      .map(eng =>
        eng.submitted
          ? moment(eng.submitted)
            .toDate()
            .getTime()
          : 0
      )
      .filter(time => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format("MM/DD/YY")
      : 0;
    const latestStage =
      Engagement.stageOrder[
      Math.max.apply(
        null,
        engagements.map(eng => Engagement.stageOrder.indexOf(eng.stage))
      )
      ];
    const componentEngagements = (
      <div className="cursor-pointer" onClick={ev => candidate.openDetails()}>
        <span title="Open, Confirmation">{confEngagements} conf |&nbsp;</span>
        <span title="Open, Review">{reviewEngagements} review |&nbsp;</span>
        <span title="Open, Screen">{screenEngagements} screen |&nbsp;</span>
        <span title="Open, Onsite">{onsiteEngagements} onsite</span>
        <br />
        <span title="Most advanced stage">
          <b>{latestStage || mdash}</b> |&nbsp;
        </span>
        <span title="Closed">{inactiveEngagements} inactive |&nbsp;</span>
        <span title={`Last submission: ${lastSubmissionDate}`}>
          <b>{lastSubmission || mdash}</b>
        </span>
      </div>
    );
    const alternativeCmp = <div />;
    return Core.isAdminOrCoordinator() ? componentEngagements : alternativeCmp;
  }
}
class RowCheckbox extends Component {
  render() {
    const { candidate } = this.props;
    return (
      <div className="row-checkbox">
        <Checkbox
          checked={candidate.selected}
          onCheck={(ev, checked) => {
            candidate.select(checked, res => this.setState({ updated: true }));
          }}
        />
      </div>
    );
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { candidate } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          className="starred"
          checked={candidate.starred}
          onCheck={(ev, checked) => {
            candidate.checkStar(checked, res =>
              this.setState({ updated: true })
            );
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
        />
        {Core.isRecruiter() &&
          Core.isProduction() &&
          !candidate.resumes.length ? (
          false
        ) : (
          <Menu icon
            name={`candidate_model__row_options__menu`}
            options={[
              {
                acl: (Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())),
                label: 'Match',
                onClick: candidate.goMatch
              },
              {
                acl: !!candidate.resumes.length,
                label: 'CV',
                onClick: (event) => {
                  if (
                    /.*.(doc|docx)/i.test(candidate.resumes[0].url) === true
                  ) {
                    Core.openSameWindow(candidate.resumes[0].url);
                  } else {
                    Core.openPopUp(candidate.resumes[0].url);
                  }
                }
              },
              {
                acl: Core.isAdmin(),
                label: 'Copy Confirmation Job Description',
                onClick: (event) => candidate.bulkCopy()
              },
              {
                acl: Core.isAdmin(),
                label: 'Message',
                onClick: candidate.openMessage
              },
              {
                acl: Core.isAdmin(),
                label: 'Delete',
                onClick: (event) => candidate.delete(res => Core.Main && Core.Main.fetchData())
              }
            ]}
          />
        )}
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: "pointer",
            fontWeight: 200,
            ...candidate.openedColor,
            ...candidate.rightArrow
          }}
          onClick={candidate.openDetails}
        >
          chevron_right
        </i>
        {candidate.blacklisted}
      </div>
    );
  }
}

setTimeout(() => {
  // Sets the default accountId
  extended.accountId = Core.getUserId();
  DEBUG_CANDIDATE_MAP = Core.debug('DEBUG_CANDIDATE_MAP');
});

/**
 *
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @param {boolean} options.parser
 * @param {boolean} options.matchExclusion
 * @returns {object} A new model
 */
function getCandidateModel({
  extended: isExtendedRequired,
  parser: isParserModelRequired,
  matchExclusion,
} = {}) {
  return newModel(
    isExtendedRequired
      ? extended
      : isParserModelRequired
        ? parser
        : matchExclusion ?
          modelMatchExclusion
          : model
  );
}

export {
  MODEL_NAME_CANDIDATE as CANDIDATE_MODEL_NAME,
  getCandidateModel,
  mapCandidate,
  mapCandidates, parser as parserModel
};

