import {
  COLLECTION__ACCOUNT_EMPLOYERS
} from '../services/BE/loopback.api';

export const QUERY__ACCOUNT_EMPLOYERS__BY__EMPLOYER = {
  collection: COLLECTION__ACCOUNT_EMPLOYERS,
  where: {
    employerId: '{{EMPLOYER__ID}}'
  },
  fields: ['id', 'accountId', 'employerId'],
  include: [
    {
      relation: 'account',
      scope: {
        fields: ['id', 'firstName', 'lastName', 'email']
      }
    }
  ]
}
