import {
  TableRow
} from '@mui/material';
import {
  trim
} from '../../../lib/String.lib';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STAGES,
  ENGAGEMENT__ACTIVE_STATUSES,
  ENGAGEMENT__STATES
} from '../../../dictionaries/Engagement.dic';
import {
  COLLECTION__EMPLOYER_ATS_MAPPING_RULES,
  createLoopbackRecord,
  deleteLoopbackRecord,
  readLoopbackRecord,
  updateLoopbackRecord,
} from '../../../lib/services/BE/loopback.api';
import StyledTable, {
  renderDropdown,
  renderInput
} from '../../Layout/Wrappers/StyledTable';
import StyledTableCell from '../../Layout/Wrappers/StyledTabCell';

export const ATS_MAPPING__INTERVIEW_DATE__IGNORE = { id: 'ignore', label: 'Ignore' };
export const ATS_MAPPING__INTERVIEW_DATE__NOT_SET = { id: 'not_set', label: 'Not set' };
export const ATS_MAPPING__INTERVIEW_DATE__PAST = { id: 'past', label: 'In the past' };
export const ATS_MAPPING__INTERVIEW_DATE__FUTURE = { id: 'future', label: 'In the future' };

export function getATSMappingModel({ employerId = null } = {}) {
  return {
    employerId,
    ATSStatus: null,
    ATSInterviewDate: 'ignore',
    ATSRejectionReason: null,
    stage: null, // STAGE_CONFIRMATION,
    status: null, // STATUS_W_10X10,
    state: null, // ENGAGEMENT__STATE_OPEN,
    rejectionReason: null,
  };
}

export function AdminATSMappingContent(props) {
  let {
    employerId,
    reference = () => null,
  } = props;
  const CONFIG = {
    name: 'AdminATSMappingContent',
    collection: COLLECTION__EMPLOYER_ATS_MAPPING_RULES,
    columnHeaders: {
      order: '',
      ATSStatus: 'ATS Status',
      ATSInterviewDate: 'ATS Interview Date',
      ATSRejectionReason: 'ATS Rejection Reason',
      currentStage: 'Old Stage',
      stage: 'New Stage',
      status: 'Status',
      state: 'State',
      rejectionReason: 'Rejection Reason',
    },
    columnWidth: {
      order: 100,
      ATSStatus: 200,
      ATSInterviewDate: 200,
      ATSRejectionReason: 200,
      currentStage: 200,
      stage: 200,
      status: 200,
      state: 200,
      rejectionReason: 200,
    },
    cellHeight: 48,
    tableColors: {
      dataFromATS: '#016560',
      oldEngagement: '#767779',
      tenByTenEngagement: '#84734d',
      actionsTop: '#767779',
      actions: '#c6c7c9',
      order: '#c6c7c9',
      ATSStatus: '#d5e4e5',
      ATSInterviewDate: '#d5e4e5',
      ATSRejectionReason: '#d5e4e5',
      currentStage: '#c6c7c9',
      stage: '#d5c9a6',
      status: '#d5c9a6',
      state: '#d5c9a6',
      rejectionReason: '#d5c9a6',
    },
    requiredFieldLabel: 'This field is required',
    requiredFields: {
      order: false,
      ATSStatus: (value) => !!trim(value),
      ATSInterviewDate: (value) => !!trim(value),
      ATSRejectionReason: false,
      currentStage: false,
      stage: false,
      status: false,
      state: false,
      rejectionReason: false,
    },
    uniqueFieldLabel: 'This field is duplicated',
    uniqueFields: {
      order: true,
    },
    cellRender: {
      order: renderInput('number'),
      ATSStatus: renderInput(),
      ATSInterviewDate: renderDropdown([
        ATS_MAPPING__INTERVIEW_DATE__IGNORE,
        ATS_MAPPING__INTERVIEW_DATE__NOT_SET,
        ATS_MAPPING__INTERVIEW_DATE__PAST,
        ATS_MAPPING__INTERVIEW_DATE__FUTURE,
      ]),
      ATSRejectionReason: renderInput(),
      currentStage: renderDropdown(ENGAGEMENT__ACTIVE_STAGES),
      stage: renderDropdown(ENGAGEMENT__ACTIVE_STAGES),
      status: renderDropdown(ENGAGEMENT__ACTIVE_STATUSES),
      state: renderDropdown(ENGAGEMENT__STATES),
      rejectionReason: renderDropdown(ENGAGEMENT__ACTIVE_REJECTION_REASONS),
    },
    fieldSet: {
      add: 'Add rule',
      noData: 'No rules yet. Click on the "Add" button to add a rule.'
    },
    saveConfirm: {
      title: 'Confirm ATS Rules Changes',
      message: `
        {{{BREAK_LINE}}}
        <p>
          {{#if TO_CREATE}}{{TO_CREATE}} rules will be added.<br/>{{/if}}
          {{#if TO_UPDATE}}{{TO_UPDATE}} rules will be updated.<br/>{{/if}}
          {{#if TO_DELETE}}{{TO_DELETE}} rules will be deleted.<br/>{{/if}}
        </p>
        <p>
          Are you sure you want to continue?
        </p>
        {{{BREAK_LINE}}}
      `,
      onAcceptLabel: 'Confirm',
    },
  };
  return (
    <StyledTable
      config={CONFIG}
      topHeaders={(
        <TableRow>
          <StyledTableCell
            colSpan={1}
            className='sticky-start'
            style={{ backgroundColor: CONFIG.tableColors.actionsTop }}
          >
            Order
          </StyledTableCell>
          <StyledTableCell
            colSpan={3}
            className='center'
            style={{ backgroundColor: CONFIG.tableColors.dataFromATS }}
          >
            Data from ATS
          </StyledTableCell>
          <StyledTableCell
            colSpan={1}
            className='center'
            style={{ backgroundColor: CONFIG.tableColors.oldEngagement }}
          >
            Old Engagement
          </StyledTableCell>
          <StyledTableCell
            colSpan={4}
            className='center'
            style={{ backgroundColor: CONFIG.tableColors.tenByTenEngagement }}
          >
            New Engagement
          </StyledTableCell>
          <StyledTableCell
            colSpan={1}
            className='sticky-end'
            style={{ backgroundColor: CONFIG.tableColors.actionsTop }}
          >
            Actions
          </StyledTableCell>
        </TableRow>
      )}
      getModel={() => getATSMappingModel({ employerId })}
      fetchData={async () => {
        return readLoopbackRecord({ collection: COLLECTION__EMPLOYER_ATS_MAPPING_RULES, where: { employerId } });
      }}
      createRecord={async ({ record }) => {
        return createLoopbackRecord({ collection: COLLECTION__EMPLOYER_ATS_MAPPING_RULES, record });
      }}
      updateRecord={async ({ id, record }) => {
        return updateLoopbackRecord({ collection: COLLECTION__EMPLOYER_ATS_MAPPING_RULES, id, record });
      }}
      deleteRecord={async ({ id }) => {
        return deleteLoopbackRecord({ collection: COLLECTION__EMPLOYER_ATS_MAPPING_RULES, id });
      }}
      reference={reference}
    />
  );
}
