import {
  REC_MSG_TYPE__REPRESENTATION_ID
} from '../../Definition';
import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  Obj
} from '../../Object.lib';
import TemplateLib from '../../Template.lib';

const CACHE = {};

export default async function fetchRecipientsCAVE({
  candidateId,
  container = 'candidate' // or 'recruiter'
}) {
  const results = {
    candidate: {},
    recruiter: {},
  }
  try {
    const envelope = CACHE[candidateId] || (
      CACHE[candidateId] = await TemplateLib.getRender({
        templateName: 'candidate-message',
        candidateId: candidateId,
        messageTypes: [
          REC_MSG_TYPE__REPRESENTATION_ID
        ]
      })
    );
    results.candidate = Obj(envelope?.mixins);
    results.recruiter = Obj(envelope?.mixins?.recruiterEnvelope);
  }
  catch (error) {
    globalErrorHandler(error);
  }
  const result = Obj(results[container]);
  return {
    from: result.from,
    emails: result.emails,
    to: result.to,
    cc: result.cc,
    bcc: result.bcc,
  };
}
