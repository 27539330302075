import moment from 'moment';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  DATE_FORMAT__DATE__US_LONG
} from '../../../lib/Date.lib';
import {
  trim
} from '../../../lib/String.lib';
import Box from '../../Layout/Wrappers/Box';
import {
  PLACEMENT__LEFT
} from '../../Layout/Wrappers/StyledTooltip';
import {
  isMatchDecisionNo,
  isMatchDecisionYes
} from '../Libraries/MatchDecisions.lib';

const CONFIG = {
  labels: {
    details: 'Details',
    diffDecision: 'Diff decision',
    fields: 'Categories/Fields',
    privateNote: 'Private note',
    reviewRequested: 'Review requested',
    shouldTag: 'Should tag',
    shouldNotTag: 'Should not tag',
    sameDecision: 'Same decision',
    whyYesHeader: 'Why yes',
    whyNoHeader: 'Why no',
    whyNeedToReadCV: 'Need to read CV',
  },
};

const Fieldset = ({ title, subtitle, children }) => {
  return (
    <div className='mt-2'>
      <span className='f-md fw-500'>{title}</span>
      {subtitle && (
        <span className='f-sm c-black-medium'>
          &nbsp;-&nbsp;
          {subtitle}
        </span>
      )}<br />
      <span className='f-sm'>
        {children}
      </span>
    </div>
  );
}

export default function MatchDecisionMaker(props) {
  let {
    matchDecisionTableRecord = {},

    /** [ 2023-02-01 ][ MS: Following prop is coming from the infinite loader to calc the height or each card where is used this component ] */
    measure,
    index = 0
  } = props;
  const {
    annotator = '',
    createdAt = '',
    updatedAt = '',
    matchDecision = '',
    whyYesDetails = '',
    whyYesFieldsValues = [],
    whyYesPrivateNote = '',
    whyNoDetails = '',
    whyNoFieldsValues = [],
    whyNoPrivateNote = '',
    whyNeedToReadCV = '',
    shouldTag = '',
    shouldNotTag = '',
    reviewRequested = false,
    sameDecision = [],
    diffDecision = [],
  } = matchDecisionTableRecord;
  const isYes = isMatchDecisionYes({ label: matchDecision });
  const isNo = isMatchDecisionNo({ label: matchDecision });
  const _renderFields = (list) => {
    return Arr(
      list.map((hash) => {
        return Object.entries(hash).map(([key, value], index) => {
          return (
            <li key={`match_decision_maker__list_entries__key__${key}__index_${index}`}>
              {value.label || key}:
              <ul className='m-0'>
                <li>candidate: {join(value.candidate) || 'n/a'}</li>
                <li>job: {join(value.job) || 'n/a'}</li>
              </ul>
            </li>
          );
        });
      })
    ).flat();
  };
  const title = (
    <>
      <div className='f-lg fw-500 d-flex flex-align-left-center'>
        <span className='mr-auto'>
          {isYes ? CONFIG.labels.whyYesHeader : isNo ? CONFIG.labels.whyNoHeader : 'Why...'}
        </span>
        {reviewRequested && (
          <>
            <span className='f-sm mr-1'>
              {CONFIG.labels.reviewRequested}
            </span>👈
          </>
        )}
      </div>
      <Fieldset
        title={CONFIG.labels.details}
        subtitle={(isYes ? 'whyYesDetails' : isNo ? 'whyNoDetails' : '')}
      >
        {trim(isYes ? whyYesDetails : isNo ? whyNoDetails : '') || 'n/a'}
      </Fieldset>

      <Fieldset
        title={CONFIG.labels.privateNote}
        subtitle={(isYes ? 'whyYesPrivateNote' : isNo ? 'whyNoPrivateNote' : '')}
      >
        {trim(isYes ? whyYesPrivateNote : isNo ? whyNoPrivateNote : '') || 'n/a'}
      </Fieldset>

      {isYes
        ? !!whyYesFieldsValues?.length && (
          <Fieldset title={CONFIG.labels.fields} subtitle={'whyYesFieldsValues'}>
            {_renderFields(whyYesFieldsValues)}
          </Fieldset>
        )
        : isNo
          ? !!whyNoFieldsValues?.length && (
            <Fieldset title={CONFIG.labels.fields} subtitle={'whyNoFieldsValues'}>
              {_renderFields(whyNoFieldsValues)}
            </Fieldset>
          )
          : ''}
      {whyNeedToReadCV && (
        <Fieldset title={CONFIG.labels.whyNeedToReadCV} subtitle={'whyNeedToReadCV'}>
          {whyNeedToReadCV}
        </Fieldset>
      )}
      {shouldTag && (
        <Fieldset title={CONFIG.labels.shouldTag} subtitle={'shouldTag'}>
          {shouldTag}
        </Fieldset>
      )}
      {shouldNotTag && (
        <Fieldset title={CONFIG.labels.shouldNotTag} subtitle={'shouldNotTag'}>
          {shouldNotTag}
        </Fieldset>
      )}
      {!!Arr(sameDecision).length && (
        <Fieldset title={CONFIG.labels.sameDecision} subtitle={'sameDecision'}>
          <ul className='m-0'>
            {sameDecision.map((annotator, index) => (
              <li key={`match_decision_maker__same_decision__annotator__index_${index}`}>{annotator}</li>
            ))}
          </ul>
        </Fieldset>
      )}
      {!!Arr(diffDecision).length && (
        <Fieldset title={CONFIG.labels.diffDecision} subtitle={'diffDecision'}>
          <ul className='m-0'>
            {diffDecision.map((annotator) => (
              <li key={`match_decision_maker__diff_decision__annotator__index_${index}`}>{annotator}</li>
            ))}
          </ul>
        </Fieldset>
      )}
    </>
  );
  const _isFirstDecision = (index === 0);
  return (
    <Box
      title={title}
      placement={PLACEMENT__LEFT}
      paperStyle={{ minWidth: 360 }}
    >
      <Box
        title={
          <>
            Created at {moment(createdAt).format(DATE_FORMAT__DATE__US_LONG)}<br />
            Updated at {moment(updatedAt).format(DATE_FORMAT__DATE__US_LONG)}
          </>
        }
        onLoad={measure}
        className='f-sm fw-400 hover-bg d-flex flex-align-left-center'
        style={{ maxWidth: 'calc(100% - 48px)' }}
      >
        <span className={_isFirstDecision ? 'fw-700' : ''}>{matchDecision}</span>
        &nbsp;-&nbsp;{annotator}&nbsp;-&nbsp;
        {moment(_isFirstDecision ? createdAt : updatedAt).format(DATE_FORMAT__DATE__US_LONG)}
      </Box>
    </Box>
  );
}
