import {
  times
} from "lodash";
import moment from 'moment';
import React, {
  Component
} from 'react';
import {
  SUGGESTION_TYPE__SCHOOL
} from '../../lib/Crunchbase.lib';
import Menu from '../Layout/Wrappers/Menu';

const MONTHS = [
  'Month',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

class YearsDropdown extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      startYear: this.props.type === SUGGESTION_TYPE__SCHOOL ? Number(moment().add(10, 'years').format('YYYY')) : Number(moment().format('YYYY')),
      month: 0,
      year: 0,
    }
  }

  componentDidMount() {
    let month = this.props.month;

    if (typeof month === 'string') {
      let regex = new RegExp(month, "g");
      month = MONTHS.find(m => regex.test(m));

      month = MONTHS.indexOf(month);
      if (month < 0) {
        month = 0;
      }
    }

    if (!month) {
      month = 0;
    }

    this.setState({
      month,
      year: +this.props.year || 0
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.month !== prevProps.month) {
      let month = this.props.month || 0;

      if (typeof month === 'string') {
        let regex = new RegExp(month, "g");
        month = MONTHS.find(m => regex.test(m))
        month = MONTHS.indexOf(month);
        if (month < 0) {
          month = 0;
        }
      }

      if (!month) {
        month = 0;
      }

      this.setState({
        month,
      });
    }

    if ((!!this.props.year || this.props.year) && this.props.year !== prevProps.year) {
      this.setState({
        year: +this.props.year || 0
      });
    }
  }

  render() {
    const { startYear, month, year } = this.state;
    let { onChangeHandler } = this.props;

    return (
      <div className={`d-flex ${this.props.className}`.trim()}>
        <Menu dropdown
          name="selectValue"
          value={month}
          onChange={(month) => {
            this.setState(({ month }), () => {
              let monthString = MONTHS[month];
              let monthCleaned = month === 0 ? null : month;
              onChangeHandler(monthCleaned, year, monthString);
            })
          }}
          onClose={this.props.onClose}
          variant='standard'
          className='mr-1'
          style={{ width: 120 }}
          paperStyle={{ maxHeight: '50vh', minHeight: 320 }}
          options={MONTHS.map(
            (month, index) => ({
              id: index,
              label: month
            })
          )}
        />
        <Menu dropdown
          name="selectValue"
          value={year}
          onChange={(year) => {
            this.setState({ year }, () => {
              let monthString = MONTHS[month];
              let monthCleaned = month === 0 ? null : month;
              onChangeHandler(monthCleaned, year, monthString);
            })
          }}
          onClose={this.props.onClose}
          style={{ width: 120 }}
          paperStyle={{ maxHeight: '50vh', minHeight: 320 }}
          options={times(70,
            (index) => {
              const _year = startYear - index;
              return ({
                id: _year,
                label: String(_year)
              });
            }
          )}
        />
      </div >
    );
  }
}

export default YearsDropdown;
