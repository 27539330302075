import {
  Paper as MuiPaper
} from '@mui/material';
import {
  forwardRef
} from 'react';
import {
  mapWrapper
} from '../Libraries/Theme.lib';

const Paper = ({
  wrapperProps = {},
  ...props
}, ref) => {
  return (
    <MuiPaper
      {...mapWrapper({
        role: 'Paper',
        props: wrapperProps,
        assign: props
      })}
      ref={ref}
    />
  );
}
export default forwardRef(Paper);

export function getDefaultPaperStyle({
  acl = true,
  height = '100%',
  width = 'calc(1200px - 2rem)',
  ...props
} = {}) {
  if (!acl) { return null; }
  return ({
    height,
    width,
    minWidth: 720,
    maxWidth: 'calc(100vw - 2rem)',
    maxHeight: 'calc(100vh - 64px - 2rem)',
    ...props
  });
}
