import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio
} from '@mui/material';
import { isString } from 'lodash';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  isObject
} from '../../../lib/Object.lib';
import useState from '../../../lib/hooks/useState.hook';
import { joinClassName } from '../Libraries/Theme.lib';

/**
 * 
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.label
 * @param {any[]} props.options  [custom_prop][object:{id,label}|string]
 * @param {string} props.value
 * @param {function} props.onChange
 * @returns 
 * @see https://mui.com/material-ui/api/radio-group/
 * @see https://mui.com/material-ui/api/radio/
 */
export default function RadioGroup({
  acl = true,
  debug = false,
  name = 'radio_group',
  className = '',
  label = false,
  labelProps = {},
  groupProps = {},
  optionProps = {},
  optionLabelProps = {},
  options = [],
  value: propsValue = null,
  onChange = async (value = '') => null,
  column = false,
  row = !column,
  fullWidth = true,
  ...props
}) {

  const _default = { value: propsValue };
  const [{ value }, _updateState] = useState(_default, _default);

  if (!acl) { return null; }

  const _onChange = async (event) => {
    const _value = isObject(event) ? event.target.value : event;
    await _updateState({ value: _value });
    onChange(event, _value);
  }

  const _onClick = async (event) => {
    if (event.target.value === value) {
      await _onChange(null);
    }
  }

  options = Arr(options).map((option) => {
    if (isString(option)) {
      option = { id: option, label: option };
    }
    option.id = String(option.id);
    return option;
  });

  const _groupProps = {
    ...groupProps,
    className: 'd-flex',
    name,
    value,
    onChange: _onChange,
    onClick: _onClick,
    row,
    children: (
      options.map(
        (option, index) => {

          const _optionLabelProps = {
            className: 'f-md mr-1',
            ...optionLabelProps,
          };

          const _optionProps = {
            ...optionProps,
            className: 'tt-unset mr-1 w-auto',
            value: option.id,
            label: (
              <span {..._optionLabelProps}>
                {option.label}
              </span >
            ),
            control: (
              <Radio {...option} />
            )
          }

          return (
            <FormControlLabel
              {..._optionProps}
              key={`${name}__${index}__${option.label}`}
            />
          );

        }
      )
    )
  };

  if (label !== false) {
    const _labelProps = {
      ...labelProps,
      children: label,
      className: join(['mr-1', labelProps.className], ' '),
    }
    label = (
      <FormLabel {..._labelProps} />
    );
  }

  const _wrapperProps = {
    ...props,
    className: joinClassName([
      'flex-align-left-center w-auto',
      column ? 'flex-column' : 'flex-row',
      className
    ]),
    children: (
      <>
        {label}
        <MuiRadioGroup {..._groupProps} />
      </>
    ),
  }

  return (
    <FormControl {..._wrapperProps} />
  );

}
