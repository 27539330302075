import {
  debounce
} from 'lodash';
import {
  Component
} from 'react';
import Announcement from '../../lib/Announcement';
import {
  Arr,
  join
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import {
  MDASH
} from '../../lib/Constants';
import Core from '../../lib/Core';
import Definition, {
  ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS,
  STATE_ACTIVE
} from '../../lib/Definition';
import Job from '../../lib/Job';
import {
  extended as announcementModel
} from '../../lib/models/announcement';
import {
  COLLECTION__EMPLOYERS,
  COLLECTION__JOBS,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import Store from '../../lib/Store';
import {
  Str
} from '../../lib/String.lib';
import {
  getLocation
} from '../../lib/URL.lib';
import Page from '../Layout/Page';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import Navigate from '../Layout/Wrappers/Navigate';
import Paper, {
  getDefaultPaperStyle
} from '../Layout/Wrappers/Paper';
import Table from '../Layout/Wrappers/Table';
import EditHotJobsPaper from './EditHotJobsPaper';

class Announcements extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      data: [{ ...announcementModel }],
      employers: [],
      jobs: [],
    };
    Store.set('path', getLocation());
  }
  publish = (ev) => {
    const data = [...this.state.data];
    const next = (em) => {
      setTimeout((st) => {
        if (!!data.length) {
          const announcement = data.pop();
          Core.log({ announcement });
          if (
            !announcement.id &&
            {
              Employer: !!announcement.employerId,
              Job: announcement.jobId,
            }[announcement.entity] &&
            announcement.eventType
          ) {
            Announcement.post(
              announcement,
              (response) => {
                const eventType = Definition.getLabel(
                  `announcementJobEventType`,
                  announcement.eventType
                );
                const inactiveId = Definition.getId('state', 'Inactive');
                const jobId = announcement.jobId;
                Core.log({ jobId, inactiveId, eventType });
                if (jobId && /closed/i.test(eventType)) {
                  Job.update(jobId, { state: inactiveId });
                }
                next();
              },
              (error) => {
                Core.failure({ title: 'Announcement Failure', message: error });
                next();
              }
            );
          } else {
            next();
          }
        } else {
          Core.go({ ...this.props, to: (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS })) ? '/announcements' : '/' });
        }
      });
    };
    next();
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    let showSave = false;
    debounce(() => {
      if (NOT(this.state.busy || this.state.error || this.state.ready)) {
        this.setState({ busy: true }, async () => {
          try {
            this.setState({
              jobs: Arr(
                await readLoopbackRecord({
                  collection: COLLECTION__JOBS,
                  where: { state: STATE_ACTIVE },
                  fields: ['id', 'jobTitle', 'employerId'],
                  include: [
                    {
                      relation: 'employer',
                      scope: {
                        fields: ['id', 'name']
                      }
                    }
                  ],

                  mapper: (data) => Arr(data).map(
                    (job) => ({
                      id: job.id,
                      label: join(
                        [
                          job.employer?.name,
                          job.jobTitle
                        ],
                        ` ${MDASH} `
                      ),
                      data: job
                    })
                  ),
                })
              ).sort(
                (a, b) => Str(a.label).localeCompare(String(b.label))
              ),
              employers: await readLoopbackRecord({
                collection: COLLECTION__EMPLOYERS,
                where: { state: STATE_ACTIVE },
                fields: ['id', 'name'],
              }),
              busy: false,
              ready: true
            });
          }
          catch (error) {
            Core.showError(error);
            this.setState({ error });
          }
        });
      }
    })();
    console.debug('AnnouncementsCreate', this.state);
    return (
      <Page
        title="Create Announcements"
      >
        <Paper
          className="p-2 my-2 mx-auto scroll"
          style={{
            ...getDefaultPaperStyle(),
            minHeight: 300,
            maxHeight: 'calc(40vh - 2rem)'
          }}
        >
          <Table styled>
            <Table.Head className='sticky-top '>
              <Table.Row className='border-bottom'>
                <Table.Cell style={{ width: 60 }}>#</Table.Cell>
                <Table.Cell>Entity</Table.Cell>
                <Table.Cell>Subject</Table.Cell>
                <Table.Cell>Event Type</Table.Cell>
                <Table.Cell className='align-right c-white-hover' style={{ width: 60 }}>
                  <IconButton
                    className='c-white icon24 mx-1'
                    onClick={(ev) => {
                      this.setState((state) => {
                        state.data.push({ ...announcementModel });
                        return state;
                      });
                    }}
                  >
                    <i className="material-icons">add_circle_outline</i>
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body className=''>
              {this.state.data.map((item, index) => (
                <Table.Row
                  key={`announcements_create__table_body_row__${index}`}
                  className='border-bottom hover-bg'
                >
                  <Table.Cell>
                    #{index + 1}
                  </Table.Cell>
                  <Table.Cell className='w-33'>
                    <Menu dropdown
                      value={this.state.data[index].entity}
                      onChange={(entity) =>
                        this.setState((state) => {
                          state.data[index].entity = entity;
                          return state;
                        })
                      }
                      options={['Employer', 'Job']}
                    />
                  </Table.Cell>
                  <Table.Cell className='w-33'>
                    {this.state.data[index].entity === 'Employer' ? (
                      <Menu dropdown
                        value={this.state.data[index].employerId}
                        onChange={(employerId) =>
                          this.setState((state) => {
                            state.data[index].employerId = employerId;
                            return state;
                          })
                        }
                        options={this.state.employers}
                      />
                    ) : (
                      <Menu dropdown
                        value={this.state.data[index].jobId}
                        onChange={(jobId) =>
                          this.setState((state) => {
                            state.data[index].jobId = jobId;
                            return state;
                          })
                        }
                        options={this.state.jobs}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell className='w-33'>
                    <Menu dropdown
                      value={this.state.data[index].eventType}
                      onChange={(eventType) =>
                        this.setState((state) => {
                          state.data[index].eventType = eventType;
                          return state;
                        })
                      }
                      options={Definition.get(
                        `announcement${this.state.data[index].entity}EventType`
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell className='align-right'>
                    <Box row noWrap>
                      <IconButton
                        onClick={(ev) => {
                          this.setState((state) => {
                            state.data.splice(index, 1);
                            return state;
                          });
                        }}
                      >
                        <i className="material-icons">remove_circle_outline</i>
                      </IconButton>
                      {!item.id &&
                        {
                          Employer: !!this.state.data[index].employerId,
                          Job: this.state.data[index].jobId,
                        }[this.state.data[index].entity] &&
                        this.state.data[index].eventType &&
                        (showSave = true) && <i className="material-icons">done</i>}
                    </Box>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <br />
          {showSave && (
            <Button flat
              className="button-1 float-right"
              label="Publish"
              onClick={this.publish}
            />
          )}
        </Paper>
        <EditHotJobsPaper />
      </Page>
    );
  }
}

export default Announcements;
