import { Select } from "@mui/material";
import React, {
  useState
} from "react";
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS
} from '../../dictionaries/Engagement.dic';
import Menu from '../Layout/Wrappers/Menu';

export function RejectionReasonsMenu(props) {
  const [status, setStatus] = useState();
  const statuses = [...ENGAGEMENT__ACTIVE_REJECTION_REASONS].filter(s => !!s.match(props.filter || /.?/)).map(
    status => (<Menu.Item value={status} key={status}>{status}</Menu.Item>)
  );
  return (
    <div className={["d-flex flex-align-left-center", props.className].filter(v => !!v).join(' ')} style={props.style}>
      <strong className="mr-1 c-cyan-darker">
        {props.children || 'Rejection Reason'}
      </strong>
      <Select
        variant="standard"
        value={status || props.value}
        size="small"
        style={{ width: '100%', minWidth: 150 }}
        onChange={event => {
          let status = event.target.value;
          setStatus(status);
          if (props.onChange instanceof Function) {
            props.onChange(status);
          }
        }}
      >
        {statuses}
      </Select>
    </div>
  );
}
