import {
  compile
} from 'handlebars';
import {
  generateMatchHavesHTMLString,
  KEY__MATCH_HAVES__ACCEPTANCE
} from '../../../components/Match/Haves/v2/Generators.lib';
import {
  join
} from '../../Array.lib';
import Core from '../../Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
  ENGAGEMENT_MATCH_STRENGTH__STRONG_YES,
  ENGAGEMENT_MATCH_STRENGTH__WEAK_YES,
  ENGAGEMENT_MATCH_STRENGTH__YES
} from '../../Definition';
import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  getPersonName,
  Obj
} from '../../Object.lib';
import {
  trim
} from '../../String.lib';
import {
  TEMPLATE__CAVE__RECRUITER__SUBJECT,
  TEMPLATE__RECRUITER__CAVE__BODY
} from '../../templates/CAVE/RepresentationEmailForRecruiter.template';
import fetchRecipientsCAVE from './fetchRecipientsCAVE.fun';

export default async function generateRecruiterCAVE({
  engagement = {}
}) {
  try {
    const candidate = Obj(engagement.candidate);
    const job = Obj(engagement.job);
    const jobLinks = join([
      (!!job.jobDescriptionPublicURL?.trim()
        ? `<a href="${job.jobDescriptionPublicURL}" target="_blank"><b>${job.jobTitle}</b></a>`
        : job.jobTitle
      ),
      (!!job.employer.url
        ? `<a href="${job.employer.url}" target="_blank"><b>${job.employer.name}</b></a>`
        : job.employer.name
      )
    ], ' at ');
    const matchStrengthTag = Definition.getTag({
      categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
      tagId: engagement.matchStrength
    });
    const recipients = await fetchRecipientsCAVE({
      candidateId: candidate.id,
      container: 'recruiter'
    });
    const result = {
      from: recipients.from,
      emails: recipients.emails,
      to: recipients.to,
      cc: recipients.cc,
      bcc: recipients.bcc,
      subject: compile(
        TEMPLATE__CAVE__RECRUITER__SUBJECT
      )({
        MATCH_DECISION: matchStrengthTag.label,
        CANDIDATE__FULLNAME: getPersonName(candidate),
        EMPLOYER: engagement.job.employer.name,
        JOB_TITLE: engagement.job.jobTitle
      }),
      body: compile(
        TEMPLATE__RECRUITER__CAVE__BODY
      )({
        RECRUITER__NAME: (
          candidate.recruiter.talentAdvisor ||
          candidate.recruiter.firstName
        ),
        YES: (engagement.matchStrength === ENGAGEMENT_MATCH_STRENGTH__YES),
        STRONG_YES: (engagement.matchStrength === ENGAGEMENT_MATCH_STRENGTH__STRONG_YES),
        WEAK_YES: (engagement.matchStrength === ENGAGEMENT_MATCH_STRENGTH__WEAK_YES),
        CANDIDATE__FULLNAME: getPersonName(candidate),
        JOB_LINKS: jobLinks,
        MATCH_DECISION: matchStrengthTag.label,
        HAVES: trim(
          await generateMatchHavesHTMLString({
            job: engagement.job,
            candidate: engagement.candidate,
            edit: KEY__MATCH_HAVES__ACCEPTANCE
          })),
        CANDIDATE__NAME: (
          candidate.nickName ||
          candidate.firstName
        ),
        SIGNATURE: Core.getEmailSignature()
      })
    };
    return result;
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
