import {
  Chip as MuiChip
} from '@mui/material';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  stringify
} from '../../../lib/Object.lib';
import {
  isString
} from '../../../lib/String.lib';
import {
  hasNoWidth,
  joinClassName,
  mapWrapper,
  THEME__COLOR__DEFAULT,
  THEME__COLOR__INHERIT,
  THEME__COLOR__PRIMARY,
  THEME__COLOR__SECONDARY,
  THEME__COLOR__SUCCESS,
  THEME__VARIANT__FILLED,
  THEME__VARIANT__OUTLINED
} from '../Libraries/Theme.lib';
import Icon from './Icon';
import Wrapper from './Wrapper';

/**
 * 
 * @param {object} options
 * @param {object} options.label
 * @param {boolean} options.styled  Adds style look ex. FilterControl.
 * @param {boolean} options.label  Adds style look ex. MatchList.
 * @returns 
 * @see https://mui.com/material-ui/api/chip/
 */
export default function Chip({
  component = MuiChip,
  // ---- //
  children = '',
  label = children,
  variant = THEME__VARIANT__FILLED,
  color = THEME__COLOR__DEFAULT,
  size = 'small',
  icon = null,
  onRequestDelete,
  onDelete = onRequestDelete,
  className = '',
  style = {},
  backgroundColor = '',
  // ---- //
  debug = false,
  primary = false,
  secondary = false,
  standard = false,
  outlined = false,
  medium = false,
  styled = false,
  slim = false,
  // ---- //
  wrapperProps = {},
  // ---- //
  small = undefined,
  ...props
}) {
  if (NOT(label)) {
    debug && console.debug(
      'Chip: missing label...',
      '\n', { label, children },
      new Warning()
    );
    return null;
  }
  if (primary) { color = THEME__COLOR__PRIMARY; }
  else if (secondary) { color = THEME__COLOR__SECONDARY; }
  else if (standard) { color = THEME__COLOR__INHERIT; }
  if (slim) { size = 'small'; }
  else if (medium) { size = 'medium'; }
  if (outlined) { variant = THEME__VARIANT__OUTLINED; }
  if (isString(icon)) {
    icon = <Icon size={size} icon={icon} className='mx-05' />;
  }
  const hasNotMargins = NOT(
    !!className.match(/(^|\W)m(x|t|b|r|l)?-/) ||
    !!stringify(style).match(/margin/i)
  );
  if (
    backgroundColor === colors.purple.common
  ) {
    color = THEME__COLOR__PRIMARY;
  }
  else if (
    (backgroundColor === colors.cyan.darker) ||
    (backgroundColor === colors.green.common)
  ) {
    color = THEME__COLOR__SUCCESS;
  }
  mapWrapper({
    role: 'Chip',
    props: wrapperProps,
    assign: {
      ...props,
      component,
      label,
      onDelete,
      variant,
      color,
      icon,
      size,
      className: joinClassName([
        'inline-flex px-05',
        hasNotMargins && 'mb-05 mr-05',
        styled && 'styled-chip',
        slim && 'slim-chip',
        hasNoWidth({ className, style }) && 'w-auto',
        className,
      ]),
      style
    },
  });
  return (
    <Wrapper {...wrapperProps} />
  );
}
