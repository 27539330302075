import { REC_MSG_TYPE__ALL_ID } from "../Definition";

export default function getEmailModel() {
  const emailModel = {
    "name": "",
    "email": "",
    "to": [REC_MSG_TYPE__ALL_ID], // {number[]} <entity>MessageTypeTagId
    "cc": [], // {number[]} <entity>MessageTypeTagId
    "bcc": [] // {number[]} <entity>MessageTypeTagId
  }
  return emailModel;
}
