
/**
 * @param {string} ACCOUNT__ID
 */
export const QUERY__ACCOUNT_EMPLOYER__BY_ACCOUNT__WITH_EMPLOYER = {
  where: {
    accountId: '{{ACCOUNT__ID}}'
  },
  include: [
    {
      relation: 'employer',
      scope: {
        fields: ['id', 'name']
      }
    }
  ],
  order: 'id DESC',
  limit: 1
};
