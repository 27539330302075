/** ============================================ µ
 * @description ToggleResume [JSX]
 *              V3-table UI Component
 * @createdAt   2021-10-20 Wed
 * @updatedAt   2021-10-20 Wed
 * ============================================ */
/* IMPORTS ==================================== */

import { Switch } from "antd";
import React from "react";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function ToggleResume(props) {
  const { enhancedReactTableContext } = props;
  const {
    looseMatch,
    setLooseMatchFlag,
  } = enhancedReactTableContext;
  return (looseMatch !== undefined) && (
    <div {...props}>
      <Switch
        checkedChildren={`Loose Match On`}
        unCheckedChildren={`Loose Match Off`}
        checked={!!looseMatch}
        onChange={checked => setLooseMatchFlag(checked)}
      />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { ToggleResume as default, ToggleResume };

/* ============================================ */
