import { Input } from 'antd';
import { useState } from 'react';
import { join } from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  NOT,
  capitalize
} from '../../../lib/GenericTools.lib';
import { Str } from '../../../lib/String.lib';
import {
  getExternalURL,
  getMainDomainName
} from '../../../lib/URLQuery.lib';
import formatURL from '../../../lib/tools/formatURL';
import IconButton from './IconButton';
import StyledTooltip, { PLACEMENT__RIGHT } from './StyledTooltip';

export function EditableLink({
  value: propsValue = '',
  onChange,
  className,
  style,
  children
}) {
  const [state, setState] = useState({ value: propsValue, prev: propsValue });
  let { value, prev, editing } = state;
  const _updateState = async (update) => new Promise(resolve => {
    setState(prevState => (update = ({ ...prevState, ...update })));
    resolve(update);
  });
  const _exportValue = async (value) => {
    value = formatURL(value);
    await _updateState({
      value,
      prev: value,
      editing: false
    }).then(state => console.debug('EditableLink', propsValue, state));
    await onChange(value);
    return value;
  }
  const _importValue = async (event) => {
    if (
      (prev !== propsValue) &&
      (value !== propsValue)
    ) {
      await _updateState({ value: propsValue, prev: propsValue });
    }
    return propsValue;
  }
  const _cancelEditing = async () => _updateState({
    value: propsValue,
    editing: false
  });
  const _onChange = async (event) => {
    value = Str(event.target.value);
    await _updateState({ value });
    return value;
  };
  const _onKeyDown = async (event) => {
    if (
      (event.key === 'Enter' || event.keyCode === 13) ||
      (event.key === 'Tab' || event.keyCode === 9)
    ) {
      await _exportValue(value);
    }
    else if (event.key === 'Escape' || event.keyCode === 27) {
      await _cancelEditing();
      return propsValue;
    }
    return value;
  }
  const _onBlur = async () => _exportValue(value);
  const _onClickEdit = async () => _updateState({ editing: true });
  const _onClickSave = async () => _exportValue(value);
  const _onClickCancel = _cancelEditing;
  setTimeout(_importValue);
  return (
    <div
      aria-label='editable_link'
      className={join([
        'd-flex flex-align-left-center',
        className
      ], ' ')}
      style={style}
    >
      {NOT(editing) ? (
        <>
          {NOT(value) ? <div className='mr-1'>{children}</div> : (
            <div className='truncate mr-1' style={{ maxWidth: '100%' }}>
              <StyledTooltip title='Open link URL'>
                <span
                  className='pointer c-purple'
                  onClick={event => Core.openPopUp(getExternalURL(value), 1600)}
                >
                  {getMainDomainName({ url: value, formatter: capitalize })}&nbsp;❯
                </span>
              </StyledTooltip>
            </div>
          )}
          <StyledTooltip
            title='Edit URL'
            placement={PLACEMENT__RIGHT}
          >
            <IconButton
              onClick={_onClickEdit}
            >
              <i className='material-icons icon16'>edit</i>
            </IconButton>
          </StyledTooltip>
        </>
      ) : (
        <>
          <Input
            autoFocus
            className='w-100 mr-1 styled'
            value={value}
            onChange={_onChange}
            onKeyDown={_onKeyDown}
            onBlur={_onBlur}
          />
          <StyledTooltip
            title='Save URL'
            className='mr-1'
          >
            <IconButton
              size='small'
              onClick={_onClickSave}
            >
              <i className='material-icons icon16 c-inherit'>save</i>
            </IconButton>
          </StyledTooltip>
          <StyledTooltip
            title='Cancel editing URL'
            className='mr-1'
          >
            <IconButton
              size='small'
              onClick={_onClickCancel}
            >
              <i className='material-icons icon16 c-inherit'>cancel</i>
            </IconButton>
          </StyledTooltip>
        </>
      )}
    </div>
  );
}
