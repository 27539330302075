import {
  Component
} from "react";
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import TextField from '../../Layout/Wrappers/TextField';
import {
  Label
} from '../../Layout/Wrappers/Typography';
import { joinClassName } from '../../Layout/Libraries/Theme.lib';

class InputFieldContent extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      content: this.props.content
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!prevProps.selectedMatch && prevProps.selectedMatch.id !== this.props.selectedMatch.id) {
      this.setState({ content: this.props.content });
    }
  }

  saveSkillContent = () => {
    const { saveJob, contentKey } = this.props;
    const { content } = this.state;

    !!saveJob && saveJob(contentKey, content);
  }

  render() {
    const { content } = this.state;
    const { label } = this.props;

    return (
      <Box row noWrap w100 scrollX
        className={joinClassName([
          this.props.className,
          'mt-2 mb-1 px-1'
        ])}
      >

        <Label mr>
          {label}
        </Label>

        <TextField
          name="newTechSkill"
          value={content}
          onChange={(event) => this.setState({ content: event.target.value })}
        />

        <IconButton
          onClick={this.saveSkillContent}
          icon='save'
        />

      </Box>
    );
  }
}

export default InputFieldContent;