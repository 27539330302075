
export const QUERY__ENGAGEMENTS = {
  include: [
    {
      relation: 'engagementStarreds',
      scope: {
        where: {
          accountId: '{{USER}}',
        },
      },
    },
    {
      relation: 'candidate',
      scope: {
        fields: [
          'id',
          'accountId',
          'firstName',
          'lastName',
          'nickName',
          'introduced',
          'platformRating',
          'email',
          'phone',
          'resumes',
          'resumeTxtUrl',
          'gitHubURL',
          'linkedInURL',
          'emailsList'
        ],
        include: [
          {
            relation: 'account',
            scope: {
              fields: [
                'firstName',
                'lastName',
                'companyName',
                'email',
                'role',
                'emailsList',
                'privateNotes',
                'createdAt'
              ],
            },
          },
        ],
      },
    },
    {
      relation: 'job',
      scope: {
        fields: [
          'id',
          'jobTitle',
          'employerId',
          'id',
          'role',
          'roles',
          'email',
          'emailsList',
          'addressCity',
          'jobDescription',
          'jobInterviewProcess',
          'qualificationNotes',
          'emailsList',
          'feedbackLogResume',
          'feedbackLogInterview',
          'feedbackSummaryResume',
          'feedbackSummaryInterview',
          'internalSubmissionInstructions'
        ],
        include: [
          {
            relation: 'employer',
            scope: {
              fields: [
                'id',
                'name',
                'proxyName',
                'email',
                'url',
                'totalFunding',
                'stage',
                'technicalSkills',
                'employeeCount',
                'crunchBaseUrl',
                'additionalUrl',
                'tagline',
                'product',
                'teamCount',
                'expectedResumeResponseTime',
                'expectedSchedulingResponseTime',
                'expectedScreenResponseTime',
                'expectedOnsiteResponseTime',
                'submissionMethods',
                'atsUrl',
                'atsApiProviders',
                'atsTypeId',
                'interviewProcess',
                'primaryContactNotes',
                'emailsList',
                'whyUs',
                'teamStructure',
                'crunchbaseUrl',
                'additionalUrl',
                'notables',
                'perks',
                'internalSubmissionInstructions'
              ],
            },
          },
        ],
      },
    },
    'actionOwner',
  ]
}

export default QUERY__ENGAGEMENTS;