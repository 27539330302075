import React from 'react';
import loader from '../../assets/images/loader.gif';

export default function Loader({
  loading = true,
  text = 'Loading',
  noText = false,
  className,
  style,
  children
}) {
  return loading && (
    noText ? LoadingImage : (
      <div className={['c-cyan-darker mr-1 d-flex flex-align-left-center', className].filter(v => !!v).join(' ')}>
        <span className='mr-1'>{(children || text)}</span><img src={loader} alt='' className='icon16' />
      </div>
    )
  );
}

export const LoadingImage = <img src={loader} alt='' className='icon16 s-align-center' />;
