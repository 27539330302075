import {
  isEqual
} from 'lodash';
import React, {
  useState
} from 'react';
import Core from '../../lib/Core';
import {
  newModel
} from '../../lib/GenericTools.lib';
import Col from "../Layout/Wrappers/Col";
import Row from "../Layout/Wrappers/Row";
import IconButton from '../Layout/Wrappers/IconButton';

export default function EditList({
  name,
  heading = '',
  subsection = false,
  data: input = [],
  getContext = () => ({}),
  renderItem: Component = () => null,
  onChange = () => { },
  model,
  nameProp = 'name'
}) {
  let [data, setData] = useState(input);
  let [prev, setPrev] = useState(input);
  let newRecord = data.find(({ __newRecord }) => __newRecord);

  function _addNewItem() {
    if (!newRecord) {
      data.unshift(newModel({ ...model, id: new Date().toISOString(), __newRecord: true }));
      setData(data);
      onChange(data);
    }
  }

  function _onChangeRecord(model, index) {
    return update => {
      data[index] = { ...data[index], ...update };
      delete data[index].__newRecord;
      setData(data);
      onChange(data);
    }
  }

  function _onDeleteRecord(model, index) {
    const remove = () => {
      data = data.filter((n, i) => i !== index);
      setData(data);
      onChange(data);
    }
    return event => {
      if (model.__newRecord) {
        remove();
      }
      else {
        Core.showConfirm({
          title: 'Confirm',
          message: (
            <span className='f-lg'>
              Are you sure you want to remove&nbsp;
              {model[nameProp]
                ? <>this <strong>{model[nameProp]}</strong></>
                : <>this item</>
              }
              &nbsp;?
            </span>
          ),
          async onAccept(event) {
            remove();
          }
        });
      }
    }
  }

  if (!isEqual(prev, input)) {
    setPrev(input);
    setData(input);
  }

  return (
    <>
      <Row>
        <Col fullWidth className='d-flex flex-align-left-center p-0'>
          {/* <label> */}
          {!subsection
            ? (
              <>
                <label className='m-0 tt-unset'>{heading}</label>
                <IconButton
                  onClick={_addNewItem}
                  color="primary"
                  title={`Add ${name ? `${name}`.toLowerCase() : 'item'}`} disabled={newRecord}
                >
                  <i className='material-icons c-inherit'>add_circle</i>
                </IconButton>
              </>
            ) : (
              <>
                <strong className='c-cyan-darker'>{heading}</strong>
                <IconButton
                  onClick={_addNewItem}
                  color="secondary"
                  title={`Add ${name ? `${name}`.toLowerCase() : 'item'}`}
                  disabled={!!newRecord}
                >
                  <i className='material-icons c-inherit'>add_circle_outline</i>
                </IconButton>
              </>
            )}
          {/* </label> */}
        </Col>
      </Row>
      {data.map((model, index) => (
        <div className='ml-2' key={`edit-list-item-${model.id}`}>
          <Component
            index={index}
            model={model}
            onChange={_onChangeRecord(model, index)}
            onDelete={_onDeleteRecord(model, index)}
            getContext={getContext}
          />
        </div>
      ))}
    </>
  );
}
