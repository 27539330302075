import {
  useState
} from 'react';
import Definition, {
  DEFINITION_CATEGORY__ACCOUNT_ACTION
} from '../../../lib/Definition';
import {
  trim
} from '../../../lib/String.lib';
import {
  COLLECTION__ACCOUNT_SUBROLES,
  createLoopbackRecord,
  deleteLoopbackRecord,
  readLoopbackRecord,
  updateLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import StyledTable, {
  renderMultiselect,
  renderInput
} from '../../Layout/Wrappers/StyledTable';
import Page from '../../Layout/Page';

export const CONFIG__ACCOUNT_SUBROLES = {
  button: {
    label: 'Manage subroles'
  }
}

export function getAccountSubroleModel() {
  return {
    key: null,
    label: null,
    actionsList: [],
  };
}

export default function ManageAccountSubroles(props) {
  let [state, setState] = useState({});
  const _updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    setState(prevState => ({ ...prevState, ...update }));
    return delay ? new Promise(resolve => setTimeout(() => resolve(state), delay)) : state;
  };
  if (!Definition.get(DEFINITION_CATEGORY__ACCOUNT_ACTION).length) {
    setTimeout(async () => {
      await _updateState({});
    });
  }
  return !!Definition.get(DEFINITION_CATEGORY__ACCOUNT_ACTION).length && (
    <StyledTable
      config={{
        name: 'accounts__edit__manage_subroles',
        collection: COLLECTION__ACCOUNT_SUBROLES,
        columnHeaders: {
          order: 'Order',
          key: 'Key',
          label: 'Label',
          actionsList: 'Actions',
        },
        columnWidth: {
          order: 100,
          key: 200,
          label: 200,
          actionsList: 512,
        },
        columnMaxWidth: 256,
        cellHeight: 48,
        tableColors: {
          order: '#c6c7c9',
          key: '#d5e4e5',
          label: '#d5e4e5',
          actionsList: '#d5e4e5',
          actions: '#c6c7c9',
        },
        requiredFieldLabel: 'This field is required',
        requiredFields: {
          key: (value) => !!trim(value),
          label: (value) => !!trim(value),
          actionsList: false,
        },
        uniqueFieldLabel: 'This field is duplicated',
        uniqueFields: {
          key: true,
        },
        cellRender: {
          order: renderInput('number'),
          key: renderInput(),
          label: renderInput(),
          actionsList: renderMultiselect(Definition.get(DEFINITION_CATEGORY__ACCOUNT_ACTION).sort((a, b) => {
            return String(a.label).localeCompare(b.label);
          })),
        },
        fieldSet: {
          add: 'Add subrole',
          noData: 'No subroles yet. Click on the "Add" button to add a subrole.'
        },
        saveConfirm: {
          title: 'Confirm Account Subroles Changes',
          message: `
              {{{BREAK_LINE}}}
              <p>
                {{#if TO_CREATE}}{{TO_CREATE}} subroles will be added.<br/>{{/if}}
                {{#if TO_UPDATE}}{{TO_UPDATE}} subroles will be updated.<br/>{{/if}}
                {{#if TO_DELETE}}{{TO_DELETE}} subroles will be deleted.<br/>{{/if}}
              </p>
              <p>
                Are you sure you want to continue?
              </p>
              {{{BREAK_LINE}}}
            `,
          onAcceptLabel: 'Proceed',
        },
      }}
      getModel={() => getAccountSubroleModel()}
      fetchData={async () => {
        return readLoopbackRecord({ collection: COLLECTION__ACCOUNT_SUBROLES, order: 'key ASC' });
      }}
      createRecord={async ({ record }) => {
        return createLoopbackRecord({ collection: COLLECTION__ACCOUNT_SUBROLES, record });
      }}
      updateRecord={async ({ id, record }) => {
        return updateLoopbackRecord({ collection: COLLECTION__ACCOUNT_SUBROLES, id, record });
      }}
      deleteRecord={async ({ id }) => {
        return deleteLoopbackRecord({ collection: COLLECTION__ACCOUNT_SUBROLES, id });
      }}
    />
  );
}

export function ManageAccountSubrolesPage(props) {
  return (
    <Page paper title='Manage account subroles'>
      <ManageAccountSubroles />
    </Page>
  )
}
