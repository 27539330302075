import {
  STATUS_H_10X10,
  STATUS_H_CANDIDATE,
  STATUS_H_EMPLOYER,
  STATUS_W_10X10,
  STATUS_W_10X10_ASK_PERMISSION,
  STATUS_W_10X10_NOTIFY_REJECTION,
  STATUS_W_10X10_REVIEW,
  STATUS_W_10X10_SCHEDULE,
  STATUS_W_10X10_SUBMISSION,
  STATUS_W_CANDIDATE,
  STATUS_W_CANDIDATE_PERMISSION,
  STATUS_W_CANDIDATE_SCHEDULE,
  STATUS_W_CANDIDATE_SUBMIT_FORM,
  STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
  STATUS_W_EMPLOYER,
  STATUS_W_EMPLOYER_FEEDBACK,
  STATUS_W_EMPLOYER_FORM_FEEDBACK,
  STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
  STATUS_W_EMPLOYER_SCHEDULE,
  STATUS_W_EMPLOYER_SEND_FORM,
  STATUS_W_EMPLOYER_SEND_HOMEWORK
} from '../../dictionaries/Engagement.dic';
import { getPathname } from '../../lib/URL.lib';
import {
  REACT_TABLE__DEFAULT_PAGE_SIZE,
  REACT_TABLE__INITIAL_PAGE
} from './useEnhancedReactTable.hook';
import {
  encodeUrlArray,
  encodeUrlObject
} from './useUrlQuery.hook';

export const tabBarOptionsEngagements = [
  {
    // Stage: Stage descending (laster stage first) -> Status->Recruiter -> Candidate
    label: 'Stage',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'stage': -1,
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter to do: Recruiter->Candidate->stage descending->Status
    label: 'Candidate Todo',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_CANDIDATE,
        STATUS_W_CANDIDATE_PERMISSION,
        STATUS_W_CANDIDATE_SCHEDULE,
        STATUS_W_CANDIDATE_SUBMIT_FORM,
        STATUS_W_CANDIDATE_SUBMIT_HOMEWORK,
        STATUS_H_CANDIDATE
      ]
    )}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // 10x10 todo: Recruiter->Candidate->stage descending->Status
    label: '10x10 Todo',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_10X10,
        STATUS_W_10X10_ASK_PERMISSION,
        STATUS_W_10X10_NOTIFY_REJECTION,
        STATUS_W_10X10_REVIEW,
        STATUS_W_10X10_SCHEDULE,
        STATUS_W_10X10_SUBMISSION,
        STATUS_H_10X10,
      ]
    )}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // Employer to do: Employer->stage descending->Status
    label: 'Employer Todo',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&status=${encodeUrlArray(
      [
        STATUS_W_EMPLOYER,
        STATUS_W_EMPLOYER_FEEDBACK,
        STATUS_W_EMPLOYER_FORM_FEEDBACK,
        STATUS_W_EMPLOYER_HOMEWORK_FEEDBACK,
        STATUS_W_EMPLOYER_SEND_FORM,
        STATUS_W_EMPLOYER_SEND_HOMEWORK,
        STATUS_W_EMPLOYER_SCHEDULE,
        STATUS_H_EMPLOYER,
      ]
    )}&o=${encodeUrlObject(
      {
        'job.employer.name': 1,
        'stage': -1,
        'overdueDate': -1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter: Recruiter->Candidate->stage descending->Status
    label: 'Recruiter',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
      }
    )}`,
  },
  {
    // Employer: Employer -> stage descending->status
    label: 'Company',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'job.employer.name': 1,
        'stage': -1,
      }
    )}`,
  },
  {
    // Candidate/Recruiter: Recruiter->Candidate->stage descending->Status
    label: 'Candidate',
    to: ({ context }) => `${getPathname().split('?')[0]}?p=${REACT_TABLE__INITIAL_PAGE}&l=${REACT_TABLE__DEFAULT_PAGE_SIZE}&o=${encodeUrlObject(
      {
        'candidate.account.firstName': 1,
        'candidate.firstName': 1,
        'stage': -1,
      }
    )}`,
  },
];
