import {
  VISA_CANDIDATE__CITIZEN,
  VISA_CANDIDATE__GREEN_CARD,
  VISA_CANDIDATE__H1B_WITH_I140_APPROVED,
  VISA_CANDIDATE__H4_EAD,
  VISA_CANDIDATE__J1,
  VISA_CANDIDATE__L1,
  VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU,
  VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER,
  VISA_CANDIDATE__NEEDS_NEW_VISA_SPONSOR,
  VISA_CANDIDATE__NEEDS_TN_CN_MX,
  VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED,
  VISA_CANDIDATE__O1,
  VISA_CANDIDATE__OPT_CPT_F1,
  VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
  VISA__CITIZEN_AND_GREEN_CARD_ONLY,
  VISA__CITIZEN_ONLY,
  VISA__VISA_SUPPORT_UNKNOWN,
  VISA__WILL_SPONSOR_NEW_H1,
  VISA__WILL_TRANSFER_H1
} from '../../lib/Definition';

export function mapCandidateVisaToJobPresets({ candidate, presets }) {
  // candidate: field(visa) categoryKey(visaCandidate)
  // job: field(visaTransfer) categoryKey(visa)
  // translated from: https://github.com/10By10/10by10-web-app-client/blob/8e2301c8a481b092059a3c89304c0dc554abfc16/src/lib/Job.js#L83

  const map = {
    [VISA_CANDIDATE__CITIZEN]: [
      VISA__CITIZEN_ONLY,
      VISA__CITIZEN_AND_GREEN_CARD_ONLY,
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__GREEN_CARD]: [
      VISA__CITIZEN_AND_GREEN_CARD_ONLY,
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__NEEDS_TN_CN_MX]: [
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU]: [
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER]: [
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__NEEDS_NEW_VISA_SPONSOR]: [
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__OPT_CPT_F1]: [
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__H1B_WITH_I140_APPROVED]: [
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__L1]: [
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__H4_EAD]: [
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__J1]: [
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__O1]: [
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED]: [
      VISA__CITIZEN_AND_GREEN_CARD_ONLY,
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ],

    [VISA_CANDIDATE__VISA_STATUS_UNKNOWN]: [
      VISA__CITIZEN_ONLY,
      VISA__CITIZEN_AND_GREEN_CARD_ONLY,
      VISA__WILL_TRANSFER_H1,
      VISA__WILL_SPONSOR_NEW_H1,
      VISA__VISA_SUPPORT_UNKNOWN
    ]
  };

  presets.visaTransfer = map[candidate.visa];
  if (!presets.visaTransfer) {
    delete presets.visaTransfer;
  }
}

export function mapJobVisaToCandidatePresets({ job, presets }) {
  // job: field(visaTransfer) categoryKey(visa)
  // candidate: field(visa) categoryKey(visaCandidate)
  // translated from: https://github.com/10By10/10by10-web-app-client/blob/8e2301c8a481b092059a3c89304c0dc554abfc16/src/lib/Candidate.js#L66

  const map = {
    [VISA__CITIZEN_ONLY]: [
      VISA_CANDIDATE__CITIZEN,
      VISA_CANDIDATE__VISA_STATUS_UNKNOWN
    ],

    [VISA__CITIZEN_AND_GREEN_CARD_ONLY]: [
      VISA_CANDIDATE__CITIZEN,
      VISA_CANDIDATE__GREEN_CARD,
      VISA__VISA_SUPPORT_UNKNOWN,
      VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED
    ],

    [VISA__WILL_TRANSFER_H1]: [
      VISA_CANDIDATE__CITIZEN,
      VISA_CANDIDATE__GREEN_CARD,
      VISA_CANDIDATE__NEEDS_TN_CN_MX,
      VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU,
      VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER,
      VISA_CANDIDATE__OPT_CPT_F1,
      VISA_CANDIDATE__H1B_WITH_I140_APPROVED,
      VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
      VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED
    ],

    [VISA__WILL_SPONSOR_NEW_H1]: [
      VISA_CANDIDATE__CITIZEN,
      VISA_CANDIDATE__GREEN_CARD,
      VISA_CANDIDATE__NEEDS_TN_CN_MX,
      VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU,
      VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER,
      VISA_CANDIDATE__NEEDS_NEW_VISA_SPONSOR,
      VISA_CANDIDATE__OPT_CPT_F1,
      VISA_CANDIDATE__H1B_WITH_I140_APPROVED,
      VISA_CANDIDATE__L1,
      VISA_CANDIDATE__H4_EAD,
      VISA_CANDIDATE__J1,
      VISA_CANDIDATE__O1,
      VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
      VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED
    ],

    [VISA__VISA_SUPPORT_UNKNOWN]: [
      VISA_CANDIDATE__CITIZEN,
      VISA_CANDIDATE__GREEN_CARD,
      VISA_CANDIDATE__NEEDS_TN_CN_MX,
      VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU,
      VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER,
      VISA_CANDIDATE__NEEDS_NEW_VISA_SPONSOR,
      VISA_CANDIDATE__OPT_CPT_F1,
      VISA_CANDIDATE__H1B_WITH_I140_APPROVED,
      VISA_CANDIDATE__L1,
      VISA_CANDIDATE__H4_EAD,
      VISA_CANDIDATE__J1,
      VISA_CANDIDATE__O1,
      VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
      VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED
    ]
  };

  presets.visa = map[job.visaTransfer];
  if (!presets.visa) {
    delete presets.visa;
  }
}
