import {Component} from "react";

class Not extends Component {

    render() {
        return '';
    }
}

export default Not;
