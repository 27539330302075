
import React from "react";
import Button from '../Layout/Wrappers/Button';
import NavLink from '../Layout/Wrappers/NavLink';

export default function ButtonAddNew({ enhancedReactTableContext, style, className }) {
  const { context: { config } } = enhancedReactTableContext;
  const { addNew = () => ({}) } = config;
  const { label = '+ New Element', to = '/' } = addNew();
  return (
    <NavLink to={to} style={style} className={className}>
      <Button primary small
        className="text-truncate c-white c-white-hover"
      >
        {label}
      </Button>
    </NavLink>
  );
}
