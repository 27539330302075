import {
  Arr
} from '../../../../lib/Array.lib';
import {
  trim
} from '../../../../lib/String.lib';
import {
  evaluateChip
} from './Chip.lib';
import {
  evaluateChipGroup
} from './ChipGroup.lib';
import {
  evaluateChipTag
} from './ChipTag.lib';

export function evaluateComplexHaves({ have, candidate }) {

  const decisions = Arr(have.args).map((have) => {
    if (have.type === 'chipGroup') {
      return haveDecision(evaluateChipGroup({ have, candidate }));
    }
    else if (have.type === 'chipTag') {
      return haveDecision(evaluateChipTag({ have, resumeText: trim(candidate._resumeText) }));
    }
    else if (have.type === 'chip') {
      return haveDecision(evaluateChip({ have, candidate }));
    }
    return haveDecision();
  });

  if (have.type === 'and') {
    return have.match = evaluateAndHaves({ decisions });
  }
  else if (have.type === 'or') {
    return have.match = evaluateOrHaves({ decisions, atLeast: have.atLeast });
  }
  else if (have.type === 'not') {
    return have.match = evaluateNotHaves({ decisions });
  }

  return have.match = null;
}

export function evaluateAndHaves({ decisions }) {
  return Arr(decisions).every((decision) => (decision.group === 1));
}

export function evaluateOrHaves({ decisions, atLeast = 1 }) {
  let counter = Arr(decisions).reduce((accumulator, decision) => (
    (decision.group === 1) ? accumulator + 1 : accumulator
  ), 0);
  return (counter >= atLeast);
}
export function evaluateNotHaves({ decisions }) {
  return Arr(decisions).every((decision) => (decision.group === 0));
}

export function evaluateAnyHaves({ decisions }) {
  return Arr(decisions).find((decision) => (decision.group === 1));
}

export function haveDecision(evaluation) {
  return (evaluation === true) ? { group: 1 } : { group: 0 };
}
