import Core from "../Core";

function getBinary({ url, onSuccess, onError }) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      try {
        const mimeType = reader.result.split(";").unshift();
        const binary = reader.result.split(",").pop();
        onSuccess({ binary, mimeType });
      } catch (ex) {
        Core.failure({
          source: 'getBinary.js(tool):onload:catch',
          exception: ex,
          params: { url }
        });
      }
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.onerror = function (error) {
    Core.failure({
      source: 'getBinary.js(tool):onerror',
      exception: error,
      params: { url }
    });
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}
export default getBinary;
