import Core from '../../lib/Core';
import Engagement from '../../lib/Engagement';
import { mapEngagements } from '../../lib/models/engagement';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import { FilterNumberRangeColumn } from './FilterNumberRangeColumn';
import { FilterStringColumn } from './FilterStringColumn';
import { moreMenuOptionsEngagements } from './3DotsMenu/moreMenuOptionsEngagements';
import {
  CellActionsColumn,
  CellTagColumn, FilterActionsColumn,
  FilterTagColumn
} from './ReactTable';
import { tabBarOptionsEngagements } from './tabBarOptionsEngagements';
import {
  REACT_TABLE__COLUMN_ACTION, REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_SM,
  REACT_TABLE__COLUMN_XL, REACT_TABLE__COLUMN_XXL, REACT_TABLE__COLUMN_XXXL
} from './useEnhancedReactTable.hook';

export const configEngagements = {
  Entity: Engagement,
  entityMapper: mapEngagements,
  searchPath: 'Engagements/_search',
  autocompletePath: 'Engagements/_autocomplete',
  facetsPath: 'Engagements/_facets',
  starredAccessor: 'engagementStarreds',
  searchPlaceholder: 'Search by employer, recruiter, candidate name',
  defaultQuery: {
    filters: [
      {
        state: 'Open',
      },
    ],
    // "sort": { "firstName": 1, "lastName": 1 },
    skip: 0,
    limit: 10,
    query: [],
    associations: ['candidate.account', 'job.employer', 'engagementStarreds'],
  },
  hiddenColumns: [
    'candidate.firstName',
    'id',
    'screened',
    'onsite',
    'offered',
    'hired',
    'startDate',
    'guaranteeDate',
    'holdDate',
    'overdueDate',
    'followedUpCount',
    'nextAction',
    'actionOwner',
    'actionOwner.id',
    'jobId',
    'candidateId',
    'state',
    'createdAt',
    'updatedAt',
    'rejectionReason'
  ],
  columns: [
    {
      accessor: 'candidate.lastName',
      Header: 'Candidate Lastname',
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_MD,
      _headerClassName: 'sticky-start bg-cyan-common',
      _cellClassName: 'sticky-start bg-white-common',
    },
    {
      accessor: 'candidate.firstName',
      Header: 'Candidate Firstname',
      Filter: FilterStringColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_MD,
      _headerClassName: 'sticky-start bg-cyan-common',
      _cellClassName: 'sticky-start bg-white-common',
    },
    {
      accessor: 'job.employer.name',
      Header: 'Employer',
      Filter: FilterStringColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_employerName',
    },
    {
      accessor: 'job.employer.careersPage',
      Header: 'Careers Page',
      Cell: CellTagColumn,
      width: 160,
      _accessor: '_careersPage',
    },
    {
      accessor: 'job.roles',
      Header: 'Roles',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      _accessor: '_roles',
      _categoryKey: 'roles',
    },
    {
      accessor: 'job.jobTitle',
      Header: 'Job',
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'candidate.platformRating',
      Header: 'Platform Rating',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_MD,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_candidatePlatformRating',
      _categoryKey: 'platformRating',
    },
    {
      accessor: 'candidate.account.firstName',
      Header: 'Recruiter',
      Filter: FilterStringColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_recruiterName',
    },
    {
      accessor: 'candidate.account.companyName',
      Header: 'Company',
      Filter: FilterStringColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_agencyName',
    },
    {
      accessor: 'stage',
      Header: 'Stage',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'status',
      Header: 'Status',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'screen1',
      Header: 'Screen 1',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      Cell: CellTagColumn,
      _accessor: '_screen1',
    },
    {
      accessor: 'screen2',
      Header: 'Screen 2',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      Cell: CellTagColumn,
      _accessor: '_screen2',
    },
    {
      accessor: 'screen3',
      Header: 'Screen 3',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_screen3',
    },
    {
      accessor: 'onsite1',
      Header: 'On Site 1',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_onsite1',
    },
    {
      accessor: 'onsite2',
      Header: 'On Site 2',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_onsite2',
    },
    {
      accessor: 'introduced',
      Header: 'Introduced',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_introduced',
    },
    {
      accessor: 'matched',
      Header: 'Matched',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_matched',
    },
    {
      accessor: 'confirmed',
      Header: 'Confirmed',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_confirmed',
    },
    {
      accessor: 'submitted',
      Header: 'Submitted',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_submitted',
    },
    {
      accessor: 'reviewed',
      Header: 'Reviewed',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_reviewed',
    },
    {
      accessor: 'lastAction',
      Header: 'Last Action',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_lastAction',
    },
    {
      accessor: 'matchType',
      Header: 'Match Type',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_MD,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_matchType',
      _categoryKey: 'matchType',
    },
    {
      accessor: 'backMatchDays',
      Header: 'Back Match Days',
      Filter: FilterNumberRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XL,
      _accessor: '_backMatchDays',
    },
    {
      accessor: 'matchedByWho.email',
      Header: 'Matched By Who',
      Cell: CellTagColumn,
      disableSortBy: false,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_matchedByWho',
    },
    {
      accessor: 'matchStrength',
      Header: 'Match Strength',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_matchStrength',
      _categoryKey: 'engagementMatchStrength',
    },

    /** TO HIDE */

    {
      accessor: 'id',
      Header: 'Engagement ID',
      disableFilters: true,
      width: REACT_TABLE__COLUMN_XL,
    },
    {
      accessor: 'screened',
      Header: 'Screened',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_screened',
    },
    {
      accessor: 'onsite',
      Header: 'Onsite',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_onsite',
    },
    {
      accessor: 'offered',
      Header: 'Offered',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_offered',
    },
    {
      accessor: 'hired',
      Header: 'Hired',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_hired',
    },
    {
      accessor: 'startDate',
      Header: 'Start Date',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_startDate',
    },
    {
      accessor: 'guaranteeDate',
      Header: 'Guarantee Date',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_guaranteeDate',
    },
    {
      accessor: 'holdDate',
      Header: 'Hold Date',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_holdDate',
    },
    {
      accessor: 'overdueDate',
      Header: 'Overdue Date',
      Cell: CellTagColumn,
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      _accessor: '_overdueDate',
    },
    {
      accessor: 'followedUpCount',
      Header: 'Followed Up Count',
      Filter: FilterNumberRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XL,
      _accessor: '_followedUpCount',
    },
    {
      accessor: 'nextAction',
      Header: 'Next Action',
      Cell: CellTagColumn,
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_nextAction',
    },
    {
      accessor: 'actionOwner',
      Header: 'Action Owner',
      Cell: CellTagColumn,
      Filter: FilterStringColumn,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_actionOwner',
    },
    {
      accessor: 'actionOwner.id',
      Header: 'Action Owner ID',
      disableFilters: true,
      width: REACT_TABLE__COLUMN_XL,
    },
    {
      accessor: 'jobId',
      Header: 'Job ID',
      disableFilters: true,
      width: REACT_TABLE__COLUMN_XL,
    },
    {
      accessor: 'candidateId',
      Header: 'Candidate ID',
      disableFilters: true,
      width: REACT_TABLE__COLUMN_XL,
    },
    {
      accessor: 'state',
      Header: 'State',
      width: REACT_TABLE__COLUMN_SM,
      _suggestions: ['Open', 'Closed'],
      _acl: [Core.isAdminOrCoordinator()],
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      Filter: FilterDateRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XXXL,
      _accessor: '_createdAt',
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated At',
      Filter: FilterDateRangeColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XXXL,
      _accessor: '_updatedAt',
    },
    {
      accessor: 'rejectionReason',
      Header: 'Rejection Reason',
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_XL,
    },

    // actions column
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_ACTION,
      _starredAccessor: 'engagementStarreds.starred',
      _headerClassName: 'sticky-end bg-cyan-common c-white-all bl-1',
      _cellClassName: 'sticky-end bg-white-common bl-1 d-flex flex-align-right-center',
    },
  ],
  tabBarOptions: tabBarOptionsEngagements,
  moreMenuOptions: moreMenuOptionsEngagements,
};
