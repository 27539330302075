import AppUI from '../../../dictionaries/AppUI.dic';
import EmployerPendingsUI from '../../../dictionaries/EmployerPendingsUI.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  copyString
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  getPersonName
} from '../../../lib/Object.lib';
import {
  QUERY__ACCOUNT_EMPLOYERS__BY__EMPLOYER
} from '../../../lib/queries/AccountEmployers.query';
import { getEmployerPendingsURL } from '../../../lib/services/Accounts/Session.lib';
import {
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  compileText,
  trim
} from '../../../lib/String.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';

export default async function openEmployerPendingsUrlGeneratorDialog({
  engagement = {},
  job = {},
  employer = {}
}) {
  try {
    const context = {};
    const employerId = employer.id || job.employerId || engagement.job?.employerId;
    if (NOT(employerId)) {
      throw new Error('Missed employerId');
    }
    const options = Arr(
      await readLoopbackRecord(
        compileText(
          QUERY__ACCOUNT_EMPLOYERS__BY__EMPLOYER,
          { EMPLOYER__ID: employerId }
        )
      )
    );
    if (NOT(options.length)) {
      return Core.showWarning(
        EmployerPendingsUI.urlGeneratorDialog.warningNoAccountsFound
      );
    }
    if (options.length === 1) {
      return generateAndCopyEmployerPendingsURL({
        accountId: options[0].accountId
      })
    }
    Object.assign(context, options[0]);
    showConfirm({
      title: EmployerPendingsUI.urlGeneratorDialog.title,
      content: (
        <EmployerPendingsOptions
          options={options}
          context={context}
        />
      ),
      paperStyle: { minWidth: 640 },
      onAcceptLabel: EmployerPendingsUI.urlGeneratorDialog.acceptLabel,
      async onAccept() {
        return generateAndCopyEmployerPendingsURL({
          accountId: context.accountId
        });
      },
    })
  }
  catch (error) {
    if (Core.isProduction()) {
      Core.showError(AppUI.unexpectedError.message, error);
    }
    else {
      Core.showError(error);
    }
  }
}

export async function generateAndCopyEmployerPendingsURL({
  accountId
}) {
  try {
    const URL = await getEmployerPendingsURL({ accountId });
    if (trim(URL)) { copyString(URL); }
    else {
      throw new Error(
        EmployerPendingsUI.urlGeneratorDialog.unexpectedError
      );
    }
  }
  catch (error) {
    if (Core.isProduction()) {
      Core.showError(AppUI.unexpectedError.message, error);
    }
    else {
      Core.showError(error);
    }
  }
}


export function EmployerPendingsOptions({
  options = [],
  context = {}
}) {
  const [{ id = null }, updateState] = useState({ id: context.id });
  const _renderLabel = ({ id, account = {} }) => (
    <>
      {getPersonName(account)} {MDASH} {account.email}
    </>
  );
  return (
    <>
      <Fieldset
        title='Select an account'
      >
        <Menu dropdown avoidNullOption
          value={id}
          options={options}
          onChange={
            async (id, selected) => {
              await updateState({ id });
              Object.assign(context, selected);
            }
          }
          optionLabelMapper={_renderLabel}
          renderOption={({ option }) => _renderLabel(option)}
        />
      </Fieldset>
    </>
  )
}
