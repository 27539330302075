import { useState } from 'react';
import AccountLib from '../../lib/Account';
import Candidate from '../../lib/Candidate';
import Core from '../../lib/Core';
import Engagement from '../../lib/Engagement';
import Http from '../../lib/Http';
import { getBookmarkModel, mapAccount } from '../../lib/models/account';

export function useAccountBookmarks({ accountId }) {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(false);

  async function fetchBookmarks() {
    setLoading(true);
    setFirstFetch(true);
    let promise = Http.get(Core.getApi('Accounts/' + accountId))
      .then(mapAccount)
      .then(({ bookmarks }) => {
        setLoading(false);
        setBookmarks(bookmarks);
        return bookmarks;
      });
    promise.catch((e) => {
      setLoading(false);
    });
    return promise;
  }

  async function upsertBookmark({

    bookmark = getBookmarkModel(),

    // set bookmarks just in case do you want to avoid and extra call.
    bookmarks: previousBookmarks

  }) {

    let bookmarks = previousBookmarks || await fetchBookmarks();

    setLoading(true);

    let newBookmark = true;
    let newBookmarks = bookmarks.map((_bookmark) => {
      if (_bookmark.createdAt === bookmark.createdAt) {
        newBookmark = false;
        return bookmark;
      }
      return _bookmark;
    });
    let update = newBookmark
      ? { bookmarks: [bookmark, ...bookmarks] }
      : { bookmarks: newBookmarks };

    AccountLib.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();

    let promise = Http.patch(Core.getApi('Accounts/' + accountId), update)
      .then((response) => mapAccount(response).bookmarks)
      .then((bookmarks) => {
        setBookmarks(bookmarks);
        setLoading(false);
        return bookmarks;
      });
    promise.catch((e) => {
      setLoading(false);
      Core.showError(e);
    });

    return promise;

  }

  async function deleteBookmark(bookmark) {
    if (firstFetch) {
      setLoading(true);

      let update = {
        bookmarks: bookmarks.filter(
          ({ createdAt }) => createdAt !== bookmark.createdAt
        ),
      };

      AccountLib.cleanCache();
      Engagement.cleanCache();
      Candidate.cleanCache();

      let promise = Http.patch(Core.getApi('Accounts/' + accountId), update)
        .then((response) => mapAccount(response).bookmarks)
        .then((bookmarks) => {
          setBookmarks(bookmarks);
          setLoading(false);
          return bookmarks;
        });
      promise.catch((e) => {
        setLoading(false);
        Core.showError(e);
      });

      return promise;
    } else {
      Core.showWarning('you need first fetch bookmarks');
    }

    return async () => { };
  }

  return {
    firstFetch,
    bookmarks,
    loading,
    fetchBookmarks,
    upsertBookmark,
    deleteBookmark,
  };
}
