import {
  Component
} from "react";
import Definition from "../../../../lib/Definition";
import Autocomplete from '../../../Layout/Wrappers/Autocomplete';
import Box from '../../../Layout/Wrappers/Box';
import Button from '../../../Layout/Wrappers/Button';
import Dialog from '../../../Layout/Wrappers/Dialog';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import IconButton from '../../../Layout/Wrappers/IconButton';
import Menu from '../../../Layout/Wrappers/Menu';
import TextField from '../../../Layout/Wrappers/TextField';
import Typography from '../../../Layout/Wrappers/Typography';
import { trim } from '../../../../lib/String.lib';

class JobTags extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      id: this.props.element.id,
      key: this.props.element.key || "",
      value: this.props.element.value || "",
      defaultTM: Object.values(Definition.getRes()).map(def => def.label).pop(),
      def: Definition.getRawDef("jobLevelGroup"),
      sources: Definition.getRawDef("jobLevelGroup").values.map(data => data.key),
      defAll: Definition.getRes(),
      defAllKeys: Object.values(Definition.getRes()).map(def => def.label),
      createWithPopup: false
    }
  }

  finalStruct = () => {
    let { id, key, value } = this.state;

    return {
      id,
      key,
      value
    }
  }

  updateAttr = (key, value) => {
    const { onDone } = this.props;
    this.setState({ [key]: value }, () => {
      !!onDone && onDone(this.finalStruct())
    });
  }

  autoFillKeyValues = (key) => {
    let { def } = this.state;

    const allValues = def.values;
    const searchedValue = allValues.find(val => val.key === key);

    if (!!searchedValue) {
      this.setState({ value: searchedValue.label });
    }
  }

  updateGroup = () => {
    let { def, key, value } = this.state;
    let values = def.values;
    let keys = values.map(el => el.key);
    let newLabel = {};
    let newindex = 0;

    let length = !!values.length ? (values.length - 1) : 0;

    if (!keys.includes(key)) {
      newindex = !!values.length ? values[length].id : 0;
      newLabel = { id: newindex + 1, key: key, label: value };
      values.push(newLabel);
      Definition.update(def.id, { values })
    }

    let newDefs = {
      ...def,
      values
    }
    let newSources = values.map(data => data.key);

    this.setState({ def: newDefs, sources: newSources });
  }

  updateTagManagement = () => {

    let { defAll, defaultTM, value, key, createWithPopup } = this.state;
    let needToUpdate = {};
    let keyNeedToupdate = "";

    Object.keys(defAll).forEach(def => {
      if (defAll[def].label === defaultTM) {
        needToUpdate = { ...defAll[def] };
        keyNeedToupdate = def;
      }
    });

    if (!!Object.keys(needToUpdate).length) {
      let values = needToUpdate.values;
      let keys = values.map(el => el.key);
      let newLabel = {};
      let newindex = 0;

      if (!keys.includes(key)) {
        newindex = values[values.length - 1].id;
        newLabel = { id: newindex + 1, key: key, label: value };
        values.push(newLabel);
        Definition.update(needToUpdate.id, { values });
      }

      let newDefs = {
        ...needToUpdate,
        values
      }
      let newDefAll = {
        ...defAll,
        [keyNeedToupdate]: newDefs
      };
      this.setState({ defAll: newDefAll, createWithPopup: !createWithPopup });
    }

  }

  handlerDialog = () => {
    let { createWithPopup } = this.state;
    this.setState({ createWithPopup: !createWithPopup });
  }

  render() {
    const { key, value, sources, createWithPopup, defAllKeys, defaultTM } = this.state;
    const { element, handlerDeleteItem } = this.props;

    const _setSelected = (value) => {
      if (trim(value)) {
        this.updateAttr("key", value);
      }
    };

    return (
      <Box column w100>

        <Box row w100>

          <IconButton
            onClick={() => handlerDeleteItem(element)}
            icon='cancel'
            color='error'
          />

          <Typography strong sub className='mr-1'>
            Key:
          </Typography>
          <Autocomplete
            name="search"
            placeholder="Key"
            options={sources}
            value={key}
            onSelect={_setSelected}
            className='mr-2 flex-1 w-100'
            style={{ minWidth: 168 }}
          />

          <Typography strong sub className='mr-1'>
            Value:
          </Typography>
          <TextField
            value={value}
            style={{ width: "150px" }}
            placeholder={"Value"}
            onChange={(event, text) => {
              this.updateAttr('value', text)
            }}
            className='flex-1 w-100 mr-2'
          />

          <Button flat primary
            onClick={() => this.updateGroup()}
          >
            Make Tag Global
          </Button>

        </Box>

        <Dialog open
          acl={!!createWithPopup}
          maxWidth='xl'
          actions={[
            <Button outlined minW120
              label="Cancel"
              onClick={() => this.handlerDialog()}
            />,
            <Button primary minW120
              label="Update"
              onClick={() => this.updateTagManagement()}
            />
          ]}
        >
          <Fieldset title='Update keys and value in one of the List from Tag Management'>
            <Menu dropdown
              name="TM"
              value={defaultTM}
              paperStyle={{ maxHeight: 640 }}
              onChange={(selected) => this.setState({ defaultTM: selected })}
              options={defAllKeys}
            />
          </Fieldset>
        </Dialog>

      </Box>
    );
  }
}

export default JobTags;
