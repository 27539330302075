import moment from 'moment';
import React, {
  useRef,
  useState
} from 'react';
import {
  evalDefaultPosition
} from '../../../lib/Company';
import {
  OPTION_YES,
  TIMEZONE__LA
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition from "../../../lib/Definition";
import {
  setCandidateEmployer
} from '../../../lib/models/candidate';
import {
  EMPLOYMENT_HISTORIES__PRESENT,
  evalDateTroubles,
  evalMissingDates
} from '../CandidateHistories.lib';
import Col from "../../Layout/Wrappers/Col";
import {
  ErrorChipMessage,
  InfoChipMessage
} from '../../Layout/Wrappers/Message';
import Row from "../../Layout/Wrappers/Row";
import Button from '../../Layout/Wrappers/Button';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import TextField from '../../Layout/Wrappers/TextField';
import YearsDropdown from "../../Shared/YearsDropdown";
import Box from '../../Layout/Wrappers/Box';

export default function ExperiencePositionHistory({
  index = -1,
  model = {},
  onChange = () => null,
  onDelete = () => null,
  getContext = () => ({})
}) {
  const ref = useRef();
  const busy = useRef();
  let [state, setState] = useState({ position: model });
  async function _updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }

  let {
    position,
    showMissingDateError,
    showTroubleDateError,
    showTitleError
  } = state;

  let {
    employment = {},
    candidate = {},
    attemptToClose
  } = getContext();

  let { title = '', description = '', currentlyEmployed, startDateMonth, startDateYear, endDateMonth, endDateYear } = position;
  let elementId = `position-${position.id}`;

  function _onChange(position) {
    if (
      evalDefaultPosition({
        candidate,
        employment,
        position
      })
    ) {
      setCandidateEmployer({
        candidate,
        employment,
        position
      });
    }
    onChange(position);
  }

  async function _evalTitle(position) {
    return _updateState({
      showTitleError: !String(position.title || '').trim(),
    });
  }

  async function _evalDates(position) {
    return _updateState({
      showMissingDateError: evalMissingDates(position),
      showTroubleDateError: evalDateTroubles(position)
    });
  }

  async function _scrollIntoView() {
    return document.getElementById(elementId)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  async function _evalErrors() {
    await _evalTitle(position);
    await _evalDates(position);
  }

  if (attemptToClose && !busy.current) {
    busy.current = true;
    _evalErrors().then(async () => {
      if (
        !!showTitleError ||
        !!showMissingDateError ||
        !!showTroubleDateError
      ) {
        await _scrollIntoView();
      }
      busy.current = false;
    });
  }

  return (
    <div className='rounded m-1 bg-main p-2' id={elementId} ref={ref}>
      <Row>
        <Col className='p-0 mr-1 w-100'>
          <div className='d-flex flex-align-left-center'>
            <strong className='c-cyan-darker mr-1'>
              Title*
            </strong>
            <div className='d-flex flex-align-left-center ml-auto'>
              {(
                (candidate.currentEmployer === employment.employerOrgName) && (candidate.currentTitle === position.title) ? (
                  <InfoChipMessage color='secondary' icon={false}>
                    Most recent
                  </InfoChipMessage>
                ) : (
                  position.title
                    ? (
                      <div
                        title={position.title ? 'Set this position as most recent' : 'Type a position title to enable this button'}
                        className='ml-auto'
                      >
                        <Button
                          size='small'
                          color='secondary'
                          variant='contained'
                          onClick={async event => {
                            if (candidate.currentTitle) {
                              Core.showConfirm({
                                title: 'Confirm',
                                content: (
                                  <Box column w100 className='f-lg'>
                                    <div>
                                      Another position is currently set as the candidate’s most recent position:
                                    </div>
                                    <strong>
                                      {[candidate.currentTitle, candidate.currentEmployer].filter(v => !!v).join(' - ')}
                                    </strong>
                                    <div>
                                      Do you wish to update this candidate’s most recent position?
                                    </div>
                                  </Box>
                                ),
                                async onAccept() {
                                  setCandidateEmployer({
                                    candidate,
                                    employment,
                                    position
                                  });
                                  await _updateState({ position });
                                  _onChange(position);
                                },
                                onAcceptLabel: 'Update'
                              });
                            }
                            else {
                              setCandidateEmployer({
                                candidate,
                                employment,
                                position
                              });
                              await _updateState({ position });
                              _onChange(position);
                            }
                          }}
                          disabled={!position.title}
                        >
                          Set as most recent
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )
                )
              )}
              <IconButton className='hint--top ml-1' aria-label='Remove position' onClick={onDelete}>
                <i className='material-icons'>delete</i>
              </IconButton>
            </div>
          </div>
          <TextField
            name="title"
            variant='standard'
            value={title || ''}
            onChange={async event => {
              employment.__forceAutomaticallyDerive = true;
              position.title = event.target.value;
              await _updateState({ position });
            }}
            onBlur={async () => {
              await _updateState({ position });
              await _evalTitle(position);
              _onChange(position);
            }}
            fullWidth
          />
        </Col>
      </Row>
      <ErrorChipMessage show={!!showTitleError} className='mt-1'>
        Title is a required field
      </ErrorChipMessage>
      <Row className='mt-2'>
        <Col className='p-0 mr-1 w-100'>
          <div className='d-flex flex-align-left-center'>
            <strong className='c-cyan-darker mr-1'>
              Description
            </strong>
          </div>
          <TextField
            name="description"
            variant='standard'
            maxRows={5}
            multiline
            value={description || ''}
            onChange={async event => {
              employment.__forceAutomaticallyDerive = true;
              position.description = event.target.value;
              await _updateState({ position });
            }}
            onBlur={() => _onChange(position)}
            fullWidth
          />
        </Col>
      </Row>
      <Row className='mt-2 flex-wrap'>
        <Col className='p-0 pr-2 w-33' style={{ minWidth: 120 }}>
          <div className='truncate'>
            <strong className='c-cyan-darker'>
              Currently in this position?
            </strong>
          </div>
          <Menu dropdown
            name="currentlyEmployed"
            value={currentlyEmployed}
            onChange={async (currentlyEmployed) => {
              employment.__forceAutomaticallyDerive = true;
              position.currentlyEmployed = currentlyEmployed;
              if (parseInt(position.currentlyEmployed) === OPTION_YES) {
                position.endDate = EMPLOYMENT_HISTORIES__PRESENT;
                position.endDateYear = null;
                position.endDateMonth = null;
              }
              else if (
                parseInt(position.currentlyEmployed) !== OPTION_YES &&
                (position.endDate === EMPLOYMENT_HISTORIES__PRESENT)
              ) {
                position.endDate = null;
                position.endDateYear = null;
                position.endDateMonth = null;
              }
              await _updateState({ position });
              await _evalDates(position);
              _onChange(position);
            }}
            onClose={async event => await _evalDates(position)}
            options={Definition.get("diversity")}
          />
        </Col>

        <Col className='p-0 pr-2 w-33' style={{ minWidth: 120 }}>
          <strong className='c-cyan-darker'>Start date*</strong>
          <YearsDropdown
            month={startDateMonth}
            year={startDateYear}
            onChangeHandler={async (startDateMonth, startDateYear) => {
              employment.__forceAutomaticallyDerive = true;
              position.startDateMonth = startDateMonth;
              position.startDateYear = startDateYear;
              position.startDate = (
                position.startDateYear
                  ? moment(
                    `${position.startDateYear}${position.startDateMonth ? `-${String(position.startDateMonth).padStart(2, '0')}` : ''}`
                  ).tz(TIMEZONE__LA).toISOString()
                  : null
              );
              await _updateState({ position });
              await _evalDates(position);
              _onChange(position);
            }}
            onClose={async event => await _evalDates(position)}
          />
        </Col>

        <Col className='p-0 w-33' style={{ minWidth: 120 }}>
          {parseInt(currentlyEmployed) === 1
            ? (
              <>
                <strong className='c-cyan-darker'>End date*</strong>
                <TextField
                  variant='standard'
                  size='large'
                  name={'employerTo'}
                  value={EMPLOYMENT_HISTORIES__PRESENT}
                  onChange={async event => {
                    position.endDate = event.target.value;
                    await _updateState({ position });
                  }}
                  fullWidth
                  disabled
                />
              </>
            )
            : (
              <>
                <strong className='c-cyan-darker'>End date</strong>
                <YearsDropdown
                  month={endDateMonth}
                  year={endDateYear}
                  onChangeHandler={async (endDateMonth, endDateYear) => {
                    employment.__forceAutomaticallyDerive = true;
                    position.endDateMonth = endDateMonth;
                    position.endDateYear = endDateYear;
                    position.endDate = (
                      position.endDateYear
                        ? moment(
                          `${position.endDateYear}${position.endDateMonth ? `-${String(position.endDateMonth).padStart(2, '0')}` : ''}`
                        ).tz(TIMEZONE__LA).toISOString()
                        : null
                    );
                    await _updateState({ position });
                    await _evalDates(position);
                    _onChange(position);
                  }}
                  onClose={async event => await _evalDates(position)}
                />
              </>
            )
          }
        </Col>
      </Row>

      <ErrorChipMessage show={!!showMissingDateError} className='mt-1'>
        Start and end dates are required
      </ErrorChipMessage>

      <ErrorChipMessage show={!!showTroubleDateError} className='mt-1'>
        End date can’t be earlier than start date
      </ErrorChipMessage>

    </div >
  );
}
