import React, {
  Component
} from "react";

import Chips from "../../../../../Forms/Chips";

class JobTags extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      items: this.props.data.items,
      additionalItems: this.props.data.additionalItems,
      values: this.props.data.values,
      onChange: this.props.data.onChange
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.values !== prevProps.data.values) {
      this.setState({ values: this.props.data.values });
    }
  }

  filterJobTagValues = () => {
    const { items, additionalItems, values } = this.state;

    const keysFromItems = [...items, ...additionalItems].map(item => item.key);
    const filteredValues = values.filter(value => keysFromItems.includes(value.key));

    return filteredValues;
  }

  render() {
    const { items, additionalItems, onChange } = this.state;
    const { dialogClass } = this.props.data;
    const filteredValues = this.filterJobTagValues();

    return <>
      <Chips
        name="jobTagGroup"
        label="Job Tags"
        items={items}
        additionalItems={additionalItems}
        values={filteredValues}
        commonCase={true}
        groupingTitle={<strong style={{ fontSize: '14px' }}>Global Tag</strong>}
        allSkillsTitle={<strong style={{ fontSize: '14px' }}>Local Tag</strong>}
        onChange={(chips, chipsObj) => {
          onChange(chips, chipsObj);
        }}
        hidePopupContent={() => {
          document.getElementsByClassName(dialogClass)[0].classList.add("removeClass");
        }}
        displayPopupContent={() => {
          document.getElementsByClassName(dialogClass)[0].classList.remove("removeClass");
        }}
      />
    </>
  }
}

export default JobTags;
