import {
  Component
} from 'react';
import {
  join
} from '../../../lib/Array.lib';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STAGES,
  ENGAGEMENT__ACTIVE_STATUSES,
  ENGAGEMENT__STATES,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../../dictionaries/Engagement.dic';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import Button from '../../Layout/Wrappers/Button';
import Dialog from '../../Layout/Wrappers/Dialog';
import Menu from '../../Layout/Wrappers/Menu';
import TextField from '../../Layout/Wrappers/TextField';

class ManageEngagement extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      ...this.props
    }
  }

  componentDidMount() {
    const { acl = true } = this.props;
    if (!acl) { return null; }
    this.setupItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { acl = true } = this.props;
    if (!acl) { return null; }
    if (prevProps.open !== this.props.open) {
      this.setState(
        {
          open: this.props.open,
        }
      );
    }
  }

  setupItems = () => {
    this.setState({
      stages: [...ENGAGEMENT__ACTIVE_STAGES],
      statuses: [...ENGAGEMENT__ACTIVE_STATUSES],
      states: [...ENGAGEMENT__STATES],
      rejectionReasons: [...ENGAGEMENT__ACTIVE_REJECTION_REASONS],
      matchStrengths: Definition.get(
        DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
      ),
    });
  }

  close = () => {
    const { closeParentPopup } = this.props;
    this.setState({ open: false });
    closeParentPopup();
  };

  submit = () => {
    let { engagement } = this.state;
    const newDate = new Date();
    const newISO = newDate.toISOString();
    [
      [
        new RegExp(
          join([
            STAGE_SUBMISSION,
            STAGE_REVIEW,
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "confirmed"
      ],
      [
        new RegExp(
          join([
            STAGE_REVIEW,
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "submitted"
      ],
      [
        new RegExp(
          join([
            STAGE_SCREEN,
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "reviewed"
      ],
      [
        new RegExp(
          join([
            STAGE_ONSITE,
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "screened"
      ],
      [
        new RegExp(
          join([
            STAGE_OFFER,
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "onsite"
      ],
      [
        new RegExp(
          join([
            STAGE_HIRE,
            STAGE_GUARANTEE
          ], '|')
        ),
        "offered"
      ]
    ].forEach(item => {
      if (item[0].test(engagement.stage) && !engagement[item[1]]) {
        engagement[item[1]] = newISO;
      }
    });

    const engToSubmit = {
      confirmed: engagement.confirmed,
      lastAction: engagement.lastAction,
      overdueDate: engagement.overdueDate,
      rejectionReason: engagement.rejectionReason,
      rejectionReasonAdditionalInfo: engagement.rejectionReasonAdditionalInfo,
      stage: engagement.stage,
      state: engagement.state,
      status: engagement.status,
      matchStrength: engagement.matchStrength
    }

    Engagement.update(engagement, engToSubmit, (res) => {
      const { closeParentPopup } = this.props;
      this.setState({ open: false }, () => {
        const { afterUpdateEngagement } = this.props;
        afterUpdateEngagement(engToSubmit);
      });
      closeParentPopup();
    }, () => (alert('failed to save')))
  }

  setAttr = (attr) => (value) => {
    const { engagement } = this.state;
    this.setState({ engagement: { ...engagement, [attr]: value } });
  };

  render() {
    const { acl = true } = this.props;
    if (!acl) { return null; }
    const {
      open,
      engagement,
      stages,
      statuses,
      states,
      matchStrengths,
      rejectionReasons,
    } = this.state;
    const {
      stage,
      status,
      state,
      matchStrength,
      rejectionReason,
      rejectionReasonAdditionalInfo,
    } = engagement;
    return <Dialog
      open={open}
      title="Update Engagement"
      content={
        <>
          <div className="Modal-block">
            Stage
            <Menu dropdown
              name="stage"
              value={stage}
              onChange={this.setAttr('stage')}
              options={stages}
            />
          </div>
          <div className="Modal-block">
            Status
            <Menu dropdown
              name="status"
              value={status}
              onChange={this.setAttr('status')}
              options={statuses}
            />
          </div>
          <div className="Modal-block">
            State
            <Menu dropdown
              name="state"
              value={state}
              onChange={this.setAttr('state')}
              options={states}
            />
          </div>
          <div className="Modal-block">
            Strength
            <Menu
              name="matchStrength"
              value={matchStrength}
              onChange={this.setAttr('matchStrength')}
              options={matchStrengths}
            />
          </div>
          <div className="Modal-block">
            Rej Reason
            <Menu dropdown
              name="rejectionReason"
              value={rejectionReason}
              onChange={this.setAttr('rejectionReason')}
              style={{ float: "right" }}
              options={rejectionReasons}
            />
          </div>
          <div className="Modal-block">
            RR-Add Info &nbsp;
            <TextField
              name={`rejectionReasonAdditionalInfo`}
              value={!!rejectionReasonAdditionalInfo ? rejectionReasonAdditionalInfo : ""}
              onChange={this.setAttr('rejectionReasonAdditionalInfo')}
              multiLine={true}
              rowsMax={5}
            />
          </div>
        </>
      }
      actions={[
        <Button outlined minW120
          label="Cancel"
          onClick={this.close}
        />,
        <Button primary minW120
          label="Submit"
          onClick={this.submit}
        />
      ]}
      paperStyle={{ minHeight: sizes.m5, width: sizes.m6 }}
      alignLeft
    />
  }
}

export default ManageEngagement;
