
export function mapCandidateInfoForCSV(candidate) {
  let {
    firstName,
    lastName,
    _name,
    email,
    phone,
    linkedInURL,
    isDuplicate,
    _platformRating,
    yearsOfExperience,
    _visa,
    _technicalSkills,
    _positiveSignals,
    _negativeSignals,
    _recruiterName,
    _introduced,
    _updatedAt,
    _roles,
    __exportDate,
    __exportOwner
  } = candidate;
  return {
    'Date': __exportDate,
    'First name': firstName,
    'Last name': lastName,
    'Full name': _name,
    'Email': email,
    'Phone': phone,
    'LinkedIn': linkedInURL,
    'Rating': _platformRating,
    'YoE': yearsOfExperience,
    'Roles': _roles,
    'Visa': _visa,
    'Tech skills': _technicalSkills,
    'Positive': _positiveSignals,
    'Negative': _negativeSignals,
    'Recruiter name': _recruiterName,
    'Duplicate': isDuplicate,
    'Introduced': _introduced,
    'Updated': _updatedAt,
    'Source': __exportOwner
  };
}
