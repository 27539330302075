import {
  mapJobVisaToCandidatePresets
} from '../../../../../../Home/mapVisaPresets.lib';

/**
 * Evaluates if a candidate's visa matches the job's visa requirements.
 *
 * @param {Object} params - The parameters for evaluating the visa match.
 * @param {Object} params.job - The job object containing visa requirements.
 * @param {Object} params.candidate - The candidate object containing visa information.
 * @returns {boolean} - Returns true if the candidate's visa matches the job's visa requirements, false otherwise.
 */
export default function evalVisaMatch({
  job = {},
  candidate = {},
}) {
  const map = {};
  mapJobVisaToCandidatePresets({ job, presets: map });
  return map.visa.includes(candidate.visa);
}
