import {
  uniqueId
} from 'lodash';
import Button from './Button';
import Menu from './Menu';

export default function Dropdown({
  name = `${Date.now()}__${uniqueId} `,
  onChange = (value) => null,
  data = [],
  value = '',
  ...props
}) {
  return (
    <Menu dropdown
      name={name}
      options={data}
      value={value}
      onChange={async (value) => onChange(value || null)}
      paperStyle={{ minWidth: 240 }}
      renderSelected={({ selected = {}, open }) => (
        <Button
          className='tt-unset bg-white space-between h-100 w-100 nowrap'
          variant='outlined'
          size='small'
          color={open ? 'primary' : 'gray'}
          endIcon={open ? 'expand_less' : 'expand_more'}
        >
          <span className={open ? 'inherit' : 'c-black-medium'}>{selected.label || <>⸺</>}</span>
        </Button>
      )}
    />
  );
}