import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import {
  Input
} from 'antd';
import {
  useState
} from 'react';
import {
  getEducationConfigDefaultModel,
  useEducationConfig
} from '../../lib/services/Configurations/Configurations.lib';
import Paper, {
  getDefaultPaperStyle
} from '../Layout/Wrappers/Paper';

const { TextArea } = Input;

export default function MainConfiguration(props) {
  let [state, setState] = useState({});
  async function updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }
  function setExpanded(panel) {
    return (event, isExpanded) => updateState({ panel: isExpanded ? panel : false });
  }
  let { panel = 'EducationTags' } = state;
  let { configuration, loadingConfig, errorConfig, fetchConfig, updateConfig } = useEducationConfig();
  if (!configuration && !loadingConfig && !errorConfig) { fetchConfig(); }
  let _configuration = configuration || getEducationConfigDefaultModel();
  let {
    exclusionList = [],
    stemList = [],
    computerList = [],
    bootcampList = []
  } = _configuration;
  function onChange(attribute) {
    return event => {
      state[attribute] = event.target.value;
      updateState(state);
    }
  }
  function onBlur(attribute) {
    return event => {
      _configuration[attribute] = event.target.value.split(/\n|,/).map(v => v.trim()).filter(v => !!v).sort();
      state[attribute] = _configuration[attribute].join('\n');
      updateState(state);
      updateConfig(_configuration);
    }
  }
  return (
    <div className="flex-column vh-100">
      <Paper style={getDefaultPaperStyle()} className='mx-auto'>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='c-purple m-0 p-2'>
          Configurations
        </Typography>
        <Accordion
          expanded={(panel === 'EducationTags')}
          onChange={setExpanded('EducationTags')}
          className='m-0'
        >
          <AccordionSummary
            expandIcon={<i className='material-icons'>expand_more</i>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <strong className='f-xl c-black'>
              Candidate Education Auto-Tagging
            </strong>
          </AccordionSummary>
          <AccordionDetails className='overflow-hidden'>
            <div className='d-flex flex-wrap'>
              <div className='flex-column flex-1 mr-1' style={{ minWidth: 160 }}>
                <strong className='f-lg c-cyan-darker'>Exclusions</strong>
                <small className='c-black-medium truncate'>Keywords to exclude from auto-tagging</small>
                <TextArea
                  placeholder='Type here'
                  value={state.exclusionList || exclusionList.join('\n')}
                  autoSize={{ minRows: 1, maxRows: 12 }}
                  onChange={onChange('exclusionList')}
                  onBlur={onBlur('exclusionList')}
                />
              </div>
              <div className='flex-column flex-1 mr-1' style={{ minWidth: 160 }}>
                <strong className='f-lg c-cyan-darker'>STEM</strong>
                <small className='c-black-medium truncate'>Keywords on major for STEM</small>
                <TextArea
                  placeholder='Type here'
                  value={state.stemList || stemList.join('\n')}
                  autoSize={{ minRows: 1, maxRows: 12 }}
                  onChange={onChange('stemList')}
                  onBlur={onBlur('stemList')}
                />
              </div>
              <div className='flex-column flex-1 mr-1' style={{ minWidth: 160 }}>
                <strong className='f-lg c-cyan-darker'>Computer</strong>
                <small className='c-black-medium truncate'>Keywords on major for Computer</small>
                <TextArea
                  placeholder='Type here'
                  value={state.computerList || computerList.join('\n')}
                  autoSize={{ minRows: 1, maxRows: 12 }}
                  onChange={onChange('computerList')}
                  onBlur={onBlur('computerList')}
                />
              </div>
              <div className='flex-column flex-1' style={{ minWidth: 160 }}>
                <strong className='f-lg c-cyan-darker'>Bootcamp</strong>
                <small className='c-black-medium truncate'>Keywords on school-name for Bootcamp</small>
                <TextArea
                  placeholder='Type here'
                  value={state.bootcampList || bootcampList.join('\n')}
                  autoSize={{ minRows: 1, maxRows: 12 }}
                  onChange={onChange('bootcampList')}
                  onBlur={onBlur('bootcampList')}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
}
