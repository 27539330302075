import { Button } from '@mui/material';
import { useState } from 'react';
import { Arr } from '../../../lib/Array.lib';
import { CSS_GRAY_RGB } from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Employer from '../../../lib/Employer';
import { NOT, YES } from '../../../lib/GenericTools.lib';
import { Obj, safeStringify } from '../../../lib/Object.lib';
import {
  FirstWord,
  compileText,
  trim
} from '../../../lib/String.lib';
import {
  getNewEmployerEmailTemplateStructure
} from '../../../lib/models/employerEmailTemplate.model';
import {
  TEN_BY_TEN_RECRUITER_EMAIL_OBJECT,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import EmailPreview, {
  EMAIL_DIALOG_PANEL__EDIT
} from '../../Dialogs/EmailPreview';
import Loader from '../../Shared/Loader';
import {
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME,
  EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME
} from '../EmployerManageTemplates/EmployerManageTemplatesContent';
import { EmployerTemplateBar } from '../EmployerManageTemplates/EmployerTemplateBar';
import {
  EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK
} from './EmployerPendings.lib';

export function MessageEmployerToCandidate(props) {
  let { context: { selected, employer }, appendText } = props;
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  if (!employer?.id) { return; }
  if (NOT(Obj(Arr(Obj(employer.emailTemplate).list)[0]).templateId)) {
    employer.emailTemplate = getNewEmployerEmailTemplateStructure();
  }
  let emailTemplateRecord = employer.emailTemplate.list[0];

  async function _onSend(params) {
    Object.assign(emailTemplateRecord, {
      subject: params.subject,
      greetings: params.greetings,
      body: params.html
    });
    return Employer.update(employer.id, {
      emailTemplate: { ...employer.emailTemplate, list: [emailTemplateRecord] }
    }).catch(Core.showError);
  }
  const userFirstname = FirstWord(Core.getUserName());
  const greetings = '';
  const prefix = compileText(trim(`
    <i style="color: {{COLOR}}">
      Sent by 10 By 10 on behalf of {{USER__NAME}} from {{EMPLOYER__NAME}}.<br/>
      Please reply all to make sure you respond to both the employer and 10 By 10
    </i>
  `), {
    COLOR: CSS_GRAY_RGB,
    USER__NAME: Core.getUserName(),
    EMPLOYER__NAME: employer.name
  });
  const isCalibration = (selected.engagementKey === EMPLOYER__PROCESSING_MODEL_KEY__CALIBRATION_FEEDBACK);
  const _onUserEdit = async () => {
    try {
      const emailSent = safeStringify(selected.emailSent);
      const currentEmail = safeStringify(Core.getKeyValue('MessageEmployerToCandidate_EmailPreview').getParams());
      console.debug('D5', (emailSent !== currentEmail), emailSent, currentEmail);
      if (emailSent && currentEmail && (emailSent !== currentEmail)) {
        delete selected.emailSent;
        await _updateState();
      }
    } catch { }
  }

  return (
    <>
      <EmailPreview
        ref={self => Core.setKeyValue('MessageEmployerToCandidate_EmailPreview', self)}
        {...({
          editDisclaimer: (
            <div className='f-md f-italic c-black-medium mb-2'>
              Edit email to candidate and manage shared templates.
            </div>
          ),
          previewDisclaimer: (
            <div className='f-md f-italic c-black-medium mb-2'>
              Preview email to candidate.
            </div>
          ),
          to: isCalibration ? [] : [
            {
              accountType: 'Candidate Contact',
              name: selected.candidate._name,
              email: selected.candidate.email
            }
          ],
          cc: isCalibration ? [] : [
            {
              accountType: 'User',
              name: Core.getUserName(),
              email: Core.getSessionEmail()
            },
            TEN_BY_TEN_RECRUITER_EMAIL_OBJECT
          ],
          templateBar: (
            <EmployerTemplateBar
              employerId={employer.id}
              templateId={emailTemplateRecord.templateId}
              onChange={async template => {
                Core.getKeyValue('MessageEmployerToCandidate_EmailPreview').setTemplate(template);
                Object.assign(emailTemplateRecord, {
                  templateId: template.id,
                  subject: template.subject,
                  body: template.body
                });
                _onUserEdit();
              }}
              macros={{
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__MY_FIRSTNAME]: userFirstname,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__NICKNAME]: selected.candidate.nickName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__FIRSTNAME]: selected.candidate.firstName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__CANDIDATE__LASTNAME]: selected.candidate.lastName,
                [EMPLOYER_MANAGE_TEMPLATES_TOKEN__EMPLOYER_NAME]: employer.name,
              }}
            />
          ),
          subject: compileText(
            trim(emailTemplateRecord.subject),
            { EMPLOYER__NAME: employer.name || '[ employer_name ]' }
          ),
          prefix,
          // greetings,
          body: compileText(
            (`${trim(emailTemplateRecord.body)}${trim(appendText)}`),
            { USER__FIRSTNAME: userFirstname || '[ your name ]' }
          ),
          copyText: `$to $from $subject $body`,
          showFrom: true,
          panel: EMAIL_DIALOG_PANEL__EDIT,
          enablePanels: true,
          onUserEdit: _onUserEdit
        })}
      />
      <div className='bg-white sticky-bottom p-1'>
        <Button
          variant='contained'
          size='small'
          className='min-w-160'
          disabled={(
            state.busy ||
            isCalibration ||
            YES(selected.emailSent)
          )}
          onClick={async event => {
            _updateState({ busy: true });
            let params = Core.getKeyValue('MessageEmployerToCandidate_EmailPreview').getParams();
            await sendSafeEmail({
              ...params,
              html: (
                `${!!prefix ? `${prefix}<hr/>` : ''}${!!greetings ? `<div>${greetings}</div>` : ``}${params.html || ''}`
              ),
              boxKey: selected.boxKey,
              source: 'Email.lib.js'
            });
            await _onSend(params);
            selected.emailSent = params;
            await _updateState({ busy: false });
          }}
        >
          {state.busy ? <Loader>Sending...</Loader> : selected.emailSent ? 'Email sent' : 'Send'}
        </Button>
      </div>
    </>
  );
}
