import {
  Arr
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import useState from '../../lib/hooks/useState.hook';
import {
  Obj
} from '../../lib/Object.lib';
import {
  getLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import Button from '../Layout/Wrappers/Button';
import Menu from '../Layout/Wrappers/Menu';

const CONFIG = {
  collection: 'PrescreenQuestionTemplates',
};

export function PrescreenQuestionSelector({
  onChange = async (selected) => null,
}) {
  const [{ selected = {}, data = [] }, _updateState] = useState();
  const _fetchData = async () => {
    console.debug('D1');
    try {
      console.debug('D2');
      const r = await getLoopbackRecord({
        collection: CONFIG.collection
      })
      console.debug('D3', r)
      await _updateState({
        data: Arr(r)
      });
      console.debug('D5');
    }
    catch (error) {
      console.debug('D6');
      Core.showError(error);
    }
    console.debug('D7');
  }
  return (
    <div className='d-flex flex-align-left-center nowrap' style={{ height: 32 }}>
      <span className='mr-1 f-md fw-500 c-black-medium'>Add a question from bank:</span>
      <Menu
        name='emp_pre_scr_sel_frm_bnk'
        options={data}
        value={selected.id}
        onChange={async (value) => {
          const selected = Obj(data.find(e => e.id === value));
          delete selected.label;
          await _updateState({ selected });
          onChange(selected);
        }}
        onClick={_fetchData}
        renderSelected={
          ({ selected = {}, open }) => (
            <Button
              className='tt-unset space-between h-100 w-100'
              style={{ minWidth: 256, maxWidth: 320, height: 24 }}
              variant='outlined'
              size='small'
              color={open ? 'inherit' : 'primary'}
              endIcon={
                <i className={`material-icons c-inherit`}>{open ? 'expand_less' : 'expand_more'}</i>
              }
            >
              <div className='truncate'>
                {selected.question || <>⸺</>}
              </div>
            </Button>
          )
        }
        renderOption={({ option }) => (
          <div className='truncate' style={{ minWidth: 256, maxWidth: 512 }}>
            {option.question}
          </div>
        )}
      />
    </div>
  );
}
