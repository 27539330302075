import {
  Component
} from "react";
import Core from "../../lib/Core";
import Debug from "../../lib/Debug.lib";
import {
  ACCOUNT_ACTION__EDIT_EMPLOYER
} from '../../lib/Definition';
import Employer from "../../lib/Employer";
import Store from "../../lib/Store";
import { getLocation } from '../../lib/URL.lib';
import FilterControl from "../FilterControl/FilterControl";
import {
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import NavLink from '../Layout/Wrappers/NavLink';
import Snackbar from '../Layout/Wrappers/Snackbar';
import List from "../List/ListNoPaging";
import EmployerCard from "./Card/EmployerCard";

let attempt = 0;

class Employers extends Component {
  data;
  constructor() {
    super(...arguments);
    this.name = "Employers";
    this.state = {
      snackBarMessage: "",
      snackBarOpen: false,
      showAll: false
    };
    Store.set("path", getLocation());
    this.reloadData = ev => this.loadData();
    this.loadData();
  }
  loadData() {
    setTimeout(st => {
      if (this.filterControl) {
        Employer.getAll(
          employers =>
            this.filterControl &&
            this.filterControl.setItems(employers, /^Active$/)
        );
      }
      else {
        if (attempt < 10) {
          attempt++;
          Debug.log({ missingFilterControl: true });
          this.loadData();
        }
      }
    });
  }
  render() {
    return (
      <Box column h100 w100>

        <FilterControl
          Controller={this}
          menus={Employer.menus}
          more={Employer.more}
          onChange={filtered => this.List.setItems(filtered)}
          toolBarRight={
            Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) && (
              <NavLink to="/employer/create" className="ui-m-min">
                <Button primary small
                  label="+ New Employer"
                  className='mr-1 c-white c-white-hover'
                />
              </NavLink>
            )
          }
        />

        <Box column h100 w100 scrollY>

          <List
            ref={List => (this.List = List)}
            tabs={Employer.listTabs}
            tab={Employer.listTab}
            name="Employer"
            card={EmployerCard}
            parent={this}
            floatingButton={
              Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) && (
                <NavLink className="ui-m-max" to={`/employer/create`}>
                  <FloatingActionButton
                    className="list-floating-button"
                    color={THEME__COLOR__SECONDARY}
                  />
                </NavLink>
              )
            }
          />

        </Box>

        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          autoHideDuration={7000}
          onClose={this.hideMessage}
        />

      </Box>
    );
  }
}

export default Employers;
