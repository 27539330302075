import {
  Arr
} from '../../../../lib/Array.lib';
import {
  NOT
} from '../../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__TECHNICAL_SKILLS,
  DEFINITION_GROUP_ID__TECHNICAL_SKILLS__BACKEND,
  DEFINITION_GROUP_ID__TECHNICAL_SKILLS__CLOUD_TECH,
  DEFINITION_GROUP_ID__TECHNICAL_SKILLS__FRONTEND,
  DEFINITION_GROUP_ID__TECHNICAL_SKILLS__NO_SQL,
  DEFINITION_GROUP_ID__TECHNICAL_SKILLS__SQL_TECH
} from '../../../../lib/Definition';
import {
  fetchTagIdEmployerSkills
} from './EmployerSkills.lib';
import {
  fetchTagIdJobSkills
} from './JobSkills.lib';

export default async function getCandidateResumePdfAnnotations({
  jobId = '',
  employerId = ''
}) {
  const annotations = [];
  const jobSkillIds = await fetchTagIdJobSkills({ jobId });
  const employerSkillIds = await fetchTagIdEmployerSkills({ employerId });
  const groupSkillIds = Arr(
    Arr(
      Definition.getRawDef(
        DEFINITION_CATEGORY__TECHNICAL_SKILLS
      ).groups
    ).filter(
      ({ id: groupId }) => (
        [
          DEFINITION_GROUP_ID__TECHNICAL_SKILLS__FRONTEND,
          DEFINITION_GROUP_ID__TECHNICAL_SKILLS__BACKEND,
          DEFINITION_GROUP_ID__TECHNICAL_SKILLS__NO_SQL,
          DEFINITION_GROUP_ID__TECHNICAL_SKILLS__SQL_TECH,
          DEFINITION_GROUP_ID__TECHNICAL_SKILLS__CLOUD_TECH,
        ].includes(groupId)
      )
    ).map(
      ({ ids }) => (ids)
    )
  ).flat();

  // JOB skills
  annotations.push({
    keywords: Definition.getLabels(
      DEFINITION_CATEGORY__TECHNICAL_SKILLS,
      jobSkillIds
    ),
    action: 'highlight',
    intensity: 'light',
    color: 'red'
  });

  // EMPLOYER skills minus JOB skills
  annotations.push({
    keywords: Definition.getLabels(
      DEFINITION_CATEGORY__TECHNICAL_SKILLS,
      employerSkillIds.filter(
        (id) => NOT(jobSkillIds.includes(id))
      )
    ),
    action: 'highlight',
    intensity: 'light',
    color: 'yellow'
  });

  // GROUP skills minus JOB AND EMPLOYER SKILLS
  annotations.push({
    keywords: Definition.getLabels(
      DEFINITION_CATEGORY__TECHNICAL_SKILLS,
      groupSkillIds.filter(
        (id) => NOT([...jobSkillIds, ...employerSkillIds].includes(id))
      )
    ),
    action: 'highlight',
    intensity: 'light',
    color: 'green'
  });

  return annotations;
}
