import React, {
  Component
} from "react";
import {
  trim
} from '../../../lib/String.lib';
import {
  matchSkillWithText
} from '../../Candidates/SubmissionNotes.lib';
import Chip from '../../Layout/Wrappers/Chip';
import {
  getEvaluationChipStyle
} from '../Haves/v2/Evaluations.lib';

class LocationDetails extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
  }
  getDisplayColor = () => {
    const {
      candidateText,
      jobText
    } = this.props;
    if (!candidateText | !jobText) {
      return null;
    }
    if (candidateText.length > jobText.length) {
      return matchSkillWithText(jobText, candidateText);
    }
    else if (candidateText.length < jobText.length) {
      return matchSkillWithText(candidateText, jobText);
    }
    else if (candidateText === jobText) {
      return true;
    }
    return false;
  }
  getDisplayLabel = () => {
    const {
      candidateText,
      jobText,
      source
    } = this.props;
    let label = null;
    if (source === 'candidate') {
      return candidateText;
    }
    else if (source === 'job') {
      return jobText;
    }
    return label;
  }
  render() {
    const { candidateText, jobText, source } = this.props;
    if (source === 'candidate' && !candidateText) {
      return null;
    }
    if (source === 'job' && !jobText) {
      return null;
    }
    const _label = this.getDisplayLabel();
    return (
      <Chip slim outlined small
        label={_label}
        title={trim(_label)}
        style={getEvaluationChipStyle(this.getDisplayColor())}
      />
    );
  }
}

export default LocationDetails;