/**
 * HTML template for notifying the approval of a candidate for an interview, including a link box to the employer portal.
 *
 * This template generates a screen that displays:
 * - A portal link box (`TEMPLATE__EMPLOYER_PORTAL__LINK_BOX`) for accessing the employer portal.
 * - A message indicating that a candidate has been approved for an interview.
 * - A list of details about the candidate, job, and approval information.
 *
 * The template uses the following dynamic variables:
 * - `CANDIDATE`: The full name of the approved candidate.
 * - `JOB`: The title of the job the candidate is applying for.
 * - `APPROVER`: The name of the person who approved the candidate.
 * - `DATE`: The date when the candidate was approved.
 *
 * @constant {string} TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__BODY - The HTML template for the candidate approval notification screen with employer portal link box.
 */
const TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__BODY = (`
  <div>
    A candidate has been approved. Please proceed with scheduling the interview.
  </div>
  <blockquote>
    <div>Candidate: <b>{{CANDIDATE}}</b></div>
    <div>Job Title: {{JOB}}</div>
    <div>Approved by: {{APPROVER}}</div>
    <div>Approval Date: {{DATE}}</div>
  </blockquote>
`);
export default TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__BODY;

export const TEMPLATE__TRANSITION__SCREEN__W_EMP_SCHEDULE__SUBJECT = (
  '[ 10 BY 10 bot ] Action Needed: Schedule Interview for Approved Candidate - {{CANDIDATE}}'
);
