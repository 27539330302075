import {
  Component,
  Fragment
} from "react";
import {
  withTranslation
} from "react-i18next";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../../lib/Array.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Core from "../../../../lib/Core";
import Definition, {
  ATS_TYPE__GH_HARVEST_ID,
  ATS_TYPE__GH_INGESTION_ID,
  ATS_TYPE__NONE_ID,
  EMPLOYER_SUBMISSION_METHOD__ATS
} from "../../../../lib/Definition";
import {
  EMPLOYER__SCHEDULING__EMPLOYER_LINK,
  EMPLOYER__SCHEDULING__EMPLOYER_MANUAL,
  EMPLOYER__SCHEDULING__TEN_BY_TEN
} from '../../../../lib/Employer';
import {
  getAtsProviderModel
} from "../../../../lib/models/employer";
import {
  Obj
} from '../../../../lib/Object.lib';
import Greenhouse from "../../../../lib/services/ATS/Greenhouse.lib";
import Chips from "../../../Forms/Chips";
import {
  JOB_SECTION__EMPLOYER__ADDITIONAL_INFO,
  JOB_SECTION__INTERVIEW_PROCESSES,
  JOB_SECTION__PUBLIC_NOTES, openJobView
} from '../../../Jobs/Card/JobDetails';
import Autocomplete from '../../../Layout/Wrappers/Autocomplete';
import Button from '../../../Layout/Wrappers/Button';
import Checkbox from '../../../Layout/Wrappers/Checkbox';
import Col from "../../../Layout/Wrappers/Col";
import DatePicker from '../../../Layout/Wrappers/DatePicker';
import Divider from '../../../Layout/Wrappers/Divider';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import Menu from '../../../Layout/Wrappers/Menu';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import TextField from '../../../Layout/Wrappers/TextField';
import {
  PrescreenQuestionsEdit
} from '../../../PrescreenQuestions/PrescreenQuestionsSection';
import InternalSubmissionInstructions from '../Forms/InternalSubmissionInstructions';
import { THEME__VARIANT__STANDARD } from '../../../Layout/Libraries/Theme.lib';
import InputNumber from '../../../Layout/Wrappers/InputNumber';
import Icon from '../../../Layout/Wrappers/Icon';

class Process extends Component {
  render() {

    const self = this;

    const EmployerEditController = this.props.parent;

    const { t } = this.props;

    const {
      state,
    } = EmployerEditController;

    const {
      atsTypeId,
      atsUrl,
      atsApiProviders = {},
      submissionMethods = [],
      validatingConnection = false,
      atsSourceNameWarning,
      atsUserNameWarning,
      atsUserIdWarning,
      atsUserName,
      atsUserEmail,
      atsUserDisabled,
    } = state;

    const locationTags = Definition.get("location");

    const atsTypes = (
      Definition.get("aTSTypes")
        .sort(
          (a, b) =>
            String(a.label)
              .localeCompare(String(b.label))
        )
    );

    const isAtsSelected = (
      !!atsTypeId &&
      (atsTypeId !== ATS_TYPE__NONE_ID)
    );

    const atsApiProvider = (
      atsApiProviders[atsTypeId] ||
      getAtsProviderModel({ atsTypeId })
    );

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:Process', { atsApiProvider });
    /** */

    const {
      /** common for greenhouse */
      apiKey,
      manualSubmissionUrl,
      /** specific for greenhouse harvest */
      onBehalfOfEmployerUserName = '',
      onBehalfOfEmployerUserId,
      sourceIdAgencyId,
      agencyName = '',
      agencyRecruiterName,
      /** specific for greenhouse ingestion */
      onBehalfOfEmployerUser,
    } = atsApiProvider;

    const isAtsValidateConnectionDisabled = !(
      !!apiKey?.trim() &&
      !!agencyName?.trim() &&
      (!!onBehalfOfEmployerUserName?.trim() || !!onBehalfOfEmployerUserId)
    );

    const isAtsGhHarvestSelected = (
      atsTypeId === ATS_TYPE__GH_HARVEST_ID
    );

    const isAtsUserInfoAvailable = (
      isAtsGhHarvestSelected &&
      (atsUserName || atsUserEmail)
    );

    const isAtsGhIngestionSelected = (
      atsTypeId === ATS_TYPE__GH_INGESTION_ID
    );

    const isAtsMethodIncluded = (
      submissionMethods.includes(EMPLOYER_SUBMISSION_METHOD__ATS)
    );

    function onChangeSelectAtsType(atsTypeId) {
      EmployerEditController.setState({
        /**
         * @todo
         * Review patch code for "ats" field
         * after 2021-06-01
         * to determine utility of maintain it.
         *
         * @see employer.patchATS | model library
         */
        ats: String(atsTypeId),
        atsTypeId
      });
      try { Core.getKeyValue('EmployerSettingsButton').update({ atsTypeId }); }
      catch (error) { console.debug('Employers > Forms > Process : error', error); }
    }

    function onChangeAtsProviderInput(field) {
      return (event, value) => {
        EmployerEditController.setState(({
          atsApiProviders
        }) => {
          atsApiProvider[field] = value;
          atsApiProviders[atsTypeId] = atsApiProvider;
          if (field === 'manualSubmissionUrl') {
            return {
              /**
               * @todo
               * Review patch code
               * for "atsUrl" field
               * after 2021-06-01
               * to determine utility
               * of maintain it.
               * Ask to BB.
               *
               * @see employer.patchATS - model library
               */
              atsUrl: value,
              atsApiProviders
            };
          }
          else {
            return {
              atsApiProviders
            };
          }
        });
      }
    }

    function onGreenhouseFailure(failures, suggestions, statusCode) {

      EmployerEditController.setState({ validatingConnection: false });

      Core.dialog.open({
        title: `Greenhouse API connection failed`,
        message: (
          <Fragment>
            {(
              statusCode
                ? <h4>Error code: {statusCode}</h4>
                : ''
            )}
            <h4>Error messages:</h4>
            <small>
              <b>
                <pre>{failures.join('\n\n')}</pre>
              </b>
            </small>
            <h4>Suggestions for taking action:</h4>
            <small>
              <b>
                <i>
                  <pre>{suggestions.join('\n')}</pre>
                </i>
              </b>
            </small>
          </Fragment>
        )
      });
    }

    async function getGreenhouseSourceId() {
      return await Greenhouse.harvest.getSourceId({
        apiKey,
        agencyName,
        onSuccess({
          sourceIdAgencyId = null,
          sourceName = ''
        }) {
          if (sourceName.toLowerCase() === agencyName.toLowerCase()) {
            onChangeAtsProviderInput('sourceIdAgencyId')(
              null, sourceIdAgencyId
            );
          }
          else {
            EmployerEditController.setState({
              atsSourceNameWarning: `Found "${sourceName}" for agency name rather than one provided "${agencyName}".`
            });
          }
        },
        onFailure: onGreenhouseFailure
      });
    }

    async function getGreenhouseUserById({ atsUserId }) {
      return await Greenhouse.harvest.getUserById({
        apiKey,
        userId: atsUserId,
        onSuccess({
          id,
          name: atsUserName,
          first_name,
          last_name,
          primary_email_address: atsUserEmail,
          updated_at,
          created_at,
          disabled: atsUserDisabled,
          site_admin,
          emails = [],
          employee_id,
          linked_candidate_ids = [],
        }) {
          /**
            * @note
            * The Id is the important value for us,
            * so we will prefer the user got it by id,
            * over the one got it by name.
            * For that reason here is not a conditional,
            * as it is on getGreenhouseUserByName > onSuccess
            */
          onChangeAtsProviderInput('onBehalfOfEmployerUserName')(
            null, atsUserName
          );
          EmployerEditController.setState({
            atsUserName,
            atsUserEmail,
            atsUserDisabled,
          });
        },
        onFailure(failures, suggestions, statusCode) {
          EmployerEditController.setState({
            atsUserIdWarning: `User not found, please review this user id is right.`
          }, () => {
            onGreenhouseFailure(failures, suggestions, statusCode);
          });
        }
      });
    }

    function getGreenhouseUserByName() {
      return new Promise(function (resolve, reject) {
        Greenhouse.harvest.getUserByName({
          apiKey,
          userName: onBehalfOfEmployerUserName,
          onSuccess(user) {
            if (!onBehalfOfEmployerUserId) {
              onChangeAtsProviderInput('onBehalfOfEmployerUserId')(
                null, user.id
              );
            }
            console.debug({ user });
            EmployerEditController.setState({
              atsUserName: user.name,
              atsUserEmail: user.primary_email_address,
              atsUserDisabled: user.disabled,
            }, state => {
              resolve(user);
            });
          },
          onFailure: (failures, suggestions, statusCode) => {
            onGreenhouseFailure(failures, suggestions, statusCode);
            EmployerEditController.setState({ atsUserNameWarning: failures[0] });
            reject({ failures, suggestions, statusCode });
          }
        });
      });
    }

    function updateValidations(update) {
      if (update) {
        self.validations = {
          ...self.validations,
          ...update
        };
      }
      else {
        self.validations = {};
        EmployerEditController.setState({
          validatingConnection: true,
          atsSourceNameWarning: null,
          atsUserNameWarning: null,
          atsUserIdWarning: null,
          atsUserName: null,
          atsUserEmail: null,
          atsUserDisabled: null,
        },
          state => onChangeAtsProviderInput('sourceIdAgencyId')()
        );
      }
    }

    async function onClickValidateConnectionButton(event) {

      /**
       * Starting
       * - Set loading flag.
       * - Clean warning text.
       * - Clean state values to get from GH.
       */
      updateValidations();

      /**
       * Make calls
       */
      let atsUser;
      if (!!onBehalfOfEmployerUserName.trim()) {
        atsUser = await getGreenhouseUserByName();
      }

      const atsUserId = onBehalfOfEmployerUserId || atsUser?.id;
      if (!!atsUserId) {
        await getGreenhouseUserById({ atsUserId });
      }

      await getGreenhouseSourceId();

      /**
       * Done
       */
      EmployerEditController.setState({
        validatingConnection: false
      });

    }

    /** µ FOR DEBUG PURPOSES * /
    console.debug('µ:Employer:Process:render', { state });
    console.debug('µ:Employer:Process:render', { atsApiProvider });
    /**  */

    return (
      <div className="p-1">
        <div className="p-2 f-xl fw-500">
          PROCESS
        </div>

        <Row>

          <Col>

            <Chips
              name="employerSubmissionMethod"
              label={<label>Submission Method(s)</label>}
              sub="Please select all the submission methods your are interested in"
              values={submissionMethods}
              onChange={submissionMethods => {
                EmployerEditController.setState({ submissionMethods });
              }}
            />

          </Col>

          <Fieldset
            title={
              <>Who will primarily schedule interviews?</>
            }
            subtitle={
              <>If employer link is selected, the auto reminder will prompt the candidate to select a time from the booking link. Otherwise, ask the candidate to provide availability</>
            }
          >
            <Menu dropdown
              name="scheduling"
              value={EmployerEditController.state.scheduling}
              onChange={(scheduling) => EmployerEditController.setState({ scheduling })}
              options={[
                EMPLOYER__SCHEDULING__EMPLOYER_MANUAL,
                EMPLOYER__SCHEDULING__EMPLOYER_LINK,
                EMPLOYER__SCHEDULING__TEN_BY_TEN
              ]}
            />
          </Fieldset>

        </Row>

        {isAtsMethodIncluded && (

          <div className="bg-main pb-2 rounded-sm">

            <Row className='mt-3'>

              <Col>
                <label>What ATS is the company using?</label>
                <small className="c-black-medium">Choose one from the dropdown</small>
                <Menu dropdown
                  name="atsTypeId"
                  value={atsTypeId}
                  onChange={onChangeSelectAtsType}
                  options={atsTypes}
                />
              </Col>

              {isAtsSelected && (
                <div className='w-50 px-2'>
                  <div className='d-flex flex-align-left-bottom mt-1 w-100'>
                    <div className='mr-auto fw-500 f-lg c-cyan-darker mr-auto'>ATS URL</div>
                    {Core.isAdmin() && state.id && (
                      <Button flat
                        onClick={event => Core.openPopUp(Core.getPath(`admin/ats/mapping/${state.id}`), 1200)}
                        className='tt-unset c-black-medium'
                        size='small'
                        startIcon={
                          <i className='material-icons c-inherit'>launch</i>
                        }
                      >
                        Edit ATS mapping rules
                      </Button>
                    )}
                  </div>
                  <div className='f-sm c-black-medium'>
                    The URL to open for submission to ATS in case the Auth is not available.
                  </div>
                  {/** µ TO REVIEW 2021-04-29 */}
                  {!manualSubmissionUrl && !!atsUrl && (
                    <small className="c-red d-block">
                      This value is coming from deprecated field,
                      please edit it then save to being use it,
                      for selected ATS provider!
                    </small>
                  )}
                  {/** */}
                  <TextField
                    name="_atsUrl"
                                        /**

                      NOTES
                      ---

                      atsUrl
                      - It is the old singleton field.

                      manualSubmissionUrl
                      - It is the new nested field
                        for submission providers
                        object.

                      > Review it in future
                      for cleanup.

                      2021-04-07 µ

                    */
                    value={manualSubmissionUrl || atsUrl}
                    onChange={onChangeAtsProviderInput('manualSubmissionUrl')}
                    style={{ marginBottom: '0.75rem' }}
                    fullWidth
                  />
                </div>
              )}

            </Row>

            <Row>

              {(isAtsGhHarvestSelected || isAtsGhIngestionSelected) && (
                <Col>
                  <label>API key</label>
                  <small className="c-black-medium">Authentication credential to ATS.</small>
                  <TextField
                    name="_atsApiKey"
                                        value={apiKey}
                    onChange={onChangeAtsProviderInput('apiKey')}
                    fullWidth
                  />
                </Col>
              )}

              {isAtsGhIngestionSelected && (
                <Col>
                  <label>On-Behalf-Of</label>
                  <small className="c-black-medium">User name issuing this request.</small>
                  <TextField
                    name="_atsOnBehalfOf"
                                        value={onBehalfOfEmployerUser}
                    onChange={onChangeAtsProviderInput('onBehalfOfEmployerUser')}
                    fullWidth
                  />
                </Col>
              )}

            </Row>

            {isAtsGhHarvestSelected && (
              <Fragment>

                <Row>

                  <Col>
                    <label>On-Behalf-Of Name</label>
                    <small className="c-black-medium">
                      Name of the user issuing this request.
                      {atsUserEmail && (<>&nbsp;<b>({atsUserEmail})</b></>)}
                    </small>
                    <small className="c-red d-block">
                      {atsUserNameWarning}
                    </small>
                    <TextField
                      name="_atsOnBehalfOfName"
                                            value={onBehalfOfEmployerUserName}
                      onChange={onChangeAtsProviderInput('onBehalfOfEmployerUserName')}
                      fullWidth
                    />
                  </Col>

                  <Col>
                    <label>On-Behalf-Of ID</label>
                    <small className="c-black-medium">
                      ID of the user issuing this request.
                    </small>
                    <small className="c-red d-block">
                      {atsUserIdWarning}
                    </small>
                    <TextField
                      name="_atsOnBehalfOfId"
                                            value={onBehalfOfEmployerUserId}
                      type="number"
                      onChange={onChangeAtsProviderInput('onBehalfOfEmployerUserId')}
                      fullWidth
                    />
                  </Col>

                </Row>

                <Row>

                  <Col>
                    <label>Agency Name</label>
                    <small>Agency name employer gave 10x10.</small>
                    <small className="c-red d-block">
                      {atsSourceNameWarning}
                    </small>
                    <TextField
                      name="_atsAgencyName"
                                            value={agencyName}
                      onChange={onChangeAtsProviderInput('agencyName')}
                      fullWidth
                    />
                  </Col>

                  {sourceIdAgencyId && (
                    <Col>
                      <label>ATS Source ID</label>
                      <small>The ID of the source to be credited for application creation</small>
                      <TextField
                        name="_atsSourceId"
                        type="number"
                                                value={sourceIdAgencyId}
                        onChange={onChangeAtsProviderInput('sourceIdAgencyId')}
                        fullWidth
                      />
                    </Col>
                  )}

                </Row>

                <Row>

                  <Col>
                    <label>Agency Recruiter Name</label>
                    <small className="c-black-medium">Recruiter name employer gave 10x10.</small>
                    <TextField
                      name="_atsAgencyRecruiterName"
                                            value={agencyRecruiterName}
                      onChange={onChangeAtsProviderInput('agencyRecruiterName')}
                      fullWidth
                    />
                  </Col>

                  <Col>
                  </Col>

                </Row>

                <Row>

                  <Col>
                    <Button
                      label={
                        validatingConnection
                          ? "VALIDATING..."
                          : "VALIDATE CONNECTION"
                      }
                      title={isAtsValidateConnectionDisabled
                        ? 'Disabled'
                        : null
                      }
                      className="mt-2"
                      onClick={onClickValidateConnectionButton}
                      disabled={isAtsValidateConnectionDisabled}
                    />
                  </Col>

                  {isAtsUserInfoAvailable && (
                    <Col>
                      <label>User</label>
                      <small>
                        <b>Name: </b>{atsUserName}<br />
                        <b>Email: </b>{atsUserEmail}
                      </small>
                      {atsUserDisabled && (
                        <small>
                          &nbsp;|&nbsp;
                          <b className="c-red uppercase">Disabled</b>
                        </small>
                      )}
                    </Col>
                  )}

                </Row>

              </Fragment>
            )}


          </div>

        )}

        <Row className='mt-3'>
          <InternalSubmissionInstructions
            entity={EmployerEditController.state}
            onChange={async (update) => new Promise((resolve) => EmployerEditController.setState(update, resolve))}
            autoSave={false}
          />
        </Row>

        <PrescreenQuestionsEdit Controller={EmployerEditController} />

        <Fieldset
          title='Interview Process'
          subtitle={
            <>This generic employer interview process will be shown above the job-specific interview process for all jobs</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: Obj(Arr(EmployerEditController.state.jobs)[0]).id,
              sectionName: JOB_SECTION__INTERVIEW_PROCESSES
            })
          }}
        >
          <RichTextBox
            name="interviewProcess"
            value={EmployerEditController.state.interviewProcess}
            onChange={interviewProcess =>
              EmployerEditController.setState({ interviewProcess })
            }
          />
        </Fieldset>

        <Fieldset
          acl={Core.isAdminOrTrusted()}
          title={
            <>Hiring Priority</>
          }
          info={Core.isAdmin() && AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: Core.isAdmin() && openJobView({
              jobId: Obj(Arr(EmployerEditController.state.jobs)[0]).id,
              sectionName: JOB_SECTION__EMPLOYER__ADDITIONAL_INFO
            })
          }}
        >
          <RichTextBox
            name="additionalInfo"
            value={EmployerEditController.state.additionalInfo}
            onChange={additionalInfo =>
              EmployerEditController.setState({ additionalInfo })
            }
          />
        </Fieldset>

        <Row className='mt-3'>
          <Col>
            <label>How did you hear of us?</label>
            <Autocomplete
              role='EmployerReference'
              placeholder="Enter a Keyword"
              options={
                [
                  'Social Media',
                  'News',
                  'Ads',
                  'Other',
                ]
              }
              value={
                EmployerEditController.state.reference
              }
              onSelect={
                (reference) => EmployerEditController.setState({
                  reference
                })
              }
            />
          </Col>
          <Col>
            <label>
              Other Recruiting agencies / services that you are using?
            </label>
            <TextField
              name="recruitingAgencies"
              value={EmployerEditController.state.recruitingAgencies}
              onChange={(ev, recruitingAgencies) =>
                EmployerEditController.setState({ recruitingAgencies })
              }
              variant={THEME__VARIANT__STANDARD}
              maxRows={5}
              multiLine
              fullWidth
              className='mt-05'
            />
          </Col>
        </Row>

        <Row>
          <Fieldset
            title={
              <>Expected Resume Review Response Time</>
            }
            fullWidth={false}
          >
            <div className='d-flex flex-align-left-center'>
              <Icon icon='access_time' className='mr-1' xl />
              <InputNumber
                name="expectedResumeResponseTime"
                value={EmployerEditController.state.expectedResumeResponseTime}
                onChange={(expectedResumeResponseTime) =>
                  EmployerEditController.setState({
                    expectedResumeResponseTime,
                  })
                }
                style={{ width: "calc(100% - 30px)" }}
                units='hrs'
              />
            </div>
          </Fieldset>
          <Fieldset
            title={
              <>Expected Scheduling Response Time</>
            }
            fullWidth={false}
          >
            <div className='d-flex flex-align-left-center'>
              <Icon icon='access_time' className='mr-1' xl />
              <InputNumber
                name="expectedSchedulingResponseTime"
                value={EmployerEditController.state.expectedSchedulingResponseTime}
                onChange={(expectedSchedulingResponseTime) =>
                  EmployerEditController.setState({
                    expectedSchedulingResponseTime,
                  })
                }
                style={{ width: "calc(100% - 30px)" }}
                units='hrs'
              />
            </div>
          </Fieldset>
        </Row>

        <Row>
          <Fieldset
            title={
              <>Expected Screen Feedback Response Time</>
            }
            fullWidth={false}
          >
            <div className='d-flex flex-align-left-center'>
              <Icon icon='access_time' className='mr-1' xl />
              <InputNumber
                name="expectedScreenResponseTime"
                value={EmployerEditController.state.expectedScreenResponseTime}
                onChange={(expectedScreenResponseTime) =>
                  EmployerEditController.setState({
                    expectedScreenResponseTime,
                  })
                }
                onFocus={(ev) => ev.target.select()}
                style={{ width: "calc(100% - 30px)" }}
                units='hrs'
              />
            </div>
          </Fieldset>
          <Fieldset
            title={
              <>Expected Onsite Feedback Response Time</>
            }
            fullWidth={false}
          >
            <div className='d-flex flex-align-left-center'>
              <Icon icon='access_time' className='mr-1' xl />
              <InputNumber
                name="expectedOnsiteResponseTime"
                value={EmployerEditController.state.expectedOnsiteResponseTime}
                onChange={(expectedOnsiteResponseTime) =>
                  EmployerEditController.setState({
                    expectedOnsiteResponseTime,
                  })
                }
                onFocus={(ev) => ev.target.select()}
                style={{ width: "calc(100% - 30px)" }}
                units='hrs'
              />
            </div>
          </Fieldset>
        </Row>

        <div
          className="green-block rounded py-1 mt-3"
          style={{ backgroundColor: colors.green.light }}
        >
          <Row>
            <Col fullWidth>
              <h4>For 10 by 10 use only</h4>
            </Col>
          </Row>
          <Row>
            <Col fullWidth>
              <label>
                Additional recruiting services that employer did not mention
              </label>
              <TextField
                name="additionalRecruitingAgencies"
                                value={EmployerEditController.state.additionalRecruitingAgencies}
                onChange={(ev, additionalRecruitingAgencies) =>
                  EmployerEditController.setState({
                    additionalRecruitingAgencies,
                  })
                }
                fullWidth
              />
            </Col>
          </Row>

          {/** related to story-3258 */}
          <Row className='mt-3'>
            <Col fullWidth>
              <label>{t('employer.process.location.label')}</label>
              <Menu tree multiple={false}
                value={EmployerEditController.state.location}
                onChange={(location) => {
                  EmployerEditController.setState({ location });
                }}
                options={locationTags}
                placeholder={t('employer.process.location.placeholder')}
                className="my-1"
              />
            </Col>
          </Row>
          {/** */}

          <Row className='mt-3'>
            <Col>
              <label>Current State</label>
              <RadioGroup column
                name="current_state"
                options={Definition.get("state").filter((tag) =>
                  Core.isAdminOrCoordinator()
                    ? true
                    : Definition.test("state", tag.id, /draft/)
                )}
                value={EmployerEditController.state.state}
                onChange={(event, state) => EmployerEditController.setState({ state })}
              />
            </Col>
            <Col>
              <label>Hiring Difficulty</label>
              <RadioGroup column
                name="employerHiringDifficulty"
                options={Definition.get("employerHiringDifficulty")}
                value={EmployerEditController.state.employerHiringDifficulty}
                onChange={
                  (ev, employerHiringDifficulty) => EmployerEditController.setState({ employerHiringDifficulty })
                }
              />
            </Col>
            <Col>
              <label>Flags</label>
              {Definition.get("flags").map((tag) => (
                <Checkbox
                  key={tag.id}
                  label={tag.label}
                  checked={!!~EmployerEditController.state.flags.indexOf(tag.id)}
                  onCheck={(ev, checked) => {
                    EmployerEditController.setState((state) => {
                      state = { flags: EmployerEditController.state.flags };
                      if (checked) {
                        if (!state.flags.find((id) => id === tag.id)) {
                          state.flags.push(tag.id);
                        }
                      } else {
                        state.flags = state.flags.filter((id) => id !== tag.id);
                      }
                      Core.log({ state });
                      return state;
                    });
                  }}
                />
              ))}
              <Divider />
              <Checkbox
                label="Confidential"
                checked={EmployerEditController.state.confidential}
                onCheck={(ev, confidential) =>
                  EmployerEditController.setState({ confidential })
                }
              />
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <label>Placement Fee</label>
              <div className='d-flex flex-align-left-center'>
                <TextField
                  name="placementFee"
                                    type="number"
                  max={100}
                  maxLength={5}
                  value={EmployerEditController.state.placementFee}
                  onChange={(ev, placementFee) =>
                    EmployerEditController.setState({
                      placementFee: Number(
                        placementFee > 100
                          ? 100
                          : String(placementFee).slice(0, 5)
                      ),
                    })
                  }
                />
                <span className="c-black-medium">%</span>
              </div>
            </Col>
            <Col>
              <label>Guarantee</label>
              <div className='d-flex flex-align-left-center'>
                <TextField
                  name="guarantee"
                                    type="number"
                  maxLength={3}
                  value={EmployerEditController.state.guarantee}
                  onChange={(ev, guarantee) =>
                    EmployerEditController.setState({
                      guarantee:
                        guarantee > 999
                          ? 999
                          : String(Math.round(guarantee)).slice(0, 3),
                    })
                  }
                />
                <span className="c-black-medium">days</span>
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col fullWidth>
              <label>Additional Contract Information</label>
              <TextField
                name="additionalContractInfo"
                                type="text"
                rows={1}
                rowsMax={3}
                multiLine={true}
                value={EmployerEditController.state.additionalContractInfo}
                onChange={(ev, additionalContractInfo) =>
                  EmployerEditController.setState({
                    additionalContractInfo,
                  })
                }
                fullWidth
              />
            </Col>
          </Row>

          <Fieldset
            title={
              <>Public Notes</>
            }
            subtitle=''
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: Obj(Arr(EmployerEditController.state.jobs)[0]).id,
                sectionName: JOB_SECTION__PUBLIC_NOTES
              })
            }}
          >
            <TextField
              name="publicNotes"
              rows={5}
              rowsMax={10}
              value={EmployerEditController.state.publicNotes}
              onChange={(ev, publicNotes) => EmployerEditController.setState({ publicNotes })}
            />
          </Fieldset>

          <Row className='mt-3'>
            <Col fullWidth>
              <label>Internal Privacy Notes not visible to recruiters</label>
              <TextField
                name="internalNotes"
                rows={5}
                rowsMax={10}
                value={EmployerEditController.state.internalNotes}
                onChange={(ev, internalNotes) =>
                  EmployerEditController.setState({ internalNotes })
                }
              />
            </Col>
          </Row>
        </div>
        <Row className='mt-3'>
          <Col>
            <label>Creation Date</label>
            <DatePicker
              name="createdAt"
                            value={
                EmployerEditController.state.createdAt ? new Date(EmployerEditController.state.createdAt) : null
              }
              onChange={(createdAt) => {
                EmployerEditController.setState({ createdAt: createdAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
          <Col>
            <label>Last Update</label>
            <DatePicker
              name="updatedAt"
                            value={
                EmployerEditController.state.updatedAt ? new Date(EmployerEditController.state.updatedAt) : null
              }
              onChange={(updatedAt) => {
                EmployerEditController.setState({ updatedAt: updatedAt.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label>Closed Date</label>
            <DatePicker
              name="closeDate"
                            value={
                EmployerEditController.state.closeDate ? new Date(EmployerEditController.state.closeDate) : null
              }
              onChange={(closeDate) => {
                EmployerEditController.setState({ closeDate: closeDate.toISOString() });
              }}
              fullWidth
            />
          </Col>
        </Row>
      </div >
    );
  }
}

/* EXPORTS ==================================== */

export default withTranslation()(Process);

/* ============================================ */
