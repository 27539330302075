import React from 'react';
import Core from '../../../lib/Core';
import { compileText } from '../../../lib/String.lib';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import InputNumber from '../../Layout/Wrappers/InputNumber';
import { WarningChipMessage } from '../../Layout/Wrappers/Message';
import Row from '../../Layout/Wrappers/Row';
import { CANDIDATE_UI } from '../CandidateUI.dic';

export default function YearsOfExperienceRow({ candidate, className }) {
  const { errorRelevantYearsOfExperience } = candidate;
  let CandidateEditController = Core.getKeyValue('CandidateEditController') || { setStateStore() { } };
  let totalYearsOfExp = !!candidate.adjustedTotalYearsOfExp
    ? candidate.adjustedTotalYearsOfExp
    : null;
  let relevantYearsOfExp = !!candidate.yearsOfExperience
    ? candidate.yearsOfExperience
    : null;
  const RelevantYearsOfExpWarning = !!totalYearsOfExp ? (
    totalYearsOfExp < relevantYearsOfExp ? (
      <WarningChipMessage>
        Warning: Parser estimated fewer total years of experience
      </WarningChipMessage>
    ) : null
  ) : null;
  return (
    <Row className={className}>
      <Fieldset
        title={CANDIDATE_UI.totalYearsOfExp.title}
        subtitle={compileText(
          CANDIDATE_UI.totalYearsOfExp.subtitle,
          { YEARS: Number((candidate.parserTotalMonthsOfExp || 0) / 12).toFixed(1) }
        )}
        dot='orange'
      >
        <InputNumber
          min={0}
          max={40}
          step={0.1}
          units='years'
          className='w-60'
          value={totalYearsOfExp}
          onChange={(adjustedTotalYearsOfExp) => {
            adjustedTotalYearsOfExp = Math.round(adjustedTotalYearsOfExp * 10) / 10;
            CandidateEditController.setStateStore({
              adjustedTotalYearsOfExp,
              key: 'adjustedTotalYearsOfExp',
            });
          }}
        />
      </Fieldset>
      <Fieldset
        title={CANDIDATE_UI.relevantYearsOfExp.title}
        subtitle={CANDIDATE_UI.relevantYearsOfExp.subtitle}
        dot='red'
      >
        <InputNumber
          min={0}
          max={40}
          step={0.1}
          units='years'
          className='w-60'
          value={relevantYearsOfExp}
          onChange={(yearsOfExperience) => {
            yearsOfExperience = (
              Math.round(
                yearsOfExperience * 10
              ) / 10
            );
            CandidateEditController.setStateStore({
              yearsOfExperience,
              key: 'yearsOfExperience',
              errorRelevantYearsOfExperience: '',
            });
          }}
        />
        <div className='pl-1'>
          <small className='c-red'>{errorRelevantYearsOfExperience}</small>
          <div className='mt-05'>
            {RelevantYearsOfExpWarning}
          </div>
        </div>
      </Fieldset>
    </Row>
  );
}
