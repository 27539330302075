import { Button } from '@mui/material';
import Core from '../../../lib/Core';

export function AdminManageTemplatesActionButton(props) {
  return (
    <Button
      variant='contained'
      color='secondary'
      size='small'
      className='nowrap min-w-240 tt-unset'
      onClick={event => {
        Core.openPopUp(Core.getPath(`admin/manage/templates/`), 1600);
      }}
    >
      Manage templates
    </Button>
  );
}
