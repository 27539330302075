import {
  padEnd
} from 'lodash';
import moment from 'moment';
import {
  Arr,
  join
} from '../../lib/Array.lib';
import {
  MDASH,
  US_DATE_FORMAT
} from '../../lib/Constants';
import Definition, {
  STATE_ACTIVE
} from "../../lib/Definition";
import globalErrorHandler from '../../lib/Error/globalErrorHandler.fun';
import {
  Fun
} from '../../lib/Function.lib';
import useState from '../../lib/hooks/useState.hook';
import QUERY__JOBS_DROPDOWN from '../../lib/queries/JobsDropdown.query';
import {
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  joinKeyName,
  trim
} from '../../lib/String.lib';
import Box from '../Layout/Wrappers/Box';
import Icon from '../Layout/Wrappers/Icon';
import Menu from '../Layout/Wrappers/Menu';
import {
  PLACEMENT__BOTTOM_END
} from '../Layout/Wrappers/StyledTooltip';
import Typography, {
  Label
} from '../Layout/Wrappers/Typography';
import {
  Dash
} from '../Shared/Dash';
import { THEME__COLOR__PRIMARY, THEME__COLOR__SECONDARY } from '../Layout/Libraries/Theme.lib';

export default function JobsDropdown({
  acl = true,
  query = {},
  content = '',
  info = '',
  value = null,
  onChange = Fun,
  ...props
}) {
  const [state, updateState] = useState();
  async function _fetchJobs() {
    try {
      await updateState({
        jobs: Arr(
          await readLoopbackRecord({
            ...QUERY__JOBS_DROPDOWN,
            ...query
          })
        ).sort(compareJobSortKeys)
      });
    }
    catch (error) {
      globalErrorHandler(error);
    }
  }
  const jobs = Arr(state.jobs);
  return (
    <Box row noWrap>
      <Label mr>
        Jobs
      </Label>
      <Icon mr
        acl={!!info}
        title={info}
        placement={PLACEMENT__BOTTOM_END}
        icon='help_outlined'
        className='icon16'
      />
      <Box>
        <Menu dropdown
          acl={!!acl}
          role='JobsDropdown'
          content={content}
          options={jobs}
          onClick={_fetchJobs}
          value={state.jobId}
          placeholder='Jobs'
          onChange={async (jobId) => {
            await updateState({ jobId });
            onChange(jobId);
          }}
          optionLabelMapper={
            ({
              id,
              jobTitle,
              employer = {}
            }) => (
              <Box className='min-w-240'>
                {
                  id
                    ?
                    join([
                      employer.name,
                      jobTitle
                    ], ` ${MDASH} `)
                    :
                    MDASH
                }
              </Box>
            )
          }
          renderOption={
            ({ option: {
              id,
              jobTitle,
              state,
              createdAt,
              employer = {}
            } }) => (
              <div>
                <Typography
                  acl={!!employer.name}
                  color={THEME__COLOR__SECONDARY}
                >
                  {employer.name}
                </Typography>
                {` ${MDASH} `}
                <Typography
                  acl={!!jobTitle}
                  color={THEME__COLOR__PRIMARY}
                >
                  {jobTitle}
                </Typography>
                {` ${MDASH} `}
                <span
                  className={`f-sm ${(state === STATE_ACTIVE) ? `c-cyan` : `c-gray`}`}
                >
                  {Definition.getLabel('state', state)}
                </span>
                {createdAt ? (
                  <span className='f-sm c-black-medium'>
                    <Dash />
                    {moment(createdAt).format(US_DATE_FORMAT)}
                  </span>
                ) : ''}
              </div>
            )
          }
        />
      </Box>
    </Box>
  );
}

export function compareJobSortKeys(a, b) {
  return generateJobSortKey(a).localeCompare(generateJobSortKey(b))
}

export function generateJobSortKey({
  employer = {},
  jobTitle,
  state
}) {
  const result = joinKeyName([
    state,
    padEnd(trim(employer.name).slice(0, 48), 48, '_'),
    padEnd(trim(jobTitle).slice(0, 48), 48, '_')
  ]);
  console.debug('generateJobSortKey', result);
  return result;
}
