import {
  getShortURL
} from './GenericTools.lib';
import {
  compileText,
  trim
} from './String.lib';
import cleanHtml from './tools/cleanHtml';
import formatURL from './tools/formatURL';

export const HTML__IN_BLANK_LINE = '<p><br/></p>';

export function getHTMLLinkString({ url, name, postContent = '', className, full, dropProtocol: _dropProtocol }) {
  url = formatURL(url);
  const link = compileText(joinHTML(
    `<a href="{{URL}}" target="_blank" referrer="noreferrer"{{CLASS}}>
      {{NAME}}{{POST}}
    </a>`
  ), {
    URL: url,
    NAME: (name
      ? name
      : full
        ? url
        : _dropProtocol
          ? dropProtocol(url)
          : getShortURL(url)
    ),
    POST: postContent,
    CLASS: className ? ` class="${className}"` : ''
  });
  // console.debug('getHTMLLinkString :link', link, typeof link);
  return link;
};

export function escapeHtml(str) {
  return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
};

/**
 * This method is for minifying HTML code such as handlebars templates.
 * The string value will be split by the line `\n` into an array, 
 * then each line will be trimmed, and finally the array will be joined 
 * into a single-line string with no separators.
 * 
 * @param {string} value  [HTML]
 * @param {string} div  [plaintext] Supply it in case you want to add a separator between each line.
 * @returns {string}  
 */
export function joinHTML(value, div = '') {
  return trim(value)
    .split(/\n/)
    .map(v => trim(v).replace(/&nbsp;/gi, ' '))
    .filter(v => !!v)
    .join(div)
    .trim();
}

export function dropProtocol(url = '') {
  return String(url || '').replace(/http(s*):\/\/|www\./, '');
}

export function composeCopyText(value) {

  // getting the composed html from the email preview instead have it in a separated template.
  let outputText = value || '';

  /**
   * @todo
   * To review for improvements.
   *
   * Following code block is converting HTML text into plain text.
   * 2021-07-08 µ
   */
  /* start block ========================== */

  outputText = cleanHtml(outputText);

  outputText = outputText.replace(/\n/ig, '');
  outputText = outputText.replace(/\s\s/ig, ' ');

  outputText = outputText.replace(/&nbsp;/ig, ' ');
  outputText = outputText.replace(/<\/div>/ig, '\n');
  outputText = outputText.replace(/<\/li>/ig, '\n');
  outputText = outputText.replace(/<li>/ig, '');
  outputText = outputText.replace(/<ul>|<\/ul>/ig, '');
  outputText = outputText.replace(/<p><\/p>/ig, '');
  outputText = outputText.replace(/<\/p>/ig, '\n');
  outputText = outputText.replace(/\n{2}/ig, '\n');
  outputText = outputText.replace(/<br\s*[/]?>/gi, '');
  outputText = outputText.replace(/<[^>]+>/ig, '');
  outputText = outputText.replace(/&gt;/ig, '>');
  outputText = outputText.split('\n').map(n => n.trim()).join('\n');

  return outputText;

};

export function htmlToMarkdown(html) {
  html = html.replace(/&nbsp;/ig, ' ');
  html = html.replace(/\s{2,}/g, ' ');
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  function processNode(node, indentLevel = 0) {
    let markdown = '';
    switch (node.nodeType) {
      case Node.ELEMENT_NODE:
        const tag = node.tagName.toLowerCase();
        const children = node.childNodes;
        switch (tag) {
          case 'p':
            children.forEach(child => {
              markdown += processNode(child, indentLevel);
            });
            markdown += '\n';
            break;
          case 'ul': case 'ol':
            indentLevel = indentLevel + 1;
            children.forEach(child => {
              markdown += processNode(child, indentLevel);
            });
            break;
          case 'li':
            markdown += ' '.repeat(indentLevel * 2) + '- ';
            children.forEach(child => {
              markdown += processNode(child, indentLevel);
            });
            markdown += '\n';
            break;
          case 'a':
            markdown = ` [${node.textContent.trim()}](${node.getAttribute('href')}) `;
            break;
          case 'b':
            markdown = `**${node.textContent.trim()}** `;
            break;
          default:
            children.forEach(child => {
              markdown += processNode(child, indentLevel);
            });
            break;
        }
        break;
      case Node.TEXT_NODE:
        markdown = node.textContent.trim();
        break;
      default:
        break;
    }
    return markdown;
  }
  const body = doc.body;
  let markdown = processNode(body).trim();
  markdown = markdown.split('\n').filter(n => n.trim()).join('\n');
  markdown = markdown.replace(/ +:/g, ':');
  markdown = markdown.replace(/:/g, ': ');
  markdown = markdown.replace(/: +\/\//g, '://');
  markdown = markdown.replace(/: {2,}/g, ': ');
  markdown = markdown.replace(/- {2,}/g, '- ');
  markdown = markdown.replace(/\) {2,}\[/g, ') [');
  return markdown;
}

export function removeHtmlBlankLines(value) {
  const htmlBlankLine = new RegExp('<(p|div)><br(\/)?><\/(p|div)>', 'gi');
  return trim(value).replace(htmlBlankLine, '').trim();
}

export function trimHtml(value) {
  const htmlBlankLine = '(<p>\\s*<br\\s*\/?>\s*<\/p>|<div>\\s*<br\\s*\/?>\\s*<\/div>)+';
  return trim(value)
    .replace(/\u00A0/g, ' ')
    .replace(/ <\/li>/gi, '</li>')
    .replace(/ <\/div>/gi, '</div>')
    .replace(/ <\/p>/gi, '</p>')
    .replace(/color: /gi, 'color:')
    .replace(new RegExp(`^${htmlBlankLine}|${htmlBlankLine}$`, 'gi'), '').trim();
}
