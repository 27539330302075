export default function getEmploymentModel({ 
  id = new Date().toISOString(),
  isCurrentEmployer = false, 
  positionHistories = [],
  __newRecord = false,
  __schemeVersion = 2
} = {}) {
  return {
    id,
    positionHistories,
    isCurrentEmployer,
    __newRecord,
    __schemeVersion,
    
    companyId: undefined,
    employerOrgName: undefined,
    
    // Signals are arrays, but be undefined is required
    positiveSignalsTags: undefined,
    negativeSignalsTags: undefined,

    automaticallyDerivedAt: undefined,
    manuallyOverriddenAt: undefined,
    manuallyOverriddenBy: undefined,
  };
}