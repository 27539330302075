import Core from '../../../lib/Core';
import {
  getParams
} from '../../../lib/URL.lib';
import Page from '../../Layout/Page';
import Paper, { getDefaultPaperStyle } from '../../Layout/Wrappers/Paper';
import {
  EmployerManageTemplatesContent
} from './EmployerManageTemplatesContent';

export function EmployerManageTemplatesPage(props) {
  const { employerId } = getParams({ pattern: '/employer/manage/templates/:employerId' });
  console.debug({ employerId });
  return (
    <Page showBack={false}
      title={<>{Core.getUserName()} - Manage Templates</>}
    >
      <Paper
        className="mx-auto p-1 scroll-y"
        style={
          getDefaultPaperStyle({
            height: '100%',
            width: '100%',
            maxWidth: 900
          })
        }
      >
        <EmployerManageTemplatesContent employerId={employerId} />
      </Paper>
    </Page>
  )
}
