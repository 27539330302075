import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import moment from 'moment';
import React, {
  useState
} from "react";
import { defaultTrue } from '../../lib/Boolean.lib';
import Candidate from "../../lib/Candidate";
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  REC_MSG_TYPE__REJECTION_ID
} from "../../lib/Definition";
import {
  EngagementLib
} from "../../lib/Engagement";
import {
  NOT,
  validateEmail
} from "../../lib/GenericTools.lib";
import Streak from '../../lib/Streak';
import {
  STAGE_CONFIRMATION,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from "../../dictionaries/Engagement.dic";
import {
  combineEmailsLists,
  mapEmailsListToRecipients,
  mapEmailsListToStringArray,
  sendSafeEmail
} from "../../lib/services/Email/Email.lib";
import MatchExclusionFieldset from "../Candidates/Forms/MatchExclusionFieldset";
import {
  hideAlert,
  showAlert
} from "../Dialogs/AlertDialog";
import EmailPreview from "../Dialogs/EmailPreview";
import Col from "../Layout/Wrappers/Col";
import Message from '../Layout/Wrappers/Message';
import MultipleSelect from "../Layout/Wrappers/MultipleSelect";
import Row from "../Layout/Wrappers/Row";
import Button from '../Layout/Wrappers/Button';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Divider from '../Layout/Wrappers/Divider';
import IconButton from '../Layout/Wrappers/IconButton';
import Loader from "../Shared/Loader";
import EngagementUI from '../../dictionaries/EngagementUI.dic';
import {
  RejectionReasonInfo
} from "./RejectionReasonInput";
import {
  RejectionReasonsMenu
} from "./RejectionReasonsMenu";
import {
  StatusesMenu
} from "./StatusesMenu";

export const WITHDRAW_CANDIDATE__SEND_EMAILS__TO_EMPLOYERS__FLAG_KEY = 'withdraw_candidate__send_emails__to_employers';
export const WITHDRAW_CANDIDATE__SEND_EMAILS__TO_RECRUITERS__FLAG_KEY = 'withdraw_candidate__send_emails__to_recruiters';

export function EmployerNotificationPreview({
  candidateId,
  snippets,
  onChange = () => null
}) {
  const [engagements, setEngagements] = useState();
  const [selected, setSelected] = useState();
  const [recipients, setRecipients] = useState();
  const [, reRender] = useState();

  Core.setKeyValue('EmployerNotificationPreviewRef', {
    getEngagementsToClose() {
      return engagements.filter(({ id }) => (selected || []).includes(id));
    },
    refetch({ showMessage }) { setTimeout(() => fetch({ showMessage })); }
  });

  async function fetch({ showMessage } = {}) {
    showMessage && Core.showMessage(`Fetching engagements`);
    const validStages = [STAGE_REVIEW, STAGE_SCREEN, STAGE_ONSITE, STAGE_OFFER];
    const engagements = await EngagementLib.getWhere({ candidateId, stage: { inq: validStages }, state: 'Open' }, undefined, undefined, true);
    setEngagements(engagements);
    let _selected = engagements.map(({ id }) => id);
    setSelected(_selected);
    let _recipients = {};
    engagements.forEach(engagement => {
      let jobRecipients = mapEmailsListToRecipients({
        emailsList: engagement.job.emailsList,
        accountType: 'Job Contact'
      });
      let employerRecipients = mapEmailsListToRecipients({
        emailsList: engagement.employer.emailsList,
        accountType: 'Employer Contact',
        primaryContact: {
          name: engagement.employer.primaryContactName,
          email: engagement.employer.primaryContactEmail,
          accountType: 'Primary Employer Contact'
        }
      });
      employerRecipients.emails = combineEmailsLists(employerRecipients.emails, jobRecipients.emails);
      employerRecipients.to = combineEmailsLists(employerRecipients.to, jobRecipients.to);
      employerRecipients.cc = combineEmailsLists(employerRecipients.cc, jobRecipients.cc);
      employerRecipients.bcc = combineEmailsLists(employerRecipients.bcc, jobRecipients.bcc);
      _recipients[engagement.id] = employerRecipients;
      _recipients[engagement.id]._to = mapEmailsListToStringArray(_recipients[engagement.id].to);
    });
    setRecipients(_recipients);
    onChange({ selected: _selected, recipients: _recipients });
    Core.hideMessage();
  }

  const handleToggleAll = (value) => () => {
    if (selected.length === engagements.length) {
      setSelected([]);
      onChange({ selected: [], recipients });
    }
    else {
      let _selected = engagements.map(({ id }) => id);
      setSelected(_selected);
      onChange({ selected: _selected, recipients });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
    onChange({ selected: newChecked, recipients });
  };

  if (!engagements) { fetch({ showMessage: false }); }

  const SEND_EMAILS = defaultTrue(Core.getKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_EMPLOYERS__FLAG_KEY));

  return !engagements?.length ? (<Message>There are not engagements to notify to employers</Message>) : (
    <>
      <div className="d-flex flex-column">
        {engagements && selected && (
          <div>
            <Row>
              <Col className="d-flex flex-align-left-center">
                {SEND_EMAILS ? (
                  <label className="m-0 mr-1">Message to notify employers</label>
                ) : (
                  <label className="m-0 mr-1">Close employer engagements</label>
                )}
                <IconButton
                  title="Refetch Engagements"
                  onClick={event => fetch({ showMessage: true })}
                  className='mr-1'
                >
                  <i className="material-icons">refresh</i>
                </IconButton>
                <Checkbox
                  checked={SEND_EMAILS}
                  onChange={(event) => {
                    Core.setKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_EMPLOYERS__FLAG_KEY, event.target.checked);
                    reRender(Date.now());
                    try { Core.getKeyValue('WithdrawCandidateRef').reRender(Date.now()); } catch { }
                  }}
                  labelProps={{ className: 'f-sm fw-500 c-black-medium' }}
                  tooltip={EngagementUI.closeEngagements.sendEmails.tooltip}
                >
                  Send emails
                </Checkbox>
              </Col>
            </Row>
            <div className="ml-4">
              <List sx={{ bgcolor: 'background.paper' }}>
                <ListItem>
                  <div className="d-flex flex-align-left-center w-50">
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={!!selected.length && selected.length === engagements.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-all` }}
                        onClick={handleToggleAll()}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-all`} primary={<strong>All</strong>} />
                  </div>
                </ListItem>
                {engagements.map(engagement => {
                  const { id, job, stage, state } = engagement;
                  const { jobTitle: jobName, employer } = job;
                  const { name: employerName } = employer;
                  const labelId = `checkbox-list-label-${id}`;
                  return (
                    <ListItem
                      key={id}
                      disablePadding
                    >
                      <ListItemButton role={undefined} dense>
                        <div className="d-flex flex-align-left-center w-50">
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selected.indexOf(id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={handleToggle(id)}
                            />
                          </ListItemIcon>
                          <ListItemText className="truncate" id={labelId} primary={`${employerName} - ${jobName} - ${stage} - ${state}`} />
                        </div>
                        {SEND_EMAILS && (
                          <div className="d-flex flex-align-right-center w-50 ml-auto">
                            {recipients && recipients[engagement.id] && (
                              <>
                                <MultipleSelect
                                  placeholder={('type an email')}
                                  multiple="tags"
                                  data={recipients[engagement.id].emails.filter(c => validateEmail(c.email)).map(contact => {
                                    let accountType = contact.accountType ? <strong>{contact.accountType}:</strong> : '';
                                    let name = contact.name && String(contact.name).trim() ? ` ${contact.name}` : '';
                                    let email = ` <${contact.email}>`;
                                    return {
                                      label: (<small>{accountType}{name}{email}</small>),
                                      id: `${name}${email}`.trim()
                                    }
                                  })}
                                  value={recipients[engagement.id]._to}
                                  onChange={to => {
                                    let _recipients = { ...recipients };
                                    _recipients[engagement.id]._to = to;
                                    setRecipients(_recipients);
                                    onChange({ selected, recipients: _recipients });
                                  }}
                                  optionLabelProp
                                  maxTagCount={false}
                                />
                              </>
                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) && (
                              <IconButton
                                aria-label="Popup Edit Employer - REFETCH ENGAGEMENTS AFTER SAVE EMPLOYER CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUp(`/#/employer/edit/${engagement.employer.id}`, 1600)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>
                            )}
                          </div>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {SEND_EMAILS && (
                <div className="px-2">
                  <EmailPreview
                    ref={self => Core.setKeyValue('EmployerNotificationEmailPreview', self)}
                    omitUIRecipients
                    snippets={snippets}
                    // Commented next line by story_8806
                    // defaultSnippet={{ snippetName: /E\s*-\s*Notify\s*Cand.*Withdraw$/ }}
                    taller
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Divider className="mt-4 mb-2" />
    </>
  );
}

export function RecruiterNotificationPreview({
  candidateId,
  snippets,
  onChange = () => null
}) {
  const [engagements, setEngagements] = useState();
  const [selected, setSelected] = useState();
  const [recipients, setRecipients] = useState();
  const [, reRender] = useState();

  async function fetch({ showMessage } = {}) {
    showMessage && Core.showMessage(`Fetching additional engagements`);
    const validStages = [STAGE_SUBMISSION, STAGE_CONFIRMATION];
    let engagementsToClose = [];
    try {
      engagementsToClose = Core.getKeyValue('EmployerNotificationPreviewRef').getEngagementsToClose().map(e => { e.__toNotifyREcruiter = true; return e });
    } catch { }
    const engagements = [
      ...await EngagementLib.getWhere({ candidateId, stage: { inq: validStages }, state: 'Open' }, undefined, undefined, true),
      ...engagementsToClose
    ];
    setEngagements(engagements);
    let _selected = engagements.map(({ id }) => id);
    setSelected(_selected);
    let _recipients = {};
    engagements.forEach(engagement => {
      let recruiter = engagement.recruiter;
      let candidate = engagement.candidate;
      let recruiterRecipients = mapEmailsListToRecipients({
        emailsList: recruiter.emailsList,
        primaryContact: { name: recruiter._name, email: recruiter.email, accountType: 'Recruiter Main Contact' },
        accountType: 'Recruiter Additional Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      const candidateRecruiterRecipients = mapEmailsListToRecipients({
        emailsList: candidate ? candidate.emailsList : [],
        accountType: 'Candidate Recruiter Contact',
        messageTypes: [REC_MSG_TYPE__REJECTION_ID]
      });
      recruiterRecipients.emails = combineEmailsLists(candidateRecruiterRecipients.emails, recruiterRecipients.emails);
      recruiterRecipients.to = combineEmailsLists(candidateRecruiterRecipients.to, recruiterRecipients.to);
      recruiterRecipients.cc = combineEmailsLists(candidateRecruiterRecipients.cc, recruiterRecipients.cc);
      recruiterRecipients.bcc = combineEmailsLists(candidateRecruiterRecipients.bcc, recruiterRecipients.bcc);
      _recipients[engagement.id] = recruiterRecipients;
      _recipients[engagement.id]._to = mapEmailsListToStringArray(_recipients[engagement.id].to);
    });
    setRecipients(_recipients);
    onChange({ selected: _selected, recipients: _recipients });
    hideAlert();
  }

  Core.setKeyValue('RecruiterNotificationPreviewRef', {
    refetch({ showMessage }) { setTimeout(() => fetch({ showMessage })); },
    getSelectedEngagements() { return engagements.filter(({ id }) => selected.includes(id)); }
  });

  const handleToggleAll = (value) => () => {
    if (selected.length === engagements.length) {
      let _selected = selected.filter(engagementId => engagements.find(({ id }) => (id === engagementId)).__toNotifyREcruiter);
      setSelected(_selected);
      onChange({
        selected: _selected,
        recipients
      });
    }
    else {
      let _selected = engagements.map(({ id }) => id);
      setSelected(_selected);
      onChange({ selected: _selected, recipients });
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = selected.indexOf(value);
    const newChecked = [...selected];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
    onChange({ selected: newChecked, recipients });
  };

  if (!engagements) { fetch({ showMessage: false }); }

  const SEND_EMAILS = defaultTrue(Core.getKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_RECRUITERS__FLAG_KEY));

  return !engagements?.length ? (<Message>There are not engagements to notify to recruiters</Message>) : (
    <>
      <div className="d-flex flex-column">
        {engagements && selected && (
          <div>
            <Row>
              <Col className="d-flex flex-align-left-center">
                {SEND_EMAILS ? (
                  <label className="m-0 mr-1">Message to notify recruiters</label>
                ) : (
                  <label className="m-0 mr-1">Close recruiter engagements</label>
                )}
                <IconButton
                  title="Refetch Engagements"
                  onClick={event => fetch({ showMessage: true })}
                  className='mr-1'
                >
                  <i className="material-icons">refresh</i>
                </IconButton>
                <Checkbox
                  checked={SEND_EMAILS}
                  onChange={(event) => {
                    Core.setKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_RECRUITERS__FLAG_KEY, event.target.checked);
                    reRender(Date.now());
                    try { Core.getKeyValue('WithdrawCandidateRef').reRender(Date.now()); } catch { }
                  }}
                  labelProps={{ className: 'f-sm fw-500 c-black-medium' }}
                  tooltip={EngagementUI.closeEngagements.sendEmails.tooltip}
                >
                  Send emails
                </Checkbox>
              </Col>
            </Row>
            <div className="ml-4">
              <List sx={{ bgcolor: 'background.paper' }}>
                <ListItem>
                  <div className="d-flex flex-align-left-center w-50">
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.length === engagements.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `checkbox-list-label-all` }}
                        onClick={handleToggleAll()}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-all`} primary={<strong>All</strong>} />
                  </div>
                </ListItem>
                {engagements.map(engagement => {
                  const { id, job, stage, state, __toNotifyREcruiter } = engagement;
                  const { jobTitle: jobName, employer } = job;
                  const { name: employerName } = employer;
                  const labelId = `checkbox-list-label-${id}`;
                  return (
                    <ListItem
                      key={id}
                      disablePadding
                      className={__toNotifyREcruiter ? 'bg-main' : ''}
                    >
                      <ListItemButton role={undefined} dense>
                        <div className="d-flex flex-align-left-center w-50">
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={selected.indexOf(id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={handleToggle(id)}
                              disabled={__toNotifyREcruiter}
                            />
                          </ListItemIcon>
                          <ListItemText className="truncate" id={labelId} primary={`${employerName} - ${jobName} - ${stage} - ${state}`} />
                        </div>
                        {SEND_EMAILS && (
                          <div className="d-flex flex-align-left-center w-50 ml-auto d-none">
                            {recipients && recipients[engagement.id] && (
                              <>
                                <MultipleSelect
                                  placeholder={('type an email')}
                                  multiple="tags"
                                  data={recipients[engagement.id].emails.filter(c => validateEmail(c.email)).map(contact => {
                                    let accountType = contact.accountType ? <strong>{contact.accountType}:</strong> : '';
                                    let name = contact.name && String(contact.name).trim() ? ` ${contact.name}` : '';
                                    let email = ` <${contact.email}>`;
                                    return {
                                      label: (<small>{accountType}{name}{email}</small>),
                                      id: `${name}${email}`.trim()
                                    }
                                  })}
                                  value={recipients[engagement.id]._to}
                                  onChange={to => {
                                    let _recipients = { ...recipients };
                                    _recipients[engagement.id]._to = to;
                                    setRecipients(_recipients);
                                    onChange({ selected, recipients: _recipients });
                                  }}
                                  optionLabelProp
                                  maxTagCount={false}
                                />
                              </>
                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) && (
                              <IconButton
                                aria-label="Popup Edit Candidate - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUp(`/#/candidate/edit/${engagement.candidate.id}`, 1600)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>

                            )}
                            {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) && (
                              <IconButton
                                aria-label="Popup Edit Account - REFETCH ENGAGEMENTS AFTER SAVE RECRUITER CONTACTS"
                                className="hint--left"
                                onClick={ev => Core.openPopUp(`/#/account/edit/${engagement.recruiter.id}`, 1600)}
                              >
                                <i className="material-icons">edit</i>
                              </IconButton>
                            )}
                          </div>
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
              {SEND_EMAILS && (
                <>
                  <Row>
                    <Col className="d-flex flex-align-left-center">
                      <label className='m-0 mr-1'>To:</label>
                      <span className='c-black-medium'>{((recipients || {})[Object.keys(recipients || {})[0]] || {})._to?.join(', ')}</span>
                    </Col>
                  </Row>
                  <div className="px-2">
                    <EmailPreview
                      ref={self => Core.setKeyValue('RecruiterNotificationEmailPreview', self)}
                      omitUIRecipients
                      snippets={snippets}
                      // Commented next line by story_8806
                      // defaultSnippet={{ snippetName: /R\s*-\s*Notify\s*Cand.*Withdraw$/ }}
                      taller
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <Divider className="mt-4 mb-1" />
    </>
  );
}

export default function WithdrawCandidate({ candidateId, snippets }) {
  const { current } = React.useRef({});
  const [candidate, setCandidate] = useState();
  const [status, setStatus] = useState();
  const [rejectionReason, setRejectionReason] = useState();
  const [rejectionReasonAdditionalInfo, setRejectionReasonAdditionalInfo] = useState();
  const [employerSelectedEngagements, setEmployerSelectedEngagements] = useState([]);
  const [employerRecipients, setEmployerRecipients] = useState([]);
  const [recruiterSelectedEngagements, setRecruiterSelectedEngagements] = useState([]);
  const [recruiterRecipients, setRecruiterRecipients] = useState([]);
  const [, reRender] = useState();

  Core.setKeyValue('WithdrawCandidateRef', {
    getStatus: () => status,
    reRender
  });

  const SEND_EMAILS_TO_EMPLOYERS = defaultTrue(Core.getKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_EMPLOYERS__FLAG_KEY));
  const SEND_EMAILS_TO_RECRUITERS = defaultTrue(Core.getKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_RECRUITERS__FLAG_KEY));

  function setEmployerData({ selected, recipients }) {
    setEmployerRecipients(recipients);
    setEmployerSelectedEngagements(selected);
    Core.getKeyValue('RecruiterNotificationPreviewRef')?.refetch({ showMessage: false });
  }
  function setRecruiterData({ selected, recipients, engagements }) {
    setRecruiterRecipients(recipients);
    setRecruiterSelectedEngagements(selected);
  }

  async function submit() {

    let employerNotificationParams;
    let recruiterNotificationParams;

    employerNotificationParams = Core.getKeyValue('EmployerNotificationEmailPreview')?.getParams();
    recruiterNotificationParams = Core.getKeyValue('RecruiterNotificationEmailPreview')?.getParams();
    console.debug('recruiterNotificationParams', recruiterNotificationParams);

    if (SEND_EMAILS_TO_EMPLOYERS) {
      if (!!employerSelectedEngagements.length) {
        if (!employerNotificationParams?.subject) {
          return showAlert({ message: 'Missing employer subject content', severity: 'error' });
        }
        if (!employerNotificationParams?.html) {
          return showAlert({ message: 'Missing employer body content', severity: 'error' });
        }
      }
    }

    if (SEND_EMAILS_TO_RECRUITERS) {
      if (!recruiterNotificationParams?.subject) {
        return showAlert({ message: 'Missing recruiter subject content', severity: 'error' });
      }
      if (!recruiterNotificationParams?.html) {
        return showAlert({ message: 'Missing recruiter body content', severity: 'error' });
      }
    }

    try {
      let selectedEngagements = Core.getKeyValue('RecruiterNotificationPreviewRef').getSelectedEngagements();
      Core.dialog.open({
        title: <>Action Summary</>,
        paperStyle: {
          minWidth: '50vw',
          maxWidth: '70vw'
        },
        message: (
          <>
            {SEND_EMAILS_TO_EMPLOYERS && (
              <div>
                To send notification to employers
                <ul>
                  {employerSelectedEngagements.map((engagementId, index) => {
                    return (
                      <li key={`employer-recipient-${engagementId}`}>{employerRecipients[engagementId]._to.join(',')}</li>
                    )
                  })}
                </ul>
              </div>
            )}
            {SEND_EMAILS_TO_RECRUITERS && (
              <div>
                To send notification to recruiters
                <ul>
                  {[recruiterSelectedEngagements[0]].map((engagementId, index) => {
                    return (
                      <li key={`recruiter-recipient-${engagementId}`}>{recruiterRecipients[engagementId]._to.join(',')}</li>
                    )
                  })}
                </ul>
              </div>
            )}
            <div>
              To close these engagements
              <ul>
                {selectedEngagements.map((engagement, index) => {
                  const { job, stage, state, screen1, screen2, screen3, onsite1, onsite2 } = engagement;
                  const { jobTitle: jobName, employer } = job;
                  const { name: employerName } = employer;
                  let futureInterview;
                  if (stage === STAGE_SCREEN) {
                    if (moment().isBefore(screen1)) {
                      futureInterview = { screen1 };
                    }
                    else if (moment().isBefore(screen2)) {
                      futureInterview = { screen2 };
                    }
                    else if (moment().isBefore(screen3)) {
                      futureInterview = { screen3 };
                    }
                  }
                  if (stage === STAGE_ONSITE) {
                    if (moment().isBefore(onsite1)) {
                      futureInterview = { onsite1 };
                    }
                    else if (moment().isBefore(onsite2)) {
                      futureInterview = { onsite2 };
                    }
                  }
                  if (futureInterview) {
                    futureInterview = Object.keys(futureInterview).map(key => `${key}: ${moment(futureInterview[key]).format('YYYY-MM-DD')}`);
                  }
                  return (
                    <li key={`engagement-to-close-${engagement.id}`}>
                      {employerName} - {jobName} - {stage} - {state}{futureInterview ? <> - <span className='c-red'>{futureInterview}</span></> : ''}
                    </li>
                  )
                })}
              </ul>
            </div>
          </>
        ),
        actions: [
          <Button
            key={`action-button-cancel`}
            variant='outlined'
            color='secondary'
            onClick={Core.dialog.close}>
            Cancel
          </Button>,
          <Button
            key={`action-button-confirm`}
            variant='contained'
            color='primary'
            onClick={async ev => {
              Core.dialog.close();
              let ok;
              if (SEND_EMAILS_TO_EMPLOYERS) {
                showAlert({ message: <Loader>Sending Employer Emails</Loader> });
                ok = await Promise.all(
                  employerSelectedEngagements.map(async (engagementId, index) => {
                    let _params = {
                      ...employerNotificationParams,
                      to: employerRecipients[engagementId]._to.join(','),
                      boxKey: selectedEngagements.find(engagement => {
                        return engagementId === engagement.id
                      })?.boxKey
                    };
                    return sendSafeEmail(_params);
                  })
                ).catch(exception => Core.failure({ message: exception }));
                console.debug('EMPLOYERS', ok);
                if (!ok) { return; }
              }
              if (SEND_EMAILS_TO_RECRUITERS) {
                showAlert({ message: <Loader>Sending Recruiter Emails</Loader> });
                ok = await Promise.all(
                  recruiterSelectedEngagements.map(async (engagementId, index) => {
                    let _params = {
                      ...recruiterNotificationParams,
                      to: recruiterRecipients[engagementId]._to.join(','),
                      boxKey: selectedEngagements.find(engagement => {
                        return engagementId === engagement.id
                      })?.boxKey
                    };
                    return sendSafeEmail(_params);
                  })
                ).catch(exception => Core.failure({ message: exception }));
                console.debug('RECRUITERS', ok);
                if (!ok) { return; }
              }
              showAlert({ message: <Loader>Closing Engagements</Loader> });
              ok = await Promise.all(
                selectedEngagements.map(async (engagement, index) => {
                  return EngagementLib.update(engagement, {
                    state: 'Closed',
                    closed: new Date().getTime(),
                    status,
                    rejectionReason,
                    rejectionReasonAdditionalInfo
                  });
                })
              ).then(() => Core.getKeyValue('EmployerNotificationPreviewRef')?.refetch({ showMessage: true }))
                .catch(exception => Core.failure({ message: exception }));
              console.debug('ENGAGEMENTS', ok);
            }}
          >
            Confirm
          </Button>
        ]
      });
    } catch { }
  }

  async function fetchCandidate() {
    current.busy = true;
    try {
      setCandidate(await Candidate.get(candidateId));
      current.ready = true;
    }
    catch (error) {
      current.error = error;
      Core.showError(error);
    }
    current.busy = false;
    setTimeout(async () => reRender(Date.now()));
  }
  if (NOT(current.busy || current.error || current.ready)) { fetchCandidate(); }

  return (
    <>
      <div className="sticky-top bg-main">
        <Row className="py-1">
          <Col className="d-flex flex-align-left-center px-0">
            <IconButton
              className="mx-1"
              onClick={event => {
                event.stopPropagation();
                Core.closeDrawer()
              }}
            >
              <i className="material-icons">close</i>
            </IconButton>
            <label className="m-0 mr-1">
              <strong className="mr-1">Withdraw Candidate {Object(candidate)._name}</strong>
            </label>
          </Col>
        </Row>
        <Divider />
      </div>
      <Loader className='m-2' loading={current.busy} />
      <EmployerNotificationPreview candidateId={candidateId} snippets={snippets} onChange={setEmployerData} />
      <RecruiterNotificationPreview candidateId={candidateId} snippets={snippets} onChange={setRecruiterData} />
      <div className="p-1 d-flex">
        <MatchExclusionFieldset candidateId={candidateId} />
      </div>
      <div className="sticky-bottom bg-main">
        <Divider className="mt-1" />
        <div className="d-flex flex-column p-2">
          <div className="d-flex w-100 space-between">
            <StatusesMenu
              filter={/e - (cand|recruiter)/i}
              className="mx-1 w-20"
              style={{ height: 30 }}
              onChange={status => {
                setStatus(status);
                Core.getKeyValue('RecruiterNotificationPreviewRef')?.refetch({ showMessage: true });
              }}
            />
            <RejectionReasonsMenu
              className="mr-1 w-30"
              style={{ height: 30 }}
              onChange={setRejectionReason}
            >
              Reason
            </RejectionReasonsMenu>
            <RejectionReasonInfo
              className="flex-align-left-top w-50"
              style={{ minHeight: 30 }}
              onChange={setRejectionReasonAdditionalInfo}
            >
              <span style={{ height: 30 }}>Details</span>
            </RejectionReasonInfo>
          </div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className="m-auto my-2"
            style={{ minWidth: 222 }}
            onClick={submit}
            disabled={!status || !rejectionReason || (!employerSelectedEngagements.length && !recruiterSelectedEngagements.length)}
          >
            {SEND_EMAILS_TO_EMPLOYERS ? `Notify` : `Close`} ({employerSelectedEngagements.length}) Employer Engagements and {SEND_EMAILS_TO_RECRUITERS ? `Notify` : `Close`} ({recruiterSelectedEngagements.length}) Recruiter Engagements
          </Button>
        </div>
      </div>
    </>
  );
}

export async function openWithdrawCandidate({ candidateId }) {
  showAlert();
  const snippets = await Streak.getSnippets();
  Core.setKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_EMPLOYERS__FLAG_KEY, true);
  Core.setKeyValue(WITHDRAW_CANDIDATE__SEND_EMAILS__TO_RECRUITERS__FLAG_KEY, true);
  hideAlert();
  Core.openDrawer({
    style: {
      width: 1200,
      maxWidth: 'calc(100vw - 2rem)',
    },
    content: (
      <WithdrawCandidate candidateId={candidateId} snippets={snippets} />
    ),
  });
}
