import React from "react";
import {
  join
} from '../../../lib/Array.lib';
import Core from "../../../lib/Core";
import Job from "../../../lib/Job";
import copyHtml from "../../../lib/tools/copyHtml";
import Button from '../../Layout/Wrappers/Button';

export default function SelectedBulkJob({ selectedMatches = [], selectedMatch = {} }) {
  const _bulkCopy = async (event) => {
    try {
      const headers = [];
      const body = [];
      selectedMatches.forEach(job => {
        if (job && Job.getPreview(job)) {
          headers.push("<li>" + job._name + "</li>");
          body.push(Job.getPreview(job));
          body.push('<br/><hr/>');
        }
      });
      await copyHtml(`
        ${selectedMatch._name} - Looking forward to helping you interview - ${Core.getEnvValue("TEAM_NAME")}<br/>
        <br/>
        Hi ${selectedMatch.firstName} - Below my signature, you'll find team and job specific information about:<br/>
        <ul>
        ${headers.join("")}
        </ul>
        <b>Important</b><br/>
        When you've read this, please email me back with any questions. Based on your reply, I will connect you with the teams you like to get interviews started.<br/>
        <br/>
        <u>By working with us, we commit to give you:</u><br/>
        <ul>
        <li>Access to innovative companies, many with jobs not yet publicly posted.</li>
        <li>Fast-track interview, so your resumes isn't lost in a black hole.</li>
        <li>An insider scoop to help you navigate the interview process and save time.</li>
        <li>Market compensation data and expert advice on offer negotiations.</li>
        <li>Care and consideration for your preferences and career goals.</li>
        </ul>
        <br/>
        I will be your point of contact throughout the interview,<br/>
        ${Core.getEmailSignature()}
        <br/><hr/>
        ${join(body, '')}
      `);
      Core.showSuccess("Copied!");
    }
    catch (error) {
      Core.showError("Fail copy!");
      console.error(
        'SelectedBulkJob: something goes wrong...',
        '\n', error
      );
    }
  }
  return (
    <Button outlined small secondary
      onClick={_bulkCopy}
      className='mr-1'
    >
      {`Copy (${selectedMatches.length})`}
    </Button>
  );
}
