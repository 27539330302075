import { mapEvents, MODEL_NAME__EVENT } from '../../lib/models/event.model';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import { CellActionsColumn, CellTagColumn, FilterActionsColumn } from "./ReactTable";
import { REACT_TABLE__COLUMN_LG, REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_XS, REACT_TABLE__COLUMN_XXL } from './useEnhancedReactTable.hook';

export const configEvent = {
  Entity: { name: MODEL_NAME__EVENT },
  entityMapper: mapEvents,
  searchPath: 'Events/_search',
  autocompletePath: 'Events/_autocomplete',
  facetsPath: 'Events/_facets',
  searchPlaceholder: 'Search',
  defaultQuery: {
    "filters": [],
    "skip": 0,
    "limit": 10,
    "query": []
  },
  hiddenColumns: [],
  columns: [
    {
      accessor: 'source',
      Header: 'source',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'modelName',
      Header: 'modelName',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
      _headerClassName: 'sticky-start bg-cyan-common',
      _cellClassName: 'sticky-start bg-white-common',
    },
    {
      accessor: 'modelInstanceId',
      Header: 'modelInstanceId',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'actor.accountId',
      Header: 'actor',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'eventName',
      Header: 'eventName',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'fieldName',
      Header: 'fieldName',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'previousValue',
      Header: 'previousValue',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_LG,
    },
    {
      accessor: 'value',
      Header: 'value',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_LG,
    },
    {
      accessor: 'context',
      Header: 'context',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_createdAt',
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated At',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_updatedAt',
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_XS,
      _headerClassName: 'sticky-end bg-cyan-common c-white-all bl-1',
      _cellClassName: 'sticky-end bg-white-common bl-1 d-flex flex-align-right-center',
    }
  ],
  moreMenuOptions: [],
  disabledStarred: true,
};
