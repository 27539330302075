import {
  join
} from '../Array.lib';
import globalErrorHandler from '../Error/globalErrorHandler.fun';
import {
  Obj
} from '../Object.lib';
import Streak from '../Streak';
import {
  Str
} from '../String.lib';

export default async function appendStreakNote({
  boxKey = '',
  note = ''
}) {
  try {
    if (boxKey && note) {
      const box = Obj(await Streak.getBox(boxKey));
      const notes = Str(box.notes).replace('\r', '').split('\n\n');
      const sign = (notes.length > 1) ? notes.pop() : '';
      await Streak.updateNotes(
        {
          boxKey: boxKey,
          notes: join([
            join([
              ...notes,
              note,
            ], '\n'),
            sign
          ], '\n\n')
        }
      );
    }
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
