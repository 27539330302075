import React, {
  Component
} from "react";
import Box from '../../Layout/Wrappers/Box';
import Chip from '../Haves/v1/Chip';
import ChipTag from '../Haves/v1/ChipTag';
import Complex from '../Haves/v1/Complex';
import Text from '../Haves/v1/Text';

class ComparisonHaves extends Component {

  getElementCategory = () => {
    const { element, toMatchWith, candidateResume } = this.props;
    if (!!element.args && !!element.args.length) {
      return Complex.result(element, toMatchWith, candidateResume);
    }
    else if (element.type === 'chip' || element.type === 'chipGroup') {
      return Chip.result(element, toMatchWith);
    }
    else if (element.type === 'chipTag') {
      return ChipTag.resultWithDecision(element, candidateResume);
    }
    else if (element.type === 'text') {
      return Text.result();
    }
    return null;
  }

  render() {
    const {
      className,
      style
    } = this.props;
    const wrapperProps = {
      className,
      style
    }
    return (
      <Box row wAuto {...wrapperProps}>
        {this.getElementCategory()}
      </Box>
    )
  }
}

export default ComparisonHaves;
