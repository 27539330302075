import { PLATFORM_RATING__A_PLUS, PLATFORM_RATING__A_TOP, PLATFORM_RATING__B_STRONG } from "../../lib/Definition";
import { MODEL_NAME_CANDIDATE } from "../../lib/models/candidate";
import { MODEL_NAME_JOB } from "../../lib/models/job";

export function mapLooseMatch({
  context: { subject },
  Entity = {},
  filters = {},
  looseMatch
}) {

  let newFilters = { ...filters };

  if (looseMatch) {

    if (Entity.name === MODEL_NAME_JOB) {
      if (newFilters.minYearsOfExperience) {
        newFilters = mapCandidateMatchingJobYearsOfExperience({ candidate: subject, filters: newFilters });
      }
      if (newFilters.salaryMax) {
        newFilters = mapCandidateMatchingJobSalary({ candidate: subject, filters: newFilters });
      }
    }

    if (Entity.name === MODEL_NAME_CANDIDATE) {
      if (newFilters.yearsOfExperience) {
        newFilters = mapJobMatchingCandidateYearsOfExperience({ job: subject, filters: newFilters });
      }
      if (newFilters.minimumSalary) {
        newFilters = mapJobMatchingCandidateSalary({ job: subject, filters: newFilters });
      }

    }
  }

  if (Entity.name === MODEL_NAME_JOB) {
    if (newFilters.minYearsOfExperience) {
      newFilters = { ...newFilters };
      newFilters['$or'] = newFilters['$or'] || [];
      newFilters['$or'].push({ minYearsOfExperience: { $lte: newFilters.minYearsOfExperience } });
      newFilters['$or'].push({ minYearsOfExperience: { $exists: false } });
      delete newFilters.minYearsOfExperience;
    }
    if (newFilters.salaryMax) {
      newFilters = { ...newFilters };
      newFilters['$or'] = newFilters['$or'] || [];
      newFilters['$or'].push({ salaryMax: { $gte: newFilters.salaryMax } });
      newFilters['$or'].push({ salaryMax: { $exists: false } });
      delete newFilters.salaryMax;
    }
  }

  if (Entity.name === MODEL_NAME_CANDIDATE) {
    if (newFilters.yearsOfExperience) {
      newFilters = { ...newFilters, yearsOfExperience: { $gte: newFilters.yearsOfExperience } };
    }
    if (newFilters.minimumSalary) {
      newFilters = { ...newFilters, minimumSalary: { $lte: newFilters.minimumSalary } };
    }
  }

  return newFilters;

}

export function mapCandidateMatchingJobYearsOfExperience({ candidate, filters }) {

  const newFilters = { ...filters };

  const { minYearsOfExperience } = filters;

  let $or = newFilters['$or'] || [];

  if (candidate.platformRating === PLATFORM_RATING__A_PLUS) {
    newFilters['$or'] = [...$or, { minYearsOfExperience: { $lte: 0.5 * minYearsOfExperience } }];
    newFilters['$or'].push({ minYearsOfExperience: { $exists: false } });
    delete newFilters.minYearsOfExperience;
  }
  else if (candidate.platformRating === PLATFORM_RATING__A_TOP) {
    newFilters['$or'] = [...$or, { minYearsOfExperience: { $lte: 0.7 * minYearsOfExperience } }];
    newFilters['$or'].push({ minYearsOfExperience: { $exists: false } });
    delete newFilters.minYearsOfExperience;
  }
  else if (candidate.platformRating === PLATFORM_RATING__B_STRONG) {
    newFilters['$or'] = [...$or, { minYearsOfExperience: { $lte: 0.85 * minYearsOfExperience } }];
    newFilters['$or'].push({ minYearsOfExperience: { $exists: false } });
    delete newFilters.minYearsOfExperience;
  }

  return newFilters;

}

export function mapCandidateMatchingJobSalary({ candidate, filters }) {

  const newFilters = { ...filters };

  const { salaryMax } = filters;

  let $or = newFilters['$or'] || [];

  if (candidate.platformRating === PLATFORM_RATING__A_PLUS) {
    newFilters['$or'] = [...$or, { salaryMax: { $gte: 1.5 * salaryMax } }];
    newFilters['$or'].push({ salaryMax: { $exists: false } });
    delete newFilters.salaryMax;
  }
  else if (candidate.platformRating === PLATFORM_RATING__A_TOP) {
    newFilters['$or'] = [...$or, { salaryMax: { $gte: 1.3 * salaryMax } }];
    newFilters['$or'].push({ salaryMax: { $exists: false } });
    delete newFilters.salaryMax;
  }
  else if (candidate.platformRating === PLATFORM_RATING__B_STRONG) {
    newFilters['$or'] = [...$or, { salaryMax: { $gte: 1.15 * salaryMax } }];
    newFilters['$or'].push({ salaryMax: { $exists: false } });
    delete newFilters.salaryMax;
  }

  return newFilters;

}

export function mapJobMatchingCandidateYearsOfExperience({ job, filters }) {

  const newFilters = { ...filters };

  const { yearsOfExperience } = filters;

  let $or = newFilters['$or'] || [];

  newFilters['$or'] = [
    ...$or,
    {
      $and: [
        { platformRating: PLATFORM_RATING__A_PLUS },
        { yearsOfExperience: { $gte: 0.5 * yearsOfExperience } }
      ]
    },
    {
      $and: [
        { platformRating: PLATFORM_RATING__A_TOP },
        { yearsOfExperience: { $gte: 0.7 * yearsOfExperience } }
      ]
    },
    {
      $and: [
        { platformRating: PLATFORM_RATING__B_STRONG },
        { yearsOfExperience: { $gte: 0.85 * yearsOfExperience } }
      ]
    },
    {
      yearsOfExperience: { $gte: yearsOfExperience }
    }
  ];

  delete newFilters.yearsOfExperience;

  return newFilters;

}

export function mapJobMatchingCandidateSalary({ job, filters }) {

  const newFilters = { ...filters };

  const { minimumSalary } = filters;

  let $or = newFilters['$or'] || [];

  newFilters['$or'] = [
    ...$or,
    {
      $and: [
        { platformRating: PLATFORM_RATING__A_PLUS },
        { minimumSalary: { $lte: 1.5 * minimumSalary } }
      ]
    },
    {
      $and: [
        { platformRating: PLATFORM_RATING__A_TOP },
        { minimumSalary: { $lte: 1.3 * minimumSalary } }
      ]
    },
    {
      $and: [
        { platformRating: PLATFORM_RATING__B_STRONG },
        { minimumSalary: { $lte: 1.15 * minimumSalary } }
      ]
    },
    { minimumSalary: { $lte: minimumSalary } }
  ];

  delete newFilters.salaryMax;

  return newFilters;

}
