import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import Core from '../../../lib/Core';
import IconButton from './IconButton';
import {
  mapWrapperName
} from '../Libraries/Theme.lib';
import Typography from './Typography';
import Box from './Box';

/**
 * @see https://mui.com/material-ui/api/app-bar/
 */
export default function AppBar({
  title,
  showBack,
  actionBarLeft,
  contentMiddle,
  contentLeft,
  contentRight,
  style = {},
  debug = false,
  ...props
}) {
  const _goBack = (event) => Core.goBack(props);
  const _propsWrapper = mapWrapperName(
    'AppBar',
    {
      title,
      showBack,
      actionBarLeft,
      contentMiddle,
      contentLeft,
      contentRight,
      style,
      ...props
    }
  );
  if (debug) { console.debug(_propsWrapper); }
  return (
    <MuiAppBar {...props} position='static' className='d-flex flex-align-center bg-white c-black-medium mb-2 scroll-x'>
      <Toolbar className='w-100'>

        {/** LEFT */}
        <Box row flex1 noWrap>

          {actionBarLeft && (
            <div className='mr-2'>
              {actionBarLeft}
            </div>
          )}

          {/** BACK */}
          {showBack && (
            <IconButton
              title='Go back'
              color='default'
              onClick={_goBack}
              className='mr-2'
              icon='arrow_back'
            />
          )}

          {/** TITLE */}
          {title && (
            <Typography
              variant="h6"
              component="div"
              className='d-flex flex-align-left-center flex-1 nowrap c-purple m-0 mr-2 f-xxl fw-500'
              style={{ letterSpacing: 1 }}
            >
              {title}
            </Typography>
          )}

          {contentLeft}

        </Box>

        {/** MIDDLE */}
        <Box row flex1 noWrap alignCenter>
          {contentMiddle}
        </Box>

        {/** RIGHT */}
        <Box row flex1 noWrap alignRight>
          {(contentRight)}
        </Box>

      </Toolbar>
    </MuiAppBar>
  );
};
