import {
  useState
} from "react";
import Core from '../../lib/Core';
import Employer from "../../lib/Employer";
import Job from "../../lib/Job";
import JobFeedbacks from '../Jobs/Edit/Forms/JobFeedbacks';
import Fieldset from '../Layout/Wrappers/Fieldset';
import RichTextBox from "../Layout/Wrappers/RichTextBox";

export default function InterviewDetails({
  engagement
}) {
  const {
    candidate,
    job
  } = engagement;
  const {
    employer
  } = job;
  console.debug({
    candidate,
    job,
    employer,
  });
  const [interviewProcess, setInterviewProcess] = useState(employer.interviewProcess);
  const [jobInterviewProcess, setJobInterviewProcess] = useState(job.jobInterviewProcess);
  const [qualificationNotes, setQualificationNotes] = useState(job.qualificationNotes);
  const [, reRender] = useState();
  console.debug(
    'InterviewDetails...',
    '\n', engagement,
    '\n', job,
  );
  return (
    <>

      <Fieldset
        title='Employer Interview Process'
      >
        <RichTextBox
          value={interviewProcess}
          onChange={(interviewProcess) => {
            setInterviewProcess(interviewProcess);
            clearTimeout(InterviewDetails.timeout);
            InterviewDetails.timeout = setTimeout(() => Employer.update(employer.id, { interviewProcess }), 3000);
          }}
        />
      </Fieldset>

      <Fieldset
        title='Job Interview Process + Interview Rejection Reason'
      >
        <RichTextBox
          value={jobInterviewProcess}
          onChange={(jobInterviewProcess) => {
            setJobInterviewProcess(jobInterviewProcess);
            clearTimeout(InterviewDetails.timeout);
            InterviewDetails.timeout = setTimeout(() => Job.update(job.id, { jobInterviewProcess }), 3000);
          }}
        />
      </Fieldset>

      <Fieldset
        title='Job Insider Scoop + Resume Rejection Reason'
      >
        <RichTextBox
          value={qualificationNotes}
          onChange={(qualificationNotes) => {
            setQualificationNotes(qualificationNotes);
            clearTimeout(InterviewDetails.timeout);
            InterviewDetails.timeout = setTimeout(() => Job.update(job.id, { qualificationNotes }), 3000);
          }}
        />
      </Fieldset>

      <Fieldset
        title={<>Job feedback</>}
        wrapperProps={{ className: 'p-0' }}
        fullWidth
      >
        <JobFeedbacks
          job={job}
          onChange={async (update) => new Promise((resolve) => {
            Object.assign(engagement.job, update);
            reRender(Date.now());
            resolve();
            clearTimeout(InterviewDetails.timeout);
            InterviewDetails.timeout = setTimeout(async () => {
              await Job.update(job.id, update).catch(Core.showError);;
            }, 3000);
          })}
        />
      </Fieldset>

    </>
  );
}
