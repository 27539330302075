import ReactTestUtils from 'react-dom/test-utils';
import React from 'react';
import { MDASH } from '../../lib/Constants';
import { isBoolean, join } from 'lodash';

export function CellTagColumn({ column: { id, accessor, _accessor }, row }) {
  let { original: model = {} } = row;
  // eslint-disable-next-line no-eval
  let children = ((model[_accessor] !== undefined) ? model[_accessor] : eval(`model.${id}`));
  if (Array.isArray(children)) { children = join(children,', '); }
  if (isBoolean(children)) { children = children === true ? 'true' : 'false'; }
  // console.debug('cell', _accessor, id, children);
  return (
    <span className="line-clamp">
      {children && (ReactTestUtils.isElement(children) || (Object(children) !== children)) ? children : MDASH}
    </span>
  );
}
