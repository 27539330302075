import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';
import Box from '../Layout/Wrappers/Box';
import IconButton from '../Layout/Wrappers/IconButton';
import { REACT_TABLE__INITIAL_PAGE } from './useEnhancedReactTable.hook';

function TablePaginationActions({
  page,
  rowsPerPage: pageSize,
  count: totalCount,
  onPageChange,
}) {
  const theme = useTheme();
  page = page + 1;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, REACT_TABLE__INITIAL_PAGE);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(totalCount / pageSize)));
  };

  return (
    <Box row w100 className='ml-4'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === REACT_TABLE__INITIAL_PAGE}
        aria-label="first page"
      >
        {theme.direction === 'rtl'
          ? <i className="material-icons">last_page</i>
          : <i className="material-icons">first_page</i>}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === REACT_TABLE__INITIAL_PAGE}
        aria-label="previous page"
      >
        {theme.direction === 'rtl'
          ? <i className="material-icons">keyboard_arrow_right</i>
          : <i className="material-icons">keyboard_arrow_left</i>}
      </IconButton>
      <input
        type="number"
        style={{
          width: 48,
          textAlign: 'center',
          border: '1px solid #ccc',
          outline: 'none',
          borderRadius: 3,
        }}
        value={page}
        onChange={(event) => onPageChange(event, Number(event.target.value))}
        max={Math.max(0, Math.ceil(totalCount / pageSize))}
      />
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(totalCount / pageSize)}
        aria-label="next page"
      >
        {theme.direction === 'rtl'
          ? <i className="material-icons">keyboard_arrow_left</i>
          : <i className="material-icons">keyboard_arrow_right</i>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(totalCount / pageSize)}
        aria-label="last page"
      >
        {theme.direction === 'rtl'
          ? <i className="material-icons">first_page</i>
          : <i className="material-icons">last_page</i>}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default TablePaginationActions;
