import {
  Arr
} from '../../../../lib/Array.lib';
import {
  getMatchEntities,
  isJobBlackListed,
  jobContainConstraints
} from '../MatchList.lib';

export default function getJobLabels(props) {
  const {
    job = {},
    candidate = {}
  } = getMatchEntities(props);
  const { account: recruiter = {} } = candidate;
  const isCandidateHired = (candidate = {}) => {
    if (!!Arr(candidate.engagements).length) {
      let foo = candidate.engagements.filter((eng) => eng.stage === "Guarantee");
      if (foo.length !== 0) { return true; }
    }
    return false;
  };
  let jobTypes = [];
  if (jobContainConstraints(job, candidate, "jobsPermitted")) {
    jobTypes.push("Permitted");
  }
  if (jobContainConstraints(job, candidate, "jobsPitched")) {
    jobTypes.push("Awaiting");
  }
  if (jobContainConstraints(job, candidate, "jobsDeclined")) {
    jobTypes.push("Declined");
  }
  if (isJobBlackListed(job, candidate)) {
    jobTypes.push("BlackListed");
  }
  else if (isCandidateHired(candidate)) {
    jobTypes.push("Hired");
  }
  else if (recruiter.role === "LimitedRecruiter") {
    jobTypes.push("Limited");
  }
  return jobTypes;
}
