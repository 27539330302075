import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Arr
} from '../../lib/Array.lib';
import Core from "../../lib/Core";
import {
  Str
} from '../../lib/String.lib';
import TemplateLib, {
  TEMPLATE_NAME_HOT_JOBS_TRUSTED,
  TEMPLATE_NAME_RECRUITER_TODO_REMINDER,
  TEMPLATE_TYPE_DOCUMENT,
  TEMPLATE_TYPE_EMAIL
} from "../../lib/Template.lib";
import Col from "../Layout/Wrappers/Col";
import Paper from '../Layout/Wrappers/Paper';
import Loader from '../Shared/Loader';

async function fetchTodoList({
  recruiterId,
  setTodoList
}) {
  const template = await TemplateLib.getBy({
    type: TEMPLATE_TYPE_EMAIL,
    name: TEMPLATE_NAME_RECRUITER_TODO_REMINDER
  });
  TemplateLib.getRender({
    templateId: template.id,
    recruiterId,
    setRenderedTemplate: ({ errors = [], rendered = {}, mixins = {} }) => {
      let { bodyHtml: body = '' } = rendered;
      const postProcessingTemplate = Arr(Str(body).match(/<!-- POST_PROCESSING >>> -->([\s\S]*?)<!-- <<< POST_PROCESSING -->/))[0];
      body = String(body || '').replace(postProcessingTemplate, '');
      let todoList = body.replace(/<strong>.*Your todo:.*<\/strong>|<p>.*<strong>.*Note:.*<\/strong>/ig, 'DIVIDER').split('DIVIDER');
      todoList = todoList.length === 3 ? (`<p>${todoList[1].trim()}`) : '';
      console.debug('body', body);
      console.debug('todoList', todoList);
      setTodoList(body);
    }
  });
};

function RecruiterHome(props) {

  const { t } = useTranslation();

  const recruiterId = Core.getUserId();

  const [todoList, setTodoList] = React.useState(true);
  const [hotJobs, setHotJobs] = React.useState(true);


  React.useEffect(() => {
    if (todoList === true) {
      fetchTodoList({ recruiterId, setTodoList });

    }
  }, [todoList, recruiterId]);

  React.useEffect(() => {
    if (hotJobs === true) {
      TemplateLib.getBy({
        type: TEMPLATE_TYPE_DOCUMENT,
        name: TEMPLATE_NAME_HOT_JOBS_TRUSTED,
        setTemplate: template => setHotJobs(template.templates.content.replace(/<a /gi, '<a target="_blank" rel="noreferrer" '))
      })
    }
  }, [hotJobs]);

  console.debug({ recruiterId, todoList, hotJobs });

  return (
    <div className="d-flex flex-wrap py-2 px-1" style={{ justifyContent: "center" }}>

      <Paper className="w-sm m-1 p-1">
        <Col fullWidth>
          <label className="mb-2">
            {t(`Your todo list`)}
          </label>
          {todoList === true ? (
            <Loader>
              Fetching list
            </Loader>
          ) : !!todoList?.length ? (
            <div dangerouslySetInnerHTML={{ __html: todoList }} />
          ) : (
            <span>{t('recruiter.home.todoList.empty')}</span>
          )}
        </Col>
      </Paper>
      <Paper className="w-sm m-1 p-1">
        <Col>
          <label className="mb-2">
            {t(`Hot jobs`)} {hotJobs?.updatedAt && <>({moment(hotJobs.updatedAt).format('M/D')})</>}
          </label>
        </Col>
        <Col fullWidth>
          {hotJobs === true ? (
            <Loader>
              Fetching list
            </Loader>
          ) : !!hotJobs?.length ? (
            <div dangerouslySetInnerHTML={{ __html: hotJobs }} />
          ) : (
            <span>Document not found</span>
          )}
        </Col>
      </Paper>
    </div>
  );
}

export {
  RecruiterHome as default,
  RecruiterHome
};
