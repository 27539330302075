export const PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN = {
  id: 'ten_by_ten',
  label: '10x10'
};

export const PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER = {
  id: 'employer',
  label: 'Employer'
};

export const PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER = {
  id: 'job_specific_answer',
  label: 'Job specific answer'
};
export const PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER = {
  id: 'global_answer',
  label: 'Global answer'
};
export const PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS = {
  id: 'all_jobs',
  label: 'All jobs'
};

const PrescreenQuestionsSectionUI = {
  name: 'prescreen_questions_section',
  collection: 'PrescreenQuestionTemplates',
  collectionEmployer: 'Employers',
  fieldSet: {
    title: 'Employer prescreen questions',
    subtitleEmployer:
      <>
        Employer questions that applies to all jobs. To select questions from the reusable question bank, click on the selection drop down next to the ADD QUESTION button
      </>
    ,
    subtitleJob: (
      <i>
        Question column visible to employer and recruiting partner. Instruction column visible to recruiting partner<br />
        Update the "Order" column to automatically reorder the sequence
      </i>
    ),
    add: 'Add question',
    noData: 'No prescreen questions yet. Click on the "Add" button to add a question.'
  },
  dataFieldName: 'mustHaveQuestions',
  scrollToDivId: 'pre_scr_qst_scr_id',
  askedBy: {
    data: [
      PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN,
      PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER,
    ]
  },
  answerApplyTo: {
    data: [
      PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER,
      PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER,
    ],
  },
  columnHeaders: {
    order: 'Order',
    question: 'Question',
    answerMandatory: 'Answer mandatory',
    askedBy: 'Asked by',
    questionApplyTo: 'Question apply to',
    answerApplyTo: 'Answer apply to',
    autoIncludeInWriteup: 'Include in writeup',
    instruction: 'Instruction',
    author: 'Author',
  },
  columnWidth: {
    order: 100,
    question: 320,
    answerMandatory: 80,
    askedBy: 120,
    questionApplyTo: 160,
    answerApplyTo: 160,
    autoIncludeInWriteup: 100,
    instruction: 416,
  },

  manageQuestionsBankButtonLabel: 'Manage questions bank',

  questionApplyTo: {
    data: [
      PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS,
    ],
  },
  requiredFieldLabel: 'This field is required',

  readOnlySection: {
    title: 'PRE-SCREEN QUESTIONS'
  }
};
export default PrescreenQuestionsSectionUI;
