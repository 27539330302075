const events = [];
/** * /
setTimeout(st => {
  [
    {
      title: (
        <a
          onClick={ev => {
            alert("works!");
          }}
        >
          All Day Event
        </a>
      ),
      allDay: true,
      start: new Date(2015, 3, 0),
      end: new Date(2015, 3, 1),
      type: "follow-up"
    },
    {
      title: "Long Event",
      start: new Date(2015, 3, 7),
      end: new Date(2015, 3, 8),
      type: "interview"
    },

    {
      title: "DTS STARTS",
      start: new Date(2016, 2, 13, 0, 0, 0),
      end: new Date(2016, 2, 20, 0, 0, 0),
      type: "follow-up"
    },

    {
      title: "DTS ENDS",
      start: new Date(2016, 10, 6, 0, 0, 0),
      end: new Date(2016, 10, 13, 0, 0, 0),
      type: "interview"
    },

    {
      title: "Some Event",
      start: new Date(2015, 3, 9, 0, 0, 0),
      end: new Date(2015, 3, 9, 0, 0, 0),
      type: "follow-up"
    },
    {
      title: "Conference",
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people",
      type: "interview"
    },
    {
      title: (
        <a
          onClick={ev => {
            alert("works!");
          }}
        >
          Conference 2
        </a>
      ),
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people",
      type: "interview"
    },
    {
      title: "Conference 3",
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people",
      type: "follow-up"
    },
    {
      title: "Meeting",
      start: new Date(2015, 3, 12, 10, 30, 0, 0),
      end: new Date(2015, 3, 12, 12, 30, 0, 0),
      desc: "Pre-meeting meeting, to prepare for the meeting",
      type: "interview"
    },
    {
      title: "Lunch",
      start: new Date(2015, 3, 12, 12, 0, 0, 0),
      end: new Date(2015, 3, 12, 13, 0, 0, 0),
      desc: "Power lunch",
      type: "follow-up"
    },
    {
      title: "Meeting",
      start: new Date(2015, 3, 12, 14, 0, 0, 0),
      end: new Date(2015, 3, 12, 15, 0, 0, 0),
      type: "interview"
    },
    {
      title: "Happy Hour",
      start: new Date(2015, 3, 12, 17, 0, 0, 0),
      end: new Date(2015, 3, 12, 17, 30, 0, 0),
      desc: "Most important meal of the day",
      type: "follow-up"
    },
    {
      title: "Dinner",
      start: new Date(2015, 3, 12, 20, 0, 0, 0),
      end: new Date(2015, 3, 12, 21, 0, 0, 0),
      type: "interview"
    },
    {
      title: "Birthday Party",
      start: new Date(2015, 3, 13, 7, 0, 0),
      end: new Date(2015, 3, 13, 10, 30, 0),
      type: "follow-up"
    }
  ].forEach(item => events.push(item));
}, 1000);
/** */
//
// setTimeout(function(){
//   Engagement.getAll(engagements => {
//     engagements.forEach(engagement => {
//       const candidate = engagement.candidate;
//       if (engagement.screen1) {
//         events.push({
//           title: <div>{candidate._name}'s 1st screen interview</div>,
//           start: moment(engagement.screen1).toDate(),
//           end: moment(engagement.screen1)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "screen" : "screen-inactive"
//         });
//       }
//       if (engagement.screen2) {
//         events.push({
//           title: <div>{candidate._name}'s 2nd screen interview</div>,
//           start: moment(engagement.screen2).toDate(),
//           end: moment(engagement.screen2)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "screen" : "screen-inactive"
//         });
//       }
//       if (engagement.screen3) {
//         events.push({
//           title: <div>{candidate._name}'s 3nd screen interview</div>,
//           start: moment(engagement.screen3).toDate(),
//           end: moment(engagement.screen3)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "screen" : "screen-inactive"
//         });
//       }
//       if (engagement.onsite1) {
//         events.push({
//           title: <div>{candidate._name}'s 1st onsite interview</div>,
//           start: moment(engagement.onsite1).toDate(),
//           end: moment(engagement.onsite1)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "onsite" : "onsite-inactive"
//         });
//       }
//       if (engagement.onsite2) {
//         events.push({
//           title: <div>{candidate._name}'s 2nd onsite interview</div>,
//           start: moment(engagement.onsite2).toDate(),
//           end: moment(engagement.onsite2)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "onsite" : "onsite-inactive"
//         });
//       }
//       if (engagement.startDate) {
//         let title = `${candidate._name}, Starts ${moment(
//             engagement.startDate
//         ).fromNow()} at ${engagement._employerName}`;
//         //Core.log({ start: title });
//         events.push({
//           title: <div>{title}</div>,
//           tooltip: title,
//           start: moment(engagement.startDate).toDate(),
//           end: moment(engagement.startDate)
//               .add(1, "h")
//               .toDate(),
//           type: /open/i.test(engagement.state) ? "start" : "start-inactive"
//         });
//       }
//     });
//   });
//
// })

export default events;
