import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_SUBROLE,
  ACCOUNT_ACTION__MANAGE_SUBROLES
} from '../../../lib/Definition';
import useState from '../../../lib/hooks/useState.hook';
import {
  COLLECTION__ACCOUNT_SUBROLES,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Menu from '../../Layout/Wrappers/Menu';
import Typography from '../../Layout/Wrappers/Typography';
import {
  CONFIG__ACCOUNT_SUBROLES
} from './ManageAccountSubroles';

const CONFIG = {
  fieldset: {
    title: 'Subrole',
    actionBar: {
      button: ''
    }
  }
}

export function SelectSubrole(props) {
  let {
    account,
    onChange = (subroleId) => null,
    disabled = !Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_SUBROLE })
  } = props;
  let [state, _updateState] = useState();
  let { subroles = [] } = state;
  const _fetchSubroles = async () => {
    await _updateState({ fetching: true });
    await _updateState({
      subroles: Arr(
        await readLoopbackRecord({
          collection: COLLECTION__ACCOUNT_SUBROLES
        }).catch(Core.showError)
      ).sort((a, b) => (a.order - b.order))
    });
    await _updateState({ fetching: false, fetched: true });
  }
  if (!state.fetching && !state.fetched) {
    _fetchSubroles();
  }
  return (
    <Fieldset
      title={CONFIG.fieldset.title}
      actionBar={(
        <Box
          title={
            (
              disabled
            ) ? (
              <Typography className='c-yellow'>
                Manage subroles is disabled
              </Typography>
            ) : (
              'Open manage subroles'
            )
          }
        >
          <Button flat primary small
            onClick={event => Core.openPopUp(Core.getPath('/manage/account-subroles'), 1200)}
            disabled={!Core.isAdmin({ action: ACCOUNT_ACTION__MANAGE_SUBROLES })}
            startIcon='launch'
          >
            {CONFIG__ACCOUNT_SUBROLES.button.label}
          </Button>
        </Box>
      )}
    >
      <Menu dropdown
        name='accounts__edit__select_subrole__dropdown'
        value={account.subroleId}
        onChange={onChange}
        options={subroles}
        disabled={disabled}
      />
    </Fieldset>
  )
}
