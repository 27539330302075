import {
  Component
} from "react";
import {
  NOT
} from '../../../lib/Boolean.lib';
import Candidate from "../../../lib/Candidate";
import Core from '../../../lib/Core';
import Definition from "../../../lib/Definition";
import {
  CANDIDATE_MODEL_NAME
} from '../../../lib/models/candidate';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Divider from '../../Layout/Wrappers/Divider';
import Menu from '../../Layout/Wrappers/Menu';
import Switch from '../../Layout/Wrappers/Switch';
import {
  handlerSkippedMatch
} from '../Libraries/MatchList.lib';
import CreateDisagreement from "./CreateDisagreement";
import {
  ListPipeController
} from './ListPipe';
import SelectedBulkJob from "./SelectedBulkJob";
import ThumbsBar from "./ThumbsBar";

export default class ListHeader extends Component {
  constructor() {
    super(...arguments);
    this.role = 'ListHeader';
    this.state = {
      ...this.props
    };
  }

  componentDidMount() {
    if (!!ListPipeController().state.annotatorMode) {
      this.toggleAnnotatorMode();
    }
  }

  handleScroll = (e, sectionId, key) => {
    let previousActiveLink = document.getElementsByClassName(
      "job-list-tab-active"
    )[0];
    if (previousActiveLink !== undefined) {
      previousActiveLink.className = "";
    }
    let section = document.getElementById(sectionId);
    if (section !== null) {
      section.scrollIntoView();
      e.target.classList.add("job-list-tab-active");
    } else {
      const { scrollToIndex } = this.props;
      !!scrollToIndex && scrollToIndex(key);
    }
  };

  render() {

    if (NOT(this.props.acl)) { return null; }

    const {
      selectedMatch = {},
      selectedMatches = [],
      matchKey = '',
      countForThumbBarHeading,
    } = this.props;
    return (
      <Box column w100 className={this.props.className}>

        <Box style={{ minHeight: 120 }}>

          <Box row w100 className="p-05">
            <Box row w100 className="mr-auto">
              <Button small outlined color='inherit'
                acl={selectedMatch.id}
                label="Skip"
                onClick={handlerSkippedMatch}
                className='mr-1'
              />
              {!!selectedMatches.length && matchKey === "job" && (
                <SelectedBulkJob
                  selectedMatches={selectedMatches}
                  selectedMatch={selectedMatch}
                />
              )}
            </Box>
            <ThumbsBar Controller={this} />
          </Box>

          <Box row w100 spaceBetween className="p-05">
            <Switch
              checked={ListPipeController().state.annotatorMode}
              onChange={ListPipeController().handleAnnotatorMode}
              label='Annotator Mode'
            />
            <Menu dropdown small
              name={`thumb_bar__model`}
              value={ListPipeController().state.currentMlModel}
              onChange={ListPipeController().handlerMLScoreMode}
              options={Definition.get("machineLearningModel")}
            />
          </Box>

          <Box row w100 spaceBetween className="f-sm fw-500 p-05">
            {!!countForThumbBarHeading &&
              Object.keys(countForThumbBarHeading).map((link, index) => (
                <span
                  key={`thumb_bar__tab__${index}`}
                  className="anchor mr-1 decoration-none"
                  onClick={(e) =>
                    this.handleScroll(e,
                      `list-card-section-${countForThumbBarHeading[link].label}`,
                      countForThumbBarHeading[link].key
                    )
                  }
                >
                  <strong>
                    {countForThumbBarHeading[link].label}{" "}
                    {`(${countForThumbBarHeading[link].count})`}
                  </strong>
                </span>
              ))}
          </Box>

        </Box >

        <Divider />

        <CreateDisagreement Controller={this} />

      </Box>
    );
  }
}

export async function saveCandoPrivateNoteAddition({
  candidate,
  candoPrivateNoteText,
  jobsPermittedAnswers,
  engagement
}) {
  if (candidate.___model___ === CANDIDATE_MODEL_NAME) {
    let finalNotes = candidate.privateNotes;
    if (!!candoPrivateNoteText) {
      finalNotes = candidate.privateNotes
        ? candidate.privateNotes + "\n" + candoPrivateNoteText
        : candoPrivateNoteText;
    }
    return Candidate.update(candidate.id, {
      privateNotes: finalNotes,
      jobsPermittedAnswers,
    }).catch(Core.showError);
  }
};
