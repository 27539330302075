const SourceListHash = (collectedSourceLists, hashKey) => {
    let hash = {};
    let name = "", formatDate = "";
    let date;

    collectedSourceLists.forEach(resp => {
        date = [];

        if (resp[hashKey] in hash) {

        } else {
            hash[resp[hashKey]] = [];
        }
        if(hashKey==='jobId'){
            name = `${resp.account.firstName} ${resp.account.lastName}`;
        }else{
            name = `${resp.job.jobTitle}`;
        }

        if (!!resp.startDate) {
            formatDate = new Date(resp.startDate);
            date.push(`${formatDate.getMonth() + 1}/${formatDate.getDate()}/${formatDate.getFullYear()}`);
        }

        if (!!resp.endDate) {
            formatDate = new Date(resp.endDate);
            date.push(`${formatDate.getMonth() + 1}/${formatDate.getDate()}/${formatDate.getFullYear()}`);
        }

        hash[resp[hashKey]].push(`${name} (${date.join(" - ")})`);
    });

    return hash;
}

export default SourceListHash;
