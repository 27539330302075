import {
  CircularProgress
} from '@mui/material';
import {
  debounce
} from 'lodash';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import {
  formatDate
} from '../../../lib/Date.lib';
import Definition, {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_JOBS,
  DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
  DEFINITION_CATEGORY__POSITIVE_SIGNALS
} from '../../../lib/Definition';
import globalErrorHandler from '../../../lib/Error/globalErrorHandler.fun';
import {
  copyString
} from '../../../lib/GenericTools.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  getPersonName
} from '../../../lib/Object.lib';
import QUERY__REPORTS__ENGAGEMENTS from '../../../lib/queries/ReportsEngagements.query';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  openMessageEmailPreview
} from '../../../lib/services/Email/Email.lib';
import {
  joinKeyName,
  trim
} from '../../../lib/String.lib';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import Box from './Box';
import Button from './Button';
import Chip from './Chip';
import Fieldset from './Fieldset';
import Icon from './Icon';
import IconButton from './IconButton';
import {
  PLACEMENT__TOP_START
} from './StyledTooltip';
import Switch from './Switch';
import Table from './Table';
import TextField from './TextField';
import Typography from './Typography';

export const KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON = 'common';
export const KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER = 'recruiter';
export const KEY__TABLE_COLLECTION__VIEW_TYPE__CANDIDATE = 'candidate';
export const KEY__TABLE_COLLECTION__VIEW_TYPE__EMPLOYER = 'employer';
export const KEY__TABLE_COLLECTION__VIEW_TYPE__JOB = 'job';

const COL__COMMON__CLASSNAME = 'p-05 px-1 border-bottom';
const COL__HEADER__CLASSNAME = joinClassName([
  COL__COMMON__CLASSNAME,
  'sticky-top bg-cyan-common c-white-all',
]);


/**
 *
 * @param {Object} props
 * @param {string} [props.title] title of the table
 * @param {Object} [props.actionBar] action bar component
 * @param {Object} [props.context]
 * Used set a reference outside component reference with 
 * the current instance of the exports object. 
 * ex. context.TableCollection = TableCollection;
 * then you can call context.TableCollection.updateState({});
 * @param {Object} [props.wrapperProps] wrapper properties
 *
 * @param {Object} props.defaultQuery default query
 * @param {string} [props.collection=COLLECTION__ENGAGEMENTS] collection name
 * @param {Object} [props.where={}] where filter
 *
 * @param {Function} [props.mapper] data mapper function
 * @param {Function} [props.filter] data filter function
 * @param {Function} [props.filterColumns] filter columns function
 *
 * @param {boolean} [props.isDrawer=false] is drawer flag
 *
 * @returns {JSX.Element} TableCollection component
 * 
 * @todo Make this component generic and move Engagements features to a separate component.
 */
export default function TableCollection({
  /** CONFIGURATION QUERY */
  defaultQuery = QUERY__REPORTS__ENGAGEMENTS, // ex. { fields: ['id'],include: [{ relation: 'candidate', scope: { fields: ['id'] } }] }
  collection = COLLECTION__ENGAGEMENTS,
  where: propsWhere = {},

  /** LOCAL DATA CUSTOMIZATION */
  mapper = (engagements) => Arr(engagements).map(
    (engagement) => {
      return {
        ...engagement,
        ID__RECRUITER: engagement.candidate?.accountId,
        ID__EMPLOYER: engagement.job?.employerId,
        ID__JOB: engagement.jobId,
        NAME__CANDIDATE: getPersonName(engagement.candidate),
        NAME__EMPLOYER: engagement.job?.employer?.name,
        NAME__JOB: engagement.job?.jobTitle,
        NAME__RECRUITER: getPersonName(engagement.candidate?.account),
        VALUE__STAGE: engagement.stage,
        VALUE__STATUS: engagement.status,
        VALUE__STATE: engagement.state,
        VALUE__REJECTION_REASON: engagement.rejectionReason,
        VALUE__REJECTION_REASON__ADDITIONAL_INFO: engagement.rejectionReasonAdditionalInfo,
        VALUE__FEEDBACK: engagement.empFBText,
        VALUES__POSITIVE_SIGNALS: Definition.getLabels(
          DEFINITION_CATEGORY__POSITIVE_SIGNALS,
          engagement.candidate?.positiveSignals
        ),
        VALUES__NEGATIVE_SIGNALS: Definition.getLabels(
          DEFINITION_CATEGORY__NEGATIVE_SIGNALS,
          engagement.candidate?.negativeSignals
        ),
        DATE__SHORT__INTRODUCED: formatDate(engagement.introduced),
        DATE__SHORT__MATCHED: formatDate(engagement.matched),
        DATE__SHORT__SUBMITTED: formatDate(engagement.submitted),
        DATE__SHORT__REVIEWED: formatDate(engagement.reviewed),
        DATE__SHORT__SCREENED: formatDate(engagement.screened),
        DATE__SHORT__SCREEN_1: formatDate(engagement.screen1),
        DATE__SHORT__SCREEN_2: formatDate(engagement.screen2),
        DATE__SHORT__SCREEN_3: formatDate(engagement.screen3),
        DATE__SHORT__ONSITE: formatDate(engagement.onsite),
        DATE__SHORT__ONSITE_1: formatDate(engagement.onsite1),
        DATE__SHORT__ONSITE_2: formatDate(engagement.onsite2),
        DATE__SHORT__OFFERED: formatDate(engagement.offered),
        URL__CANDIDATE__EDIT: `/candidate/edit/${engagement.candidateId}`,
        URL__ENGAGEMENT__VIEW: `/engagement/view/${engagement.id}`,
        URL__EMPLOYER__EDIT: `/employer/edit/${engagement.job?.employerId}`,
        URL__JOB__VIEW: `/job/view/${engagement.jobId}`,
        URL__RECRUITER__EDIT: `/account/edit/${engagement.candidate?.accountId}`,
      }
    }
  ),
  filter: propsFilter = (data) => Arr(data), // ex. data.filter(context => context.ID__RECRUITER === Core.getAccountId()),

  /** @todo */
  filterColumns = (row) => ({}),

  /** CONFIGURATION */
  viewType: propsViewType = KEY__TABLE_COLLECTION__VIEW_TYPE__COMMON,

  /** FLAGS */
  isDrawer = false,

  /** WRAPPER PROPS */
  title = null,
  actionBarBottomRight = null,
  context = {},
  wrapperProps = {},
  ...props
}) {

  // STATE LOGIC
  // set "ready" to false when "where" is updated from props.
  const [{
    filter = propsFilter,
    ...state
  }, updateState] = useStateObserved({
    observed: { where: propsWhere },
    state: {
      flagShowDates: false,
      viewType: propsViewType,
    },
    onChange: () => updateState({ ready: false })
  });

  // DATA SOURCE LOGIC
  debounce(async () => {
    if (NOT(state.busy || state.error || state.ready) && !!Object.keys(state.where).length) {
      await updateState({ busy: true });
      try {
        await updateState({
          data: Arr(
            await readLoopbackRecord({
              ...defaultQuery,
              collection,
              where: state.where,
              filter,
              mapper,
            })
          ),
        });
        await updateState({ ready: true });
      }
      catch (error) {
        globalErrorHandler(error);
        await updateState({ error });
      }
      await updateState({ busy: false });
    }
  })();

  // EXPORTS (state, methods, etc)
  Object.assign(
    context,
    {
      TableCollection: {
        state,
        updateState
      }
    }
  );

  const _generateSummary = () => Arr(state.data).map(
    (model) => '- ' + join([
      model.NAME__CANDIDATE,
      model.NAME__EMPLOYER,
      model.NAME__JOB,
      model.VALUE__STAGE,
      model.VALUE__STATUS,
      model.VALUE__REJECTION_REASON,
      model.VALUE__REJECTION_REASON__ADDITIONAL_INFO,
      join(model.VALUES__NEGATIVE_SIGNALS),
      trim(model.VALUE__FEEDBACK).replace(/[\n\r]/g, ' ')
    ], ' | ')
  ).join('\n\n');

  // FLAGS
  const FLAG__SHOW_DATES = !!state.flagShowDates;
  const FLAG__VIEW_TYPE__RECRUITER = state.viewType === KEY__TABLE_COLLECTION__VIEW_TYPE__RECRUITER;
  const FLAG__SHOW__RECRUITER_SUMMARY = !!state.showRecruiterSummary;

  // VALUES
  const VALUE__RECRUITER_SUMMARY = (
    (state.recruiterSummary !== undefined)
      ? state.recruiterSummary
      : _generateSummary()
  );

  // RENDER
  return (
    <Box column flex1>

      {/** TOP BAR */}
      <Box row flex1
        className='sticky-top bg-white border-bottom space-between p-1'
        style={{ minHeight: '3rem', zIndex: 3 }}
      >
        {/** TITLE */}
        <Box noWrap scroll flex1>
          <Typography className='f-xxxl c-purple flex-align-left-center px-1'>
            {title}
          </Typography>
        </Box>

        {/** ACTIONS BAR - TOP-RIGHT */}
        <Box row noWrap scroll
          style={{ maxHeight: 'calc(100vh - 10rem)' }}
        >
          <Switch small
            checked={FLAG__SHOW_DATES}
            onChange={(event) => updateState({
              flagShowDates: !FLAG__SHOW_DATES
            })}
            label={
              FLAG__SHOW_DATES
                ? 'Hide dates'
                : 'Show dates'
            }
            className='mr-1'
          />
          <IconButton
            acl={isDrawer}
            icon='arrow_forward_ios'
            onClick={(event) => Core.closeDrawer()}
          />
        </Box>

      </Box>

      {/** TABLE */}
      <Box row scroll flex1>
        <Table styled>
          <Table.Head>
            <Table.Row>
              <Table.Cell
                className={joinClassName([
                  'sticky-start',
                  COL__HEADER__CLASSNAME
                ])}
                style={{ zIndex: 2 }}
              >
                #
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Action
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Employer
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Job
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Candidate
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Recruiter
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Stage/Status
              </Table.Cell>
              {FLAG__SHOW_DATES &&
                <>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Introduced
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Matched
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Submitted
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Reviewed
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Screened
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Onsite
                  </Table.Cell>
                  <Table.Cell className={COL__HEADER__CLASSNAME}>
                    Offered
                  </Table.Cell>
                </>
              }
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Why No
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Feedback
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Positive Signals
              </Table.Cell>
              <Table.Cell className={COL__HEADER__CLASSNAME}>
                Negative Signals
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>

            {/** INDICATOR: LOADING */}
            {state.busy &&
              <Table.Row>
                <Table.Cell colSpan={13} className='text-muted'>
                  <CircularProgress className='m-2' />
                </Table.Cell>
              </Table.Row>
            }

            {/** INDICATOR: EMPTY */}
            {state.ready && NOT(Arr(state.data).length) &&
              <Table.Row>
                <Table.Cell colSpan={13} className='text-muted placeholder'>
                  No engagements found
                </Table.Cell>
              </Table.Row>
            }

            {/** DATA */}
            {Arr(state.data).map((
              model,
              index
            ) => (
              <Table.Row
                key={
                  joinKeyName([
                    model.URL__ENGAGEMENT__VIEW,
                    index
                  ])
                }
                className={joinClassName([
                  (model.VALUE__STATE === 'Closed') && 'opacity-50',
                ])}
              >

                {/** INDEX */}
                <Table.Cell className={joinClassName(['align-center sticky-start', COL__COMMON__CLASSNAME])}>
                  {index + 1}
                </Table.Cell>

                {/** ACTION */}
                <Table.Cell className={joinClassName(['align-center', COL__COMMON__CLASSNAME])}>
                  <IconButton primary icon='visibility'
                    onClick={(event) => Core.openPopUp(model.URL__ENGAGEMENT__VIEW, 1600)}
                    title='Click to see engagement details'
                    placement={PLACEMENT__TOP_START}
                  />
                </Table.Cell>

                {/** EMPLOYER */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Button flat primary minW120
                    onClick={(event) => Core.openPopUp(model.URL__EMPLOYER__EDIT, 1600)}
                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }))}
                    className='align-left'
                  >
                    {model.NAME__EMPLOYER}
                  </Button>
                </Table.Cell>

                {/** JOB */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Button flat primary minW120
                    onClick={(event) => Core.openPopUp(model.URL__JOB__VIEW, 1600)}
                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }))}
                    className='align-left'
                  >
                    {model.NAME__JOB}
                  </Button>
                </Table.Cell>

                {/** CANDIDATE */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Button flat primary mr1 minW120
                    onClick={(event) => Core.openPopUp(model.URL__CANDIDATE__EDIT, 1600)}
                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }))}
                    className='align-left'
                  >
                    {model.NAME__CANDIDATE}
                  </Button>
                </Table.Cell>

                {/** RECRUITER */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Button flat primary minW120
                    onClick={(event) => Core.openPopUp(model.URL__RECRUITER__EDIT, 1600)}
                    disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }))}
                    className='align-left'
                  >
                    {model.NAME__RECRUITER}
                  </Button>
                </Table.Cell>

                {/** STAGE/STATUS */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Box column>
                    <Box row flex1>
                      <Chip small className="p-0 px-05">
                        {model.VALUE__STAGE}
                      </Chip>
                      <Chip small>
                        {model.VALUE__STATUS}
                      </Chip>
                    </Box>
                    <Chip small acl={model.VALUE__REJECTION_REASON}>
                      <Box row>
                        <Icon mr icon='info'
                          className='icon16 c-gray'
                          placement={PLACEMENT__TOP_START}
                          title={model.VALUE__REJECTION_REASON__ADDITIONAL_INFO ?
                            (
                              <Box column>
                                <Typography
                                  acl={
                                    NOT(
                                      trim(
                                        model.VALUE__REJECTION_REASON__ADDITIONAL_INFO
                                      ).includes(
                                        model.VALUE__REJECTION_REASON
                                      )
                                    )
                                  }
                                  className='f-sm c-gray-common'
                                >
                                  {model.VALUE__REJECTION_REASON}
                                </Typography>
                                {model.VALUE__REJECTION_REASON__ADDITIONAL_INFO}
                              </Box>
                            ) : null
                          }
                          titleStyle={{ maxWidth: 320 }}
                        />
                        {model.VALUE__REJECTION_REASON}
                      </Box>
                    </Chip>
                  </Box>
                </Table.Cell>

                {FLAG__SHOW_DATES &&
                  <>
                    {/** DATE INTRODUCED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      {model.DATE__SHORT__INTRODUCED}
                    </Table.Cell>

                    {/** DATE MATCHED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      {model.DATE__SHORT__MATCHED}
                    </Table.Cell>

                    {/** DATE SUBMITTED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      {model.DATE__SHORT__SUBMITTED}
                    </Table.Cell>

                    {/** DATE REVIEWED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      {model.DATE__SHORT__REVIEWED}
                    </Table.Cell>

                    {/** DATE SCREENED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      <Box column>
                        <div>{model.DATE__SHORT__SCREEN_1}</div>
                        <div>{model.DATE__SHORT__SCREEN_2}</div>
                        <div>{model.DATE__SHORT__SCREEN_3}</div>
                      </Box>
                    </Table.Cell>

                    {/** DATE ONSITE */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      <Box column>
                        <div>{model.DATE__SHORT__ONSITE_1}</div>
                        <div>{model.DATE__SHORT__ONSITE_2}</div>
                      </Box>
                    </Table.Cell>

                    {/** DATE OFFERED */}
                    <Table.Cell className={COL__COMMON__CLASSNAME}>
                      {model.DATE__SHORT__OFFERED}
                    </Table.Cell>
                  </>
                }

                {/** WHY NO */}
                <Table.Cell className={COL__COMMON__CLASSNAME} style={{ minWidth: 320 }}>
                  {model.VALUE__REJECTION_REASON__ADDITIONAL_INFO}
                </Table.Cell>

                {/** FEEDBACK */}
                <Table.Cell className={COL__COMMON__CLASSNAME} style={{ minWidth: 480 }}>
                  {model.VALUE__FEEDBACK}
                </Table.Cell>

                {/** POSITIVE SIGNALS */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Box row wrap style={{ minWidth: 720 }}>
                    {Arr(model.VALUES__POSITIVE_SIGNALS).slice(0, 6).map((label, index) => (
                      <Chip small mr1
                        key={joinKeyName([
                          'table_collection',
                          'positive',
                          label,
                          index
                        ])}
                        label={label}
                        className='bg-green-light c-black-medium p-0 px-05'
                      />
                    ))}
                    <Icon
                      acl={Arr(model.VALUES__POSITIVE_SIGNALS).length > 6}
                      icon='more_horiz'
                      className='icon16 c-gray'
                      placement={PLACEMENT__TOP_START}
                      title={
                        <Box column>
                          <Typography className='f-sm'>
                            {model.NAME__EMPLOYER} - {model.NAME__JOB}
                          </Typography>
                          <Typography className='f-sm'>
                            {model.NAME__CANDIDATE}
                          </Typography>
                          <Box row wrap className='bg-white-common rounded pt-05'>
                            {Arr(model.VALUES__POSITIVE_SIGNALS).map((label, index) => (
                              <Chip small mr1
                                key={joinKeyName([
                                  'table_collection',
                                  'positive',
                                  label,
                                  index
                                ])}
                                label={label}
                                className='bg-green-light c-black-medium p-0 px-05'
                              />
                            ))}
                          </Box>
                        </Box>
                      }
                      titleStyle={{
                        maxWidth: 320
                      }}
                    />
                  </Box>
                </Table.Cell>

                {/** NEGATIVE SIGNALS */}
                <Table.Cell className={COL__COMMON__CLASSNAME}>
                  <Box row wrap style={{ minWidth: 240 }}>
                    {Arr(model.VALUES__NEGATIVE_SIGNALS).slice(0, 6).map((label, index) => (
                      <Chip small mr1
                        key={joinKeyName([
                          'table_collection',
                          'negative',
                          label,
                          index
                        ])}
                        label={label}
                        className='bg-red-light c-black-medium p-0 px-05'
                      />
                    ))}
                    <Icon
                      acl={Arr(model.VALUES__NEGATIVE_SIGNALS).length > 6}
                      icon='more_horiz'
                      className='icon16 c-gray'
                      placement={PLACEMENT__TOP_START}
                      title={
                        <Box column>
                          <Typography className='f-sm'>
                            {model.NAME__EMPLOYER} - {model.NAME__JOB}
                          </Typography>
                          <Typography className='f-sm'>
                            {model.NAME__CANDIDATE}
                          </Typography>
                          <Box row wrap className='bg-white-common rounded pt-05'>
                            {Arr(model.VALUES__NEGATIVE_SIGNALS).map((label, index) => (
                              <Chip small mr1
                                key={joinKeyName([
                                  'table_collection',
                                  'negative',
                                  label,
                                  index
                                ])}
                                label={label}
                                className='bg-red-light c-black-medium p-0 px-05'
                              />
                            ))}
                          </Box>
                        </Box>
                      }
                      titleStyle={{
                        maxWidth: 320
                      }}
                    />
                  </Box>
                </Table.Cell>

              </Table.Row>

            ))}
          </Table.Body>
        </Table>
      </Box>

      {/** BOTTOM BAR */}
      <Box column flex1
        className='sticky-bottom bg-white border-top'
      >

        {/** RECRUITER SUMMARY */}
        <Box column flex1
          acl={FLAG__SHOW__RECRUITER_SUMMARY}
          className='p-1 border-bottom'
        >

          <Fieldset
            title='Recruiter Summary'
          >

            {/** INPUT - RECRUITER SUMMARY */}
            <TextField multiline
              maxRows={6}
              value={VALUE__RECRUITER_SUMMARY}
              onChange={(event, value) => {
                updateState({ recruiterSummary: value });
              }}
            />

            {/** ACTIONS BAR - RECRUITER SUMMARY */}
            <Box row noWrap
              className='mt-05'
            >
              <Button small outlined
                onClick={async () => {
                  await updateState({ showRecruiterSummary: false });
                  copyString(VALUE__RECRUITER_SUMMARY);
                }}
                className='min-w-160 mr-1'
              >
                <Icon icon='content_copy' className='icon16 mr-05' />
                Copy to clipboard
              </Button>
              <Button small secondary
                className='c-white-hover min-w-180'
                onClick={async () => {
                  await updateState({ showRecruiterSummary: false });
                  openMessageEmailPreview({
                    subject: 'Recruiter Summary',
                    body: VALUE__RECRUITER_SUMMARY.replace(/\n/g, '<br/>')
                  })
                }}
              >
                <Icon icon='mail' className='icon16 mr-05' />
                Open in Email
              </Button>
            </Box>

          </Fieldset>

        </Box>

        {/** ACTIONS BAR - BOTTOM */}
        <Box row flex1
          className='space-between p-1'
          style={{ minHeight: '3rem', zIndex: 3 }}
        >

          {/** ACTIONS BAR - BOTTOM-LEFT */}
          <Box row>
            <Button small secondary
              acl={FLAG__VIEW_TYPE__RECRUITER}
              className='mr-1'
              label='Summary for Recruiter'
              onClick={() => {
                updateState({
                  showRecruiterSummary: !FLAG__SHOW__RECRUITER_SUMMARY
                })
              }}
            />
          </Box>

          {/** ACTIONS BAR - BOTTOM-RIGHT */}
          <Box row noWrap>
            {actionBarBottomRight}
          </Box>

        </Box>
      </Box>

    </Box>
  )

}
