import {
  useRef,
  useState
} from 'react';
import Draggable from 'react-draggable';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import {
  RefBox
} from './Box';
import Button from './Button';
import Icon from './Icon';
import IconButton from './IconButton';

/**
 * 
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.dialogTitle
 * @param {string} props.className
 * @param {object} props.styleDialog
 * @param {object} props.children
 * @param {boolean} props.show
 * @param {function} props.reference
 * @param {boolean} props.minimized
 * @param {function} props.onMaximized
 * @param {object} props.position
 * @returns 
 */
function StickyNote(props) {
  let {
    title,
    dialogTitle,
    className,
    styleDialog = {},
    children,
    show = true,
    reference: exposeMethods = () => null,
    minimized: defaultMinimized = false,
    onMaximized = () => null,
    position: propsPosition = { top: 72, right: 72 }
  } = props;
  let [position, setPosition] = useState(propsPosition);
  const [isMinimized, setIsMinimized] = useState(defaultMinimized);
  const [isDragging, setIsDragging] = useState(false);
  const nodeRef = useRef(null);
  let self = {
    isMinimized: !!isMinimized,
    async open(position) {
      setPosition(position);
      setIsMinimized(false);
    }
  };
  exposeMethods(self);
  return show && (
    isMinimized ? (
      <Button flat title='Open sticky note'
        onClick={() => {
          setIsMinimized(false);
          onMaximized();
        }}
        startIcon='sticky_note_2'
        className={
          joinClassName([
            'tt-unset c-black-medium',
            className
          ])
        }
      >
        {title || 'StickyNote'}
      </Button>
    ) : (
      <Draggable
        handle=".sticky_note_title"
        onDrag={() => setIsDragging(true)}
        onStop={() => setIsDragging(false)}
        bounds='body'
        nodeRef={nodeRef}
      >
        <RefBox
          className={
            joinClassName([
              'sticky-note flex-column align-left',
              isDragging ? ' opacity-90' : ''
            ])
          }
          style={{ ...position, maxHeight: 'calc(100vh - 2rem)', ...styleDialog }}
          ref={nodeRef}
        >
          <div className='sticky_note_title d-flex flex-align-left-center pl-1 flex-1 w-100'>
            <Icon className='mr-1' icon='drag_handle' />
            <div className='f-xl fw-500 c-purple'>
              {dialogTitle || title}
            </div>
            <div className='ml-auto'>
              <IconButton
                onClick={() => setIsMinimized(true)}
                icon='cancel'
              />
            </div>
          </div>
          <div className='flex-1 bg-main scroll' style={{ maxWidth: '100%' }}>
            <div
              className='flex-column f-md flex-align-center-top f-lg'
              style={{ minHeight: 120 }}
            >
              {children}
            </div>
          </div>
        </RefBox>
      </Draggable>
    )
  );
}

export default StickyNote;