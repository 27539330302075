import moment from "moment";
import React from "react";
import {
  OPTION_YES
} from '../../../lib/Constants';
import Core from "../../../lib/Core";
import {
  EMPLOYMENT_HISTORIES__PRESENT
} from '../CandidateHistories.lib';
import {
  removeMappedSignalsFlag
} from '../CandidateSignalTags.lib';
import { padWithZeros } from '../../../lib/String.lib';
import { WarningMessage } from '../../Layout/Wrappers/Message';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import TextField from '../../Layout/Wrappers/TextField';

const DEBUG_EMPLOYMENT_GAP = Core.debug('DEBUG_EMPLOYMENT_GAP');

/* METHODS ==================================== */

/**
 * Returns true,
 * if finds an maximum of consecutive occurrences
 * of less of a minimum position duration
 * with different employers
 *
 * @param {object} params
 * @param {object} params.resume - parsed resume
 * @param {object[]} params.resume.employmentHistories
 * @param {object[]} params.resume.employmentHistories[].positionHistories
 * @param {number} params.maxJumps - max amount of jumps between employments
 * @param {number} params.minDuration - min position duration
 * @param {number} params.maxEmployersToReview - max of last jobs to review
 * @param {number} params.maxYearsToReview - max years old employment startDate to review
 * @param {number} params.minCurrentEmploymentDuration - minimum years working in current employment to discard it as employmentGap signal
 * @param {number} params.escapePositionTypes - the positions matching with these keywords will be ignored for calculation
 * @param {number} params.escapeTitles - the positions matching with these keywords will be ignored for calculation
 */
export function isHavingCandidateEmploymentGap({
  candidate = {},
  maxYearsGap = 0.5,
  minDuration = 1,
  maxEmployersToReview = 5,
  maxYearsToReview = 5,
  minCurrentEmploymentDuration = 2.5,
  escapePositionTypes = ['internship'],
  escapeTitles = ['teaching assistant', 'ta', 'volunteer']
}) {

  let candidateHaveEmploymentGap = false;
  let escapePositionTypesRegExp = `\\s*(${escapePositionTypes.join('|')})\\s*`;
  let escapeJobTitlesRegExp = `\\s*(${escapeTitles.join('|')})\\s*`;
  let { employmentHistories = [], employmentGapDisputeParser = false } = candidate;
  let countEmployers = 0;
  let followingEmployer;
  let followingStartDate;

  DEBUG_EMPLOYMENT_GAP && console.debug(
    'DEBUG_EMPLOYMENT_GAP:params\n',
    {
      candidate,
      minDuration,
      maxEmployersToReview,
      maxYearsToReview,
      minCurrentEmploymentDuration,
      escapePositionTypes,
      escapeJobTitles: escapeTitles
    }
  );

  if (employmentGapDisputeParser || !(Core.isAdmin() || Core.isRecruiter())) {
    DEBUG_EMPLOYMENT_GAP && console.debug('DEBUG_EMPLOYMENT_GAP:scaped by disputed parser or invalid account type');
    return false;
  }

  employmentHistories.find(employmentHistory => {
    let { employerOrgName, positionHistories = [], isCurrentEmployer } = employmentHistory;

    // FILTERING valid positions 
    // ---if position startDate is oldest than `maxYearsToReview` this position will be ignored---
    // ---if position match with keywords this position will be ignored---
    positionHistories = positionHistories.filter(
      ({
        positionType = '',
        title = '',
        startDateYear,
        startDateMonth,
      }) => {
        let scapedByKeyword = (
          positionType?.match(new RegExp(escapePositionTypesRegExp, 'i')) ||
          title?.match(new RegExp(escapeJobTitlesRegExp, 'i'))
        )
        const _from = `${startDateYear}${startDateMonth ? `-${padWithZeros(startDateMonth, 2)}` : ''}`;
        return (
          (moment().diff(_from, 'years') <= maxYearsToReview) &&
          !scapedByKeyword
        )
      }
    );

    DEBUG_EMPLOYMENT_GAP && console.debug('positionHistories', positionHistories);

    // BREAK if not valid positions
    if (!positionHistories.length) { return false; }

    // GETTING minimum position startDate
    let startDate = Math.min(
      ...positionHistories.map(({ startDateMonth, startDateYear, }) => {
        let stringDate = `${startDateYear}${startDateMonth ? `-${String(startDateMonth).padStart(2, '0')}` : ''}`;
        return startDateYear && moment(stringDate).tz('America/Los_Angeles').toDate().getTime();
      })
    );

    // GETTING maximum position endDate
    let endDate = Math.max(
      ...positionHistories.map(({ endDateMonth, endDateYear, endDate, currentlyEmployed }) => {
        const stringDate = `${endDateYear}${endDateMonth ? `-${padWithZeros(endDateMonth, 2)}` : ''}`;
        return (
          ((currentlyEmployed === OPTION_YES) || !endDate || (endDate === EMPLOYMENT_HISTORIES__PRESENT))
            ? new Date().getTime()
            : endDateYear && moment(stringDate).tz('America/Los_Angeles').toDate().getTime()
        );
      })
    );

    // CALCULATING duration
    let duration = moment(endDate).diff(startDate, 'years', true);

    // BREAK IF is current employer and duration is oldest than minCurrentEmploymentDuration
    if (isCurrentEmployer && (duration >= minCurrentEmploymentDuration)) {
      candidateHaveEmploymentGap = false;
      DEBUG_EMPLOYMENT_GAP && console.debug('DEBUG_EMPLOYMENT_GAP:scaped by current employment duration');
      return true;
    }

    // BREAK if is current employer and duration is oldest than minCurrentEmploymentDuration
    DEBUG_EMPLOYMENT_GAP && console.debug('DEBUG_EMPLOYMENT_GAP:calc\n', JSON.stringify({
      followingEmployer: followingEmployer,
      followingStartDate,
      followingStartDateStr: moment(followingStartDate).format(),
      employerOrgName,
      endDate: moment(endDate).format(),
      calculatedGap: moment(followingStartDate).diff(endDate, 'years', true),
      maxYearsGap,
      result: moment(followingStartDate).diff(endDate, 'years', true) >= maxYearsGap
    }, null, 2));

    // SETTING followingEmployer
    followingEmployer = employerOrgName;

    // BREAK if max gap reached
    if (!!followingStartDate && moment(followingStartDate).diff(endDate, 'years', true) >= maxYearsGap) {
      candidateHaveEmploymentGap = true;
      return true;
    }

    // SETTING followingStartDate
    followingStartDate = startDate;

    // BREAK if max employers reached
    if (countEmployers >= ++maxEmployersToReview) {
      DEBUG_EMPLOYMENT_GAP && console.debug('A2');
      return true;
    }

    return false;

  });

  DEBUG_EMPLOYMENT_GAP && console.debug('candidateHaveEmploymentGap', candidateHaveEmploymentGap);

  return candidateHaveEmploymentGap;

}

/**
 * Functional Component
 *
 * "Is Candidate a Employment Gap?"
 *
 * - Only available for Admins and Recruiters
 *
 * @param {object} props_
 * @param {object} props_.CandidateEditController CandidateEdit component (parent)
 * @param {object} props_.basicsController  Basics component (child)
 * 
 */
export default function EmploymentGapFieldset(props) {
  const {
    /**
     * CandidateEdit component controller
     * (parent of Basics)
     */
    CandidateEditController
  } = props;
  const {
    /** candidate state */
    state: candidate,
    /** function to update candidate state */
    setStateStore,
  } = CandidateEditController;
  // candidate state fields
  const {
    employmentGapReason4Emp = '',
    employmentGapReason4Us = '',
    employmentGapDisputeParser = false,
    __flagCandidateHaveEmploymentGap
  } = candidate;
  return (
    (__flagCandidateHaveEmploymentGap || employmentGapDisputeParser) ?
      (
        <WarningMessage className='rounded m-1'>
          {/** RED TEXT */}
          <div className="d-flex flex-align-left-center f-sm w-100">
            <div className='mr-1' style={{ width: 450 }}>
              WARNING: resume parser detects <span className='fw-700'>employments gap</span> {`(6m + in last 5y)`}
            </div>
            {/** CHECKBOX */}
            <div className='flex-1'>
              <Checkbox
                name="employmentGapDisputeParser"
                label='Check here if parser is wrong (no employment gap)'
                labelProps={{ className: 'f-sm c-inherit' }}
                checked={employmentGapDisputeParser}
                onChange={event => {
                  let checked = event.target.checked;
                  removeMappedSignalsFlag();
                  setStateStore({
                    employmentGapDisputeParser: checked,
                    key: "employmentGapDisputeParser"
                  });
                }}
                color="primary"
              />
            </div>
          </div>

          {employmentGapDisputeParser && (
            <div className='f-sm'>

              {/** TEXTFIELD 1 */}
              <div className="d-flex flex-align-left-center py-1">
                <span className="w-100 d-flex justify-start">
                  Reasons to be included in the employer submission:
                </span>
                <TextField
                  name="employmentGapReason4Emp"
                  type="text"
                  variant="standard"
                  placeholder="for example: company shut down"
                  value={employmentGapReason4Emp}
                  onChange={event => {
                    setStateStore({ employmentGapReason4Emp: event.target.value });
                  }}
                  color="primary"
                  fullWidth
                />
              </div>

              {/** TEXTFIELD 2 */}
              <div className="d-flex flex-align-left-center py-1">
                <span className="w-100 d-flex justify-start">
                  Reasons to be shared with employer only as needed:
                </span>
                <TextField
                  name="employmentGapReason4Us"
                  type="text"
                  variant="standard"
                  placeholder="for example: take care of sick parents"
                  value={employmentGapReason4Us}
                  onChange={event => {
                    setStateStore({ employmentGapReason4Us: event.target.value });
                  }}
                  color="primary"
                  fullWidth
                />
              </div>

            </div>
          )}
        </WarningMessage>
      )
      : null
  );
}
