/**
 * Utilities for parsing and validating urls
 * Includes
 *   checkURL - checks that the url has the proper componenets
 *   validateURL - calls check url and returns the validated url string
 *   validateLinkedinURL - validates a url is a proper linkedin url and normalizes to avoid false duplicates
 *   validateGithubURL - validates a url is a proper github url
 */
var transformLinkedinURL = require('linkedin-canonical-url');
/**
 * Check URL - checks the url input url string for correctness by utilizing the URL Object
 * 
 * IF the string is a valid url it will retrun a URL Object, otherwise null
 * 
 * @param {string} url
 * @returns {URL | null}
 * 
 */
export function checkURL(url = '') {
  let newURL = null;
  try {
    newURL = new URL(url);
  } catch(e) {
  }
  return newURL;
}

/**
 * Validate URL - checks the url input url string and if valid returns the validated url string
 * or a null string
 * 
 * @param {string} url
 * @returns {string}
 * 
 */
export function validateURL(url = '') {
  const newURL = checkURL(url);
  let urlString = '';
  if (!!newURL) {
    urlString = newURL.href;
  }
  return urlString;
}

/**
 * Validate a Linkedin URL - checks the input url string and if valid returns the validated url string
 * or a null string.  It uses the URL object to decode the url string
 * 
 * A linkedin URL is comprised of a hostname in the format of 
 * 
 *     www.linkedin.com; or
 *     <xx>.linkenin.com where xx is a 2 letter coountry designation such ca for Canada
 * 
 *     Followed by a path in the format /in/<unique account id>
 * 
 * We don't check if the actual page exists
 * 
 * @param {string} url
 * @returns {string}
 * 
 */
 export function validateLinkedinURL(url = '') {
  const newURL = checkURL(url);
  let urlString = '';
  // check if it is a valid URL and its hostname includes linkedin
  if (!!newURL && newURL.hostname.match(/linkedin/i)) {
    /** 
     * now check if the path includes 'in' followed by a string
     * we ignore // as the browsers ignore them and so the url will work
     */ 
    const pathTokens = newURL.pathname.split('/').filter((token) => {return token !== ''});
    /** 
     * a proper linked in path have 2 non "" tokens when split by '/'
     *   "in" | "pub"
     *   "unique account id"
     * if these are not present then it isn't a linkedin url
     */
    if (pathTokens.length >= 2 && pathTokens[0].match(/in|pub/i)) {
      /**
       * Transform url to eliminate 
       *   - international domains
       *   - use of 'pub' vs 'in'
       *   - id format differences e.g foo-bar/a7/576/b50 vs foo-bar-b50576a7
       */
      urlString = transformLinkedinURL(newURL.href);
    }
  }
  return urlString;
}

/**
 * Validate a Github URL - checks the input url string and if valid returns the validated url string
 * or a null string.  It uses the URL object to decode the url string
 * 
 * A github URL is can be 1 of 2 formats
 * 
 *     githbub.com/<unique account id>  or
 *     <unique account id>.linkenin.io
 * 
 *   Both the github hostname and the unique id must be present
 * 
 * We don't check if the actual page exists
 * 
 * @param {string} url
 * @returns {string}
 * 
 */
 export function validateGithubURL(url = '') {
  const newURL = checkURL(url);
  let urlString = '';
  // check if it is a valid URL and its hostname includes github
  if (!!newURL && newURL.hostname.match(/github/i)) {
    // now check if it is github.com or github.io
    const hostTokens = newURL.hostname.split('.');
    switch (String(hostTokens.slice(-1))) {
      case 'com':
        /**
         * a proper github.com url has github as the first token in the hostname
         * (e.g. https://github.com) and a path in the form of /in/<unique account id>/
         * so we first check that github is the first hostname token and if yes 
         * we split the path by '/' into tokens to see how many non "" tokens are present
         * if the first hostname token is 'github' and the path tokens are greater
         * than 1, then we assume it is valid
         * if not, then it is generic and should be avoided
         * 
         */
        const pathTokens = newURL.pathname.split('/').filter((token) => {return token !== ''});
        if (hostTokens[0].toLowerCase() === 'github' && pathTokens.length >= 1) {
          urlString = newURL.href;
        }
        break;
      case 'io':
        /**
         * a proper github.io url has a hostname <unique account id>.github.io
         * so we will validate that github.io is preceded by a single string
         * if yes, then we assume it is valid
         * if not, then it is generic and should be avoided
         */
        if (hostTokens[0] !== '' && hostTokens[1].toLowerCase() === 'github') {
           urlString = newURL.href;
        }
        break;
      default:
        break;
      }
  }
  return urlString;
}