import {
  Card,
  CardActions,
  CardHeader
} from "@mui/material";
import {
  Component
} from "react";
import Account from "../../../lib/Account";
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  NOT,
  YES
} from '../../../lib/Boolean.lib';
import Core from "../../../lib/Core";
import {
  ACCOUNT_ACTION__MATCH_JOB,
  ACCOUNT_ACTION__RESUME_SUBMISSION,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT
} from "../../../lib/Definition";
import Job from "../../../lib/Job";
import {
  Obj
} from '../../../lib/Object.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import {
  mapEmployers
} from "../../../lib/models/employer";
import copyHtml from "../../../lib/tools/copyHtml";
import {
  openEmployerPendings
} from '../../Employers/EmployerPendings/EmployerPendings.lib';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Chip from '../../Layout/Wrappers/Chip';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import NavLink from '../../Layout/Wrappers/NavLink';
import Switch from '../../Layout/Wrappers/Switch';
import Typography from '../../Layout/Wrappers/Typography';
import {
  getMLStatsString
} from '../Libraries/Match.lib';
import {
  getMatchEntities,
  KEY__CON__SINGLE_CARD,
  updateEngagement
} from '../Libraries/MatchList.lib';
import getJobLabels from '../Libraries/Tools/getJobLabels.tool';
import getOwnershipChip from '../Libraries/Tools/getOwnershipChip.tool';
import onSelectMatch from '../Libraries/Tools/onSelectMatch.tool';
import {
  MatchPage__addKeyword,
  MatchPage__getState,
  MatchPage__removeKeyword
} from '../MatchPage';
import CardCheckbox from "./CardCheckbox";
import CreateDisagreement from "./CreateDisagreement";
import { ListPipeController } from './ListPipe';
import ManageEngagement from "./ManageEngagement";
import MatchDecisionDetails, {
  MATCH_DECISION_DETAILS__CONFIG
} from './MatchDecisionDetails';
import MatchDecisionMaker from './MatchDecisionMaker';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__JD_REQUESTER
} from "./MatchLocationChips";
import ThumbsBar from "./ThumbsBar";

const ROLE_LIMITED_RECRUITER = "LimitedRecruiter";

class SingleJobCard extends Component {
  constructor() {
    super(...arguments);
    this.role = 'SingleJobCard';
    this.state = {
      ...this.props,
      wantsUpdateEngagement: false,
      reviewed: false,
      MlDecisionPending: true,
      matchDecisions: [],
      employerName: "Employer",
    };
    this.cardStyles = {};
    const {
      match = {}
    } = this.props;
    Object.assign(match, {
      [KEY__CON__SINGLE_CARD]: this
    });
  }

  componentDidMount() {
    this.getEmployerName();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isSelected !== this.props.isSelected) {
      this.setState({ isSelected: this.props.isSelected });
    }
    if (prevState.job !== this.props.job) {
      this.setState({ job: this.props.job });
    }
  }

  getEmployerName = async () => {
    const {
      recruiter = {},
      employer = {}
    } = getMatchEntities(this.state);
    let flagHideEmployerName = false;
    if (recruiter.role === ROLE_LIMITED_RECRUITER) {
      const whiteList = mapEmployers(
        await Account.getRecruiterWhiteList(recruiter.id)
      );
      flagHideEmployerName = true;
      whiteList.find(({ id: _empID }) => {
        if (_empID === employer.id) {
          flagHideEmployerName = false;
          return true;
        }
        return false;
      });
    }
    const employerName = flagHideEmployerName
      ? employer.proxyName || employer.id
      : employer.name;
    this.setState({ employerName });
  };

  afterUpdateEngagement = (update = {}) => {
    const { afterMainUpdateEngagement } = this.props;
    const {
      engagement = {}
    } = this.state;
    Object.assign(engagement, update);
    this.setState({ engagement },
      () => afterMainUpdateEngagement(engagement)
    );
  };

  closeParentPopup = () => {
    this.setState({ wantsUpdateEngagement: false });
  };

  handlerUpdateEngagement = () => {
    this.setState({ wantsUpdateEngagement: true });
  };

  onCheckStar = (job) => (event, checked) => {
    event.stopPropagation();
    Job.updateStarred(job.id, this.state.starredId, checked, (response) => {
      job.starred = response.starred;
      this.setState({ job });
    });
  };

  handlerOnSelect = () => {
    const {
      type = '',
    } = this.props;
    const {
      match = {},
      engagement = {}
    } = getMatchEntities(this.state, true);
    const selected = type === 'engagement' ? engagement : match;
    this.setState({ isSelected: true });
    onSelectMatch(selected);
    ListPipeController().resizeIndex(this.props.index);
  };

  handlerMlDecisionMaking = (status, MlDecisionMakers) => {
    this.setState(
      {
        MlDecisionPending: status,
        matchDecisions: MlDecisionMakers,
      },
      () => ListPipeController().resizeIndex(this.props.index)
    );
  };

  render() {
    const {
      profile = {},
      match = {},
      engagement = {},
      employer = {},
    } = getMatchEntities(this.state);
    const {
      type = '',
      isSelected = false,
      wantsUpdateEngagement,
      reviewed,
      MlDecisionPending,
      matchDecisions,

      /** @see this.getEmployerName() */
      employerName,

    } = this.state;
    const {
      handlerOnSelectBox,
      selectedMatches,
      segregateRolesChip,
      onLoad,
      measure,
      registerChild,
      label = '',
      index = 0,
      style = {},
    } = this.props;

    const cardStyles = {};

    if (isSelected) {
      cardStyles.border = `2px solid ${colors.purple.common}`;
    }
    if (type === 'engagement') {
      cardStyles.backgroundColor = 'LightGrey';
    }
    else if (match.isDuplicate) {
      cardStyles.backgroundColor = 'LightPink';
    }

    const mlStatsJoined = getMLStatsString({
      mlScoreObject: match.mlScoreObject
    });

    let { keywords = [] } = MatchPage__getState();
    let isFilterByEmployer = !!keywords.find(k => k.name === employer.name);
    let ownerShipChip = getOwnershipChip({ profile: profile, match: match });
    let ownerShipChipLabel = Obj(Obj(Arr(ownerShipChip)[0]).props)['data-label'];
    let isAlreadyOwnIt = ownerShipChipLabel === 'iAlreadyOwnIt';
    if (this.state.isAlreadyOwnIt !== isAlreadyOwnIt) {
      setTimeout(() => this.setState({ isAlreadyOwnIt }));
    }

    const _engagementId = engagement.id
    const _hasCheckedOrEngagementId = (!!this.state.checked || !!_engagementId);
    const _hasRequiredFields = (!!_engagementId && !!match.id && !!profile.id);
    const _isAdminAndHasCheckedOrEngagement = Core.isAdmin() && _hasCheckedOrEngagementId;
    const _menuOptions = [
      // SUBMISSION
      {
        acl: (
          Core.isAdmin({ action: ACCOUNT_ACTION__RESUME_SUBMISSION }) &&
          _hasCheckedOrEngagementId &&
          _hasRequiredFields
        ),
        label: 'Submission',
        onClick: (event) => {
          Core.go({ ...(this?.props || {}), to: `/candidate/resume-submission/${profile.id}/${match.id}` });
        }
      },
      // UPDATE ENGAGEMENT
      {
        acl: !!_engagementId,
        label: 'Update Engagement',
        onClick: this.handlerUpdateEngagement
      },
      // EMPLOYER PENDINGS
      {
        acl: Core.isAdmin(),
        label: 'Employer Portal',
        onClick: (event) => {
          openEmployerPendings({
            employerId: employer.id,
            engagementId: _engagementId
          });
        }
      },
      // CALCULATE MATCH SCORE
      {
        label: 'Calculate Match Score',
        onClick: (event) => {
          match.mlScore = "calculating";
          match.mlScoreObject = {};
          this.setState({ match });
          ListPipeController().processMLScoreSingle(
            {
              candidateId: profile.id,
              jobIds: [match.id],
            },
            'get_jobs_scores',
            (results) => {
              const thisJobResult = Obj(
                results.find(
                  (result) => result.item_id === match.id
                )
              );
              if (thisJobResult.match) {
                match.mlScore = thisJobResult.match.score;
                match.mlScoreObject = thisJobResult.match;
                this.setState({ match });
              }
            }
          );
        }
      },
      // MATCH DECISION DETAILS
      {
        acl: Core.isAdmin() && !!_engagementId,
        label: MATCH_DECISION_DETAILS__CONFIG.dialog.title,
        onClick: (event) => {
          this.matchDecisionDetails.open();
        }
      },
      // COPY
      {
        acl: Core.isAdminOrTrusted(),
        label: 'Copy',
        onClick: (event) => {
          copyHtml(Job.getPreview(match))
            .then((em) => {
              Core.log("Copy email command was successful");
              Core.showSuccess('Copied!');
            })
            .catch((ex) => {
              Core.log("Oops, unable to copy");
              Core.showError('Fail copy!');
            });
        }
      },
      // MAKE ML POSITIVE
      {
        acl: _isAdminAndHasCheckedOrEngagement && _engagementId,
        label: 'Mark ML Positive',
        onClick: (event) => {
          updateEngagement({
            engagement,
            update: {
              useForMlTraining: "true",
            }
          });
        }
      },
      // MAKE ML NEGATIVE
      {
        acl: _isAdminAndHasCheckedOrEngagement && _engagementId,
        label: 'Mark ML Negative',
        onClick: (event) => {
          updateEngagement({
            engagement,
            update: {
              useForMlTraining: "false",
            }
          });
        }
      },
      // STAR
      {
        title: 'Click to Star',
        className: 'star-menu-item',
        label: (
          <Checkbox
            className="star"
            checked={match.starred}
            onCheck={this.onCheckStar(match)}
            checkedIcon={<i className="material-icons">star</i>}
            uncheckedIcon={
              <i className="material-icons">star_border</i>
            }
          />
        )
      },
    ];

    return (
      <RefBox key={`single_job_card__${index}`}
        /**
         * @note
         * These props are essential for recomputeRowHeights.
         * onLoad={measure} ref={registerChild} style={style}
         * Also this wrapper must be a forwardable element.
         */
        // ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ //
        onLoad={measure}
        ref={registerChild}
        style={style}
        // ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ //
        className='pb-05'
      >

        <Button role='GroupHeader' flat primary
          acl={
            YES(label) &&
            NOT(ListPipeController().state.annotatorMode)
          }
          label={label}
          className={
            joinClassName(
              [
                'mb-05 bg-purple-lighter',
                index ? 'mt-3' : 'mt-05'
              ]
            )
          }
          fullWidth
        />

        <Card onLoad={onLoad}
          onClick={this.handlerOnSelect}
          className='pointer'
          style={cardStyles}
        >
          <CardHeader
            className="match-new job-list-card-header"
            action={
              <CardActions>
                {
                  NOT(matchLocation(/v3/i)) &&
                  <Switch small
                    checked={isFilterByEmployer}
                    onChange={event => {
                      isFilterByEmployer = event.target.checked;
                      if (isFilterByEmployer) {
                        MatchPage__addKeyword(employer.name);
                      }
                      else {
                        MatchPage__removeKeyword(employer.name);
                      }
                    }}
                  >
                    Employer filter
                  </Switch>
                }
                <CardCheckbox
                  entity={match}
                  handlerOnSelectBox={handlerOnSelectBox}
                  selectedCheck={
                    !!selectedMatches &&
                    selectedMatches.find((entity) => (entity.id === match.id))
                  }
                />
              </CardActions>
            }
            title={
              <Typography strong breakAll>
                {
                  join([
                    employerName,
                    employer._employerHiringDifficulty && `(${employer._employerHiringDifficulty})`,
                    (match.addressCity || match._officeLocations) && `| ${match.addressCity || match._officeLocations}`
                  ], ' ')
                }
              </Typography>
            }
            disableTypography={true}
          />

          <Box className='px-1'>
            <div className="match-new job-card-text">
              {match._roles} | {match.jobTitle}
            </div>

            <div>
              {!!match.id &&
                !!match._visaTransfer &&
                this.props.staticRowDisplayChip(
                  match._visaTransfer,
                  profile._visa,
                  this.props
                    .staticRowDisplayColor(match, profile)
                    .getVisaColor()
                )}
              {!!segregateRolesChip && segregateRolesChip(match, profile)}
              {!!match.id && typeof match.minYearsOfExperience !== "undefined"
                ? this.props.staticRowDisplayChip(
                  match.minYearsOfExperience + " yrs",
                  profile._yearsOfExperienceForCalc,
                  this.props
                    .staticRowDisplayColor(match, profile)
                    .getYearsXp()
                )
                : null}

              {/* 2021-09-01 µ */}
              <MatchLocationChips
                {...{
                  requester: MATCH_LOCATION_CHIPS__JD_REQUESTER,
                  job: match,
                  candidate: profile
                }}
              />

              {!!match.id &&
                typeof match.salaryMax !== "undefined" &&
                this.props.staticRowDisplayChip(
                  "<=$" + match.salaryMax,
                  profile.minimumSalary,
                  this.props
                    .staticRowDisplayColor(match, profile)
                    .getSalaryColor()
                )}
              {!!match.id &&
                !!match._employeeRating &&
                this.props.staticRowDisplayChip(
                  match._employeeRating,
                  null,
                  null
                )}
            </div>

            <Box
              acl={!!match.id && !!profile.id}
              className={joinClassName([
                'mt-1',
                (
                  ListPipeController().state.annotatorMode ||
                  !mlStatsJoined.length
                ) && 'd-none'
              ])}
            >
              <mark>{mlStatsJoined}</mark>
            </Box>

            <Box row w100 className='mt-1'>
              <ThumbsBar
                Controller={this}
                profile={profile}
                match={match}
              />
              <Switch small
                checked={reviewed}
                onChange={(event) => {
                  this.setState({ reviewed: event.target.checked });
                }}
                className='ml-auto'
              >
                Reviewed
              </Switch>
            </Box>

            <Box column w100 className='mt-1'>

              <Typography
                acl={
                  !!engagement.id &&
                  !ListPipeController().state.annotatorMode
                }
              >
                {[
                  engagement.state,
                  engagement.status,
                  engagement.stage,
                  engagement.rejectionReason,
                  engagement.rejectionReasonAdditionalInfo
                ].filter(v => !!v).join(' | ')}
              </Typography>

              {(!ListPipeController().state.annotatorMode
                ? (!MlDecisionPending
                  ? (
                    matchDecisions.map(
                      (decision, index) => (
                        <MatchDecisionMaker
                          key={`single_job_card__match_decisions__index_${index}`}
                          matchDecisionTableRecord={decision}
                          measure={measure}
                          index={index}
                        />
                      )
                    )
                  ) : (<div className="match-new job-card-text">Loading decision...</div>)
                ) : null
              )}

              <Box row w100 wrap className='mt-1'>

                {/** OWNERSHIP LABELS */}
                <Box row w100 wrap
                  acl={!!match.id}
                  className='mr-auto flex-1'
                >
                  {getJobLabels({ candidate: profile, job: match }).map((job, index) => (
                    <Chip slim key={`single_job_card__labels__index_${index}`}
                      className='bg-red-darker c-white px-1 mr-05 mb-05 f-md'
                    >
                      {job}
                    </Chip>
                  ))}
                  {match.blacklisted}
                  {ownerShipChip}
                </Box>

                {/** ACTIONS */}
                <Box row
                  acl={Core.isAdminOrTrusted()}
                  className='flex-align-right-bottom w-20'
                >
                  <IconButton
                    acl={
                      Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT }) &&
                      YES(engagement.id)
                    }
                    title='View engagement'
                    onClick={(event) => Core.openPopUp(`/engagement/view/${engagement.id}`, 1600)}
                    icon='visibility'
                  />
                  {(Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_JOB }) || (Core.isRecruiter() && Core.isOnDev())) && (
                    <NavLink
                      to={`/job/match/${(match.id)}`}
                      icon='fact_check'
                      reload
                    />
                  )}
                  <Menu icon
                    name={`single_job_card__menu`}
                    options={_menuOptions}
                  />
                </Box>
              </Box>
            </Box>


          </Box>
        </Card >

        <CreateDisagreement Controller={this} />

        <ManageEngagement
          acl={YES(engagement.id)}
          engagement={engagement}
          open={wantsUpdateEngagement}
          closeParentPopup={this.closeParentPopup}
          afterUpdateEngagement={this.afterUpdateEngagement}
        />

        <MatchDecisionDetails
          acl={YES(engagement.id)}
          Controller={this}
        />

      </RefBox>
    );
  }
}

export default SingleJobCard;
