const Styles = {
  AppBar: {
    title: {
      color: colors.purple.common,
      fontSize: '20px',
    },
    rightLabel: {
      color: colors.cyan.common,
    },
    backButton: {
      color: colors.gray.dark,
      marginTop: 7,
      minWidth: 80
    },
    nav: {
      backgroundColor: colors.white.common
    },
    navNew: {
      backgroundColor: colors.white.common
    },
    contentLeft: {
      color: colors.gray.dark,
      marginTop: "5px"
    }
  },
  Paper: {
    margin: 'auto',
    maxWidth: 856
  },
  List: {
    cancel: {
      border: '1px solid',
      borderColor: colors.red.dark,
      color: colors.red.dark,
      margin: '0 5px',
      float: 'left'
    },
    save: {
      border: '1px solid',
      borderColor: colors.purple.common,
      color: colors.purple.common,
      margin: '0 5px'
    },
    button: {
      backgroundColor: colors.cyan.common,
      color: colors.white.common,
      margin: '0 5px'
    }
  },
  CandidateMatch: {
    margins: {
      m20: {
        margin: '20px 20px'
      },
      mRight10: {
        marginRight: '10px'
      },
      mBottom10: {
        marginBottom: '10px'
      },
      mBottom20: {
        marginBottom: '20px'
      }
    },
    padding: {
      m2050: {
        padding: '20px 50px'
      },
      m1540: {
        padding: '15px 40px'
      },
      m2020: {
        padding: '20px 20px'
      },
      m2010: {
        padding: '20px 10px'
      },
      mRight10: {
        paddingRight: '10px'
      }
    },
    border: {
      bLeft: {
        borderLeft: '1px solid LightGray'
      },
      bBottom: {
        borderBottom: '1px solid LightGray'
      },
      bRight: {
        borderRight: '1px solid LightGray'
      }
    },
    fonts: {
      sizes: {
        f12: {
          font: '12px',
        },
        f14: {
          font: '14px'
        }
      }
    },
    backgrounds: {
      colors: {
        backgroundColor: colors.white.common
      }
    }
  }

};

export default Styles;