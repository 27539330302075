import { styled } from '@mui/system';
import Table from './Table';

const StyledTableCell = styled(Table.Cell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    backgroundColor: theme.palette.background.main,
    verticalAlign: 'top',
  },
}));
export default StyledTableCell
