function readTSV(file, callback) {
  // Check for the various File API support.
  if (window.FileReader) {
    // FileReader are supported.
    getAsText(file);
  } else {
    alert("FileReader are not supported in this browser.");
  }
  function getAsText(fileToRead) {
    var reader = new FileReader();
    // Read file into memory as UTF-8
    reader.readAsText(fileToRead);
    // Handle errors load
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
  }
  function loadHandler(event) {
    var csv = event.target.result;
    processData(csv);
  }
  function processData(csv) {
    var allTextLines = csv.split(/\r\n|\n/);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
      var data = allTextLines[i].split(/\t/);
      var tarr = [];
      for (var j = 0; j < data.length; j++) {
        tarr.push(data[j]);
      }
      lines.push(tarr);
    }
    callback(lines);
  }
  function errorHandler(evt) {
    if (evt.target.error.name === "NotReadableError") {
      alert("Can't read file!");
    }
  }
}
export default readTSV;
