/** ============================================ µ
 * @description ToggleBlacklisted [JSX]
 *              V3-table UI Component
 * @createdAt   2021-11-09 Tue
 * @updatedAt   2021-11-09 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import { Switch } from "antd";
import React from "react";
import { REACT_TABLE__INITIAL_PAGE } from "./useEnhancedReactTable.hook";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function ToggleBlacklisted(props) {
  const { enhancedReactTableContext } = props;
  const {
    blacklisted,
    updateUrlState,
  } = enhancedReactTableContext;
  return (blacklisted !== undefined) && (
    <div {...props}>
      <Switch
        checkedChildren={`Blacklisted On`}
        unCheckedChildren={`Blacklisted Off`}
        checked={!!blacklisted}
        onChange={checked => updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, blacklisted: checked })}
      />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { ToggleBlacklisted as default, ToggleBlacklisted };

/* ============================================ */
