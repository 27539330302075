import moment from 'moment';
import { evalDefaultPosition } from '../../lib/Company';
import { OPTION_YES, TIMEZONE__LA } from '../../lib/Constants';
import { evalDefaultDegree } from '../../lib/School';

export const EMPLOYMENT_HISTORIES__PRESENT = 'Present';

/** @todo make the sortByName for all 4 sorting methods | 2022-07-29 µ */

export function sortEmploymentHistories({ candidate }) {
  function sortByName(a, b) {
    return (
      String(a.employerOrgName || '').toLowerCase().localeCompare(String(b.employerOrgName || '').toLowerCase())
    );
  }
  function sort(a, b) {
    const getStringValue = employment => {
      return `${(
        employment.__newRecord ? 1 : 0
      )}${(
        !employment.positionHistories?.length ? 1 : 0
      )}${(
        employment.isCurrentEmployer === true ? 1 : 0
      )}${employment.__endDate}${employment.__startDate}`;
    };
    return getStringValue(b).localeCompare(getStringValue(a));
  }
  candidate.employmentHistories.sort(sortByName);
  candidate.employmentHistories.sort(sort);
  candidate.employmentHistories = candidate.employmentHistories.map(employment => {
    employment.positionHistories.sort((a, b) => sortPositionHistories({ candidate, employment, a, b }));
    return employment;
  })
}

export function sortPositionHistories({ candidate, employment, a, b }) {
  const getStringValue = position => {
    let { endDateMonth, endDateYear, endDate, startDate, currentlyEmployed } = position;
    let stringDate = `${endDateYear}${endDateMonth ? `-${String(endDateMonth).padStart(2, '0')}` : ''}`;
    endDate = (
      ((currentlyEmployed === OPTION_YES) || !endDate || (endDate === EMPLOYMENT_HISTORIES__PRESENT))
        ? new Date().getTime()
        : endDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime()
    );
    let stringId = `${position.__newRecord ? 1 : 0}${(
      evalDefaultPosition({
        candidate,
        employment,
        position
      }) ? 1 : 0
    )}${endDate}${startDate}`;
    // console.debug('sortPositionHistories', stringId);
    return stringId;
  };
  return (
    getStringValue(b).localeCompare(getStringValue(a))
    // + String(a.title || '').toLowerCase().localeCompare(String(b.title || '').toLowerCase())
  );
}

export function sortEducationHistories({ candidate }) {
  function sort(a, b) {
    const getStringValue = education => {
      return `${(
        education.__newRecord ? 1 : 0
      )}${(
        !education.degrees?.length ? 1 : 0
      )}${(
        education.isDefault === true ? 1 : 0
      )}${education.__endDate}${education.__startDate}`;
    };
    return (
      getStringValue(b).localeCompare(getStringValue(a))
      // + String(a.schoolName || '').toLowerCase().localeCompare(String(b.schoolName || '').toLowerCase())
    );
  }
  candidate.educationHistories.sort(sort);
  candidate.educationHistories = candidate.educationHistories.map(education => {
    education.degrees.sort((a, b) => sortDegrees({ candidate, education, a, b }));
    return education;
  })
}

export function sortDegrees({ candidate, education, a, b }) {
  const getStringValue = (degree) => {
    let { endDateMonth, endDateYear, endDate, startDate, isDefault } = degree;
    let stringDate = `${endDateYear}${endDateMonth ? `-${String(endDateMonth).padStart(2, '0')}` : ''}`;
    endDate = (
      ((isDefault === OPTION_YES) || !endDate || (endDate === EMPLOYMENT_HISTORIES__PRESENT))
        ? new Date().getTime()
        : endDateYear && moment(stringDate).tz(TIMEZONE__LA).toDate().getTime()
    );
    return `${(
      Number(!!degree.__newRecord)
    )}${(
      Number(
        evalDefaultDegree({
          candidate,
          education,
          degree
        })
      )
    )}${endDate}${startDate}`;
  };
  // const normalizeDegreeName = degreeName => { return Definition.getTag({ categoryKey: 'undergraduateDegree', tagLabel: degreeName })?.label || ''; }
  return (
    getStringValue(b).localeCompare(getStringValue(a))
    // + normalizeDegreeName(a.degreeName).localeCompare(normalizeDegreeName(b.degreeName))
  );
}

export function evalDateTroubles(model) {
  return !!(
    (
      !model.startDate ||
      (
        (model.startDate && model.endDate) &&
        (model.endDate === EMPLOYMENT_HISTORIES__PRESENT)
      )
    )
      ? false
      : moment(model.startDate).isAfter(model.endDate)
  );
}

export function evalMissingDates(model) {
  return (!model.startDate || !model.endDate);
}

export function evalMissingStartDate(model) {
  return !model.startDate;
}

export function filterHistoriesWithTroubles({ histories = [], evalDates = evalMissingDates }) {
  return histories.filter(history => (
    !(
      history.title ||
      history.degreeName
    ) ||
    evalDates(history) ||
    evalDateTroubles(history)
  ));
}
