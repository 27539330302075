import React, {
  useRef,
  useState
} from 'react';
import Company, {
  fixCompanyName
} from '../../../lib/Company';
import Core from '../../../lib/Core';
import CrunchbaseLib from '../../../lib/Crunchbase.lib';
import School, {
  fixSchoolName
} from '../../../lib/School';
import {
  removeMappedSignalsFlag
} from '../CandidateSignalTags.lib';
import {
  SIGNALS_TYPE__COMPANY, SIGNALS_TYPE__SCHOOL
} from '../OrganizationSignals.lib';
import {
  ErrorChipMessage
} from '../../Layout/Wrappers/Message';
import Button from '../../Layout/Wrappers/Button';
import TextField from '../../Layout/Wrappers/TextField';
import {
  CandidateEdit__updateState
} from '../Edit/CandidateEdit';

export function HistoryName({ history, type = SIGNALS_TYPE__COMPANY, organization }) {
  const ref = useRef();
  let $input = ref.current;
  let propName = (
    (type === SIGNALS_TYPE__COMPANY)
      ? 'employerOrgName'
      : (type === SIGNALS_TYPE__SCHOOL)
        ? 'schoolName'
        : ''
  );
  let [state, setState] = useState({ prev: history[propName], inputId: new Date().toISOString() });
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let isValueChanged = (state.prev !== String(history[propName] || '').trim());
  async function _updateHistory() {
    if (isValueChanged) {
      history[propName] = String(history[propName] || '').trim();
      if (type === SIGNALS_TYPE__COMPANY) {
        let autolinkResponse = await autolinkCompany({ [propName]: history[propName] });
        history.companyId = autolinkResponse.company?.id;
      }
      else if (type === SIGNALS_TYPE__SCHOOL) {
        let autolinkResponse = await autolinkSchool({ [propName]: history[propName] });
        history.schoolId = autolinkResponse.school?.id;
      }
      removeMappedSignalsFlag();
      await CandidateEdit__updateState();
    }
    await _updateState({ prev: history[propName], edit: false });
  }
  return (
    state.edit
      ? (
        <div className='d-flex flex-align-left-center'>
          <TextField
            id={state.inputId}
            ref={ref}
            value={history[propName]}
            onChange={async (event) => {
              let { value = '' } = event.target;
              history[propName] = value;
              await CandidateEdit__updateState();
            }}
            onKeyPress={async event => {
              if (event.key === 'Enter') { _updateHistory(); }
            }}
            className='mr-1'
          />
          {isValueChanged && (
            <Button small
              onClick={event => _updateHistory()}
              startIcon={<i className='material-icons c-inherit'>refresh</i>}
              className='tt-unset min-w-180 c-black-medium'
            >
              Fetch company signals
            </Button>
          )}
        </div>
      ) : (
        <strong
          className={`my-auto truncate f-xl ${organization.cb_url ? 'pointer' : ''}`.trim()}
          onClick={async event => {
            await _updateState({ edit: true });
            if ($input) { $input.focus(); }
            else { document.getElementById(state.inputId)?.focus(); }
          }}
        >
          {history[propName] || (
            <ErrorChipMessage show={!history[propName]} className='fw-normal'>
              An employer name is required
            </ErrorChipMessage>
          )}
        </strong>
      )
  );
}

export async function autolinkCompany({ employerOrgName, showSuccess = true }) {
  let organization;
  let company = await Company.get({ where: { name: { regexp: `/^${employerOrgName}$/i` } } }).then(
    (companies) => companies[0]
  );
  if (company) {
    if (company.crunchbaseOrganizationId) {
      organization = await CrunchbaseLib.get({
        where: { id: company.crunchbaseOrganizationId },
      }).then((organizations) => organizations[0]);
    }
    company = await fixCompanyName({ company, organization });
  }
  if (company?.id && !!showSuccess) {
    Core.showSuccess(
      <>
        {employerOrgName} was found in the Company Table!
        <br />
        <span className='c-black-medium'>
          Candidate's experience will be linked to this company when the candidate record is saved (SAVE or SUBMIT)
        </span>
      </>
    );
  } else {
    Core.hideMessage();
  }
  return {
    company,
    organization
  }
}

export async function autolinkSchool({ schoolName, showSuccess = true }) {
  let organization;
  let school = await School.get({ where: { universityName: { regexp: `/^${schoolName}$/i` } } }).then(
    (schools) => schools[0]
  );
  if (school) {
    if (school.crunchbaseOrganizationId) {
      organization = await CrunchbaseLib.get({
        where: { id: school.crunchbaseOrganizationId },
      }).then((organizations) => organizations[0]);
    }
    school = await fixSchoolName({ school, organization });
  }
  if (school?.id && !!showSuccess) {
    Core.showSuccess(
      <>
        {schoolName} was found in the School Table!
        <br />
        <span className='c-black-medium'>
          Candidate's education will be linked to this school when the candidate record is saved (SAVE or SUBMIT)
        </span>
      </>
    );
  } else {
    Core.hideMessage();
  }
  return {
    school,
    organization
  }
}
