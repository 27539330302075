import React, {
  useState
} from "react";
import TextField from '../Layout/Wrappers/TextField';

export function RejectionReasonInfo(props) {
  const [rejectionReasonAdditionalInfo, setRejectionReasonAdditionalInfo] = useState();
  return (
    <div className={["d-flex flex-align-left-top", props.className].filter(v => !!v).join(' ')} style={props.style}>
      <strong className="mr-1 c-cyan-darker">{props.children || 'RR-Add Info'}</strong>
      <TextField
        variant="standard"
        color="primary"
        size="small"
        value={rejectionReasonAdditionalInfo}
        onChange={event => {
          let rejectionReasonAdditionalInfo = event.target.value;
          setRejectionReasonAdditionalInfo(rejectionReasonAdditionalInfo);
          if (props.onChange instanceof Function) {
            props.onChange(rejectionReasonAdditionalInfo);
          }
        }}
        multiline
        rowsMax={5}
        style={{ width: '100%' }}
      />
    </div>
  );
}