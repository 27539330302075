import React, {
  Component
} from "react";
import Checkbox from '../../Layout/Wrappers/Checkbox';

class CardCheckbox extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      selected: !!this.props.selectedMatches
        ? this.props.selectedMatches.find((entity) => (entity.id === this.props.entity.id))
        : false
    };
  }

  onSelectEntity = () => {
    const { handlerOnSelectBox, entity } = this.props;
    this.setState({ selected: !this.state.selected }, () => {
      !!handlerOnSelectBox && handlerOnSelectBox(entity, this.state.selected);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.entity.id !== prevProps.entity.id) {
      this.setState({ selected: false });
    }
  }

  render() {
    const { selectedCheck } = this.props;

    return (
      <Checkbox
        title="Click to Select"
        value={selectedCheck}
        checked={!!selectedCheck ? true : false}
        onCheck={this.onSelectEntity}
      />
    );
  }
}

export default CardCheckbox;
