import queryString from "query-string";
import {
  Component
} from "react";
import {
  Arr
} from '../../lib/Array.lib';
import Core from "../../lib/Core";
import Engagement from "../../lib/Engagement";
import Store from "../../lib/Store";
import {
  getLocation,
  getParams,
  getSearch
} from '../../lib/URL.lib';
import {
  ENGAGEMENT__ACTIVE_REJECTION_REASONS,
  ENGAGEMENT__ACTIVE_STATUSES
} from '../../dictionaries/Engagement.dic';
import {
  listTab,
  listTabs
} from "../../lib/models/engagement";
import onReady from "../../lib/tools/onReady";
import ReactTable from "../Home/ReactTable";
import {
  configEngagements
} from "../Home/configEngagements";
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';
import Menu from '../Layout/Wrappers/Menu';
import Snackbar from '../Layout/Wrappers/Snackbar';
import List from "../List/List";
import CalendarEvent from "./CalendarEvent";
import EngagementCard from "./Card/EngagementCard";

class Engagements extends Component {
  data;
  constructor() {
    super(...arguments);
    this.state = {
      snackBarMessage: "",
      snackBarOpen: false,
      showActives: true,
      showInactives: false,
      right: true,
      selected: [],
      wantBulkUpdate: false,
      processingBulkUpdate: false,
      bulkUpdateResponse: [],
      engagements: [],
    };
    this._setReducedFilters = true;
    Store.set("path", getLocation());
    this.reloadData = (ev) => this.loadData();
    this.loadData();
  }

  loadData() {
    const { engagementId } = getParams({ pattern: '/engagement/view/:engagementId' });
    let params = queryString.parse(getSearch());
    const where = (
      engagementId
        ? { id: engagementId }
        : this.state.showActives && this.state.showInactives
          ? {}
          : this.state.showActives
            ? { state: "Open" }
            : this.state.showInactives
              ? { state: "Closed" }
              : { state: "undefined" }
    );
    const include = [
      {
        relation: "engagementStarreds",
        scope: {
          where: {
            accountId: Core.getUserId(),
          },
        },
      },
      {
        relation: "candidate",
        scope: {
          fields: [
            "id",
            "accountId",
            "firstName",
            "lastName",
            "introduced",
            "platformRating",
            "email",
            "phone",
            "resumes",
            "resumeTxtUrl",
            "gitHubURL",
            "linkedInURL",
          ],
          include: [
            {
              relation: "account",
              scope: {
                fields: [
                  "firstName",
                  "lastName",
                  "companyName",
                  "email",
                  "role",
                ],
              },
            },
          ],
        },
      },
      {
        relation: "job",
        scope: {
          fields: [
            "jobTitle",
            "employerId",
            "id",
            "role",
            "email",
            "emailsList",
            "addressCity",
            "jobDescription",
          ],
          include: [
            {
              relation: "employer",
              scope: {
                fields: [
                  "name",
                  "proxyName",
                  "email",
                  "url",
                  "totalFunding",
                  "stage",
                  "technicalSkills",
                  "employeeCount",
                  "crunchBaseUrl",
                  "additionalUrl",
                  "tagline",
                  "product",
                  "teamCount",
                  "expectedResumeResponseTime",
                  "expectedSchedulingResponseTime",
                  "expectedScreenResponseTime",
                  "expectedOnsiteResponseTime",
                ],
              },
            },
          ],
        },
      },
      "actionOwner",
    ];

    let filters = { ...params };

    if (params.perf || this._setReducedFilters) {
      filters = { ...params, include };
    }

    Engagement.getWhere(
      where,
      (engagements) => {
        this.setState({ engagements });
        if (!!engagementId) {
          onReady(this, "List").then((em) => {
            const _engagements = engagements
              .filter((eng) => eng.id === engagementId)
              .map((eng) => {
                eng.expanded = true;
                return eng;
              });
            this.List.setItems(_engagements);
          });
        }
      },
      filters
    );
  }

  updateField = (engagement, update, callback = () => { }) => {
    const { selected } = this.state;

    if (/end/i.test(update.stage)) {
      update.open = false;
    }

    if (selected.length) {

      // This is the bulk operation flow | 2021-08-16 Mon µ
      selected.forEach((card) => {
        const { state: engagement } = card;
        Engagement.update(
          engagement,
          update,
          response => {

            engagement.checked = true;

            this.List.updateItem({ ...engagement, ...update }
              //, { reload: true }
            );

            // story-3778-m2 | 2021-08-13 Fri µ
            // The reload is not re-rendering the card component for that we must update the card state.
            card.setState({ ...engagement, ...update });

          },
          failure => Core.showError(`failed to update engagement with id = ${engagement.id}`)
        );
      });

    } else {

      Engagement.update(
        engagement,
        update,
        response => callback(response),
        failure => Core.showError(`failed to update engagement with id = ${engagement.id}`)
      );

    }

  };

  showMessage = (msg) => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true,
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false,
    });
  };

  handlerBulkUpdate = () => {
    let {
      selectedStatusForBulkUpdate,
      selected,
      engagements,
      selectedStatusForRejectionReason,
    } = this.state;

    if (!selectedStatusForBulkUpdate) {
      alert("you didn't pick any status");
      return;
    }

    if (!selected.length) {
      alert("no engagements are selected, please select at least 1 engagement");
      return;
    }

    if (window.confirm("Are you sure")) {
      this.setState(
        { processingBulkUpdate: true, bulkUpdateResponse: [] },
        () => {
          let payload = selected.map((card) => {

            const { state: engagement } = card;

            let p = {
              id: engagement.id,
              status: selectedStatusForBulkUpdate
            };

            if (!!selectedStatusForRejectionReason) {
              p["rejectionReason"] = selectedStatusForRejectionReason;
            }

            return p;

          });

          Engagement.updateMany(
            payload,
            (response) => {
              let bulkUpdateResponse = response.res;

              bulkUpdateResponse.forEach((resEng) => {
                this.updateEngagementInArray(
                  engagements,
                  resEng.id,
                  Object(payload.find((eng) => eng.id === resEng.id))
                );
              });

              this.setState(
                {
                  engagements,
                  processingBulkUpdate: false,
                  bulkUpdateResponse,
                },
                () => {
                  //this.filterControl.setItems(engagements);
                }
              );

              this.setState({ wantBulkUpdate: false });
            },
            () => {
              this.setState({ processingBulkUpdate: false });
              alert("something went wrong");
            }
          );
        }
      );
    }
  };

  updateEngagementInArray = (engagements, engId, data) => {
    engagements.forEach((engagement) => {
      if (engagement.id === engId) {
        Object.keys(data).forEach((attr) => {
          engagement[attr] = data[attr];
        });
      }
    });

    return engagements;
  };

  render() {
    let {
      selected,
      selectedStatusForBulkUpdate,
      processingBulkUpdate,
      bulkUpdateResponse,
      selectedStatusForRejectionReason,
    } = this.state;

    return (
      <>
        <div style={{ height: 'auto' }}>
          <ReactTable
            {...this.props} // to pass the router context
            config={configEngagements}
            onChange={data => {
              try { this.List.setItems(Arr(data)); }
              catch (e) { console.debug(e); }
            }}
            disableBodyRender
          />
        </div>
        <List
          ref={(List) => (this.List = List)}
          tabs={listTabs}
          tab={listTab}
          disableTabs={true}
          name="Engagement"
          card={EngagementCard}
          parent={this}
          onCheck={(id, checked, card) => {
            let selected = [...this.state.selected];

            if (!checked) {
              selected = selected.filter(card => card.state.id !== id);
            } else {
              selected.push(card);
            }

            let engagements = this.updateEngagementInArray(
              this.state.engagements,
              id,
              { checked }
            );

            this.setState({ selected, engagements });
          }}
        />
        <CalendarEvent
          ref={(self) => (this.CalendarEvent = self)}
          parent={this}
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.snackBarMessage}
          className="snack-bar"
          onClose={this.hideMessage}
        />

        <Dialog
          title="Update Status"
          actions={[
            <Button outlined minW120
              label="Cancel"
              onClick={() => this.setState({ wantBulkUpdate: false })}
            />,
            <Button primary minW120
              label="Submit"
              onClick={() => this.handlerBulkUpdate()}
            />,
          ]}
          paperStyle={{ width: 800 }}
          open={this.state.wantBulkUpdate}
          scroll={"body"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {!processingBulkUpdate && (
            <>
              You are going to update these ( {selected.length} ) engagements:
              {Array.isArray(selected) &&
                selected.map((card, index) => {
                  const { state: engagement } = card;
                  return (
                    <p key={`engagements-selected-${engagement.id}`}>
                      {!!bulkUpdateResponse.length ? (
                        bulkUpdateResponse
                          .map((e) => e.id)
                          .includes(engagement.id) ? (
                          <i className='material-icons'>check</i>
                        ) : (
                          <i className='material-icons'>cancel</i>
                        )
                      ) : (
                        ""
                      )}
                      <strong style={{ fontSize: "12px" }}>
                        - {engagement._name} => {engagement._jobTag}
                      </strong>{" "}
                      &nbsp;&nbsp;
                      <span
                        className="anchor"
                        onClick={() => {
                          let engagements = this.updateEngagementInArray(
                            this.state.engagements,
                            engagement.id,
                            { checked: false }
                          );
                          this.setState(
                            {
                              engagements,
                              selected: selected.filter(
                                selectedEng =>
                                  (selectedEng.id !== engagement.id)
                              ),
                            },
                            () => {
                              // this.filterControl.setItems(engagements);
                            }
                          );
                        }}
                      >
                        remove
                      </span>
                    </p>
                  );
                })}
              <div className="">
                Please pick Status from dropdown:&nbsp;&nbsp;
                <Menu dropdown
                  name="status"
                  value={selectedStatusForBulkUpdate}
                  onChange={(selectedStatusForBulkUpdate) => {
                    this.setState({
                      selectedStatusForBulkUpdate,
                      bulkUpdateResponse: [],
                    });
                  }}
                  options={[...ENGAGEMENT__ACTIVE_STATUSES]}
                />
                <div className="Modal-block">
                  Rejection Reason
                  <Menu dropdown
                    name="rejectionReason"
                    value={selectedStatusForRejectionReason}
                    onChange={(selectedStatusForRejectionReason) => {
                      this.setState({
                        selectedStatusForRejectionReason,
                        bulkUpdateResponse: [],
                      });
                    }}
                    options={[...ENGAGEMENT__ACTIVE_REJECTION_REASONS]}
                  />
                </div>
              </div>
            </>
          )}
          {!!processingBulkUpdate && (
            <>
              <h2>processing...</h2>
            </>
          )}
        </Dialog>
      </>
    );
  }
}
export default Engagements;
