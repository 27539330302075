import moment from "moment-timezone";
import React, {
  Component
} from "react";
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';
import Table from '../Layout/Wrappers/Table';

class PermittedJobTable extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      openForm: true
    }
  }

  componentDidMount() {
    this.loadData();
  };

  loadData = () => {

  };

  handleClose = () => {
    this.setState({ openForm: false });
    !!this.props.onClose && this.props.onClose();
  };

  render() {
    const { openForm } = this.state;
    const { candoPermittedJobs } = this.props;
    return (
      <Dialog
        fullWidth={'xl'}
        maxWidth={'xl'}
        open={openForm}
        onClose={this.handleClose}
        name="permitted_job_table"
        title='Permitted Job Details'
        actions={[
          <Button
            onClick={this.handleClose}
            primary
            className='min-w-120'
          >
            Cancel
          </Button>
        ]}
      >
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Cell>
                Action
              </Table.Cell>
              <Table.Cell>
                hasOwnership?
              </Table.Cell>
              <Table.Cell>
                Timestamp (24hrs)
              </Table.Cell>
              <Table.Cell>
                OperatedBy
              </Table.Cell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {candoPermittedJobs.map(job => {
              let timestamp = job.timestamp;

              return <Table.Row key={`permitted-job-details-manager-${job.id}`}>
                <Table.Cell>
                  {job.action}
                </Table.Cell>

                <Table.Cell>
                  {job.hasOwnership}
                </Table.Cell>

                <Table.Cell>
                  {!!timestamp ? moment.tz(timestamp, "America/Los_Angeles").format('MM/DD/YYYY HH:mm') : "N/A"} PST
                </Table.Cell>

                <Table.Cell>
                  {job.operatedBy} - {Object(job.operatedBye).email}
                </Table.Cell>
              </Table.Row>
            })}
          </Table.Body>
        </Table>
      </Dialog>
    );
  }
}

export default PermittedJobTable;

