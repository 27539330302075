const dirtyUniqAttrs = (latest,persisted)=>{
    const attrs = ['email', 'linkedInURL', 'gitHubURL', 'phone'];
    latest = Object(latest);
    persisted = Object(persisted);
    
    return attrs.filter(attr=>{
        return latest[attr]!==persisted[attr];
    })
};

export default dirtyUniqAttrs;