import {
  isBoolean
} from 'lodash';
import {
  PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER,
  PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER,
  PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS
} from '../../dictionaries/PrescreenQuestionsSectionUI.dic';
import {
  Arr
} from '../../lib/Array.lib';
import {
  Obj
} from '../../lib/Object.lib';
import {
  COLLECTION__PRESCREEN_QUESTION_TEMPLATES,
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  isEmptyString,
  isNonEmptyString
} from '../../lib/String.lib';
import {
  PRESCREEN_QUESTIONS_CONFIG
} from './PrescreenQuestions.config';

export async function fetchQuestionFromBank() {
  return readLoopbackRecord({
    collection: COLLECTION__PRESCREEN_QUESTION_TEMPLATES,
    where: {
      questionApplyTo: PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS.id,
    }
  });
}

export const PRESCREEN_QUESTIONS__VERSION = 2;
export const QUESTION_ANSWER__SOURCE__GLOBAL = 'global';
export const QUESTION_ANSWER__SOURCE__BANK = 'bank';
export const QUESTION_ANSWER__SOURCE__EMPLOYER = 'employer';
export const QUESTION_ANSWER__SOURCE__JOB = 'job';

export function mapQuestionAnswer({ question, answer }) {
  return {
    questionId: question.id,
    question: question.question,
    instruction: question.instruction,
    answer: answer.answer || '',
    askedBy: question.askedBy,
    order: question.order || 0,
    answerMandatory: question.answerMandatory,
    autoIncludeInWriteup: (
      isBoolean(answer.autoIncludeInWriteup)
        ? answer.autoIncludeInWriteup
        : isBoolean(question.autoIncludeInWriteup)
          ? question.autoIncludeInWriteup
          : true
    )
  };
}

export function mapQuestionsAnswers(options) {
  let {
    globalQuestions = [],
    jobs = [],
    candidate = {},
    autoIncludeInWriteup = false
  } = options;
  let { jobsPermittedAnswers = {} } = candidate;
  let questionsAllJobsSpecific = {};
  let globalAnswers = {};
  let employerAnswers = {};
  let jobAnswers = {};
  let answers = [];
  let missedQuestions = [];
  const _addAnswer = ({ title, question, answer }) => {
    if (autoIncludeInWriteup) {
      if (isBoolean(answer.autoIncludeInWriteup)
        ? !answer.autoIncludeInWriteup : false
      ) { return; }
    }
    if (isNonEmptyString(question.question)) {
      if (isEmptyString(answer.answer)) {
        missedQuestions.push({ title, ...question });
      }
      else {
        answers.push({ title, ...answer, askedBy: question.askedBy });
      }
    }
  }
  const _mapAnswers = ({ title, source, entityId, questions = [] }) => {
    questions.forEach(question => {
      let isFromEmployer = (source === QUESTION_ANSWER__SOURCE__EMPLOYER);
      let isFromJob = (source === QUESTION_ANSWER__SOURCE__JOB);
      let isGlobalQuestion = (question.answerApplyTo === PRESCREEN_QUESTION__ANSWER_APPLY_TO__GLOBAL_ANSWER.id);
      let isSpecificQuestion = (
        (question.answerApplyTo === PRESCREEN_QUESTION__ANSWER_APPLY_TO__JOB_SPECIFIC_ANSWER.id)
      );
      let isSharedQuestion = (
        (question.questionApplyTo === PRESCREEN_QUESTION__QUESTION_APPLY_TO__ALL_JOBS.id) &&
        !isSpecificQuestion
      )
      if (isGlobalQuestion) {
        let answer = Obj(Obj(jobsPermittedAnswers.globalAnswers)[question.id]);
        globalAnswers[question.id] = mapQuestionAnswer({ question, answer });
        _addAnswer({ title, question, answer });
      }
      else if (entityId) {
        if (isFromEmployer || isSharedQuestion) {
          let answer = Obj(Obj(Obj(Obj(jobsPermittedAnswers.employerAnswers)[entityId]).answers)[question.id]);
          employerAnswers[entityId] = Obj(employerAnswers[entityId]);
          employerAnswers[entityId].answers = Obj(employerAnswers[entityId].answers);
          employerAnswers[entityId].answers[question.id] = mapQuestionAnswer({ question, answer });
          _addAnswer({ title, question, answer });
        }
        else if (isFromJob || isSpecificQuestion) {
          let answer = Obj(Obj(Obj(Obj(jobsPermittedAnswers.jobAnswers)[entityId]).answers)[question.id]);
          jobAnswers[entityId] = Obj(jobAnswers[entityId]);
          jobAnswers[entityId].answers = Obj(jobAnswers[entityId].answers);
          jobAnswers[entityId].answers[question.id] = mapQuestionAnswer({ question, answer });
          _addAnswer({ title, question, answer });
        }
      }
      else if (isSpecificQuestion) {
        questionsAllJobsSpecific[question.id] = question;
      }
    });
  }
  _mapAnswers({
    title: PRESCREEN_QUESTIONS_CONFIG.globalAnswers.title,
    source: QUESTION_ANSWER__SOURCE__BANK,
    questions: Arr(globalQuestions).filter(question => isNonEmptyString(question.question)),
    answers: Obj(jobsPermittedAnswers.globalAnswers),
  });
  jobs.forEach(job => {
    let employer = Obj(job.employer);
    jobAnswers[job.id] = Obj(jobAnswers[job.id]);
    jobAnswers[job.id].answers = Obj(jobAnswers[job.id].answers);
    jobAnswers[job.id].id = job.id;
    jobAnswers[job.id].employerId = job.employerId;
    jobAnswers[job.id].title = job._name;
    employerAnswers[job.employerId] = Obj(employerAnswers[job.employerId]);
    employerAnswers[job.employerId].answers = Obj(employerAnswers[job.employerId].answers);
    _mapAnswers({
      title: job._name,
      source: QUESTION_ANSWER__SOURCE__EMPLOYER,
      entityId: job.employerId,
      questions: Arr(employer.mustHaveQuestions).filter(question => isNonEmptyString(question.question)),
      answers: Obj(Obj(Obj(jobsPermittedAnswers.employerAnswers)[job.employerId]).answers),
    });
    _mapAnswers({
      title: job._name,
      source: QUESTION_ANSWER__SOURCE__JOB,
      entityId: job.id,
      questions: Arr(job.mustHaveQuestions).filter(question => isNonEmptyString(question.question)),
      answers: Obj(Obj(Obj(jobsPermittedAnswers.jobAnswers)[job.id]).answers),
    });
    _mapAnswers({
      title: job._name,
      source: QUESTION_ANSWER__SOURCE__JOB,
      entityId: job.id,
      questions: Object.values(questionsAllJobsSpecific),
      answers: Obj(Obj(Obj(jobsPermittedAnswers.jobAnswers)[job.id]).answers),
    });
  });
  return {

    // questionObject: {id,question,instruction,askedBy,answerMandatory,questionApplyTo,answerApplyTo,order,author,autoIncludeInWriteup}
    // answerObject: {questionId,question,instruction,answer,askedBy,order,answerMandatory,autoIncludeInWriteup}

    // {[questionId]:answerObject,...}
    globalAnswers,

    // {[employerId]:{answers:{[questionId]:answerObject,...}}}
    employerAnswers,

    // {[jobId]:{answers:{[questionId]:answerObject,...}}}
    jobAnswers,

    // [{title,...answerObject},...]
    answers,

    // [{title,...questionObject},...]
    missedQuestions,

    updateAnswer(options) {
      let { source, entityId, update } = options;
      if (source === QUESTION_ANSWER__SOURCE__GLOBAL) {
        globalAnswers[update.questionId] = update;
      }
      else if (entityId) {
        if (source === QUESTION_ANSWER__SOURCE__EMPLOYER) {
          employerAnswers[entityId] = Obj(employerAnswers[entityId]);
          employerAnswers[entityId].answers = Obj(employerAnswers[entityId].answers);
          employerAnswers[entityId].answers[update.questionId] = update;
        }
        else if (source === QUESTION_ANSWER__SOURCE__JOB) {
          jobAnswers[entityId] = Obj(jobAnswers[entityId]);
          jobAnswers[entityId].answers = Obj(jobAnswers[entityId].answers);
          jobAnswers[entityId].answers[update.questionId] = update;
        }
      }
      else {
        console.debug('updateAnswer:Error', options);
      }
      return {
        version: PRESCREEN_QUESTIONS__VERSION,
        globalAnswers,
        employerAnswers,
        jobAnswers
      };
    }
  };
}
