import { Avatar } from '@mui/material';
import CrunchbaseLib, { CRUNCHBASE__FAVICON, SUGGESTION_TYPE__CBO } from '../../../lib/Crunchbase.lib';

export function CrunchbaseSuggestedOption(option) {
  return (
    <div className='d-flex flex-align-center-top'>
      <div className='mt-05 mr-1'>
        <Avatar
          src={option.logo}
          style={{ width: 32, height: 32 }}
          className='f-xs bg-purple-common'
        >
          {String(option.value || option.label)
            .replace(/\W/g, '')
            .split(' ')
            .map((w) => w[0]?.toUpperCase())
            .slice(0, 2)
            .join('')}
        </Avatar>
      </div>
      <div className='d-flex flex-align-left-center'>
        <div
          className='d-flex flex-column truncate'
          style={{ minWidth: sizes.m6, maxWidth: sizes.m6 }}
        >
          <div className='d-flex flex-align-left-center'>
            <div className='mr-1'>
              {option.label}
            </div>
            {CrunchbaseLib.isEnabled() && (
              (option.type === SUGGESTION_TYPE__CBO) ||
              (!!option.crunchbaseOrganizationId)
            ) && (
                <Avatar
                  src={CRUNCHBASE__FAVICON}
                  style={{ width: 16, height: 16 }}
                  className='f-xs bg-purple mr-1'
                />
              )}
            {!!option.meta?.alternativeNames.length && (
              <small
                className='c-black-medium truncate flex-1'
                title={option.meta.alternativeNames.join(', ')}
              >
                Aliases: {option.meta.alternativeNames.join(', ')}
              </small>
            )}
          </div>
          <small className='c-black-medium truncate' title={option.description}>
            {option.description}
          </small>
        </div>
      </div>
    </div>
  );
}
