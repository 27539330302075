function onReady(context, prop) {
  let count = 0;
  return new Promise(resolve => {
    let interval;
    interval = setInterval(it => {
      if (context[prop]) {
        clearInterval(interval);
        resolve();
      } else {
        count >= 7 && clearInterval(interval);
      }
      count++;
    }, 100);
  });
}
export default onReady;
