import React from 'react';
import Core from '../../lib/Core';
import Store from '../../lib/Store';
import {
  getLocation
} from '../../lib/URL.lib';
import getMainRoutes, {
  getNavigationList
} from '../../routes/Main.routes';
import AnnouncementBell from '../Announcements/AnnouncementBell';
import HistoryMenu from '../HistoryMenu/HistoryMenu';
import AppBar from '../Layout/Wrappers/AppBar';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import Router from '../Layout/Libraries/Router';
import MainSideMenu from './MainSideMenu';
import Box from '../Layout/Wrappers/Box';

export default function Main({ ...props }) {
  Store.set('path', getLocation());
  if (Core.isLoggedOut()) {
    return <Navigate to="/login" />;
  }
  return (
    <Box column className='vh-100 vw-100'>
      <AppBar
        actionBarLeft={
          <MainSideMenu />
        }
        title='10by10'
        contentMiddle={
          getNavigationList().map((option) => (
            <NavLink main {...option} key={`main_side_menu__option__${option.label}`} />
          ))
        }
        contentRight={
          <>
            <AnnouncementBell />
            <NavLink
              title='Go to see calendar'
              icon='calendar_today'
              to={'/calendar'}
              acl={Core.isAdminOrCoordinator()}
            />
            <HistoryMenu />
          </>
        }
      />
      <Box column w100 scrollY>
        <Router redirect routes={getMainRoutes()} />
      </Box>
    </Box>
  );
}
