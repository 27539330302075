import Core from "../../../lib/Core";
import {
  ACCOUNT_ACTION__LIST_ACCOUNTS,
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS
} from '../../../lib/Definition';
import Typography from '../../Layout/Wrappers/Typography';

export const reactTableMoreMenuOptions = [
  {
    acl: (model) => (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })),
    label: 'Candidates',
    to: () => `/v3/candidates`
  },
  {
    acl: (model) => (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })),
    label: 'Jobs',
    to: () => `/v3/jobs`
  },
  {
    acl: (model) => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS }),
    label: 'Employers',
    to: () => `/v3/employers`
  },
  {
    acl: (model) => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ACCOUNTS }),
    label: 'Accounts',
    to: () => `/v3/accounts`
  },
  {
    acl: (model) => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }),
    label: 'Engagements',
    to: () => `/v3/engagements`
  },
  {
    acl: (model) => Core.isAdmin(),
    label: 'Matcher',
    to: () => `/matcher`
  },
  {
    acl: (model) => Core.isAdmin(),
    label: 'Events',
    to: () => `/events`
  },
  {
    acl: (model) => (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })),
    label: (
      <>
        Jobs
        <Typography sub className='c-black-medium ml-05'>
          (pro)
        </Typography>
      </>
    ),
    to: () => `/pro/jobs`
  },
  {
    acl: (model) => Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS }),
    label: (
      <>
        Engagements
        <Typography sub className='c-black-medium ml-05'>
          (pro)
        </Typography>
      </>
    ),
    to: () => `/pro/engagements`
  },
];
