import {
  InputNumber as AntDInputNumber
} from "antd";
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  join
} from '../../../lib/Array.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  hasNoWidth,
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';
import Typography from './Typography';

/**
 * 
 * @param {object} props_
 * @param {number} props_.min float, = undefined
 * @param {number} props_.max float, = undefined
 * @param {number} props_.step float, = 1.0
 * @param {string} props_.value = ''
 * @param {function} props_.onChange = (value:{number}) => null
 * @param {function} props_.parser = (value:{string}) => value.replace(/\$\s?|(,*)/g, '')
 * @param {function} props_.formatter = (value:{string}) => formatNumberToAmericanFormat(value)
 * @param {string} props_.units = ''
 * @param {string} props_.className = ''
 * @param {object} props_.wrapperProps = {}
 * @param {boolean} props_.controls = false
 * @returns {JSX.Element}
 * 
 * @see https://ant.design/components/input-number
 */
export default function InputNumber({
  min = NaN,
  max = NaN,
  step = 1.0,
  value: propsValue = '',
  onChange = (value) => null,
  moneyFormat = false,
  formatter = (
    (value) => (
      (
        value
      ) ? (
        join([
          !!moneyFormat && '$',
          !isNaN(value) && value,
        ], ' ').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      ) : (
        ''
      )
    )
  ),
  parser = (value) => value.replace(/\$\s?|(,*)/g, ''),
  prefix = '',
  units = '',
  wrapperProps = {},
  controls = false,
  autoFocus = false,
  errorText = '',
  className = '',
  style = {},
  placeholder = AppUI.placeholder.number,
  fullWidth = true,
  ...props
}) {
  const _defaultState = { value: propsValue };
  const [{ value = '' }, _updateState] = useState(_defaultState, _defaultState);
  /**
   * @note
   * The min and max parameters for AntDInputNumber are not working as expected.
   * Therefore, we are normalizing the value.
   */
  const _normalizeValue = (value) => {
    value = parseFloat(value);
    if (!isNaN(min) && !isNaN(max) && (min >= 0) && (max >= 0) && (min <= max)) {
      if (value > max) { value = max; }
      if (value < min) { value = min; }
    }
    return parseFloat(value);
  }
  const _onChange = async (value) => {
    value = _normalizeValue(value);
    await _updateState({ value });
    onChange(value);
    return value;
  }
  className = joinClassName([
    fullWidth ? 'w-100' : hasNoWidth({ className, style }) && 'w-auto',
    className
  ]);
  mapWrapper({
    role: 'InputNumber',
    props: wrapperProps,
    assign: {
      className,
      style
    }
  });
  return (
    <Box column {...wrapperProps}>
      <Box row noWrap w100>
        <Typography sub mr
          acl={!!prefix}
        >
          {prefix}
        </Typography>
        <AntDInputNumber
          {...props}
          value={value}
          placeholder={placeholder}
          onChange={_onChange}
          step={step}
          formatter={formatter}
          parser={parser}
          className={
            joinClassName([
              'flex-1 styled',
              !!errorText && 'border-red',
              units && 'mr-1',
            ])
          }
          controls={controls}
          autoFocus={autoFocus}
        />
        <Typography sub>
          {units}
        </Typography>
      </Box>
      <Typography sub italic error
        acl={!!errorText}
      >
        {errorText}
      </Typography>
    </Box>
  );
}
