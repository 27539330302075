export const NEXT_STEP__RECRUITER_SCREEN = 'Recruiter screen';
export const NEXT_STEP__MANAGER_SCREEN = 'Manager screen';
export const NEXT_STEP__TECH_SCREEN = 'Tech screen';
export const NEXT_STEP__LIVE_CODING = 'Live coding';
export const NEXT_STEP__HOMEWORK = 'Homework';
export const NEXT_STEP__ONSITE_INTERVIEW = 'Onsite/Late stage';
export const NEXT_STEP__CXO_INTERVIEW = 'CXO interview';
export const NEXT_STEP__FINAL_ROUND_INTERVIEW = 'Final round interview';
export const NEXT_STEP__REFERENCE_CHECK = 'Reference check';
export const NEXT_STEP__VERBAL_OFFER = 'Verbal offer';
export const NEXT_STEP__WRITTEN_OFFER = 'Written offer';
export const NEXT_STEP__OTHER = 'Other';

export const NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW = 'Need to request interview';
export const NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO = 'Waiting for cando availability';
export const NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED = 'Interview booked';
export const NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD = 'Interviewed - decision TBD';

export const NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK = 'To send homework';
export const NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK = 'Cando to finish homework';
export const NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK = 'Homework done - grade TBD';

export function getAcceptPresets({ activePresets = [] } = {}) {
  let list = [
    [
      NEXT_STEP__RECRUITER_SCREEN,
      NEXT_STEP__MANAGER_SCREEN,
      NEXT_STEP__TECH_SCREEN,
      NEXT_STEP__LIVE_CODING,
    ],
    [
      NEXT_STEP__HOMEWORK,
      NEXT_STEP__ONSITE_INTERVIEW,
      NEXT_STEP__OTHER
    ],
    []
  ];
  let filterPreset = activePresets.find(preset => !!getAcceptAdditionalPresets({ preset }).length);
  if (filterPreset) {
    return [[filterPreset]];
  }
  return list;
}

export function getAcceptAdditionalPresets({ preset }) {
  preset = String(preset || '').trim();
  if (
    [
      NEXT_STEP__RECRUITER_SCREEN,
      NEXT_STEP__MANAGER_SCREEN,
      NEXT_STEP__TECH_SCREEN,
      NEXT_STEP__LIVE_CODING,
      NEXT_STEP__ONSITE_INTERVIEW
    ].includes(preset)
  ) {
    return ([
      NEXT_STEP_ADDITIONAL__TO_ASK_FOR_INTERVIEW,
      NEXT_STEP_ADDITIONAL__NEED_TIME_FROM_CANDO,
      NEXT_STEP_ADDITIONAL__INTERVIEW_BOOKED,
      NEXT_STEP_ADDITIONAL__INTERVIEWED_DECISION_TBD
    ]);
  }
  else if (preset === NEXT_STEP__HOMEWORK) {
    return ([
      NEXT_STEP_ADDITIONAL__TO_SEND_HOMEWORK,
      NEXT_STEP_ADDITIONAL__NEED_HOMEWORK_BACK,
      NEXT_STEP_ADDITIONAL__TO_GRADE_HOMEWORK
    ]);
  }
  return [];
}
