import { mapMatchDecisionTables, MODEL_NAME_MATCH_DECISION_TABLE } from '../../lib/models/matchDecisionTable';
import FilterBoolean from './FilterBoolean';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import FilterStringColumnNoFacet from './FilterStringColumnNoFacet';
import {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn
} from "./ReactTable";
import { REACT_TABLE__COLUMN_LG, REACT_TABLE__COLUMN_MD, REACT_TABLE__COLUMN_SM, REACT_TABLE__COLUMN_XS, REACT_TABLE__COLUMN_XXL } from './useEnhancedReactTable.hook';

export const configMatchDecisionTable = {
  Entity: { name: MODEL_NAME_MATCH_DECISION_TABLE },
  entityMapper: mapMatchDecisionTables,
  searchPath: 'MatchDecisionTables/_search',
  autocompletePath: 'MatchDecisionTables/_autocomplete',
  facetsPath: 'MatchDecisionTables/_facets',
  searchPlaceholder: 'Search by candidate, job, annotator',
  defaultQuery: {
    "filters": [],
    // "sort": { "firstName": 1, "lastName": 1 },
    "skip": 0,
    "limit": 10,
    "query": [],
    "associations": [
      'engagement',
      'candidate',
      'job'
    ]
  },
  hiddenColumns: [],
  columns: [
    {
      accessor: 'annotator',
      Header: 'Annotator',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
      _headerClassName: 'sticky-start bg-cyan-common',
      _cellClassName: 'sticky-start bg-white-common',
    },
    {
      accessor: 'candidateJobId',
      Header: 'Candidate-Job Id',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'candidateName',
      Header: 'Candidate Name',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'candidateUrl',
      Header: 'Candidate URL',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'employerName',
      Header: 'Employer Name',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'engagementStage',
      Header: 'Engagement Stage',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'engagementStatus',
      Header: 'Engagement Status',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'engagementUrl',
      Header: 'Engagement URL',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'jobName',
      Header: 'Job Name',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'jobRoles',
      Header: 'Job Roles',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'jobUrl',
      Header: 'Job URL',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'matchDecision',
      Header: 'Match Decision',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'matchingUrl',
      Header: 'Matching URL',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'shouldNotTag',
      Header: 'Should Not Tag',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'shouldTag',
      Header: 'Should Tag',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'source',
      Header: 'Source',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'whyNeedToReadCV',
      Header: 'Why Need To Read CV',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'reviewRequested',
      Header: 'Review Requested',
      Filter: FilterBoolean,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'sameDecision',
      Header: 'Same Decision',
      disableFilters: false,
      Filter: FilterStringColumnNoFacet,
      width: REACT_TABLE__COLUMN_LG,
    },
    {
      accessor: 'diffDecision',
      Header: 'Diff Decision',
      Filter: FilterStringColumnNoFacet,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_LG,
    },
    {
      accessor: 'whyNoCategories',
      Header: 'Why No Categories',
      Filter: FilterStringColumnNoFacet,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
      _accessor: '_whyNoCategories',
    },
    {
      accessor: 'whyNoDetails',
      Header: 'Why No Details',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'whyYesCategories',
      Header: 'Why Yes Categories',
      Filter: FilterStringColumnNoFacet,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'whyYesDetails',
      Header: 'Why Yes Details',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'whyNoPrivateNote',
      Header: 'Why No Private Note',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'whyYesPrivateNote',
      Header: 'Why Yes Private Note',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'fromAnnotator',
      Header: 'From Annotator',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'toAnnotator',
      Header: 'To Annotator',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'decision',
      Header: 'Decision',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'disagreementReason',
      Header: 'Disagreement Reason',
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'probScore',
      Header: 'Prob Score',
      Cell: CellTagColumn,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_MD,
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_createdAt',
    },
    {
      accessor: 'updatedAt',
      Header: 'Updated At',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_updatedAt',
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_XS,
      _headerClassName: 'sticky-end bg-cyan-common c-white-all bl-1',
      _cellClassName: 'sticky-end bg-white-common bl-1 d-flex flex-align-right-center',
    }
  ],
  moreMenuOptions: [],
  disabledStarred: true,
};
