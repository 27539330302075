import {
  useEffect,
  useState
} from 'react';
import {
  Arr
} from '../../../lib/Array.lib';
import Definition from "../../../lib/Definition";
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  trim
} from '../../../lib/String.lib';
import formatURL from '../../../lib/tools/formatURL';
import StepHeader from '../../Forms/StepHeader';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Col from '../../Layout/Wrappers/Col';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import RadioGroup from '../../Layout/Wrappers/RadioGroup';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import Row from '../../Layout/Wrappers/Row';
import StyledTooltip from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import AnswerJobsQuestions from '../../PrescreenQuestions/AnswerJobsQuestions';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CANDIDATE_SUMMARY__INCLUDE_FLAGS
} from '../SubmissionNotes.lib';
import CandidateJobChoices from './CandidateJobChoices';
import {
  CandidateRolesFieldset
} from './CandidateRolesFieldset';
import {
  EditCandidateRecruiters
} from './EditCandidateRecruiters';
import {
  IncludeSubmissionCheckbox
} from './IncludeSubmissionCheckbox';

const CONFIG = {
  name: 'candidate_edit__step_3'
};

export function CandidateEditStep3(props) {
  let { CandidateEditController } = props;
  let { state: candidate, state } = CandidateEditController
  let { account: recruiter } = candidate;

  // following 2 lines are to force compatibility of children class components are using componentDidUpdate
  const [, keepLive] = useState();
  useEffect(() => { keepLive(state.step); }, [state.step]);

  const _openStickyNote = event => {
    try {
      if (!event.target.className.match(/ql-editor|ms_button/i)) {
        return console.debug(`element does no match (${event.target.className})`);
      }
      let { top, right, left } = event.target.getBoundingClientRect();
      top = Number(top) - 64;
      left = Number(right) + 32;
      CandidateEditController.StickyNote.open({ top, left })
    } catch (e) { console.debug(e); }
  }

  return (
    <div className='p-1'>

      <StepHeader {...props} />

      {/** 3A */}
      <CandidateRolesFieldset
        CandidateEditController={CandidateEditController}
      />

      {/** 3B1 - reasonsToLeaveLastJob */}
      <Fieldset
        title={CANDIDATE_UI.reasonsToLeaveLastJob.title} dot='orange'
        nextToTitle={
          <StyledTooltip
            title={CANDIDATE_UI.reasonsToLeaveLastJob.tooltip}
            placement='top'
            paperStyle={{ maxWidth: 420 }}
          >
            <i className='material-icons c-purple help icon16'>help_outline</i>
          </StyledTooltip>
        }
        actionBar={
          <IncludeSubmissionCheckbox
            CandidateEditController={CandidateEditController}
            fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.reasonsToLeaveLastJob}
          />
        }
      >
        <TextField
          name="reasonsToLeaveLastJob"
          value={CandidateEditController.state.reasonsToLeaveLastJob}
          onChange={(event) =>
            CandidateEditController.setStateStore({
              reasonsToLeaveLastJob: event.target.value, key: 'reasonsToLeaveLastJob'
            })
          }
          placeholder={CANDIDATE_UI.reasonsToLeaveLastJob.tooltip}
          variant='standard'
          multiline
          fullWidth
        />
      </Fieldset>

      {/** 3B2 - explainRedFlags */}
      <Fieldset
        title={CANDIDATE_UI.explainRedFlags.title} dot='orange'
        info={CANDIDATE_UI.explainRedFlags.tooltip}
        actionBar={
          <IncludeSubmissionCheckbox
            CandidateEditController={CandidateEditController}
            fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.explainRedFlags}
          />
        }
      >
        <TextField
          name="explainRedFlags"
          value={CandidateEditController.state.explainRedFlags}
          onChange={(event) =>
            CandidateEditController.setStateStore({ explainRedFlags: event.target.value, key: 'explainRedFlags' })
          }
          placeholder={CANDIDATE_UI.explainRedFlags.tooltip}
          variant='standard'
          multiline
          fullWidth
        />
      </Fieldset>

      {/** 3BB */}
      {/** [ 2023-01-18 ][ MS: content removed by story_7792 ] */}

      {/** 3C */}
      {/** submissionNotes */}
      <Fieldset
        title={CANDIDATE_UI.submissionNotes.title}
        dot={'orange'}
        nextToTitle={
          <StyledTooltip
            title={CANDIDATE_UI.submissionNotes.tooltip}
            placement='top'
            paperStyle={{ maxWidth: 540 }}
          >
            <i className='material-icons c-purple help icon16'>help_outline</i>
          </StyledTooltip>
        }
        actionBar={
          <>
            <Button
              onClick={_openStickyNote}
              variant='text'
              className='c-black-medium tt-unset ms_button'
              startIcon={<i className='material-icons c-inherit'>sticky_note_2</i>}
            >
              View 10x10 auto generated summary
            </Button>
          </>
        }
      >
        <Box row w100 onFocus={_openStickyNote}>
          <RichTextBox
            name='submissionNotes'
            value={CandidateEditController.state.submissionNotes}
            placeholder={CANDIDATE_UI.submissionNotes.placeholder}
            onChange={(submissionNotes) => {
              CandidateEditController.setStateStore({
                submissionNotes,
                key: 'submissionNotes',
              });
            }}
            minH128
          />
        </Box>
      </Fieldset>

      {/** 3D */}
      {/** noteToAccountManager */}
      <Fieldset
        title={CANDIDATE_UI.noteToAccountManager.title}
        info={CANDIDATE_UI.noteToAccountManager.placeholder}
      >
        <RichTextBox
          name='noteToAccountManager'
          placeholder={CANDIDATE_UI.noteToAccountManager.placeholder}
          value={candidate.noteToAccountManager}
          onChange={value => {
            CandidateEditController.setStateStore({
              noteToAccountManager: value
            });
          }}
        />
      </Fieldset>

      {/** 3E-1 */}
      <div className='mt-3'>
        <AnswerJobsQuestions
          name={CONFIG.name}
          reference={self => (AnswerJobsQuestions[CONFIG.name] = self)}
          candidate={candidate}
          jobIds={candidate.jobsPermitted}
          onAcceptLabel='Apply'
          onCancelLabel='Cancel'
          onCancel={update => () => {
            CandidateEditController.setStateStore(update);
          }}
          onChange={CandidateEditController.setStateStore}
        />
        <CandidateJobChoices
          CandidateEditController={CandidateEditController}
          parentComponent={CandidateEditController}
          parent={CandidateEditController.state}
          handlerQuestionsLink={event => AnswerJobsQuestions[CONFIG.name].open()}
          onChange={(item) => (result, jobObjects) => {
            let toBeSaved = { key: item.key };
            toBeSaved[item.key] = result;
            CandidateEditController.setStateStore(toBeSaved);
            CandidateEditController.setState({ [`_${item.key}`]: jobObjects }, () => {
              setTimeout(() => AnswerJobsQuestions[CONFIG.name].open(), 300);
            });
            CandidateEditController.checkPotentialDuplicatedCandidates((em) => { });
          }}
        />
      </div>

      {/** 3E-2 */}
      {/** @todo [ 2023-01-04 ][ TBD ] */}

      <Divider className='mt-3' />

      {/** 3E-3 */}
      <EditCandidateRecruiters
        candidate={candidate}
        recruiter={recruiter}
        onSave={update => CandidateEditController.setStateStore({ ...update, key: 'emailsList' })}
        className='mt-3'
        dot
      />

      {/** 3E-4 */}
      {/** HOW DID YOU FIND */}
      <Fieldset
        title='How did you find the candidate?'
        dot='orange'
      >
        <RadioGroup
          name="relationship"
          options={Definition.get("relationShip")}
          value={CandidateEditController.state.relationship}
          onChange={(event, relationship) => {
            CandidateEditController.setStateStore({ relationship, key: 'relationship' });
          }}
        />
      </Fieldset>

      {/** 3E5 */}
      <Row>
        <Fieldset
          title="Candidate's calendar booking link"
          dot='orange'
          nextToTitle={!!CandidateEditController.state.calendarBookingLink && (
            <i
              className="material-icons pointer"
              style={{ fontSize: 18 }}
              onClick={(ev) => window.open(CandidateEditController.state.calendarBookingLink)}
            >
              open_in_new
            </i>
          )}
        >
          <TextField
            name="calendarBookingLink"
            value={CandidateEditController.state.calendarBookingLink}
            onChange={(event) => {
              let calendarBookingLink = formatURL(event.target.value);
              const update = {
                calendarBookingLink,
                key: "calendarBookingLink"
              };

              // [2023-12-13][story_9765] "Auto select the checkbox if the recruiter enters a url."
              // IF the NEW calendarBookingLink value IS empty, remove the flag for it from submissionIncludeFlags.
              if (NOT(trim(calendarBookingLink))) {
                update.submissionIncludeFlags = Arr(
                  CandidateEditController.state.submissionIncludeFlags
                ).filter(v => !!v && (v !== CANDIDATE_SUMMARY__INCLUDE_FLAGS.calendarBookingLink));
              }
              // ELSE IF the OLD calendarBookingLink value IS empty, include the flag for it in submissionIncludeFlags.
              else if (NOT(trim(CandidateEditController.state.calendarBookingLink))) {
                update.submissionIncludeFlags = [
                  ...new Set([
                    ...Arr(CandidateEditController.state.submissionIncludeFlags),
                    CANDIDATE_SUMMARY__INCLUDE_FLAGS.calendarBookingLink
                  ])
                ].filter(v => !!v);
              }

              CandidateEditController.setStateStore(update);
            }}
            variant='standard'
            fullWidth
          />
          <IncludeSubmissionCheckbox
            CandidateEditController={CandidateEditController}
            fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.calendarBookingLink}
          />
        </Fieldset>
        <Fieldset
          title='Where else are they interviewing?'
          dot='orange'
        >
          <TextField
            name='otherCompanyInterviews'
            value={CandidateEditController.state.otherCompanyInterviews}
            onChange={(event) =>
              CandidateEditController.setStateStore({
                otherCompanyInterviews: event.target.value,
                key: 'otherCompanyInterviews',
              })
            }
            variant='standard'
            multiline
            rowsMax={5}
            fullWidth
          />
          <IncludeSubmissionCheckbox
            CandidateEditController={CandidateEditController}
            fieldId={CANDIDATE_SUMMARY__INCLUDE_FLAGS.otherCompanyInterviews}
          />
        </Fieldset>
      </Row>

      {/** 3E-6 */}
      {/** HOW SOON */}
      <Row className='mt-3'>
        <Col fullWidth>
          <label>How soon can they start after accepting an offer?</label>
          <RadioGroup
            name="acceptOffer"
            options={
              Definition.get("offerAcceptance")
                .filter(
                  (tag) => (tag.visible !== false)
                )
                .map(
                  (tag) => ({
                    ...tag,
                    onClick: (event) => {
                      if (CandidateEditController.state.acceptOffer === tag.id) {
                        CandidateEditController.setStateStore({
                          acceptOffer: 0,
                          key: 'acceptOffer'
                        });
                      }
                    }
                  })
                )
            }
            value={CandidateEditController.state.acceptOffer}
            onChange={(event, acceptOffer) =>
              CandidateEditController.setStateStore({
                acceptOffer,
                key: 'acceptOffer'
              })
            }
          />
        </Col>
      </Row>

    </div>
  );
}
