import {
  Snackbar as MuiSnackbar
} from '@mui/material';
import {
  Fun
} from '../../../lib/Function.lib';

/**
 * @see https://mui.com/material-ui/api/snackbar/
 */
export default function Snackbar({
  autoHideDuration = 6000,
  onClose = Fun,
  children = '',
  message = children,
  ...props
}) {
  const _props = {
    autoHideDuration,
    onClose,
    message,
    ...props
  };
  return (
    <MuiSnackbar {..._props} />
  );
}
