import {
  Tab,
  Tabs
} from '@mui/material';
import {
  uniqueId
} from 'lodash';
import PropTypes from 'prop-types';
import {
  useState
} from 'react';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  getPacificTimeIsoString
} from '../../../lib/Date.lib';
import Box from './Box';
import Typography from './Typography';

export const NAVIGATION_TABS__DEFAULT_TAB_1 = 'default_navigation_tab_1';
export const NAVIGATION_TABS__DEFAULT_TAB_2 = 'default_navigation_tab_2';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * 
 * @param {object} props_
 * @param {object} props_.defaultTab - default 0 
 * @returns 
 */
export default function NavTabs(props) {
  let {
    data = [
      {
        title: NAVIGATION_TABS__DEFAULT_TAB_1,
        content: NAVIGATION_TABS__DEFAULT_TAB_1
      },
      {
        title: NAVIGATION_TABS__DEFAULT_TAB_2,
        content: NAVIGATION_TABS__DEFAULT_TAB_2
      }
    ],
    defaultTab = 0,
    reset
  } = props;
  data = sanitizeArr(data);
  let [state, setState] = useState({ value: defaultTab, prev: reset });
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  const handleChange = (event, newValue) => {
    _updateState({ value: newValue });
  };
  let { value = 0, prev } = state;
  (async () => {
    if (reset !== prev) {
      prev = reset;
      await _updateState({ value: defaultTab, prev });
    }
  })();
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {data.map(({ title }, index) => (
            <Tab
              id={`simple_tab__index_${index}`}
              label={title}
              aria-controls={`simple_tabpanel__index_${index}`}
              key={`simple_tab__name_${props.name}__index_${index}__${uniqueId()}`}
            />
          ))}
        </Tabs>
      </Box>
      {data.map(({ content }, index) => (
        <TabPanel
          value={value}
          index={index}
          key={`navigation-tab-content-${props.name || getPacificTimeIsoString()}-${index}`}
        >
          {content}
        </TabPanel>
      ))}
    </Box>
  );
}

