import moment from 'moment';
import Core from '../Core';

export default function getHoldModel({ prefill, startDate, endDate, note } = {}) {
  const holdModel = {
    "startDate": prefill ? startDate || moment().toISOString() : null,              // <ISODateString>
    "endDate": prefill ? endDate || moment().add(2, 'days').toISOString() : null,   // <ISODateString>
    "note": note || '',                                                             // <String>
    "byWho": Core.getUser().email,                                                  // <StringEmail>
  }
  return holdModel;
}
