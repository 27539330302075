import moment from 'moment';
import {
  join
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  DATE_FORMAT__DATE__US_LONG,
  formatDateTime12hPT
} from '../../../lib/Date.lib';
import {
  trim
} from '../../../lib/String.lib';
import Table from '../../Layout/Wrappers/Table';
import StyledTooltip, {
  PLACEMENT__BOTTOM_START, PLACEMENT__RIGHT
} from '../../Layout/Wrappers/StyledTooltip';

export function Row2Col1(props) {
  const { engagement } = props;
  const streakFailures = window.streakFailures || {};
  return (
    <Table.Cell
      onClick={props.onClick}
      className="first-item blocks cursor-default"
      style={{ minWidth: 300, maxWidth: 300 }}
      colSpan={3}
    >
      <span
        title="Stage, Status, State, Rejection Reason"
        className="fw-600 c-black-medium"
      >
        {engagement.stage}, {engagement.status}
        {`${Core.isAdminOrCoordinator() ? `, ${engagement.state}` : ''}`}
        {engagement.rejectionReason && /closed/i.test(engagement.state)
          ? ', ' + engagement.rejectionReason
          : ''}
      </span>
      <StyledTooltip
        title={(engagement.statusUpdatedDate
          ? join([
            `Status updated: ${formatDateTime12hPT(
              engagement.statusUpdatedDate,
              { seconds: true }
            )}`,
            !!engagement.actionOwnerId && trim(
              engagement.actionOwner.email
            )
          ], ' ') : ''
        )}
        placement={PLACEMENT__BOTTOM_START}
        className='d-flex flex-align-left-center'
      >
        <span className='mr-auto'>
          {join([
            !!engagement.statusUpdatedDate &&
            `Last: ${moment(
              engagement.statusUpdatedDate
            ).format(
              DATE_FORMAT__DATE__US_LONG
            )}`,
            !!engagement.actionOwnerId && (
              `Owner: ${engagement.actionOwner._name}`
            )
          ])}
        </span>
        {Core.isAdmin() && (
          <StyledTooltip
            title="Go to Streak Box"
            placement={PLACEMENT__RIGHT}
          >
            <a
              href={`https://mail.google.com/mail/u/1/#box/${engagement.boxKey}`}
              target="_blank"
              rel="noreferrer"
              className={`pointer ${!engagement.boxKey ? 'c-red' : ''}`.trim()}
            >
              Streak&nbsp;❯&nbsp;&nbsp;&nbsp;
            </a>
          </StyledTooltip>
        )}
      </StyledTooltip>
      {
        Core.isAdmin() && !!streakFailures[engagement.id]?.length && (
          <span className="c-red capitalize">
            Streak Failures: {streakFailures[engagement.id].join(', ')}
          </span>
        )
      }
    </Table.Cell >
  );
}
