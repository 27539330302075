import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  mapEngagement
} from '../../models/engagement';
import {
  Obj
} from '../../Object.lib';
import {
  COLLECTION__ENGAGEMENTS,
  readLoopbackRecord
} from '../../services/BE/loopback.api';
import {
  getParams
} from '../../URL.lib';
import generateCandidateCAVE from './generateCandidateCAVE.fun';
import generateRecruiterCAVE from './generateRecruiterCAVE.fun';
import navigateToNextJob from './navigateToNextJob.fun';
import openDialogCAVE from './openDialogCAVE.fun';

/**
 * Controls the flow for handling a Candidate and Recruiter CAVE process for a specific engagement.
 *
 * @param {Object} params - Parameters for controlling the CAVE flow.
 * @param {string} [params.engagementId=''] - The ID of the engagement to process.
 * @returns {Promise<void>} - A promise that resolves once the entire flow is complete.
 */
export default async function controlFlowCAVE({
  engagementId = ''
}) {
  try {
    const engagement = Obj(
      await readLoopbackRecord({
        collection: COLLECTION__ENGAGEMENTS,
        where: { id: engagementId },
        limit: 1,
        include: [
          {
            relation: 'candidate',
            scope: {
              include: [
                'account',
                {
                  'engagements': [
                    { 'job': 'employer' },
                    'candidate'
                  ]
                }
              ],
            },
          },
          { 'job': 'employer' }
        ],
        mapper: mapEngagement
      })
    );
    await openDialogCAVE({
      engagement,
      generator: generateCandidateCAVE,
      waitForEmailResponse: false
    });
    await openDialogCAVE({
      engagement,
      generator: generateRecruiterCAVE
    });
    navigateToNextJob({
      candidateId: engagement.candidateId,
      nextJobId: getParams({
        pattern: '/represent/:engagementId/:nextJobId'
      }).nextJobId
    });
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
