
/**
 * @param {string:html} URL
 * @param {string:html} NAME
 * @param {string:html} SUBTITLE
 */
export const TEMPLATE__COMMON__GREEN_BUTTON = (`
  <div style="
      background-color: #ffffff;
      display: block;
      margin: 0 0 2rem;
      max-width: 360px;
      font-family: Helvetica, Arial, sans-serif;
      text-align: center;
      justify-items: center;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      padding: 1rem 2rem;
    ">
    <div style="margin-top: 0rem">
      <a rel="noopener" target="_blank" href="{{{URL}}}" style="
        background-color: #009688;
        font-size: 0.875rem;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: bold;
        text-decoration: none;
        padding: 0.75rem 1.5rem;
        color: #ffffff;
        border-radius: 4px;
        display: inline-block;
      ">
        <span>{{{NAME}}}</span>
      </a>
    </div>
    <div style="
      font-size: 0.75rem;
      color: #7d7e80;
      margin-top: 0.5rem;
    ">
      {{{SUBTITLE}}}
    </div>
  </div>
`);
