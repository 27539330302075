import moment from "moment";
import Core from "./Core";
import Store from "./Store";
import {
  getHash,
  getLocation,
  reloadLocation,
  setLocation
} from './URL.lib';

const HistoryLog = {
  set: ({ label, state, group }, then) => {
    const historyDate = String(getLocation()).split("history=")[1];
    // console.log({ historyDate, res: moment(historyDate).isValid() });
    if (historyDate && moment(historyDate).isValid()) {
      then instanceof Function && then(HistoryLog.get());
    } else {
      const hash = getHash().replace(/\?history.*/g, "");
      const history = (Store.get("history") || []).filter(
        itm => itm.hash !== hash
      );
      history.unshift({
        hash,
        label,
        state: state || {},
        group: group || label,
        date: moment().format()
      });
      history.splice(20);
      Store.set("history", history);
      // console.log("set", { history });
    }
  },
  get: em => {
    const history = Store.get("history") || [];
    const hash = getHash().replace(/\?history.*/g, "");
    const date = getHash().split("?history=")[1];
    const result = history.find(
      itm => itm.hash === hash && itm.date === date
    ) || { state: {} };
    // console.log("get", { result });
    return result;
  },
  getList: em => {
    const history = (Store.get("history") || []).sort((b, a) =>
      String(a.date).localeCompare(String(b.date))
    );
    // console.log("list", { history });
    return history;
  },
  getGroupList: em => {
    const list = HistoryLog.getList();
    const results = {
      accounts: [],
      candidates: [],
      employers: [],
      jobs: []
    };
    list.forEach(
      history => results[history.group] && results[history.group].push(history)
    );
    return results;
  },
  go: history => {
    setLocation(
      getLocation().split("#")[0] +
      history.hash +
      "?history=" +
      history.date
    );
    reloadLocation();
    Core.closeDrawer();
  }
};
export default HistoryLog;
