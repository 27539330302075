import {
  Component
} from "react";
import {
  join,
  sanitizeArr
} from '../../../lib/Array.lib';
import Definition from "../../../lib/Definition";
import Box from '../../Layout/Wrappers/Box';
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import Typography from '../../Layout/Wrappers/Typography';

class EducationHistory extends Component {

  getEducationHistory = (candidate) => {
    let list = [];
    candidate.educationHistories = sanitizeArr(candidate.educationHistories);
    if (candidate.educationHistories.length !== 0) {
      candidate.educationHistories.forEach((school) => {
        sanitizeArr(school.degrees).forEach((degree) => {
          list.push(
            join([
              degree.degreeMajor,
              degree.degreeType,
              school.schoolName,
              join([
                !!degree.startDate && degree.startDateYear,
                !!degree.endDate && degree.endDateYear
              ], ' - ')
            ])
          )
        })
      })
    }
    else {
      sanitizeArr(candidate.schoolsLinkedIn).forEach((obj, index) => {
        list.push(
          join([
            obj.field,
            Definition.getLabel('undergraduateDegree', obj.degree),
            obj.school,
            obj.date
          ])
        )
      })
    }
    return list;
  }

  render() {
    const { candidate } = this.props;
    return (
      <Card className="mb-05">
        <Typography strong large alignCenter blackDark
          className='m-1'
        >
          EDUCATION
        </Typography>
        <Divider />
        {((cando) => {
          const history = this.getEducationHistory(cando);
          return sanitizeArr(history).map((item, index) => {
            return (
              <Box row
                key={`match__candidate__pipe__education__${index}`}
                className='p-1'
              >
                {item}
              </Box>
            )
          })
        })(candidate)}
      </Card>
    )
  }
}

export default EducationHistory;