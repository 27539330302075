import Definition, {
  LOC_TYPE__ALIAS_ID
} from "../../lib/Definition";
import Box from '../Layout/Wrappers/Box';
import Menu from '../Layout/Wrappers/Menu';
import Typography from '../Layout/Wrappers/Typography';

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function TagAttributeItem(props) {
  const {
    category,
    tag,
    attributeName,
    value = LOC_TYPE__ALIAS_ID,
    onChange = () => { },
  } = props;
  function _getLocationTags({ ignoreAlias } = {}) {
    return category.values.filter(n => (
      /* story-3220 M1 - "not allow aliases to be selected as parents" */
      (ignoreAlias ? n.type !== LOC_TYPE__ALIAS_ID : true) &&
      (n.id !== tag.id) &&
      !n.archived
    ));
  }
  function _onChange(attributeName) {
    return (value) => {
      onChange({
        attributeName,
        value,
      });
    }
  }
  return (
    <Box row noWrap w100>
      <Typography small mr
        style={{ width: 120 }}
      >
        {attributeName}:
      </Typography>
      {(
        attributeName.match(/type/i)
          ? (
            <Menu dropdown avoidNullOption
              name="type"
              placeholder="Select a type of location"
              options={[
                ...Definition.get('locationType')
              ]}
              value={value}
              onChange={_onChange('type')}
            />
          )
          : attributeName.match(/parentId/i)
            ? (
              <Menu dropdown
                name="locationId"
                placeholder="Select a location"
                options={_getLocationTags({ ignoreAlias: true })}
                value={value}
                onChange={_onChange('parentId')}
              />
            )
            : attributeName.match(/aliasLocationIds/i)
              ? (
                <Menu multiple
                  name="aliasLocationIds"
                  placeholder="Select multiple locations"
                  options={_getLocationTags()}
                  value={value}
                  onChange={_onChange('aliasLocationIds')}
                />
              )
              : ''
      )}
    </Box>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export {
  TagAttributeItem as default,
  TagAttributeItem
};

/* ============================================ */
