import {
  Arr,
  ArrInt
} from '../../../../lib/Array.lib';
import Definition from '../../../../lib/Definition';
import {
  evaluatePositiveSignal
} from './PositiveSignals.lib';
import {
  evaluateAnyHaves,
  haveDecision
} from './Complex.lib';

const MAPPING__CATEGORY_TO_FIELD = {
  technicalSkills: 'technicalSkills',   // candidate has array
  positiveSignals: 'positiveSignals',   // candidate has array
  negativeSignals: 'negativeSignals',   // candidate has array
  experience: 'level',                  // candidate has integer
  technologyDomain: 'technologyDomain'  // candidate has array
};

export function evaluateChip({ have, candidate }) {

  let parsedToMatch = [];

  if (MAPPING__CATEGORY_TO_FIELD[have.key] === 'level') {
    const candidateLevel = Definition.getLabel('level', parseInt(candidate.level));
    const haveExperience = Definition.getLabel('experience', parseInt(have.value));
    return have.match = (haveExperience === candidateLevel);
  }
  else if (MAPPING__CATEGORY_TO_FIELD[have.key] === 'positiveSignals') {
    return have.match = evaluatePositiveSignal({
      tagId: parseInt(have.value),
      tagIds: ArrInt(candidate[MAPPING__CATEGORY_TO_FIELD[have.key]])
    });
  }
  else if (!!have.category && have.category === 'technicalSkills') {
    const additionalMappers = [
      ...Arr(candidate[have.category]),
      ...Arr(candidate._strongTechnicalSkills),
      ...Arr(candidate.techSkillsInResume),
    ].map((tagId) => parseInt(tagId));
    return have.match = additionalMappers.includes(parseInt(have.value));
  }
  else {
    if (!!have.key) {
      parsedToMatch = candidate[MAPPING__CATEGORY_TO_FIELD[have.key]].map((tagId) => parseInt(tagId));
      return have.match = parsedToMatch.includes(parseInt(have.value));
    }
    return have.match = false;
  }
}

export function evaluateChipWithDecision({ have, candidate }) {
  return evaluateAnyHaves({
    decisions: [
      haveDecision(
        evaluateChip({ have, candidate })
      )
    ]
  });
}
