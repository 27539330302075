/** ============================================ µ
 * @description ToggleResume [JSX]
 *              V3-table UI Component
 * @createdAt   2021-10-19 Tue
 * @updatedAt   2021-10-19 Tue
 * ============================================ */
/* IMPORTS ==================================== */

import { Switch } from "antd";
import React from "react";

/* CONSTANTS ================================== */

/* METHODS ==================================== */

function ToggleResume(props) {
  const { enhancedReactTableContext, style, className } = props;
  const {
    latestQuery = {},
    setResumeFlag,
  } = enhancedReactTableContext;
  let { resume } = latestQuery;
  return (resume !== undefined) && (
    <div style={style} className={className}>
      <Switch
        checkedChildren={`Search CV On`}
        unCheckedChildren={`Search CV Off`}
        checked={!!resume}
        onChange={checked => setResumeFlag(checked)}
      />
    </div>
  );
}

/* DICTIONARIES =============================== */

/* EXPORTS ==================================== */

export { ToggleResume as default, ToggleResume };

/* ============================================ */
