import {
  ThemeProvider
} from '@mui/material/styles';
import {
  createRoot
} from 'react-dom/client';
import {
  HashRouter
} from 'react-router-dom';
import './assets/scripts/global';
import './assets/styles/__imports.css';
import GlobalComponents from './components/Home/GlobalComponents';
import Theme from './components/Layout/Libraries/Theme.lib';
import Box from './components/Layout/Wrappers/Box';
import {
  LoadingMessage
} from './components/Layout/Wrappers/Message';
import './i18n/i18n.tool';
import Core from './lib/Core';
import './lib/Debug.lib';
import useStart from './lib/hooks/useStart.hook';
import AdminUser from './routes/Admin.routes';
import CommonUser from './routes/CommonUser.routes';
import EmployerUser from './routes/Employer.routes';
import Error from './routes/Error';
import RecruiterOnDev from './routes/RecruiterOnDev.routes';
import Unlogged from './routes/Unlogged.routes';

function App(props) {
  const { busy, error, ready } = useStart();
  /**
   * @note
   * The ThemeProvider and HashRouter
   * must wrap all rendered elements
   * in order to work properly.
   */
  return (
    <ThemeProvider theme={Theme}>
      <HashRouter>
        <Box role='Main' className="vh-100 vw-100">
          <LoadingMessage show={busy}
            className='vh-100 vw-100 m-0 flex-align-center bg-cyan-lighter'
          >
            Starting...
          </LoadingMessage>
          <Error error={error} />
          <GlobalComponents />
          {ready &&
            <Box role='Content' className='vh-100 vw-100'>
              {
                Core.isLoggedOut() ? (
                  <Unlogged />
                ) : Core.isAdmin() ? (
                  <AdminUser />
                ) : Core.isEmployer() ? (
                  <EmployerUser />
                ) : Core.isRecruiterOnDev() ? (
                  <RecruiterOnDev />
                ) : (
                  <CommonUser />
                )
              }
            </Box>
          }
        </Box>
      </HashRouter>
    </ThemeProvider>
  );
};

createRoot(document.getElementById('Root')).render(<App />);
