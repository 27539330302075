
const EmployerPendingsUI = {
  middlePanelCardTitleAcceptance: 'Next step',
  middlePanelCardHeaderOneAcceptance: 'Current status',
  middlePanelCardHeaderTwoAcceptance: 'Why Yes?',
  middlePanelActionYes: 'Like',
  middlePanelActionYesInterview: 'Yes - interview!',
  middlePanelInputPlaceholder: 'Please let us know the latest status',
  middlePanelInputPlaceholderYes: 'Share why you liked the candidate, along with current status and next steps',
  middlePanelInputPlaceholderNo: `Share why rejection to help recruiters filter future submissions`,
  middlePanelInputActionFeedbackOnly: {
    actionLabel: 'Notify 10 By 10',
    actionCompleted: 'Sent'
  },
  urlGeneratorDialog: {
    title: 'Employer portal - generate and copy URL',
    acceptLabel: 'Generate & copy URL',
    warningNoAccountsFound: 'No accounts configured',
    unexpectedError: 'The url could not be generated'
  },
  TriggerWaitingEmployerSummary: {
    IconButton: {
      tooltip: 'Generate todos summary',
      loadingTooltip: 'Generating todos summary...'
    },
    MenuItem: {
      tooltip: 'Employer: Generate todos summary',
    }
  }
};
export default EmployerPendingsUI;
