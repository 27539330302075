import Card from "@mui/material/Card";
import {
  Component
} from "react";
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Candidate from "../../../lib/Candidate";
import Core from "../../../lib/Core";
import {
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__MATCH_CANDIDATE,
  ACCOUNT_ACTION__RESUME_SUBMISSION,
  ACCOUNT_ACTION__VIEW_ENGAGEMENT
} from "../../../lib/Definition";
import Engagement from "../../../lib/Engagement";
import {
  YES
} from '../../../lib/GenericTools.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import {
  mapCandidateTechSkills
} from '../../../lib/models/candidate';
import {
  CANDIDATE_UI
} from '../../Candidates/CandidateUI.dic';
import {
  openScrubbedResume
} from '../../Home/3DotsMenu/moreMenuOptionsCandidates';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Checkbox from '../../Layout/Wrappers/Checkbox';
import Chip from '../../Layout/Wrappers/Chip';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import NavLink from '../../Layout/Wrappers/NavLink';
import Switch from '../../Layout/Wrappers/Switch';
import Typography from '../../Layout/Wrappers/Typography';
import {
  getMLStatsString
} from '../Libraries/Match.lib';
import {
  getMatchEntities,
  KEY__CON__SINGLE_CARD
} from '../Libraries/MatchList.lib';
import getJobLabels from '../Libraries/Tools/getJobLabels.tool';
import getOwnershipChip from '../Libraries/Tools/getOwnershipChip.tool';
import onSelectMatch from '../Libraries/Tools/onSelectMatch.tool';
import {
  MatchPage__addKeyword,
  MatchPage__getState,
  MatchPage__removeKeyword
} from '../MatchPage';
import CardCheckbox from "./CardCheckbox";
import CreateDisagreement from "./CreateDisagreement";
import {
  ListPipeController
} from './ListPipe';
import ManageEngagement from "./ManageEngagement";
import MatchDecisionDetails, {
  MATCH_DECISION_DETAILS__CONFIG
} from './MatchDecisionDetails';
import MatchDecisionMaker from './MatchDecisionMaker';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__CD_REQUESTER
} from "./MatchLocationChips";
import ThumbsBar from "./ThumbsBar";

class SingleCandidateCard extends Component {
  constructor() {
    super(...arguments);
    this.role = 'SingleCandidateCard';
    this.state = {
      ...this.props,
      showQuestions: false,
      wantsUpdateEngagement: false,
      reviewed: false,
      MlDecisionPending: true,
      matchDecisions: [],
    };
    const {
      match = {}
    } = this.props;
    Object.assign(match, {
      [KEY__CON__SINGLE_CARD]: this
    });
  }

  componentDidMount() {
    const {
      match = {}
    } = this.state;
    if (!this.state.processedOwnerships) {
      this.setState({ processedOwnerships: true });
      if (match.id) {
        Candidate.getPotentialDuplicatedWithOwnerships(match).then((result) => {
          match.jobOwnerships = Arr(result);
          Engagement.getWhereV2(
            { candidateId: match.id },
            {
              include: [
                {
                  relation: "job",
                  scope: {
                    fields: ["id", "employerId", "jobTitle", "roles", 'visaTransfer', 'technicalSkills'],
                    include: [
                      {
                        relation: "employer",
                        scope: {
                          fields: ["id", "name"],
                        },
                      },
                    ],
                  },
                },
              ],
              fields: [
                "id",
                "jobId",
                "candidateId",
                "stage",
                "status",
                "state",
                "lastAction",
              ],
            }, {}, { source: `single_candidate_card__component_did_mount` }
          ).then(
            (engagements) => {
              match.engagements = engagements;
              this.setState({ match });
            }
          );
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isSelected !== this.props.isSelected) {
      this.setState({ isSelected: this.props.isSelected });
    }
    if (prevState.job !== this.props.job) {
      this.setState({ job: this.props.job });
    }
  }

  afterUpdateEngagement = (update = {}) => {
    const { afterMainUpdateEngagement } = this.props;
    const {
      engagement = {}
    } = this.state;
    Object.assign(engagement, update);
    this.setState({ engagement },
      () => afterMainUpdateEngagement(engagement)
    );
  };

  closeParentPopup = () => {
    this.setState({ wantsUpdateEngagement: false });
  };

  handlerUpdateEngagement = () => {
    this.setState({ wantsUpdateEngagement: true });
  };

  onCheckStar = (job) => (event, checked) => {
    event.stopPropagation();
    Candidate.updateStarred(
      job.id,
      this.state.starredId,
      checked,
      (response) => {
        job.starred = response.starred;
        this.setState({ job });
      }
    );
  };

  handlerOnSelect = () => {
    const {
      type = '',
    } = this.props;
    const {
      match = {},
      engagement = {}
    } = getMatchEntities(this.state);
    const newSelectedMatch = type === 'engagement' ? engagement : match;
    onSelectMatch(newSelectedMatch);
    ListPipeController().resizeIndex(
      this.props.index
    );
  };

  handlerMlDecisionMaking = (status, MlDecisionMakers) => {
    this.setState(
      {
        MlDecisionPending: status,
        matchDecisions: MlDecisionMakers,
      },
      () => ListPipeController().resizeIndex(
        this.props.index
      )
    );
  };

  render() {
    const {
      profile = {},
      match = {},
      engagement = {},
      recruiter = {},
    } = getMatchEntities(this.state);
    const {
      wantsUpdateEngagement,
      reviewed,
      MlDecisionPending,
      matchDecisions,
    } = this.state;
    const {
      type = '',
      isSelected = false,
      handlerOnSelectBox,
      selectedMatches,
      segregateRolesChip,
      updateEngagement,
      measure,
      registerChild,
      label = '',
      index = 0,
      style = {}
    } = this.props;

    const cardStyles = {};

    if (isSelected) {
      cardStyles.border = `2px solid ${colors.purple.common}`;
    }
    if (type === 'engagement') {
      cardStyles.backgroundColor = 'LightGrey';
    }
    else if (match.isDuplicate) {
      cardStyles.backgroundColor = 'LightPink';
    }

    const mlStatsJoined = getMLStatsString({
      mlScoreObject: match.mlScoreObject
    });

    let { keywords = [] } = MatchPage__getState();

    let isFilterByRecruiter = !!keywords.find(k => k.name === recruiter._name);

    let ownerShipChip = getOwnershipChip({ profile, match });
    let ownerShipChipLabel = Obj(Obj(Arr(ownerShipChip)[0]).props)['data-label'];
    let isAlreadyOwnIt = ownerShipChipLabel === 'iAlreadyOwnIt';
    if (this.state.isAlreadyOwnIt !== isAlreadyOwnIt) {
      setTimeout(() => this.setState({ isAlreadyOwnIt }));
    }

    const _engagementId = engagement.id
    const _hasCheckedOrEngagementId = (!!this.state.checked || !!_engagementId);
    const _hasRequiredFields = (!!_engagementId && !!profile.id && !!match.id);
    const _isAdminAndHasCheckedOrEngagement = Core.isAdmin() && _hasCheckedOrEngagementId;
    const _menuOptions = [
      {
        acl: (
          Core.isAdmin({ action: ACCOUNT_ACTION__RESUME_SUBMISSION }) &&
          _hasCheckedOrEngagementId &&
          _hasRequiredFields
        ),
        label: 'Submission',
        onClick: (ev) => Core.go({ ...(this?.props || {}), to: `/candidate/resume-submission/${match.id}/${profile.id}` })
      },
      {
        acl: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES }),
        label: 'View Dups',
        onClick: () => Core.openExact("/candidates?viewDup=t&cId=" + match.id)
      },
      {
        acl: !!match.sovrenDataId,
        label: CANDIDATE_UI.resumeScrubbedUrl.moreMenuLabel,
        onClick: () => openScrubbedResume({ candidate: match })
      },
      // CALCULATE MATCH SCORE
      {
        label: 'Calculate Match Score',
        onClick: () => {
          match.mlScore = "calculating";
          match.mlScoreObject = {};
          this.setState({ match });
          ListPipeController().processMLScoreSingle(
            {
              jobId: profile.id,
              candoIds: [match.id],
            },
            'get_candos_scores',
            (results) => {
              const thisJobResult = Obj(
                results.find(
                  (result) => result.item_id === match.id
                )
              );
              if (thisJobResult.match) {
                match.mlScore = thisJobResult.match.score;
                match.mlScoreObject = thisJobResult.match;
                this.setState({ match });
              }
            }
          );

        }
      },
      {
        acl: !!_engagementId,
        label: 'Update Engagement',
        onClick: this.handlerUpdateEngagement
      },
      // MATCH DECISION DETAILS
      {
        acl: Core.isAdmin() && !!_engagementId,
        label: MATCH_DECISION_DETAILS__CONFIG.dialog.title,
        onClick: () => this.matchDecisionDetails.open()
      },
      {
        acl: _isAdminAndHasCheckedOrEngagement && !!_engagementId,
        label: 'Mark ML Positive',
        onClick: (ev) => {
          updateEngagement(engagement, {
            useForMlTraining: "true",
          });
        }
      },
      {
        acl: _isAdminAndHasCheckedOrEngagement && !!_engagementId,
        label: 'Mark ML Negative',
        onClick: (ev) => {
          updateEngagement(engagement, {
            useForMlTraining: "false",
          });
        }
      },

      {
        title: 'Click to Star',
        className: 'star-menu-item',
        label: (
          <Checkbox
            className="star"
            checked={match.starred}
            onCheck={this.onCheckStar(match)}
            checkedIcon={<i className="material-icons">star</i>}
            uncheckedIcon={
              <i className="material-icons">star_border</i>
            }
          />
        )
      },
    ];

    return (
      <RefBox key={`single_candidate_card__${index}`}
        /**
         * @note
         * These are essential for recomputeRowHeights.
         * onLoad={measure} ref={registerChild} style={style}
         * Also this wrapper must be a forwardable element.
         */
        // ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼ ▼
        onLoad={measure}
        ref={registerChild}
        style={style}
        // ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲ ▲
        className='pb-05'
      >

        <Button role='GroupHeader' flat primary
          acl={
            YES(label) &&
            NOT(ListPipeController().state.annotatorMode)
          }
          label={label}
          className={
            joinClassName(
              [
                'mb-05 bg-purple-lighter',
                index ? 'mt-3' : 'mt-05'
              ]
            )
          }
          fullWidth
        />

        <Card
          onClick={this.handlerOnSelect}
          className='pointer'
          style={cardStyles}
        >
          <Box column w100 className='p-1'>
            <Box row w100 spaceBetween>
              <Box row w100>
                <Typography strong breakAll>
                  {match._name}
                </Typography>
              </Box>
              <Box row w100>
                <Switch small acl={NOT(matchLocation(/v3/i))}
                  checked={isFilterByRecruiter}
                  onChange={event => {
                    isFilterByRecruiter = event.target.checked;
                    if (isFilterByRecruiter) {
                      MatchPage__addKeyword(recruiter._name);
                    }
                    else {
                      MatchPage__removeKeyword(recruiter._name);
                    }
                  }}
                >
                  Recruiter filter
                </Switch>
                <CardCheckbox
                  entity={match}
                  handlerOnSelectBox={handlerOnSelectBox}
                  selectedCheck={
                    !!selectedMatches &&
                    selectedMatches.find((entity) => entity.id === match.id)
                  }
                />
              </Box>
            </Box>
            <Typography sub>
              {match._roles}
            </Typography>
          </Box>

          <Box className='px-1'>

            <Typography className='my-1 match-new job-card-text'>
              {recruiter._name}, {match._introduced}
            </Typography>

            <Box className='mt-1'>
              {!!match._visa &&
                this.props.staticRowDisplayChip(
                  match._visa,
                  profile._visaTransfer,
                  this.props
                    .staticRowDisplayColor(match, profile)
                    .getVisaColor()
                )}
              {!!segregateRolesChip && segregateRolesChip(match, profile)}
              {!!match.id && typeof match._yearsOfExperienceForCalc !== "undefined"
                ? this.props.staticRowDisplayChip(
                  match._yearsOfExperienceForCalc + " yrs",
                  profile.minYearsOfExperience,
                  this.props
                    .staticRowDisplayColor(match, profile)
                    .getYearsXp()
                )
                : null}

              <MatchLocationChips
                requester={MATCH_LOCATION_CHIPS__CD_REQUESTER}
                job={profile}
                candidate={match}
              />

              {!!profile.id &&
                typeof profile.minimumSalary !== "undefined" &&
                this.props.staticRowDisplayChip(
                  "<=$" + profile.minimumSalary,
                  match.salaryMax,
                  this.props
                    .staticRowDisplayColor(profile, match)
                    .getSalaryColor()
                )}
              {!!profile.id &&
                !!profile.platformRating &&
                this.props.staticRowDisplayChip(
                  profile._platformRating,
                  null,
                  null
                )}
            </Box>


            <Box
              acl={match._technicalSkills}
              className='mt-1'
            >
              Tech Skills: {mapCandidateTechSkills(match)}
            </Box>

            <Box
              acl={!!match.id && !!profile.id}
              className={joinClassName([
                'mt-1',
                (
                  ListPipeController().state.annotatorMode ||
                  !mlStatsJoined.length
                ) && 'd-none'
              ])}
            >
              <mark>{mlStatsJoined}</mark>
            </Box>

            <Box row w100 className='mt-1'>
              <ThumbsBar
                Controller={this}
                profile={profile}
                match={match}
              />
              <Switch small
                checked={reviewed}
                onChange={(event) => {
                  this.setState({ reviewed: event.target.checked });
                }}
                className='ml-auto mr-05'
              >
                Reviewed
              </Switch>
            </Box>

            <Box column w100 className='mt-1'>

              <Typography
                acl={
                  !!engagement.id &&
                  !ListPipeController().state.annotatorMode
                }
              >
                {[
                  engagement.state,
                  engagement.status,
                  engagement.stage,
                  engagement.rejectionReason,
                  engagement.rejectionReasonAdditionalInfo
                ].filter(v => !!v).join(' | ')}
              </Typography>

              {(!ListPipeController().state.annotatorMode
                ? (!MlDecisionPending
                  ? (
                    matchDecisions.map(
                      (decision, index) => (
                        <MatchDecisionMaker
                          key={`single_candidate_card__match_decision_maker__index_${index}`}
                          matchDecisionTableRecord={decision}
                          measure={measure}
                          index={index}
                        />
                      )
                    )
                  ) : (
                    <div className='my-1 match-new job-card-text'>
                      Loading decision...
                    </div>
                  )
                ) : null
              )}

              <Box row w100 className='mt-1'>

                {/** OWNERSHIP LABELS */}
                <Box row w100 wrap
                  acl={!!match.id}
                  className='mr-auto'
                  style={{ width: 240 }}
                >
                  {
                    getJobLabels({ job: profile, candidate: match }).map(
                      (candidate, index) => (
                        <Chip slim key={`single_candidate_card__labels__index_${index}`}
                          className='bg-red-darker c-white px-1 mr-05 mb-05 f-md'
                        >
                          {candidate}
                        </Chip>
                      )
                    )
                  }
                  {
                    profile.blacklisted
                  }
                  {
                    ownerShipChip
                  }
                </Box>

                {/** ACTIONS */}
                <Box row
                  acl={Core.isAdminOrTrusted()}
                  className='flex-align-right-bottom w-20'
                >
                  {Core.isAdmin({ action: ACCOUNT_ACTION__VIEW_ENGAGEMENT }) && engagement.id && (
                    <IconButton
                      title='View engagement'
                      onClick={(event) => Core.openPopUp(`/engagement/view/${engagement.id}`, 1600)}
                      icon='visibility'
                    />
                  )}
                  {Core.isAdmin({ action: ACCOUNT_ACTION__MATCH_CANDIDATE }) && (
                    <NavLink
                      to={`/candidate/match/${(match.id)}`}
                      icon='fact_check'
                      reload
                    />
                  )}
                  <Menu icon
                    name={`single_candidate_card__menu`}
                    options={_menuOptions}
                  />
                </Box>

              </Box>
            </Box>

          </Box>
        </Card>

        <CreateDisagreement Controller={this} />

        <ManageEngagement
          acl={YES(engagement.id)}
          engagement={engagement}
          open={wantsUpdateEngagement}
          closeParentPopup={this.closeParentPopup}
          afterUpdateEngagement={this.afterUpdateEngagement}
        />

        <MatchDecisionDetails
          acl={YES(engagement.id)}
          Controller={this}
        />

      </RefBox>
    );
  }
}

export default SingleCandidateCard;
