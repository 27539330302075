import * as Sentry from "@sentry/browser";
import {
  Integrations
} from "@sentry/tracing";
import {
  compile
} from 'handlebars';
import {
  isFunction
} from 'lodash';
import moment from "moment";
import {
  v4 as uuidv4
} from 'uuid';
import packageJson from "../../package.json";
import {
  hideAlert,
  showAlert
} from "../components/Dialogs/AlertDialog";
import {
  showConfirm
} from '../components/Dialogs/AppConfirmationDialog';
import {
  LoadingImage
} from '../components/Shared/Loader';
import revisionJson from "../revision.json";
import {
  Arr
} from './Array.lib';
import {
  TIMEZONE__LA
} from './Constants';
import {
  ACCOUNT_ACTION__LIST_CANDIDATES,
  ACCOUNT_ACTION__LIST_EMPLOYERS,
  ACCOUNT_ACTION__LIST_ENGAGEMENTS,
  ACCOUNT_ACTION__LIST_JOBS
} from './Definition';
import {
  copyString,
  stringToBoolean
} from "./GenericTools.lib";
import Http from "./Http";
import {
  Obj
} from './Object.lib';
import {
  ROLE__EMPLOYER_RECRUITER,
  ROLE__LIMITED_RECRUITER,
  ROLE__SYS_ADMIN,
  ROLE__TRUSTED_RECRUITER,
  login,
  logout
} from './services/Accounts/Session.lib';
import {
  COLLECTION__ACCOUNTS, readLoopbackRecord
} from './services/BE/loopback.api';
import calculateRank from './services/GitHub/calculateRank.lib';
import githubGraphqlGetUser from './services/GitHub/githubGraphqlGetUser';
import Store from "./Store";
import {
  Str,
  isNonEmptyString,
  trim
} from './String.lib';
import {
  TEMPLATE__EMAIL_SIGNATURE
} from './templates/Platform.template';
import {
  getHash,
  getLocation,
  getOrigin,
  reloadLocation,
  setLocation
} from './URL.lib';

const CONFIG__CORE = {
  sessionKey: 'ten_by_ten__session__user'
};

let key = 1;

const baseApiPath = process.env.REACT_APP_API_URL;
const basePath = process.env.REACT_APP_BASE_URL
const isLocalHost = stringToBoolean(process.env.REACT_APP_IS_LOCALHOST);
const isDevelopment = stringToBoolean(process.env.REACT_APP_IS_DEVELOPMENT);
const isProduction = stringToBoolean(process.env.REACT_APP_IS_PRODUCTION);
const isStaging = stringToBoolean(process.env.REACT_APP_IS_STAGING);
const Core = {
  Router: {},
  debug: key => Arr(Store.get('__debug')).includes(key),
  getBasePath: () => basePath,
  /** STORAGE & ENVIRONMENT */
  getApi: path => !!path ? `${baseApiPath}/api/${path}` : `${baseApiPath}/api`,
  getPath: path => `${basePath}/#/${trim(path).replace(/^\//, '')}`,
  getRedirectURI: ({ end, employerId }) => {
    switch (end) {
      case "be":
        return baseApiPath + "/api/Credentials/auth";
      case "fe/employer":
        return `${getOrigin()}/#/employer/settings/${employerId}`;
      case "fe":
      default:
        return `${getOrigin()}/#/admin/tools`;
    }
  },
  getUniqueId: num =>
    parseInt(
      num + "" + new Date().getTime() + "" + parseInt(Math.random() * 1000, 10),
      10
    ),
  getUserId: em => Core.getSession().userId,
  getAccessToken: em => Core.getSession().id,
  getUser: em => Obj(Core.getKeyValue(CONFIG__CORE.sessionKey)),
  getSession: em => Object(Store.get("session")),
  getSessionEmail: em => Store.get("email"),
  getUserName: em => {
    const user = Core.getUser();
    return `${user.firstName || "Anonymous"} ${user.lastName || "User"}`;
  },
  getUserRole: em => Core.getSession().role,
  getUserTimezone: em => moment.tz.guess() || TIMEZONE__LA,
  isLocalHost: em => isLocalHost,
  isDevelopment: em => isDevelopment,
  isProduction: em => isProduction,
  isStaging: em => isStaging,
  isOnDev: em => isDevelopment || isLocalHost,
  isAdmin: ({ action } = {}) => {
    let yes = Core.getUserRole() === ROLE__SYS_ADMIN;
    if (yes && isNonEmptyString(action) && !!Core.getUser().subroleId) {
      yes = Arr(Obj(Core.getUser().subrole).actionsList).includes(action)
    }
    return yes;
  },
  isEmployer: em => Core.getUserRole() === ROLE__EMPLOYER_RECRUITER,
  isAdminOrCoordinator: () => Core.isAdmin(),
  isTester: () => [
    'april@10by10.io',
    'bob@10by10.io',
    'sourcer@10by10.io',
    'silvamart@gmail.com',
    'mgodevs@gmail.com'
  ].includes(Core.getUser().email || '⸺'),
  isLimited: em => Core.getUserRole() === ROLE__LIMITED_RECRUITER,
  isTrusted: em => Core.getUserRole() === ROLE__TRUSTED_RECRUITER,
  getUIVersion: em => parseInt(Store.get('ui-version')) || DEFAULT_UI_VERSION,
  setUIVersion: num => Store.set('ui-version', num),
  /**
   * 
   * @param {Object} options
   * @param {string} options.to - '/jobs',
   * @param {boolean} options.refresh - if true will refresh the page after assign the new url
   * @param {object} options.history - route.component.props.history
   */
  go({ history, to = '/', refresh } = { to: '/' }) {
    to = Core.getLink(to);
    if (history) {
      history.push(to);
    }
    else {
      console.debug('history', history);
      setLocation(`/#${to}`)
    }
    refresh && reloadLocation();
  },
  goBack: ({ history } = {}) => {
    const prev = getLocation();
    if (
      !!prev.match(/candidate/i) &&
      (
        Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })
      )
    ) {
      Core.go({ to: '/candidates', history });
    }
    else if (
      !!prev.match(/job/i) &&
      (
        Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS })
      )
    ) {
      Core.go({ to: '/jobs', history });
    }
    else if (
      !!prev.match(/account/i) &&
      (
        Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_CANDIDATES })
      )
    ) {
      Core.go({ to: '/accounts', history });
    }
    else if (
      !!prev.match(/employer/i) &&
      (
        Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_EMPLOYERS })
      )
    ) {
      Core.go({ to: '/employers', history });
    }
    else if (
      !!prev.match(/engagement/i) &&
      (
        Core.isAdmin() ||
        Core.isAdmin({ action: ACCOUNT_ACTION__LIST_ENGAGEMENTS })
      )
    ) {
      Core.go({ to: '/engagements', history });
    }
    else {
      setLocation();
    }
  },
  open(to) {
    to = Core.getLink(to);
    window.open(`#${to}`);
  },
  openExact(to) {
    window.open(`#${to}`);
  },
  /**
   * 
   * @param {string} to  [value:'/path']
   * @returns {string} to  [value:'/path'|`/v3/path`]
   */
  getLink(to) {
    to = trim(to).replace(/v3\//i, '').replace(/^\//, '');
    if ((Core.getUIVersion() === 3) && !(Str(to).match(/pro\//i))) {
      to = `/v3/${to}`;
    }
    else {
      to = `/${to}`;
    }
    return to;
  },
  isRecruiter: em => Core.isTrusted() || Core.isLimited(),
  isAdminOrTrusted: em => Core.isAdminOrCoordinator() || Core.isTrusted(),
  isAdminOnDev: em => Core.isAdmin() && Core.isOnDev(),
  isAdminOrRecruiterOnDev: em =>
    Core.isAdminOrCoordinator() || (Core.isRecruiter() && Core.isOnDev()),
  isNotAdminOrRecruiterOnDev: em => !Core.isAdminOrRecruiterOnDev(),
  isAdminOnLocal: em => Core.isAdmin() && Core.isLocalHost(),
  isRecruiterOnDev: em => Core.isRecruiter() && Core.isOnDev(),
  osWindows: em => /windows/i.test(window.navigator.userAgent),
  isDuc: em => /duc\+recruiter@10by10.io/i.test(Core.getSessionEmail()),
  isTeam: em => /recruiter@10by10.io/i.test(Core.getSessionEmail()),
  isLogged: em => !!Core.getAccessToken(),
  isLoggedOut: em => !Core.isLogged(),
  isPath: search => new RegExp(search, "i").test(getHash()),
  failure({
    title,
    message,
    exception,
    error,
    source,
    params,
    options,
    omitSentToSentry = false,
    omitUIMessage = false,
  }) {
    if (exception === true || message === true) { return Core.hideMessage(); }
    const ticketId = uuidv4();
    const timeStamp = moment().toISOString();
    const user = Core.getUser().email;
    const url = getLocation();
    let ticket = (`
      ${source} | ${exception} | ${user} | ${url}\n${(
        JSON.stringify({
          ticketId,
          timeStamp,
          user,
          url,
          title,
          message,
          exception,
          error,
          source,
          params,
        }, null, 2)
      )}
    `).trim();
    console.debug('<');
    console.debug('FAILURE');
    console.debug(ticket);
    console.debug('omitSentToSentry', omitSentToSentry);
    console.debug('omitUIMessage', omitUIMessage);
    console.debug('>');
    let messageTitle = '';
    let messageText = '';
    let messageTicket = false;
    if (exception === 'Network Failure') {
      messageTitle = exception;
      messageText = 'Try in a few minutes, if the issue persists contact to the support team';
    }
    else {
      if (!omitSentToSentry) {
        Sentry.withScope(scope => {
          const ticketObject = {
            ticketId,
            timeStamp,
            user,
            url,
            title,
            message,
            exception,
            source,
            params,
          };
          Object.keys(ticketObject).forEach(
            key => {
              if (ticketObject[key] !== undefined) {
                let value = ticketObject[key];
                if (Object(value) === value) { value = JSON.stringify(value, null, 2).trim() }
                scope.setExtra(key, value);
              }
            });
          Sentry.captureMessage(`${timeStamp} | ${source} | ${exception} | ${user} | ${url} | ${ticketId}`, options || 'error');
        });
      }
      messageTitle = 'Sorry something went wrong';
      messageText = 'Send following code as text to the support team to track this issue';
      messageTicket = true;
    }
    if (!omitUIMessage) {
      showAlert({
        severity: 'error',
        title: title ? title : (
          <div className="text-capitalize">{messageTitle}</div>
        ),
        message: (message ? message : (
          <div className='flex-column'>
            <i>{messageText}</i>
            {messageTicket && (
              <span
                className="text-mono pointer"
                onClick={event => copyString(`${ticketId} | ${timeStamp}`)}
              >
                {ticketId}<br />{timeStamp}
              </span>
            )}
          </div>
        ))
      });
    }
  },
  getEnvironment: em =>
    `${Core.isProduction() ? "domain" : Core.isStaging() ? "staging" : Core.isDevelopment() ? "d101010" : "local"
    }${process.env.REACT_APP_ENV ? "/" + process.env.REACT_APP_ENV : ""}`,
  getFrontendVersion: () => {
    return `${packageJson.version}-${revisionJson.revision || 'local'}`;
  },
  info: (props) => console.debug({ [Symbol.toStringTag]: "Info", ...props }),
  getVersion: async () => {
    const env = Core.getEnvironment();
    const fullVersionRevision = Core.getFrontendVersion();

    Store.set("FE", fullVersionRevision);
    if (!Core.isLocalHost()) {
      Sentry.init({

        dsn: "https://3959265fb5424436a9706084cef96dc2@sentry.io/1229219",

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: fullVersionRevision,
        integrations: [new Integrations.BrowserTracing()],

        environment: process.env.REACT_APP_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: Core.isProduction() ? 0.2 : 1.0,

      });
    }
    function info() {
      Core.info({
        'server/environment': env,
        fe: Store.get("FE"),
        be: Store.get("BE"),
        session: Core.isOnDev() && Core.getSession(),
        isLocalhost: Core.isLocalHost(),
        isDevelopment: Core.isDevelopment(),
        isProduction: Core.isProduction(),
        isOnDev: Core.isOnDev(),
        isAdmin: Core.isAdmin(),
        isLimited: Core.isLimited(),
        isTrusted: Core.isTrusted()
      });
    }
    let version = await Http.get(Core.getApi("Accounts/version"))
      .catch(error => {
        info();
        return `${env} FE@${fullVersionRevision}`;
      })
      .then(
        apiVersion => {
          Store.set('BE', apiVersion);
          info();
          return `${env} FE@${fullVersionRevision} BE@${apiVersion}`;
        }
      );
    if (Core.isLogged()) {
      Core.setKeyValue(CONFIG__CORE.sessionKey,
        Obj(await readLoopbackRecord({
          collection: COLLECTION__ACCOUNTS,
          where: { id: Core.getUserId() },
          include: ['subrole'],
          limit: 1
        }).catch(Core.showError))
      );
    }
    return version;
  },
  getEnvValue: key => process.env[`REACT_APP_${key}`] || `(${key})`,
  getEmailSignature: em => compile(TEMPLATE__EMAIL_SIGNATURE)({
    TEAM_NAME: Core.getEnvValue("TEAM_NAME"),
    TEAM_PHONE: Core.getEnvValue("TEAM_PHONE"),
    TEAM_EMAIL: Core.getEnvValue("TEAM_EMAIL"),
    TEAM_CALENDAR: Core.getEnvValue("TEAM_CALENDAR"),
    TEAM_SLOGAN: Core.getEnvValue("TEAM_SLOGAN")
  }),
  getResumeSubmissionFromEmail: em => {
    return process.env.REACT_APP_RESUME_SUBMISSION_FROM_EMAIL;
  },
  getResumeSubmissionBcc: em => {
    return process.env.REACT_APP_RESUME_SUBMISSION_BCC;
  },
  getNewCandidateReceiverNotiFromEmail: em => {
    return process.env.REACT_APP_NEW_CANDIDATE_RECEIVER_NOTI_FROM_EMAIL;
  },

  getDraftCandidateReceiverNotiToEmail: em => {
    return process.env.REACT_APP_DRAFT_CANDIDATE_RECEIVER_NOTI_TO_EMAIL;
  },
  getNewCandidateReceiverNotiToEmail: em => {
    return process.env.REACT_APP_NEW_CANDIDATE_RECEIVER_NOTI_TO_EMAIL;
  },
  getResumeS3Storage: em => {
    return process.env.REACT_APP_RESUME_S3_BUCKET;
  },
  getCalendarCoordinatorPhone: em => {
    return process.env.REACT_APP_CALENDAR_COORDINATOR_PHONE;
  },
  log: Function.prototype.bind.call(console.log, console),
  /** AUTH */
  logout,
  login,
  root: em => (setLocation("/")),

  // this is overwritten by the app controller
  reload: em => reloadLocation(),

  refresh: em => reloadLocation(),
  forgot: async (inputs, success, failure) => {
    return Http.post(Core.getApi("Accounts/reset"), inputs, success, failure);
  },
  resetPassword: async (inputs, success, failure) => {
    return Http.post(Core.getApi("Accounts/reset-password"), inputs, success, failure);
  },
  /** SHARED */
  getKey: () => key++,
  getFromNow: stringDate => {
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago"
      }
    });
    return moment(stringDate).fromNow();
  },
  /**
   * 
   * @param {string} url required
   * @param {number} width optional
   * @param {number} height optional
   * @sample Core.openPopUp(Core.getPath(`employer/edit/${this.state.id}`), 1600);
   */
  openPopUp: (url = '/', width = 1200, height = 960) => {
    url = trim(url);
    if (!!url.match(/^\//)) {
      url = `/#${Core.getLink(url).replace('/#', '')}`;
    }
    const leftPosition = window.screen.width - width;
    const topPosition = 0;
    const newWindow = window.open(
      url,
      `new_window_${Core.getKey()}`,
      `width=${width},height=${height},top=${topPosition},left=${leftPosition}`
    );
    newWindow.resizeTo(width, height);
    newWindow.moveTo(leftPosition, topPosition);
  },
  openPopUpFullSize: (url) => {
    const newWindow = window.open(
      url,
      "newWindow",
      `width=${window.screen.width},height=${window.screen.height},top=0,left=0`
    );
    newWindow.resizeTo(window.screen.width, window.screen.height);
    newWindow.moveTo(0, 0);
  },
  openSameWindow: url => {
    window.open(url, "_self");
  },
  showLoader(message, onClose) { return showAlert({ message, onClose, icon: LoadingImage }); },
  showMessage(message, onClose) { return showAlert({ message, onClose }); },
  hideMessage() { return hideAlert(); },
  showSuccess(message, onClose) { return showAlert({ message, onClose, severity: 'success', title: false }); },
  showWarning(message, onClose) { return showAlert({ message, severity: 'warning', title: false, onClose }); },

  /**
   * 
   * @param {any} message  [ string |JSX.Element ]
   * @param {any} options  [ function | { error: any, onClose: function } ]
   * @returns 
   */
  showError(message, options) {
    let { error, onClose } = Obj(options);
    if (isFunction(options)) {
      onClose = options;
    }
    else if (error) {
      console.error(error);
    }
    return showAlert({ message, severity: 'error', title: false, onClose });
  },

  showConfirm,
  /** REMOTE STORAGE start block >>> */
  /** */
  uploadFile: (container, file, filename, success, failure) => {
    container = `${Core.getUserId()}_${container ||
      "new" + new Date().toISOString().replace(/-|:|\./g, "")}`;
    container = Core.getResumeS3Storage() + "%2F" + container;
    if (file instanceof File) {
      uploadFile(file, filename);
    } else {
      failure("Invalid file to upload");
    }
    function uploadFile(_file, _filename) {
      Core.log(_file);
      const data = new FormData();
      data.append("file", _file, _filename);
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            if (failure instanceof Function) {
              try {
                const result = JSON.parse(this.responseText).result.files
                  .file[0];

                const resumeUrl = `${Core.getApi(
                  "ImportContainer"
                )}/${container}/download/${result.name}`;

                Core.log(result);
                success(resumeUrl);
              } catch (ex) {
                failure("unexpected error");
                Core.failure({
                  source: 'Core.js(lib)>uploadFile:catch',
                  exception: ex,
                  params: {
                    container,
                    file,
                    filename,
                  }
                });
              }
            }
          } else {
            if (failure instanceof Function) {
              try {
                failure(JSON.parse(this.responseText).error.message);
              } catch (ex) {
                failure("unexpected error");
              }
              Core.failure({
                source: 'Core.js(lib)>uploadFile:!ok',
                exception: this.responseText,
                params: {
                  container,
                  file,
                  filename,
                }
              });
            }
          }
        }
      });
      xhr.open(
        "POST",
        `${Core.getApi("ImportContainer")}/${container}/upload/`
      );
      const token = Core.getAccessToken();
      if (token) {
        xhr.setRequestHeader("Authorization", token);
      }
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send(data);
    }
  },
  deleteFile: async (container, file, success) => {
    return Http.delete(
      Core.getApi(`ImportContainer/${container}/files/${file}`),
      success
    );
  },
  renameS3Resume: (bucketname, oldkey, newkey, success, failure) => {
    Http.post(
      Core.getApi("Importcontainer/rename"),
      {
        bucketname,
        oldkey,
        newkey
      },
      success,
      failure
    );
  },
  /** <<< end block */
  /**
   * Core.setKeyValue
   *
   * Sets to memory a pair of (key:value) to be used on post-routines.
   *
   * This is util to pass values thru controllers without re-render
   * and/or to avoid miss values on re-render inside same controller
   *
   * @sample `Core.setKeyValue('my-key-name', <any>);`
   * @note ***Be sure your key is unique across all routine proceses***
   * @see Core.getKeyValue;
   */
  setKeyValue(key = '', value) {
    GLOBAL_KEY_VALUE_PAIRS[key] = value;
    if (Core.isLocalHost()) {
      window.µ = window.µ || {};
      window.µ[key] = value;
    }
    return Core.getKeyValue(key);
  },
  /**
   * Core.getKeyValue
   *
   * Returns value related to a key, previously set by Core.setKeyValue.
   *
   * @sample `let myVar = Core.getKeyValue('my-key-name');`
   * @see Core.setKeyValue;
   */
  getKeyValue(key = '') {
    return GLOBAL_KEY_VALUE_PAIRS[key];
  },
  /**
   * Returns true if value is any combination of 10x10 name
   * 
   * @param {string} value 
   * @returns {boolean}
   */
  isTenByTenAlias(value) {
    return !!Str(value).match(/(ten|10)\s*(x|by)\s*(ten|10)/i)
  }
};
/**
 * Cache to be used by Core.setKeyValue & Core.getKeyValue
 *
 * @see Core.setKeyValue;
 * @see Core.getKeyValue;
 */
const GLOBAL_KEY_VALUE_PAIRS = {};
window.GLOBAL_KEY_VALUE_PAIRS = GLOBAL_KEY_VALUE_PAIRS;

window.moment = moment;
window.githubGraphqlGetUser = async username => {
  let gitHubStats = await githubGraphqlGetUser({ username }) || {};
  delete gitHubStats.singleRowHtml;
  delete gitHubStats.submissionNotesHtml;
  console.debug(JSON.stringify(gitHubStats, null, 2));
};
window.calculateRank = props => { return calculateRank(props); };
window.Core = Core;

export const DEFAULT_UI_VERSION = 1;
export const LATEST_UI_VERSION = 3;

export default Core;

