import {
  MDASH
} from '../../../lib/Constants';
import JobFeedbacks, {
  evaluateEmployerFeedback
} from '../../Jobs/Edit/Forms/JobFeedbacks';
import Card from '../../Layout/Wrappers/Card';
import Divider from '../../Layout/Wrappers/Divider';
import ExpandableCard from '../../Layout/Wrappers/ExpandableCard';
import {
  Html
} from '../../Layout/Wrappers/Html';
import Typography from '../../Layout/Wrappers/Typography';

export default function RequirementsSection3({
  job = {}
}) {
  const hasEmployerFeedbacks = evaluateEmployerFeedback({ job });
  return (
    job.id &&
    (
      !job.qualificationNotes ||
      hasEmployerFeedbacks ||
      !!job.publicNotes ||
      !!job.privateNotes ||
      !!job.jobDescription
    ) &&
    <>

      {!!job.qualificationNotes &&
        <Card
          role='QualificationNotesCard'
          className='mb-05'
        >
          <Typography alignCenter
            className='m-1'
          >
            <Typography strong blackDark large>
              INSIDER SCOOP
            </Typography>
            <Typography className='mx-1'>
              {MDASH}
            </Typography>
            <Typography>qualificationNotes</Typography>
          </Typography>
          <Divider />
          <Html
            value={job.qualificationNotes}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Card>
      }

      {hasEmployerFeedbacks &&
        <ExpandableCard
          role='RequirementsSection3__JobFeedbacksCard'
          cardTitle={
            <Typography strong large alignCenter blackDark>
              RESUME FEEDBACK
            </Typography>
          }
          cardTitleOnOpen={
            <Typography strong large alignCenter blackDark>
              {MDASH}
            </Typography>
          }
          className='p-0 mb-05'
        >
          <JobFeedbacks readOnly
            job={job}
            className='border-none'
          />
        </ExpandableCard>
      }

      {!!job && !!job.publicNotes && (
        <Card
          role='PublicNotesCard'
          className='mb-05'
        >
          <Typography strong large alignCenter blackDark
            className='m-1'
          >
            PUBLIC NOTES
          </Typography>
          <Divider />
          <Html
            value={job.publicNotes}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </Card>
      )}

      {!!job && !!job.privateNotes && (
        <ExpandableCard
          role='PrivateNotesCard'
          cardTitle={
            <Typography strong large alignCenter blackDark>
              PRIVATE NOTES
            </Typography>
          }
          className='mb-05'
        >
          <Html
            value={job.privateNotes}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </ExpandableCard>
      )}

      {!!job && !!job.jobDescription && (
        <ExpandableCard
          role='JobDescriptionCard'
          cardTitle={
            <Typography strong large alignCenter blackDark>
              JOB DESCRIPTION
            </Typography>
          }
          className='mb-05'
        >
          <Html
            value={job.jobDescription}
            className='w-unset m-1 f-md fw-400 c-black-dark'
          />
        </ExpandableCard>
      )}

    </>
  );
}
