/** ============================================ µ
 * @Description Greenhouse Ingestion Library [JS]
 * @CreatedAt   2021-03-22
 * @ReviewedAt  2021-05-12
/** =========================================== */

/* IMPORTS ==================================== */

import Greenhouse from './Greenhouse.lib';
import Core from "../../Core.js";
import tools from "../../GenericTools.lib";

/* METHODS ==================================== */

/**
 * Maps a Candidate model of 10x10
 * into a Candidate input for Greenhouse harvest
 * 
 * @param {*} candidate Cando object based on 10x10 model
 * @param {integer} atsJobId The ID of the job you want to create an application to for this candidate
 * @param {*} submissionNote Object with submission notes
 * @param {string} submissionNote.public Public notes
 * @returns {*} Object with results of mapping
 * @returns {*} results.candidate - Cando object based on GH post Input
 * @returns {[string]} results.failures - Array with texts of failures on mapping
 */
function mapCando10ToCandoGH({ candidate, atsJobId, submissionNotes }) {
  const {
    id: external_id,
    firstName: first_name,
    lastName: last_name,
    currentEmployer: company,
    currentTitle: title,
    phone: phone_number,
    email,
    gitHubURL,
    linkedInURL,
    resumes = [],
  } = candidate;
  const {
    public: notes
  } = submissionNotes
  const results = {
    candidate: {
      external_id,
      first_name,
      last_name,
      company,
      title,
      notes,
      phone_numbers: [],
      emails: [],
      websites: [],
      job_id: null,
      prospect: false,
    },
    failures: []
  };

  /** 
   * µ NOTE be careful 
   * 
   * following conditionals are not equals than harvest library
   */

  if (phone_number) {
    if (tools.validatePhone(phone_number)) {
      results.candidate.phone_numbers.push({
        phone_number, type: "home"
      });
    }
    else {
      results.failures.push(`Phone number must have at least 10 digits`);
      results.failures.push(`"${phone_number}"`);
    }
  }

  if (email) {
    if (tools.validateEmail(email)) {
      results.candidate.emails.push({
        email, type: "personal"
      });
    }
    else {
      results.failures.push(`Email is badly formatted.`);
      results.failures.push(`"${email}"`);
    }
  }

  if (gitHubURL) {
    if (tools.validateURL(gitHubURL)) {
      results.candidate.websites.push({
        url: gitHubURL, type: "personal"
      });
    }
    else {
      results.failures.push(`Website is badly formatted.`);
      results.failures.push(`"${gitHubURL}"`);
    }
  }

  if (linkedInURL) {
    if (tools.validateURL(linkedInURL)) {
      results.candidate.websites.push({
        url: linkedInURL, type: "personal"
      });
    }
    else {
      results.failures.push(`Website is badly formatted.`);
      results.failures.push(`"${linkedInURL}"`);
    }
  }

  if (!!resumes.length) {
    const attachmentTypes = ["resume", "cover_letter", "admin_only"];
    resumes.find(resume => {
      const filename = tools.getFilenameFromURL(resume.url);
      if (!!filename && !!attachmentTypes.length) {
        /** 
         * µ LOCALHOST PATCH
         * Greenhouse is no able 
         * to get the resume file 
         * from a localhost url,
         * for that reason we are using
         * a public domain test resume url 
         */
        results.candidate.resume = (
          Core.isLocalHost()
            ? Greenhouse.TEST_RESUME_URL
            : resume.url
        );
      }
      return true;
    });
  }

  /** µ SAMPLES of resume vs resumes * /
    // Greenhouse
    "resume": "https://prod-heroku.s3.amazonaws.com/..."
    // 10by10
    resumes:[
      {
        "url": "https://<10by10API>/.../download/MAR...537.pdf",
        "createdAt": "2021-02-16T18:35:38.929Z",
        "timestamp": 1613500538929
      }
    ]
  /** */

  /** µ FOR DEBUG PURPOSES */
  if (Core.isLocalHost()) {
    results.candidate.first_name = '_' + results.candidate.first_name;
    results.candidate.last_name = '_' + results.candidate.last_name;
  }
  /** */

  return results;
}

/**
 * Call POST a Candidate into Greenhouse
 * 
 * @param {*} candidate Cando object based on 10x10 model
 * @param {string} atsApiKey The credential to use basic Auth
 * @param {string} atsOnBehalfOf ID of the user issuing this request. Required for auditing purposes.
 * @param {integer} atsJobId The ID of the job you want to create an application to for this candidate
 * @param {*} submissionNote Object with submission notes
 * @param {string} submissionNote.public Public notes
 * @param {function} onSuccess Function to execute on success
 * @param {function} onFailure Function to execute on failure
 */
function addCandidate({
  candidate,
  atsApiKey,
  atsOnBehalfOf: onBehalfOf,
  atsJobId,
  submissionNotes = { public: '' },
  onSuccess,
  onFailure
}) {
  const mappingCandidateResults = mapCando10ToCandoGH({
    candidate,
    atsJobId,
    submissionNotes
  });
  const {
    candidate: payload,
    failures
  } = mappingCandidateResults;
  const options = {
    url: Core.getApi('Candidates/greenhouse/ingestion'),
    method: 'POST',
    body: {
      path: "/candidates/",
      /** 
       * µ TO REVIEW 2021-03-26
       * following value will be encrypted 
       * from BE side, continue on developing.
       */
      auth: tools.encodeBase64(`${atsApiKey}:`),
      onBehalfOf,
      payload,
    }
  };

  /** µ FOR TEST PURPOSES un-existing endpoint * /
  options.url = Core.getApi('undefined');
  /** */

  /** µ FOR TEST PURPOSES error on payload * /
  options.body.payload = {};
  /** */

  /** µ FOR TEST PURPOSES error on payload * /
  options.body.payload.emails[0].email = 'https://linkedin/in/x';
  /** */

  /** µ FOR DEBUG PURPOSES * /
  console.debug('µ:Greenhouse+ingestion::addCandidate', options);
  // return;
  /** */

  Greenhouse.request({ options, failures, onSuccess, onFailure });

}

/* DICTIONARIES =============================== */

const GreenhouseIngestion = {
  addCandidate
};

/* EXPORTS ==================================== */

export {
  GreenhouseIngestion as default,
  GreenhouseIngestion as Ingestion,
  addCandidate
};

/* NOTES ====================================== */

/** µ SAMPLE import and use * /

import Greenhouse from "./lib/services/ATS/Greenhouse.lib.js";
import * as Greenhouse from "./lib/services/ATS/Greenhouse.lib.js";
import { Greenhouse } from "./lib/services/ATS/Greenhouse.lib.js";

Greenhouse.ingestion.addCandidate(...);

/** */

/** µ SAMPLE addCandidate post body * /
{
  "path": "/candidates/",
  "payload": {
    "first_name": "_cando01",
    "last_name": "_lastname_test",
    "company": "10x10",
    "title": "Software Engineer",
    "phone_numbers": [
      {
        "phone_number": "+1 (000) 000-0000",
        "type": "home"
      }
    ],
    "emails": [
      {
        "email": "_cando01@mail.com",
        "type": "personal"
      }
    ],
    "websites": [
      {
        "url": "https://www.linkedin.com/in/cando01/",
        "type": "personal"
      }
    ],
    "job_id": "400523600",
    "external_id": "602c122022000425040208",
    "notes": "public submission 01\npublic submission note 02",
    "prospect": false,
    "resume": "https://d101010-api.go10x10.com/api/.../download/_CANDO01_10210317_164140.pdf"
  }
}
/** */

/** µ SAMPLE addCandidate response * /
  {
    "id": 427337200,
    "application_id": 427678800,
    "external_id": "602c122022000425040208",
    "profile_url": "https://app4.greenhouse.io/people/4273372004?application_id=427678800"
  }
/** */
