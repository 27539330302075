import {
  isObject
} from 'lodash';
import {
  sanitizeArr
} from '../Array.lib';
import {
  trim
} from '../String.lib';

export const KEY__MODEL__NAME = '___model___';

export function getEntities(source) {
  if (isObject(source)) { source = Object.values(source); }
  source = sanitizeArr(source);
  const entities = {}
  source.forEach((entity) => {
    if (entity[KEY__MODEL__NAME]) {
      Object.assign(entities, {
        [trim(entity[KEY__MODEL__NAME]).toLowerCase()]: entity
      });
    }
  });
  // console.debug('getEntities', entities);
  return entities;
}

export function getStateModel(state, model) {
  const newModel = {};
  Object.keys(model).forEach(key => {
    if (state[key] === "") {
      // newModel[key] = undefined; // this was to prevent save empty values
      newModel[key] = null; // by the moment we need to save null values in db.
      // TODO improve the updates logic.
    } else if (state[key] === "<p><br></p>" || state[key] === "null") {
      newModel[key] = null;
    } else if (
      typeof state[key] === "boolean" ||
      state[key] === 0 ||
      key === "visaTransfer" ||
      key === "holdDate" ||
      !!state[key]
    ) {
      newModel[key] =
        typeof state[key] === "string" ? state[key].trim() : state[key];
    }
  });
  return newModel;
}

export function snapshotState({ state, model }) {
  return JSON.stringify(getStateModel(state, model));
}

export function isEngagement(entity) {
  return (entity[KEY__MODEL__NAME] === 'Engagement')
}
