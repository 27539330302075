import {
  Input,
  Select
} from "antd";
import {
  Component,
  Fragment
} from "react";
import {
  Arr
} from '../../lib/Array.lib';
import {
  TEN_BY_TEN_ADMIN_CONTACT
} from "../../lib/Constants";
import Core from "../../lib/Core";
import {
  validateEmail,
  YES
} from '../../lib/GenericTools.lib';
import {
  escapeHtml
} from '../../lib/HTML.lib';
import {
  combineEmailsLists,
  mapContactsToStrings
} from "../../lib/services/Email/Email.lib";
import Streak from '../../lib/Streak';
import {
  joinKeyName
} from '../../lib/String.lib';
import cleanHtml from '../../lib/tools/cleanHtml';
import copyHtml from '../../lib/tools/copyHtml';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import {
  ErrorMessage,
  LoadingMessage
} from '../Layout/Wrappers/Message';
import MultipleSelect from "../Layout/Wrappers/MultipleSelect";
import RichTextBox from "../Layout/Wrappers/RichTextBox";
import {
  PLACEMENT__LEFT_START
} from '../Layout/Wrappers/StyledTooltip';
import TextField from '../Layout/Wrappers/TextField';

const { Option } = Select;

export const EMAIL_DIALOG_PANEL__PREVIEW = 'Preview';
export const EMAIL_DIALOG_PANEL__EDIT = 'Edit';
export const EMAIL_DIALOG_PANEL__COPY = 'Copy';

/**
 * @EmailObject {{
 *    accountType:{string},
 *    name:{string},
 *    email:{string}
 * }}
 * @property {EmailObject[]} emails
 * @property {EmailObject[]} to
 * @property {EmailObject[]} cc
 * @property {EmailObject[]} bcc
 * @property {string} subject
 * @property {string} body
 * @property {object[]} snippets  [array:SnippetStreakObject]
 * @property {boolean} loadSnippets  [default:false]
 */
export default class EmailPreview extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      emails: (this.props.emails || []).filter(contact => validateEmail(contact?.email)),
      toInput: "",
      ccInput: "",
      selectedTo: (this.props.to && this.props.to[0]) || "",
      selectedCc: (this.props.cc && this.props.cc[0]) || "",
      from: this.props.from || Core.getNewCandidateReceiverNotiFromEmail(),
      to: (this.props.to || []).filter(contact => validateEmail(contact?.email)).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      cc: (this.props.cc || []).filter(contact => validateEmail(contact?.email)).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      bcc: (this.props.bcc || []).filter(contact => validateEmail(contact?.email)).map(
        item => (item.name ? `${item.name} <${item.email}>` : item.email)
      ),
      subject: this.props.subject,
      greetings: this.props.greetings,
      body: this.props.body,
      snippets: (
        (this.props.snippets || [])
          .sort((a, b) => String(a.snippetName).toLowerCase().localeCompare(String(b.snippetName).toLowerCase()))
          // sanitizing html for body
          .map(snippet => { snippet.snippetText = cleanHtml(snippet.snippetText); return snippet; })
      ),
      selectedSnippet: '',
      selectedPanel: this.props.panel || EMAIL_DIALOG_PANEL__EDIT
    };
  }
  componentDidMount = () => {
    this.setState({ loadingSnippets: true }, () => this.setSnippets());
  }
  setSnippets = async () => {
    try {
      const { snippets, loadSnippets = false } = this.props;
      let _snippets = Arr(snippets);
      if (!_snippets.length && loadSnippets) {
        _snippets = await Streak.getSnippets();
      }
      if (this.props.filterSnippets) {
        _snippets = _snippets.filter(
          (snippet) => YES(
            snippet.snippetName.match(
              new RegExp(this.props.filterSnippets, 'i')
            )
          )
        );
      }
      this.setState({
        snippets: (
          _snippets
            .sort((a, b) => String(a.snippetName).toLowerCase().localeCompare(String(b.snippetName).toLowerCase()))
            .map(snippet => { snippet.snippetText = cleanHtml(snippet.snippetText); return snippet; })
        ),
        loadingSnippets: false
      }, () => this.setDefaultSnippet());
    }
    catch (error) {
      Core.showError(`Snippets: ${error}`);
    }
  }
  setDefaultSnippet = () => {
    setTimeout(() => {
      if (!!this.state.snippets.length && this.props.defaultSnippet && !this.state.selectedSnippet) {
        let defaultSnippet = this.state.snippets.find(snippet => !!snippet.snippetName.match(new RegExp(this.props.defaultSnippet.snippetName, 'i')));
        if (defaultSnippet) {
          this.setSnippet(defaultSnippet.key);
        }
        else {
          this.setState({ showDefaultSnippetError: true });
        }
      }
    });
  }
  setTo = (ev, index, to) =>
    this.setState(state => {
      state.to.push(to);
      return state;
    });
  setCc = (ev, index, cc) =>
    this.setState(state => {
      state.cc.push(cc);
      return state;
    });
  setSnippet = value => {
    const snippet = this.state.snippets.find(snippet => snippet.key === value);
    this.setState({ selectedSnippet: '' });
    setTimeout(() => {
      this.setState(
        state => {
          state.selectedSnippet = value;
          state.subject = `${state.subject ? state.subject : ''}${snippet.subject ? ` ${snippet.subject}` : ''}`;
          let _body = cleanHtml(state.body ? state.body : '');
          let _snippetText = cleanHtml(snippet.snippetText ? snippet.snippetText : '');
          state.body = cleanHtml(`
            <div>
              <div>
                ${_body}
              <div>
              </div>
                ${_snippetText}
              </div>
            </div>
          `);
          return state;
        }
      );
    });
  };
  setTemplate = async ({ subject, body }) => {
    return new Promise(resolve => {
      this.setState(
        state => {
          state.subject = subject;
          state.body = body;
          return state;
        },
        resolve
      );
    });
  }
  onChangeSubject = ev => this.setState({ subject: ev.target.value });
  onChangeBody = body => this.setState({ body });
  getParams = ev => ({
    from: this.props.from || Core.getResumeSubmissionFromEmail(),
    to: this.state.to.filter(v => !!v).join(","),
    cc: this.state.cc.filter(v => !!v).join(","),
    bcc: this.state.bcc.filter(v => !!v).join(","),
    subject: this.state.subject,
    greetings: this.state.greetings,
    html: this.state.body
  });
  setParams = (recipients, entity) => {
    const removeDuplicates = arr => [...new Set(arr)];
    this.setState(state => {
      let emails = combineEmailsLists(state.emails, recipients.emails);
      let update = {
        from: recipients.from,
        emails,
        to: removeDuplicates([...state.to, ...mapContactsToStrings(recipients.to)]),
        cc: removeDuplicates([...state.cc, ...mapContactsToStrings(recipients.cc)]),
        bcc: removeDuplicates([...state.bcc, ...mapContactsToStrings(recipients.bcc)]),
        subject: `${state.subject || ''} ${emails.filter(c => String(c.accountType).match(/candidate|employer/i)).map(c => c.name).join(', ')}`.trim(),
        body: `${state.body ? `${state.body}<br/>` : ''}${emails.filter(c => String(c.accountType).match(/employer|job/i)).map(c => c.name).join('<br/>')}`.trim()
      };
      return update;
    });
  }
  render() {
    const {
      emails = [],
      snippets = [],
      selectedSnippet
    } = this.state;
    const emailsOptions = emails.map(
      (
        {
          accountType = '',
          name = '',
          email = '',
        }
      ) => {
        const AccountType = (
          accountType
            ? (
              <strong>
                {`${accountType.trim()}:`}
              </strong>
            )
            : ''
        );
        const Name = (
          name
            ? ` ${name}`
            : ''
        );
        const Email = (
          email
            ? ` <${email.trim()}>`
            : ''
        );
        return (
          {
            label: (
              <small>
                {AccountType}
                {Name}
                {Email}
              </small>
            ),
            id: `${Name}${Email}`.trim()
          }
        )
      }
    );
    const _onUserEdit = ((this.props.onUserEdit instanceof Function)
      ? this.props.onUserEdit
      : () => null
    );

    const loadingSnippets = !!this.props.loadSnippets && !!this.state.loadingSnippets;

    return (
      <div className="ui-email-preview" style={{ overflow: "auto" }}>

        <div>

          {this.props.enablePanels && (
            <div className='d-flex flex-align-left-center mb-1'>
              <Button
                startIcon={
                  <i className='material-icons c-inherit'>visibility</i>
                }
                variant='contained'
                color={(this.state.selectedPanel === EMAIL_DIALOG_PANEL__PREVIEW) ? 'secondary' : 'inherit'}
                className={`tt-unset mr-1 min-w-160 nowrap`}
                onClick={event => {
                  this.setState({ selectedPanel: EMAIL_DIALOG_PANEL__PREVIEW });
                }}
              >
                Preview
              </Button>
              <Button
                startIcon={
                  <i className='material-icons c-inherit'>edit</i>
                }
                variant='contained'
                color={(this.state.selectedPanel === EMAIL_DIALOG_PANEL__EDIT) ? 'secondary' : 'inherit'}
                className={`tt-unset mr-1 min-w-160 nowrap`}
                onClick={event => {
                  this.setState({ selectedPanel: EMAIL_DIALOG_PANEL__EDIT });
                }}
              >
                Edit
              </Button>
              <Button
                startIcon={
                  <i className='material-icons c-inherit'>content_copy</i>
                }
                variant='contained'
                color={(this.state.selectedPanel === EMAIL_DIALOG_PANEL__COPY) ? 'secondary' : 'inherit'}
                className={`tt-unset mr-1 min-w-160 nowrap`}
                onClick={event => {
                  this.setState({ selectedPanel: EMAIL_DIALOG_PANEL__COPY });
                }}
              >
                Copy information
              </Button>
            </div>
          )}

          {(this.state.selectedPanel === EMAIL_DIALOG_PANEL__PREVIEW) ? (
            <>
              {!!String(this.props.previewDisclaimer).trim() && (
                <div className='align-left'>
                  {this.props.previewDisclaimer}
                </div>
              )}
              <div className='align-left bg-white rounded outlined px-2 py-1 f-md'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cleanHtml(`
                    <p><strong>From:</strong> ${escapeHtml(this.state.from || '')}</p>
                    <p><strong>To:</strong> ${escapeHtml((this.state.to || []).join(', ') || '')}</p>
                    ${!!this.state.cc?.length ? `<p><strong>Cc:</strong> ${escapeHtml((this.state.cc || []).join(', ') || '')}</p>` : ``}
                    <p><strong>Subject:</strong> ${escapeHtml((this.state.subject || '') || '')}</p>
                    <p><strong>Body:</strong> </p>
                    ${this.props.prefix || ''}
                    <hr />
                    ${this.props.greetings ? `<p>${this.props.greetings}</p>` : ``}
                    ${this.state.body || ''}
                  `)
                  }}
                  className='overflow-auto'
                  style={{ maxHeight: 'calc(60vh - 10rem)' }}
                />
              </div>
            </>
          ) : (this.state.selectedPanel === EMAIL_DIALOG_PANEL__COPY) ? (
            <div className='d-flex flex-align-left-top w-100'>
              <div className='mt-1'>
                <div>
                  <Button
                    title='Copy value'
                    startIcon={
                      <i className='material-icons c-inherit'>content_copy</i>
                    }
                    color={'primary'}
                    className={`tt-unset mr-1 min-w-120 flex-align-left-center`}
                    onClick={event => {
                      let value = escapeHtml((this.state.from || '') || '');
                      copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                    }}
                  >
                    10x10 email:&nbsp;
                    <span className='fw-400 c-black'>
                      {String(this.state.from || '')}
                    </span>
                  </Button>
                </div>
                <div>
                  <Button
                    title='Copy value'
                    startIcon={
                      <i className='material-icons c-inherit'>content_copy</i>
                    }
                    color={'primary'}
                    className={`tt-unset mr-1 min-w-120 flex-align-left-center`}
                    onClick={event => {
                      let value = escapeHtml((this.state.to || []).join(', ') || '');
                      copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                    }}
                  >
                    Candidate:&nbsp;
                    <span className='fw-400 c-black'>
                      {((this.state.to || []).join(', ') || '')}
                    </span>
                  </Button>
                </div>
                <div>
                  <Button
                    title='Copy value'
                    startIcon={
                      <i className='material-icons c-inherit'>content_copy</i>
                    }
                    color={'primary'}
                    className={`tt-unset mr-1 min-w-120 flex-align-left-center`}
                    onClick={event => {
                      let value = String(this.state.subject || '');
                      copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                    }}
                  >
                    Subject:&nbsp;
                    <span className='fw-400 c-black'>
                      {String(this.state.subject || '')}
                    </span>
                  </Button>
                </div>
                <div>
                  <Button
                    title='Copy value'
                    startIcon={
                      <i className='material-icons c-inherit'>content_copy</i>
                    }
                    color={'primary'}
                    className={`tt-unset mr-1 min-w-120 flex-align-left-center`}
                    onClick={event => {
                      let value = String(this.state.body || '');
                      copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                    }}
                  >
                    Email body
                  </Button>
                </div>
                <div
                  className='rounded bg-white outlined px-2 py-1 f-md overflow-auto'
                  dangerouslySetInnerHTML={{ __html: (this.state.body || '') }}
                  style={{ maxHeight: 'calc(60vh - 10rem)' }}
                />
              </div>
            </div>
          ) : (this.state.selectedPanel === EMAIL_DIALOG_PANEL__EDIT) && (
            <>

              {!!String(this.props.editDisclaimer).trim() && (
                <div className='align-left'>
                  {this.props.editDisclaimer}
                </div>
              )}

              {!this.props.omitUIRecipients && (
                <>
                  <div className="d-flex flex-align-left-center f-sm">
                    {(this.props.toAddRecipients !== false) && (
                      <span className="ml-auto">
                        <span className="c-black-medium">Add&nbsp;</span>
                        {[
                          this.props.employerRecipients && (
                            <span className="anchor" onClick={ev => this.setParams(this.props.employerRecipients, 'employer')}>Employer</span>
                          ),
                          this.props.jobRecipients && (
                            <span className="anchor" onClick={ev => this.setParams(this.props.jobRecipients, 'job')}>Job</span>
                          ),
                          this.props.recruiterRecipients && (
                            <span className="anchor" onClick={ev => this.setParams(this.props.recruiterRecipients, 'recruiter')}>Recruiter</span>
                          ),
                          this.props.candidateRecipients && (
                            <span className="anchor" onClick={ev => this.setParams(this.props.candidateRecipients, 'candidate')}>Candidate</span>
                          ),
                          (
                            <span className="anchor" onClick={ev => this.setParams(this.props.adminRecipients || {
                              from: Core.getResumeSubmissionFromEmail(),
                              emails: [TEN_BY_TEN_ADMIN_CONTACT],
                              to: [TEN_BY_TEN_ADMIN_CONTACT],
                              cc: [TEN_BY_TEN_ADMIN_CONTACT],
                              bcc: []
                            }, 'admin')}>10by10</span>
                          ),
                        ].filter(v => !!v).map((el, index) =>
                          <Fragment
                            key={joinKeyName([
                              'email_preview__recipients',
                              el,
                              index
                            ])}
                          >
                            {index !== 0 ? <>&nbsp;|&nbsp;</> : ''}
                            {el}
                          </Fragment>
                        )}
                      </span>
                    )}
                  </div>

                  <div className='d-flex flex-align-left-center mt-1'>
                    <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
                      To
                    </div>
                    <MultipleSelect
                      placeholder={('Type an email')}
                      multiple="tags"
                      data={emailsOptions}
                      value={this.state.to}
                      onChange={(to) => {
                        this.setState({ to });
                        _onUserEdit();
                      }}
                      onSearch={_onUserEdit}
                      onDeselect={_onUserEdit}
                      optionLabelProp
                      maxTagCount={false}
                      style={{ maxWidth: 'calc(100% - 128px)' }}
                    />
                    <IconButton
                      title='Copy value'
                      onClick={event => {
                        let value = escapeHtml((this.state.to || []).join(', ') || '');
                        copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                      }}
                    >
                      <i className='material-icons c-inherit'>content_copy</i>
                    </IconButton>
                  </div>
                  <div className='d-flex flex-align-left-center mt-1'>
                    <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
                      Cc
                    </div>
                    <MultipleSelect
                      placeholder={('Type an email')}
                      multiple="tags"
                      data={emailsOptions}
                      value={this.state.cc}
                      onChange={(cc) => {
                        this.setState({ cc });
                        _onUserEdit();
                      }}
                      onSearch={_onUserEdit}
                      onDeselect={_onUserEdit}
                      optionLabelProp
                      maxTagCount={false}
                      style={{ maxWidth: 'calc(100% - 128px)' }}
                    />
                    <IconButton
                      title='Copy value'
                      onClick={event => {
                        let value = escapeHtml((this.state.cc || []).join(', ') || '');
                        copyHtml(value).then(html => !!String(html || '').trim() && Core.showSuccess('Value copied!'));
                      }}
                    >
                      <i className='material-icons c-inherit'>content_copy</i>
                    </IconButton>
                  </div>
                </>
              )}

              {this.props.templateBar}
              <div className='d-flex flex-align-left-center mt-1'>
                <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
                  Subject
                </div>
                <TextField outlined small
                  name="subject"
                  placeholder=""
                  value={this.state.subject}
                  onChange={this.onChangeSubject}
                  onKeyDown={_onUserEdit}
                />
                <IconButton
                  title='Copy value'
                  onClick={event => {
                    copyHtml(this.state.subject).then(
                      html => !!String(html || '').trim() && Core.showSuccess('Value copied!')
                    );
                  }}
                >
                  <i className='material-icons c-inherit'>content_copy</i>
                </IconButton>
              </div>
              <SnippetsSelect
                loading={loadingSnippets}
                list={snippets}
                value={selectedSnippet}
                onChange={this.setSnippet}
              />
              {Core.isAdmin() && this.props.defaultSnippet && this.state.showDefaultSnippetError && (
                <ErrorMessage>
                  Can not found the default snippet <span className='c-red-lighter'>{`${this.props.defaultSnippet.snippetName}`}</span> in the snippets list
                </ErrorMessage>
              )}

              {!!String(this.props.greetings || '').trim() && (
                <div className='d-flex flex-align-left-center mt-1'>
                  <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
                    Greetings
                  </div>
                  <Input
                    name="greetings"
                    value={this.state.greetings}
                    onChange={event => this.setState({ greetings: event.target.value })}
                  />
                  <IconButton
                    title='Copy value'
                    onClick={event => {
                      copyHtml(this.state.greetings).then(
                        html => !!String(html || '').trim() && Core.showSuccess('Value copied!')
                      );
                    }}
                  >
                    <i className='material-icons c-inherit'>content_copy</i>
                  </IconButton>
                </div>
              )}

              <div className='d-flex flex-align-left-bottom mt-1'>
                <div className='flex-1 scroll-y'>
                  <RichTextBox
                    name="body"
                    value={this.state.body}
                    onChange={this.onChangeBody}
                    onKeyDown={_onUserEdit}
                    xtaller={this.props.taller}
                  />
                </div>
                <IconButton
                  title='Copy value'
                  onClick={event => {
                    copyHtml(this.state.body).then(
                      html => !!String(html || '').trim() && Core.showSuccess('Value copied!')
                    );
                  }}
                >
                  <i className='material-icons c-inherit'>content_copy</i>
                </IconButton>
              </div>
            </>
          )}
        </div>

      </div>
    );
  }
}

export function SnippetsSelect({
  list: snippets = [],
  value = '',
  onChange = () => { },
  loading = false,
}) {
  return (
    loading
      ?
      <LoadingMessage
        show={!!loading}
        className='p-0 mt-1'
        style={{ maxHeight: 36 }}
      >
        Getting snippets, wait a moment...
      </LoadingMessage>
      :
      !!snippets.length && <div className='d-flex flex-align-left-center mt-1'>
        <div className='c-cyan-darker fw-600 f-lg mr-1 min-w-80'>
          Snippets
        </div>
        <Select
          showSearch
          style={{ width: '100%', marginRight: 40 }}
          dropdownStyle={{ zIndex: 9999 }}
          listHeight={360}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.key.toLowerCase().localeCompare(optionB.key.toLowerCase())}
          value={value}
          onSelect={onChange}
          className='styled'
        >
          {[...snippets].map((snippet, index) => (
            <Option
              key={joinKeyName([
                'email_preview__snippet',
                snippet.snippetName,
                index
              ])}
              value={snippet.key}
              className='flex-row flex-align-left-center'
            >
              <Icon mr
                icon='help_outlined'
                className='icon16 c-purple'
                title={
                  <Box scrollY
                    style={{ maxHeight: 'calc(100vh - 3rem)' }}
                  >
                    {snippet.subject ? <div>{snippet.subject}<hr /></div> : ''}
                    {snippet.snippetText ? <div dangerouslySetInnerHTML={{ __html: snippet.snippetText }}></div> : ''}
                  </Box>
                }
                placement={PLACEMENT__LEFT_START}
                titleStyle={{
                  backgroundColor: 'var(--whiteCommon)',
                  color: 'var(--blackDarker)',
                  width: 640,
                  maxWidth: '40vw',
                  fontSize: '0.875rem',
                  border: 'var(--outlined)',
                }}
              />
              {snippet.snippetName}
            </Option>
          ))}
        </Select>
      </div>
  );
};
