import moment from 'moment';
import { Arr } from '../../lib/Array.lib';
import Company, { fetchEmploymentHistorySignals, getCompany, NEGATIVE_COMPANY_SIGNAL_TAG_IDS, NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED, POSITIVE_COMPANY_SIGNAL_TAG_IDS, POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED } from '../../lib/Company';
import Core from '../../lib/Core';
import CrunchbaseLib from '../../lib/Crunchbase.lib';
import Definition, { DIVERSITY_CATEGORIES__UNKNOWN, NEGATIVE_SIGNALS__EMPLOYMENT_GAP, NEGATIVE_SIGNALS__JOB_HOPPER, POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND } from '../../lib/Definition';
import { fetchEducationHistorySignals, NEGATIVE_SCHOOL_SIGNAL_TAG_IDS, NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED, POSITIVE_SCHOOL_SIGNAL_TAG_IDS, POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED } from '../../lib/School';
import { isHavingCandidateEmploymentGap } from './Forms/EmploymentGapFieldset';
import { isCandidateJobHopper } from './Forms/JobHopperFieldset';
import { mapOrganizationTenureSignals, SIGNALS_TYPE__COMPANY, SIGNALS_TYPE__SCHOOL } from './OrganizationSignals.lib';

const DEBUG_CANDIDATE_SIGNAL_TAGS = Core.debug('DEBUG_CANDIDATE_SIGNAL_TAGS');

/**
 * 
 * @param {object} options.candidate
 * @returns An update object to be applied on candidate
 */
export default async function mapCandidateSignals({ candidate = {} }) {

  /** 
   * @note 
   * [ 2023-02-13 ][ MS ]
   * I was need to comment following flag in order get this function working for match page.
   * Because React does not support promises while rendering components.
   * I think we need would need to improve this, at some moment.
   */
  // if (Core.getKeyValue('BUSY_MAP_CANDIDATE_SIGNALS')) { return false; }
  // Core.setKeyValue('BUSY_MAP_CANDIDATE_SIGNALS', true);

  let {
    employmentHistories = [],
    educationHistories = [],
    positiveSignals,
    positiveSignalsManual = [],
    negativeSignals,
    negativeSignalsManual = [],
  } = candidate;

  let positiveSignalsTagIds = Definition.get('positiveSignals').map(({ id }) => id);
  let negativeSignalsTagIds = Definition.get('negativeSignals').map(({ id }) => id);

  let __dicSignals = ({
    ...await fetchEmploymentHistorySignals({
      employmentHistories
    }).catch(Core.showError),
    ...await fetchEducationHistorySignals({
      educationHistories
    }).catch(Core.showError)
  });

  let __dicOrganizations = {};

  if (CrunchbaseLib.isEnabled()) {
    let organizationIds = Object.entries(__dicSignals).map(([key, company]) => company.crunchbaseOrganizationId).filter(v => !!v);
    __dicOrganizations = !!organizationIds.length ? (
      await CrunchbaseLib.get({
        where: { id: { inq: organizationIds } }
      })
        .then(response => {
          let hash = {};
          response.forEach(organization => {
            hash[organization.id] = organization;
          });
          return hash;
        })
        .catch(Core.showError) || {}
    ) : {};
  }

  positiveSignals = [];
  negativeSignals = [];

  // loop employments
  for (let employment of employmentHistories) {
    setupCandidateHistorySignals({
      history: employment
    });
    let signalTags = __dicSignals[employment.companyId];
    if (signalTags) {
      signalTags = mapOrganizationTenureSignals({
        signals: signalTags,
        type: SIGNALS_TYPE__COMPANY
      });
      mapCandidateHistorySignals({
        signalTags,
        history: employment
      });
      // merge history signals
      positiveSignals = [
        ...positiveSignals,
        ...employment.positiveSignalsTags
      ];
      negativeSignals = [
        ...negativeSignals,
        ...employment.negativeSignalsTags
      ];
    }
  }

  // loop educations
  for (let education of educationHistories) {
    setupCandidateHistorySignals({
      history: education
    });
    let signalTags = __dicSignals[education.schoolId];
    if (signalTags) {
      signalTags = mapOrganizationTenureSignals({
        signals: signalTags,
        type: SIGNALS_TYPE__SCHOOL
      });
      mapCandidateHistorySignals({
        signalTags,
        history: education
      });
      // merge history signals
      positiveSignals = [
        ...positiveSignals,
        ...education.positiveSignalsTags
      ];
      negativeSignals = [
        ...negativeSignals,
        ...education.negativeSignalsTags
      ];
    }
  }

  // EMPLOYMENT GAP CALC
  let __flagCandidateHaveEmploymentGap;
  negativeSignalsManual = negativeSignalsManual.filter(id => id !== NEGATIVE_SIGNALS__EMPLOYMENT_GAP);
  if (isHavingCandidateEmploymentGap({ candidate })) {
    __flagCandidateHaveEmploymentGap = true;
    negativeSignals.push(NEGATIVE_SIGNALS__EMPLOYMENT_GAP);
  }
  else {
    __flagCandidateHaveEmploymentGap = false;
    negativeSignals = negativeSignals.filter(id => id !== NEGATIVE_SIGNALS__EMPLOYMENT_GAP);
  }

  // JOB HOPPER CALC
  let __flagCandidateIsJobHopper;
  negativeSignalsManual = negativeSignalsManual.filter(id => id !== NEGATIVE_SIGNALS__JOB_HOPPER);
  if (isCandidateJobHopper({ candidate })) {
    __flagCandidateIsJobHopper = true;
    negativeSignals.push(NEGATIVE_SIGNALS__JOB_HOPPER);
  }
  else {
    __flagCandidateIsJobHopper = false;
    negativeSignals.filter(id => id !== NEGATIVE_SIGNALS__JOB_HOPPER);
  }

  // DIVERSITY CALC
  positiveSignals = positiveSignals.filter(id => id !== POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND);
  if (!!candidate.__forceSignals) {
    if (
      !positiveSignalsManual.includes(POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND)
    ) {
      candidate.diversityCategories = [];
    }
  }
  else {
    if (
      !!Arr(candidate.diversityCategories).length &&
      !Arr(candidate.diversityCategories).includes(DIVERSITY_CATEGORIES__UNKNOWN)
    ) {
      positiveSignalsManual.push(POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND);
    }
    else {
      positiveSignalsManual = positiveSignalsManual.filter(id => id !== POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND);
    }
  }

  // update for the candidate state
  let update = {

    // de-dup [c.signals] and filter valid signals
    positiveSignals: [
      ...new Set([...positiveSignals, ...positiveSignalsManual])
    ].filter(id => positiveSignalsTagIds.includes(id)),
    negativeSignals: [
      ...new Set([...negativeSignals, ...negativeSignalsManual])
    ].filter(id => negativeSignalsTagIds.includes(id)),

    positiveSignalsManual: [...new Set(positiveSignalsManual)],
    negativeSignalsManual: [...new Set(negativeSignalsManual)],

    employmentHistories,
    educationHistories,

    diversityCategories: candidate.diversityCategories,

    // to be used by the SignalChips component - this is not saved to DB
    __dicSignals,
    __dicOrganizations,

    __flagCandidateHaveEmploymentGap,
    __flagCandidateIsJobHopper,
    __forceSignals: false

  };

  DEBUG_CANDIDATE_SIGNAL_TAGS && console.debug('mapCandidateSignals:update', update);

  // Core.setKeyValue('BUSY_MAP_CANDIDATE_SIGNALS', false);

  return update;
}

export function filterInvalidCandidateHistorySignals({
  candidate,
  history,
  type,
  filteringPositiveSignals = [],
  filteringNegativeSignals = []
}) {
  if (type === SIGNALS_TYPE__COMPANY) {
    filteringPositiveSignals = [...POSITIVE_COMPANY_SIGNAL_TAG_IDS, ...POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_COMPANY_SIGNAL_TAG_IDS, ...NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED];
  }
  else if (type === SIGNALS_TYPE__SCHOOL) {
    filteringPositiveSignals = [...POSITIVE_SCHOOL_SIGNAL_TAG_IDS, ...POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
    filteringNegativeSignals = [...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS, ...NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED];
  }
  if (!!history.positiveSignalsTags?.length) {
    history.positiveSignalsTags = history.positiveSignalsTags.filter(id => filteringPositiveSignals.includes(id));
  }
  if (!!history.negativeSignalsTags?.length) {
    history.negativeSignalsTags = history.negativeSignalsTags.filter(id => filteringNegativeSignals.includes(id));
  }
}

/**
 * Wipeout signal tags from history if modification flag is not set
 * 
 * @param {object} options
 * @param {object} options.history
 */
export function setupCandidateHistorySignals({
  history
}) {
  if (!history.manuallyOverriddenAt) {
    history.positiveSignalsTags = [];
    history.negativeSignalsTags = [];
  }
}

export function mapCandidateHistorySignals({
  signalTags = {},
  history
}) {
  DEBUG_CANDIDATE_SIGNAL_TAGS && console.debug('mapCandidateHistorySignals', {
    signalTags,
    history
  });
  if (signalTags) {
    if (history.manuallyOverriddenAt && !history.__forceAutomaticallyDerive) {
      history.positiveSignalsTags = history.positiveSignalsTags || [];
      history.negativeSignalsTags = history.negativeSignalsTags || [];
    }
    else {
      history.automaticallyDerivedAt = moment().toISOString();
      history.positiveSignalsTags = signalTags.positive || [];
      history.negativeSignalsTags = signalTags.negative || [];
      delete history.manuallyOverriddenAt;
      delete history.manuallyOverriddenBy;
      delete history.__forceAutomaticallyDerive;
    }
  }
}

export async function fixEmploymentIssues(candidate) {
  for (let index in candidate.employmentHistories) {
    let employment = candidate.employmentHistories[index];
    let { companyId, employerOrgName } = employment;
    DEBUG_CANDIDATE_SIGNAL_TAGS && console.debug('DEBUG_CANDIDATE_SIGNAL_TAGS:fixEmploymentIssues\n', companyId, employerOrgName);
    if (!companyId) {
      const setCompany = ({ company }) => {
        if (company) {
          employment.companyId = company.id;
          employment.__companyIdFixedAt = new Date().toISOString();
        }
      }
      let organization = await CrunchbaseLib.getSuggestions({ name: employerOrgName }).then(organizations => {
        return organizations && organizations.find(organization => {
          if (organization.id && organization.name?.match(new RegExp(`^${employerOrgName}$`, 'i'))) {
            return organization;
          }
          return false;
        });
      });
      if (organization) {
        setCompany({
          company: (
            await Company.get({ where: { crunchbaseOrganizationId: organization.id }, limit: 1 }).then(companies => companies[0]) ||
            await Company.post({ name: employerOrgName, crunchbaseOrganizationId: organization.id })
          )
        });
      }
      else {
        setCompany({
          company: await getCompany({ name: employerOrgName })
        });
      }
    }
  }
  return candidate;
}

export function removeMappedSignalsFlag() {
  delete Core.getKeyValue('CandidateEditController')?.__mappedSignals;
}

export async function reRenderEditCandidate() {
  return new Promise(resolve => {
    removeMappedSignalsFlag();
    if (Core.getKeyValue('CandidateEditController')?.setStateStore instanceof Function) {
      Core.getKeyValue('CandidateEditController').setStateStore({}, resolve);
    }
    else {
      resolve();
    }
  })
}
