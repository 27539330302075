import {
  Route,
  Routes
} from 'react-router-dom';
import Announcements from '../components/Announcements/Announcements';
import CandidateEdit from '../components/Candidates/Edit/CandidateEdit';
import Main from '../components/Home/Main';
import JobEdit from '../components/Jobs/Edit/JobEdit';
import Navigate from '../components/Layout/Wrappers/Navigate';
import ForgotPassword from '../components/Login/ForgotPassword';
import Login from '../components/Login/Login';
import ResetPassword from '../components/Login/ResetPassword';
import MatchPage from '../components/Match/MatchPage';
// import Settings from '../components/Settings/Settings';
import NoMatch from './NoMatch';

const RecruiterOnDev = () => {
  console.debug('Recruiter on dev routes');
  return (
    <Routes>
      {/** COMMON */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Login />} />
      <Route path="/forgot/password" element={<ForgotPassword />} />
      <Route path="/reset/password/:token" element={<ResetPassword />} />
      <Route path="/home" element={<Main />} />
      <Route path="/candidates" element={<Main />} />
      <Route path="/candidate/create" element={<CandidateEdit />} />
      <Route path="/candidate/edit-section/:id" element={<CandidateEdit />} />
      <Route path="/candidate/edit/:id" element={<CandidateEdit />} />
      <Route path="/candidate/view/:id" element={<CandidateEdit />} />
      <Route path="/candidate/match/:id" element={<MatchPage />} />
      <Route path="/candidate/matchNew/:id" element={<MatchPage />} />
      <Route path="/jobs" element={<Main />} />
      <Route path="/job/view/:id" element={<Main />} />
      <Route path="/settings" element={<Main />} />
      <Route path="/announcements" element={<Announcements />} />
      <Route path="/recruiter" element={<Main />} />

      {/** RESERVED FOR RECRUITER ON DEV */}
      <Route path="/engagements" element={<Main />} />
      <Route path="/engagement/view/:id" element={<Main />} />
      <Route path="/engagement/represent/:id/:nextJobId" element={<Main />} />
      <Route path="/engagement/represent/:id" element={<Main />} />
      <Route path="/job/create" element={<JobEdit />} />
      <Route path="/job/edit/:id" element={<JobEdit />} />
      <Route path="/job/match/:id" element={<MatchPage />} />
      <Route path="/v3/job/matchNew/:id" element={<MatchPage />} />

      {/** COMMON SECTIONS V3 */}
      <Route path="/v3/candidate/create" element={<CandidateEdit />} />
      <Route path="/v3/candidate/edit-section/:id" element={<CandidateEdit />} />
      <Route path="/v3/candidate/edit/:id" element={<CandidateEdit />} />
      <Route path="/v3/candidate/view/:id" element={<CandidateEdit />} />
      <Route path="/v3/candidate/match/:id" element={<MatchPage />} />
      <Route path="/v3/candidate/matchNew/:id" element={<MatchPage />} />
      <Route path="/v3/announcements" element={<Announcements />} />
      <Route path="/v3/home" element={<Main />} />
      <Route path="/v3/candidates" element={<Main />} />
      <Route path="/v3/jobs" element={<Main />} />
      <Route path="/pro/jobs" element={<Main />} />

      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
}
export default RecruiterOnDev;
