import {
  ENGAGEMENT__FEEDBACK_LOG
} from './Engagement.dic';

export const EngagementUI = {
  homeworkAssigned: {
    label: 'HW issued',
    fullLabel: 'Homework assigned',
  },
  homeworkCompleted: {
    label: 'HW done',
    fullLabel: 'Homework completed',
  },
  closeEngagements: {
    sendEmails: {
      tooltip: 'Uncheck to perform operation without sending email'
    }
  },
  [ENGAGEMENT__FEEDBACK_LOG]: {
    title: 'Feedback log',
    placeholder: 'Type to edit'
  }
}

export default EngagementUI;
