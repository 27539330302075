import moment from 'moment';
import {
  Component
} from 'react';
import JobDetails from '../../components/Jobs/Card/JobDetails';
import {
  JobEngagementsDetails
} from '../../components/Jobs/Card/JobEngagementsDetails';
import Button from '../../components/Layout/Wrappers/Button';
import Checkbox from '../../components/Layout/Wrappers/Checkbox';
import IconButton from '../../components/Layout/Wrappers/IconButton';
import Menu from '../../components/Layout/Wrappers/Menu';
import NavLink from '../../components/Layout/Wrappers/NavLink';
import {
  ENGAGEMENT__STATE_CLOSED,
  ENGAGEMENT__STATE_OPEN,
  STAGE_CONFIRMATION,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_REVIEW,
  STAGE_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  join
} from '../Array.lib';
import Candidate from '../Candidate';
import {
  MDASH
} from '../Constants';
import Core from '../Core';
import Definition, {
  ACCOUNT_ACTION__EDIT_JOB,
  EMP_MSG_TYPE__SUBMISSION_ID,
  LOCATION__EMP_HIRING_LOCS_ID,
  RELOCATION__UNKNOWN_ID,
  STATE_ACTIVE
} from '../Definition';
import FilterControlLib from '../FilterControl';
import {
  NOT,
  getShortURL,
  getUnitTestingItemId,
  newModel
} from '../GenericTools.lib';
import Job from '../Job';
import copyHtml from '../tools/copyHtml';
import formatMoney from '../tools/formatMoney';
import {
  setLocation
} from '../URL.lib';
import {
  mapEmployer
} from './employer';
import {
  mapEngagements
} from './engagement';
import {
  mapStarred
} from './mapStarred.tool';

export const MODEL_NAME_JOB = 'Job';

const mdash = '—';
const model = {
  atsJobId: null,
  jobTitle: '',
  hiringTarget: 1, // default value 1
  visaTransfer: 0,
  salaryMin: 0,
  salaryMax: 0,
  salaryNote: '',
  equityMin: 0,
  equityMax: 0,
  jobDescription: '',
  jobDescriptionPublicURL: '',
  additionalNotes: '',
  candidateMust: '',
  candidateMustNot: '',
  candidatePositives: '',
  candidateNegatives: '',
  candidateMustDetails: {},
  candidateMustNotDetails: {},
  candidatePositivesDetails: {},
  candidateNegativesDetails: {},
  minYearsOfExperience: '',
  idealMinExperience: '',
  idealMaxExperience: '',
  qualificationNotes: '',
  publicNotes: '',
  resumeSubmissionName1: '',
  resumeSubmissionName2: '',
  resumeSubmissionName3: '',
  resumeSubmissionName4: '',
  resumeSubmissionEmail1: '',
  resumeSubmissionEmail2: '',
  resumeSubmissionEmail3: '',
  resumeSubmissionEmail4: '',
  resumeSubmissionEmailAction1: 'to',
  resumeSubmissionEmailAction2: 'cc',
  resumeSubmissionEmailAction3: 'cc',
  resumeSubmissionEmailAction4: 'cc',
  internalSubmissionInstructions: '',
  jobInterviewProcess: '',
  privateNotes: '',
  closeDate: null, // "2017-12-18T20:51:55.157Z",
  flagTagIds: [],
  placementFee: '', // NUMBER
  guarantee: '', // NUMBER
  additionalContractInfo: '',
  searchConfig: null,
  state: 2,
  role: 0,
  roles: [], // {number[]}:{(tagId:roles)[]}

  relocation: RELOCATION__UNKNOWN_ID,

  relocationExtraInfo: '',

  examplesOfAccepted: '',
  examplesOfRejected: '',

  /** to clone from employer >>> */

  /** TO DEPRECATE? 2021-06-09 µ */
  addressCity: '',

  /** TO DEPRECATE? 2021-06-09 µ */
  location: 0,

  candoPermittedJobs: [],

  /** <<< */

  /** foreign keys */
  employerId: '',
  resumeSubmissionEmailTemplateId: '',

  /** news */
  technicalSkills: [],
  // newTechSkill: "",
  technicalSkillsDetail: {},
  experience: [],
  positiveSignals: [],
  negativeSignals: [],
  flags: [],
  level: 0,
  calEvTemplates: null,

  mustHaveSkills: [],
  mustHave: {},
  niceToHave: {},
  jobTags: [],

  /** mixin timestamps */
  createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  updatedAt: null, // new Date().toISOString() // "2017-12-18T20:51:55.157Z",

  submissionMethod: 0,
  submissionMethods: [],

  mustHaveQuestions: [],
  putDownJobs: [],

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038(new locations)-story-3652-m2 | 2021-08-03 Tue µ */
  jobType: 0, // old field | jobType(TagId)
  desiredEmploymentTypes: [], // new field | desiredEmploymentType(TagId)

  /* epic-3038-story-3330-m2 | 2021-07-01 Thu µ */
  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  remote: 0, // old field | remote(TagId)
  inOfficeRemoteFlags: [], // new field | inOfficeRemote(TagId)

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  locations: [], // old field | location(TagId)
  officeLocations: [], // new field | location(TagId)

  /* epic-3038-story-3385 | 2021-07-15 Thu µ */
  /* epic-3038-story-3573 | 2021-07-28 Wed µ */
  candidateLocations: [], // new field | location(TagId)

  // epic-3038(new locations)-story-3705-m5 | 2021-08-06 Fri µ
  // epic-3038-story-3330-m4 | 2021-07-01 µ
  remoteExtraInfo: '', // old field
  locationDetails: '', // new field

  // story-3927-m4 | 2021-09-02 Thu µ
  emailsList: [],

  // {string} - story_8816
  feedbackLogResume: '',

  // {string} - story_8816
  feedbackLogInterview: '',

  // {string} - story_8816
  feedbackSummaryResume: '',

  // {string} - story_8816
  feedbackSummaryInterview: '',

  // {object} - story_9481
  // ex. { [PROVIDER_TAG_ID]: { [VARIABLE_NAME]: {{ANY}} } }
  atsContext: {}

};

const extended = {
  ___model___: MODEL_NAME_JOB,
  id: null,
  ...model,
  /** local includes */
  employer: {},
  /** local fills */
  jobBlackList: [],
  jobSourceList: [],
  employers: [],
  engagements: [],
  engaged: [],
  ___keys___: [],
};

/**
 *
 * @param {object} job
 * story-3927-m6 | 2021-09-02 Thu µ
 */
function mapOldEmailsToNewStructure(job) {
  const emails = job.emailsList.map(({ email }) => email);
  if (
    job.resumeSubmissionEmail1 &&
    NOT(emails.includes(job.resumeSubmissionEmail1))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail1,
      name: job.resumeSubmissionName1 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction1]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail2 &&
    NOT(emails.includes(job.resumeSubmissionEmail2))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail2,
      name: job.resumeSubmissionName2 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction2]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail3 &&
    NOT(emails.includes(job.resumeSubmissionEmail3))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail3,
      name: job.resumeSubmissionName3 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction3]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (
    job.resumeSubmissionEmail4 &&
    NOT(emails.includes(job.resumeSubmissionEmail4))
  ) {
    job.emailsList.push({
      email: job.resumeSubmissionEmail4,
      name: job.resumeSubmissionName4 || '',
      to: [],
      cc: [],
      bcc: [],
      [job.resumeSubmissionEmailAction4]: [EMP_MSG_TYPE__SUBMISSION_ID],
    });
  }
  if (!!job.emailsList.length) {
    job.resumeSubmissionEmail1 = '';
    job.resumeSubmissionName1 = '';
    job.resumeSubmissionEmailAction1 = '';
    job.resumeSubmissionEmail2 = '';
    job.resumeSubmissionName2 = '';
    job.resumeSubmissionEmailAction2 = '';
    job.resumeSubmissionEmail3 = '';
    job.resumeSubmissionName3 = '';
    job.resumeSubmissionEmailAction3 = '';
    job.resumeSubmissionEmail4 = '';
    job.resumeSubmissionName4 = '';
    job.resumeSubmissionEmailAction4 = '';
  }
}

/**
 * Map job._technicalSkills to
 *
 * job._agnosticTechnicalSkillsJobCard
 *
 * and
 *
 * job._agnosticTechnicalSkillsJobCopy
 *
 * @param {object} job
 *
 * story-3807-m1-2 | 2021-09-07 Tue µ
 */
function mapAgnosticTechnicalSkills(job) {
  let agnostic = false;

  const technicalSkills = job._technicalSkills.split(',').filter((e) => {
    let match = !e.trim().match(/^agnostic$/i);
    if (!match) {
      agnostic = true;
    }
    return match;
  });

  job._agnosticTechnicalSkillsJobCard = agnostic
    ? `${technicalSkills.join(', ')} (Agnostic - can interview in similar tech)`
    : job._technicalSkills;
  job._agnosticTechnicalSkillsJobCopy = agnostic
    ? `${technicalSkills.join(
      ', '
    )} (similar tech acceptable if willing to work on job's tech stack)`
    : job._technicalSkills;
}

const mapJob = (item) => {
  const job = getJobModel({ extended: true });
  if (item) {
    Object.keys(extended).forEach(
      (key) => (!!item[key] || item[key] === 0) && (job[key] = item[key])
    );
    job.id = item.id || item._id;

    mapStarred({ model: job, starredList: item.jobStarreds || [] });

    job.employer = mapEmployer(item.employer);
    job.engagements = mapEngagements(item.engagements);
    job._updatedAt = job.updatedAt
      ? moment(job.updatedAt).format('MM-DD-YYYY')
      : mdash;

    job._jobDescriptionPublicURL = job.jobDescriptionPublicURL ? (
      <a
        href={job.jobDescriptionPublicURL}
        target="_blank"
        rel="noreferrer"
      >
        {getShortURL(job.jobDescriptionPublicURL)}
      </a>
    ) : (
      ''
    )

    // story-3927-m6 | 2021-09-02 Thu µ
    mapOldEmailsToNewStructure(job);

    if (!job.submissionMethods.length) {
      job.submissionMethods = job.employer.submissionMethods;
    }

    if (!!Object(job.mustHave).type) {
      let elements = job.mustHave.args;

      const to_s = {
        complex: (el) => {
          let seps = {
            or: '|',
            and: '+',
            not: '-',
          };

          if (!el.args || !el.args.length) {
            return '';
          }

          let out;

          let mapped = el.args
            .sort(function (x, y) {
              return x === y ? 0 : x ? 1 : -1;
            })
            .map((e) => to_s[e.type](e))
            .filter((el) => !!el)
            .join(seps[el.type]);
          /** @patch  [2024-07-18][story_9166] wording. */
          Object.assign(el, { atLeast: el.atLeast || el.atleast });
          if (el.type === 'not') {
            out = !!mapped ? `${seps[el.type]} ${mapped}` : '';
          }
          else if (el.atLeast > 1) {
            out = `${el.atLeast}:${el.args
              .filter((arg) => arg.key === 'technicalSkills')
              .map((e) => to_s[e.type](e))
              .filter((e) => !!e)
              .join(seps[el.type])}`;
          }
          else {
            out = `${el.args
              .filter((arg) => arg.key === 'technicalSkills')
              .map((e) => to_s[e.type](e))
              .filter((e) => !!e)
              .join(seps[el.type])}`;
          }

          return out;
        },
        chipGroup: (el) => {
          let label = '';
          let defRaw = Definition.getRawDef(el.key);
          let groupEntry =
            !!defRaw && Array.isArray(defRaw.groups)
              ? defRaw.groups.find((group) => group.id === el.value)
              : {};
          if (!!groupEntry.ids) {
            label = Definition.getLabels(el.key, groupEntry.ids).join('|');
          }

          let primaryLabel = el.preferred ? '*' : '';
          return !!label ? `${primaryLabel}${label}` : '';
        },
        chipTag: (el) => {
          return '';
        },
        chip: (el) => {
          let label = '';
          if (el.key === 'technicalSkills') {
            label = Definition.getLabel(el.key, el.value);
          }

          let primaryLabel = el.preferred ? '*' : '';
          return !!label ? `${primaryLabel}${label}` : '';
        },
        text: (el) => {
          return ``;
        },
      };

      try {
        let formatted = elements
          .filter((el) => !!el.type)
          .map((el) => {
            let type = el.type;

            if (['or', 'not', 'and'].includes(el.type)) {
              type = 'complex';
            }

            return to_s[type](el);
          })
          .filter((el) => !!el);

        let separator = job.mustHave.type === 'and' ? '+' : '|';
        job.newTechSkill = formatted
          .map((label) => `(${label})`)
          .join(separator);
      } catch (exception) {
        console.log({ exception });
      }
    }

    job._submissionMethods = job.submissionMethods
      .map((tagId) => Definition.getLabel('employerSubmissionMethod', tagId))
      .join(', ');

    job._relocation =
      job.relocation === 0 ? 'No' : job.relocation === 1 ? 'Yes' : 'Unknown';

    /* prefills from employer */
    job.additionalContractInfo =
      job.additionalContractInfo || job.employer.additionalContractInfo;
    job.placementFee = job.placementFee || job.employer.placementFee;
    job.guarantee = job.guarantee || job.employer.guarantee;

    job._placementFee = job.placementFee ? `$${formatMoney(job.placementFee)}` : MDASH;

    /* Employer HQ Address */
    job._employerHeadQuarterAddress = [
      job.employer.addressStreet,
      job.employer.addressCity,
      job.employer.addressState,
      job.employer.addressCountry,
      job.employer.addressZip,
    ]
      .filter((n) => !!n)
      .join(', ')
      .trim();

    /* mapping stuff */
    job._starred = job.starred ? 'Starred: True' : 'Starred: False';
    job._engagementsLength = Number(job.engagements.length);

    let isActiveLabel = job.state !== STATE_ACTIVE ? ' - Disabled' : '';

    job._name = `${job.employer.name || ''} - ${job.jobTitle || ''} - ${job.addressCity || ''
      }${isActiveLabel}`.trim();
    job._shortName = join([job.employer.name, job.jobTitle], ` ${MDASH} `);
    job._employerName = `${job.employer.name || ''}`.trim();
    job._employerProxyName = `${job.employer.proxyName || ''}`.trim();
    job._employerName = Core.isLimited()
      ? job._employerProxyName
      : job._employerName;
    job._jobTitle = `${job.jobTitle}${job.hiringTarget ? ` (${job.hiringTarget})` : ''
      }`.trim();
    job._employeeCountNum = job.employer.employeeCount;
    job._employeeCount = job.employer.employeeCount
      ? `~${job.employer.employeeCount} employees`
      : mdash;

    job._employeeStage = job.employer._stage;
    job._employeeRating = job.employer._employerHiringDifficulty;
    job._yearsOfExperience = job.minYearsOfExperience
      ? `${job.minYearsOfExperience}+ years`
      : mdash;

    job._minSalary = formatMoney(job.salaryMin, 0);

    job._salaryMax = job.salaryMax ? `$${formatMoney(job.salaryMax, 0)}` : MDASH;

    if (!job.internalSubmissionInstructions) {
      job.internalSubmissionInstructions =
        Object(job.employer).internalSubmissionInstructions || '';
    }

    if (!job.location) {
      job.location = Definition.getId('location', 'Unspecified');
    }

    if (!!job.role && !job.roles.includes(parseInt(job.role))) {
      job.roles = [...job.roles, job.role];
    }

    if (!!job.location && !job.locations.includes(parseInt(job.location))) {
      job.locations = [...job.locations, job.location];
    }
    /* set definition labels */
    /* for fill filter menus and autocomplete */
    Definition.set(job, 'state');
    Definition.set(job, 'level');
    Definition.set(job, 'remote');
    Definition.set(job, 'visa', 'visaTransfer');

    /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
    Definition.set(job, 'jobType');
    Definition.map(job, 'desiredEmploymentType', 'desiredEmploymentTypes');

    Definition.map(job, 'roles');
    Definition.map(job, 'location', 'locations');
    Definition.map(job, 'technicalSkills');

    // story-3807-m1-2 | 2021-09-07 Tue µ
    mapAgnosticTechnicalSkills(job);

    Definition.map(job, 'experience');
    Definition.map(job, 'positiveSignals');
    Definition.map(job, 'negativeSignals');
    Definition.map(job, 'flags');
    Definition.map(job, 'stage', 'employerStage');

    /* NEW FIELDS 2021-06-14 µ story-3081 >>> */
    Definition.map(job, 'location', 'officeLocations');
    Definition.map(job, 'location', 'candidateLocations');

    /* epic-3038(new locations)-story-3330-m2 - 2021-07-01 µ */
    /* sets job._inOfficeRemoteFlags and job._inOfficeRemoteFlagsKeys */
    Definition.map(job, 'inOfficeRemote', 'inOfficeRemoteFlags');

    job._infoCmp = <JobInfo job={job} />;

    // labels without prefix for v3
    job.__inOfficeRemoteFlags = job._inOfficeRemoteFlags;

    /* sets the menu-prefix on each keyword
        contained in job._inOfficeRemoteFlags
        to include them into job.___keys___ */
    job._inOfficeRemoteFlags = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'inOfficeRemoteFlags' }),
      itemLabels: job._inOfficeRemoteFlags,
    });

    // labels without prefix for v3
    job.__officeLocations = job._officeLocations;

    /* NEW FIELDS 2021-06-22 µ story-3081 */
    /* set menu.prefix */
    job._officeLocations = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'officeLocations' }),
      itemLabels: job._officeLocations,
    });

    // labels without prefix for v3
    job.__candidateLocations = job._candidateLocations;

    /* NEW FIELDS 2021-06-22 µ story-3081 */
    /* set menu.prefix */
    job._candidateLocations = FilterControlLib.getItemValues({
      menu: Job.getMenu({ key: 'candidateLocations' }),
      itemLabels: job._candidateLocations,
    });

    /* epic-3038 (new locations) | story-3079-M6 | 2021-06-28 µ */
    if (job.candidateLocations.includes(LOCATION__EMP_HIRING_LOCS_ID)) {
      job._employerHiringLocations = Definition.getLabels(
        'location',
        job.employer?.candidateLocations || []
      ).join(', ');

      // labels without prefix for v3
      job.__employerHiringLocations = job._employerHiringLocations;

      job._employerHiringLocations = FilterControlLib.getItemValues({
        menu: Job.getMenu({ key: 'candidateLocations' }),
        itemLabels: job._employerHiringLocations,
      });
    }

    job._salaryRange = String(job.salaryMin) + '-' + String(job.salaryMax);
    if (job._experience) {
      job._experience = job._experience.replace(/Lead/gi, 'Experience: Lead');
    }
    if (job._state === 'Lead') {
      job._state2 = 'State: Lead';
    }

    job._jobTitleCmp = Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
      <a href={'/#/job/edit/' + job.id}>
        <b>{job._jobTitle}</b>
      </a>
    ) : (
      job._jobTitle
    );
    job._engagementsInfoCmp = <EngagementsInfo job={job} />;
    job._rowOptionsCmp = <RowOptions job={job} />;
    job.openDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: 900,
          minWidth: '60vw',
          maxWidth: 'calc(100vw - var(--containerMinMargin))',
        },
        content: (
          <>
            <JobDetails job={job} />
            <IconButton
              style={{ position: 'fixed', top: 0, right: 0, zIndex: 2 }}
              onClick={(ev) => Core.closeDrawer()}
            >
              <i className="material-icons">close</i>
            </IconButton>
          </>
        ),
      });
    };
    job.openEngagementsDetails = (ev) => {
      if (Object(ev).preventDefault) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      Core.openDrawer({
        style: {
          width: '80vw',
          maxWidth: "calc(100vw - var(--containerMinMargin))",
        },
        content: job.getEngagementDetails({ isDrawer: true }),
      });
    };
    job.getEngagementDetails = (props) => <JobEngagementsDetails job={job} {...props} />;
    job.delete = (onSuccess) => {
      Core.dialog.open({
        title: <>Delete Job?</>,
        message: "This action can't be undone.",
        style: { width: '320px' },
        actions: [
          <Button flat
            label="Cancel"
            className="button-white-cyan"
            onClick={(ev) => {
              Core.dialog.close();
            }}
          />,
          <Button flat
            label="Delete"
            className="button-flat-cyan"
            onClick={(ev) => {
              Core.dialog.close();
              Job.delete(job.id, onSuccess ? onSuccess : function () { });
            }}
          />,
        ],
      });
    };
    job.checkStar = (checked, onSuccess) => {
      Job.updateStarred(job.id, job.starredId, checked, (response) => {
        job.starredId = response.id;
        job.starred = response.starred;
        job.filters = {
          ...job.filters,
          Starred: ['Starred: False', 'Starred: True'][
            ~~Boolean(response.starred)
          ],
        };
        onSuccess && onSuccess(response);
      });
    };
    job.clone = (ev) => {
      const newJobModel = {};
      Object.keys(model).forEach((key) => {
        if (
          typeof job[key] === 'boolean' ||
          key === 'visaTransfer' ||
          !!job[key]
        ) {
          newJobModel[key] = job[key];
        }
      });
      delete newJobModel.id;
      newJobModel.state = Definition.getId('state', 'Draft');
      Core.log({ newJobModel });
      Job.post(newJobModel, (response) => job.goEdit());
    };
    job.getPreview = (em) => Job.getPreview(job);
    job.copy = (ev) =>
      copyHtml(Job.getPreview(job))
        .then((em) => {
          Core.log('Copy email command was successful');
          Core.showSuccess('Copied!');
        })
        .catch((ex) => {
          Core.log('Oops, unable to copy');
          Core.showError('Fail copy!');
        });
    job.goMatch = (ev) => setLocation('/#/job/match/' + job.id);
    job.goEdit = (ev) => setLocation('/#/job/edit/' + job.id);

    /* For autocomplete AND filtering */
    job.___keys___ = [
      /* Jira Ticket Ticket VER-20: All jobs must match with "Citizen" */
      // "Citizen",
      /* Jira Ticket Ticket VER-20: Always include unspecified, unknow in the search results. */
      // "Visa Support Unknown",
      job.jobTitle,
      job._employerName,
      job._employerProxyName,
      job.addressCity,
      job._locations,
      job._roles,
      job._state2 || job._state,
      job._employeeCountNum,
      job.minYearsOfExperience,

      /* epic-3038(new locations)-story-3652-m4 | 2021-08-03 Tue µ */
      job._jobType,
      job._desiredEmploymentTypes,

      job._level,
      job._remote,
      job._visaTransfer,
      job._technicalSkills,
      job._experience,
      job._positiveSignals,
      job._negativeSignals,
      job._flags,
      job._starred,
      job._engagementsLength,
      job._employeeStage,

      /** NEW FIELDS 2021-06-14 µ story-3081 */
      job._officeLocations,
      job._candidateLocations,

      /* epic-3038 (new locations) | story-3079-M6 | 2021-06-28 µ */
      job._employerHiringLocations,

      /* epic-3038(new locations)-story-3330-M2 - 2021-07-01 µ */
      job._inOfficeRemoteFlags,
    ]
      /* combine and split values */
      .join(',')
      .split(',')
      /* remove empty values */
      .filter((s = '') => !!s.trim())
      .map((s) => s.trim());

    /* epic-3038(new locations)-story-3652-m2,m4 | 2021-08-03 Tue µ */
    if (NOT(job.desiredEmploymentTypes.length)) {
      Definition.get('desiredEmploymentType').forEach((tag) =>
        job.___keys___.push(tag.label)
      );
    }

    /* epic-3038(new locations)-story-3573-m2 | 2021-07-28 Wed µ */
    job.__unitTestingItemId = getUnitTestingItemId(job.jobTitle);

    // job.__moreOptionsActions = candidateMoreMenuOptions;

    // job.__renderExpandedView = renderCandidateExpandedView;

    job.__onClickStar = (checked) =>
      Candidate.updateStarred(job.id, job.starredId, checked).then(
        ({ starred, id }) => {
          job.starredId = id;
          job.starred = starred;
          return starred;
        }
      );

    job.__getCurrentState = () => job;
  }
  return job;
};

const mapJobs = (data) => {
  const results = (data || []).map((item) => {
    const job = mapJob(item);
    return {
      ...job,
      filters: {
        Role: Definition.getLabel('roles', job.role),
        Recent: moment(job.updatedAt),
        Employer: job.employer.name || '—',
        Starred: ['Non Starred', 'Starred'][~~Boolean(job.starred)],
        [`My Sourcing`]: 'My Sourcing',
      },
    };
  });
  return results;
};

class EngagementsInfo extends Component {
  render() {
    const { job } = this.props;
    const engagements = job.engagements;
    const reviewEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_REVIEW)
    ).length;
    const screenEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_SCREEN)
    ).length;
    const onsiteEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_ONSITE)
    ).length;
    const offerEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_OFFER)
    ).length;
    const confEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_OPEN) && (eng.stage === STAGE_CONFIRMATION)
    ).length;
    const hireEngagements = engagements.filter(
      (eng) => (eng.stage === STAGE_HIRE)
    ).length;
    const inactiveEngagements = engagements.filter(
      (eng) => (eng.state === ENGAGEMENT__STATE_CLOSED)
    ).length;
    const submissions = engagements
      .map((eng) =>
        eng.submitted ? moment(eng.submitted).toDate().getTime() : 0
      )
      .filter((time) => !!time);
    const lastSubmissionTime = Math.max.apply(null, submissions);
    const lastSubmissionDate = moment(lastSubmissionTime).toISOString();
    const lastSubmission = submissions.length
      ? moment(lastSubmissionTime).format('M/DD')
      : 0;
    const componentEngagements = (
      <div className="pointer" onClick={(ev) => job.openDetails()}>
        <span
          aria-label={`Last submission: ${lastSubmissionDate}`}
          className="hint--right hulk"
        >
          <b>{lastSubmission || mdash}</b>|
        </span>
        <span aria-label="Open, Review" className="hint--bottom hulk">
          {reviewEngagements}rv|
        </span>
        <span aria-label="Open, Screen" className="hint--bottom hulk">
          {screenEngagements}scr|
        </span>
        <span aria-label="Open, Onsite" className="hint--bottom hulk">
          {onsiteEngagements}os|
        </span>
        <span aria-label="Open, Offer" className="hint--bottom hulk">
          {offerEngagements}ofr|
        </span>
        <span aria-label="Total Hire" className="hint--bottom hulk">
          {hireEngagements}h|
        </span>
        <span aria-label="Closed" className="hint--bottom hulk">
          {inactiveEngagements}e|
        </span>
        <span aria-label="Open, Confirmation" className="hint--bottom hulk">
          {confEngagements}cf
        </span>
      </div>
    );
    return Core.isAdminOrCoordinator() ? componentEngagements : <div />;
  }
}
class RowOptions extends Component {
  render() {
    // Core.log("RowOptions", "render");
    const { job } = this.props;
    return (
      <div className="row-options inline-blocks">
        <Checkbox
          title="Click to Starred"
          className="starred"
          checked={job.starred}
          onCheck={(ev, checked) => {
            job.checkStar(checked, (res) => this.setState({ updated: true }));
          }}
          checkedIcon={<i className="material-icons">star</i>}
          uncheckedIcon={<i className="material-icons">star_border</i>}
          iconStyle={job.openedColor}
        />
        {Core.isAdminOrTrusted() && (
          <Menu icon
            name={`job_model__row_options__menu`}
            option={[
              {
                acl: (Core.isAdmin() || (Core.isRecruiter() && Core.isOnDev())),
                label: 'Match',
                onClick: job.goMatch,
              },
              {
                acl: Core.isAdminOrCoordinator(),
                label: 'Clone',
                onClick: job.clone,
              },
              {
                acl: Core.isAdminOrTrusted(),
                label: 'Copy',
                onClick: job.copy,
              },
              {
                acl: Core.isAdminOnLocal() && !job.engagements.length,
                label: 'Delete',
                onClick: (event) => job.delete((res) => Core.Main && Core.Main.fetchData()),
              },
            ]}
          />
        )}
        <i
          className="material-icons"
          style={{
            width: 24,
            height: 24,
            margin: 0,
            cursor: 'pointer',
            fontWeight: 200,
            ...job.openedColor,
            ...job.rightArrow,
          }}
          onClick={job.openDetails}
        >
          chevron_right
        </i>
        {job.blacklisted}
      </div>
    );
  }
}

/**
 *
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @returns {object} A new model
 */
function getJobModel({ extended: isExtendedRequired } = {}) {
  return newModel(isExtendedRequired ? extended : model);
}

class JobInfo extends Component {
  render() {
    const { job } = this.props;
    return Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
      <NavLink to={`/job/edit/${job.id}`}>
        <b>{job.jobTitle || MDASH}</b>
      </NavLink>
    ) : (
      <b
        style={{
          color: colors.black.common,
          fontWeight: 500
        }}
      >
        {job.jobTitle || MDASH}
      </b>
    );
  }
}

setTimeout((st) => (extended.accountId = Core.getUserId()));

export {
  MODEL_NAME_JOB as JOB_MODEL_NAME,
  extended as default, extended,
  getJobModel,
  mapJob,
  mapJobs,
  model
};

