import moment from 'moment';
import {
  v4 as uuidv4
} from 'uuid';
import {
  deepModelMerge,
  Obj
} from '../../../lib/Object.lib';
import {
  ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE
} from './Content';

export const TEMPLATE_TYPE__EMAIL = 'email';

/**
 * 
 * @param {object} option 
 * @param {string} option.id
 * @param {string} option.name  Access keyword
 * @param {object} option.templates
 * @param {string} model.templates.subject  [handlebars]
 * @param {string} model.templates.bodyHtml  [handlebars]
 * @param {toISOString} option.createdAt
 * @param {toISOString} option.updatedAt
 * @returns 
 */
export function TemplateModel(model) {
  const now = moment().toISOString();
  return deepModelMerge(
    {
      id: ADMIN_MANAGE_TEMPLATES_OPTION__CREATE_TEMPLATE,
      name: uuidv4().replace(/-/g, '_'),
      type: TEMPLATE_TYPE__EMAIL,
      templates: {
        subject: '',
        bodyHtml: ''
      },
      createdAt: now,
      updatedAt: now
    },
    Obj(model)
  );
}
