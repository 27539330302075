import React, {
  Component
} from "react";
import ResumeMatch from "../../Shared/ResumeMatch";

class TextCVContentDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { textCV: "" }
  }
  renderTextCV = (text) => this.setState({ textCV: text });
  render() {
    const {
      candidate = {},
      job = {}
    } = this.props;
    const {
      employer = {}
    } = job;
    return (
      !!candidate.id && !!job.id &&
      <>
        <div
          style={{
            width: "auto",
            padding: "5px 10px 5px 10px",
            fontSize: "13px"
          }}
          dangerouslySetInnerHTML={{ __html: this.state.textCV }}
        />
        <ResumeMatch
          showOnDrawer={true}
          renderTextCV={this.renderTextCV}
          candidateResume={candidate.resumeTxtUrl}
          jobSkills={job._technicalSkills}
          employerSkills={employer._technicalSkills}
          element={(cb) => {
            return (null)
          }}
        />
      </>
    )
  }
}
export default TextCVContentDrawer;