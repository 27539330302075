import {
  Arr
} from '../Array.lib';
import {
  Obj
} from '../Object.lib';
import {
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_PAID
} from '../../dictionaries/Engagement.dic';

export function mapAccountEmployerDetails(accountDetails) {
  console.debug('mapAccountEmployerDetails:account', accountDetails);
  const placements = [];
  const offers = [];
  const model = { offers, placements };
  if (accountDetails) {
    let {
      employers = [],
    } = accountDetails;
    const employer = Obj(employers[0]);
    if (employer.id) {
      const { jobs } = employer;
      Arr(jobs).forEach((job) => {
        Arr(job.engagements).forEach((engagement, index) => {
          engagement.job = job;
          if ([
            STAGE_GUARANTEE,
            STAGE_HIRE,
            STAGE_PAID
          ].includes(engagement.stage)) {
            placements.push(engagement);
          }
          else if ([
            STAGE_OFFER
          ].includes(engagement.stage)) {
            offers.push(engagement);
          }
        })
      });
    }
  }
  console.debug('mapAccountEmployerDetails:model', model);
  return model;
}
