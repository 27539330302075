import {
  Component
} from "react";
import loader from "../../assets/images/loader.gif";
import {
  STAGE_CONFIRMATION,
  STAGE_END,
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_ONSITE,
  STAGE_PENDING,
  STAGE_REVIEW,
  STAGE_SCREEN,
  STAGE_SUBMISSION
} from '../../dictionaries/Engagement.dic';
import {
  Arr
} from '../../lib/Array.lib';
import Core from "../../lib/Core";
import Debug from "../../lib/Debug.lib";
import EngagementModel from "../../lib/models/engagement";
import {
  Obj
} from '../../lib/Object.lib';
import {
  Str
} from '../../lib/String.lib';
import {
  getLocation
} from '../../lib/URL.lib';
import {
  joinClassName,
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import IconButton from '../Layout/Wrappers/IconButton';
import NavLink from '../Layout/Wrappers/NavLink';

let itemsPerPage = 50;

const loadWrapper = [
  <div key={"loaderKey"} className="not-found inline-blocks">
    Loading&nbsp;
    <img alt="loading..." height="14" src={loader} />
  </div>
];

class List extends Component {
  data;
  tabState = {};
  constructor() {
    super(...arguments);
    this.parent = this.props.parent;
    this.state = {
      collection: [],
      page: loadWrapper,
      tab: this.props.tab,
      index: 0
    };
    itemsPerPage =
      Number(
        /items=/.test(getLocation()) &&
        getLocation().split("items=").pop()
      ) || itemsPerPage;

  }
  setItems(data) {
    this.data = data;
    this.loadData();
  }
  restart() {
    this.setState({
      collection: [],
      page: loadWrapper,
      index: 0
    });
  }
  updateItem = (item, opts = {}) => {
    this.data = this.data.map(current => {
      if (current.id === item.id) {
        current = { ...current, ...item };
      }
      return current;
    });

    !!opts.reload && this.loadData();
  };
  loadData(tab) {
    this.selectTab(tab || this.state.tab);
  }
  selectTab(tab) {
    Debug.time("Render List");
    this.setState({ tab }, then => {
      this.groupBy(tab);
    });
  }
  groupBy(filter) {
    var items = {};
    // organice data by filter key
    (this.data || []).forEach(item => {
      var header = item.filters[filter];
      if (filter !== undefined && filter !== null) {
        if (filter === "Recent") {
          header = Core.getFromNow(header);
        }
        else if (filter === "Introduced") {
          header = "Introduced";
        }
        items[header] = items[header] || [];
        if (filter === "Starred") {
          if (item.filters["Starred"] === "Starred") {
            items[header].push(item);
          }
        } else {
          if (header !== false) {
            items[header].push(item);
          }
        }
      }
    });
    let headers = Object.keys(items);
    headers = filter === "Stage"
      ? [
        STAGE_END,
        STAGE_HIRE,
        STAGE_GUARANTEE,
        STAGE_OFFER,
        STAGE_ONSITE,
        STAGE_SCREEN,
        STAGE_REVIEW,
        STAGE_SUBMISSION,
        STAGE_CONFIRMATION,
        STAGE_PENDING
      ]
      : filter === "Starred"
        ? ["Starred", "Non Starred"]
        : filter === "Recent"
          ? headers.sort((a, b) => {
            a = items[a][0].filters.Recent;
            b = items[b][0].filters.Recent;
            return a < b ? 1 : -1;
          })
          : headers.sort((a, b) => a.localeCompare(b));
    this.setCollection(headers, items, filter);
  }
  setCollection(headers, items, filter) {
    var collection = [];
    // push filtered data in result
    headers.forEach(header => {
      if (items[header]) {
        collection = collection.concat(sort(filter, items[header]));
      }
    });
    /** [ MS: for debug purposes ] */
    /** * /
    console.debug(
      `## Filter: ${filter}\n## Headers: \n${join(headers.slice(0, 3), '\n')}...\n## Collection:\n${join(collection.slice(0, 3).map(({ stage, statusUpdatedDate }) => [stage, statusUpdatedDate]), '...\n')}`
    );
    /** */
    // update the collection
    this.setPage(0, collection);
    function sort(header, items) {
      Core.setKeyValue('list-header', header);
      Core.setKeyValue('list-header-emp-todo', !!header.match(/Employer Todo/i));
      switch (header) {
        case "Introduced":
          return items
            .sort(
              (a, b) =>
                Str(b.introduced).localeCompare(Str(a.introduced))
            );
        case "Candidate Todo":
        case "10x10 Todo":
        case "Employer Todo":
          let orderedList = items.map(item => {
            // update filters to do status transition if needed.
            item.filters = EngagementModel.mapFilters(item);
            return item;
          }).sort(
            (a, b) =>
              b.filters[header].localeCompare(a.filters[header])
          );
          return orderedList;
        case "Stage":
          return items.sort((a, b) => ~~a.open - ~~b.open);
        default:
          return items;
      }
    }
  }
  setPage(index, collection) {
    const Card = this.props.card;
    collection = collection || this.state.collection;
    index =
      index < 0 ? 0 : index > collection.length ? collection.length : index;
    const page = collection.slice(index, index + itemsPerPage);
    this.setState({
      index,
      collection,
      page: page.length ? (
        page.map(model => (
          <Card
            key={`list-card-${model.id}`}
            model={model}
            params={this.props.params}
            parent={this}
            parentModel={this.props.parent.state}
            source={this.props.source || "ListMatch"}
            onCheck={this.props.onCheck}
          />
        ))
      ) : (
        <div className="not-found">No matches</div>
      )
    });
    Debug.showTimeEnd("Render List");
    this.scroller && this.scroller.scrollTo(0, 0);
  }
  render() {
    const collectionLength = this.state.collection.length;
    const previousIndex = this.state.index - itemsPerPage;
    const currentIndex = this.state.index;
    let nextIndex = this.state.index + itemsPerPage;
    nextIndex = nextIndex > collectionLength ? collectionLength : nextIndex;
    setListController(this);
    return (
      <>
        <div className="list">

          <Box row
            className="sort-buttons sticky-top bg-main"
          >

            {!this.props.disableTabs &&
              !!collectionLength && (
                <div className="collection-controls inline-blocks align-left">
                  <div className="collection-info">
                    {currentIndex + 1} - {nextIndex} of {collectionLength}
                  </div>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(0)}
                    disabled={currentIndex === 0}
                  >
                    <i className="material-icons">first_page</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(previousIndex)}
                    disabled={currentIndex === 0}
                  >
                    <i className="material-icons">navigate_before</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev => this.setPage(nextIndex)}
                    disabled={nextIndex >= collectionLength}
                  >
                    <i className="material-icons">navigate_next</i>
                  </IconButton>
                  <IconButton
                    className="icon24"
                    onClick={ev =>
                      this.setPage(collectionLength - itemsPerPage)
                    }
                    disabled={nextIndex >= collectionLength}
                  >
                    <i className="material-icons">last_page</i>
                  </IconButton>
                </div>
              )}

            {/**TABS */}
            <Box row wAuto noWrap scrollX
              className='flex-align-right-bottom ml-auto'
            >
              {
                !this.props.disableTabs &&
                Arr(this.props.tabs).filter(
                  tab => {
                    if (tab === "Closed") {
                      if (Core.isRecruiter()) {
                        return false;
                      } else if (!this.parent.state.showAll) {
                        return false;
                      }
                    }
                    if (/todo/i.test(tab)) {
                      if (this.parent.state.showAll) {
                        return false;
                      }
                    }
                    return true;
                  }
                ).map(
                  (tab, index) => (
                    <Button flat
                      key={`tab${tab}`}
                      className={joinClassName([
                        'sort-button',
                        (this.state.tab === tab) && 'tab-selected'
                      ])}
                      label={tab}
                      onClick={event => this.selectTab(tab)}
                      small
                    />
                  )
                )
              }
            </Box>

          </Box>

          <div
            ref={scroller => (this.scroller = scroller)}
            className="list-scroller"
            style={collectionLength === 1 ? { maxHeight: 'calc(100vh - 64px)' } : {}}
          >
            {this.state.page}
            {this.props.children}
          </div>
          {this.state.collection.length > 4 && (
            <div className="blocks fixed-bottom-right">
              <IconButton
                className="icon24"
                onClick={ev => this.scroller && this.scroller.scrollTo(0, 0)}
              >
                <i className="material-icons">expand_less</i>
              </IconButton>
              <IconButton
                className="icon24"
                onClick={ev => {
                  this.scroller &&
                    this.scroller.scrollTo(0, this.scroller.scrollTop + 500);
                }}
              >
                <i className="material-icons">expand_more</i>
              </IconButton>
            </div>
          )}
          {Core.isAdminOrRecruiterOnDev() && (
            <NavLink
              className="ui-m-max"
              to={`/${(this.props.name || "").toLowerCase()}/create`}
            >
              <FloatingActionButton
                className="list-floating-button"
                color={THEME__COLOR__SECONDARY}
              />
            </NavLink>
          )}
        </div>
      </>
    );
  }
}

export default List;

export const KEY__CON__LIST = "list_controller";
export function ListController() {
  return Obj(Core.getKeyValue(KEY__CON__LIST));
}

export function setListController(controller) {
  return Core.setKeyValue(KEY__CON__LIST, controller);
}
