// CAVE

export const TEMPLATE__CANDIDATE__CAVE__SUBJECT = `{{CANDIDATE__FULLNAME}} , thank you for your interest in {{EMPLOYER}}`;

export const TEMPLATE__CANDIDATE__CAVE__BODY = `
Hi {{CANDIDATE__NAME}},
<br/><br/>

<div>
  <b>{{RECRUITER__NAME}}</b>, our partner, shared that you're interested in new opportunities. We've presented you to the hiring team to expedite the process.
</div>
<br/>

Please review the information below to ensure that we have presented you to the correct role(s):

<ul>
  {{{JOB_LIST}}}
</ul>

Here's the contact information we have:

<ul>
  <li>
    Email: {{{CANDIDATE__EMAIL}}}
  </li>
  <li>
    Phone: {{{CANDIDATE__PHONE}}}
  </li>
</ul>

<span style="color:#ff0000;">
  Important: Employers expect you to be excited about the role and can explain why you want this interview.
</span>
<br/>

What's next?

<ul>
  <li>
    You are represented by <a href="https://www.10by10.io/candidate">10 BY 10</a>.
  </li>
  <li>
    <b>Employers</b> communicate <b>solely</b> with {{{TEN_X_TEN__EMAIL_ADDRESS}}} (we will relay info to {{RECRUITER__NAME}}).
  </li>
  <li>
    <b>Employer will email you</b> if interested in interviewing you.
  </li>
  <li>
    <b>{{RECRUITER__NAME}}</b>, our partner in finding top candidates, can help with interview prep.
  </li>
  <li>
    For questions, email both {{{TEN_X_TEN__EMAIL_ADDRESS}}} and {{RECRUITER__NAME}} for a faster response.
  </li>
</ul>
<br/>

Best of luck!

{{{SIGNATURE}}}
`;