import { WarningMessage } from '../Layout/Wrappers/Message';

export default function AccessDenied(props) {
  return (
    <div className='d-flex flex-align-top-center mt-3 p-2'>
      <WarningMessage className='m-auto'>
        You have not access to this page.
      </WarningMessage>
    </div>
  );
}
