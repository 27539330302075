import ChipGroup from "../../components/Match/Haves/v1/ChipGroup";

export const getCategoryKeys = (obj, jobArgs) => {
    
    let typeArray = [];
    
    jobArgs.forEach(args => {
        if(!!obj.key){
            typeArray.push(...getKeySpecificValues(obj, args));
        }else if (!!obj.type){
            typeArray.push(...getJobTagsValues(obj, args));
        }
    });
    
    return typeArray;
}

const getJobTagsValues = (obj, args) => {
    let typeArray = [];
    let innerArgs = [];

    if(!!args.args && !!args.args.length){
        innerArgs = args.args;
        innerArgs.filter(innerArg=>innerArg.type===obj.type)
                .forEach(innerArg=>{
            const splitValue = innerArg.value.split(",");
            typeArray = [
                ...typeArray,
                ...splitValue
            ];
        });
        
    } else if(!!obj.type && !!args.type && args.type === obj.type){
        typeArray.push(args.value);
    }

    return typeArray;
}

const getKeySpecificValues = (obj, args) => {
    let typeArray = [];
    let innerArgs = [];

    if(!!args.args && !!args.args.length){
        innerArgs = args.args;
        innerArgs.filter(innerArg=>innerArg.key === obj.key)
                .forEach(innerArg=>{
                    if(innerArg.type==='chip'){
                        typeArray.push(parseInt(innerArg.value));
                    }
                    else if(innerArg.type==='chipGroup'){
                        typeArray.push(...ChipGroup.getIdsOfGroupings(innerArg));
                    }
        });
        
    } else if(!!obj.key && !!args.type && args.key === obj.key){
        if(args.type === 'chip'){
            typeArray.push(parseInt(args.value));   
        }else if(args.type === 'chipGroup'){
            typeArray.push(...ChipGroup.getIdsOfGroupings(args));
        }
    }

    return typeArray;
}