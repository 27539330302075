import AppConfirmationDialog from '../Dialogs/AppConfirmationDialog';
import Core from '../../lib/Core';
import AlertDialog from '../Dialogs/AlertDialog';
import CustomDialog from '../Dialogs/CustomDialog';
import CustomDrawer from '../Dialogs/CustomDrawer';


export default function GlobalComponents() {
  return (
    <>
      <CustomDrawer
        ref={
          (self) => {
            if (self) {
              Core.openDrawer = ({ direction, content, style, onClose }) =>
                self.open({
                  direction,
                  content,
                  style,
                  onClose,
                });
              Core.closeDrawer = (ev) => self.close(ev);
            }
          }
        }
      />
      <CustomDialog
        ref={
          (self) => {
            if (self) {
              Core.dialog = self;
              Core.showFailure = (failure, onClose) => Core.dialog.open(
                {
                  title: 'Failure',
                  content: failure,
                  paperStyle: { width: 640 },
                  onClose,
                }
              );
            }
          }
        }
      />
      <AlertDialog />
      <AppConfirmationDialog />
    </>
  );
}
