import {
  Avatar
} from '@mui/material';
import {
  Input,
  Popover
} from 'antd';
import { useRef, useState } from 'react';
import Core from '../../../lib/Core';
import Loader from '../../Shared/Loader';
import Box from './Box';
import Menu from './Menu';

const DEBUG_SUGGESTION_LIST = Core.debug('DEBUG_SUGGESTION_LIST');

export const SUGGESTION_TYPE_HEADER = 'Header';
export const SUGGESTION_TYPE_NEW = 'New';

/**
 * 
 * @param {object} props
 * @param {string} props.title ---text to how as main header of the dropdown view---
 * @param {string} props.placeholder ---default text to show in the search input box when there is not input value---
 * @param {boolean} props.loading ---if true, pause user interactions with component---
 * @param {string} props.value ---is the id of the selected item from list---
 * @param {string} props.defaultSearch ---is the default value for the input box---
 * @param {object} props.defaultIcon ---is the icon to display as prefix in the input box---
 * @param {object[]} props.data ---this is the list of item to display in the dropdown---
 * @param {function} props.onSearch receives(search) ---this is triggered a second after user stop typing in the input box---
 * @param {function} props.onVisible receives(search) ---this is triggered when dropdown becomes visible on---
 * @param {function} props.onSelect receives(selected,option,search) ---this is triggered user click on an item---
 * @param {function} props.onCloseList ---this is triggered when dropdown becomes visible off---
 * @param {function} props.optionRender ---this is functional component to be used for render a option the list---
 * @param {boolean} props.focusOnLoaded ---if true, then it will try to autofocus the input box---
 * @returns {ReactJSXElement} UI input box
 */
export default function SuggestionList({
  title,
  placeholder = 'input search text',
  loading = false,
  value: defaultValue,
  defaultSearch,
  defaultIcon,
  data = [],
  onSearch = () => null,
  onVisible = () => null,
  onSelect = () => null,
  onCloseList = () => null,
  optionRender: OptionView,
  style = { width: '100%' },
  focusOnLoaded
}) {
  const inputElement = useRef();
  let [state, setState] = useState({
    selected: defaultValue, defaultValue,
    search: defaultSearch, defaultSearch,
    option: data.find(({ id }) => id === defaultValue)
  });
  let {
    selected,
    search,
    visible,
    loaded
  } = state;
  async function _updateState(update) {
    state = { ...state, ...update };
    setState(state);
    return await new Promise(resolve => setTimeout(() => resolve(state)));
  }
  async function _close() {
    await _updateState({ visible: false });
  }
  async function _onSelect({ id, option = {} } = {}) {
    if (loading) { return; }
    await _updateState({
      selected: option.id,
      search: option.value || option.label,
      option
    }).then(state => {
      if (option.action instanceof Function) {
        option.action(state);
      }
      else {
        onSelect(state);
      }
      _close();
    });
  }
  async function _onSearch(event) {
    let search = event.target.value;
    await _updateState({
      selected: null,
      search,
      visible: true
    });
    if (loading) { return; }
    clearTimeout(SuggestionList.timer);
    SuggestionList.timer = setTimeout(() => {
      console.debug('_onSearch', search);
      onSearch(search);
    }, 1000);
  }
  async function _onPressEnter() {
    if (loading) { return; }
    onSelect(state);
    _close();
  }
  async function _onVisibleChange(visible) {
    await _updateState({ visible });
    if (visible) {
      onVisible(search);
    }
    else {
      // ON BLUR
      onCloseList(search);
    }
  }
  if (defaultValue !== state.defaultValue) {
    _updateState({ selected: defaultValue, defaultValue, option: data.find(({ id }) => id === defaultValue) });
  }
  if (defaultSearch !== state.defaultSearch) {
    _updateState({ search: defaultSearch, defaultSearch });
  }
  if (focusOnLoaded && !loaded && !search) {
    _updateState({ loaded: true }).then(state => {
      inputElement.current.focus();
    });
  }
  DEBUG_SUGGESTION_LIST && console.debug(
    'DEBUG_SUGGESTION_LIST\n',
    state,
    data,
    defaultIcon
  );
  return (
    <Popover
      content={(
        <div
          className='d-flex flex-column'
          style={{ overflowY: 'auto', maxHeight: '25rem', maxWidth: 720 }}
          data-selected={selected}
          data-value={defaultValue}
        >
          {loading
            ? <Loader className='p-2' />
            : !!data.length
              ? data.map((option, index) =>
                option.type === SUGGESTION_TYPE_HEADER
                  ? (
                    <Box
                      key={`suggestion-list-option-${option.id}-${option.label}`}
                      title={option.subtype}
                      placement='right'
                    >
                      <div
                        className='p-0 px-2 d-flex flex-align-left-center'
                        style={{
                          backgroundColor: '#F1F5F9',
                          color: '#363739',
                          borderTop: '1px solid #E2E8F0',
                          borderBottom: '1px solid #E2E8F0'
                        }}
                      >
                        <SuggestionIcon icon={option.icon} label={option.label} className='mr-1' />
                        {option.label}
                      </div>
                    </Box>
                  )
                  : (
                    <Menu.Item
                      key={`suggestion-item-${option.id}-${option.label}`}
                      className={(option.id === defaultValue) && (option.label === defaultSearch) ? 'bg-cyan-a15' : ''}
                      style={{ maxHeight: 'unset', height: 'auto' }}
                      onClick={event => _onSelect({ id: option.id, option })}
                      data-id={option.id}
                      data-label={option.label}
                    >
                      {OptionView ? <OptionView {...option} /> : option.label}
                    </Menu.Item>
                  )
              ) : (
                <i className='c-black-medium p-2'>Empty list</i>
              )
          }
        </div >
      )}
      placement="bottomLeft"
      overlayClassName='suggestion-list styled'
      zIndex={99999}
      trigger="click"
      visible={visible}
      onVisibleChange={_onVisibleChange}
    >
      <Input.Search
        ref={inputElement}
        placeholder={placeholder}
        allowClear
        value={search}
        onChange={_onSearch}
        onPressEnter={_onPressEnter}
        style={style}
        prefix={<SuggestionIcon icon={defaultIcon} label={search} />}
      />
    </Popover >
  );
}

export function SuggestionIcon({ icon, label, style, className }) {
  return (
    icon === false ? null : icon
      ? (
        <Avatar
          src={icon}
          style={{ width: 16, height: 16, ...style }}
          className={`f-xs ${className}`.trim()}
        />
      )
      : (
        label ? (
          <span
            style={{ fontSize: '0.625rem', width: 16, height: 16, borderRadius: '50%', ...style }}
            className={`f-xs bg-cyan-prime c-white fw-300 d-flex flex-align-center ${className}`.trim()}
          >
            {String(label).replace(/\W/g, '').split(' ').map(w => w[0]?.toUpperCase()).slice(0, 2).join('')}
          </span>
        ) : null
      )
  );
}
