/** ============================================ µ
 * @description Template [JS]
 *              Library
 * @base        EmailTemplate library
 * @createdAt   2021-08-20 Fri 
 * @updatedAt   2021-08-20 Fri
 * ============================================ */
/* IMPORTS ==================================== */

import Core from "./Core";
import Http from "./Http";
import {
  getTemplateModel,
  mapTemplate,
  mapTemplates
} from "./models/template.model.js";
import getStateModel from "./tools/getStateModel";

/* CONSTANTS ================================== */

export const TEMPLATE_TYPE_EMAIL = 'email';
export const TEMPLATE_TYPE_DOCUMENT = 'document';
export const TEMPLATE_NAME_HOT_JOBS_TRUSTED = 'hot-jobs-trusted';
export const TEMPLATE_NAME_RECRUITER_TODO_REMINDER = 'recruiter-todo-reminder';

const cache = {};

const commonQuery = {
  include: []
};

/* METHODS ==================================== */

const getAll = cb => {
  cb = cb instanceof Function ? cb : function () { };
  if (cache.all) {
    cb(cache.all);
  } else {
    Http.get(
      Core.getApi("Templates"),
      {
        filter: JSON.stringify({ ...commonQuery })
      },
      function onSuccess(response) {
        cache.all = mapTemplates(response);
        cb(cache.all);
      }
    );
  }
};

const getWhere = (where, cb, skipCache) => {
  cb = cb instanceof Function ? cb : function () { };
  const key = JSON.stringify(where).replace(/\W/g, "");
  if (!skipCache && cache[key]) {
    cb(cache[key]);
  } else {
    return Http.get(
      Core.getApi("Templates"),
      {
        filter: JSON.stringify({ ...commonQuery, where: { ...where } })
      },
      function onSuccess(response) {
        cache[key] = mapTemplates(response);
        cb(cache[key]);
      }
    );
  }
};

const get = (templateId, cb) => {
  cb = cb instanceof Function ? cb : function () { };
  if (cache[templateId]) {
    cb(cache[templateId]);
  } else {
    Http.get(
      Core.getApi("Templates/" + templateId),
      {
        filter: JSON.stringify({ ...commonQuery })
      },
      function onSuccess(response) {
        cache[templateId] = mapTemplate(response);
        cb(cache[templateId]);
      }
    );
  }
};

const post = (template, success) => {
  return Http.post(
    Core.getApi("Templates"),
    getStateModel(template, getTemplateModel()),
    success
  );
};

const update = (templateId, template, success) => {
  return Http.patch(
    Core.getApi("Templates/" + templateId),
    getStateModel(template, getTemplateModel()),
    response => {
      delete cache[templateId];
      success instanceof Function && success(response);
    }
  );
};

async function getRender({
  templateName,
  templateId,
  recruiterId,
  employerId,
  jobId,
  candidateId,
  accountId,
  engagementId,
  setRenderedTemplate = ({ errors = [], rendered = {}, mixins = {} }) => { },
  messageTypes
}) {

  if (templateName) {
    templateId = (await TemplateLib.getBy({
      type: 'email',
      name: templateName
    })).id;
  }

  const response = (
    await Http.post(
      Core.getApi(`Templates/${templateId}/render`),
      {
        engagementId,
        recruiterId,
        employerId,
        jobId,
        candidateId,
        accountId,
        messageTypes
      }
    ).catch(f =>
      Core.showError(`Failure on fetching rendered template: ${f}`)
    ) || {}
  );
  const { errors = [], rendered = {}, mixins = {} } = response;
  setRenderedTemplate({ errors, rendered, mixins });
  return response;
}

async function getEnvelope({
  recruiterId,
  employerId,
  jobId,
  candidateId,
  messageTypes = [],
  setRenderedTemplate = ({ errors = [], rendered = {}, mixins = {} }) => { }
}) {

  let templateId = (await TemplateLib.getBy({
    type: 'email',

    /** 
     * @todo 
     * 
     * expand it fo other envelopes or create an specific function and or endpoint for it. 
     *
     * story-3927-M17
     * 2021-09-09 Thu µ 
     *
     */
    // this is using an exiting template, it's just in case we just want the job envelope
    name: 'job-todo-reminder'

  })).id;

  const response = (
    await Http.post(
      Core.getApi(`Templates/${templateId}/render`),
      { recruiterId, employerId, jobId, messageTypes }
    ).catch(f =>
      Core.showError(`Failure on fetching rendered template: ${f}`)
    ) || {}
  );
  const { errors = [], rendered = {}, mixins = {} } = response;
  setRenderedTemplate({ errors, rendered, mixins });
  return response;

}

async function getBy({ type = '', name = '', setTemplate = () => { } }) {
  const templates = await TemplateLib.getWhere(
    {
      type,
      name
    },
    null,
    true
  ).catch(f => Core.showError(`Failure on fetching template: ${f}`));
  if (templates && templates[0]) {
    const template = templates[0];
    setTemplate(template);
    return template;
  }
  else {
    const template = getTemplateModel();
    setTemplate(template);
    return template;
  }
}

async function upsert({ template = getTemplateModel(), type = '', name = '', setTemplate = () => { } }) {
  if (template.id) {
    await TemplateLib.update(
      template.id,
      template
    ).catch(f => Core.showError(`Failure on updating template: ${f}`));
  }
  else {
    const _template = await TemplateLib.post({
      ...template,
      type,
      name
    }).catch(f => Core.showError(`Failure on creating template: ${f}`));
    _template && setTemplate(_template);
  }
}

/* DICTIONARIES =============================== */

const TemplateLib = {
  getAll,
  getWhere,
  get,
  post,
  update,
  getList: success => {
    Http.get(Core.getApi("Templates"), success);
  },
  delete: (templateId, success) => {
    Http.delete(Core.getApi("Templates/" + templateId), success);
  },
  getRender,
  getEnvelope,
  getBy,
  upsert,
};

/* EXPORTS ==================================== */

export default TemplateLib;

/* ============================================ */
