import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache
} from "@apollo/client";
import {
  setContext
} from "@apollo/client/link/context";
import moment from 'moment';
import React from "react";
import Fieldset from '../../../components/Layout/Wrappers/Fieldset';
import {
  GITHUB_API_URL,
  GITHUB_TOKEN
} from "../../Constants";
import Core from "../../Core";
import Definition, {
  NEGATIVE_SIGNALS__CODDING_BOOTCAMP,
  POSITIVE_SIGNALS__GITHUB,
  POSITIVE_SIGNALS__GREAT_GITHUB,
  POSITIVE_SIGNALS__IMPRESSIVE_GITHUB, POSITIVE_SIGNALS__STRONG_GITHUB
} from '../../Definition';
import Http from "../../Http";
import {
  numberSuffixFormat
} from "../../tools/numberSuffixFormat.tool";
import calculateRank, {
  GH_RANK__A_P_PLUS,
  GH_RANK__A_PLUS,
  GH_RANK__B_PLUS,
  GH_RANK__S,
  GH_RANK__S_PLUS
} from "./calculateRank.lib";

/* CONSTANTS ================================== */

const DEFAULT_MIN_VALUE = 5;

const httpLink = createHttpLink({
  uri: GITHUB_API_URL,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${GITHUB_TOKEN}`,
    },
  };
});

/* SETTINGS =================================== */

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

/* METHODS ==================================== */

const getGithubUsernameFromUrl = (githubUrl) => {
  let username = "";
  if (!githubUrl) {
    return false;
  }

  if (/github.io/i.test(githubUrl)) {
    let choppedUrl = githubUrl.match(/.+\.github/g);
    if (choppedUrl) {
      username = choppedUrl.pop().split(".")[0];
    }
  } else if (/github.com/g.test(githubUrl)) {
    let choppedUrl = githubUrl.match(/.com\/.+/g);
    if (choppedUrl) {
      username = choppedUrl.pop().split("/")[1];
    }
  } else {
    return false;
  }
  username = username.replace(/http(s)?:\/\//i, '');
  return username;
};

function minValue(value) {
  return value >= DEFAULT_MIN_VALUE ? value : null;
}

/**
 * GitHub Stats Row
 *
 * @param {object} props
 * @param {object} props.gitHubStats
 * @param {string} props.gitHubURL
 * @returns {React.Component} containedGithubStats
 */
function getSingleRowHtml({
  gitHubStats = {},
  gitHubURL = '',
  candidate = {}
}) {

  let {
    totalCommits,
    lastYearContributions,
    followers = 0,
    stargazers,
    stars = 0,
    repos = 0,
    prs = 0,
    issues = 0,
    rank = {}
  } = gitHubStats;

  const {
    level: rankLevel = '',
    score: rankScore = 0
  } = rank;

  const gitHubTagLabel = getGitHubTag({ gitHubStats, candidate }).label;
  lastYearContributions = minValue(lastYearContributions);
  followers = minValue(followers);
  stargazers = minValue(stargazers);
  stars = minValue(stars);
  repos = minValue(repos);
  prs = minValue(prs);
  issues = minValue(issues);
  const item = (tag, value) => value && (
    <>
      ,&nbsp;
      <div className='truncate nowrap'>
        <b>{tag}: </b>{numberSuffixFormat(value)}
      </div>
    </>
  );
  return (
    <Fieldset
      title='Github Stats'
    >
      <small className="c-black-medium d-flex flex-wrap">
        <div className='truncate nowrap'>
          <b>Contributions: </b>
          {numberSuffixFormat(totalCommits)}
          {lastYearContributions && (
            <>
              &nbsp;all-time&nbsp;
              ({numberSuffixFormat(lastYearContributions)} this year)
            </>
          )}
        </div>
        {item('Followers', followers)}
        {item('Stargazer', stargazers)}
        {item('Stars', stars)}
        {item('Repos', repos)}
        {item('PRs', prs)}
        {item('Issues', issues)}
        ,&nbsp;<div className='truncate nowrap'><b>Score: </b>{Number(rankScore).toFixed(2)}</div>
        ,&nbsp;<div className='truncate nowrap'><b>Level: </b>{rankLevel}{gitHubTagLabel ? `/${gitHubTagLabel}` : ''}</div>
      </small>
    </Fieldset>
  );
};

/**
 * Submission Notes
 *
 * GitHub Stats Line
 *
 * @param {object} props
 * @param {object} props.gitHubStats
 * @param {string} props.gitHubURL
 * @param {object} props.minValues
 *
 * @returns {React.Component}
 */
function getSubmissionNotesHtml({
  gitHubStats = {},
  gitHubURL = '',
  candidate = {}
}) {
  let {
    totalCommits,
    lastYearContributions,
    followers = 0,
    stargazers,
    stars = 0,
    repos = 0,
    prs = 0,
    issues = 0
  } = gitHubStats;
  lastYearContributions = minValue(lastYearContributions);
  followers = minValue(followers);
  stargazers = minValue(stargazers);
  stars = minValue(stars);
  repos = minValue(repos);
  prs = minValue(prs);
  issues = minValue(issues);
  const item = (tag, value) => value ? `, ${numberSuffixFormat(value)} ${tag}` : '';
  return (
    <>
      <a href={gitHubURL} rel="noreferrer" target="_blank">
        {gitHubURL.replace(/http(s*):\/\/|www\./, '')}
      </a>:&nbsp;
      {lastYearContributions
        ? `contributions: ${numberSuffixFormat(totalCommits)} all-time (${numberSuffixFormat(lastYearContributions)} this year)`
        : `${numberSuffixFormat(totalCommits)} contributions`}
      {item('followers', followers)}
      {item('stargazer', stargazers)}
      {item('stars', stars)}
      {item('repos', repos)}
      {item('PRs', prs)}
      {item('issues', issues)}
    </>
  );
};

/**
 * Get GitHub User's Commits | HTTP request
 *
 * Then returns updatedStats on success callback
 */
async function fetchTotalCommits({ stats, token }) {
  return Http.get(
    `https://api.github.com/search/commits?q=author:${stats.username}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.github.cloak-preview",
        Authorization: `bearer ${token}`,
      },
    }).then(result => {
      const updatedStats = {
        ...stats,
        totalCommits: +result.total_count + +stats.privateCommits,
      };
      // console.debug('updatedStats', stats, updatedStats);
      return updatedStats;
    });
};

/**
 * Get GitHub User | GQL query
 */
async function githubGraphqlGetUser({ gitHubURL, username, candidate }) {
  username = username || getGithubUsernameFromUrl(gitHubURL);
  if (username) {
    return client.query({
      query: gql`
        query userInfo($login: String!, $now: DateTime!, $oneYearAgo: DateTime!) {
          user(login: $login) {
            id
            name
            login
            contributionsCollection(from: $oneYearAgo, to: $now) {
              totalCommitContributions
              restrictedContributionsCount
              contributionCalendar {
                totalContributions
              }
            }
            repositoriesContributedTo(
              first: 1
              contributionTypes: [COMMIT, ISSUE, PULL_REQUEST, REPOSITORY]
            ) {
              totalCount
            }
            pullRequests(first: 1) {
              totalCount
            }
            issues(first: 1) {
              totalCount
            }
            followers {
              totalCount
            }
            starredRepositories {
              totalCount
            }
            repositories(
              ownerAffiliations: OWNER
              first: 100
              orderBy: {field: STARGAZERS, direction: DESC}
            ) {
              totalCount
              nodes {
                id
                stargazerCount
                createdAt
                nameWithOwner
              }
            }
          }
        }
      `,
      variables: {
        login: username,
        now: moment().toISOString(),
        oneYearAgo: moment().subtract(372, 'days').toISOString()
      },
    }).catch(ex => {
      let message = `git-hub > gql - failure: ${ex}`;
      console.debug(message);
      let errorMessage = (ex.graphQLErrors || [])[0]?.message;
      if (errorMessage) {
        console.debug(`GitHub: ${errorMessage}`);
      }
      else {
        Core.failure({
          source: 'githubGraphqlGetUser.js(service):catch',
          exception: ex,
          params: { githubUrl: gitHubURL, username },
          options: { level: 'critical' },
          omitUIMessage: true
        });
      }
    }).then(result => {
      if (!result) { return; }
      const user = result.data.user;
      const stargazers = [0, ...user.repositories.nodes.map(node => node.stargazerCount)].reduce((previousValue, currentValue) => previousValue + currentValue);
      const stars = user.starredRepositories.totalCount;
      const totalCommits = user.contributionsCollection.totalCommitContributions || 0;
      const lastYearContributions = user.contributionsCollection.contributionCalendar.totalContributions || 0;
      const repos = user.repositories.totalCount || 0;
      const followers = user.followers.totalCount || 0;
      const privateCommits = user.contributionsCollection.restrictedContributionsCount || 0;
      const contributedTo = user.repositoriesContributedTo.totalCount || 0;
      const prs = user.pullRequests.totalCount || 0;
      const issues = user.issues.totalCount || 0;
      const rank = calculateRank({
        totalRepos: repos,
        totalCommits,
        contributions: contributedTo,
        followers,
        prs,
        issues,
        stargazers,
      });
      const stats = {
        stargazers,
        stars,
        totalCommits,
        lastYearContributions,
        repos,
        followers,
        username,
        privateCommits,
        contributedTo,
        prs,
        issues,
        rank,
      };
      return fetchTotalCommits({ stats, token: GITHUB_TOKEN }).then(statsWithTotalCommits => {
        return {
          ...statsWithTotalCommits,
          singleRowHtml: getSingleRowHtml({
            gitHubStats: statsWithTotalCommits,
            gitHubURL,
            candidate
          }),
          submissionNotesHtml: getSubmissionNotesHtml({
            gitHubStats: statsWithTotalCommits,
            gitHubURL,
            candidate
          }),
        };
      });
    });
  }
};

/* EXPORTS ==================================== */

export default githubGraphqlGetUser;

export function getGitHubTag({ gitHubStats = {}, candidate = {} }) {
  let id = null;
  let level = gitHubStats.rank?.level;
  let {
    negativeSignals = [],
    yearsOfExperience: relevantYearsOfExperience
  } = candidate;
  if (level === GH_RANK__S_PLUS || level === GH_RANK__S) {
    id = POSITIVE_SIGNALS__IMPRESSIVE_GITHUB;
  }
  else if (level === GH_RANK__A_P_PLUS) {
    id = POSITIVE_SIGNALS__GREAT_GITHUB;
  }
  else if (level === GH_RANK__A_PLUS) {
    id = POSITIVE_SIGNALS__STRONG_GITHUB;
  }
  else if (level === GH_RANK__B_PLUS) {
    id = POSITIVE_SIGNALS__GITHUB;
  }
  if (!!candidate.id && level === GH_RANK__A_PLUS) {
    id = (
      (
        negativeSignals.includes(NEGATIVE_SIGNALS__CODDING_BOOTCAMP) &&
        (relevantYearsOfExperience < 3)
      )
        ? POSITIVE_SIGNALS__GITHUB
        : POSITIVE_SIGNALS__STRONG_GITHUB
    );
  }
  let tag = Definition.getTag({ categoryKey: 'positiveSignals', tagId: id });
  console.debug('getCandidateGitHubTagId', level, tag);
  return tag;
}
