import {
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_PAID
} from '../../dictionaries/Engagement.dic';

export const AccountEmployerDetailsForCSVQuery = {
  fields: [
    'id',
    'firstName',
    'lastName',
    'title',
    'announcements',
    'email',
    'phone',
    'state',
    'role',
    'linkedInUrl',
    'calendarBookingLink',
    'createdAt',
    'companyName',
    'leftCompanyFlag',
    'newCompanyName'
  ],
  include: {
    relation: 'employers',
    scope: {
      fields: [
        'id',
        'name',
        'url',
        'careersPage',
        'primaryContactLinkedin',
        'stage',
        'crunchbaseUrl',
        'notables',
        'employerName',
      ],
      include: {
        relation: 'jobs',
        scope: {
          fields: [
            'id',
            'jobTitle'
          ],
          include: {
            relation: 'engagements',
            scope: {
              fields: [
                'id',
                'candidateId',
                'stage'
              ],
              where: {
                stage: {
                  inq: [
                    STAGE_OFFER,
                    STAGE_GUARANTEE,
                    STAGE_HIRE,
                    STAGE_PAID
                  ]
                }
              },
              include: {
                relation: 'candidate',
                scope: {
                  fields: [
                    'id',
                    'firstName',
                    'lastName'
                  ]
                }
              }
            }
          }
        }
      }
    }
  }
};
