import {
  useState
} from 'react';
import Core from '../../../lib/Core';
import {
  saveEmpAtsUrl
} from '../../../lib/Engagement';
import {
  getEmpAtsUrl
} from '../../../lib/models/engagement';
import {
  EditableLink
} from '../../Layout/Wrappers/EditableLink';
import Table from '../../Layout/Wrappers/Table';

export function Row2ColN({ engagement }) {
  const [, reRender] = useState();
  return (
    <Table.Cell
      className="cursor-default p-0"
      colSpan={9}
    >
      <br />
      {(Core.isAdmin() || Core.isEmployer()) && (
        <EditableLink
          className='w-50'
          value={getEmpAtsUrl(engagement)}
          onChange={async value => {
            await saveEmpAtsUrl(engagement)(value);
            await reRender({ __renderedAt: Date.now() });
          }}
        >
          ATS URL
        </EditableLink>
      )}
    </Table.Cell>
  );
}
