/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:add:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__ADD = 'add';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:cancel:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__CANCEL = 'cancel';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:clear:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__CLEAR = 'clear';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:close:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__CLOSE = 'close';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:mode_edit:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__EDITOR_MODE = 'mode_edit';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:highlight_off:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__HIGHLIGHT_OFF = 'highlight_off';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:help_outline:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__QUESTION_MARK = 'help_outline';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:more_vert:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__MORE_VERT = 'more_vert';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:open_in_browser:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__OPEN_IN_BROWSER = 'open_in_browser';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:save:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__SAVE = 'save';

/** @see https://fonts.google.com/icons?selected=Material+Icons+Outlined:visibility:&amp;icon.style=Filled */
export const MATERIAL_ICON_NAME__EYE = 'visibility';

export const MATERIAL_ICON__ACTION_HOME = <i className='material-icons'>action_home</i>;
export const MATERIAL_ICON__EXIT_TO_APP = <i className='material-icons'>exit_to_app</i>;
export const MATERIAL_ICON__FACE = <i className='material-icons'>face</i>;
export const MATERIAL_ICON__SUPERVISOR_ACCOUNT = <i className='material-icons'>supervisor_account</i>;
export const MATERIAL_ICON__BUSINESS = <i className='material-icons'>business</i>;
export const MATERIAL_ICON__SYNC = <i className='material-icons'>sync</i>;
export const MATERIAL_ICON__BUSINESS_CENTER = <i className='material-icons'>business_center</i>;
export const MATERIAL_ICON__INSERT_CHART_OUTLINED = <i className="material-icons-block">insert_chart_outlined</i>;
export const MATERIAL_ICON__SETTINGS = <i className="material-icons">settings</i>;
