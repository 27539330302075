import {
  debounce
} from 'lodash';
import {
  join
} from '../../../lib/Array.lib';
import {
  fetchCandidateResumePdfFile,
  getLocalCandidateResumePdfUrl
} from './Libraries/CandidateResumePdf.lib';
import {
  fetchCandidateResumePdfHighlightsFile,
  getLocalUrlCandidateResumePdfHighlights
} from './Libraries/CandidateResumePdfHighlights.lib';
import {
  fetchTagLabelEmployerSkills
} from './Libraries/EmployerSkills.lib';
import getRemoteCandidateResumePdfUrl from './Libraries/getRemoteCandidateResumePdfUrl.fun';
import {
  fetchTagLabelJobSkills
} from './Libraries/JobSkills.lib';
import Core from '../../../lib/Core';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  getShortURL,
  NOT,
  validateURL
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  joinKeyName,
  Str,
  trim
} from '../../../lib/String.lib';
import formatURL from '../../../lib/tools/formatURL';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import {
  Html
} from '../../Layout/Wrappers/Html';
import Icon from '../../Layout/Wrappers/Icon';
import ResumeMatch from '../../Shared/ResumeMatch';

export default function CandidateDocumentPreview({
  onLoad = Fun,
  candidate = {},
  jobId = '',
  employerId = '',
  ...props
}) {
  const [state, updateState] = useState({
    source: 'resume'
  });
  const {
    prev = {},
    busy = false,
    ready = false
  } = state;
  const {
    resumeTxtUrl,
    htmlResumeUrl,
    gitHubURL,
    linkedInURL,
    stackoverflowUrl,
    otherLinks = '',
  } = candidate;
  const resumeFiles = [
    {
      key: 'linkedin',
      url: formatURL(linkedInURL),
      type: 'SITE',
      label: <>LinkedIn</>
    },
    {
      key: 'resume',
      url: getLocalCandidateResumePdfUrl(candidate),
      type: 'PDF',
      label: 'PDF'
    },
    {
      key: 'highlights',
      url: getLocalUrlCandidateResumePdfHighlights({
        candidate,
        jobId,
        employerId
      }),
      type: 'PDF',
      label: 'Highlights'
    },
    {
      key: 'text',
      url: formatURL(resumeTxtUrl),
      type: 'TXT',
      label: 'Skill Text'
    },
    {
      key: 'html',
      url: formatURL(htmlResumeUrl),
      type: 'HTML',
      label: 'HTML'
    },
    {
      key: 'github',
      url: formatURL(gitHubURL),
      type: 'SITE',
      label: 'GitHub'
    },
    {
      key: 'stack_overflow',
      url: formatURL(stackoverflowUrl),
      type: 'SITE',
      label: 'Stack overflow'
    },
    [
      ...String(otherLinks || '')
        .split(/\n| - |\s|(,|;|\|)\s?/)
        .map((url, index) => ({
          key: joinKeyName(['site', index]),
          url: formatURL(url),
          type: 'SITE',
          label: getShortURL(url)
        }))
        .filter(({ url }) => validateURL(url))
    ][0]
  ].filter(
    (resume) => (
      !!resume &&
      (
        (resume.key === 'highlights') ||
        !!resume.url
      )
    )
  );
  const localUrlCandidateResumePdf = Str(
    ['resume', 'highlights'].includes(state.source) &&
    Obj(
      resumeFiles.find(
        ({ key }) => (key === state.source)
      )
    ).url
  );
  debounce(async () => {
    const url = getRemoteCandidateResumePdfUrl(candidate);
    if (NOT(url)) { return; }
    if (
      (url !== prev) ||
      NOT(busy || ready)
    ) {
      await updateState({
        busy: true,
        prev: url,
        loading: true,
        error: false,
        source: 'resume'
      });
      fetchCandidateResumePdfFile(
        candidate
      ).then(() => {
        onLoad();
      });
      fetchCandidateResumePdfHighlightsFile(
        {
          candidate,
          jobId,
          employerId
        }
      ).then(
        () => updateState({ loading: false })
      ).catch(
        (error) => {
          updateState({ loading: false, error });
        }
      );
      await updateState({
        jobSkills: join(await fetchTagLabelJobSkills({ jobId })),
        employerSkills: join(await fetchTagLabelEmployerSkills({ employerId }))
      });
      await updateState({
        busy: false,
        ready: true
      });
    }
  })();
  console.debug(
    'CandidateDocumentPreview',
    state
  );
  return (
    <Box column h100 w100 role="CandidateDocumentPreview">
      <Box
        row
        w100
        scrollX
        noWrap
        alignCenter
        role="CandidateDocumentPreview__Header"
        className="p-1"
        style={{ minHeight: 42 }}
      >
        {(() => {
          return resumeFiles.map((resume, index) =>
            (
              resume.type === 'TXT'
            ) ? (
              <ResumeMatch
                key={joinKeyName(['document_preview', index, resume.key])}
                candidateResume={resume.url}
                jobSkills={Str(state.jobSkills)}
                employerSkills={Str(state.employerSkills)}
                element={(cb) => {
                  return (
                    <Button
                      small
                      mr1
                      minW80
                      variant={
                        (state.source === resume.key)
                          ? 'outlined'
                          : 'standard'
                      }
                      className="p-0 px-1"
                      onClick={(ev) => cb()}
                    >
                      {resume.label}
                    </Button>
                  );
                }}
                onClick={(resumeTXT) => {
                  updateState({
                    source: resume.key,
                    resumeTXT
                  });
                }}
              />
            ) : (
              <Button
                small
                mr1
                minW80
                key={joinKeyName(['document_preview', index, resume.key])}
                variant={
                  (state.source === resume.key)
                    ? 'outlined'
                    : 'standard'
                }
                className="p-0 px-1"
                onClick={async (event) => {
                  if (resume.type === 'SITE') {
                    Core.openPopUp(resume.url);
                  }
                  else if (resume.key === 'html') {
                    const onSuccess = (result) => {
                      updateState({ source: resume.key, resumeHTML: result });
                    };
                    function getText() {
                      // read text from URL location
                      var request = new XMLHttpRequest();
                      request.open('GET', resume.url, true);
                      request.send(null);
                      request.onreadystatechange = function () {
                        if (
                          request.readyState === 4 &&
                          request.status === 200
                        ) {
                          var type = request.getResponseHeader('Content-Type');
                          if (type.indexOf('text') !== 1) {
                            onSuccess(request.responseText);
                            return request.responseText;
                          }
                        }
                      };
                    }
                    getText();
                  }
                  else {
                    updateState({ source: resume.key });
                  }
                }}
                disabled={
                  (resume.key === 'highlights') &&
                  (state.loading || state.error)
                }
              >
                {resume.label}
                {
                  (resume.key === 'highlights') &&
                  (state.loading || state.error) &&
                  (
                    <Icon outlined
                      icon={state.loading ? 'hourglass_empty' : 'error'}
                      className='icon16'
                      title={
                        trim(Str(state.error).split(':').pop()) ||
                        (state.loading && 'Loading...')
                      }
                    />
                  )
                }
              </Button>
            )
          );
        })()}
      </Box>
      <Box
        column
        h100
        scrollY
        role="CandidateDocumentPreview__Content"
        className="flex-align-left-top"
      >
        {(state.source === 'text') && state.resumeTXT &&
          <Html column={false}
            role="CandidateDocumentPreview__TXT"
            className="flex-wrap flex-align-left-top break-all p-2"
          >
            {state.resumeTXT}
          </Html>
        }
        {(state.source === 'html') && state.resumeHTML &&
          <Html
            role="CandidateDocumentPreview__HTML"
            className="flex-wrap flex-align-left-top break-all p-2"
          >
            {state.resumeHTML}
          </Html>
        }
        {localUrlCandidateResumePdf &&
          <iframe
            title='candidate_document_preview__iframe'
            src={`${localUrlCandidateResumePdf}#view=fitH&toolbar=1`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="h-100"
          />
        }
      </Box>
    </Box>
  );
}
