import {
  NOT,
  getShortURL
} from './GenericTools.lib';
import {
  Str,
  trim
} from './String.lib';
import {
  getLocation
} from './URL.lib';

export function getURL(key) {
  return String(getLocation() || '');
}

export function getVariableFromURLQuery(key) {
  return new URLSearchParams(getURL().split('?').pop() || '').get(key);
}

export function updateURLQuery(update) {
  document.location.assign(setURLQueryString({ url: getURL(), update }));
}

/**
 * 
 * @param {string} options.url -string where setting the URL query
 * @param {string} options.update -dictionary of variables to in the URL query
 * @param {string} options.exact -if false, will ignore falsable values
 * @returns 
 */
export function setURLQueryString({ url = '', update = {}, exact = true }) {
  url = String(url || '').split('?');
  let path = (url.shift() || '');
  let query = new URLSearchParams(url.shift() || '');
  Object.entries(update).forEach(([key, value]) => {
    if (!value && (exact === false)) { return; }
    query.set(key, value);
  });
  return (`${String(path)}?${query.toString()}`);
}

// For debug purposes
window.updateURLQuery = updateURLQuery;

export function getExternalURL(url) {
  return (NOT(Str(url).match(/^.{3,5}:\/\//)) ? `https://${trim(url)}` : trim(url));
}

export function getMainDomainName({ url, formatter = value => value }) {
  let mainDomain = getShortURL(url).split('.');
  return formatter(mainDomain.length >= 3 ? mainDomain[1] : mainDomain[0]);
}
