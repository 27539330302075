import moment from 'moment';
import React from "react";
import {
  Arr
} from '../../lib/Array.lib';
import {
  US_DATE_FORMAT
} from '../../lib/Constants';
import Core from "../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_JOB,
  STATE_ACTIVE
} from "../../lib/Definition";
import {
  getJob
} from '../../lib/Job';
import {
  ErrorChipMessage
} from '../Layout/Wrappers/Message';
import Divider from '../Layout/Wrappers/Divider';
import Button from '../Layout/Wrappers/Button';
import Menu from '../Layout/Wrappers/Menu';
import useState from '../../lib/hooks/useState.hook';
import {
  Dash
} from '../Shared/Dash';

export function EmployerJobsDropdown({
  employerId,
  confirmAction = (callback = () => null) => callback(),
  ...props
}) {
  let [state, _updateState] = useState();
  async function _fetchJobs() {
    try {
      await _updateState({ fetchingJobs: true, errorFetchingJobs: false });
      await _updateState({
        fetchingJobs: false,
        errorFetchingJobs: false,
        jobs: await getJob({ where: { employerId }, fields: ['id', 'jobTitle', 'state', 'createdAt'], order: 'state ASC' }),
      });
    }
    catch (error) {
      Core.showError(error);
      await _updateState({
        errorFetchingJobs: error
      });
    }
  }
  if (employerId && !state.jobs && !state.fetchingJobs && !state.errorFetchingJobs) {
    _fetchJobs();
  }
  const _jobs = Arr(state.jobs);
  return (
    <Menu
      {...props}
      name={`employer_jobs_dropdown`}
      acl={
        !!employerId &&
        !!_jobs.length &&
        (
          !Core.isAdmin() ||
          Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB })
        )
      }
      content={
        <>
          <div className='px-2 py-1' style={{ maxWidth: 480 }}>
            <ErrorChipMessage>
              Unsaved data will be lost
            </ErrorChipMessage>
            <div className='c-red f-sm mt-1'>
              If you have made any edits, please save it first or your edits will be lost when you switch to a different job
            </div>
          </div>
          <Divider />
        </>
      }
      options={_jobs}
      renderSelected={
        ({ selected, open }) => (
          <Button flat
            title='Select a job to go'
            startIcon={open ? 'expand_less' : 'expand_more'}
            className='min-w-80'
          >
            Jobs
          </Button>
        )
      }
      renderOption={
        ({ option: { id, jobTitle, state, createdAt } }) => (
          <div
            onClick={(event) => {
              confirmAction(() => {
                Core.go({ to: `/job/edit/${id}`, refresh: true });
              });
            }}
          >
            {jobTitle}
            <Dash />
            <span
              className={`f-sm ${(state === STATE_ACTIVE) ? `c-cyan` : `c-gray`}`}
            >
              {Definition.getLabel('state', state)}
            </span>
            {createdAt ? (
              <span className='f-sm c-black-medium'>
                <Dash />
                {moment(createdAt).format(US_DATE_FORMAT)}
              </span>
            ) : ''}
          </div>
        )
      }
    />
  );
}
