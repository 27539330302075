export function mapEngagementStateFilter({
  filters = {},
}) {

  let newFilters = { ...filters };

  let {
    state = [],
    rejectionReason = [],
  } = filters;

  if (Array.isArray(state) && state.includes('Closed') && !rejectionReason.includes('10x10 - No Match')) {
    newFilters["$and"] = newFilters["$and"] || [];
    newFilters["$and"].push({ rejectionReason: { $ne: '10x10 - No Match' } });
  }

  return newFilters;

}
