import {
  Card
} from '@mui/material';
import {
  useRef,
  useState
} from 'react';
import Core from '../../../lib/Core';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  getJobModel
} from '../../../lib/models/job';
import cleanHtml from '../../../lib/tools/cleanHtml';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Divider from '../../Layout/Wrappers/Divider';
import {
  Html
} from '../../Layout/Wrappers/Html';
import {
  WarningMessage
} from '../../Layout/Wrappers/Message';
import Typography from '../../Layout/Wrappers/Typography';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import getCandidateNotes from '../SubmissionNotes.lib';

export const CANDIDATE_NOTES__DICTIONARY_KEY = 'CandidateNotes__dictionary';

export function CandidateNotes(props) {
  const timeout = useRef();
  let { candidate, job, wrapper = false, className, refresh } = props;
  const [prev, setPrev] = useState(refresh);
  let [candidateNotes = '', setCandidateNotes] = useState();
  candidateNotes = cleanHtml(candidateNotes).replace(/<\/ul><ul>/gi, '');
  Core.setKeyValue(
    CANDIDATE_NOTES__DICTIONARY_KEY,
    {
      ...Obj(Core.getKeyValue(CANDIDATE_NOTES__DICTIONARY_KEY)),
      [candidate.id]: candidateNotes
    }
  );
  clearTimeout(timeout.current);
  timeout.current = setTimeout(() => {
    if (!candidateNotes || (prev !== refresh)) {
      getCandidateNotes({
        candidate,
        job: job?.id ? job : getJobModel(),
        onLoad: candidateNotes => {
          setPrev(refresh);
          setCandidateNotes(candidateNotes);
        },
        disclaimer: false,
      });
    }
  }, 1000);
  // console.debug('CandidateNotes', candidate, candidateNotes);
  const _onCopy = event => {
    event.preventDefault();
    Core.showError('Copy is not allowed');
  };
  const hasCandidateNotes = !!cleanHtml(candidateNotes).replace(/<ul><\/ul>/gi, '').length;
  const content = (
    <Html
      value={candidateNotes}
      className={joinClassName([
        'w-unset my-1 f-md fw-400 c-black-dark inside-ul-m-0',
        className
      ])}
      onCopy={!Core.isAdmin() ? _onCopy : undefined}
    />
  );
  return (
    !hasCandidateNotes ? (
      <WarningMessage style={{ minWidth: 256 }}>
        {CANDIDATE_UI.CandidateNotes.empty}
      </WarningMessage>
    ) : wrapper
      ? (
        <Card
          role='CandidateNotesCard'
          className='mb-05'
        >
          <Typography strong large alignCenter blackDark
            className='m-1'
          >
            {CANDIDATE_UI.CandidateNotes.title}
          </Typography>
          <Divider />
          {content}
        </Card>
      )
      : content
  );
}

export default CandidateNotes;
