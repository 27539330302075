import {
  NOT
} from '../../../../../../../lib/Boolean.lib';
import Definition, {
  DEFINITION_CATEGORY__VISA
} from '../../../../../../../lib/Definition';
import {
  joinKeyName,
  Str
} from '../../../../../../../lib/String.lib';
import {
  IS_GREEN,
  IsYearsOfExperienceGreen
} from '../../../../../../../lib/YearOfExperienceColor';
import MatchLocationChips, {
  MATCH_LOCATION_CHIPS__JD_REQUESTER
} from '../../../../../../Match/List/MatchLocationChips';
import evalVisaMatch from './evalVisaMatch.fun';
import getLocationMatches from './getLocationMatches.fun';
import MatchProperty from './MatchProperty';

export default function MatchProperties({
  job = {},
  candidate = {},
  ...props
}) {
  if (NOT(job.id || candidate.id)) { return ''; }
  return (
    [
      {
        label: 'Experience',
        chipLabel: `${job.minYearsOfExperience} yrs`,
        evalMatch: () => (
          IsYearsOfExperienceGreen(
            job,
            candidate
          ) === IS_GREEN
        )
      },
      {
        label: 'Location',
        evalMatch: () => getLocationMatches({
          job,
          candidate
        }).yes.length,
        chipRender: () => (
          <MatchLocationChips
            requester={MATCH_LOCATION_CHIPS__JD_REQUESTER}
            candidate={candidate}
            job={job}
            slim={false}
          />
        )
      },
      {
        label: 'Visa',
        chipLabel: Definition.getLabel(
          DEFINITION_CATEGORY__VISA,
          job.visaTransfer
        ),
        evalMatch: () => evalVisaMatch({
          job,
          candidate
        })
      },
      {
        label: 'Salary',
        chipLabel: `<$${Str(job.salaryMax)}`,
        evalMatch: () => (
          candidate.minimumSalary <= job.salaryMax
        )
      }
    ].map((options) => (
      <MatchProperty
        {...options}
        key={joinKeyName([
          'job_requirements',
          'match_property',
          options.label
        ])}
      />
    ))
  );

}
