import Core from '../../lib/Core';
import { Obj } from '../../lib/Object.lib';
import { mapCandidate } from '../../lib/models/candidate';
import mapCandidateSignals from './CandidateSignalTags.lib';

/**
 * This promise will receive a new set of signals, 
 * then will add or remove signals from the candidate,
 * then it would call the updater method if there is some update.
 * Also this promise returns an update object.
 * 
 * @param {object} options 
 * @param {object} options.key
 * @param {object} options.candidate
 * @param {object} options.updater
 * @param {object} options.signals
 * @returns 
 */
export default async function mapCandidateSignalsOnChange({
  key = 'positiveSignals' || 'negativeSignals',
  candidate = mapCandidate({}),
  updater = () => null,
  signals = [],
}) {
  let update = false;
  let manualTags = `${key}Manual`;
  let automaticTags = `${key}Tags`;

  // CASE add signal
  signals.forEach(id => {
    if (!candidate[key].includes(id)) {
      update = true;
      candidate[manualTags].push(id);
    }
  });

  // CASE remove signal
  candidate[key].forEach(id => {
    if (!signals.includes(id)) {
      update = true;
      candidate[key] = candidate[manualTags].filter(_id => _id !== id);
      candidate[manualTags] = candidate[manualTags].filter(_id => _id !== id);
      const _filterSignal = (history) => (_id) => {
        if (_id !== id) { return true; }
        history.manuallyOverriddenAt = new Date().toISOString();
        history.manuallyOverriddenBy = Core.getUserId();
        return false;
      }
      candidate.employmentHistories = candidate.employmentHistories.map(employment => {
        employment[automaticTags] = (employment[automaticTags] || []).filter(_filterSignal(employment));
        return employment;
      });
      candidate.educationHistories = candidate.educationHistories.map(education => {
        education[automaticTags] = (education[automaticTags] || []).filter(_filterSignal(education));
        return education;
      });
    }
  });

  if (update) {
    update = {
      [manualTags]: candidate[manualTags],
      key: manualTags,
      employmentHistories: candidate.employmentHistories,
      educationHistories: candidate.educationHistories,
      __forceSignals: true
    };
    Object.assign(candidate, update);
    update = await mapCandidateSignals({ candidate });
    updater(update);
  }

  return Obj(update);
}
