import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  Obj
} from '../../../lib/Object.lib';
import Col from '../../Layout/Wrappers/Col';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';

export default function RejectionEmail({
  EngagementCardController,
  deliveryTo
}) {
  EngagementCardController.state.__rejectionEmail = Obj(
    EngagementCardController.state.__rejectionEmail
  );
  const [{
    subject = '',
    body = '',
  }, updateState] = useStateObserved({
    state: {
      subject: EngagementCardController.state.__rejectionEmail.subject,
    },
    observed: {
      body: EngagementCardController.state.__rejectionEmail.body,
    }
  });
  return (
    <Col className='p-0' fullWidth>
      <div className="d-flex flex-align-left-top" fullWidth>
        <label className='m-0 col1'>To:</label>&nbsp;
        <span className='c-black-medium mt-05'>{deliveryTo}</span>
      </div>
      <div className="d-flex flex-align-left-top" fullWidth>
        <label className='m-0 col1'>Subject:</label>&nbsp;
        <span className='c-black-medium mt-05'>
          {subject}
        </span>
      </div>
      <RichTextBox
        className='mt-1'
        value={body}
        onChange={async (value) => {
          const update = Object.assign(
            EngagementCardController.state.__rejectionEmail,
            { body: value }
          );
          await updateState(update);
          EngagementCardController.setState(update);
        }}
        taller
      />
    </Col>
  );
}
