import {
  Component
} from "react";
import {
  join
} from '../../lib/Array.lib';
import Candidate from "../../lib/Candidate";
import {
  accountIncludeForCandidate,
  fieldsForCandidatePage
} from '../../lib/Constants';
import Core from "../../lib/Core";
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE
} from '../../lib/Definition';
import Job from "../../lib/Job";
import {
  THEME__COLOR__SECONDARY
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import FloatingActionButton from '../Layout/Wrappers/FloatingActionButton';
import Link from '../Layout/Wrappers/Link';
import NavLink from '../Layout/Wrappers/NavLink';
import {
  Label
} from '../Layout/Wrappers/Typography';
import List from "../List/List";
import CandidateCard from "./Card/CandidateCard";
import ConflictDetector from "./ConflictDetector";

class CandoDuplicateDetails extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      candidateDuplicatesList: [],
      candidate: {},
      processingComplete: false
    }
  }

  componentDidMount() {
    const { candidateId = '' } = this.props;
    Candidate.get(candidateId, (candidate) => {
      this.setState({ candidate }, () => {
        Candidate.getPotentialDuplicated(candidate, (result) => {
          Job.getWhere({
            id: {
              inq: candidate.jobsPermitted
            }
          }, (jobs) => {
            candidate._jobsPermitted = jobs;
            new ConflictDetector(candidate, result, (detectorResult) => {
              console.log({ detectorResult });
              let allJobIds = [];

              result.forEach(element => {
                const duplicated = (detectorResult.matchingPool || []).find(el => String(el.id) === String(element.id));
                if (!!duplicated) {
                  console.log({ duplicated });
                  element = { ...element, ...duplicated };
                }
                allJobIds = [...allJobIds, ...element.jobsPermitted];
              });

              const params = {
                include: [
                  ...accountIncludeForCandidate,
                  // { engagements: { job: "employer" } },    // not required for card information, on expand, it calls for engagements
                  {
                    relation: "candidateStarreds",
                    scope: {
                      where: { accountId: Core.getUserId() }
                    }
                  }
                ],
                fields: fieldsForCandidatePage,
                where: {
                  id: {
                    inq: result.map(cando => cando.id)
                  }
                }
              };

              //need to do this to fetch all required attrs of cando
              Candidate.getAll(candidates => {
                Job.getWhere(
                  {
                    id: {
                      inq: allJobIds
                    },
                  }, (allJobs) => {
                    const matchPoolCandoIds = detectorResult.matchingPool.map(
                      (candidate) => candidate.id
                    );
                    let compiledCandos = result.filter(
                      (candidate) => matchPoolCandoIds.includes(candidate.id)
                    ).map(
                      (candidate) => {
                        const jobsPermitted = allJobs.filter(
                          (job) => candidate.jobsPermitted.includes(String(job.id))
                        );
                        let candoDetails = candidates.find(
                          (current) => String(current.id) === String(candidate.id)
                        );
                        return {
                          ...candidate,
                          ...candoDetails,
                          _jobsPermitted: jobsPermitted,
                          jobsPermitted: candidate.jobsPermitted
                        };
                      }
                    );

                    setTimeout(() => {
                      this.List.setItems(compiledCandos);
                    });

                    this.setState({
                      candidateDuplicatesList: compiledCandos,
                      detectorResult, processingComplete: true
                    });

                  },
                  {
                    include: [
                      {
                        relation: 'employer',
                        scope: {
                          fields: ['id', 'name']
                        }
                      }
                    ],
                    fields: ['employerId', 'id', 'jobTitle']
                  }
                );
              }, params, true);
            });
          })

        })
      });
    })
  }

  render() {
    const {
      candidate = {},
      processingComplete = false
    } = this.state;
    return (
      <>

        <Box column hAuto w100
          role='ViewDups__Card'
          acl={!!candidate.id}
          className='px-3'
        >

          <CandidateBasicInfo candidate={candidate} />

          <CandidateCard
            source="candoDuplicateDetails"
            model={candidate}
            parent={this}
          />

        </Box>

        <Box column w100
          role='ViewDups__List'
          display={!!processingComplete}
          className='flex-align-left-top'
        >
          <List
            ref={self => !!self && (this.List = self)}
            parent={this}
            tabs={
              Core.isRecruiter()
                ? Candidate.listTabsRecruiters
                : Candidate.listTabs
            }
            tab={Candidate.listTab}
            name="Candidate"
            card={DuplicateCard}
            source={"duplicate-candidates"}
            floatingButton={
              (!Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })) && (
                <NavLink className="ui-m-max" to={`/candidate/create`}>
                  <FloatingActionButton
                    className="list-floating-button"
                    color={THEME__COLOR__SECONDARY}
                  />
                </NavLink>
              )
            }
          />
        </Box>

      </>
    );
  }
}

export default CandoDuplicateDetails;

const DuplicateCard = (props) => {
  const model = props.model;
  const permittedJobs = model._jobsPermitted.map(job => job._name);
  return (

    <Box column w100
      key={`dup-cando-${model.id}`}
    >

      <Box column w100>

        <Box row w100
          acl={!!model.isStrongConflict}
          className='py-05'
        >
          <Label>
            Strong Conflict on&nbsp;
            <mark>
              {join(model.attrsThatConflictedStrong)}
            </mark>
          </Label>
        </Box>

        <Box row w100
          acl={!!model.isWeakConflict && !model.isPotentialStrongConflict}
          className='py-05'
        >
          Weak Conflict on&nbsp;
          <mark>
            {join(model.attrsThatConflictedWeak)}
          </mark>
        </Box>

        <Box row w100
          acl={!!model.isPotentialStrongConflict && !model.isStrongConflict}
          className='py-05'
        >
          Potential Strong Conflict on&nbsp;
          <mark>
            {join(model.attrsThatConflictedStrong)}
          </mark>
        </Box>

        <Box row w100
          acl={!!model.jobsPermitted.length}
          className='py-05'
        >
          Permitted jobs:&nbsp;
          <mark>
            {join(permittedJobs)}
          </mark>
        </Box>

        <CandidateBasicInfo candidate={model} />

      </Box>

      <CandidateCard
        {...props}
        model={props.model}
        parent={props.parent} source="candoDuplicateDetails"
      />

    </Box>
  );
};

export function CandidateBasicInfo({
  candidate = {},
  ...props
}) {
  return (
    <Box row w100
      role='CandidateBasicInfo'
      className='my-1'
      {...props}
    >
      <Box acl={candidate.email} wAuto className='mr-1'>
        [ <Link href={`mailto:${candidate.email}`}>{candidate.email}</Link> ]
      </Box>
      <Box acl={candidate.phone} wAuto className='mr-1'>
        [ <Link href={`tel:${candidate.phone}`}>{candidate.phone}</Link> ]
      </Box>
      <Box acl={candidate.linkedInURL} wAuto className='mr-1'>
        [ <Link href={candidate.linkedInURL} /> ]
      </Box>
      <Box acl={candidate.gitHubURL} wAuto>
        <Link href={candidate.gitHubURL} />
      </Box>
    </Box>
  );
}