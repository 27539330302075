import Core from "../Core";
import { Str } from '../String.lib';
import {
  MODEL_NAME_ACCOUNT
} from './account';

export function mapStarred({ model, starredList = [] }) {

  // filtered STARRED list from server by current user
  model.starred = starredList.filter(
    ({
      accountId,
      userId
    }) => (
      (
        model.___model___ === MODEL_NAME_ACCOUNT
      ) ? (
        userId === Core.getUserId()
      ) : (
        accountId === Core.getUserId()
      )
    )
  ).sort(
    (a, b) => Str(
      a.updatedAt
    ).localeCompare(
      b.updatedAt
    )
  );

  // LAST STARRED
  model.starred = model.starred.length ? model.starred.pop() : {};

  // STARRED ID (string)
  model.starredId = model.starred.id || model.starred._id;

  // STARRED FLAG (boolean)
  model.starred = model.starred.starred;

  return model;
}
