import {
  compile
} from 'handlebars';
import Core from '../../Core';
import globalErrorHandler from '../../Error/globalErrorHandler.fun';
import {
  getPersonName,
  Obj
} from '../../Object.lib';
import {
  TEMPLATE__CANDIDATE__CAVE__BODY,
  TEMPLATE__CANDIDATE__CAVE__SUBJECT
} from '../../templates/CAVE/RepresentationEmailForCandidate.template';
import fetchRecipientsCAVE from './fetchRecipientsCAVE.fun';

export default async function generateCandidateCAVE({
  engagement = {}
}) {
  try {
    const candidate = Obj(engagement.candidate);
    const job = Obj(engagement.job);
    let jobList = [];
    jobList.push(
      [
        (!!job.jobDescriptionPublicURL?.trim()
          ? `<a href="${job.jobDescriptionPublicURL}" target="_blank"><b>${job.jobTitle}</b></a>`
          : job.jobTitle
        ),
        (!!job.employer.url
          ? `<a href="${job.employer.url}" target="_blank"><b>${job.employer.name}</b></a>`
          : job.employer.name
        )
      ].filter(n => !!n).join(' at ')
    );
    jobList = `<li>${jobList.filter(n => !!n).map(n => n.trim()).join('</li><li>')}</li>`;
    const recipients = await fetchRecipientsCAVE({
      candidateId: candidate.id,
      container: 'candidate'
    });
    const recipientsRecruiter = await fetchRecipientsCAVE({
      candidateId: candidate.id,
      container: 'recruiter'
    });
    const result = {
      from: recipients.from,
      emails: recipients.to,
      to: [...recipients.to, ...recipientsRecruiter.to],
      cc: recipientsRecruiter.cc,
      bcc: recipientsRecruiter.bcc,
      subject: compile(
        TEMPLATE__CANDIDATE__CAVE__SUBJECT
      )({
        CANDIDATE__FULLNAME: getPersonName(candidate),
        EMPLOYER: job.employer.name
      }),
      body: compile(
        TEMPLATE__CANDIDATE__CAVE__BODY
      )({
        CANDIDATE__NAME: (
          candidate.nickName ||
          candidate.firstName
        ),
        RECRUITER__NAME: (
          candidate.recruiter.talentAdvisor ||
          candidate.recruiter._name
        ),
        JOB_LIST: jobList,
        CANDIDATE__EMAIL: candidate.email || '?',
        CANDIDATE__PHONE: candidate.phone || '?',
        SIGNATURE: Core.getEmailSignature(),
        TEN_X_TEN__EMAIL_ADDRESS: `<a href="mailto:${process.env.REACT_APP_TEAM_EMAIL}">${process.env.REACT_APP_TEAM_EMAIL}</a>`
      })
    };
    return result;
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
