import React from "react";
import {
  Arr
} from '../../lib/Array.lib';
import {
  getPathname
} from '../../lib/URL.lib';
import NavLink from '../Layout/Wrappers/NavLink';

function TabBarOptions({ context }) {
  return (
    <div className="d-flex no-wrap overflow-auto">
      {
        Arr(context.config.tabBarOptions).map((option, index) => {
          const {
            label,
            to = ({ context }) => getPathname()
          } = option;
          return (
            <div key={`engagements-tab-bar-option-${index}`} className="flex-row">
              {(index === 0) ? '' : <span className='mx-1'>|</span>}
              <NavLink to={`${to({ context })}`} className='decoration-none'>{label}</NavLink>
            </div>
          );
        })
      }
    </div>
  );
}

export { TabBarOptions as default, TabBarOptions };
