import React, {
  Component,
  Fragment
} from "react";
import { Arr } from '../../../lib/Array.lib';
import Definition from "../../../lib/Definition";
import {
  matchSkillWithText
} from '../../Candidates/SubmissionNotes.lib';
import {
  getCategoryKeys
} from "../../../lib/tools/getSpecificCategoryKey";
import Chip from '../../Layout/Wrappers/Chip';
import BasicChip from "../Haves/v1/Chip";
import Fieldset from '../../Layout/Wrappers/Fieldset';
import Typography from '../../Layout/Wrappers/Typography';

class MatchSkills extends Component {

  constructor() {
    super(...arguments);
    this.state = { candidateSkills: [], jobArgsTechnicalSkills: [], jobArgsJobTags: [] }
  }

  componentDidMount() {
    const { jobArgs } = this.props;
    const jobArgsTechnicalSkills = getCategoryKeys({ key: 'technicalSkills' }, jobArgs);
    const jobArgsJobTags = getCategoryKeys({ type: 'chipTag' }, jobArgs);
    this.setState({ jobArgsTechnicalSkills, jobArgsJobTags });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobArgs !== this.props.jobArgs) {
      const jobArgsTechnicalSkills = getCategoryKeys({ key: 'technicalSkills' }, this.props.jobArgs);
      const jobArgsJobTags = getCategoryKeys({ type: 'chipTag' }, this.props.jobArgs);
      this.setState({ jobArgsTechnicalSkills, jobArgsJobTags });
    }
  }

  processCandidateSkills = (candidateCV = "") => {
    const { candidate } = this.props;
    let structure = {};
    let candidateSkills = [];

    const allSkills = Definition.get('technicalSkills').map(el => el.label);

    allSkills.filter(fskill => !!fskill.length).forEach(skill => {
      structure = {};
      let sources = this.getCandidateSourceSkill(skill, { cv: candidateCV });

      if (!!sources.length) {
        structure.skillName = skill;
        structure.skillSource = sources
        structure.skillStrength = this.getCandidateSkillStrength(sources);
        structure.skillApproved = this.getCandidateSkillApprovedBy();
        structure.candidateId = candidate.id;

        candidateSkills.push(structure);
      }
    });

    this.setState({
      candidate: {
        ...candidate,
        candidateSkills
      },
      candidateSkills
    });
  }

  iterateCandidateSkills = () => {
    const { candidate } = this.props;

    if (candidate.resumeTxtUrl) {
      fetch(candidate.resumeTxtUrl).then(responseText => {
        responseText.text().then(text => {
          this.processCandidateSkills(text);
        });
      });
    } else {
      this.processCandidateSkills();
    }
  }

  getCandidateSkillApprovedBy = () => {
    const { candidate } = this.props;
    return candidate.recruiter.email;
  }

  getCandidateSkillStrength = (sources) => {
    let strength = "";

    if (sources.includes("10x10-approved")) {
      strength = "approved";
    } else if (sources.includes("10x10-write-up")) {
      strength = "strong";
    } else if (sources.includes("10x10-recruiter-write-up")) {
      strength = "strong";

    } else if (sources.includes("cv")) {
      strength = "may-be";
    }

    return strength;
  }

  getCandidateSourceSkill = (skill, attr) => {
    const { candidate } = this.props;
    let sourceSkills = [];
    let candidateTechSkills = Definition.getLabels('technicalSkills', candidate.technicalSkills);

    if (candidateTechSkills.includes(skill))
      sourceSkills.push("10x10-approved");
    if (this.matchSkillWithText(skill, candidate.submissionNotes))
      sourceSkills.push("10x10-write-up");
    if (this.matchSkillWithText(skill, candidate.submissionNotesToEmployer))
      sourceSkills.push("10x10-recruiter-write-up");
    if (!!attr.cv && attr.cv.length > 0 && this.matchSkillWithText(skill, attr.cv))
      sourceSkills.push("cv");

    return sourceSkills;
  }

  matchSkillWithText = (skill, text) => {
    let skillLabel = this.escapeRegExp(skill);
    const skillRegx = new RegExp(`\\b${skillLabel}\\b|^${skillLabel}\\b|^${skillLabel},\\w`, 'gi');

    return !!skillRegx.test(text);
  }

  escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  makeGrouping = (sourceObj) => {
    const { candidateSkills } = this.state;
    let skills = candidateSkills
      .filter((innerValue) => (innerValue.skillStrength === sourceObj.key))
      .map(el => el.skillName);

    if (skills.length === 0) return null;
    return (
      <Fieldset
        title={sourceObj.label}
      >
        {
          skills.map(
            (label, index) => {
              return (
                <Chip
                  key={index}
                  className={"chip slim-chip ui-match-skills-make-grouping-chip"}
                  variant="outlined"
                  size="small"
                  label={label}
                  style={{
                    color: "#454545"
                  }}
                />
              )
            }
          )
        }
      </Fieldset>
    );
  }

  getCandidateTechSKillIds = (source, candidate) => {
    if (source.key === 'techSkillsInNotes') {
      return Arr(candidate._strongTechnicalSkills).filter(el => !!el);
    }
    return Arr(candidate[source.key]).filter(el => !!el);
  }

  jobTagsMatchWithLabels = (jobArgsJobTags, label) => {
    return jobArgsJobTags.filter(arg => {
      if (arg.length > label.length) {
        return !!matchSkillWithText(label, arg);
      }
      return !!matchSkillWithText(arg, label);
    }).length > 0;
  }

  makeGroupings = (sourceObj) => {
    const { candidate, source, deleteIcon, onDelete } = this.props;
    const { jobArgsTechnicalSkills, jobArgsJobTags } = this.state;

    const ids = this.getCandidateTechSKillIds(sourceObj, candidate);

    return (
      <Fieldset
        title={
          <>
            <strong className='mr-05'>{sourceObj.label}</strong>
            <Typography sub>
            {sourceObj.subLabel}
            </Typography>
          </>
        }
      >
        {ids.map((id, index) => {
          const label = Definition.getLabel('technicalSkills', id);
          const chipObj = { key: 'technicalSkills', value: id };
          const toMatchObj = { technicalSkills: jobArgsTechnicalSkills };
          const isMatchWithLabel = sourceObj.key === "techSkillsInResume" ?
            this.jobTagsMatchWithLabels(jobArgsJobTags, label) : false;
          const isChipMatched = BasicChip.isChipMatched(chipObj, toMatchObj) || isMatchWithLabel;

          const optionalProps = (deleteIcon && onDelete) ? {
            deleteIcon: <i className='material-icons'>add</i>,
            onDelete: ((candidate, sourceAttr, chipId) => () => onDelete(candidate, sourceAttr, chipId))(candidate, sourceObj.key, id)
          } : {};

          if (!!label) {
            return (
              <Chip
                key={index}
                className={"chip slim-chip ui-match-skills-chip"}
                variant="outlined"
                size="small"
                label={label}
                style={{
                  color: "#454545",
                  border: !!isChipMatched ? "1px solid green" : (source === 'candidateMatch') ? "1px solid rgba(0, 0, 0, 0.23)" : "2px solid red"
                }}
                {...optionalProps}
              />
            );
          }
          return null;
        })}
      </Fieldset>
    );

  }


  render() {

    const { candidate = {} } = this.props;

    return (
      <>
        {!!candidate.id && [
          {
            key: "techSkillsInNotes",
            label: "STRONG SKILL CHIP",
            subLabel: " (from LinkedIn,RecruiterWriteup,SubmissionNoteEmployer)"
          },
          {
            key: "techSkillsInResume",
            label: "MAY BE SKILL CHIP",
            subLabel: "(From CV)"
          }
        ].map((sourceObj, key) => {

          return (
            <Fragment key={key}>
              <div>{this.makeGroupings(sourceObj)}</div>
            </Fragment>)
        })}
      </>
    );
  }
}

export default MatchSkills;