import React, {
  Component
} from "react";
import Box from '../../../../../Layout/Wrappers/Box';

class OperatorDropdown extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      opeartor: this.props.type,
      openOperatorDD: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = document;

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        openOperatorDD: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.setState({ operator: this.props.type });
    }
  }
  
  openOperatorDD = () => {
    let { openOperatorDD } = this.state;
    this.setState({ openOperatorDD: !openOperatorDD });
  }

  handlerOperatorOption = (opeartor) => () => {
    const { handlerOperator, mainComponent } = this.props;
    this.setState({ opeartor, openOperatorDD: false }, () => {
      !!handlerOperator && handlerOperator(opeartor, !!mainComponent ? mainComponent : "");
    });
  }

  render() {
    const { opeartor, openOperatorDD } = this.state;
    const { onlyView, operators } = this.props;
    const styleView = !!onlyView ? 'fc-button-color-view' : 'fc-button-color-secondary';
    const {
      className,
      style
    } = this.props;
    const wrapperProps = {
      className,
      style
    }
    return <Box role='OperatorDropdown' row {...wrapperProps}>
      <span className="fc-dropdownmenu">
        {!!opeartor &&
          <span
            className={`fc-dropdownmenu-button ${styleView}`}
            onClick={this.openOperatorDD}
            onKeyDown={this.openOperatorDD}
          >
            <span>{opeartor}</span>
          </span>
        }
        {!onlyView && !!openOperatorDD &&
          <span className="fc-dropdownmenu-contextmenu fc-dropdown-position">
            {operators.map((item, index) => {
              return <div
                className="fc-dropdownmenu-contextmenu-item"
                key={index}
                selected={index}
                onClick={this.handlerOperatorOption(item.name)}
              >
                {item.name}
              </div>
            })}
          </span>
        }
      </span>&nbsp;&nbsp;
    </Box>
  }
}

export default OperatorDropdown;
