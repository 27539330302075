import {
  STAGE_GUARANTEE,
  STAGE_HIRE,
  STAGE_OFFER,
  STAGE_PAID
} from '../../dictionaries/Engagement.dic';

export const AccountEmployerDetailsQuery = {
  where: { id: '{{ACCOUNT_ID}}' },
  limit: 1,
  fields: [
    'id'
  ],
  include: {
    relation: 'employers',
    scope: {
      fields: [
        'id'
      ],
      include: {
        relation: 'jobs',
        scope: {
          fields: [
            'id',
            'jobTitle'
          ],
          include: {
            relation: 'engagements',
            scope: {
              fields: [
                'id',
                'candidateId',
                'stage'
              ],
              where: {
                stage: {
                  inq: [
                    STAGE_OFFER,
                    STAGE_GUARANTEE,
                    STAGE_HIRE,
                    STAGE_PAID
                  ]
                }
              },
              include: {
                relation: 'candidate',
                scope: {
                  fields: [
                    'id',
                    'firstName',
                    'lastName'
                  ]
                }
              }
            }
          }
        }
      }
    }
  }
};
