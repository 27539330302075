import React, {
  Component
} from "react";
import Icon from '../../../Layout/Wrappers/Icon';
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';

class Not extends Component {

  render() {
    const { decision } = this.props;
    let text = (
      <Icon mr
        role='NotYes'
        icon='done'
        color={THEME__COLOR__SUCCESS}
      />
    );

    decision.forEach(dec => {
      if (dec.group > 0) {
        text = (
          <Icon mr
            role='NotNo'
            icon='close'
            color={THEME__COLOR__ERROR}
          />
        );
      }
    });
    return text;
  }
}

export default Not;
