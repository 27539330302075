import React, { Component } from 'react';
import Job from '../../../lib/Job';
import Chips from '../../Forms/Chips';


class EditJobSourceList extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
    setTimeout(() => {
      Job.getActives((jobs) => this.setState({ jobs }));
    }, 300);
  }

  render() {
    const {
      onChange,
      title,
      values,
      addButtonClass,
      labelColor,
    } = this.props;

    const {
      jobs = []
    } = this.state;

    return !!jobs.length && (
      <Chips
        name="recruiterJobs"
        heading={this.props.heading}
        commonCase={true}
        items={jobs.map((d) => ({ id: d.id, label: d._name }))}
        values={values}
        hideExternalValues={true}
        addButtonClass={addButtonClass}
        labelColor={labelColor}
        onChange={(jobs, jobObj) => {
          !!onChange && onChange(jobs, jobObj);
        }}
        title={title}
        openWidth={'600'}
      />
    );
  }
}

export default EditJobSourceList;
