import {
  useState
} from 'react';
import Core from '../../lib/Core';
import {
  ATS_TYPE__LEVER_API_ID
} from '../../lib/Definition';
import {
  NOT,
  YES
} from '../../lib/GenericTools.lib';
import {
  Obj
} from '../../lib/Object.lib';
import {
  Str,
  compileText
} from '../../lib/String.lib';
import {
  getParams,
  reloadLocation
} from '../../lib/URL.lib';
import Lever from '../../lib/services/ATS/Lever.lib';
import {
  COLLECTION__EMPLOYERS, readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  mapContactsToStrings
} from '../../lib/services/Email/Email.lib';
import {
  showConfirm
} from '../Dialogs/AppConfirmationDialog';
import Page from '../Layout/Page';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Fieldset from '../Layout/Wrappers/Fieldset';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import {
  ErrorMessage,
  LoadingMessage,
  SuccessChipMessage,
  WarningChipMessage
} from '../Layout/Wrappers/Message';
import Row from '../Layout/Wrappers/Row';

export const EMPLOYER_SETTINGS__LABELS = {
  page: {
    title: '{{EMPLOYER__NAME}} settings'
  },
  leverSection: {
    title: '10x10 Lever settings',
    subsection1: {
      title: 'Application authorization'
    },
    subsection2: {
      title: 'Connection settings'
    }
  },
  confirm: {
    title: 'Disconnect?',
    message: 'This action will remove all Lever credentials for {{EMPLOYER__NAME}}',
    onAccept: 'Proceed'
  },
  validateSource: 'Validate',
  isValidSource: 'Validated source!',
  invalidSource: 'Please select a 10x10 agency',
  invalidUser: 'Please select a user',
  verifyingConnection: 'Verifying connection state',
  loadingResources: 'Loading settings',
  connect: 'Connect',
  disconnect: 'Disconnect',
  connected: 'Connected',
  disconnected: 'Disconnected',
  validated: 'Validated',
  invalid: 'Invalid'
};

export default function EmployerSettings(props) {
  let [state, setState] = useState({});
  let {
    isConnected,
    employer = {},
    error = false
  } = state;
  const _updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    setState(prevState => ({ ...prevState, ...update }));
    return delay ? new Promise(resolve => setTimeout(() => resolve(state), delay)) : state;
  };
  const { employerId } = getParams({ pattern: '/employer/settings/:employerId' });
  (async () => {
    setTimeout(async () => {
      if (employerId && !(EmployerSettings.busy || EmployerSettings.ready || error)) {
        EmployerSettings.busy = true;
        try {
          await _updateState({
            isConnected: await Lever.isConnected({ employerId }),
            employer: await readLoopbackRecord({
              collection: COLLECTION__EMPLOYERS,
              where: { id: employerId },
              fields: ['name'],
              limit: 1
            }).catch(Core.showError)
          });
          EmployerSettings.ready = true;
        }
        catch (error) {
          await _updateState({ error });
        }
        EmployerSettings.busy = false;
        await _updateState();
      }
      // it's needed set a delay after the 3rd party connection is completed
    }, 500);
  })();
  isConnected = YES(isConnected);
  console.debug('EmployerSettings:employerId/state', employerId, state);
  return (
    <Page paper showBack={false}
      title={
        compileText(EMPLOYER_SETTINGS__LABELS.page.title, {
          EMPLOYER__NAME: Str(employer.name)
        })
      }
    >
      <Row>
        <Fieldset
          title={EMPLOYER_SETTINGS__LABELS.leverSection.title}
        >
          <LoadingMessage show={EmployerSettings.busy && !error} className='m-2 mt-3'>
            {EMPLOYER_SETTINGS__LABELS.verifyingConnection}
          </LoadingMessage>
          {EmployerSettings.ready && (
            <>
              <Fieldset column
                title={EMPLOYER_SETTINGS__LABELS.leverSection.subsection1.title}
                className='d-flex flex-align-left-center'
                fullWidth={false}
              >
                <Box row w100>
                  <span className='c-black-medium f-md mr-2'>Status:</span>
                  <SuccessChipMessage show={isConnected} className='mr-2'>
                    {EMPLOYER_SETTINGS__LABELS.connected}
                  </SuccessChipMessage>
                  <WarningChipMessage show={NOT(isConnected)} className='mr-2'>
                    {EMPLOYER_SETTINGS__LABELS.disconnected}
                  </WarningChipMessage>
                </Box>
                <Button small
                  onClick={(event) => {
                    if (isConnected) {
                      showConfirm({
                        title: EMPLOYER_SETTINGS__LABELS.confirm.title,
                        message: compileText(EMPLOYER_SETTINGS__LABELS.confirm.message, {
                          EMPLOYER__NAME: employer.name
                        }),
                        onAcceptLabel: EMPLOYER_SETTINGS__LABELS.confirm.onAccept,
                        onAccept: () => {
                          Lever.disconnect({ employerId }).catch(Core.showError).then(response => {
                            Core.showMessage(response.message, onClose => reloadLocation());
                          });
                        }
                      });
                    }
                    else {
                      Lever.connect({ employerId });
                    }
                  }}
                  startIcon={
                    <i className='material-icons c-inherit'>
                      {isConnected ? 'logout' : 'logins'}
                    </i>
                  }
                  variant={isConnected ? 'outlined' : 'contained'}
                  className='mt-2'
                >
                  {isConnected ? EMPLOYER_SETTINGS__LABELS.disconnect : EMPLOYER_SETTINGS__LABELS.connect}
                </Button>
              </Fieldset>
              {isConnected && (
                <LeverSettingsConnected employerId={employerId} />
              )}
            </>
          )}
          <ErrorMessage show={error} className='m-2 mt-3'>
            {error}
          </ErrorMessage>
        </Fieldset>
      </Row>
    </Page >
  )
}

function LeverSettingsConnected({ employerId }) {
  let [state, setState] = useState({});
  let {
    sources = [],
    users = [],
    atsContext = {},
    error = false,
    userLabel = ''
  } = state;
  const _updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    setState(prevState => ({ ...prevState, ...update }));
    return delay ? new Promise(resolve => setTimeout(() => resolve(state), delay)) : state;
  };
  (async () => {
    if (employerId && !(LeverSettingsConnected.busy || LeverSettingsConnected.ready || error)) {
      LeverSettingsConnected.busy = true;
      try {
        atsContext = await Lever.getAtsContext({ employerId });
        users = await Lever.getUsers({ employerId });
        sources = await Lever.getSources({ employerId });
        const tenByTenUser = Core.getUser(); tenByTenUser.name = Core.getUserName();
        const leverUser = Obj(users.find(({ email }) => email === tenByTenUser.email));
        const leverSource = Obj(sources.find(({ text }) => Core.isTenByTenAlias(text)));
        const isValidUser = YES(leverUser.id);
        const isValidSource = YES(leverSource.text);
        const update = {};
        if (isValidUser && NOT(atsContext.userId)) {
          update.userId = leverUser.id;
        }
        if (isValidSource && NOT(atsContext.agencyName)) {
          update.agencyName = leverSource.text;
        }
        if (!!Object.keys(update).length) {
          atsContext = await Lever.updateAtsContext({ employerId, update });
        }
        userLabel = isValidUser ? mapContactsToStrings([leverUser]) : mapContactsToStrings([tenByTenUser]);
        await _updateState({
          atsContext,
          userLabel,
          users,
          sources
        });
        LeverSettingsConnected.ready = true;
      }
      catch (error) {
        await _updateState({ error });
      }
      LeverSettingsConnected.busy = false;
      await _updateState();
    }
  })();
  console.debug('EmployerSettingsConnected:state', state);
  return (
    <>
      <LoadingMessage show={LeverSettingsConnected.busy && !error} className='m-2 mt-3'>
        {EMPLOYER_SETTINGS__LABELS.loadingResources}
      </LoadingMessage>
      <ErrorMessage show={error} className='m-2 mt-3'>
        {error}
      </ErrorMessage>
      {LeverSettingsConnected.ready && (
        <Row>
          <Fieldset
            title={EMPLOYER_SETTINGS__LABELS.leverSection.subsection2.title}
            className='px-2'
          >
            <div className='d-flex flex-align-left-center mr-2'>
              <span className='c-black-medium f-md mr-2 min-w-120'>Source:</span>
              <div className='min-w-180 mr-2'>
                <Menu dropdown
                  name={`employer_settings_${employerId}_sources`}
                  value={atsContext.agencyName}
                  onChange={async (agencyName) => {
                    await _updateState({
                      atsContext: await Lever.updateAtsContext({
                        employerId,
                        update: { agencyName }
                      })
                    });
                  }}
                  options={sources.map(({ text }) => ({
                    id: text,
                    label: (
                      Core.isTenByTenAlias(text)
                        ? (
                          <div className='d-flex flex-align-left-center'>
                            <span className='mr-1'>{text}</span>
                            <i className='material-icons c-cyan icon16'>verified</i>
                          </div>
                        )
                        : text
                    )
                  }))}
                />
              </div>
              <WarningChipMessage show={NOT(Core.isTenByTenAlias(atsContext.agencyName))}>
                {EMPLOYER_SETTINGS__LABELS.invalidSource}
              </WarningChipMessage>
            </div>
            <div className='d-flex flex-align-left-center mt-1'>
              <span className='c-black-medium f-md mr-2 min-w-120'>User:</span>
              <div className='min-w-180 mr-2'>
                <Menu dropdown
                  name={`employer_settings_connected_${employerId}_users`}
                  value={atsContext.userId}
                  onChange={async (userId) =>
                    await _updateState({
                      atsContext: await Lever.updateAtsContext({
                        employerId,
                        update: { userId }
                      }),
                      isValidUser: YES(userId)
                    })
                  }
                  options={users}
                />
              </div>
              <WarningChipMessage show={NOT(atsContext.userId)}>
                {compileText(EMPLOYER_SETTINGS__LABELS.invalidUser, { CONTACT: userLabel })}
              </WarningChipMessage>
            </div>
          </Fieldset>
        </Row>
      )}
    </>
  );
}

export function EmployerSettingsButton({ employerId }) {
  let [state, setState] = useState({});
  const _updateState = async (update, delay) => {
    update = Object(update) === update ? update : {};
    setState(prevState => ({ ...prevState, ...update }));
    return delay ? new Promise(resolve => setTimeout(() => resolve(state), delay)) : state;
  };
  Core.setKeyValue('EmployerSettingsButton', {
    async update({ atsTypeId }) {
      await _updateState({ isAtsLeverApiSelected: atsTypeId === ATS_TYPE__LEVER_API_ID });
    }
  });
  (async () => {
    if (
      employerId &&
      NOT(EmployerSettingsButton.error || EmployerSettingsButton.busy || EmployerSettingsButton.ready)
    ) {
      EmployerSettingsButton.busy = true;
      let isAtsLeverApiSelected = false;
      try {
        isAtsLeverApiSelected = await Lever.isAtsLeverApiSelected({ employerId });
        EmployerSettingsButton.ready = true;
      }
      catch (error) {
        EmployerSettingsButton.error = error;
        console.debug('EmployerSettingsButton:error', error);
      }
      EmployerSettingsButton.busy = false;
      await _updateState({ isAtsLeverApiSelected });
    }
  })();
  return YES(state.isAtsLeverApiSelected) && (
    <IconButton
      aria-label='Open settings'
      className='hint--left mr-1'
      onClick={() => Lever.openEmployerSettings({ employerId })}
    >
      <i className="material-icons">settings</i>
    </IconButton>
  );
}
