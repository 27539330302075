import React, {
  Component
} from "react";
import Menu from '../../Layout/Wrappers/Menu';

class CompanySize extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      minimumCompanySize: this.props.minimumCompanySize || 0,
      showCompanySizePopup: false
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.minimumCompanySize !== prevProps.minimumCompanySize) {
      this.setState({ minimumCompanySize: this.props.minimumCompanySize });
    }
  }

  closeEventCompanySizePopup = () => {
    this.setState({ showCompanySizePopup: false });
  }

  applyEventCompanySizePopup = (companySize) => {
    const { updateSliderValues } = this.props;

    this.closeEventCompanySizePopup();
    this.setState({ minimumCompanySize: companySize }, () => {
      updateSliderValues("tempMinimumCompanySize", companySize);
    });
  }
  onChangeEventCompanySizePopup = (companySize) => {
    this.setState({ minimumCompanySize: companySize });
  }
  showCompanySizePopup = () => {
    const { companySizePopup } = this.props;
    const { showCompanySizePopup, minimumCompanySize } = this.state;

    if (!!showCompanySizePopup && !!companySizePopup) {
      return companySizePopup(this.closeEventCompanySizePopup, this.applyEventCompanySizePopup,
        this.onChangeEventCompanySizePopup, minimumCompanySize);
    }
    return null;
  }

  render() {

    return (
      <>
        <Menu.Item
          label='Company size'
          onClick={(event) => this.setState({ showCompanySizePopup: true })}
          endIcon='arrow_drop_down'
          className="f-sm"
        />
        {this.showCompanySizePopup()}
      </>
    );
  }
}

export default CompanySize;