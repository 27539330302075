import {
  ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER,
  REJECTION_REASON__EMPLOYER__NOT_INTERESTED,
  REJECTION_REASON__RECRUITER__BAD_MATCH,
  STAGE_CONFIRMATION,
  STAGE_REVIEW,
  STAGE_SUBMISSION,
  STATUS_E_10X10,
  STATUS_E_EMPLOYER
} from '../../../dictionaries/Engagement.dic';
import {
  join
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import {
  MATERIAL_ICON_NAME__CLOSE
} from '../../../lib/constants/MaterialIconName.dic';
import Core from '../../../lib/Core';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import useState from '../../../lib/hooks/useState.hook';
import Job, {
  prependJobFeedbackLog
} from '../../../lib/Job';
import {
  mapCandidateName
} from '../../../lib/models/candidate';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  COLLECTION__ENGAGEMENTS,
  updateLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  Str,
  trim
} from '../../../lib/String.lib';
import {
  ACTION_SOURCE__ENGAGEMENT,
  ACTION_TYPE__REJECT
} from '../../Employers/EmployerPendings/EmployerPendings.lib';
import {
  REACT_TABLE__COLUMN_SM
} from '../../Home/useEnhancedReactTable.hook';
import JobFeedbacks, {
  JOB__INTERVIEW_FEEDBACK__TAB,
  JOB__RESUME_FEEDBACK__TAB
} from '../../Jobs/Edit/Forms/JobFeedbacks';
import {
  joinClassName
} from '../../Layout/Libraries/Theme.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Dialog from '../../Layout/Wrappers/Dialog';
import Divider from '../../Layout/Wrappers/Divider';
import Fieldset from '../../Layout/Wrappers/Fieldset';
import IconButton from '../../Layout/Wrappers/IconButton';
import Menu from '../../Layout/Wrappers/Menu';
import {
  PLACEMENT__LEFT_START
} from '../../Layout/Wrappers/StyledTooltip';
import TextField from '../../Layout/Wrappers/TextField';
import Typography from '../../Layout/Wrappers/Typography';

export default function RejectionDropdown(props) {

  let {
    setRef = self => null,
    rejectionReasons = [],
    engagement = {},
    onChange = () => null,
    disabled,
    className = '',
  } = props;

  engagement = Obj(engagement);
  let job = Obj(engagement.job);
  let rejectionReasonAdditionalInfo = Str(engagement.rejectionReasonAdditionalInfo);

  const [{ open = false }, _updateState] = useState();

  const isEndByEmployer = (engagement.status === STATUS_E_EMPLOYER);
  const isResumeStage = [
    STAGE_CONFIRMATION,
    STAGE_SUBMISSION,
    STAGE_REVIEW
  ].includes(engagement.stage);

  const _onOpenChange = (open) => async (event) => await _updateState({ open });

  const _onReasonChange = (rejectionReason) => async (event) => {
    try {
      await _updateState({ open: false });
      if (isEndByEmployer) {
        const _reason = trim(rejectionReason);
        const _note = Str(
          engagement.rejectionReasonAdditionalInfo
        ).replace(/\n/g, ' ').trim();
        await prependJobFeedbackLog({
          jobId: engagement.jobId,
          context: {
            ACTION: ACTION_TYPE__REJECT,
            SOURCE: ACTION_SOURCE__ENGAGEMENT,
            CANDIDATE__NAME: mapCandidateName(engagement.candidate),
            USER: Core.getUserName(),
            PRESETS: _reason,
            NOTE: _note
          },
          stage: engagement.stage
        });
      }
      onChange({ rejectionReason });
    }
    catch (error) {
      Core.showError(error);
    }
  };

  const _onInputChange = async (event) => {
    Object.assign(engagement, {
      rejectionReasonAdditionalInfo: Str(event.target.value)
    });
    await _updateState();
    clearTimeout(RejectionDropdown.timeout1);
    RejectionDropdown.timeout1 = setTimeout(async () => {
      engagement.rejectionReasonAdditionalInfo = trim(engagement.rejectionReasonAdditionalInfo);
      await updateLoopbackRecord({
        collection: COLLECTION__ENGAGEMENTS,
        id: engagement.id,
        record: { rejectionReasonAdditionalInfo: engagement.rejectionReasonAdditionalInfo }
      }).catch(Core.showError);
      _updateState();
    }, 3000);
  }

  const filterMapping = {
    '10x10': rejectionReason => /^10(x|by)10/i.test(rejectionReason),
    'Recruiter': rejectionReason => /^Recruiter/i.test(rejectionReason),
    'Candidate': rejectionReason => /^Cand/i.test(rejectionReason),
    'Employer': rejectionReason => /^Emp/i.test(rejectionReason),
    'Other': rejectionReason => !/^(10(x|by)10|Recruiter|Cand|Emp)/i.test(rejectionReason)
  };

  const groups = {
    '10x10': [],
    'Recruiter': [],
    'Candidate': [],
    'Employer': [],
    'Other': []
  };
  rejectionReasons.forEach(rejectionReason => {
    let header = Object.keys(filterMapping).find(key => filterMapping[key](rejectionReason));
    groups[header] = groups[header] || [];
    groups[header].push(rejectionReason);
  });

  setRef({
    open: async () => await _updateState({ open: true }),
    close: async () => await _updateState({ open: false })
  });

  const _sorter = (a, b) => (
    ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER.indexOf(a) -
    ENGAGEMENT__EMPLOYER_REJECTION_REASON_ORDER.indexOf(b)
  );

  return (
    <>
      <Button outlined inherit small
        title={join([
          engagement.rejectionReason,
          engagement.rejectionReasonAdditionalInfo
        ], ' - ')}
        placement={PLACEMENT__LEFT_START}
        className={joinClassName([
          'f-sm tt-unset py-0 px-1 overflow-hidden nowrap',
          className
        ])}
        style={{ height: 24, width: REACT_TABLE__COLUMN_SM, maxWidth: '100%' }}
        disabled={disabled}
        onClick={_onOpenChange(true)}
      >
        {engagement.rejectionReason || 'Rejection Reason'}
      </Button>
      <Dialog
        title={
          <Box row noWrap
            className='mr-auto'
          >
            <Box row noWrap className='flex-align-left-bottom mr-auto truncate'>
              <Typography strong className='f-xl'>{engagement._name}</Typography>
              <Typography sub cyanDark className='flex-align-left-bottom'>
                &nbsp;{MDASH}&nbsp;
                {engagement.stage}
                &nbsp;{MDASH}&nbsp;
                {engagement.status}
                {engagement.rejectionReason && <>&nbsp;{MDASH}&nbsp;</>}
                <Typography sub error>{engagement.rejectionReason}</Typography>
              </Typography>
            </Box>
            <IconButton onClick={_onOpenChange(false)} className='icon24'>
              <i className='material-icons'>{MATERIAL_ICON_NAME__CLOSE}</i>
            </IconButton>
          </Box>
        }
        content={
          <>
            <Fieldset
              title='Type additional info'
            >
              <TextField disabledBlurExport autoFocus small multiline
                value={rejectionReasonAdditionalInfo}
                onChange={_onInputChange}
              />
            </Fieldset>
            <Fieldset
              title='Select a rejection reason'
            >
              <div
                className='d-flex flex-wrap'
                style={{
                  maxHeight: 'calc(100vh - 12.5rem)',
                  maxWidth: 'calc(100vw - 4rem)'
                }}
              >
                {Object.keys(groups).filter((key) => groups[key].length).map((header, index) => {
                  return (
                    <Box column
                      key={`rejection-reason-group-${header}-${index}`}
                      className='bg-white rounded m-1 mt-0 flex-1'
                    >
                      <strong className='p-2'>{header}</strong>
                      <Divider />
                      <Box column scrollY
                        style={{ minHeight: 250 }}
                      >
                        {groups[header].sort(_sorter).map(rejectionReason => {
                          let highlight = false;
                          if (NOT(engagement.rejectionReason)) {
                            if (
                              !!Str(engagement.status).match(/employer/i) &&
                              (rejectionReason === REJECTION_REASON__EMPLOYER__NOT_INTERESTED)
                            ) {
                              highlight = true;
                            }
                            else if (
                              [
                                STAGE_CONFIRMATION,
                                STAGE_SUBMISSION
                              ].includes(engagement.stage) &&
                              (engagement.status === STATUS_E_10X10) &&
                              (rejectionReason === REJECTION_REASON__RECRUITER__BAD_MATCH)
                            ) {
                              highlight = true;
                            }
                          }
                          else if (engagement.rejectionReason === rejectionReason) {
                            highlight = true;
                          }
                          return (
                            <Menu.Item
                              key={`rejection-reason-group-${header}-${index}-${rejectionReason}`}
                              className={highlight ? `bg-yellow-a5` : `hover-bg`}
                              onClick={_onReasonChange(rejectionReason)}
                            >
                              {rejectionReason}
                            </Menu.Item>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </div>
            </Fieldset>
            {isEndByEmployer && (
              <JobFeedbacks
                job={job}
                onChange={async (update) => new Promise((resolve) => {
                  Object.assign(engagement.job, update);
                  _updateState(Date.now());
                  resolve();
                  let timeoutKey = [`timeout_${Object.keys(update)[0]}`];
                  clearTimeout(RejectionDropdown[timeoutKey]);
                  RejectionDropdown[timeoutKey] = setTimeout(async () => {
                    Object.keys(update).forEach(key => engagement.job[key] = trim(engagement.job[key]));
                    await Job.update(engagement.jobId, update).catch(Core.showError);
                    _updateState(Date.now());
                  }, 3000);
                })}
                className='mt-3 w-100'
                defaultTab={
                  isResumeStage
                    ? JOB__RESUME_FEEDBACK__TAB
                    : JOB__INTERVIEW_FEEDBACK__TAB
                }
              />
            )}
          </>
        }
        paperStyle={{
          width: 1600,
          maxWidth: 'calc(100vw - 2rem)'
        }}
        open={open}
      />
    </>
  );
}
