import React, {
  Component
} from 'react';
import {
  withTranslation
} from 'react-i18next';
import {
  CANDIDATE_UI
} from '../../Candidates/CandidateUI.dic';
import CandidateNotes from '../../Candidates/Forms/CandidateNotes';
import RichTextBox from '../../Layout/Wrappers/RichTextBox';
import Card from '../../Layout/Wrappers/Card';
import IconButton from '../../Layout/Wrappers/IconButton';
import TextField from '../../Layout/Wrappers/TextField';
import {
  PrescreenQuestionsReadOnly
} from '../../PrescreenQuestions/PrescreenQuestionsSection';
import Typography from '../../Layout/Wrappers/Typography';
import Divider from '../../Layout/Wrappers/Divider';

class CandidatePipe extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      submissionNotes: this.props.candidate.submissionNotes,
      submissionNotesToEmployer: this.props.candidate.submissionNotesToEmployer,
      privateNotes: this.props.candidate.privateNotes,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.candidate !== this.props.candidate) {
      this.setState({
        submissionNotes: this.props.candidate.submissionNotes,
        submissionNotesToEmployer: this.props.candidate.submissionNotesToEmployer,
        privateNotes: this.props.candidate.privateNotes,
      });
    }
  }

  render() {
    const {
      job = {},
      candidate = {},
      saveContentCandidate,
    } = this.props;
    const { submissionNotes, submissionNotesToEmployer, privateNotes } = this.state;

    return (!!candidate.id && !!job.id) && (
      <>
        <Card
          role='RecruiterWriteUpCard'
          className='mb-05'
        >
          <Typography strong large alignCenter blackDark
            className='m-1'
          >
            RECRUITER WRITE UP
            <IconButton
              onClick={() => saveContentCandidate('submissionNotes', submissionNotes)}
              icon='save'
            />
          </Typography>
          <Divider />
          <RichTextBox
            key={`submission-notes-${candidate.id}`}
            className='m-05'
            name='submissionNotes'
            value={submissionNotes}
            onChange={(submissionNotes) => {
              this.setState({ submissionNotes });
            }}
          />
        </Card>

        <Card
          role='PrescreenQuestionsReadOnlyCard'
          className='mb-05'
        >
          <PrescreenQuestionsReadOnly divider
            name={`candidates__match_new__candidate_pipe__write_ups`}
            candidate={candidate}
            job={job}
            onChange={async ({ jobsPermittedAnswers }) => {
              saveContentCandidate('jobsPermittedAnswers', jobsPermittedAnswers);
              setTimeout(() => {
                this.setState({ jobsPermittedAnswers });
              });
            }}
            className='m-05'
          />
        </Card>

        <Card
          role='CandidateNotesCard'
          className='mb-05'
        >
          <Typography strong large alignCenter blackDark
            className='m-1'
          >
            {CANDIDATE_UI.CandidateNotes.title}
          </Typography>
          <Divider />
          <div className='outlined rounded p-1 m-05'>
            <CandidateNotes candidate={candidate} job={job} />
          </div>
        </Card>

        <Card
          role='SubmissionNotesToEmployerCard'
          className='mb-05'
        >
          <Typography strong large alignCenter blackDark
            className='m-1'
          >
            {CANDIDATE_UI.submissionNotesToEmployer.title}
            <IconButton
              onClick={() => saveContentCandidate('submissionNotesToEmployer', submissionNotesToEmployer)}
              icon='save'
            />
          </Typography>
          <Divider />
          <RichTextBox
            key={`submission-writeups-${candidate.id}`}
            name={'submissionNotesToEmployer'}
            value={submissionNotesToEmployer}
            onChange={(submissionNotesToEmployer) => {
              this.setState({ submissionNotesToEmployer });
            }}
            className='m-05'
          />
        </Card>

        <Card
          role='CandidatePrivateNotesCard'
          className='mb-05'
        >
          {!!privateNotes && (
            <>
              <Typography strong large alignCenter blackDark
                className='m-1'
              >
                CANDIDATE PRIVATE NOTES
                <IconButton
                  onClick={() => saveContentCandidate('privateNotes', privateNotes)}
                  icon='save'
                />
              </Typography>
              <Divider />
              <TextField
                name={'privateNotes'}
                rows={2}
                rowsMax={12}
                value={privateNotes}
                onChange={(event, privateNotes) => {
                  this.setState({ privateNotes });
                }}
                className='m-05'
                style={{ width: 'calc(100% - 0.5rem)' }}
              />
            </>
          )}
        </Card>

      </>
    );
  }
}

export default withTranslation()(CandidatePipe);
