import {
  NOT
} from '../Boolean.lib';
import globalErrorHandler from '../Error/globalErrorHandler.fun';
import {
  Obj
} from '../Object.lib';
import Streak from '../Streak';
import {
  Str
} from '../String.lib';

/**
 * Retrieves the notes associated with a specific streak box.
 *
 * @param {Object} params - Parameters for retrieving streak notes.
 * @param {string} [params.boxKey=''] - The key of the box from which to retrieve notes.
 * @returns {Promise<string>} - A promise that resolves to the notes as a string.
 * @warnings – Not recommended for updating notes as this method doesn't throw an error.
 */
export default async function getStreakNotes({
  boxKey = '',
}) {
  try {
    if (NOT(boxKey)) { return ''; }
    return Str(Obj(await Streak.getBox(boxKey)).notes);
  }
  catch (error) {
    globalErrorHandler(error);
  }
}
