import Core from '../../Core';
import {
  reloadLocation,
  setLocation
} from '../../URL.lib';

/**
 * Navigates to the next job for a candidate with a countdown before redirection.
 *
 * @param {Object} params - Parameters for navigation.
 * @param {string} params.candidateId - The ID of the candidate.
 * @param {string} params.nextJobId - The ID of the next job to navigate to.
 * @param {number} [params.count] - Countdown in seconds before redirecting.
 */
export default function navigateToNextJob({
  candidateId,
  nextJobId,
  count
}) {
  if (nextJobId) {
    if (count === undefined) {
      setTimeout(
        () => navigateToNextJob({
          candidateId,
          nextJobId,
          count: 3
        }),
        1000
      );
    }
    else if (count === 0) {
      setLocation(`/candidate/resume-submission/${candidateId}/${nextJobId}`);
      reloadLocation();
    }
    else {
      Core.showMessage(`Redirecting to next job in ${count} seconds`);
      setTimeout(
        () => navigateToNextJob({
          candidateId,
          nextJobId,
          count: count - 1
        }),
        1000
      );
    }
  }
}
