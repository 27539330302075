import {
  Component
} from "react";
import {
  THEME__COLOR__ERROR,
  THEME__COLOR__SUCCESS
} from '../../../Layout/Libraries/Theme.lib';
import Icon from '../../../Layout/Wrappers/Icon';

class Or extends Component {

  render() {
    const { decision, atLeast } = this.props;
    let text = (
      <Icon mr
        role='OrNo'
        icon='close'
        color={THEME__COLOR__ERROR}
      />
    );

    let addAllOrTypes = 0;
    decision.forEach(dec => {
      addAllOrTypes += dec.group;
    });
    if (addAllOrTypes >= atLeast) {
      text = (
        <Icon mr
          role='OrYes'
          icon='done'
          color={THEME__COLOR__SUCCESS}
        />
      );
    }
    return text;
  }
}

export default Or;
