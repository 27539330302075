import {
  Grid
} from '@mui/material';
import {
  Component
} from 'react';
import TextField from '../../../../../Layout/Wrappers/TextField';
import CategoryDropdown from '../Dropdowns/CategoryDropdown';

class Text extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      text: !!this.props.updateAble ? this.props.updateAble.value : "",
      category: 'technicalSkills'
    }
  }

  finalStruct = () => {
    let { text, category } = this.state;

    return {
      type: 'text',
      value: text,
      category
    }
  }

  updateAttr = (key, value) => {
    let { onUpdate } = this.props;

    this.setState({ [key]: value }, () => {
      !!onUpdate && onUpdate(this.finalStruct())
    })
  }

  render() {
    let { text, category } = this.state;

    return <>
      <Grid item xs={12} sm={12} className="job-custom-label">
        <CategoryDropdown onSave={this.updateAttr} category={category} />
      </Grid>

      <Grid item xs={12} sm={9} className="mb-10">
        <TextField
          rows={2}
          rowsMax={2}
          value={text}
          onChange={(event, text) => {
            this.updateAttr('text', text);
          }}
        />
      </Grid>
    </>;
  }
}

export default Text;
