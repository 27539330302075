import {
  compile
} from 'handlebars';
import moment from 'moment';
import {
  Arr,
  join
} from '../../../lib/Array.lib';
import {
  MDASH
} from '../../../lib/Constants';
import CrunchbaseLib from '../../../lib/Crunchbase.lib';
import Definition, {
  DEFINITION_CATEGORY__POSITIVE_SIGNALS
} from '../../../lib/Definition';
import {
  getHTMLLinkString
} from '../../../lib/HTML.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  trim
} from '../../../lib/String.lib';
import {
  TEMPLATE__CANDIDATE_SUBMISSION__WORK_EXPERIENCE
} from '../../../lib/templates/CandidateSubmission.template';
import mapCandidateSignals from '../CandidateSignalTags.lib';
import {
  SIGNALS_TYPE__COMPANY,
  mapOrganizationTenureSignals
} from '../OrganizationSignals.lib';
import filterPositiveSignalsForCandidateSummary from './filterPositiveSignalsForCandidateSummary.fun';

/**
 * Sets the prop "candidate.__workExperienceForSubmissionNotes" to contain the context object to be used by a handlebar template
 * Constants: CANDIDATE_FIRSTNAME, LIST:[{EMPLOYER_ORG_NAME, LINKEDIN, POSITION_TITLE, SIGNALS}],
 * 
 * @param {object} options
 * @param {object} options.candidate
 * @returns 
 */
export default async function mapCandidateWorkExperience({ candidate, disclaimer = true, refresh = false }) {
  if (!!candidate.__workExperienceForSubmissionNotes && !refresh) { return candidate; }
  if (!candidate.__dicSignals || refresh) {
    Object.entries(await mapCandidateSignals({ candidate })).forEach(([key, value]) => candidate[key] = value);
  }
  let list = [];
  if (!!candidate.employmentHistories && candidate.employmentHistories.length !== 0) {
    candidate.employmentHistories.forEach((history) =>
      history.positionHistories.forEach((position) => {
        let lastPosition = Obj(list[list.length - 1]);
        if (history.employerOrgName === lastPosition.EMPLOYER_ORG_NAME) {
          Object.assign(lastPosition, { POSITION_TITLE: join([lastPosition.POSITION_TITLE, position.title]) });
        }
        else {
          let positionContext = getPositionContext({
            candidate,
            history,
            position
          });
          if (!!positionContext.SIGNALS.length) {
            list.push(positionContext);
          }
        }
      })
    );
  }
  Object.assign(candidate, {
    __workExperienceForSubmissionNotes: compile(TEMPLATE__CANDIDATE_SUBMISSION__WORK_EXPERIENCE)({
      DISCLAIMER: disclaimer,
      LIST: !!list.length ? list : ''
    }),
    __workExperienceSignalsTags: list.map(positionContext => positionContext.TAGS).flat()
  });
  return candidate;
}

function getPositionContext(props) {
  let { candidate, history, position } = props;
  let organization = Obj(Obj(candidate.__dicOrganizations)[
    Obj(candidate.__dicSignals)[history.companyId]?.crunchbaseOrganizationId
  ]);
  let signals = getPositiveSignals({ history, signalsHash: candidate.__dicSignals, });
  let context = {
    EMPLOYER_ORG_NAME: history.employerOrgName || '',
    CRUNCHBASE: (
      (CrunchbaseLib.isEnabled() && organization.cb_url)
        ? ` ${getHTMLLinkString({ url: organization.cb_url, name: 'Crunchbase' })}`
        : ''
    ),
    LINKEDIN: organization.linkedin_url ? ` ${getHTMLLinkString({ url: organization.linkedin_url, name: 'LinkedIn' })}` : '',
    POSITION_TITLE: position.title,
    SIGNALS: history.companyId ? signals.labels : '',
    TAGS: history.companyId ? signals.tags : [],
  };
  return context;
}

function getPositiveSignals({
  history,
  signalsHash,
}) {
  if (!signalsHash) { return ''; }
  let signalTags = mapOrganizationTenureSignals({
    signals: signalsHash[history.companyId],
    type: SIGNALS_TYPE__COMPANY
  });
  let positiveSignalTags = Definition.getTags({
    categoryKey: DEFINITION_CATEGORY__POSITIVE_SIGNALS,
    tagIds: [...new Set(Arr(history.positiveSignalsTags))],
    sortByArray: true
  });
  positiveSignalTags = filterPositiveSignalsForCandidateSummary(
    positiveSignalTags
  );
  return {
    labels: join(
      positiveSignalTags.map(
        (tag, index) => getSignalLabel({
          tag,
          history,
          signalTags,
          signalsKey: 'positive'
        })
      )
    ),
    tags: positiveSignalTags
  }
}

function getSignalLabel({ tag, history, signalTags, signalsKey }) {
  return trim(compile(`{{LABEL}}`)({
    LABEL: tag.label,
    // TENURE_ICON: getTenureIcon({ tag, tenure: signalTags.automated.tenure, signals: signalTags.automated.tenure[signalsKey] })
  }));
}

// eslint-disable-next-line no-unused-vars
function getTenureIcon({ tag, tenure, signals = [] }) {
  signals = signals || [];
  let start = tenure.start ? moment(tenure.start.replace(/T.*$/, '')).format('M/D/YYYY') : MDASH;
  let end = tenure.end ? moment(tenure.end.replace(/T.*$/, '')).format('M/D/YYYY') : MDASH;
  return (
    signals.includes(tag.id)
      ? compile('<i title="From tenure {{START}} - {{END}}" style="cursor: default;filter: grayscale(100%);">🗓</i>')({ START: start, END: end })
      : ''
  );
}

