import {
  useState
} from 'react';
import ReactDOMServer from "react-dom/server";
import {
  Arr,
  sanitizeArr
} from '../../../lib/Array.lib';
import Candidate from '../../../lib/Candidate';
import {
  CSS_CYAN_DARKER_RGB,
  TEN_BY_TEN_ADMIN_CONTACT
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import Definition, {
  VISA_CANDIDATE__VISA_STATUS_UNKNOWN
} from '../../../lib/Definition';
import {
  getHTMLLinkString
} from '../../../lib/HTML.lib';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  mapContactsToStrings,
  sendSafeEmail
} from '../../../lib/services/Email/Email.lib';
import {
  showConfirm
} from '../../Dialogs/AppConfirmationDialog';
import Box from '../../Layout/Wrappers/Box';
import TextField from '../../Layout/Wrappers/TextField';
import {
  mapQuestionsAnswers
} from '../../PrescreenQuestions/PrescreenQuestions.lib';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  CandidateEdit__updateState
} from './CandidateEdit';

export function confirmCandidateUpdateAction({
  candidate = {},
  onAccept = (optionalHtmlString = '') => null,

  // If exportWarnings = true,
  // it will return the html as string as param of onAccept method,
  // instead open confirmation dialog.
  exportWarnings = false
}) {

  const calculatedPR = Candidate.calculatePlatformRating({ candidate });

  let recommendedPlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: calculatedPR
  });

  let candidatePlatformRatingTag = Definition.getTag({
    categoryKey: 'platformRating',
    tagId: candidate.platformRating
  });

  candidatePlatformRatingTag.label = (
    candidatePlatformRatingTag.label ||
    'unset'
  );

  Definition.map(candidate, 'positiveSignals');
  Definition.map(candidate, 'negativeSignals');

  const missingQuestionsAnswersInData = getMissingQuestionAnswersInData({ candidate });

  let rows = getCandidateInvalidRows({ candidate });

  let condition = !!(
    !!rows.length ||
    (
      Core.isAdmin() &&
      (
        +candidate.platformRating
        !==
        +recommendedPlatformRatingTag.id
      )
    ) ||
    (
      Core.isRecruiter() &&
      !(candidate.jobsPermitted || []).length
    ) ||
    !!Arr(missingQuestionsAnswersInData).length
  );

  if (condition) {
    let warnings = (
      <CandidateUpdateWarnings
        candidate={candidate}
        recommendedPlatformRatingTag={recommendedPlatformRatingTag}
        candidatePlatformRatingTag={candidatePlatformRatingTag}
        missingQuestionsAnswersInData={missingQuestionsAnswersInData}
        rows={rows}
      />
    );
    if (exportWarnings === true) {
      let exportedWarnings = ReactDOMServer.renderToString(warnings);
      return onAccept(exportedWarnings);
    }
    showConfirm({
      title: 'Confirm',
      content: (warnings),
      onCancelLabel: 'NO - TAKE ME BACK',
      onAcceptLabel: 'YES - Submit',
      onAccept,
      actionOptions: (
        Core.isAdmin() &&
        (
          +candidate.platformRating
          !==
          +recommendedPlatformRatingTag.id
        ) &&
        sanitizeArr(
          [
            { label: 'Cancel' },
            candidate.platformRating && {
              label: `Save - ${candidatePlatformRatingTag.label} rating`,
              onClick: event => {
                showConfirm({
                  title: `Why different rating? (${candidatePlatformRatingTag.label})`,
                  content: (
                    <Box column w100>
                      <CandidatePlatformRatingWarning
                        candidate={candidate}
                        recommendedPlatformRatingTag={recommendedPlatformRatingTag}
                        candidatePlatformRatingTag={candidatePlatformRatingTag}
                      />
                      <UserReasonsForCandidateRating />
                    </Box>
                  ),
                  paperStyle: { minWidth: 640 },
                  actionOptions: [
                    {
                      label: 'Cancel',
                      onClick: event => {
                        confirmCandidateUpdateAction({ candidate, onAccept });
                      }
                    },
                    {
                      label: 'Send',
                      variant: 'contained',
                      color: 'primary',
                      onClick: event => {
                        onAccept();
                        let userReasonsForCandidateRating = String(Core.getKeyValue('userReasonsForCandidateRating') || '').trim();
                        sendSafeEmail({
                          to: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
                          subject: (
                            `[ Cando Rating Disagreement ] Platform recommends: $system_rating | $user: $candidate_rating`
                              .replace('$system_rating', recommendedPlatformRatingTag.label)
                              .replace('$user', Core.getUserName())
                              .replace('$candidate_rating', candidatePlatformRatingTag.label)
                          ),
                          html: (`
                          <p>
                            ${(getHTMLLinkString({ url: Core.getPath(`candidate/edit/${candidate.id}`), name: candidate._name || '[candidate]' }))}
                          </p>
                          <P>
                            <strong style="color: ${CSS_CYAN_DARKER_RGB};">Platform rating:</strong>&nbsp;${recommendedPlatformRatingTag.label}
                          </P>
                          <P>
                            <strong style="color: ${CSS_CYAN_DARKER_RGB};">${Core.getUserName() || '[user]'} rating:</strong>&nbsp;${candidatePlatformRatingTag.label}
                          </P>
                          ${(userReasonsForCandidateRating
                              ? (`
                              <p>
                                <strong style="color: ${CSS_CYAN_DARKER_RGB};">
                                  Why disagree
                                </strong><br />
                                <span>${userReasonsForCandidateRating}</span>
                              </p>
                            `) : ``
                            )}
                          ${(!!candidate._positiveSignals.length
                              ? (`
                              <p>
                                <strong>
                                  Positive signals
                                </strong><br />
                                <span>
                                  ${candidate._positiveSignals}
                                </span>
                              </p>
                            `) : ''
                            )}
                          ${(!!candidate._negativeSignals.length
                              ? (`
                              <p>
                                <strong>
                                  Negative signals
                                </strong><br />
                                <span>
                                  ${candidate._negativeSignals}
                                </span>
                              </p>
                            `) : ''
                            )}
                        `)
                        });
                      }
                    }
                  ]
                });
              }
            },
            {
              label: `Save - ${recommendedPlatformRatingTag.label} rating`,
              onClick: async event => {
                await CandidateEdit__updateState({
                  platformRating: recommendedPlatformRatingTag.id
                });
                onAccept();
              },
              buttonProps: { color: 'primary', variant: 'contained' }
            }
          ]
        )
      ),
      paperStyle: { minWidth: 640 }
    });
  }
  else {
    onAccept();
  }

}

export function CandidatePlatformRatingWarning(props) {
  let {
    candidate,
    recommendedPlatformRatingTag,
    candidatePlatformRatingTag,
  } = props;
  return (recommendedPlatformRatingTag.label !== candidatePlatformRatingTag.label) && (
    <div>
      <div className='f-lg c-red'>
        <b>Warning:</b>&nbsp;
        The system recommends [&nbsp;
        <b>{recommendedPlatformRatingTag.label}</b>&nbsp;
        ]&nbsp;
        rating instead of [ {candidatePlatformRatingTag.label} ]
      </div>
      {!!candidate._positiveSignals.length && (
        <div>
          <div className='f-md'>
            Positive signals
          </div>
          <div className='c-black-medium f-sm'>
            {candidate._positiveSignals}
          </div>
        </div>
      )}
      {!!candidate._negativeSignals.length && (
        <div>
          <div className='f-md'>
            Negative signals
          </div>
          <div className='c-black-medium f-sm'>
            {candidate._negativeSignals}
          </div>
        </div>
      )}
    </div>
  );
}

export function UserReasonsForCandidateRating() {
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let { value } = state;
  Core.setKeyValue('userReasonsForCandidateRating', value);
  return (
    <div className='mt-3'>
      <TextField
        label='Why disagree'
        value={value}
        onChange={event => _updateState({ value: event.target.value })}
        multiline
        fullWidth
        autoFocus
      />
    </div>
  )
}

export function CandidateUpdateWarnings(props) {
  let {
    candidate,
    recommendedPlatformRatingTag,
    candidatePlatformRatingTag,
    missingQuestionsAnswersInData,
    rows
  } = props;
  console.debug('HERE_27', candidate, recommendedPlatformRatingTag, candidatePlatformRatingTag)
  return (
    <Box column w100>

      {Core.isAdmin() && (
        candidate.platformRating !== recommendedPlatformRatingTag.id && (
          <CandidatePlatformRatingWarning
            candidate={candidate}
            recommendedPlatformRatingTag={recommendedPlatformRatingTag}
            candidatePlatformRatingTag={candidatePlatformRatingTag}
          />
        )
      )}

      {Core.isRecruiter() && (
        !(candidate.jobsPermitted || []).length && (
          <div className='c-red mt-2'>
            You will not have representation for this candidate until the candidate grants permission to be submitted and you add jobs to the “{CANDIDATE_UI.jobsPermitted.title}” section
          </div>
        )
      )}

      {!!rows.length && (
        <div className='align-left'>
          <div className='mt-2'>
            <strong className='c-red underline'>Key Information Missing</strong>&nbsp;
            <div className='f-md'>
              Processing will be delayed due to missing information.
            </div>
          </div>
          <ul className='mt-1'>
            {rows.map(row => {
              return (
                <li className='c-black-medium f-sm' key={`edit-candidate-missing-info-${row.text}`}>
                  <strong className='c-red f-md'>{row.text}</strong>
                  {row.subtext ? ` ${row.subtext} ` : ` `}
                  <span>(page {row.page})</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <MissingQuestionAnswers missingQuestionsAnswersInData={missingQuestionsAnswersInData} />
    </Box>
  );
}

export function getCandidateInvalidRows({ candidate }) {
  return [
    {
      invalid: !candidate.resumes.length,
      text: 'Resume',
      page: 1
    },
    {
      invalid: !candidate.linkedInURL.length,
      text: 'LinkedIn URL',
      page: 1
    },
    {
      invalid: candidate.phone.length <= 4,
      text: 'Phone',
      page: 1
    },
    {
      invalid: !!candidate.roles && !candidate.roles.length,
      text: 'Roles',
      subtext: 'selection',
      page: 3
    },
    {
      invalid: !!candidate.jobsPermitted && !candidate.jobsPermitted.length,
      text: 'Jobs your candidate approved for Submission',
      subtext: 'selection',
      page: 3
    },
    {
      invalid: candidate.visa === VISA_CANDIDATE__VISA_STATUS_UNKNOWN,
      text: 'Candidate Visa',
      subtext: 'status',
      page: 2
    },
    {
      invalid: (
        !candidate.inOfficeRemoteFlags.length ||
        candidate.inOfficeRemoteFlags.includes(0)
      ),
      text: 'Desirable Work Region',
      subtext: 'preference',
      page: 2
    },
    {
      invalid: !candidate.candidateLocations.length,
      text: 'Home location',
      subtext: '',
      page: 2
    },
    {
      invalid: !candidate.inOfficeRemoteFlags.length,
      text: 'Desired Location Options',
      subtext: '',
      page: 2
    },
    {
      invalid: (!candidate.minimumSalary),
      text: 'Minimum Salary',
      subtext: 'requirement',
      page: 2
    },
    {
      invalid: (
        candidate.yearsOfExperience === -1 ||
        candidate.yearsOfExperience === ''
      ),
      text: 'Years of Relevant Work Experience',
      subtext: 'selection',
      page: 1
    },
    {
      invalid: (!!candidate.technicalSkills && !candidate.technicalSkills.length),
      text: 'Technical Skills',
      subtext: 'selection',
      page: 2
    }
  ].filter(row => !!row && !!row.invalid);
}

export function MissingQuestionAnswers({ missingQuestionsAnswersInData }) {
  missingQuestionsAnswersInData = Arr(missingQuestionsAnswersInData);
  let prev = '';
  return !!Arr(missingQuestionsAnswersInData).length && (
    <div className='d-flex flex-align-left-top mt-2'>
      <div>
        <div>
          <strong className='c-red underline'>
            Submission Blockers:
          </strong>&nbsp;
          Missing information for selected jobs
        </div>
        <ul>
          {missingQuestionsAnswersInData.map(question => {
            let title = '';
            if (prev !== question.title) {
              title = question.title;
              prev = title;
            }
            return (
              title
                ? (
                  <li>
                    <strong className='c-purple'>
                      {title}
                    </strong>
                    <ul>
                      <li key={`cand_edit_lib_miss_q_a_${question.question}`}>{question.question}</li>
                    </ul>
                  </li>
                )
                : (
                  <ul>
                    <li key={`cand_edit_lib_miss_q_a_${question.question}`}>{question.question}</li>
                  </ul>
                )
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export function CandidateWarningInvalidFieldsForEmail({ candidate }) {
  let rows = getCandidateInvalidRows({ candidate });
  return (!!rows.length
    ? (`
      <tr>
        <td>
        <ul className='mt-1'>
          ${rows.map(row => (`
            <li>
              <strong>${row.text}</strong>
              ${row.subtext ? ` ${row.subtext} ` : ` `}
              <span>(page ${row.page})</span>
            </li>
          `))}
        </ul>
        </td>
      </tr>
    `) : ''
  );
};

export function getMissingQuestionAnswersInData({ candidate }) {
  let {
    permittedJobs: jobs,
    __globalQuestions: globalQuestions
  } = candidate;
  globalQuestions = Obj(globalQuestions);
  let {
    missedQuestions
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs,
  });
  return missedQuestions;
};
