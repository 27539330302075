import {
  COLLECTION__JOBS
} from '../services/BE/loopback.api';

const QUERY__JOBS_DROPDOWN = {
  collection: COLLECTION__JOBS,
  fields: [
    'id',
    'jobTitle',
    'state',
    'createdAt',
    'employerId',
    'roles'
  ],
  order: [
    'state ASC',
    'jobTitle ASC'
  ],
  include: [
    {
      relation: 'employer',
      scope: {
        fields: [
          'id',
          'name'
        ]
      }
    }
  ]
}
export default QUERY__JOBS_DROPDOWN;
