import MuiTypography from '@mui/material/Typography';
import {
  hasNoSize,
  hasNoTextTransform,
  hasNoWidth,
  joinClassName,
  mapWrapper,
  THEME__COLOR__DEFAULT,
  THEME__COLOR__SECONDARY,
  THEME__SIZE__LARGE,
  THEME__SIZE__MEDIUM,
  THEME__SIZE__SMALL,
  THEME__SIZE__XS
} from '../Libraries/Theme.lib';
import Wrapper from './Wrapper';

/**
 * @see https://mui.com/material-ui/api/typography/
 */
export default function Typography({
  component = MuiTypography,
  // ---- //
  display = 'inline',
  variant = 'subtitle1',
  color = THEME__COLOR__DEFAULT,
  size = THEME__SIZE__MEDIUM,
  className = '',
  style = {},
  // ---- //
  acl = true,
  debug = false,
  strong = false,
  bold = false,
  truncate = false,
  breakAll = false,
  secondary = false,
  sub = false,
  subtitle = sub,
  blackDark = false,
  cyanDark = false,
  small = '',
  large = false,
  error = false,
  warning = false,
  mr = false,
  alignLeft = false,
  alignCenter = false,
  alignRight = false,
  noWrap = false,
  spaceBetween = false,
  italic = false,
  xs = false,
  w100 = false,
  flex1 = false,
  // ---- //
  wrapperProps = {},
  ...props
}) {
  if (!acl) { return null; }
  if (strong || bold) { component = 'strong'; }
  if (secondary) { color = THEME__COLOR__SECONDARY; }
  if (xs) { size = THEME__SIZE__XS; }
  if (small) { size = THEME__SIZE__SMALL; }
  if (large) { size = THEME__SIZE__LARGE; }
  const _wrapperClassName = [
    (
      (size === THEME__SIZE__XS)
    ) ? (
      'f-xs lh-normal'
    ) : (
      (size === THEME__SIZE__SMALL)
    ) ? (
      'f-sm'
    ) : (
      (size === THEME__SIZE__LARGE)
    ) ? (
      'f-lg'
    ) : (
      hasNoSize({ className, style })
    ) && (
      'f-md'
    ),
    (
      strong || bold
    ) ? (
      'fw-500'
    ) : (
      'fw-400'
    ),
    italic && 'italic',
    truncate && 'truncate',
    breakAll && 'break-all',
    (
      (blackDark && 'c-black-dark') ||
      (cyanDark && 'c-cyan-darker') ||
      (warning && 'c-orange-common') ||
      (error && 'c-red-common')
    ),
    (
      (
        alignLeft
      ) ? (
        'flex-align-left-center'
      ) : (
        alignCenter
      ) ? (
        'flex-align-center'
      ) : (
        alignRight
      ) ? (
        'flex-align-right-center'
      ) : (
        spaceBetween
      ) && (
        'space-between'
      )
    ),
    flex1 && 'flex-1',
    noWrap && 'flex-no-wrap',
    subtitle && 'f-sm',
    mr && 'mr-1',
    className
  ];
  mapWrapper({
    role: 'Typography',
    props: wrapperProps,
    assign: {
      ...props,
      component,
      display,
      variant,
      color,
      size,
      className: joinClassName(_wrapperClassName),
      style,
    },
    flags: { debug }
  }
  );
  return (
    <Wrapper {...wrapperProps} />
  );
}

export function Label({
  className = '',
  style = {},
  debug = false,
  wrapperProps = {},
  ...props
}) {
  mapWrapper({
    role: 'Label',
    props: wrapperProps,
    assign: {
      ...props,
      mr: true,
      strong: true,
      cyanDark: true,
      size: THEME__SIZE__LARGE,
      className: joinClassName(
        [
          'truncate nowrap',
          hasNoWidth({ className, style }) && 'flex-1',
          hasNoTextTransform({ className, style }) && 'statement',
          className
        ]
      )
    },
    flags: { debug }
  })
  return (
    <Typography {...wrapperProps} />
  );
}
