import {
  Typography
} from '@mui/material';
import React, {
  Component
} from "react";
import Account from "../../lib/Account";
import Core from "../../lib/Core";
import Store from "../../lib/Store";
import {
  getLocation
} from '../../lib/URL.lib';
import {
  getDefaultPaperStyle
} from '../Layout/Wrappers/Paper';
import Button from '../Layout/Wrappers/Button';
import Col from "../Layout/Wrappers/Col";
import Divider from '../Layout/Wrappers/Divider';
import Paper from '../Layout/Wrappers/Paper';
import Row from "../Layout/Wrappers/Row";
import TextField from '../Layout/Wrappers/TextField';
import Navigate from '../Layout/Wrappers/Navigate';

class Settings extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
    Store.set("path", getLocation());
  }
  render() {
    if (Core.isLoggedOut()) { return <Navigate to="/login" />; }
    return (
      <Paper style={getDefaultPaperStyle()} className='m-auto'>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='c-purple m-0 p-2'>
          Settings
        </Typography>
        <Divider />
        <div className='flex-column px-2'>
          <h3>Reset Your Password</h3>
          <Row>
            <Col className="inline-blocks margin-rights" fullWidth>
              <label style={{ display: "inline-block", width: 150 }}>
                Password
              </label>
              <TextField
                name="password"
                type="password"
                autoComplete="old-password"
                value={this.state.oldPassword}
                onChange={(event, oldPassword) => {
                  this.setState({ oldPassword, errorOldPassword: "" });
                }}
                errorText={this.state.errorOldPassword}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col className="inline-blocks margin-rights" fullWidth>
              <label style={{ display: "inline-block", width: 150 }}>
                New Password
              </label>
              <TextField
                name="newPassword"
                type="password"
                autoComplete="new-password"
                value={this.state.newPassword}
                onChange={(event, newPassword) => {
                  this.setState({ newPassword, errorNewPassword: "" });
                }}
                errorText={this.state.errorNewPassword}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col className="inline-blocks margin-rights" fullWidth>
              <label style={{ display: "inline-block", width: 150 }}>
                Confirm Password
              </label>
              <TextField
                name="confirmPassword"
                type="password"
                autoComplete="confirm-password"
                value={this.state.confirmPassword}
                onChange={(event, confirmPassword) => {
                  this.setState({
                    confirmPassword,
                    errorConfirmPassword: ""
                  });
                }}
                errorText={this.state.errorConfirmPassword}
                required
              />
            </Col>
          </Row>
          <div className='flex-row flex-align-right-center mb-2'>
            <Button primary
              label="Submit"
              className="mt-3 min-w-120"
              onClick={(ev) => {
                if (!this.state.oldPassword.trim()) {
                  this.setState({ errorOldPassword: "Empty Field" }, then =>
                    Core.showWarning("Fill the old password")
                  );
                }
                else if (!this.state.newPassword.trim()) {
                  this.setState({ errorNewPassword: "Empty Field" }, then =>
                    Core.showWarning("Fill the new password")
                  );
                }
                else if (this.state.newPassword !== this.state.confirmPassword) {
                  this.setState(
                    {
                      errorConfirmPassword:
                        "New password and confirm password does not match"
                    },
                    then => Core.showWarning("New password and confirm password does not match")
                  );
                }
                else {
                  Account.changePassword(
                    {
                      oldPassword: this.state.oldPassword,
                      newPassword: this.state.newPassword
                    },
                    response => {
                      this.setState(
                        {
                          oldPassword: "",
                          newPassword: "",
                          confirmPassword: ""
                        },
                        then =>
                          Core.showSuccess("Your password was successfully changed")
                      );
                    },
                    error => {
                      this.setState({ errorOldPassword: error });
                      Core.showError(error);
                    }
                  );
                }
              }}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

export default Settings;
