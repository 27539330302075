import React, {
  Component
} from "react";

const Category = [
  { name: 'technicalSkills' },
  { name: 'workExperience' },
  { name: 'education' },
  { name: 'others' }
];

class NewCategoryDropdown extends Component {

  constructor() {
    super(...arguments);
    this.state = {
      category: this.props.category,
      openCategoryDD: false
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = document;
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        openCategoryDD: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.category !== prevProps.category) {
      this.setState({ category: this.props.category });
    }
  }

  openCategoryDD = () => {
    let { openCategoryDD } = this.state;
    this.setState({ openCategoryDD: !openCategoryDD });
  }

  handlerCategoryOption = (category) => () => {
    const { handlerCategory } = this.props;
    this.setState({ category, openCategoryDD: false }, () => {
      !!handlerCategory && handlerCategory(category);
    });

  }

  render() {
    const { category, openCategoryDD } = this.state;
    const { onlyView } = this.props;

    return <>
      <span className="fc-dropdownmenu">
        {!!category && !onlyView &&
          <span
            className={`fc-dropdownmenu-button fc-button-color-secondary`}
            onClick={this.openCategoryDD}
            onKeyDown={this.openCategoryDD}
          >
            <span>{category}</span>
          </span>
        }
        {!onlyView && !!openCategoryDD &&
          <span className="fc-dropdownmenu-contextmenu fc-dropdown-position">
            {Category.map((item, index) => {
              return <div
                className="fc-dropdownmenu-contextmenu-item"
                key={index}
                selected={index}
                onClick={this.handlerCategoryOption(item.name)}
              >
                {item.name}
              </div>
            })}
          </span>
        }
      </span>
    </>
  }
}

export default NewCategoryDropdown;
