import moment from "moment-timezone";
import dig from 'object-dig';
import {
  Component,
  Fragment,
  useRef
} from "react";
import {
  Arr
} from '../../../lib/Array.lib';
import Candidate from "../../../lib/Candidate";
import Core from "../../../lib/Core";
import {
  STATE_ACTIVE
} from '../../../lib/Definition';
import dirtyUniqAttrs from "../../../lib/DirtyUniqueAttrs";
import {
  NOT
} from '../../../lib/GenericTools.lib';
import getJobOwnershipTextAndColor from "../../../lib/GetJobOwnershipTextAndColor";
import useState from '../../../lib/hooks/useState.hook';
import Job from "../../../lib/Job";
import QUERY__CANDIDATE_JOB_CHOICES from '../../../lib/queries/CandidateJobChoices.query';
import {
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import {
  joinKeyName,
  Str
} from '../../../lib/String.lib';
import {
  matchLocation
} from '../../../lib/URL.lib';
import Chips from "../../Forms/Chips";
import Box, {
  RefBox
} from '../../Layout/Wrappers/Box';
import Chip from '../../Layout/Wrappers/Chip';
import Col from "../../Layout/Wrappers/Col";
import {
  Html
} from '../../Layout/Wrappers/Html';
import Icon from '../../Layout/Wrappers/Icon';
import Row from "../../Layout/Wrappers/Row";
import {
  PLACEMENT__TOP_START
} from '../../Layout/Wrappers/StyledTooltip';
import Switch from '../../Layout/Wrappers/Switch';
import Typography from '../../Layout/Wrappers/Typography';
import {
  generateMatchHavesHTMLString
} from '../../Match/Haves/v2/Generators.lib';
import {
  CANDIDATE_UI
} from '../CandidateUI.dic';
import {
  getPersistedCandidate
} from '../Edit/CandidateEdit';

const KEY__CANDIDATE_JOB_CHOICES = 'candidate_job_choices__section';

class CandidateJobChoices extends Component {
  constructor(args) {
    super(args);
    this.state = {
      jobsList: [],
      showAll: false,
      showActive: true,
      initialJobsPermitted: [],
      initialJobsPitched: [],
      initialJobsDeclined: [],
      isLoading: true,
      showPermittedJobsDetails: false,
      ownedPermittedJobsData: false
    };
    this.isJobsPermittedArrived = false;
    this.isJobsPitchedArrived = false;
    this.isJobsDeclinedArrived = false;
    this._allJobsArrived = false;
  };

  componentDidMount() {
    if (matchLocation('edit-section')) {
      document.getElementById(KEY__CANDIDATE_JOB_CHOICES).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  componentDidUpdate(nextProps, nextState) {
    const { jobsPermitted, loadDefaultValuesComplete, jobsPitched, jobsDeclined } = this.props.parent;
    const { ownedPermittedJobsData } = this.state;

    if (!!loadDefaultValuesComplete && (!!nextProps.parent.jobsPermitted.length !== !!jobsPermitted.length || !this.isJobsPermittedArrived)) {
      this.isJobsPermittedArrived = true;
      if (!ownedPermittedJobsData && jobsPermitted.length && !!this.props.parent.id) {
        Candidate.getOwnedPermittedJobs({
          params: {
            candidateId: this.props.parent.id,
            permittedJobIds: jobsPermitted.join(',')
          }
        }, (ownedPermittedJobs) => {
          this.setState({ ownedPermittedJobsData: ownedPermittedJobs }, () => {
            this.props.parentComponent.setState({
              jobsPermitted: ownedPermittedJobs.map(obj => obj.jobId)
            });
            this.loadInitialJobs("initialJobsPermitted", jobsPermitted);

          })
        });
      } else {
        this.setState({ ownedPermittedJobsData: [] }, () => {
          this.loadInitialJobs("initialJobsPermitted", jobsPermitted);
        })
      }

    }
    if ((!!nextProps.parent.jobsPitched.length !== !!jobsPitched.length) || !this.isJobsPitchedArrived) {
      this.isJobsPitchedArrived = true;
      this.loadInitialJobs("initialJobsPitched", jobsPitched);
    }
    if ((!!nextProps.parent.jobsDeclined.length !== !!jobsDeclined.length) || !this.isJobsDeclinedArrived) {
      this.isJobsDeclinedArrived = true;
      this.loadInitialJobs("initialJobsDeclined", jobsDeclined);
    }
  }

  loadInitialJobs = (key, jobs) => {
    if (!!jobs.length) {
      const where = { id: { inq: jobs } };
      const opts = {
        include: [
          {
            relation: "employer",
            scope: {
              include: ["employerBlackList", "employerSourceList"]
            }
          },
          "resumeSubmissionEmailTemplate",
          "jobBlackList",
          "jobSourceList",
          {
            relation: "jobStarreds",
            scope: {
              where: {
                accountId: Core.getUserId()
              }
            }
          }
        ],
        fields: ['id', 'employerId', 'jobTitle', 'addressCity', 'state']
      };

      Job.getWhere(where, (res) => {

        if (key === 'initialJobsPermitted') {
          this.props.parentComponent.setState({ _jobsPermitted: res });
        }

        this.setState({
          [key]: res.sort((a, b) =>
            String(a._name).localeCompare(String(b._name)))
        });
      }, opts);
    }
  };

  loadJobs = () => {
    this.setState(
      { isLoading: true },
      async () => {
        const query = { ...QUERY__CANDIDATE_JOB_CHOICES };
        if (Core.isAdmin()) {
          if (this.state.showActive) {
            query.where = { state: STATE_ACTIVE };
          }
        }
        else {
          query.where = { state: STATE_ACTIVE };
          query.fields = query.fields.filter((field) => !Str(field).match(/have/i));
        }
        this.setState({
          isLoading: false,
          jobsList: Arr(await readLoopbackRecord(query))
        });
      }
    );
  };

  dialogTopRow = () => {
    return (
      <>

        <>
          <Row>
            <Col className='p-05'>
              <Chip
                label='Green'
                color='success'
                size='small'
                className='min-w-80 mr-1'
              />
              <span className='f-sm c-black-medium'>
                You have representation
              </span>
            </Col>
            <Col className='p-05'>
              <Chip
                label='Blue'
                color='primary'
                size='small'
                className='min-w-80 mr-1'
              />
              <span className='f-sm c-black-medium'>
                Available (no one currently has representation)
              </span>
            </Col>
          </Row>

          <Row >
            <Col className='d-flex flex-align-left-top p-05'>
              <Chip
                label={`Red`}
                color='error'
                size='small'
                className='min-w-80 mr-1'
              />
              <span className='f-sm c-black-medium'>
                Taken (within someone else's 180 day representation period for this employer)
              </span>
            </Col>
            <Col className='p-05'>
              <Chip
                label='Gray'
                size='small'
                className='min-w-80 mr-1'
                style={{ backgroundColor: colors.gray.common, color: colors.white.common }}
              />
              <span className='f-sm c-black-medium'>
                Representation TBD (review required)
              </span>
            </Col>
          </Row>
        </>

        <div className='d-flex flex-align-left-center'>
          {Core.isAdminOrCoordinator() &&
            <Switch
              checked={this.state.showActive}
              onChange={(event) => {
                this.setState(
                  { showActive: event.target.checked },
                  () => this.loadJobs()
                );
              }}
              label="Show Active Only"
            />
          }

          <Switch
            checked={!this.state.showAll}
            onChange={ev => {
              this.setState({ showAll: !ev.target.checked })
            }}
            label="Show Jobs for selected roles only"
          />
          <Icon
            title="Turn the toggle off if you can't find the job you're looking for. The job you want may be grouped as a different role type."
            titleStyle={{ maxWidth: 420 }}
            icon='help_outline'
            className='material-icons c-purple help icon16'
          />
        </div>

      </>
    );
  };

  handlerPermittedDetails = () => {
    let { showPermittedJobsDetails } = this.state;
    let { jobsPermitted } = this.props.parent;

    if (!!this.props.parent.id) {
      Candidate.getOwnedPermittedJobs({
        params: {
          candidateId: this.props.parent.id,
          permittedJobIds: jobsPermitted.join(',')
        }
      }, (ownedPermittedJobs) => {
        this.setState({
          ownedPermittedJobsData: ownedPermittedJobs,
          showPermittedJobsDetails: !showPermittedJobsDetails
        })
      });
    }

    // showPermittedJobsDetails: false,
    // ownedPermittedJobsData: []

  };

  thisJobIsConflictColorMethod = (
    ownershipResult = [],
    duplicateResult,
    permittedJobs,
    candoPermittedJobs = []
  ) => (
    jobId
  ) => {
      const { parent } = this.props;
      // employer A, job A
      const isDirty = dirtyUniqAttrs(parent, getPersistedCandidate()).length;
      let thisJobsEmployerId = Object(permittedJobs.find(j => String(j.id) === String(jobId))).employerId;
      let { color, isOwned, iOwnIt, hasEngagement } = getJobOwnershipTextAndColor(
        ownershipResult,
        thisJobsEmployerId,
        parent.id,
        jobId,
        Object(duplicateResult).identifiedDuplicateCando,
        isDirty
      );
      const isPresentInMyPermittedJob = candoPermittedJobs.includes(jobId);
      const weekAndNotPotential = Object(duplicateResult).isWeakConflict && !Object(duplicateResult).isPotentialStrongConflict;
      if (weekAndNotPotential && !!isOwned.length && !iOwnIt && isPresentInMyPermittedJob) {
        color = colors.gray.common;
      }

      //coming from popup, while picking chip
      if (!!isOwned.length && !isPresentInMyPermittedJob && weekAndNotPotential) {
        color = colors.gray.common;
      }

      return { color, isOwned, iOwnIt, hasEngagement };
    };

  getChipToRender = (thisJobIsConflictColorMethod) => (
    key,
    id,
    value,
    onChange
  ) => {
    const { color, hasEngagement } = thisJobIsConflictColorMethod(id);
    const _key = joinKeyName([
      'candidate_job_choices',
      key
    ]);
    return (
      !!hasEngagement
        ? (
          <Chip
            key={_key}
            backgroundColor={color}
          >
            {value}
          </Chip>
        ) : (
          <Chip
            key={_key}
            backgroundColor={color}
            onRequestDelete={!!hasEngagement ? () => {
            } : ev => {
              onChange && onChange();
            }}
          >
            {value}
          </Chip>
        ));
  };

  renderChipForOption = (thisJobIsConflictColorMethod) => (model, index, selected, onClickHandler) => {
    const { color, hasEngagement } = thisJobIsConflictColorMethod(model.id);

    return (
      <ChipForOption
        key={`candidate_choices__ship_for_option__${index}_${model.label}`}
        {...({
          job: model.record,
          candidate: this.props.CandidateEditController.state,
          selected,
          color,
          model,
          hasEngagement,
          onClickHandler
        })}
      />
    );
  };

  render() {

    let { parent, onChange, handlerQuestionsLink } = this.props;
    let roles = parent.roles.map(o => parseInt(o));
    let {
      jobsList, initialJobsPermitted, initialJobsPitched, initialJobsDeclined, isLoading,
      showPermittedJobsDetails, ownedPermittedJobsData
    } = this.state;

    ownedPermittedJobsData = ownedPermittedJobsData || [];
    let data = [
      {
        key: 'jobsPermitted',
        label: CANDIDATE_UI.jobsPermitted.title,
        required: true,
        text: '',
        initialJobsLabels: initialJobsPermitted
      },
      {
        key: 'jobsPitched',
        label: 'Jobs pitched but WAITING for candidate’s submission approval',
        required: false,
        text: '',
        initialJobsLabels: initialJobsPitched
      },
      {
        key: 'jobsDeclined',
        label: 'Jobs pitched but candidate not interested',
        required: false,
        text: '',
        initialJobsLabels: initialJobsDeclined
      }
    ];
    return (
      <Box>
        <RefBox id={KEY__CANDIDATE_JOB_CHOICES} />
        {
          data.map(item => {

            // takes array of jobs from candidate, these ids are used to filter active + existing jobs
            let existing = parent[item.key] || [];

            let initialWithoutJobsList = (item.initialJobsLabels.filter(job => !jobsList.map(el => el.id).includes(job.id)));
            let jobsListFiltered = !!jobsList.length ? [...initialWithoutJobsList, ...jobsList] : item.initialJobsLabels;

            if (this.state.showActive) {
              jobsListFiltered = (
                !!jobsList.length
                  ? jobsListFiltered.filter(
                    job => (
                      (job.state === STATE_ACTIVE) ||
                      existing.includes(job.id)
                    )
                  ) : item.initialJobsLabels);
            }

            if (roles.length && !this.state.showAll) {
              jobsListFiltered = (
                !!jobsList.length
                  ? jobsListFiltered.filter(
                    job => (
                      !!roles.find(roleId => job.roles.includes(roleId)) ||
                      existing.includes(job.id)
                    )
                  ) : item.initialJobsLabels
              );
            }

            existing = [];                      // to avoid empty circles
            if (!!jobsListFiltered.length) {
              existing = parent[item.key];
              let deleted = parent[item.key].filter(jId => !jobsListFiltered.map(j => j.id).includes(jId));
              if (Core.isAdmin()) {
                jobsListFiltered = [...jobsListFiltered, ...deleted.map(jid => {
                  return { id: jid, _name: 'Deleted Job-' + jid }
                })];
              } else {
                jobsListFiltered = [...jobsListFiltered, ...deleted.map(jid => {
                  return { id: jid, _name: 'Deleted Job' }
                })];
              }
            }

            return <Row role='CandidateJobChoices' key={item.key}>
              <Col fullWidth>
                <Chips
                  name={item.key}
                  openWidth={true}
                  commonCase={true}
                  topRow={this.dialogTopRow()}
                  items={jobsListFiltered.map(j => {
                    let ownedJobInfo = ownedPermittedJobsData.find(obj => String(obj.jobId) === String(j.id));
                    let timestamp = dig(ownedJobInfo, 'permittedModel', 'timestamp');

                    let newJobName = (showPermittedJobsDetails && !!ownedJobInfo && !!timestamp) && Core.isAdmin() ?
                      `${moment(timestamp).tz("America/Los_Angeles").format('MM/DD/YYYY h:mm')} PST by ${dig(ownedJobInfo, 'permittedModel', 'operatedBy')} ${j._name}` : !!showPermittedJobsDetails ? `No Ownership Data - ${j._name}` : j._name;

                    return { id: j.id, label: newJobName, selected: existing.includes(j.id), record: j }
                  })}
                  chipRenderer={
                    this.getChipToRender(
                      this.thisJobIsConflictColorMethod(
                        parent.ownershipResult,
                        parent.duplicateResult,
                        jobsListFiltered,
                        this.props.parent.jobsPermitted
                      )
                    )}
                  chipOptionRenderer={
                    this.renderChipForOption(
                      this.thisJobIsConflictColorMethod(
                        parent.ownershipResult,
                        parent.duplicateResult,
                        jobsListFiltered,
                        this.props.parent.jobsPermitted
                      )
                    )}
                  label={
                    <>
                      <label>
                        {item.label} {item.required ? <b style={{ color: 'orange' }}>*</b> : ''} &nbsp;&nbsp;
                      </label>
                      {item.key === 'jobsPermitted' && (
                        <span className="anchor f-small" onClick={handlerQuestionsLink} color="primary"
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                          Answer Employer Questions
                        </span>
                      )}
                      {item.key === 'jobsPermitted' &&
                        <>
                          &nbsp;|&nbsp;
                          <span className="anchor f-small" onClick={this.handlerPermittedDetails} color="primary"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            {showPermittedJobsDetails ? 'Hide Details' : 'View Details'}
                          </span>
                        </>
                      }
                    </>
                  }
                  heading={
                    <div className='px-2 py-1'>
                      <span className='mr-1 f-lg fw-500 c-cyan-darker'>
                        {item.label} {item.required ? <b style={{ color: 'orange' }}>*</b> : ''} &nbsp;&nbsp;
                      </span>
                      {item.key === 'jobsPermitted' &&
                        <Fragment>
                          <span className="anchor f-small" onClick={this.handlerPermittedDetails} color="primary"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            {showPermittedJobsDetails ? 'Hide Details' : 'View Details'}
                          </span>
                        </Fragment>
                      }
                    </div>
                  }
                  sub={item.text}
                  values={existing}
                  onChange={(result) => {
                    let jobObjects = jobsListFiltered;
                    onChange(item)(result, jobObjects);
                  }}
                  onClickAddChipEvent={this.loadJobs}
                  isPartialLoadingComp={() => {
                    if (isLoading) {
                      return <div className="not-found inline-blocks" style={{ textAlign: "center" }}>
                        <label>Loading more jobs might take a few seconds</label><br />
                        <label>Please wait...</label>
                      </div>
                    }
                    return null;
                  }}
                />
              </Col>
            </Row>;

          })
        }
      </Box>
    );

  };
}

export default CandidateJobChoices;

function ChipForOption({
  job,
  candidate,
  selected,
  color,
  model,
  hasEngagement,
  onClickHandler
}) {
  const { current } = useRef({});
  const [{ tooltip = '' }, _updateState] = useState();
  const _label = !!hasEngagement ? `*${model.label}` : (!selected && model.selected) ? `${model.label}` : model.label;
  if (Core.isAdmin()) {
    setTimeout(async () => {
      if (NOT(current.ready || current.busy || current.error)) {
        current.busy = true;
        try {
          await _updateState({
            tooltip: await generateMatchHavesHTMLString({
              job, 
              candidate
            })
          });
          current.ready = true;
        }
        catch (error) {
          current.error = error;
          Core.showError(error);
        }
        current.busy = false;
      }
    });
  }
  return (
    <Chip
      backgroundColor={color}
      labelColor={
        selected ? "#FFF" : !selected && model.selected ? "#CCC" : "#FFF"
      }
      style={{
        margin: 4,
        display: "inline-block",
        border: selected
          ? "1px solid #536DFF"
          : !selected && model.selected
            ? "1px dotted #7A7A7A"
            : "1px solid #536DF"
      }}
      onClick={(!!hasEngagement && !!model.selected) ? () => {
        alert('Sorry, You cannot delete jobs with Engagements')
      } :
        (!selected && model.selected) ? () => {
          alert('you have already picked this job')
        } : onClickHandler}
    >
      <Box row noWrap>
        <Typography mr>
          {_label}
        </Typography>
        <Icon
          icon='info'
          title={
            Core.isAdmin() && (
              <>
                <strong>{_label}</strong>
                <Html
                  value={tooltip}
                  className='c-white-all mt-1'
                  style={{ minWidth: sizes.m5, maxWidth: sizes.m7 }}
                />
              </>
            )
          }
          placement={PLACEMENT__TOP_START}
          className='icon16'
        />
      </Box>
    </Chip>
  );
}
