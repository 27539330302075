import { capitalize } from 'lodash';
import { join } from './Array.lib';
import Core from "./Core";
import Definition, {
  NEGATIVE_SIGNALS__CODDING_BOOTCAMP,
  NEGATIVE_SIGNALS__LACKING_4_YEAR_DEGREE,
  POSITIVE_SIGNALS__COMPUTER_DEGREE,
  POSITIVE_SIGNALS__ELITE_UNIVERSITY,
  POSITIVE_SIGNALS__RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE,
  POSITIVE_SIGNALS__STRONG_UNIVERSITY,
  POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY
} from './Definition';
import Http from "./Http";
import {
  SIGNALS_TYPE__SCHOOL,
  mapOrganizationSignals,
  mapOrganizationTenureSignals,
  mapTenure
} from '../components/Candidates/OrganizationSignals.lib';

export const POSITIVE_SCHOOL_SIGNAL_TAG_IDS = [
  POSITIVE_SIGNALS__ELITE_UNIVERSITY,
  POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY,
  POSITIVE_SIGNALS__STRONG_UNIVERSITY,
  POSITIVE_SIGNALS__RANKED_UNIVERSITY
];

export const POSITIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED = [
  POSITIVE_SIGNALS__COMPUTER_DEGREE,
  POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE,
];

export const NEGATIVE_SCHOOL_SIGNAL_TAG_IDS = [
  NEGATIVE_SIGNALS__CODDING_BOOTCAMP
];

export const NEGATIVE_SCHOOL_SIGNAL_TAG_IDS__EXTENDED = [
  NEGATIVE_SIGNALS__LACKING_4_YEAR_DEGREE
];

const School = {

  post: async (data, success) => {
    data = mapSchoolBack(data);
    return Http.post(
      Core.getApi("School/"),
      data,
      success
    ).then(mapSchool);
  },
  update: async (schoolId, data, success) => {
    data = mapSchoolBack(data);
    return Http.patch(
      Core.getApi("School/" + schoolId),
      data,
      success
    ).then(mapSchool);
  },
  get: async (opts, cb = response => null) => {
    let { include, where, fields, limit } = opts;
    if (!include) {
      include = {};
    }
    if (!where) {
      where = {};
    }

    if (!fields) {
      fields = {};
    }

    return Http.get(
      Core.getApi("School"),
      {
        filter: JSON.stringify({ include, where, fields, limit }),
      },
      function onSuccess(response) {
        cb(mapSchools(response));
      }
    ).then(mapSchools);
  },
  delete: async schoolId => Http.delete(Core.getApi(`School/${schoolId}`)),
};

export default School;

export async function getSchool({
  id,
  name,
  exact = false,
  query
}) {
  if (!id && !name && !query) { return null; }
  let where = (
    id
      ? { id }
      : exact
        ? {
          or: [
            { universityName: { inq: [name] } },
            { alternativeNames: { inq: [name] } },
          ],
        }
        : {
          or: [
            { universityName: { like: name, options: 'i' } },
            { alternativeNames: { like: name, options: 'i' } }
          ]
        }
  );
  return Http.get(
    Core.getApi('School'),
    {
      filter: JSON.stringify(query || {
        where,
        include: {
          relation: "schoolRating",
          scope: {
            fields: [
              'degree',
              'rating',
              'department',
              'signals'
            ],
          },
        },
      }),
    }
  ).then(schools => schools[0]).then(mapSchool);
}

/**
 * @param {object} options
 * @param {string} options.schoolId 
 * @param {string} options.tenureStart "YYYY" | "YYYY-MM" | "YYYY-MM-DD"
 * @param {string} options.tenureEnd "YYYY" | "YYYY-MM" | "YYYY-MM-DD"
 * @param {string} options.description
 * @param {object} options.bulkList [{schoolId,tenureStart,tenureEnd}]
 */
export async function getSchoolSignals({
  schoolId,
  tenureStart,
  tenureEnd,
  description,
  bulkList = [],
  reset,
}) {
  if (schoolId) {
    return Http.post(Core.getApi(`School/${schoolId}/_signals`), reset ? { reset } : { tenureStart, tenureEnd, description });
  }
  else if (bulkList.length) {
    return Http.post(Core.getApi(`School/_signals`), bulkList).then(mapSchoolTenureSignals);
  }
}

export async function fetchEducationHistorySignals({ educationHistories = [], revisionDate }) {
  if (!educationHistories.length) { return {}; }
  return getSchoolSignals({
    bulkList: educationHistories
      .filter(({ schoolId }) => !!schoolId)
      .map(mapTenure)
  });
}

export async function getSchoolDuplicates({
  name,
  exact = false
}) {
  if (!name) { return null; }
  let where = (
    exact
      ? {
        or: [
          { universityName: { like: `^${name}$`, options: 'i' } },
          { alternativeNames: { like: `^${name}$`, options: 'i' } },
        ],
      }
      : {
        or: [
          { universityName: { like: name, options: 'i' } },
          { alternativeNames: { like: name, options: 'i' } }
        ]
      }
  );
  return Http.get(
    Core.getApi('School'),
    {
      filter: JSON.stringify({ where }),
    }
  ).then(mapSchools);
}

export function mapSchool(school) {
  if (!school) { return null; }
  school.name = school.universityName || school.name;
  school.schoolRating = (school.schoolRating || [])[0] || {};
  school.alternativeNames = (
    Array.isArray(school.alternativeNames)
      ? school.alternativeNames
      : []
  );
  school.signalTags = mapOrganizationSignals({
    signals: school.signalTags || {},
    type: SIGNALS_TYPE__SCHOOL
  });
  return school;
}

export function mapSchools(schools) {
  schools = Array.isArray(schools) ? schools : [];
  return schools.map(mapSchool).filter(v => !!v);
}

export function mapSchoolBack(school = {}) {
  school.universityName = school.name || school.universityName;
  delete school.name;
  delete school.schoolRating;
  let signalTags = { ...school.signalTags };
  delete signalTags.automated
  delete signalTags.positive;
  delete signalTags.negative;
  school.signalTags = signalTags;
  Object.keys(school).forEach(key => {
    if (key.match(/^_/)) {
      delete school[key];
    }
  });
  return school;
}

export function mapSchoolTenureSignals(__dicSignals = {}) {
  let hash = {};
  Object.entries(__dicSignals).forEach(([key, value]) =>
    hash[key] = mapOrganizationTenureSignals({
      signals: value,
      type: SIGNALS_TYPE__SCHOOL
    })
  );
  return hash;
}

export function getDegreeId({ schoolName, degreeName, degreeMajor }) {
  let tag = Definition.getTag({
    categoryKey: 'undergraduateDegree',
    tagLabel: degreeName
  })
  const result = join(
    [
      schoolName,
      tag?.label || degreeName,
      capitalize(degreeMajor)
    ], ' - '
  );
  return result;
}

export function evalDefaultDegree({ candidate, education, degree }) {
  return candidate.undergraduateSchool && (
    getDegreeId({
      schoolName: candidate.undergraduateSchool,
      degreeName: candidate._undergraduateDegree,
      degreeMajor: candidate.undergraduateMajor
    }) ===
    getDegreeId({
      schoolName: education.schoolName,
      degreeName: degree.degreeName,
      degreeMajor: degree.degreeMajor
    })
  );
}

export async function fixSchoolName({ school = {}, organization = {} }) {
  if (
    school.id
    &&
    organization.id
    &&
    (school.crunchbaseOrganizationId === organization.id)
    &&
    school.name.toLowerCase() !== organization.name.toLowerCase()
  ) {
    school.alternativeNames = [
      ...new Set([
        ...(school.alternativeNames || []),
        school.name
      ])
    ];
    school.name = organization.name;
    return School.update(school.id, school);
  }
  return school;
}

export async function saveSchool({ school, entity }) {
  school = school || entity;
  let signalTags = { ...school.signalTags };
  delete signalTags.automated.entity;
  delete signalTags.automated.tenure;
  delete signalTags.positive;
  delete signalTags.negative;
  return School.update(school.id, {
    name: school.name,
    description: school.description,
    alternativeNames: school.alternativeNames,
    signalTags,
    updatedBy: Core.getUserId()
  });
}
