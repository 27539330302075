
/**
 * @param TEAM_NAME
 * @param TEAM_PHONE
 * @param TEAM_EMAIL
 * @param TEAM_SLOGAN
 */
export const TEMPLATE__EMAIL_SIGNATURE = (`
  <p>
    <b>{{TEAM_NAME}}</b><br>
    <a href="tel:{{TEAM_PHONE}}">
      {{TEAM_PHONE}}
    </a>&nbsp;|&nbsp;
    <a href="mailto:{{TEAM_EMAIL}}">
      {{TEAM_EMAIL}}
    </a>&nbsp;|&nbsp;{{TEAM_SLOGAN}}
  </p>
`);
