import Core from "../../lib/Core";
import EngagementDisagreement from "../../lib/EngagementDisagreement";
import {
  QUERY__MATCH_DECISIONS
} from '../queries/MatchDecisions.query';
import {
  sendSafeEmail
} from '../services/Email/Email.lib';
import {
  compileText
} from '../String.lib';

const getHumanAnnotatorFields = (disagreement, engagement) => {
  let makeString = "";

  if (!disagreement) {
    return makeString;
  }

  let annotator = true;

  Object.keys(disagreement).forEach(obj => {
    if (!!engagement && Object(engagement.matchedByWho).email === obj) {
      annotator = false;
    } else {
      annotator = true;
    }

    makeString += `<li>`;
    makeString += ` <a href='${disagreement[obj].matchingUrl}'>
                        ${disagreement[obj].annotator} { ${disagreement[obj].matchDecision} } 
                        </a>
                        ${!annotator ? ` 
                        | 
                        <a href='${disagreement[obj].engagementUrl}'>
                            ${!!disagreement[obj].engagementStage ? disagreement[obj].engagementStage : "{ no stage }"} 
                            | ${!!disagreement[obj].engagementStatus ? disagreement[obj].engagementStatus : "{ no status }"} 
                            | ${!!disagreement[obj].matchDecision ? disagreement[obj].matchDecision : " { no decision } "}
                        </a>
                        ` : ""}
                        - ${!!annotator ? 'annotator' : 'matcher'} 
                        - ${!!disagreement[obj].createdAt ? disagreement[obj].createdAt.substring(0, 10) : new Date().toISOString().substring(0, 10)}${!!disagreement[obj].reviewed ? " - Reviewed : " + disagreement[obj].reviewed : ""}`;
    makeString += `</li>`;
  });
  return `<tr><td><ul>${makeString}</ul></td></tr>`;
}

const sendAnnotatorEmail = (
  { annotatorMatchStrength, matcherMatchStrength, matcherEmail, disagreementObj, engagement }
) => {

  const fetchHistoryCb = (disagreementHistory) => {
    let formatted = {};

    disagreementHistory.forEach(engagement => {
      if (!!engagement.annotator && disagreementObj.annotator !== engagement.annotator) {
        formatted[engagement.annotator] = engagement;
      }
    });

    const newHumanData = getHumanAnnotatorFields({ [disagreementObj.annotator]: disagreementObj }, engagement);
    const oldHumanData = getHumanAnnotatorFields(formatted, engagement);

    const annotatorEmail = Object(Core.getSession().user);
    const matcherNewEmail = !!matcherEmail ? matcherEmail : "";

    const from = Core.getNewCandidateReceiverNotiFromEmail();
    const to = [Object(annotatorEmail).email, matcherNewEmail, Core.getDraftCandidateReceiverNotiToEmail()];
    // const cc = "null";
    const subject = "Annotator Feedback";

    // const mainLabel = !!engagement ? `matcher(${engagement.matchedByWho.email}) says match strength is {${matcherStrength}} in engagement, but annotator(${disagreementObj.annotator}) says ${annotatorStrength}` : `no engagement found, but annotator says ${annotatorStrength}`;
    const mainLabel = '';
    const statusLabel = !!engagement ? `<tr><td>Status is ${engagement.status}, Stage is ${engagement.stage}</td></tr>` : '';

    let disagreementModel = "<ul>";
    if (!!engagement) {
      disagreementModel += `<li>Matched By: ${engagement.matchedByWho.email}</li>`
    }
    Object.keys(disagreementObj).filter(obj => !!disagreementObj[obj]).forEach(obj => {
      disagreementModel += `<li>${obj} : ${disagreementObj[obj]}</li>`;
    });

    disagreementModel += "</ul>";

    let html = `<html>
    <head></head>
    <body>
        
        <table style="width:60%" border="1">
          <tr>
            <th>Annotator Feeaback</th>
          </tr>
          <tr>
            <td>
              ${mainLabel}
            </td>
          </tr>
          ${statusLabel}
          ${newHumanData}
          ${oldHumanData}
          <tr>
            <td>${disagreementModel}</td>
          </tr>
        </table>
    </body></html>`;

    to.filter(el => !!el).forEach(el => {
      let params = { from, to: el, cc: null, subject, html };
      Core.log("Email notification for annotator feedback", params);
      sendSafeEmail({ ...params, source: 'annotatorActions.js line 115' }, null, Core.showError);
    });
  }

  fetchHistory(disagreementObj, fetchHistoryCb);


}

const fetchHistory = (disagreement, cb) => {
  if (!disagreement.engagementId) {
    return Core.showError(
      `Missing disagreement's engagement id (${disagreement.engagementId})`,
      new Error()
    );
  }
  EngagementDisagreement.get(cb,
    compileText(
      QUERY__MATCH_DECISIONS,
      { ENGAGEMENT_ID: disagreement.engagementId }
    )
  );
}

const annotatorActions = {
  sendAnnotatorEmail
}

export default annotatorActions;