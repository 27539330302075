import { newModel } from "../GenericTools.lib";

/**
 *
 * @param {object} options Optional
 * @param {boolean} options.extended
 * @returns {object} A new model
 */
function getTemplateModel({
  extended: extendedModelRequired,
  render: renderModelRequired
} = {}) {
  const model = {
    name: "",
    type: "",
    templates: {}
  };

  const extended = {
    id: null,
    ...model
  };

  const renderModel = {
    mixins: {
      from: "",
      to: [],
      cc: [],
      bcc: []
    },
    rendered: {
      bodyHtml: '',
      subject: ''
    }
  }
  return newModel(
    extendedModelRequired
      ? extended
      : renderModelRequired
        ? renderModel
        : model
  );
}

const mapTemplate = item => {
  const extended = getTemplateModel({ extended: true });
  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (extended[key] = item[key])
    );
  }
  return extended;
}

const mapTemplates = data => {
  return data.map(item => {
    const emailTemplate = mapTemplate(item);
    return {
      ...emailTemplate
    };
  });
};

export {
  getTemplateModel as default,
  getTemplateModel,
  mapTemplate,
  mapTemplates,
};
