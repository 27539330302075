import {
  Arr
} from '../../../../lib/Array.lib';
import Definition from "../../../../lib/Definition";

const getIdsOfGroupings = (args) => {
  let defRaw = Definition.getRawDef(!!args.key ? args.key : "technicalSkills");
  let groupEntry = Array.isArray(defRaw.groups)
    ? defRaw.groups.find(group => group.id === args.value)
    : {};

  if (!!groupEntry.ids) {
    return groupEntry.ids;
  }
  return [];
}

const result = (chip, toMatchWith) => {
  const groupedIds = getIdsOfGroupings(chip);

  if (!!chip.category && chip.category === "technicalSkills") {
    const additionalMappers = [
      ...Arr(toMatchWith[chip.category]),
      ...Arr(toMatchWith['_strongTechnicalSkills']),
      ...Arr(toMatchWith.techSkillsInResume)
    ];
    return !!additionalMappers.map(chip => parseInt(chip)).filter((chip) => groupedIds.includes(chip)).length;
  }

  if (!!Arr(toMatchWith[!!chip.key ? chip.key : "technicalSkills"]).map(chip => parseInt(chip)).filter((chip) => groupedIds.includes(chip)).length) {
    return true;
  }

  return false;
}

const ChipGroup = {
  result,
  getIdsOfGroupings
}

export default ChipGroup;
