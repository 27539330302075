import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog
} from '@mui/material';
import {
  uniqueId
} from 'lodash';
import React from 'react';
import {
  sanitizeArr
} from '../../../lib/Array.lib';
import {
  Fun
} from '../../../lib/Function.lib';
import useState from '../../../lib/hooks/useState.hook';
import {
  joinClassName
} from '../Libraries/Theme.lib';
import Box from './Box';
import Draggable from './Draggable';
import Transition from './Transition';
import Typography from './Typography';

/**
 * Custom Dialog component with drag-and-drop functionality and transition effects.
 *
 * @param {object} props
 * @param {string} [props.name] Unique identifier for the dialog
 * @param {string} [props.title] Title of the dialog
 * @param {boolean} [props.acl=true] Access control flag
 * @param {boolean} [props.open=false] Control the open/close state of the dialog
 * @param {React.ReactNode[]} [props.actions] Array of action elements
 * @param {React.ReactNode} [props.actionBar] Optional action bar content
 * @param {React.ReactNode} [props.children] Content to be displayed inside the dialog
 * @param {React.ReactNode} [props.content] Main content of the dialog
 * @param {string} [props.message] Message to be displayed
 * @param {React.CSSProperties} [props.paperStyle] Styles for the Paper component
 * @param {React.ElementType} [props.PaperComponent] Custom Paper component
 * @param {object} [props.PaperProps] Props to be passed to the Paper component
 * @param {string} [props.contentStyle] Inline styles for the content
 * @param {boolean} [props.displayActions=true] Flag to display actions
 * @param {Function} [props.onClose=Fun] Callback function for close event
 * @param {boolean} [props.alignLeft=false] Flag to align content to the left
 * @param {boolean} [props.keepMounted=false] Keep the dialog mounted
 * @returns {React.Element} The rendered dialog component
 * @see https://mui.com/material-ui/api/dialog/
 */
export default function Dialog({
  name = uniqueId(),
  title,
  acl = true,
  open: propsOpen = false,
  actions = [],
  actionBar,
  children = null,
  content = null,
  message = null,
  PaperComponent: propsPaperComponent,
  PaperProps = {},
  className = '',
  paperStyle = {},
  displayActions = true,
  onClose = Fun,
  alignLeft = false,
  keepMounted = false,
  ...props
}) {
  const _default = { open: propsOpen }
  const [{ open }, updateState] = useState(_default, _default);

  if (!acl) { return null; }

  actions = sanitizeArr(actions);

  const wrapperKey = `wrappers__dialog__${name}`;

  const _close = async (event) => {
    await updateState({ open: false });
    onClose(event);
  };

  const _props = {
    ...props,
    TransitionComponent: Transition,
    PaperComponent: Draggable,
    PaperProps: {
      ...PaperProps,
      dialogId: wrapperKey,
      className: joinClassName([
        'flex',
        className
      ]),
      style: {
        minWidth: 320,
        maxWidth: 'calc(100vw - 2rem)',
        zIndex: 99999,
        ...paperStyle
      },
    },
    keepMounted,
  };

  return (
    <MuiDialog
      /**
       * @note
       * Should we prioritize the external flag? Some legacy 
       * implementations forget to set the "open" flag to false when
       * the dialog is externally controlled OR set `open=true`
       * permanently for custom use. As a result, the dialog can remain
       * permanently closed after the first close event (unlikely behavior).
       * We should keep looking for odd behaviors about this.
       */
      open={(propsOpen || open)}
      onClose={_close}
      aria-labelledby={wrapperKey}
      aria-describedby={`${wrapperKey}__dialog_description`}
      {..._props}
    >
      {title && (
        <DialogTitle
          id={wrapperKey}
          role='Dialog__Title'
          className='c-purple cursor-move'
        >
          {title}
        </DialogTitle>
      )}
      <Box flex1 scroll>
        {children ||
          <DialogContent
            className='bg-main p-2'
            style={{ minHeight: 120 }}
          >
            <Box row w100
              acl={!!message}
              className={alignLeft ? 'flex-align-left-top' : 'flex-align-center'}
            >
              <Typography blackDark large>
                {message}
              </Typography>
            </Box>
            <Box column w100
              acl={!!content}
            >
              {content}
            </Box>
          </DialogContent>
        }
      </Box>
      {(displayActions && (actionBar || actions.length > 0)) && (
        <DialogActions>
          {actionBar && (
            <Box row wAuto className='mr-auto'>
              {actionBar}
            </Box>
          )}
          {actions.map((actionElement, index) => (
            <Box row wAuto key={`${wrapperKey}__action__${index}`} onClick={_close}>
              {actionElement}
            </Box>
          ))}
        </DialogActions>
      )}
    </MuiDialog>
  );
}
