import {
  Tooltip as MuiTooltip,
  tooltipClasses
} from '@mui/material';
import {
  styled
} from '@mui/system';
import {
  mapWrapper
} from '../Libraries/Theme.lib';
import {
  RefBox
} from './Box';

export const PLACEMENT__BOTTOM_END = 'bottom-end';
export const PLACEMENT__BOTTOM_START = 'bottom-start';
export const PLACEMENT__BOTTOM = 'bottom';
export const PLACEMENT__LEFT_END = 'left-end';
export const PLACEMENT__LEFT_START = 'left-start';
export const PLACEMENT__LEFT = 'left';
export const PLACEMENT__RIGHT_END = 'right-end';
export const PLACEMENT__RIGHT_START = 'right-start';
export const PLACEMENT__RIGHT = 'right';
export const PLACEMENT__TOP_END = 'top-end';
export const PLACEMENT__TOP_START = 'top-start';
export const PLACEMENT__TOP = 'top';

export function getTooltipDefaultStyle(style) {
  return {
    maxWidth: 'calc(100vh - 2rem)',
    maxHeight: 'calc(100vh - 2rem)',
    backgroundColor: colors.black.darker,
    color: colors.white.dark,
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    fontSize: '0.775rem',
    padding: 10,
    ...style
  };
}

/**
 * @param {string} props.title
 * @param {string} props.placement  
 * 'bottom-end'
 * | 'bottom-start'
 * | 'bottom'
 * | 'left-end'
 * | 'left-start'
 * | 'left'
 * | 'right-end'
 * | 'right-start'
 * | 'right'
 * | 'top-end'
 * | 'top-start'
 * | 'top'
 * @see https://mui.com/material-ui/api/tooltip/
 */
export default function StyledTooltip({
  paperStyle = undefined,
  // ---- //
  title = '',
  titleStyle = paperStyle = {},
  placement = PLACEMENT__BOTTOM,
  children = RefBox,
  // ---- //
  wrapperProps = {},
  ...props
}) {
  mapWrapper({
    role: 'Tooltip',
    props: wrapperProps,
    assign: {
      ...props,
      title,
      placement,
      children: <RefBox className='scroll-y'>{children}</RefBox>
    }
  });
  const Component = styled(
    (styledProps) =>
      <MuiTooltip {...styledProps} arrow
        classes={{ popper: styledProps.className }}
      />
  )({
    [`& .${tooltipClasses.tooltip}`]: getTooltipDefaultStyle(titleStyle),
    [`& .${tooltipClasses.arrow}`]: { color: colors.black.darker },
  });
  return <Component {...wrapperProps} />;
}
