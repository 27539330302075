import {
  STAGE_ONSITE,
  STAGE_SCREEN
} from '../../dictionaries/Engagement.dic';
import {
  isValidDate
} from '../Date.lib';

export default function getInterviewLabel({
  engagement = {},
}) {
  return (
    {
      [STAGE_SCREEN]: `${STAGE_SCREEN} ${[
        isValidDate(engagement.screen1),
        isValidDate(engagement.screen2),
        isValidDate(engagement.screen3)
      ].filter((v) => !!(v)).length}`,
      [STAGE_ONSITE]: `${STAGE_ONSITE} ${[
        isValidDate(engagement.onsite1),
        isValidDate(engagement.onsite2)
      ].filter((v) => !!(v)).length}`,
    }[engagement.stage]
  )
}
