/** ============================================ µ
 * @Description Greenhouse [JS]
 *              Library
 * @createdAt   2021-03-22
 * @updatedAt   2021-05-12
/** =========================================== */

/* IMPORTS ==================================== */

import Http from "../../Http";
import harvest from "./Greenhouse.harvest.lib";
import ingestion from "./Greenhouse.ingestion.lib";

/* CONSTANTS ================================== */

/* FAILURES MESSAGES */
const MSG_NO_AGENCY_FOUND = 'No 10x10 agency found';
const MSG_NO_USER_FOUND = 'No username found';
const MSG_UNEXPECTED_RESPONSE = 'Unexpected response from Greenhouse';

/* SUGGESTIONS MESSAGES */
const MSG_CONTACT_ADMIN = 'Contact to a 10x10 Admin';
const MSG_REVIEW_SETTINGS = 'Review your ATS settings';
const MSG_REVIEW_USER_ID = 'Review your ATS User id (On-Behalf-Of)';
const MSG_FIX_YOUR_SELF = 'Fix it, before submit to ATS.';
const MSG_VERIFY_AGENCY = `Please verify the Agency Name exists on your ATS Sources List and it's type is "agency".`;
const MSG_VERIFY_USER = `Please verify the User Name exists on your ATS Users List.`;

/** 
 * It must be verified that the following file still exists, from time to time.
 */
const TEST_RESUME_URL = "https://d101010-api.go10x10.com/api/ImportContainer/dev-resume-storage%2F605285610dce991a112a0c27_602fe6991e356960535d4747/download/_SPIDER_CRAB_20210317_164140.pdf";


const GREENHOUSE_SOURCE_TYPE_AGENCY_ID = 4000007004;
const GREENHOUSE_SOURCE_TYPE_AGENCY_LABEL = "Agencies";

const DEFAULT_REFERRER_VALUE = "10x10 Recruiter";

/* METHODS ==================================== */

/**
 * Wrapper for Greenhouse requests
 * 
 * to handle errors and other messages
 * 
 * @param {object} options Object with request options (url, method, body).
 * @param {array} failures Array of strings, colleted failures from mapping candidate.
 * @param {function} onSuccess Function to execute on success
 * @param {function} onFailure Function to execute on failure
 */
async function requestHandler({
  options,
  failures = [],
  onSuccess,
  onFailure
}) {

  const {
    url = '',
    method = '',
    body = {},
  } = options;

  /** µ FOR DEBUG PURPOSES */
  console.debug(
    'µ:Greenhouse::requestHandler',
    { options, failures }
  );
  /** */

  if (!!failures.length) {
    return onFailure && onFailure(
      failures,
      [MSG_FIX_YOUR_SELF]
    );
  }
  return Http[method.toLowerCase()](
    url,
    body,
    response => {

      /** µ FOR DEBUG PURPOSES */
      console.debug(
        'µ:Greenhouse::requestHandler',
        { response }
      );
      /** */

      if (response.errors) {
        const failures = response.errors.map(({ message }) => {
          if (message === 'Server error') {
            message = 'Greenhouse server failure';
          }
          return message;
        });
        return onFailure && onFailure(
          failures,
          failures.includes('Invalid OAuth Request')
            ? [MSG_REVIEW_SETTINGS]
            : (response.statusCode === 500)
              ? [MSG_REVIEW_USER_ID, MSG_CONTACT_ADMIN]
              : [MSG_REVIEW_SETTINGS, MSG_CONTACT_ADMIN],
          response.status || response.statusCode,
        );
      }

      onSuccess && onSuccess(response)
    },
    error => {

      /** µ FOR DEBUG PURPOSES */
      console.debug(
        'µ:Greenhouse::postRequest',
        { error }
      );
      /** */

      onFailure && onFailure(
        [error],
        [MSG_CONTACT_ADMIN],
        error.status || error.statusCode,
      );
    }
  );
}

/* DICTIONARIES =============================== */

const Greenhouse = {

  /* FAILURES */
  MSG_NO_AGENCY_FOUND,
  MSG_NO_USER_FOUND,
  MSG_UNEXPECTED_RESPONSE,

  /* SUGGESTIONS */
  MSG_CONTACT_ADMIN,
  MSG_REVIEW_SETTINGS,
  MSG_FIX_YOUR_SELF,
  MSG_VERIFY_AGENCY,
  MSG_VERIFY_USER,

  /* GREENHOUSE VALUES */
  SOURCE_TYPE_AGENCY_ID: GREENHOUSE_SOURCE_TYPE_AGENCY_ID,
  SOURCE_TYPE_AGENCY_LABEL: GREENHOUSE_SOURCE_TYPE_AGENCY_LABEL,
  DEFAULT_REFERRER_VALUE,

  /* OTHER CONSTANTS */
  TEST_RESUME_URL,

  /* METHODS */
  request: requestHandler,

  /* SUB-LIBRARIES */
  harvest,
  ingestion,

};

/* EXPORTS ==================================== */

export {
  Greenhouse as default,
  Greenhouse,
  harvest,
  ingestion,
};

/* NOTES ====================================== */

/** µ SAMPLE import and use * /

import Greenhouse from "./lib/services/ATS/Greenhouse.lib.js";
import * as Greenhouse from "./lib/services/ATS/Greenhouse.lib.js";
import { Greenhouse } from "./lib/services/ATS/Greenhouse.lib.js";

Greenhouse.harvest.addCandidate(...);
Greenhouse.ingestion.addCandidate(...);

/** */