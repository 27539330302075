import {
  capitalize,
} from "@mui/material";
import {
  cloneDeep
} from 'lodash';
import moment from "moment";
import React, {
  Component,
  useState
} from "react";
import {
  useTranslation
} from "react-i18next";
import {
  SCHEDULE_DAY__FRIDAY,
  SCHEDULE_DAY__MONDAY,
  SCHEDULE_DAY__SATURDAY,
  SCHEDULE_DAY__SUNDAY,
  SCHEDULE_DAY__THURSDAY,
  SCHEDULE_DAY__TUESDAY,
  SCHEDULE_DAY__WEDNESDAY
} from '../../lib/Constants';
import Core from "../../lib/Core";
import {
  sortWeekDays,
  validateEmail
} from "../../lib/GenericTools.lib";
import getEmailModel from "../../lib/models/email.model";
import ConfirmDialog from "../Dialogs/ConfirmDialog";
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Chip from '../Layout/Wrappers/Chip';
import DatePicker from '../Layout/Wrappers/DatePicker';
import Fieldset from '../Layout/Wrappers/Fieldset';
import IconButton from '../Layout/Wrappers/IconButton';
import Row from "../Layout/Wrappers/Row";
import Switch from '../Layout/Wrappers/Switch';
import Typography, { Label } from '../Layout/Wrappers/Typography';
import EmailEntry from "./EmailEntry";

const DATE_FORMAT = 'YYYY-MM-DD';

function EmailList(props) {
  let {
    name = 'email_list',
    title = '',
    subtitle = '',
    model = {},
    onChange = () => null,
    messageTypeTags = [],
    disabledExclude,
    disabledCollapse,
    actionBar,
  } = props;

  const ConfirmDialogRef = React.useRef();
  const LastEmailEntryRef = React.useRef();
  const [expanded, setExpanded] = useState(disabledCollapse);
  const [reload, setReload] = useState();
  const { t } = useTranslation();

  const {
    emailsList = [],
    excludeAutomatedEmails,
    excludeAutomatedEmailsUntil,
    reminderEmailDays = []
  } = model;

  // eslint-disable-next-line no-unused-vars
  function scrollToLastEmailEntry() {
    setTimeout(() => {
      try {
        LastEmailEntryRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest"
        });
      } catch { }
    }, 100);
  }
  function _onChange(update) {
    onChange(update);
    console.debug(reload);
    setTimeout(() => setReload(new Date())); // this just trigger internal re-render
  }
  function onChangeEmailEntry({
    index,
    data = {}
  }) {
    emailsList[index] = { ...data };
    _onChange({ emailsList });
  }
  function _onChangeExcludedAutomatedEmails(excludeAutomatedEmailsUntil) {
    const update = { excludeAutomatedEmails: excludeAutomatedEmails === 1 ? 0 : 1 };
    Object.assign(model, update);
    _onChange(update);
  }
  function _onChangeExcludedAutomatedEmailsUntil(excludeAutomatedEmailsUntil) {
    const update = {
      excludeAutomatedEmailsUntil:
        excludeAutomatedEmailsUntil
          ? excludeAutomatedEmailsUntil.toISOString()
          : '',
    };
    Object.assign(model, update);
    _onChange(update)
  }
  function _onChangeScheduleDay(day) {
    return (event) => {
      let _reminderEmailDays = [...reminderEmailDays];
      if (reminderEmailDays.includes(day)) {
        _reminderEmailDays = _reminderEmailDays.filter(_day => _day !== day);
      }
      else {
        _reminderEmailDays.push(day);
      }
      _reminderEmailDays = sortWeekDays(_reminderEmailDays);
      const update = {
        reminderEmailDays: _reminderEmailDays,
      };
      Object.assign(model, update);
      _onChange(update);
    }
  }
  function onClickAddNewEmail(event) {
    setExpanded(true);
    emailsList.unshift(getEmailModel());
    setTimeout(() => _onChange({ emailsList }));
  }
  function onClickRemoveEmailEntry({
    index,
    data
  }) {
    const {
      name,
      email
    } = data;
    return event => {
      // method to remove emails from old job emails structure
      const removeFromOldStructure = (prevState, email) => {
        const update = {};
        if (email === prevState.resumeSubmissionEmail1) {
          update.resumeSubmissionName1 = '';
          update.resumeSubmissionEmail1 = '';
          update.resumeSubmissionEmailAction1 = '';
        }
        if (email === prevState.resumeSubmissionEmail2) {
          update.resumeSubmissionName2 = '';
          update.resumeSubmissionEmail2 = '';
          update.resumeSubmissionEmailAction2 = '';
        }
        if (email === prevState.resumeSubmissionEmail3) {
          update.resumeSubmissionName3 = '';
          update.resumeSubmissionEmail3 = '';
          update.resumeSubmissionEmailAction3 = '';
        }
        if (email === prevState.resumeSubmissionEmail4) {
          update.resumeSubmissionName4 = '';
          update.resumeSubmissionEmail4 = '';
          update.resumeSubmissionEmailAction4 = '';
        }
        return update;
      }
      const onConfirm = ev => {
        emailsList.splice(index, 1);
        let update = removeFromOldStructure(model, email);
        update.emailsList = emailsList;
        _onChange(update);
      };
      if (validateEmail(email)) {
        ConfirmDialogRef.current.open({
          message: (
            name && email
              ? `Do you want remove ${name || '?'} - ${email || '?'} from list?`
              : `Do you want remove the email entry number ${index + 1} from list?`
          ),
          onConfirm
        });
      }
      else {
        onConfirm();
      }
    }
  }
  if (disabledCollapse && !emailsList.length) {
    onClickAddNewEmail();
  }
  Core.setKeyValue('CurrentAddNewContact', onClickAddNewEmail);
  return (
    <div className={[
      !!disabledCollapse
        ? 'p-05 px-1'
        : 'bg-main p-1 m-2 rounded',
    ].filter(v => !!v).join(' ')}>
      {props.before}
      <Row
        className="d-flex w-100"
        style={{ marginBottom: 0 }}
      >

        <Box row w100 noWrap spaceBetween className="flex-align-left-top py-05">

          <Box column wAuto
            acl={!disabledCollapse}
          >
            <Label noWrap className="w-auto truncate">
              {title ? title : 'Emails List'}
            </Label>
            <Typography sub acl={!!subtitle} className='flex flex-align-left-top flex-no-wrap'>
              {subtitle}
            </Typography>
          </Box>

          <Box row flex1 wrap alignRight>

            <Box row wAuto noWrap mr
              title="Automated Emails"
              acl={!disabledExclude}
            >
              <Typography>Off</Typography>
              <Switch
                checked={!excludeAutomatedEmails}
                onChange={_onChangeExcludedAutomatedEmails}
              />
              <Typography>On</Typography>
            </Box>

            <Box row wAuto noWrap mr
              acl={!disabledExclude || !!model.reminderEmailDays}
              style={{ height: 36 }}
            >
              {[
                SCHEDULE_DAY__SUNDAY,
                SCHEDULE_DAY__MONDAY,
                SCHEDULE_DAY__TUESDAY,
                SCHEDULE_DAY__WEDNESDAY,
                SCHEDULE_DAY__THURSDAY,
                SCHEDULE_DAY__FRIDAY,
                SCHEDULE_DAY__SATURDAY
              ].map(day => (
                <Chip small
                  key={`${title}-${subtitle}-reminder-email-day-${day}`}
                  label={day.slice(0, 2)}
                  className='mr-1 capitalize'
                  color={
                    reminderEmailDays.includes(day)
                      ? 'primary'
                      : 'default'
                  }
                  title={
                    `${capitalize(day)} ${reminderEmailDays.includes(day)
                      ? 'is enabled'
                      : 'is disabled'
                    } for automated emails`
                  }
                  onClick={_onChangeScheduleDay(day)}
                />
              ))}
            </Box>

            <Box wAuto mr
              title="Exclude Automated Emails Until"
              acl={!disabledExclude}
            >
              <DatePicker
                value={excludeAutomatedEmailsUntil ? moment(excludeAutomatedEmailsUntil, DATE_FORMAT) : null}
                format={DATE_FORMAT}
                placeholder={t('emailsList.excludeUntil')}
                style={{ minWidth: 240 }}
                onChange={_onChangeExcludedAutomatedEmailsUntil}
                title="Exclude Automated Emails Until"
              />
            </Box>

          </Box>

          <Box row wAuto noWrap>
            {actionBar}
          </Box>

        </Box>

      </Row>

      {
        emailsList.map((data, index) => {
          let key = `${name}__email_entry__${index}_${data.email}_${data.name}`;
          return (
            <div ref={LastEmailEntryRef} key={key} data-key={key}>
              <EmailEntry
                model={model}
                data={data}
                index={index}
                messageTypeTags={messageTypeTags}
                onChange={onChangeEmailEntry}
                onRemove={onClickRemoveEmailEntry}
                expanded={!!expanded}
              />
            </div>
          );
        })
      }

      <ConfirmDialog
        ref={ConfirmDialogRef}
        title="Confirmation"
        message=""
        actionLabel="Confirm"
      />

    </div >
  );
}

/* EXPORTS ==================================== */

export class EmailsListClass extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
  }
  onCancel = () => {
    let { model } = this.props;
    model.emailsList = this.state.persisted.emailsList;
    model.reminderEmailDays = this.state.persisted.reminderEmailDays;
  }
  onSave = () => {
    if (this.props.onSave instanceof Function) {
      let { model } = this.props;
      if (model.emailsList.find(contact => contact.email.trim() && !validateEmail(contact.email))) {
        return Core.showWarning('Correct or remove the invalid email first!');
      }
      model.emailsList = model.emailsList.filter(contact => validateEmail(contact.email));
      this.props.onSave({ emailsList: model.emailsList });
    }
  }
  openDialog = ev => {
    this.setState({ persisted: cloneDeep(this.props.model) });
    Core.dialog.open({
      title: this.props.title || 'Emails List',
      content: (
        <EmailList
          name={this.props.name}
          model={this.props.model}
          onChange={this.props.onChange}
          messageTypeTags={this.props.messageTypeTags}
          disabledExclude
          disabledCollapse
        />
      ),
      actionBar: (
        <Button
          key={`action-button-add-new`}
          variant="outlined"
          color="secondary"
          className="mr-auto"
          style={{ minWidth: 120 }}
          onClick={ev => {
            let onClickAddNewEmail = Core.getKeyValue('CurrentAddNewContact');
            if (onClickAddNewEmail instanceof Function) { onClickAddNewEmail(); }
          }}
          startIcon={<i className="material-icons c-inherit">add</i>}
        >
          Add A New Contact
        </Button>
      ),
      actions: [
        <Button
          key={`action-button-cancel`}
          variant="outlined"
          color="secondary"
          style={{ minWidth: 120 }}
          onClick={this.onCancel}
        >
          Cancel
        </Button>,
        <Button
          key={`action-button-done`}
          variant="contained"
          color="primary"
          style={{ minWidth: 120 }}
          onClick={this.onSave}
        >
          Save
        </Button>
      ],
      paperStyle: { width: 900, maxWidth: 'unset' },
    })
  }
  render() {
    return (
      this.props.children
        ? (
          <Row className={this.props.className}>
            <Fieldset
              title={this.props.title}
              info={this.props.info}
              nextToTitle={(
                <>
                  <span className='mr-1'>
                    {this.props.nextToTitle}
                  </span>
                  <IconButton onClick={this.openDialog} size="small" aria-label="Edit Candidate Recruiter Contacts" className="hint--top">
                    <i className="material-icons">edit</i>
                  </IconButton>
                </>
              )}

            >
              {this.props.children}
            </Fieldset>
          </Row>
        ) : (
          <EmailList
            before={this.props.before}
            title={this.props.title}
            name={this.props.name}
            subtitle={this.props.subtitle}
            model={this.props.model}
            onChange={this.props.onChange}
            onSave={this.props.onSave}
            messageTypeTags={this.props.messageTypeTags}
            disabledExclude={this.props.disabledExclude}
            actionBar={
              <>
                {this.props.actionBar}
                <IconButton title='Edit list' onClick={this.openDialog} size="small">
                  <i className="material-icons">edit</i>
                </IconButton>
              </>
            }
          />
        )
    );
  }
}

export {
  EmailList as default,
  EmailList
};
