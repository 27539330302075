import {
  join
} from './Array.lib';

export function isObject(o) {
  return Object(o) === o;
}

/**
 * 
 * @param {any} variable 
 * @returns {object}
 */
export function getObject(variable) {
  return isObject(variable) ? variable : {};
}
export const Obj = getObject;

export const stringify = JSON.stringify;
export function safeStringify(obj) {
  const cache = new Set();
  return stringify(obj, (key, value) => {
    if (typeof value === 'bigint') {
      return value.toString();
    }
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return '[Circular]';
      }
      cache.add(value);
    }
    return value;
  });
}

export function deepMerge(obj1, obj2) {
  for (let key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
        // If the property is an object, perform a recursive call
        if (!obj1.hasOwnProperty(key) || typeof obj1[key] !== 'object' || obj1[key] === null || Array.isArray(obj1[key])) {
          obj1[key] = {};
        }
        obj1[key] = deepMerge(obj1[key], obj2[key]);
      } else {
        // If it's not an object, simply copy the value
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}

export function deepModelMerge(model, obj) {
  for (let key in model) {
    if (model.hasOwnProperty(key)) {
      if (typeof model[key] === 'object' && model[key] !== null && !Array.isArray(model[key])) {
        // If the property is an object, perform a recursive call
        if (!obj.hasOwnProperty(key) || typeof obj[key] !== 'object' || obj[key] === null || Array.isArray(model[key])) {
          obj[key] = {};
        }
        model[key] = deepMerge(model[key], obj[key]);
      }
      else if (obj[key] !== undefined) {
        // If it's not an object, simply copy the value
        model[key] = obj[key];
      }
    }
  }
  return model;
}

export function sanitizeObj(obj) {
  const sanitizedObj = {};
  [...Object.keys(obj)].forEach((key) => (!!obj[key] && (sanitizedObj[key] = obj[key])));
  return sanitizedObj;
}

export function getPersonName(person = {}) {
  return join([person.firstName, person.lastName], ' ');
}

export function flattenObject(obj, maxLength = 1000) {
  let result = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];

      // Check if the value is an object or an array and not null
      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          // Handle array
          for (let i = 0; i < value.length; i++) {
            if (typeof value[i] !== 'object' || value[i] === null) {
              result[`${key}[${i}]`] = value[i];
            }
          }
        } else {
          // Handle object
          for (let subKey in value) {
            if (value.hasOwnProperty(subKey)) {
              if (typeof value[subKey] !== 'object' || value[subKey] === null) {
                result[`${key}.${subKey}`] = value[subKey];
              }
            }
          }
        }
      } else {
        // Handle primitive values
        if (typeof value === 'string' && value.length > maxLength) {
          result[key] = value.substring(0, maxLength);
        } else {
          result[key] = value;
        }
      }
    }
  }
  return result;
}
