import {
  Accordion,
  Icon
} from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  cloneDeep
} from 'lodash';
import {
  useState
} from 'react';
import {
  PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER,
  PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN
} from '../../dictionaries/PrescreenQuestionsSectionUI.dic';
import {
  Arr
} from '../../lib/Array.lib';
import Core from '../../lib/Core';
import Job from '../../lib/Job';
import {
  Obj
} from '../../lib/Object.lib';
import ReactDialog from '../Forms/ReactDialog';
import Checkbox from '../Layout/Wrappers/Checkbox';
import Fieldset from '../Layout/Wrappers/Fieldset';
import {
  WarningChipMessage
} from '../Layout/Wrappers/Message';
import TextField from '../Layout/Wrappers/TextField';
import {
  PRESCREEN_QUESTIONS_CONFIG
} from './PrescreenQuestions.config';
import {
  QUESTION_ANSWER__SOURCE__EMPLOYER,
  QUESTION_ANSWER__SOURCE__GLOBAL,
  QUESTION_ANSWER__SOURCE__JOB,
  fetchQuestionFromBank,
  mapQuestionsAnswers
} from './PrescreenQuestions.lib';

export default function AnswerJobsQuestions(props) {
  let {
    name,
    onClose, // close
    onAcceptLabel, // nxtBtnText
    onCancelLabel, // cancelBtnText
    onAccept = ({ jobsPermittedAnswers }) => (event) => null, // handlerNext
    onCancel = ({ jobsPermittedAnswers = initialState }) => (event) => null, // handlerNextOnCancel
    onChange = update => null, // finalUpdater
    candidate = {},
    jobIds = [],
    extraComponentStructure,
    reference = self => null
  } = props;
  let [state, setState] = useState({});
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  let {
    initialState,
    jobId: filterJobId,
    globalQuestions,

    // {object[]} - jobs
    jobs = [],

    jobsPermittedAnswers = {}

  } = state;
  const _fetchJobs = async () => {
    await Job.getWhere({
      id: {
        inq: filterJobId
          ? Arr(jobIds).filter(jobId => jobId !== filterJobId)
          : Arr(jobIds)
      }
    }).then(async jobs => await _updateState({ jobs }));
  }
  const _fetchGlobalQuestions = async () => {
    await _updateState({ globalQuestions: Arr(await fetchQuestionFromBank()) });
  }
  let {
    globalAnswers = {},
    employerAnswers = {},
    jobAnswers = {},
    updateAnswer = () => null,
  } = mapQuestionsAnswers({
    globalQuestions,
    candidate,
    jobs
  });

  // console.debug('state', state);
  // console.debug('jobsPermittedAnswers', candidate.jobsPermittedAnswers);
  // console.debug('globalAnswers', globalAnswers);
  // console.debug('employerAnswers', employerAnswers);
  // console.debug('jobAnswers', jobAnswers);

  const _onChange = source => entityId => async update => {
    let jobsPermittedAnswers = updateAnswer({ source, entityId, update });
    Object.assign(candidate.jobsPermittedAnswers, jobsPermittedAnswers)
    await _updateState({ jobsPermittedAnswers });
    await onChange({ jobsPermittedAnswers, key: 'jobsPermittedAnswers' });
  };

  return (
    <ReactDialog
      name={`${name}__answer_jobs_questions`}
      reference={(self) => {
        reference({
          async open() {
            await _updateState({ initialState: cloneDeep(candidate.jobsPermittedAnswers) });
            await _fetchJobs();
            await _fetchGlobalQuestions();
            await self.open();
          },
          async close() {
            await self.close();
          },
        });
      }}
      title='Answer employer questions for each job(s)'
      onAcceptLabel={onAcceptLabel}
      onAccept={onAccept({
        jobsPermittedAnswers: Object.assign(
          Obj(candidate.jobsPermittedAnswers),
          jobsPermittedAnswers
        )
      })}
      onCancelLabel={onCancelLabel}
      onCancel={onCancel({ jobsPermittedAnswers: initialState })}
      onClose={onClose}
      paperStyle={{ minWidth: 900 }}
    >
      <div className='flex-column w-100'>
        <GlobalAnswers
          answers={globalAnswers}
          onChange={_onChange(QUESTION_ANSWER__SOURCE__GLOBAL)}
        />
        <AnswersList
          entityAnswers={jobAnswers}
          onChange={_onChange(QUESTION_ANSWER__SOURCE__JOB)}
          employerAnswers={employerAnswers}
          onChangeEmployer={_onChange(QUESTION_ANSWER__SOURCE__EMPLOYER)}
        />
        {extraComponentStructure && (
          <div className='mt-3'>
            {extraComponentStructure}
          </div>
        )}
      </div>
    </ReactDialog>
  );
}

function GlobalAnswers(props) {
  let { answers } = props;
  return !!Object.values(answers).length && (
    <SingleCard
      title={PRESCREEN_QUESTIONS_CONFIG.globalAnswers.title}
      subtitle={PRESCREEN_QUESTIONS_CONFIG.globalAnswers.subtitle}
      answers={answers}
      {...props}
    />
  );
}

function AnswersList(props) {
  let { entityAnswers } = props;
  let list = Object.values(entityAnswers);
  return list.map((item) => (
    <SingleCard
      key={`entity_answers-${item.title}`}
      title={item.title}
      answers={item.answers}
      entityId={item.id}
      employerId={item.employerId}
      {...props}
    />)
  );
}

function SingleCard(props) {
  let { title, subtitle, answers, entityId, onChange, employerId, employerAnswers, onChangeEmployer } = props;
  let [expanded, setExpended] = useState(true);
  return !!Object.values(answers).length && (
    <Accordion
      expanded={!!expanded}
      onChange={(event) => setExpended(!expanded)}
      className='my-05'
    >
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <div>
          <Typography className='fw-600'>{title}</Typography>
          <div className='f-md c-black-medium'>{subtitle}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'inline-block', width: '100%' }}>
        {employerId && <QuestionsList {...props} answers={Obj(employerAnswers)[employerId]?.answers} onChange={onChangeEmployer(employerId)} />}
        <QuestionsList {...props} answers={answers} onChange={onChange(entityId)} />
      </AccordionDetails>
    </Accordion>
  );
}

function QuestionsList(props) {
  let { answers } = props;
  return Object.values(answers).sort((a, b) => a.order - b.order).map(answer => {
    return (
      <SingleQuestion
        key={`answers_jobs_question_list_${answer.question}-${answer.answer}`}
        answer={answer}
        {...props}
      />
    );
  });
}

function SingleQuestion(props) {
  let { answer, onChange = () => null } = props;
  let [_answer, _setAnswer] = useState(answer.answer);
  return (
    <Fieldset
      title={
        answer.question
          ? (
            <div className='d-flex flex-align-left-center'>
              {Core.isAdmin() && answer.answerMandatory && <b className={`c-red mr-1`}>*</b>}
              {Core.isAdmin() && answer.askedBy && (
                <div className='f-lg fw-500 mr-1 d-flex flex-align-left-center'>
                  [
                  <div className='statement'>
                    {
                      answer.askedBy === PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.id
                        ? PRESCREEN_QUESTIONS__ASKED_BY__TEN_BYE_TEN.label
                        : (answer.askedBy === PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.id
                        ) ? PRESCREEN_QUESTIONS__ASKED_BY__EMPLOYER.label
                          : null
                    }
                  </div>
                  ]
                </div>
              )}
              <div className='statement f-lg fw-500'>{answer.question}</div>
            </div>
          )
          : (
            <WarningChipMessage>
              <div className='statement'>
                {answer.questionError || 'Missing question'}
              </div>
            </WarningChipMessage>
          )}
      subtitle={answer.instruction}
      classNameWrapper='p-0 mt-1'
      actionBar={Core.isAdmin() && (
        <Checkbox
          checked={!!answer.autoIncludeInWriteup}
          onChange={event => {
            answer.autoIncludeInWriteup = !answer.autoIncludeInWriteup;
            onChange(answer);
          }}
          labelProps={{ className: 'c-black-medium f-sm nowrap' }}
          wrapperProps={{ size: 'small' }}
          checkboxProps={{ size: 'small' }}
        >
          Include in submission
        </Checkbox>
      )}
    >
      <TextField disabledBlurExport
        style={{ height: 40 }}
        variant='standard'
        placeholder='Write your answer here'
        value={_answer}
        onChange={(event) => _setAnswer(event.target.value)}
        onBlur={(event) => {
          answer.answer = _answer;
          onChange(answer);
        }}
      />
    </Fieldset>
  );
}
