/** @todo to review and deprecate | 2021-09-23 Thu µ */

const model = {
  name: "",
  template_type: "",
  mjml: "",
  html: ""
};

const extended = {
  id: null,
  ...model
};

const mapEmailTemplate = item => {
  const emailTemplate = { ...extended };
  if (item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (emailTemplate[key] = item[key])
    );
  }
  return emailTemplate;
}

const mapEmailTemplates = data => {
  return data.map(item => {
    const emailTemplate = mapEmailTemplate(item);
    return {
      ...emailTemplate
    };
  });
};

export { extended as default, model, extended, mapEmailTemplate, mapEmailTemplates };
