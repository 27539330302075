import {
  Component
} from "react";
import {
  Fun
} from '../../lib/Function.lib';
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';

export default class CustomDialog extends Component {

  constructor() {
    super(...arguments);
    this.defaultState = {
      open: false,
      title: 'Message',
      message: null,
      content: null,
      children: null,
      actionBar: null,
      actions: undefined,
      paperStyle: {},
      onClose: Fun,
      alignLeft: false,
    }
    this.state = {
      ...this.defaultState
    };
  }

  open = ({
    title = 'Message',
    message = null,
    content = null,
    children = null,
    actionBar = null,
    actions = undefined,
    paperStyle = {},
    onClose = Fun,
    alignLeft = false,
  }) => {
    this.setState({
      open: true,
      title,
      message,
      content,
      children,
      actionBar,
      actions: actions ||
        this.props.actions || [
          <Button primary minW120
            onClick={this.close}
          >
            Ok
          </Button>
        ],
      onClose: () => this.close(),
      paperStyle,
      alignLeft,
    });
  };

  close = (event) => {
    this.setState(this.defaultState,
      /** 
       * @note this.state coming from current scope context and is not equals to the updated state.
       */
      () => this.state.onClose()
    );
  };

  render = () => (<Dialog {...this.state} />);

}
