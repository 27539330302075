import axios from 'axios';
import {
  NOT
} from '../../../../lib/Boolean.lib';
import {
  trim
} from '../../../../lib/String.lib';
import getRemoteCandidateResumePdfUrl from './getRemoteCandidateResumePdfUrl.fun';

const cache = {};
export async function fetchCandidateResumePdfFile(candidate) {
  const url = getRemoteCandidateResumePdfUrl(candidate);
  if (cache[url] === 'pending') { return null; }
  if (NOT(cache[url])) {
    try {
      cache[url] = 'pending';
      const response = await axios.get(url, {
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      });
      cache[url] = URL.createObjectURL(blob);
    }
    catch (error) {
      cache[url] = null;
    }
  }
  return cache[url];
}

export function getLocalCandidateResumePdfUrl(candidate) {
  const url = getRemoteCandidateResumePdfUrl(candidate);
  if (cache[url] === 'pending') { return ''; }
  return trim(cache[url]);
}
