
const MatchGroupsConfig = {
  selected: {
    key: 'selected',
    label: 'Selected',
    heading: 'Selected',
  },
  toMatch: {
    label: 'TO MATCH',
    key: 'toMatch',
    heading: 'Match',
  },
  toSubmit: {
    label: 'TO SUBMIT',
    key: 'toSubmit',
    heading: 'Submit',
  },
  toProcess: {
    label: 'TO PROCESS',
    key: 'toProcess',
    heading: 'Process',
  },
  jobInProgress: {
    label: 'JOBS IN PROGRESS',
    key: 'jobInProgress',
    heading: 'Active',
  },
  inActive: {
    label: 'INACTIVE',
    key: 'inActive',
    heading: 'Inactive',
  },
  noMatch: {
    label: 'NO MATCH - ANNOTATOR',
    key: 'noMatch',
    heading: 'NoMatch',
  },
  debug: {
    label: 'DEBUG',
  },
};
export default MatchGroupsConfig;
