import oops from '../assets/images/oops.png';
import {
  NOT
} from '../lib/Boolean.lib';
import {
  Str
} from '../lib/String.lib';
import {
  reloadLocation
} from '../lib/URL.lib';

export default function Error({ error }) {
  if (NOT(error)) { return null; }
  console.warn(error);
  setTimeout(() => reloadLocation(), 36000);
  try {
    error = error.toString();
  }
  catch {
    error = Str(error);
  }
  return (
    <div className="failure page main-padding">
      <div className="cols-2 main-padding centered-wrapper">
        <div id="article" className="c-black-medium">
          <h1 className="c-purple">We’ll be back soon!</h1>
          <div>
            <div className='my-1 big'>
              Sorry for the inconvenience but we’re performing some
              maintenance at the moment.
              <br />
              If you need to you can always contact us at&nbsp;
              <a className="pointer" href="mailto:support@10by10.com">
                support@10by10.com
              </a>
              , otherwise we’ll be back online shortly!
            </div>
            <div className='my-1'>— 10by10 Admin</div>
            <div className='my-1 error'>{error}</div>
          </div>
        </div>
        <div className="align-center">
          <img
            style={{ width: 480, maxWidth: '100%' }}
            src={oops}
            alt="Oops!"
          />
        </div>
      </div>
    </div>
  );
};
