import {
  Component
} from "react";
import Definition from "../../../lib/Definition";
import {
  joinKeyName
} from '../../../lib/String.lib';
import Chip from '../../Layout/Wrappers/Chip';
import {
  getEvaluationChipStyle
} from '../Haves/v2/Evaluations.lib';

class Stage extends Component {
  constructor() {
    super(...arguments);
    this.state = {};
  }

  getDisplayColor = (id, ids) => {

    if (!id || !ids) {
      return null;
    }

    if (ids.includes(id)) {
      return true;
    }

    return false;
  }

  getDisplayLabel = () => {
    const {
      candidateStage,
      jobStage,
      source
    } = this.props;

    let label = null;

    if (source === 'candidate') {
      label = candidateStage.map((id, index) => {
        return (
          <Chip
            key={joinKeyName([
              'stage_chip',
              'display_label',
              id,
              index
            ])}
            className="slim-chip"
            variant="outlined"
            size="small"
            style={getEvaluationChipStyle(this.getDisplayColor(id, [jobStage]))}
            label={Definition.getLabel('stage', id)}
          />
        );
      });
    }
    else if (source === 'job') {
      return (
        <Chip
          className="slim-chip"
          variant="outlined"
          size="small"
          style={getEvaluationChipStyle(this.getDisplayColor(jobStage, candidateStage))}
          label={Definition.getLabel('stage', jobStage)}
        />
      );
    }

    return label;
  }

  render() {
    const { candidateStage, jobStage, source } = this.props;
    if (source === 'candidate' && !candidateStage) {
      return null;
    }
    if (source === 'job' && !jobStage) {
      return null;
    }
    return this.getDisplayLabel();
  }
}

export default Stage;