import React, { Component, Fragment } from "react";
import Job from "../../lib/Job";

class PrintMlScore extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      ...this.props,
      color: 'red',
      text: '',
      mlProcessing: false,
      engagement: this.props.engagement,
      job: this.props.job,
      candidate: this.props.candidate,
      probScore: '',
      matchStatus: ''
    };
    this.disable = true;
  }

  componentWillUnmount() {
    this.disable = false;
  }

  componentDidMount() {
    if (this.disable && this.props.visible) {
      this.mlGetMatchingScores();
    }
  };

  mlGetMatchingScores = () => {
    let randomNum = parseFloat((Math.random() * 3).toFixed(2));

    if (this.disable) {
      this.setState({ mlProcessing: true, mlMatch: {} }, () => {
        setTimeout(this.fetchScore, randomNum * 8000)
      });
    }

  }

  fetchScore = () => {
    let { job, candidate } = this.state;

    if (this.disable) {
      Job.getMlMatchingScore({
        params: {
          jobId: job.id,
          candidateId: candidate.id
        }
      }, (response) => {
        if (this.disable) {
          this.setState({ mlMatch: response, mlProcessing: false }, () => {
            this.renderMyMlLabel()
          });
        }
      }, (failure) => {
        if (this.disable) {
          let mlMatch = { msg: 'service crashed' };
          this.setState({ mlMatch, mlProcessing: false }, () => {
            this.renderMyMlLabel()
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.disable && !prevProps.visible && this.props.visible) {
      this.mlGetMatchingScores();
    }
  }
  

  parseMlResponse = () => {

  }

  renderMyMlLabel = () => {
    let color = 'red';
    let text = 'ML not triggered yet';
    let hasValidMatch = false;
    let probScore = '';
    let mlMatch = Object(this.state.mlMatch);
    let matchStatus = '';

    if (this.state.mlProcessing) {
      text = 'Processing Score'
    }

    if (Object(mlMatch.match).match) {
      let myMatch = Object(mlMatch.match);

      if (String(myMatch.match) === 'submission') {
        color = 'green';
      }

      hasValidMatch = true;
      probScore = myMatch.score;
      matchStatus = myMatch.match;
      text = `Matching Score is : (${myMatch.match}) -  ${myMatch.score}`;
    } else {
      if (Object(mlMatch).msg) {
        text = 'Error:' + mlMatch.msg;
      }
    }

    if (this.disable) {
      this.setState({ text, color, hasValidMatch, probScore, matchStatus });
    }

    const { hasMIScoreArrived } = this.props;
    text = <Fragment>
      <p>
        <span style={{ color: `${color} !important`, fontSize: "10px !important" }}>
          {this.state.mlProcessing ? 'processing' : text}
          {hasValidMatch && <Fragment>
            <span>{' | '}</span>
          </Fragment>
          }
        </span>
      </p>
    </Fragment>;
    !!hasMIScoreArrived && hasMIScoreArrived(text);
  }


  render() {
    let { text, color, mlProcessing, hasValidMatch } = this.state;

    return <Fragment>
      <p style={{ display: this.props.visible ? 'unset' : 'none' }}>
        <span style={{ color: `${color} !important`, fontSize: "10px !important" }}>
          {mlProcessing ? 'processing' : text}
          {hasValidMatch && <Fragment>
            <span>{' | '}</span>
          </Fragment>
          }
        </span>
      </p>
    </Fragment>;
  }
}

export default PrintMlScore;
