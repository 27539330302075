import {
  Arr,
  join
} from '../../Array.lib';
import Core from '../../Core';
import Definition, {
  ATS_TYPE__LEVER_API_ID,
  DEFINITION_CATEGORY__LOCATION,
  EMPLOYER_SUBMISSION_METHOD__ATS
} from '../../Definition';
import Employer from '../../Employer';
import Http from '../../Http';
import {
  Obj,
  deepMerge
} from '../../Object.lib';
import {
  Str,
  convertHtmlToPlainText
} from '../../String.lib';
import { setLocation } from '../../URL.lib';
import {
  setURLQueryString
} from '../../URLQuery.lib';
import {
  COLLECTION__EMPLOYERS,
  readLoopbackRecord
} from '../BE/loopback.api';
import {
  sendSafeEmail
} from '../Email/Email.lib';

export const PROVIDER__NAME__LEVER = 'lever';

const responseHandler = (method, response, onSuccess, onFailure) => {
  if (!!response.error) {
    Core.failure({
      source: `Lever.lib.js`,
      exception: response.error,
      params: { method }
    });
    try {
      onFailure &&
        onFailure(`Lever.${method}:` + response.error.errors.pop().message);
    } catch (ex) {
      onFailure && onFailure(`Lever.${method}: ` + JSON.stringify(response));
    }
  } else {
    onSuccess && onSuccess(response);
  }
};

const Lever = {
  /**
   * 
   * @param {object} options 
   * @param {string} options.employerId 
   * @returns {boolean}
   */
  isConnected: async ({ employerId }) => {
    if (!employerId) {
      Core.showError(`Unexpected error: missing employerId`);
      return false;
    }
    return Http.get(
      Core.getApi('Credentials/'),
      {
        filter: JSON.stringify({
          where: {
            provider: PROVIDER__NAME__LEVER,
            $and: [
              { 'payload.employerId': employerId },
              { 'payload.refresh_token': { $exists: true } }
            ]
          },
          fields: ['id'],
          limit: 1
        })
      }
    ).then((result) => !!result.length);
  },
  connect: async ({ employerId }) => {
    if (!employerId) { return Core.showError(`Unexpected error: missing employerId`); }
    try { await Lever.disconnect({ employerId }); } catch { }
    setLocation(
      setURLQueryString({
        url: Core.getApi('Credentials/login'),
        update: {
          options: encodeURIComponent(
            JSON.stringify({
              PROVIDER: PROVIDER__NAME__LEVER,
              BE_REDIRECT: Core.getRedirectURI({ end: 'be' }),
              FE_REDIRECT: Core.getRedirectURI({ end: 'fe/employer', employerId }),
              EMPLOYER_ID: employerId,
              ACCOUNT_ID: Core.getUserId()
            })
          )
        }
      })
    );
  },
  disconnect: async ({ employerId, onSuccess = () => null, onFailure = () => null }) => {
    if (!employerId) { return Core.showError(`Unexpected error: missing employerId`); }
    return Http.post(
      Core.getApi('Credentials/api'),
      {
        provider: PROVIDER__NAME__LEVER,
        employerId,
        action: 'revokeToken'
      },
      response => responseHandler('disconnect', response, onSuccess, onFailure),
      error => onFailure('Lever.disconnect: ' + error)
    );
  },
  openEmployerSettings: ({ employerId }) => {
    Core.openPopUp(
      Core.getPath(`employer/settings/${employerId}`),
      1600
    );
  },
  /**
   * 
   * @param {object} options 
   * @param {string} options.employerId 
   * @returns {object}
   */
  getAtsContext: async ({ employerId }) => {
    return readLoopbackRecord({
      collection: COLLECTION__EMPLOYERS,
      where: { id: employerId },
      fields: ['atsContext'],
      limit: 1,
      mapper: ({ atsContext }) => Obj(Obj(atsContext)[ATS_TYPE__LEVER_API_ID])
    }).catch(Core.showError);
  },
  /**
   * 
   * @param {object} options 
   * @param {string} options.employerId 
   * @returns {boolean}
   */
  isAtsLeverApiSelected: async ({ employerId }) => {
    return readLoopbackRecord({
      collection: COLLECTION__EMPLOYERS,
      where: { id: employerId },
      fields: ['atsTypeId'],
      limit: 1,
      mapper: ({ atsTypeId }) => atsTypeId === ATS_TYPE__LEVER_API_ID
    }).catch(Core.showError);
  },
  updateAtsContext: async ({ employerId, update }) => {
    try {
      const atsContext = deepMerge(
        await readLoopbackRecord({
          collection: COLLECTION__EMPLOYERS,
          where: { id: employerId },
          fields: ['atsContext'],
          limit: 1,
          mapper: ({ atsContext }) => atsContext
        }),
        { [ATS_TYPE__LEVER_API_ID]: update }
      );
      await Employer.update(employerId, { atsContext });
      return atsContext[ATS_TYPE__LEVER_API_ID];
    }
    catch (error) {
      Core.showError(error);
      throw error;
    }
  },
  /**
   * 
   * @param {object} options 
   * @param {string} options.employerId
   * @param {object} options.params
   * @returns {object[]}
   */
  getSources: async (options = {}) => {
    const { employerId, params } = options;
    const requestOptions = {
      provider: PROVIDER__NAME__LEVER,
      employerId,
      action: 'getSources'
    };
    if (params) { requestOptions.params = params }
    return Http.post(Core.getApi('Credentials/api'), requestOptions)
      .then(async response => {
        const result = Arr(response.data);
        try {
          if (response.hasNext && response.next) {
            options.params = Obj(params);
            options.params.offset = decodeURIComponent(response.next);
            result.push(...await Lever.getSources(options));
          }
        }
        catch (error) { console.debug('Lever.getSources:pagination:error', error); }
        return result;
      })
      .catch(Core.showError);
  },
  /**
   * 
   * @param {object} options 
   * @param {string} options.employerId
   * @param {object} options.params
   * @returns {object[]}
   */
  getUsers: async (options = {}) => {
    const { employerId, params } = options;
    const requestOptions = {
      provider: PROVIDER__NAME__LEVER,
      employerId,
      action: 'getUsers',
    };
    if (params) { requestOptions.params = params }
    return Http.post(Core.getApi('Credentials/api'), requestOptions)
      .then(async response => {
        const result = Arr(response.data);
        try {
          if (response.hasNext && response.next) {
            options.params = Obj(params);
            options.params.offset = decodeURIComponent(response.next);
            result.push(...await Lever.getSources(options));
          }
        }
        catch (error) { console.debug('Lever.getUsers:pagination:error', error); }
        return result;
      })
      .catch(Core.showError);
  },
  submit: async (options = {}) => {
    const { candidate = {}, job = {}, emailParams = {} } = options;
    const { employerId, employer = {} } = job;
    const { candidateLocations = [], resumes = [] } = candidate;
    const postOptions = {
      provider: PROVIDER__NAME__LEVER,
      employerId,
      action: 'postOpportunity',
      context: {
        CANDIDATE__FULLNAME: Str(candidate._name),
        CANDIDATE__HEADLINE: convertHtmlToPlainText(Str(candidate.tagLine)),
        CANDIDATE__CURRENT_LOCATION: join(Definition.getLabels(DEFINITION_CATEGORY__LOCATION, candidateLocations)),
        CANDIDATE__PHONE: Str(candidate.phone),
        CANDIDATE__EMAIL: Str(candidate.email),
        CANDIDATE__LINKEDIN: Str(candidate.gitHubURL),
        CANDIDATE__GITHUB: Str(candidate.linkedInURL),
        LEVER__AGENCY_NAME: Str(Obj(Obj(employer.atsContext)[ATS_TYPE__LEVER_API_ID]).agencyName),
        LEVER__USER_ID: Str(Obj(Obj(employer.atsContext)[ATS_TYPE__LEVER_API_ID]).userId),
        LEVER__POST_ID: Str(Obj(Obj(job.atsContext)[ATS_TYPE__LEVER_API_ID]).postId),
        RESUME__URL: Str(Obj(resumes[0]).url),
        RESUME__FILENAME: Str(Obj(resumes[0]).filename),
      }
    };
    console.debug('candidate', candidate);
    console.debug('job', job);
    console.debug('ats', postOptions);
    return Http.post(Core.getApi('Credentials/api'), postOptions).then(response => {
      return response;
    }).then(async response => {

      // DISPLAY MESSAGE TO USER
      Core.showSuccess(`Added candidate to ${PROVIDER__NAME__LEVER} successfully!`);

      // SEND EMAIL TO ADMIN
      const emailOptions = {
        ...emailParams,
        to: Core.getResumeSubmissionBcc()
      };
      emailOptions.subject = (
        `Submitted via ${PROVIDER__NAME__LEVER} API${emailOptions.subject ? ` | ${emailOptions.subject}` : ''}`
      );
      await sendSafeEmail(emailOptions);

      // MAKE STAGE TRANSITION
      try {
        await Core.getKeyValue(
          'CandidateResumeSubmissionController'
        ).transitionAfterSubmission({
          submissionMethodId: EMPLOYER_SUBMISSION_METHOD__ATS,
          atsContext: {
            [employer.atsTypeId]: {
              opportunityId: Obj(response.data).id
            }
          }
        });
      }
      catch (error) { throw error; }

      return response;

    }).catch(error => {

      Core.showError(error);

      // SEND EMAIL TO ADMIN
      const emailOptions = {
        ...emailParams,
        to: Core.getResumeSubmissionBcc()
      };
      emailOptions.subject = (
        `ERROR | ${PROVIDER__NAME__LEVER} API failed${emailOptions.subject ? ` | ${emailOptions.subject}` : ''}`
      );
      emailOptions.html = (
        (`
          <p>
            --------------------------------------------------
          </p>
          <p>
            Error:
          </p>
          <p>
            ${error}
          </p>
          <p>
            --------------------------------------------------
          </p>
        `).replace(/\s{10}/g, '') +
        emailOptions.html
      );
      emailOptions.html = emailOptions.html.replace('<html>', '');
      emailOptions.html = emailOptions.html.replace('<head></head>', '');
      emailOptions.html = emailOptions.html.replace('<body>', '');
      sendSafeEmail(emailOptions);

    });
  }
};
export default Lever;
window.Lever = Lever;
