import _ from 'lodash';
import {
  Component
} from 'react';
import AppUI from '../../../../../dictionaries/AppUI.dic';
import Core from '../../../../../lib/Core';
import {
  Fun
} from '../../../../../lib/Function.lib';
import {
  NOT
} from '../../../../../lib/GenericTools.lib';
import Job from '../../../../../lib/Job';
import {
  Str
} from '../../../../../lib/String.lib';
import {
  getLocation,
  matchLocation
} from '../../../../../lib/URL.lib';
import {
  joinClassName
} from '../../../../Layout/Libraries/Theme.lib';
import Box from '../../../../Layout/Wrappers/Box';
import Button from '../../../../Layout/Wrappers/Button';
import Fieldset from '../../../../Layout/Wrappers/Fieldset';
import InputArray from '../../../../Shared/InputArray';
import OperatorDropdown from './Dropdowns/OperatorDropdown';
import MatchProperties from './Elements/MatchProperties/MatchProperties';
import PopupAdElement from './Elements/PopupAdElement';
import PrintSingleMustHave from './Elements/PrintSingleMustHave';

const Operators = [{ name: 'and' }, { name: 'or' }];

export default class JobRequirement extends Component {

  getLabels = (item) => {
    let catTypes = [];
    if (item.category === 'technicalSkills' || item.key === 'technicalSkills') {
      catTypes.push('technicalSkills');
    }
    if (item.category === 'workExperience' || item.key === 'workExperience') {
      catTypes.push('workExperience');
    }
    if (item.category === 'education' || item.key === 'education') {
      catTypes.push('education');
    }
    if (item.category === 'others' || item.key === 'others') {
      catTypes.push('others');
    }
    return catTypes;
  };

  sortMustHaves = (item) => {
    let categoryTypes = this.getLabels(item);
    if (categoryTypes.includes('technicalSkills')) {
      return 3;
    } else {
      return -1;
    }
  };

  getSortedHaves = (existing) => {
    let newObject = !!existing ? [...existing] : [];
    newObject.sort((a, b) => this.sortMustHaves(b) - this.sortMustHaves(a));
    return [...newObject];
  };

  handlerOperatorOption = (operator, key) => {
    const { onSave = Fun } = this.props;
    if (key === 'mustHave') {
      let mustHave = {
        type: operator,
        args: this.props.mustHave.args
      };
      !!onSave && onSave(mustHave, 'mustHave');
    } else if (key === 'niceToHave') {
      let niceToHave = {
        type: operator,
        args: this.props.niceToHave.args
      };
      !!onSave && onSave(niceToHave, 'niceToHave');
    }
  };

  submitHave = (key, have) => () => {
    const { jobId } = this.props;
    Job.update(
      jobId,
      {
        [key]: have
      },
      () => {
        Core.showSuccess('Saved Successfully!');
      }
    );
  };

  render() {
    const {
      onSave = Fun,
      mustHave = {},
      niceToHave = {},
      onlyView = false,
      candidate = {},
      jobTags = [],
      candidateResume,
      source,
      ...props
    } = this.props;

    const extraInfo = {
      jobTags,
      candidateResume,
      toMatchWith: candidate,
      source
    };
    const styleCol = {
      backgroundColor: '#fff',
      padding: !!onlyView ? '2px 0px' : '0px 15px',
      wordBreak: 'break-word',
      overflow: 'hidden'
    };

    return (
      <Box column w100 {...props}>
        {/** MUST HAVE */}
        <Fieldset
          title={
            !!onlyView ? (
              <div className="my-1">
                <strong>Must Have</strong>
              </div>
            ) : (
              <>Must Have</>
            )
          }
          actionBar={
            NOT(onlyView) && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className="min-w-120"
                onClick={this.submitHave('mustHave', mustHave)}
              >
                Save
              </Button>
            )
          }
          subtitle={NOT(onlyView) && AppUI.askTechnicalExpert}
          style={!!onlyView ? { padding: '0px' } : {}}
        >
          <Box column w100
            className={joinClassName([
              'rounded-sm',
              !matchLocation(/match/i) && 'bg-main-common'
            ])}
          >

            <MatchProperties
              job={this.props.job}
              candidate={this.props.candidate}
            />

            <InputArray orderEnabled createWithPopup
              heading={
                !!mustHave.type && (
                  <OperatorDropdown
                    type={mustHave.type}
                    handlerOperator={this.handlerOperatorOption}
                    onlyView={onlyView}
                    operators={Operators}
                    mainComponent="mustHave"
                  />
                )
              }
              onlyView={onlyView}
              extraInfo={extraInfo}
              styleCol={styleCol}
              existing={_.cloneDeep(mustHave.args)}
              elementComp={PrintSingleMustHave}
              CreatePopupComponent={PopupAdElement}
              parentUpdater={(result) => {
                const operator = !!this.props.mustHave.type
                  ? this.props.mustHave.type
                  : 'and';
                const result2 = result.map((obj) => {
                  if (['or', 'not', 'and'].includes(obj.type)) {
                    const { type, atLeast, specialInstruction, args } = obj;
                    return { type, atLeast, specialInstruction, args };
                  }
                  else if (
                    obj.type === 'chip' ||
                    obj.type === 'chipGroup' ||
                    obj.type === 'chipTag'
                  ) {
                    const { type, key, value, specialInstruction, category } =
                      obj;
                    return { type, key, value, specialInstruction, category };
                  }
                  else if (obj.type === 'text') {
                    const { type, value, category } = obj;
                    return { type, value, category };
                  }
                  else {
                    return {};
                  }
                });
                const mustHave = {
                  type: operator,
                  args: _.cloneDeep(result2)
                };
                this.setState({ mustHave });
                onSave(mustHave, 'mustHave');
              }}
              className='px-1 pb-1'
            />
          </Box>
        </Fieldset>

        {/** NICE TO HAVE */}
        <Fieldset
          title={
            !!onlyView ? (
              <div className="my-1">
                <strong>Nice To Have</strong>
              </div>
            ) : (
              <>Nice To Have</>
            )
          }
          actionBar={
            NOT(onlyView) && (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                className="min-w-120"
                onClick={this.submitHave('niceToHave', niceToHave)}
              >
                Save
              </Button>
            )
          }
          subtitle={NOT(onlyView) && AppUI.askTechnicalExpert}
          style={!!onlyView ? { padding: '0px' } : {}}
        >
          <Box row w100
            className={joinClassName([
              'rounded-sm',
              !Str(getLocation()).includes('match') && 'bg-main'
            ])}
          >
            <InputArray
              heading={
                !!niceToHave.type && (
                  <OperatorDropdown
                    type={niceToHave.type}
                    handlerOperator={this.handlerOperatorOption}
                    onlyView={onlyView}
                    operators={Operators}
                    mainComponent="niceToHave"
                  />
                )
              }
              orderEnabled
              existing={_.cloneDeep(niceToHave.args)}
              elementComp={PrintSingleMustHave}
              CreatePopupComponent={PopupAdElement}
              createWithPopup={true}
              onlyView={onlyView}
              extraInfo={extraInfo}
              styleCol={styleCol}
              parentUpdater={(result) => {
                const operator = !!this.props.niceToHave.type
                  ? this.props.niceToHave.type
                  : 'and';
                let result2 = result.map((obj) => {
                  if (['or', 'not', 'and'].includes(obj.type)) {
                    let { type, atLeast, specialInstruction, args } = obj;
                    return { type, atLeast, specialInstruction, args };
                  } else if (
                    obj.type === 'chip' ||
                    obj.type === 'chipGroup' ||
                    obj.type === 'chipTag'
                  ) {
                    let { type, key, value, specialInstruction, category } =
                      obj;
                    return { type, key, value, specialInstruction, category };
                  } else if (obj.type === 'text') {
                    let { type, value, category } = obj;
                    return { type, value, category };
                  } else {
                    return {};
                  }
                });

                this.setState({ niceToHave: this.props.niceToHave });

                onSave(
                  {
                    type: operator,
                    args: _.cloneDeep(result2)
                  },
                  'niceToHave'
                );
              }}
            />
          </Box>
        </Fieldset>
      </Box>
    );
  }
}
