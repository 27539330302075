import {
  useEffect,
  useState
} from "react";
import {
  useTranslation
} from "react-i18next";
import {
  useLocation
} from 'react-router-dom';
import Core from "../../lib/Core";
import {
  matchLocation
} from '../../lib/URL.lib';
import {
  useAccountBookmarks
} from "./useAccountBookmarks.hook";
import {
  useReactTable
} from './useReactTable.hook';
import {
  useSearch
} from './useSearch.hook';
import {
  useUrlQuery
} from './useUrlQuery.hook';

export function useEnhancedReactTable(context) {

  const location = useLocation();

  let {
    config,
    presets,
    onChange = data => { },
    disableBodyRender = false,
  } = context;

  let {
    renderCellClassName = ({ model, className }) => className,
    ExpandedView,
  } = config;

  const { t } = useTranslation();

  const [data = [], setData] = useState([]);

  const { loading, totalCount, latestQuery, retrieveData } = useSearch({ context });

  let {
    loading: bookmarksBusy,
    fetchBookmarks,
  } = useAccountBookmarks({ accountId: Core.getUserId() });

  const reactTableContext = useReactTable({
    config,
    data
  });

  let {
    urlState: {
      filters = {},
      globalSearchValue = [],
      page = REACT_TABLE__INITIAL_PAGE,
      pageSize = REACT_TABLE__DEFAULT_PAGE_SIZE,
      sort = {},
      resume,
      looseMatch,
      blacklisted,
      _updatedAt
    },
    updateUrlState
  } = useUrlQuery({
    context,
    onUpdate(urlState) {
      retrieveData({
        urlState,
        setData,
        setInvertExpanded,
      }).then(onChange);
      fetchBookmarks().then(bookmarks => {
        const defaultBookmark = bookmarks.find(
          ({ pathname }) => matchLocation(pathname)
        );
        if (defaultBookmark) {
          reactTableContext.setHiddenColumns(defaultBookmark.hiddenColumns || []);
          reactTableContext.setColumnOrder(defaultBookmark.columnOrder || []);
        }
        else {
          // console.debug('config.columns', config);
          reactTableContext.setHiddenColumns(config.hiddenColumns || []);
          reactTableContext.setColumnOrder(config.columns.map(({ accessor, id }) => accessor || id));
        }
      });
    }
  });

  const [invertExpanded, setInvertExpanded] = useState(false);

  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);
      if (!location.search && presets) {
        updateUrlState({ filters: presets });
      }
    }
  }, [
    firstLoad,
    location.pathname,
    location.search,
    presets,
    updateUrlState,
    fetchBookmarks,
    reactTableContext
  ]);

  const setGlobalSearchValue = globalSearchValue => updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, globalSearchValue });
  const setFilters = filters => updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, filters });
  const setPage = page => updateUrlState({ page });
  const setPageSize = pageSize => updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, pageSize });

  function setFilter({ columnId, accessor, value } = {}) {
    let _accessor = accessor || columnId;
    let _filters = { ...filters };
    if (value !== undefined) {
      _filters[_accessor] = value;
      setFilters(_filters);
    }
    else {
      delete _filters[_accessor];
      setFilters(_filters);
    }
  }

  function setSort({ columnId }) {
    const { sort: latestSort } = latestQuery;
    let sort = { ...latestSort };
    sort[columnId] =
      (
        !sort[columnId]
          ? 1
          : (sort[columnId] === 1)
            ? -1
            : undefined
      );
    if (sort[columnId] === undefined) { delete sort[columnId]; }
    updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, sort });
  }

  function setResumeFlag() {
    updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, resume: resume === undefined ? false : !resume });
  }

  function setLooseMatchFlag() {
    updateUrlState({ page: REACT_TABLE__INITIAL_PAGE, looseMatch: !looseMatch });
  }

  let enhancedReactTableContext = {

    // translations
    t,

    // react-component-props
    context,
    renderCellClassName,
    ExpandedView,
    disableBodyRender,

    // react-table-hook (3rd-party-hook)
    ...reactTableContext, // see react-table docs for refs to props and methods.

    // url-query-hook
    filters,
    globalSearchValue,
    page,
    pageSize,
    sort,
    resume,
    looseMatch,
    blacklisted,
    _updatedAt,
    updateUrlState,

    // enhanced-hook
    loading: loading || bookmarksBusy,
    latestQuery,
    setPage,
    setPageSize,
    retrieveData,
    setData,
    totalCount,
    setGlobalSearchValue,
    setFilter,
    setFilters,
    invertExpanded,
    setInvertExpanded,
    setSort,
    setResumeFlag,
    setLooseMatchFlag,

  };

  // console.debug('enhancedReactTableContext',enhancedReactTableContext);

  return enhancedReactTableContext;

}

export const REACT_TABLE__COLUMN_XXXS = 40;
export const REACT_TABLE__COLUMN_XXXS2 = 60;
export const REACT_TABLE__COLUMN_XXS = 80;
export const REACT_TABLE__COLUMN_XXS2 = 90;
export const REACT_TABLE__COLUMN_XS = 120;
export const REACT_TABLE__COLUMN_SM = 160;
export const REACT_TABLE__COLUMN_SM2 = 180;
export const REACT_TABLE__COLUMN_MD = 200;
export const REACT_TABLE__COLUMN_MD2 = 225;
export const REACT_TABLE__COLUMN_LG = 240;
export const REACT_TABLE__COLUMN_XL = 280;
export const REACT_TABLE__COLUMN_XXL = 320;
export const REACT_TABLE__COLUMN_XXXL = 360;

export const REACT_TABLE__COLUMN_RANGE_NUMBER = 220;
export const REACT_TABLE__COLUMN_ACTION = 180;
export const REACT_TABLE__INPUT_RANGE_NUMBER = 240;
export const REACT_TABLE__INPUT_RANGE_DATE = 140;

export const REACT_TABLE__INITIAL_PAGE = 1;
export const REACT_TABLE__DEFAULT_PAGE_SIZE = Core.isAdminOnLocal() ? 10 : 20;
export const REACT_TABLE__PAGE_SIZES = [10, 20, 25, 50, 100, 200 /* { label: 'Maximum', value: totalCount } */];
