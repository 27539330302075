import {
  isString
} from 'lodash';
import {
  isMatchDecisionNo,
  isMatchDecisionYes
} from '../../components/Match/Libraries/MatchDecisions.lib';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH
} from "../../lib/Definition";

const getMisMatch = ({ engagement = {}, annotatorMatchStrength, oldMatchStrength, checkOldState = false }) => {

  let newMS = "", oldMS = "";

  const newMatchStrengthTag = Definition.getTag({
    categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
    ...(
      isString(annotatorMatchStrength)
        ? { tagLabel: annotatorMatchStrength }
        : { tagId: annotatorMatchStrength }
    )
  });

  if (
    isMatchDecisionYes(newMatchStrengthTag)
  ) {
    newMS = "yes";
  }
  else if (
    isMatchDecisionNo(newMatchStrengthTag)
  ) {
    newMS = "no";
  }

  const oldMatchStrengthTag = Definition.getTag({
    categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
    ...(
      isString(oldMatchStrength)
        ? { tagLabel: oldMatchStrength }
        : { tagId: oldMatchStrength }
    )
  });
  if (
    isMatchDecisionYes(oldMatchStrengthTag)
  ) {
    oldMS = "yes";
  }
  else if (
    isMatchDecisionNo(oldMatchStrengthTag)
  ) {
    oldMS = "no";
  }
  else if (!!checkOldState && !!engagement.id
    && /10x10 - No Match/gi.test(engagement.rejectionReason)
    && /Closed/gi.test(engagement.state)
    && /E - 10x10/gi.test(engagement.status)
  ) {
    oldMS = "no";
  }

  return { newMS, oldMS };
}

const getMisMatchThumbsAction = {
  getMisMatch
}

export default getMisMatchThumbsAction;
