
import {
  Route,
  Routes
} from 'react-router-dom';
import {
  EmployerSession
} from '../components/Employers/EmployerPendings/EmployerSession';
import Navigate from '../components/Layout/Wrappers/Navigate';
import ForgotPassword from '../components/Login/ForgotPassword';
import Login from '../components/Login/Login';
import ResetPassword from '../components/Login/ResetPassword';
import {
  JWT__EXPIRED_URL,
  JWT__SESSION_URL
} from '../lib/services/Accounts/Session.lib';

const Unlogged = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/login" />} />
    <Route path={String(JWT__SESSION_URL).replace('/#', '')} element={<EmployerSession />} />
    <Route path={String(JWT__EXPIRED_URL).replace('/#', '')} element={<EmployerSession />} />
    <Route path="/login" element={<Login />} />
    <Route path="/forgot/password" element={<ForgotPassword />} />
    <Route path="/reset/password/:token" element={<ResetPassword />} />
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
);
export default Unlogged;
