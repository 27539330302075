import {
  Component
} from "react";
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";

import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../../lib/Array.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import {
  Obj
} from '../../../../lib/Object.lib';
import formatURL from "../../../../lib/tools/formatURL";
import StepHeader from '../../../Forms/StepHeader';
import {
  JOB_SECTION__COMPANY_INFO,
  JOB_SECTION__EMPLOYER__PRODUCT,
  JOB_SECTION__EMPLOYER__TAG_LINE,
  JOB_SECTION__EMPLOYER__WHY_US,
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../../Jobs/Card/JobDetails';
import Box from '../../../Layout/Wrappers/Box';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import TextField from '../../../Layout/Wrappers/TextField';
import EmployerUI from '../../EmployerUI.dic';

class Basics extends Component {
  render() {
    const EmployerEditController = this.props.parent;
    const employer = EmployerEditController.state;
    const _firstJobId = Obj(Arr(Obj(employer).jobs)[0]).id;
    console.debug('Employer > Basics :state', employer, _firstJobId);
    return (
      <Box column w100>

        <StepHeader {...this.props} />

        <Fieldset
          title={
            <>Elevator Pitch</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__EMPLOYER__TAG_LINE
            })
          }}
        >
          <RichTextBox
            name="tagline"
            value={employer.tagline}
            onChange={tagline => EmployerEditController.setState({ tagline })}
            halfHeight
          />
        </Fieldset>

        <Fieldset
          title={
            <>Company Overview</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__EMPLOYER__PRODUCT
            })
          }}
        >
          <RichTextBox
            name="product"
            value={employer.product}
            onChange={product => EmployerEditController.setState({ product })}
          />
        </Fieldset>

        <Fieldset
          title={
            <>Why Us?</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__EMPLOYER__WHY_US
            })
          }}
        >
          <RichTextBox
            name="whyUs"
            value={employer.whyUs}
            onChange={whyUs => EmployerEditController.setState({ whyUs })}
            halfHeight
          />
        </Fieldset>

        <Fieldset
          title={
            <>Company Perks</>
          }
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__COMPANY_INFO
            })
          }}
        >
          <RichTextBox
            name="perks"
            value={employer.perks}
            onChange={perks => EmployerEditController.setState({ perks })}
            halfHeight
          />
        </Fieldset>

        <Row>
          <Fieldset
            title={
              <>Company URL</>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            fullWidth={false}
          >
            <TextField
              name="url"
              value={employer.url}
              onChange={(event, url) => EmployerEditController.setState({
                url: formatURL(url)
              })}
            />
          </Fieldset>
          <Fieldset
            title={
              <span className='tt-unset'>Crunchbase URL</span>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__COMPANY_INFO
              })
            }}
            fullWidth={false}
          >
            <TextField
              name="crunchbaseUrl"
              value={employer.crunchbaseUrl}
              onChange={(event, crunchbaseUrl) => EmployerEditController.setState({
                crunchbaseUrl: formatURL(crunchbaseUrl)
              })}
            />
          </Fieldset>
        </Row>

        <Row>
          <Fieldset
            title={
              <span className='tt-unset'>Additional URLs</span>
            }
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__COMPANY_INFO
              })
            }}
          >
            <TextField
              name="additionalUrl"
              value={employer.additionalUrl}
              onChange={(ev, additionalUrl) => {
                additionalUrl = additionalUrl
                  .split("\n")
                  .map(url => formatURL(url))
                  .join("\n");
                EmployerEditController.setState({ additionalUrl });
              }}
              rowsMax={5}
            />
          </Fieldset>
          <Fieldset
            title={EmployerUI.notables.title}
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__COMPANY_INFO
              })
            }}
          >
            <TextField
              name="notables"
              value={employer.notables}
              onChange={(ev, notables) => EmployerEditController.setState({ notables })}
              rowsMax={5}
            />
          </Fieldset>
        </Row>

      </Box>
    );
  }
}

export default Basics;
