import {
  Avatar
} from '@mui/material';
import {
  join
} from '../../../lib/Array.lib';
import {
  getGravatarURL
} from '../../../lib/GenericTools.lib';
import {
  Abbr
} from '../../../lib/String.lib';
import useState from '../../../lib/hooks/useState.hook';

/**
 * 
 * @param {object} props
 * @param {string} props.email
 * @param {string} props.label
 * @param {boolean} props.hideOnError
 * @returns JSX.Element
 */
export default function Gravatar({
  email = '',
  label = '',
  children,
  hideOnError = false,
  ...props
}) {
  const [{ show = false }, _updateState] = useState();
  const _setShow = (show) => (event) => {
    setTimeout(async () => await _updateState({ show }));
  }
  let _props = {
    ...props,
    src: getGravatarURL(email),
    className: join([
      props.className,
      (show === false) && 'd-none'
    ], ' '),
    children: Abbr({ value: label || children })
  };
  if (hideOnError) {
    _props.imgProps = {
      onLoad: _setShow(true),
      onError: _setShow(false)
    }
  }
  return (
    <Avatar {..._props} />
  );
}
