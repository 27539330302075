import {
  startCase
} from 'lodash';
import moment from 'moment';
import Candidate from '../../lib/Candidate';
import Core from '../../lib/Core';
import {
  validateGithubURL,
  validateLinkedinURL
} from '../../lib/tools/processURL.tool';

class ConflictManager {
  constructor(candidate, potentialDuplicatedCandos, finalCb) {
    this.isStrongConflict = false;
    this.isWeakConflict = false;
    this.finalCb = finalCb;
    this.candidate = candidate;
    this.potentialDuplicatedCandos = potentialDuplicatedCandos || [];
    this.processConflict();
  }

  processConflict() {
    const candidate = this.candidate;
    this.isStrongConflict = false;
    this.isWeakConflict = false;

    this.potentialDuplicatedCandos.forEach((potentialDuplicateCandidate) => {
      if (potentialDuplicateCandidate.id === this.candidate.id) {
        return;
      }
      this.setStrongConflict(candidate, potentialDuplicateCandidate);
    });

    this.setOwnership();
  }

  setOwnership() {
    let conflictedCandidateIds = [
      ...this.potentialDuplicatedCandos,
      this.candidate,
    ]
      .map((cando) => cando.id)
      .join(',');

    let candoEmployerIds = (this.candidate._jobsPermitted || [])
      .filter((el) => this.candidate.jobsPermitted.includes(el.id))
      .map((j) => j.employerId)
      .join(',');

    if (!!conflictedCandidateIds.length) {
      Candidate.candoInConflictInPermittedJobOwnership(
        {
          params: {
            conflictedCandidateIds,
            candoEmployerIds: !!candoEmployerIds.length
              ? candoEmployerIds
              : 'ignore',
          },
        },
        (resp) => {
          console.log({ resp });
          resp.forEach((cando) => {
            const myPdc = this.potentialDuplicatedCandos.find(
              (candoPdc) => String(candoPdc.id) === String(cando.candidateId)
            );
            if (!!myPdc) {
              cando.isWeakConflict = !!myPdc.isWeakConflict;
              cando.isPotentialStrongConflict =
                !!myPdc.isPotentialStrongConflict;
            }
          });
          this.ownershipResult = resp;

          this.potentialDuplicatedCandos.forEach((pdc) => {
            let inOwnership = (this.ownershipResult || []).find((el) => {
              return (
                String(el.candidateId) === String(pdc.id) &&
                el.ownership === 'owned'
              );
            });

            let inTbdOwnership = (this.ownershipResult || []).find((el) => {
              return (
                String(el.candidateId) === String(pdc.id) &&
                el.ownership === 'tbd'
              );
            });

            pdc.isStrongConflict =
              this.candidate.jobsPermitted.length &&
              !!pdc.isPotentialStrongConflict &&
              !!inOwnership;
            pdc.isTbdConflict =
              this.candidate.jobsPermitted.length && !!inTbdOwnership;
          });
          this.finalCb(this.currentState());
        }
      );
    } else {
      this.finalCb(this.currentState());
    }
  }

  // 1. recruiter A is creating a new candidate and enters email
  // 2. system fetches from Db all the potential duplciate canddiates that matches this email.
  // 3. system takes this pdcs and system flags each pdc with some data e.g someoneOwnIt
  // 4. system filters out from list, the candidate in pdcs who is owned by someone

  someoneOwnIt(potentialDuplicateCandidate) {
    if (potentialDuplicateCandidate.hasOpenEngagement) {
      return true;
    }

    if (potentialDuplicateCandidate.isOwnedRightNow === false) {
      return false;
    }

    if (
      potentialDuplicateCandidate.isOwnedRightNow &&
      potentialDuplicateCandidate.isLessThan6MonthsOld
    ) {
      return true;
    }

    if (
      potentialDuplicateCandidate.isOwnedRightNow &&
      !potentialDuplicateCandidate.isLessThan6MonthsOld
    ) {
      return false;
    }

    if (
      potentialDuplicateCandidate.isOwnedRightNow === undefined &&
      potentialDuplicateCandidate.isLessThan6MonthsOld
    ) {
      return true;
    }

    if (
      potentialDuplicateCandidate.isOwnedRightNow === undefined &&
      !potentialDuplicateCandidate.isLessThan6MonthsOld
    ) {
      return false;
    }

    return false;
  }

  setStrongConflict(candidate, candoForConflictChecking) {

    /*
     * Remove the whitespace and http:// or https:// from url for comparison
     */
    candidate._linkedInURL = candidate.linkedInURL;
    try {
      candidate._linkedInURL = candidate.linkedInURL
        .trim()
        .match(/linkedin.+\w/g)
        .pop();
    } catch (e) { }
    /*
     *  Remove the whitespace, the trailing / and http:// and https:// from the url for comparison
     *  We can't use the same expression as we did for linkedin because there are 2 github
     *  URL styles github.com/<something> and <something>.github.io.
     *
     *  NOTE - do not combine multiple statements in one try/catch as if the first one fails
     *  the additional instructions are not executed.
     */
    candidate._gitHubURL = candidate.gitHubURL;
    try {
      candidate._gitHubURL = candidate.gitHubURL
        .trim()
        .replace(/http+s?:\/\//g, '')
        .replace(/\/*$/, '');
    } catch (e) { }

    let potentialDuplicateCandidate = candoForConflictChecking;

    let attrsThatConflicted = [
      'email',
      '_linkedInURL',
      'phone',
      '_gitHubURL',
    ].filter(
      (attr) =>
        !!candidate[attr] &&
        potentialDuplicateCandidate[attr].trim().toLowerCase() ===
        candidate[attr].trim().toLowerCase()
    );
    if (
      // makae sure url is a valid github url before checking for duplication
      !!String(validateGithubURL(candidate.gitHubURL)) &&
      validateGithubURL(candidate.gitHubURL) === validateGithubURL(potentialDuplicateCandidate.gitHubURL)
    ) {
      attrsThatConflicted.push('gitHubURL');
    }
    if (
      // make sure url is a valid linkedin url before checking for duplication
      !!String(validateLinkedinURL(candidate.linkedInURL)) &&
      validateLinkedinURL(candidate.linkedInURL) === validateLinkedinURL(potentialDuplicateCandidate.linkedInURL)
    ) {
      attrsThatConflicted.push('linkedInURL');
    }
    let attrsThatConflictedWeak = [];
    let attrsThatConflictedStrong = [...attrsThatConflicted];
    let isSpecialAttrsThatConflicted =
      ['firstName', 'lastName'].filter(
        (attr) =>
          !!candidate[attr] &&
          potentialDuplicateCandidate[attr].toLowerCase().trim() ===
          candidate[attr].toLowerCase().trim()
      ).length === 2;
    let openEngagements = candoForConflictChecking.engagements;
    let hasOpenEngagement = !!openEngagements.length;
    let days = potentialDuplicateCandidate.introduced
      ? moment().diff(potentialDuplicateCandidate.introduced, 'days', false)
      : 0;
    let isLessThan6MonthsOld = days <= 180;
    let isRecruiterCando =
      candoForConflictChecking.accountId === this.candidate.accountId;
    let conflictCode = '-1';

    let someoneOwnIt = this.someoneOwnIt({
      isLessThan6MonthsOld,
      hasOpenEngagement,
      isOwnedRightNow: potentialDuplicateCandidate.isOwnedRightNow,
      companyName: potentialDuplicateCandidate.account.companyName,
    });

    let iOwnIt = isRecruiterCando && someoneOwnIt;

    let isAttrConflictMatch = [...attrsThatConflicted].length;

    if (isSpecialAttrsThatConflicted) {
      attrsThatConflicted.push('Name');
      attrsThatConflictedWeak.push('Name');
    }

    if (isSpecialAttrsThatConflicted || isAttrConflictMatch) {
      potentialDuplicateCandidate.isWeakConflict = true;
    }

    if (isAttrConflictMatch) {
      potentialDuplicateCandidate.isPotentialStrongConflict = true;
    }

    let isLegacyConflict =
      potentialDuplicateCandidate.isPotentialStrongConflict ||
      potentialDuplicateCandidate.isWeakConflict;

    if (isAttrConflictMatch || isSpecialAttrsThatConflicted) {
      if (isRecruiterCando) {
        if (iOwnIt) {
        } else {
        }
      } else {
        if (someoneOwnIt) {
        } else {
          // potentialDuplicateCandidate.isStrongConflict = false;
          // potentialDuplicateCandidate.isWeakConflict = false;
        }
      }
    }

    potentialDuplicateCandidate.attrsThatConflicted = attrsThatConflicted; // e.g email, phone,linkedinurl
    potentialDuplicateCandidate.attrsThatConflictedFormatted =
      attrsThatConflicted.map((el) => startCase(el)); // e.g email, phone,linkedinurl
    potentialDuplicateCandidate.isRecruiterCando = isRecruiterCando; // recruiter already submitted someother candidate with same attributes e.g using email
    potentialDuplicateCandidate.hasOpenEngagement = hasOpenEngagement;
    potentialDuplicateCandidate.conflictCode = conflictCode;
    potentialDuplicateCandidate.oldDays = days;
    potentialDuplicateCandidate.isLessThan6MonthsOld = isLessThan6MonthsOld;
    potentialDuplicateCandidate.iOwnIt = iOwnIt;
    potentialDuplicateCandidate.someoneOwnIt = someoneOwnIt;
    potentialDuplicateCandidate.isLegacyConflict = isLegacyConflict;
    potentialDuplicateCandidate.attrsThatConflictedStrong =
      attrsThatConflictedStrong;
    potentialDuplicateCandidate.attrsThatConflictedWeak =
      attrsThatConflictedWeak;

    console.log({ potentialDuplicateCandidate });
  }

  getEmailTable() {
    let meta = this.currentState();
    return Object.keys(this.currentState())
      .map((attr) => {
        if (typeof meta[attr] !== 'function') {
          let value = meta[attr];
          if (attr === 'identifiedDuplicateCando') {
            value = meta[attr].id;
          }

          if (attr === 'matchingPool') {
            value = meta[attr].map((cando) => cando.id);
          }

          return `<tr><td>${attr}</td><td>${value}</td></tr>`;
        }
        return '';
      })
      .join('');
  }

  sendDuplicativeEmail = () => {
    var candoUrl = Core.getPath(
      `candidates?viewDup=t&cId=${this.candidate.id}`
    );
    let moreThanOneOwnerMsg = '';

    //         if (currentState.isMoreThanOneOwner) {
    //             moreThanOneOwnerMsg = `<p style="color:red;"><strong>
    //             ALERT: More Than One Owner Found, admin need to clean things, have a look at matchingPool attribute to see respective candidate ids
    // </strong></p>`;
    //         }

    let dupUrlHtml = !!this.candidate.id
      ? `<h4><a href=${candoUrl}>Duplicate Details link</a></h4>`
      : '';

    var html = `<h3>Duplicate identifications found while submitting a candidate by ${Core.getSessionEmail()}</h3>
                ${dupUrlHtml}
                ${moreThanOneOwnerMsg}
                <p>Below are the conflict details:</p>
                <table border="1">${this.getEmailTable()}</table>`;

    return html;
    // var params = {from, to, cc, subject, html, source:'Conflict Detector - ConflictDetector.js => line 248'};
    //

    // setTimeout(()=>{
    //
    // });

    // Google.sendEmail(params, null, error => Core.showError(error));
    // if (Core.isDevelopment() || Core.isLocalHost()) {
    //     Google.sendEmail(params, null, error => Core.showError(error));
    // } else {
    //     Google.sendEmail(params, null, error => Core.showError(error));
    // }
  };

  currentState() {
    console.log({ dupp: this.potentialDuplicatedCandos });
    let dupObjects = this.potentialDuplicatedCandos.filter(
      (cand) => cand.isStrongConflict && cand.isRecruiterCando
    );
    let matchingPool = this.potentialDuplicatedCandos.filter(
      (cand) => cand.isStrongConflict || cand.isWeakConflict
    );
    let weekDupObject;
    // if (!dupObjects.length) {
    //     // dupObjects = this.potentialDuplicatedCandos.filter(cand => cand.isWeakConflict && cand.isRecruiterCando);
    // }

    if (!dupObjects.length) {
      dupObjects = this.potentialDuplicatedCandos.filter(
        (cand) => cand.isStrongConflict
      );
    }

    if (!dupObjects.length) {
      dupObjects = this.potentialDuplicatedCandos.filter(
        (cand) => cand.isPotentialStrongConflict
      );
      weekDupObject = dupObjects.find(
        (obj) => obj.accountId === Core.getUserId()
      );
    }

    if (!dupObjects.length) {
      dupObjects = this.potentialDuplicatedCandos.filter(
        (cand) => cand.isWeakConflict
      );
      weekDupObject = dupObjects.find(
        (obj) => obj.accountId === Core.getUserId()
      );
    }

    if (!dupObjects.length) {
      dupObjects = this.potentialDuplicatedCandos.filter(
        (cand) => cand.isLegacyConflict
      );
    }

    let dupObject = weekDupObject || dupObjects[0];

    let attrsThatConflicted = Object(dupObject).attrsThatConflicted || [];
    return {
      isStrongConflict: Object(dupObject).isStrongConflict || false,
      isWeakConflict:
        Object(dupObject).isWeakConflict ||
        Object(dupObject).isPotentialStrongConflict ||
        false,
      isPotentialStrongConflict:
        Object(dupObject).isPotentialStrongConflict || false,
      attrsThatConflicted,
      isRecruiterCando: Object(dupObject).isRecruiterCando || false,
      identifiedDuplicateCando: Object(dupObject),
      hasOpenEngagement: Object(dupObject).hasOpenEngagement || false,
      oldDays: Object(dupObject).oldDays,
      fnSendEmail: this.sendDuplicativeEmail,
      iOwnIt: !!Object(dupObject).iOwnIt,
      attrsThatConflictedFormatted:
        Object(dupObject).attrsThatConflictedFormatted,
      matchingPool,
      isTbdConflict: Object(dupObject).isTbdConflict,
      isMoreThanOneOwner: matchingPool.length > 1,
      isLegacyConflict: !!Object(dupObject).isLegacyConflict,
      digest: attrsThatConflicted
        .map((attr) => {
          if (attr === 'Name') {
            return (
              this.candidate['firstName'] + ' ' + this.candidate['lastName']
            );
          }
          return this.candidate[attr];
        })
        .join(''),
      ownershipResult: this.ownershipResult || [],
    };
  }
}

export default ConflictManager;
