import moment from 'moment';
import Core from '../../lib/Core';
import { STATE_ACTIVE } from '../../lib/Definition';
import { configCandidates } from './configCandidates';

export const configMatchCandidates = {
  ...configCandidates,
  defaultQuery: {
    filters: [
      {
        ...(() => {
          let limitDate = moment().subtract(180, 'days').toISOString();
          if (Core.isAdminOrCoordinator()) {
            return {
              state: STATE_ACTIVE,
              introduced: { $ne: null },
              updatedAt: { $gt: limitDate },
              'matchExclusions.excludeUntil': { "$lt": moment().startOf('day').toISOString() }
            };
          }
          return {
            state: STATE_ACTIVE,
            accountId: { $id: Core.getUserId() },
            updatedAt: { $gt: limitDate },
          };
        })(),
      },
    ],
    skip: 0,
    limit: 10,
    query: [],
    associations: ['account', 'candidateStarreds', 'engagements.job.employer'],
    resume: true,
  },
};
