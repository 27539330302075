import {
  trim
} from '../../../lib/String.lib';
import Box from './Box';

export function Html({
  children = '',
  value = children,
  ...props
}) {
  return (
    <Box column w100 flex1 scrollY
      dangerouslySetInnerHTML={{
        __html: trim(value)
      }}
      {...props}
    />
  );
}
