import React, {
  Component
} from "react";
import Drawer from '../Layout/Wrappers/Drawer';

const defaultDirection = "right";

class CustomDrawer extends Component {
  direction = "right";
  constructor() {
    super(...arguments);
    this.state = {
      left: false,
      right: false,
      top: false,
      bottom: false,
      content: "",
      style: {}
    };
  }
  open = ({ direction, content, style, onClose }) => {
    this.direction = direction || defaultDirection;
    this.setState(state => {
      state.left = false;
      state.right = false;
      state.top = false;
      state.bottom = false;
      state[this.direction] = true;
      state.content = content;
      state.style = style || {};
      state.onClose = onClose;
      return state;
    });
  };
  close = ev => {
    this.setState(
      state => {
        state[this.direction] = false;
        state.content = "";
        return state;
      },
      then => {
        if (this.state.onClose instanceof Function) {
          this.state.onClose();
          this.setState({ onClose: null });
        }
      }
    );
  };
  toggleDrawer = (side, open) => em => {
    this.setState({ [side]: open });
  };
  render() {
    return (
      <Drawer
        anchor={this.direction}
        open={this.state[this.direction]}
        onClose={this.toggleDrawer(this.direction, false)}
      >
        <div
          className="scroll-y-visible"
          style={{ width: "375px", ...this.state.style }}
        >
          {this.state.content}
        </div>
      </Drawer>
    );
  }
}

export default CustomDrawer;
