import {
  debounce
} from 'lodash';
import {
  NOT
} from '../../../lib/Boolean.lib';
import {
  fetchCandidateResumePdfFile,
  getLocalCandidateResumePdfUrl
} from './Libraries/CandidateResumePdf.lib';
import {
  fetchCandidateResumePdfHighlightsFile,
  getLocalUrlCandidateResumePdfHighlights
} from './Libraries/CandidateResumePdfHighlights.lib';
import getRemoteCandidateResumePdfUrl from './Libraries/getRemoteCandidateResumePdfUrl.fun';
import useState from '../../../lib/hooks/useState.hook';
import Box from '../../Layout/Wrappers/Box';
import Message from '../../Layout/Wrappers/Message';
import TextCVContentDrawer from "../../Match/Job/TextCVContentDrawer";

export default function DoubleContentDrawer({
  candidate = {},
  job = {},
  ...props
}) {
  const [state, updateState] = useState();
  const {
    prev = {},
    busy = false,
    ready = false
  } = state;
  const jobId = job.id;
  debounce(async () => {
    const url = getRemoteCandidateResumePdfUrl(candidate);
    if (NOT(url)) { return; }
    if (
      (url !== prev.url) ||
      (jobId !== prev.jobId) ||
      NOT(busy || ready)
    ) {
      await updateState({
        busy: true,
        prev: { url, jobId },
        loading: true,
        error: false,
        source: 'resume'
      });
      fetchCandidateResumePdfFile(
        candidate
      ).then(updateState);
      fetchCandidateResumePdfHighlightsFile(
        {
          candidate,
          jobId: job.id,
          employerId: job.employerId
        }
      ).then(
        () => updateState({ loading: false })
      ).catch(
        (error) => {
          updateState({ loading: false, error });
        }
      );
      await updateState({
        busy: false,
        ready: true
      });
    }
  })();
  const localUrlCandidateResumePdf = (
    getLocalUrlCandidateResumePdfHighlights({
      candidate,
      jobId,
      employerId: job.employerId
    }) ||
    getLocalCandidateResumePdfUrl(candidate)
  );
  console.debug(
    'DoubleContentDrawer',
    { localUrlCandidateResumePdf, job }
  );
  return (
    <Box row w100 h100 wrap
      {...props}
    >
      <Box column flex1 h100 scrollY
        style={{ minWidth: 320 }}
      >
        {!!candidate.resumeTxtUrl && !!job.id
          ? (
            <TextCVContentDrawer
              style={{ width: "100%", height: "100%" }}
              candidate={candidate}
              job={job}
            />
          ) : (
            <Message>No Text CV</Message>
          )}
      </Box>

      <Box column flex1 h100
        style={{ minWidth: 320 }}
      >
        {localUrlCandidateResumePdf
          ? (
            <iframe
              title='double_content_drawer__iframe'
              src={`${localUrlCandidateResumePdf}#view=fitH&toolbar=1`}
              type="application/pdf"
              width="100%"
              height="100%"
              className="h-100"
            />
          ) : (
            <Message>No CV</Message>
          )}
      </Box>
    </Box>
  );
}
