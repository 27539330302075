import Definition from "../Definition";
import { mapJob } from "./job";
import { mapEmployer } from "./employer";

import moment from "moment";

const model = {
  entity: "Employer",
  eventType: null,

  /** foreing keys */
  jobId: "",
  employerId: "",
  note: "",

  /** mixin timestamps */
  createdAt: null, // new Date().toISOString(), // "2017-12-18T20:51:55.157Z",
  updatedAt: null // new Date().toISOString() // "2017-12-18T20:51:55.157Z",
};

const extended = {
  id: null,
  ...model,
  /** nested objects */
  job: {},
  employer: {},
  _employers: false,
  _jobs: false,
  ___keys___: []
};

const mapAnnouncement = item => {
  const announcement = { ...extended };
  if (!!item) {
    Object.keys(extended).forEach(
      key => !!item[key] && (announcement[key] = item[key])
    );
    if (announcement.entity === "Employer") {
      announcement.employer = mapEmployer(item.employer);
      Definition.set(
        announcement,
        "announcementEmployerEventType",
        "eventType"
      );
    } else if (announcement.entity === "Job") {
      announcement.job = mapJob(item.job);
      Definition.set(announcement, "announcementJobEventType", "eventType");
    }
    announcement._date = moment(announcement.updatedAt).format("MM/DD/YYYY");
    announcement._status = `${announcement.entity} - ${
      announcement._eventType
    }`;
    announcement._name = {
      Employer: announcement.employer.name,
      Job: announcement.job._name
    }[announcement.entity];
  }
  return announcement;
};

const mapAnnouncements = data => {
  return (data || []).map(item => {
    const announcement = mapAnnouncement(item);
    return announcement;
  });
};

const Announcement = {
  extendedModel: extended,
  model,
  extended,
  mapAnnouncement,
  mapAnnouncements
};

export {
  Announcement as default,
  model,
  extended,
  mapAnnouncement,
  mapAnnouncements
};
