import { useState } from "react";
import { search } from '../../lib/services/BE/search.api';
import { mapEngagementStateFilter } from "./mapEngagementStateFilter.lib";
import { mapLocationFilters } from "./mapLocationFilters.lib";
import { mapLooseMatch } from "./mapLooseMatch.lib";
import { REACT_TABLE__DEFAULT_PAGE_SIZE, REACT_TABLE__INITIAL_PAGE } from "./useEnhancedReactTable.hook";

export function buildQuery({
  context = {},
  latestQuery,
  newQuery = {},
  urlState = {},
}) {

  const { config } = context;

  const {
    Entity = {},
    defaultQuery = {},
  } = config;

  const {
    globalSearchValue = [],
    filters = [],
    sort = {},
    page,
    pageSize,
    resume,
    looseMatch,
  } = urlState;

  let {
    filters: originalFilters = [],
    sort: originalSort = {},
    resume: originalResume,
  } = defaultQuery;

  let uniqueFilters = {};
  originalFilters.forEach(filter => { uniqueFilters = { ...filter }; });

  // comment following line an uncomment followed one to debug just location without nested logic
  uniqueFilters = mapLocationFilters({ Entity, filters: { ...uniqueFilters, ...filters } });
  // uniqueFilters = { ...uniqueFilters, ...filters };

  uniqueFilters = mapLooseMatch({ context, Entity, filters: uniqueFilters, looseMatch });
  uniqueFilters = mapEngagementStateFilter({ filters: uniqueFilters });

  let newFilters = Object.keys(uniqueFilters).length ? [uniqueFilters] : [];
  let newSort = !!Object.keys(sort).length ? sort : originalSort;
  let newResume = resume !== undefined ? resume : originalResume;

  let skip = parseInt((page - REACT_TABLE__INITIAL_PAGE) * pageSize);
  if (!skip || skip < 0) { skip = 0 };

  let limit = parseInt(pageSize) || REACT_TABLE__DEFAULT_PAGE_SIZE;

  let _query = {
    ...latestQuery,
    filters: newFilters,
    skip,
    limit,
    query: globalSearchValue.map(v => String(v)),
    sort: newSort,
    resume: newResume,
    ...newQuery,
  };

  if (!Object.keys(_query.sort).length) { delete _query.sort; }
  if (_query.resume === undefined) { delete _query.resume; }

  // console.debug(JSON.stringify(_query, null, 2));

  return _query;

}

export function useSearch({ context }) {
  const { config } = context;
  const {
    searchPath = 'Entity/_search',
    entityMapper = results => results,
    defaultQuery,
  } = config;
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [prev, setPrev] = useState('');
  const { totalCount = 0, results: data = [], query } = response;
  return {
    loading,
    totalCount,
    data,
    latestQuery: query,
    async retrieveData({
      urlState,
      newQuery,
      setData,
      setInvertExpanded,
    }) {
      setLoading(true);
      let latestQuery = query || defaultQuery;
      if (searchPath !== prev) {
        setData([]);
        setInvertExpanded(false);
        latestQuery = defaultQuery;
      }
      setPrev(searchPath);
      let promise = search({
        path: searchPath,
        query: buildQuery({ context, urlState, newQuery, latestQuery }),
        mapper: entityMapper,
      }).then(response => {
        const data = response.results;
        // console.debug(data);
        setResponse(response);
        setData(data);
        setLoading(false);
        return data;
      });
      promise.catch(response => setLoading(false));
      return promise;
    }
  }
}
