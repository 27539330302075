
import Page from '../../Layout/Page';
import {
  AdminManageTemplatesContent
} from './Content';

export function AdminManageTemplatesPage(props) {
  return (
    <Page paper title='Admin – Manage Templates'>
      <AdminManageTemplatesContent />
    </Page>
  )
}
