import {
  Component
} from 'react';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../lib/Array.lib';
import {
  NOT
} from '../../../lib/Boolean.lib';
import Core from '../../../lib/Core';
import Definition, {
  DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
  ENGAGEMENT_MATCH_STRENGTH__NO,
  ENGAGEMENT_MATCH_STRENGTH__STRONG_NO,
  ENGAGEMENT_MATCH_STRENGTH__STRONG_YES,
  ENGAGEMENT_MATCH_STRENGTH__WEAK_YES,
  ENGAGEMENT_MATCH_STRENGTH__YES
} from '../../../lib/Definition';
import {
  getToBeDefinedMatchStrengthLabel
} from '../../../lib/Engagement';
import EngagementDisagreement from '../../../lib/EngagementDisagreement';
import {
  Fun
} from '../../../lib/Function.lib';
import {
  compileText
} from '../../../lib/String.lib';
import {
  QUERY__MATCH_DECISIONS
} from '../../../lib/queries/MatchDecisions.query';
import Box from '../../Layout/Wrappers/Box';
import IconButton from '../../Layout/Wrappers/IconButton';
import {
  isMatchDecisionNo,
  isMatchDecisionYes
} from '../Libraries/MatchDecisions.lib';
import {
  getMatchEntities,
  hasEngageableMatch,
  isMatchEngageable
} from '../Libraries/MatchList.lib';
import {
  MatchPageController
} from '../MatchPage';
import {
  MatchOptionsMenuController
} from './MatchOptionsMenu';

/**
 * @param {React.Component} props.Controller  ListHeader | SingleJobCard | SingleCandidateCard
 * @param {object} props.match  [entity] Candidate | Job
 */
export default class ThumbsBar extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      matchStrength: -1,
    };
    Object.assign(this.props.Controller, { thumbsBar: this });
  }

  async componentDidMount() {
    try {
      const {
        Controller = {},
      } = this.props;
      const {
        engagement = {},
      } = getMatchEntities(Controller.state);
      if (!!engagement.id) {
        if (Controller.state?.MlDecisionPending) {
          const currentUser = Core.getSessionEmail();
          const results = Arr(
            await EngagementDisagreement.get(undefined,
              compileText(
                QUERY__MATCH_DECISIONS,
                { ENGAGEMENT_ID: engagement.id }
              )
            )
          );
          const filterCurrentUser = results.filter((decision) => !!decision.annotator && decision.annotator === currentUser);
          const lastResponse = filterCurrentUser.pop();
          const matchDecision = !!lastResponse && !!lastResponse.matchDecision ? lastResponse.matchDecision : '';
          const matchStrengthTag = Definition.getTag({
            categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
            tagId: matchDecision
          });
          this.setState(
            {
              matchStrength: matchStrengthTag.id
            },
            () => Fun(Controller.handlerMlDecisionMaking)(
              false,
              results
            )
          );
        }
      }
      else {
        Fun(Controller.handlerMlDecisionMaking)(
          false,
          []
        )
      }
    }
    catch (error) {
      Core.showError(error);
    };
  }

  render() {

    const {
      Controller = {},
    } = this.props;

    const {
      engagement = {},
      candidate = {},
      job = {},
      employer = {}
    } = getMatchEntities(Controller.state);

    const {
      reviewed = false,
    } = Controller.state;

    const {
      matchStrength
    } = this.state;

    const _handlerThumbClick = (
      matchStrength = -1,
    ) => {
      return (event) => {

        // GET DECISION TAG
        const matchStrengthTag = Definition.getTag({
          categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
          tagId: matchStrength
        });
        if (NOT(matchStrengthTag.id)) {
          return showErrorUnexpectedMatchStrength(
            matchStrength,
            matchStrengthTag.id
          );
        }

        // UPDATE
        const update = {
          matchStrength: matchStrengthTag.id,
          matchStrengthLabel: matchStrengthTag.label
        };

        console.debug(
          'MatchListLib__handlerThumbs...',
          '\n', Controller.role,
          '\n', update,
          '\n', event
        );

        this.setState(update, () => {

          // YES, DECISION (TU)
          if (isMatchDecisionYes(matchStrengthTag)) {

            // YES ENGAGEABLE
            if (
              (
                Controller.role === 'ListHeader'
              ) ? (
                // FROM LIST HEADER
                hasEngageableMatch()
              ) : (
                // FROM SINGLE CARD
                isMatchEngageable(Controller.props.match)
              )
            ) {
              // OPEN MATCH OPTIONS MENU
              MatchOptionsMenuController().open(
                event,
                {
                  Controller,
                  matchStrength
                }
              );
            }

            // NO ENGAGEABLE
            else {

              // GET TBD LABEL
              matchStrengthTag.label = getToBeDefinedMatchStrengthLabel({
                status: engagement.status,
                value: matchStrengthTag.label
              });

              // CREATE DISAGREEMENT
              MatchPageController().createDisagreement({
                matchStrength: matchStrengthTag.label,
                job,
                candidate,
                engagement,
                employer,
                reviewed
              });

            }
          }

          // NO, DECISION (TD)
          else if (isMatchDecisionNo(matchStrengthTag)) {
            Controller.createDisagreementDialog.open(event);
          }
        });

      }
    }

    const _getThumbColor = (
      thumbId = -1
    ) => {
      const matchStrengthTag = Definition.getTag({
        categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
        tagId: thumbId
      });
      const color = (
        (
          !matchStrengthTag.id
        ) ? (
          'secondary'
        ) : (
          matchStrengthTag.id === matchStrength
        ) ? (
          'primary'
        ) : (
          isMatchDecisionYes(matchStrengthTag)
        ) ? (
          'success'
        ) : (
          isMatchDecisionNo(matchStrengthTag)
        ) ? (
          'error'
        ) : (
          'default'
        )
      );
      return color;
    }

    return (
      <Box row wAuto noWrap spaceBetween className='mx-1'>
        <IconButton small
          color={_getThumbColor(
            ENGAGEMENT_MATCH_STRENGTH__STRONG_NO
          )}
          onClick={_handlerThumbClick(
            ENGAGEMENT_MATCH_STRENGTH__STRONG_NO
          )}
          icon='thumb_down'
        />
        <IconButton small
          color={_getThumbColor(
            ENGAGEMENT_MATCH_STRENGTH__NO
          )}
          onClick={_handlerThumbClick(
            ENGAGEMENT_MATCH_STRENGTH__NO
          )}
          icon='thumb_down'
          className='mr-1'
        />
        <IconButton small
          color={_getThumbColor(
            ENGAGEMENT_MATCH_STRENGTH__WEAK_YES
          )}
          onClick={_handlerThumbClick(
            ENGAGEMENT_MATCH_STRENGTH__WEAK_YES
          )}
          icon='thumb_up'
        />
        <IconButton small
          // title='Yes'
          color={_getThumbColor(
            ENGAGEMENT_MATCH_STRENGTH__YES
          )}
          onClick={_handlerThumbClick(
            ENGAGEMENT_MATCH_STRENGTH__YES
          )}
          icon='thumb_up'
        />
        <IconButton small
          // title='Strong Yes'
          color={_getThumbColor(
            ENGAGEMENT_MATCH_STRENGTH__STRONG_YES
          )}
          onClick={
            _handlerThumbClick(
              ENGAGEMENT_MATCH_STRENGTH__STRONG_YES
            )
          }
          icon='thumb_up'
        />
      </Box >
    );
  }
}

export function setMatchStrength(matchStrengthId) {
  const matchStrengthTag = Definition.getTag({
    categoryKey: DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH,
    tagId: matchStrengthId
  });
  MatchPageController().setState({
    matchStrength: matchStrengthTag.id,
    matchStrengthLabel: matchStrengthTag.label
  });
}


export function showErrorUnexpectedMatchStrength(matchStrength, matchStrengthId) {
  const error = new Error(`Unexpected match tag ${matchStrength}:${matchStrengthId}`);
  if (Core.isProduction()) {
    Core.showError(AppUI.unexpectedError.message, error);
  }
  else {
    Core.showError(error);
  }
}
