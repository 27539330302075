import {
  useTranslation
} from "react-i18next";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Core from "../../../../lib/Core";
import Definition, {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID,
  LOCATION__AMERICAS,
  LOCATION__CALIFORNIA,
  LOCATION__UNITED_STATES,
  LOC_TYPE__ALIAS_ID,
  LOC_TYPE__CITY_ID,
  LOC_TYPE__COUNTRY_ID,
  LOC_TYPE__METRO_AREA_ID,
  LOC_TYPE__METRO_AREA_SEC_ID,
  LOC_TYPE__MULTI_CONT_ID,
  LOC_TYPE__STATE_ID,
  RELOCATION__LABELS,
  RELOCATION__NO_ID,
  RELOCATION__UNKNOWN_ID,
  RELOCATION__YES_ID
} from "../../../../lib/Definition";
import {
  NOT,
  YES
} from "../../../../lib/GenericTools.lib";
import {
  joinKeyName
} from '../../../../lib/String.lib';
import {
  joinClassName
} from '../../../Layout/Libraries/Theme.lib';
import Box, {
  RefBox
} from '../../../Layout/Wrappers/Box';
import Checkbox from '../../../Layout/Wrappers/Checkbox';
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import Menu from '../../../Layout/Wrappers/Menu';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import TextField from '../../../Layout/Wrappers/TextField';
import Typography from '../../../Layout/Wrappers/Typography';
import {
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../Card/JobDetails';

/**
 * 
 * @param {React.Component} Controller  [JobEditController]
 * @returns 
 */
function LocationSection({
  Controller = {},
  className = '',
  style = {},
  ...props
}) {
  const {
    desiredEmploymentTypes = [],
    inOfficeRemoteFlags = [],
    officeLocations = [],
    candidateLocations = [],
    employer = {},
    locationDetails = '',
    relocation = null,
    relocationExtraInfo = '',
  } = Controller.state;
  const { t } = useTranslation();
  const {
    candidateLocations: employerCandidateLocations = []
  } = employer;
  const inOfficeRemoteTags = Definition.get("inOfficeRemote");
  const locationTags = Definition.get("location");
  const desiredEmploymentTypeTags = Definition.get("desiredEmploymentType");
  const candidateLocationTags = [...locationTags];
  if (!!employerCandidateLocations.length) {
    candidateLocationTags.unshift({
      "id": -1,
      "label": "Employer Hiring Locations",
      "type": 1,
      "aliasLocationIds": [...employerCandidateLocations]
    });
  }

  const isRemoteWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
  );

  const isInOfficeWork = (
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID) ||
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID)
  );

  const _hasNoCandidateLocationsAndIsRemote = NOT(candidateLocations.length) && YES(isRemoteWork);
  const _hasNoOfficeLocationsAndIsInOfficeWork = NOT(officeLocations.length) && YES(isInOfficeWork);

  return (
    <Box column {...props}
      role='LocationSection'
      className={
        joinClassName([
          'bg-main p-1 m-1 rounded',
          className
        ])
      }
      style={{
        width: 'calc(100% - 1rem)',
        ...style
      }}
    >
      <RefBox ref={self => Core.setKeyValue('location-section', self)} />

      <Fieldset
        title={
          <>
            {t('job.desiredEmploymentTypes.label')}
          </>
        }
        info={AppUI.publicInfo.tooltip}
        infoIconName={MATERIAL_ICON_NAME__EYE}
        infoProps={{
          onClick: openJobView({
            jobId: Controller.state.id,
            sectionName: JOB_SECTION__SUMMARY
          })
        }}
      >
        {desiredEmploymentTypeTags.map((item, index) => (
          <Checkbox
            key={
              joinKeyName(
                [
                  'location_section',
                  'desired_employment_type',
                  index,
                  item.id
                ]
              )
            }
            label={item.label}
            checked={desiredEmploymentTypes.includes(item.id)}
            onCheck={(ev, checked) => Controller.setState({
              desiredEmploymentTypes: !!checked
                ? [...desiredEmploymentTypes, item.id]
                : desiredEmploymentTypes.filter((n) => n !== item.id),
            })}
          />
        ))}
      </Fieldset>

      <Fieldset
        title={
          <>
            {t('job.inOfficeRemoteFlags.label')}
          </>
        }
        dot='orange'
      >
        {inOfficeRemoteTags.map((tag, index) => (
          <Checkbox
            key={
              joinKeyName(
                [
                  'location_section',
                  'desired_employment_type',
                  index,
                  tag.id
                ]
              )
            }
            label={tag.label}
            checked={inOfficeRemoteFlags.includes(tag.id)}
            onCheck={(ev, checked) => {
              Controller.setState(
                {
                  inOfficeRemoteFlags: !!checked
                    ? [...inOfficeRemoteFlags, tag.id]
                    : inOfficeRemoteFlags.filter((n) => n !== tag.id)
                },
                then => {
                  const state = Controller.state;
                  let update;
                  if (
                    NOT(
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID)
                    )
                  ) {
                    update = {};
                    update.candidateLocations = [];
                  }
                  if (
                    NOT(
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID) ||
                      state.inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID)
                    )
                  ) {
                    update = update || {}
                    update.officeLocations = [];
                  }
                  YES(update) && Controller.setState(update);
                }
              );
            }}
          />
        ))}
      </Fieldset>

      {/** OFFICE & CANDIDATE LOCATIONS */}
      <Box row>

        <Fieldset
          title={
            <>{t('job.officeLocations.label')}</>
          }
          subtitle={
            <>
              <Typography className="c-red" acl={_hasNoOfficeLocationsAndIsInOfficeWork}>
                {t('job.officeLocations.danger')}
              </Typography>
              <Typography className="c-black-medium" acl={NOT(_hasNoOfficeLocationsAndIsInOfficeWork)}>
                {t('job.officeLocations.subtitle')}
              </Typography>
              <Box acl={_hasNoCandidateLocationsAndIsRemote}>
                &nbsp;
              </Box>
            </>
          }
          info={NOT(isInOfficeWork) ? t('job.officeLocations.disabled') : ''}
        >
          <Menu tree
            layers={[
              LOC_TYPE__MULTI_CONT_ID,
              LOC_TYPE__COUNTRY_ID,
              LOC_TYPE__STATE_ID,
              LOC_TYPE__CITY_ID
            ]}
            treeDefaultExpandedIds={[
              LOCATION__AMERICAS,
              LOCATION__UNITED_STATES,
              LOCATION__CALIFORNIA
            ]}
            value={officeLocations}
            onChange={(officeLocations) => Controller.setState({
              officeLocations
            })}
            options={locationTags}
            disabled={!isInOfficeWork}
            fullWidth
          />
        </Fieldset>

        <Fieldset
          title={t('job.candidateLocations.label')}
          subtitle={
            <>
              <Typography sub error
                acl={_hasNoCandidateLocationsAndIsRemote}
              >
                {t('job.candidateLocations.danger')}
              </Typography>
              <Typography sub
                acl={NOT(_hasNoCandidateLocationsAndIsRemote)}
              >
                {t('job.candidateLocations.subtitle')}
              </Typography>
              <Box
                acl={_hasNoOfficeLocationsAndIsInOfficeWork}
              >
                &nbsp;
              </Box>
            </>
          }
          info={NOT(isRemoteWork) && t('job.candidateLocations.disabled')}
        >
          <Menu tree
            layers={[
              LOC_TYPE__ALIAS_ID,
              LOC_TYPE__MULTI_CONT_ID,
              LOC_TYPE__COUNTRY_ID,
              LOC_TYPE__STATE_ID,
              LOC_TYPE__METRO_AREA_ID,
              LOC_TYPE__METRO_AREA_SEC_ID,
              LOC_TYPE__CITY_ID
            ]}
            treeDefaultExpandedIds={[
              LOCATION__AMERICAS,
              LOCATION__UNITED_STATES
            ]}
            value={candidateLocations}
            onChange={(candidateLocations) => Controller.setState({
              candidateLocations
            })}
            options={candidateLocationTags}
            disabled={!isRemoteWork}
          />
        </Fieldset>

      </Box>

      {/** LOCATION DETAILS */}
      <Fieldset
        title={t('job.locationDetails.label')}
      >
        <TextField
          minRows={1}
          maxRows={7}
          placeholder="Type here"
          value={locationDetails}
          style={{ fontSize: "13px" }}
          onChange={(event) => Controller.setState({
            locationDetails: event.target.value
          })}
        />
      </Fieldset>

      {/** RELOCATION ASSISTANCE */}
      <Fieldset
        title={t('job.relocation.label')}
      >
        <RadioGroup
          name="relocation"
          options={[
            { id: RELOCATION__YES_ID, label: RELOCATION__LABELS[RELOCATION__YES_ID] },
            { id: RELOCATION__NO_ID, label: RELOCATION__LABELS[RELOCATION__NO_ID] },
            { id: RELOCATION__UNKNOWN_ID, label: RELOCATION__LABELS[RELOCATION__UNKNOWN_ID] }
          ]}
          value={relocation}
          onChange={(event, relocation) => Controller.setState({ relocation })}
          fullWidth
        />
      </Fieldset>

      {/** RELOCATION INFO */}
      <Fieldset
        title={t('job.relocationExtraInfo.label')}
      >
        <TextField
          minRows={1}
          maxRows={7}
          placeholder="Specify additional information"
          value={relocationExtraInfo}
          onChange={event =>
            Controller.setState({
              relocationExtraInfo: event.target.value
            })
          }
        />
      </Fieldset>

    </Box>
  );
}

export {
  LocationSection, LocationSection as default
};
