import {
  NOT
} from '../../lib/Boolean.lib';
import useState from '../../lib/hooks/useState.hook';
import getStreakNotes from '../../lib/Streak/getStreakNotes.fun';
import { trim } from '../../lib/String.lib';
import Box from '../Layout/Wrappers/Box';
import Fieldset from '../Layout/Wrappers/Fieldset';
import IconButton from '../Layout/Wrappers/IconButton';
import Typography from '../Layout/Wrappers/Typography';

export const KEY__STREAK_NOTES = 'streak_notes';

export default function StreakNotes({
  engagement = {},
  placeholder = 'No notes found',
  ...props
}) {
  const [state, updateState] = useState();
  const _fetchNotes = async () => {
    await updateState({ busy: true });
    try {
      await updateState({
        notes: await getStreakNotes({ boxKey: engagement.boxKey }),
        ready: true
      });
    }
    catch (error) {
      await updateState({ error });
    }
    await updateState({ busy: false });
  }
  const notes = trim(state.notes);
  setTimeout(async () => {
    if (NOT(state.busy || state.ready || state.error)) {
      await _fetchNotes();
    }
  });
  return (
    <Fieldset
      title='Streak notes'
      actionBar={
        <IconButton
          title='Re-fetch notes'
          icon={state.busy ? 'hourglass_empty' : 'refresh'}
          onClick={_fetchNotes}
        />
      }
    >
      <Box scrollX
        className='outlined rounded p-1 bg-white opacity-90'
        style={{ maxHeight: 512 }}
      >
        {
          (
            notes
          ) ? (
            <pre className='m-0'>
              {notes}
            </pre>
          ) : (
            <Typography className='placeholder'>
              {placeholder}
            </Typography>
          )
        }
      </Box>
    </Fieldset>
  )
}
