export const RECRUITER_WITHDRAW_CANDIDATE__SUBJECT = `[ Candidate Withdraw ] {{recruiterFullName}} withdrew {{candidateFullName}} {{todayPT}}`;

export const RECRUITER_WITHDRAW_CANDIDATE__BODY = (`
  <p>
    <b>Withdraw candidate:</b> {{candidateFullName}}
  </p>
  <p><b>Reasons:</b> {{withdrawReasons}}</p>
  {{#if withdrawExcludeUntil}}
    <p><b>Exclude Until:</b> {{withdrawExcludeUntil}}</p>
  {{/if}}
  <p><b>Note:</b> {{{withdrawNote}}}</p>
  <p>
    <b>TODO:</b> <b style="background-color: #f4f429;"><u>Check if any employer needs to be notified</b></u>.
  </p>
`);
