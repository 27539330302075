import Core from "../../Core";
import { STATE_ACTIVE } from "../../Definition";
import Http from "../../Http";

export function search({
  path = 'Entity/_search',
  query = {
    "filters": [{ "state": STATE_ACTIVE, }],
    "sort": { "fieldA": 1, "fieldB": -1, },
    "skip": 0,
    "limit": 10,
    "query": [],
    "associations": ['account']
  },
  mapper = results => results,
}) {
  const mapResponse = response => {
    if (response) {
      response.query = query;
      response.results = mapper(response.results);
    }
    else {
      response = {
        query,
        results: []
      }
    }
    return response;
  }
  let promise = Http.get(
    Core.getApi(path),
    { query: JSON.stringify(query) },
  ).then(mapResponse)
  promise.catch(exception =>
    Core.failure({
      source: 'search.api.js',
      exception,
      params: { path, query }
    })
  );
  return promise;
}
