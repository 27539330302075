import {
  Component
} from "react";
import {
  Obj
} from '../../../lib/Object.lib';
import Box from '../../Layout/Wrappers/Box';
import Button from '../../Layout/Wrappers/Button';
import Drawer from '../../Layout/Wrappers/Drawer';

class MatchDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false
    }
    Object.assign(
      Obj(props.Controller),
      { matchDrawer: this }
    );
  }

  // Open the drawer
  handleOpen = () => this.setState({ drawerOpen: true });

  // Toggle the Drawer
  handleToggle = () => this.setState({ drawerOpen: !this.state.drawerOpen });

  // Close the drawer
  handleClose = () => this.setState({ drawerOpen: false });

  render() {
    const { content } = this.props;

    return (
      <Drawer
        anchor="right"
        open={this.state.drawerOpen}
        SlideProps={{ unmountOnExit: true }}
        onClose={this.handleClose}
        PaperProps={{
          style: { width: 1600, maxWidth: 'calc(100vw - 1rem)' }
        }}
      >
        <Box className='bg-cyan-medium'>
          <Button flat
            onClick={this.handleClose}
            icon={
              <i
                className="material-icons"
                style={{ position: "absolute", left: 10, top: 10 }}
              >
                clear
              </i>
            }
            fullWidth
          >
            Close
          </Button>
        </Box>
        <Box column flex1 scrollY
          className='bg-main'
        >
          {content}
        </Box>
      </Drawer>
    )
  }
}
export default MatchDrawer;