import moment from "moment";
import {
  Component
} from "react";
import AppUI from '../../dictionaries/AppUI.dic';
import {
  Arr,
  sanitizeArr
} from '../../lib/Array.lib';
import {
  YES
} from '../../lib/Boolean.lib';
import Core from "../../lib/Core";
import Definition from "../../lib/Definition";
import FilterControlLib from "../../lib/FilterControl";
import {
  Obj
} from '../../lib/Object.lib';
import FilterLib from "../../lib/services/Filtering/Filter.lib";
import Store from "../../lib/Store";
import formatMoney from "../../lib/tools/formatMoney";
import { joinClassName, THEME__VARIANT__FLAT } from '../Layout/Libraries/Theme.lib';
import Autocomplete from '../Layout/Wrappers/Autocomplete';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Chip from '../Layout/Wrappers/Chip';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import Popover from '../Layout/Wrappers/Popover';
import TextField from '../Layout/Wrappers/TextField';
import MenuBar from './MenuBar';

export default class FilterControl extends Component {
  timeout;
  minSalary = 0;
  maxSalary = 500000;
  minCompanySize = 0;
  minXp = 0;
  maxXp = 40;
  maxCompanySize = 500;
  sliderStep = 10000;
  sliderStepXp = 1;
  sliderStepCS = 5;
  tested = false;

  constructor() {
    super(...arguments);
    const state = {
      items: [],
      chips: [],
      keywords: [],
      search: "",
      sources: [],
      anchorEl: null,
      savedSearches: [],
      minimumSalary: this.props.minimumSalary || Number(this.minSalary),
      maximumSalary: Number(this.maxSalary),
      minimumXp: this.props.minimumXp || Number(this.minXp),
      maximumXp: Number(this.maxXp),
      minimumCompanySize: Number(this.minCompanySize),
      maximumCompanySize: Number(this.maxCompanySize)
    };
    state.menus = this.props.menus || [];
    state.more = this.props.more || [];
    this.state = state;
    const { Controller = {} } = this.props;
    Object.assign(Controller, { filterControl: this });
  }
  setItems = (items, selected, visa, fields = [], filterState = {}) => {
    this.setState(
      state => {
        state.items = items;
        const sources = {};
        const menus = state.menus;
        const more = state.more;
        /* fill menu items */
        items.forEach(item => {
          /* autocomplete */
          item.___keys___.forEach(label => (sources[label] = true));
          /* fill menus items */
          menus.forEach(menu => {
            menu.items = menu.items || {};

            if (!!menu.options) {
              menu.items = menu.options
            } else {
              if (item[menu.field]) {
                if (!!menu.multiple) {

                  if (this.props.source === "jobs" && menu.key === "roles") {
                    if (item.state === 1) {
                      Object.keys(item[menu.field]).forEach(label =>
                        getChecked(menu.items, label)
                      );
                    }
                  }
                  else {
                    /* set new multiple values, if previous values set them */
                    Object.keys(item[menu.field]).forEach(label =>
                      getChecked(
                        menu.items,
                        /* story-3083 M4 | New Locations */
                        FilterControlLib.getItemValue({
                          menu,
                          itemLabel: label
                        })
                      )
                    );
                  }

                } else {
                  /* set previous single value, if not set it as false */
                  getChecked(menu.items, item[menu.field]);
                }
              }
            }

          });
        });

        /* for autocomplete */
        state.sources = Object.keys(sources).sort();
        /* fill more items */
        more.forEach(menu => {
          menu.items = menu.items || {};
          let defKeys = Definition.get(menu.definitionKey || menu.key);

          if (defKeys.length === 0 && menu.options) {
            defKeys = menu.options;
          }

          defKeys.forEach(
            /* set previous single value, if not set it as false */
            def =>
              getChecked(
                menu.items,
                /diversity/i.test(menu.key)
                  ? "Diversity: " + def.label
                  : def.label
              )
          );
        });

        function getChecked(items, label) {
          /* Gets the menu item checkbox value */
          /* xxx(not anymore) if value exits previously return it */
          /* then if value match with selected expresion return it */
          /* if not return false */
          // Core.log(label,selected);
          return (items[label] =
            /** next line is commented because
             * if you go to a match list, it preselect certain tags
             * then on back to the main list the preseleted appears again.
             * we should observe if this change would affect some features.
             */
            // items[label] ||
            selected ? new RegExp(selected, "i").test(label) : false);
        }
        return { ...state, ...filterState };
      },
      then => this.jiraVER20({ label: visa, menuKey: 'visa', checked: true, fields, cb: this.onChange, source: 'setItems' })
    );
  };
  jiraVER20 = ({ label, menuKey, checked, fields = [], cb, source }) => {
    /** [ Jira Ticket VER-20 ] ===>>> * /
    /**
    For Visa,
    Citzen > Green Card > Visa Transfer > Visa Sponsor
    If a candidate is a Citzen, it should fit all jobs.
    If a candidate requires Visa transfers,
    it should fit both Visa transfer and Visa sponsor
    (but not green card and citzen) jobs.
    Always include unspecified, unknow in the search results.
    */
    if (!label) {
      !!cb && cb();
      return;
    }

    const processSingle = (menus, menuKey, label, checked, source) => {
      const menu = menus.find(menu => menu.key === menuKey);

      if (!!menu && menu.inputType === 'radio') {
        let keys = Object.keys(menu.items);
        let items = {};

        keys.forEach(key => {
          items[key] = false;
        })

        menu.items = items;
      }

      if (!!label && !!menu) {
        let labels = label.split(',').map(el => el.trim());
        labels.forEach(label => {
          if (Object.keys(menu.items).includes(label)) {
            menu.items[label] = checked;
          }
        })
      }
    }

    this.setState(state => {
      const menus = [...state.menus, ...state.more];

      if (!!fields.length) {
        fields.forEach(el => {
          processSingle(menus, el.key, el.label, el.checked, source);
        })
      } else {
        processSingle(menus, menuKey, label, checked);
      }
      return state;
    }, cb);
    // see also FilterControl > MenuBar call to jiraVER20 method.
    // see also CandidateMatch.jiraVER20 method.
    // see also JobMatch.jiraVER20 method.
    /** <<<=== [ Jira Ticket VER-20 ] */
  };
  checkLocations = ({ label, checked, cb }) => {
    cb();
  };
  unsetChip = item => {
    this.setState(state => {
      const menus = state.menus;
      const more = state.more;
      if (item.maximumSalary) {
        state.maximumSalary = Number(this.maxSalary);
      }
      else if (item.minimumSalary) {
        state.minimumSalary = Number(this.minSalary);
      }
      else if (item.minimumXp) {
        state.minimumXp = Number(this.minXp);
      }
      else if (item.minimumCompanySize) {
        state.minimumCompanySize = Number(this.minCompanySize);
      }
      else if (item.keyword) {
        state.keywords = state.keywords.filter(
          objKeyword => objKeyword.name !== item.name
        );
      }
      else if (item.menu) {
        menus.find(unselectItem);
      }
      else if (item.more) {
        more.find(unselectItem);
      }
      function unselectItem(menu) {
        return Object.keys(menu.items).find(name => {
          if (name === item.name) {
            menu.items[name] = false;
            return true;
          }
          return false;
        });
      }
      return state;
    }, this.onChange);
  };
  storeState = async (event) => new Promise(resolve => {
    try {
      const state = {
        ...this.state,
        date: moment().toISOString(),
        name: ""
      };
      delete state.items;
      delete state.sources;
      delete state.anchorEl;
      Store.set("saved-searches", [
        state,
        ...Arr(Store.get("saved-searches"))
      ]);
      Core.showSuccess("Search Saved");
    }
    catch (error) {
      Core.showFailure(`Search was not saved: ${error}`);
    }
  });
  cleanSearch = ev =>
    new Promise(resolve => {
      this.setState(state => {
        const menus = state.menus;
        const more = state.more;
        menus.forEach(menu => {
          Object.keys(Obj(menu.items)).forEach(name => {
            menu.items[name] = false;
          });
        });
        more.forEach(menu => {
          Object.keys(Obj(menu.items)).forEach(name => {
            menu.items[name] = false;
          });
        });
        return {
          menus,
          more,
          keywords: [],
          search: "",
          maximumSalary: this.maxSalary,
          minimumSalary: this.minSalary,
          minimumXp: this.minXp,
          minimumCompanySize: Number(this.minCompanySize)
        };
      }, this.onChange);
    }).then();
  getChips = em => {
    const chips = [];
    const menus = this.state.menus;
    const more = this.state.more;
    const state = this.state;

    menus.forEach(menu => {
      menu.items &&
        Object.keys(menu.items).forEach(
          name => menu.items[name] === true && chips.push({ name, menu: true })
        );
    });
    more.forEach(menu => {
      menu.items &&
        Object.keys(menu.items).forEach(
          name => menu.items[name] === true && chips.push({ name, more: true })
        );
    });
    state.keywords.forEach(item => chips.push(item));

    if (state.minimumSalary !== this.minSalary) {
      let prefix = '';

      if (/Candidates|jobMatch/.test(this.props.source)) {
        prefix = 'Accepts Salary <='
      } else {
        prefix = 'Pays Salary >='
      }
      chips.push({
        name: `${prefix} $${formatMoney(state.minimumSalary, 0)}`,
        minimumSalary: true
      });
    }

    if (state.maximumSalary !== this.maxSalary) {
      chips.push({
        name: `Max Salary: $${formatMoney(state.maximumSalary, 0)}`,
        maximumSalary: true
      });
    }

    if (state.minimumXp !== this.minXp) {
      let prefix = '';

      if (/Candidates|jobMatch/.test(this.props.source)) {
        prefix = `has >= ${state.minimumXp}y exp`;
      } else {
        prefix = `require <= ${state.minimumXp}y exp`;
      }

      chips.push({
        name: `${prefix}`,
        minimumXp: true
      });
    }

    if (/Jobs/i.test(this.props.source) && state.minimumCompanySize > this.minCompanySize) {
      let prefix = `require <= ${state.minimumCompanySize} company size`;
      chips.push({
        name: `${prefix}`,
        minimumCompanySize: true
      });
    }

    return chips;
  };
  onChange = e => {
    const { Controller = {} } = this.props;
    if (this.props.onChange instanceof Function) {
      this.setState({ chips: this.getChips() }, then => {
        let filtered = FilterLib.filterList({
          ...this.state,
          pageName: Controller.name,
        });
        this.props.onChange(filtered, this.state.keywords);
      });
    }
  };
  render() {
    if (this.props.acl === false) { return null; }
    const _openSavedSearches = (event) => {
      this.setState({
        savedSearches: Arr(Store.get("saved-searches")),
        anchorEl: event.currentTarget
      });
    };

    Core.setKeyValue('FilterControl__Controller', this);

    const _setSelected = (name) => {
      if (
        !this.state.keywords.find(
          (keyword) => (keyword.name === name)
        )
      ) {
        this.setState(
          {
            keywords: [
              ...this.state.keywords,
              {
                name: name,
                keyword: true
              }
            ]
          },
          this.onChange
        );
      }
    };

    return (
      <Box column w100
        className={joinClassName([
          'border-bottom rounded-sm px-3',
          this.props.className
        ])}
      >

        <Box row w100 wrap
          role='ChipsBar'
          className="rounded-sm bg-white outlined"
        >

          <Box role='FilterControl__ChipsBar' row noWrap scroll
            acl={this.state.chips.length}
            className='py-05'
          >
            <Icon icon='filter_list' className='f-lg mx-1' />
            {sanitizeArr(this.state.chips).map((item, index) => (
              <Chip small key={`filter_control__chip__${index}`}
                className="styled-chip"
                onDelete={ev => this.unsetChip(item, index)}
              >
                {item.name}
              </Chip>
            ))}
          </Box>

          <Box row w100 noWrap scroll
            role='FilterControl__SearchBar'
          >
            <Autocomplete
              name="search"
              placeholder={AppUI.placeholder.filterBar}
              options={this.state.sources}
              onSelect={_setSelected}
              variant={THEME__VARIANT__FLAT}
              clearOnSelect
            />

            <Box role='FilterControl__SaveAndCleanBar' row noWrap wAuto
              acl={this.state.chips.length}
              className='p-05'
            >
              {Core.isAdminOrCoordinator() && (
                <Button outlined small minW120 mr1
                  label="Save Search"
                  onClick={this.storeState}
                />
              )}
              <Button outlined small minW120 warning
                label="Clear All"
                onClick={this.cleanSearch}
              />
            </Box>

          </Box>

        </Box>

        <Box row w100 noWrap scrollX className='py-05'>

          <MenuBar FilterControlController={this} />

          <Box row wAuto noWrap alignRight
            role='FilterControl__RightBar'
          >

            <Box row w100 noWrap alignRight wAuto
              role='FilterControl__SavedSearches'
              acl={
                Core.isAdmin() &&
                YES(Arr(Store.get("saved-searches")).length)
              }
            >
              <Button outlined minW120 mr1 small
                label="Saved Searches"
                onClick={_openSavedSearches}
              />
              <Popover
                open={!!this.state.anchorEl}
                anchorEl={this.state.anchorEl}
                onClose={(event) => this.setState({ anchorEl: null })}
              >
                {this.state.savedSearches.map((state, index) => (
                  <div key={state.date} className="d-flex flex-align-left-center">
                    <Button flat
                      title="Tap to apply filters"
                      label="Apply"
                      onClick={(event) => this.setState({
                        ...state,
                        anchorEl: null
                      }, then => this.onChange())}
                      className='c-black-medium'
                    />
                    <TextField disabledBlurExport
                      title="Enter a name for you search"
                      name="search"
                      className="filter-control-saved-search"
                      style={{ width: "300px", margin: 8 }}
                      autoComplete="off"
                      placeholder={state.date}
                      type="text"
                      value={state.name}
                      onChange={(event, name) => {
                        this.setState({
                          savedSearches: this.state.savedSearches.map(
                            saved => {
                              if (saved.date === state.date) {
                                saved.name = name;
                              }
                              return saved;
                            }
                          )
                        });
                        Store.set(
                          "saved-searches",
                          Store.get("saved-searches").map(saved => {
                            if (saved.date === state.date) {
                              saved.name = name;
                            }
                            return saved;
                          })
                        );
                      }}
                    />
                    <IconButton
                      title="Delete saved search"
                      onClick={(event) => {
                        Store.set('saved-searches',
                          Store.get("saved-searches").filter(saved => (saved.date !== state.date))
                        );
                        _openSavedSearches(event);
                      }}
                    >
                      <i className="material-icons">close</i>
                    </IconButton>
                  </div>
                ))}
              </Popover>
            </Box>

            <Box row wAuto noWrap alignRight
              role='FilterControl__RightToolBar'
            >
              {this.props.toolBarRight}
            </Box>

          </Box>

        </Box>
      </Box >
    );
  }
}

/**
 * @todo [ 2022-11-16 ][ MS: following 3 methods are for future use ]
 */

export function FilterControl__state() {
  try {
    let { state = {} } = Core.getKeyValue('FilterControl__Controller');
    return state;
  }
  catch (exception) {
    console.warn(exception);
    return {};
  }
}

export async function FilterControl__updateState(update, delay) {
  return await new Promise((resolve, reject) => {
    try {
      update = Object(update) === update ? update : {};
      let state = FilterControl__state();
      state = { ...state, ...update };
      delay
        ? setTimeout(() => Core.getKeyValue('FilterControl__Controller').setState(state, () => resolve(state)), delay)
        : Core.getKeyValue('FilterControl__Controller').setState(state, () => resolve(state));
    }
    catch (exception) {
      console.warn(exception);
      reject(exception);
    }
  });
}

export async function FilterControl__setKeyword({ keyword, delay }) {
  try {
    keyword = String(keyword || '').trim();
    let state = FilterControl__state();
    if (!!keyword) {
      await FilterControl__updateState({
        keywords: [
          ...(state.keywords || []),
          {
            name: keyword,
            keyword: true
          }
        ],
        search: ''
      }, delay);
      Core.getKeyValue('FilterControl__Controller').onChange();
    }
  }
  catch (exception) {
    console.debug(exception);
  }
}

export function FilterControlController() {
  return Obj(Core.getKeyValue('FilterControl__Controller'));
}

export function FilterControlSetActive(callback) {
  FilterControlController().setState(
    (state) => {
      state.chips.push({ name: 'Active', more: true });
      return state;
    },
    callback
  );
}
