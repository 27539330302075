import { useState } from 'react';
import Definition from '../../lib/Definition';
import { facets } from '../../lib/services/BE/facets.api';

export function useFacets(facetsPath) {
  const [suggestions, setSuggestions] = useState();
  const fetchSuggestions = ({
    column: {
      id: columnId,
      _facetTagRequired: includeTags,
      _suggestions: suggestions,
      _categoryKey: categoryKey,
      _nestedObject: nestedObject },
    filters = [],
    value = '',
  }) => {
    let tags = [];
    if (categoryKey) {
      tags = Definition.get(categoryKey);
      setSuggestions(tags);
    }
    clearTimeout(useFacets.timeout);
    useFacets.timeout = setTimeout(
      () => {
        if (!(columnId.includes('.') && categoryKey) || nestedObject) {
          facets({
            path: facetsPath,
            query: {
              fields: [columnId],
              filters,
              limit: 30,
              query: value,
              includeTags,
            },
            mapper: (response) => mapSuggestions({
              facets: (() => {
                response = (
                  response[0] ||
                  response
                );
                return (
                  response[columnId] ||
                  response[columnId.replace(/\./g, '__')]
                );
              })(),
              columnId,
              suggestions,
              categoryKey,
              tags
            }),
          }).then(setSuggestions);
        }
      },
      300
    );
  };
  return {
    suggestions,
    fetchSuggestions,
  };
}

export function mapSuggestions({
  facets = [],
  columnId,
  suggestions = [],
  categoryKey,
  tags = []
}) {

  const hash = {};

  // set in hash from default suggestions list
  if (suggestions) {
    suggestions.forEach(str => hash[str] = { id: str, label: str });
  }

  // set in hash from local tag management system
  if (categoryKey) {
    tags.forEach(tag => (hash[tag.id] = tag));
    // console.debug('tags', tags);
  }

  // set in hash from facets response
  facets.forEach(facet => {

    let key = facet._id;

    // special condition for nested objects containing an array of tagIds
    // https://10by10team.slack.com/archives/C02FRNQ9Q5N/p1636742704004600
    if (Array.isArray(key)) {
      if (Array.isArray(key[0])) {
        key[0].forEach(tagId => {
          hash[tagId] = { ...facet, id: tagId, label: Definition.getLabel(categoryKey, tagId) }
        });
      }
      return;
    }

    hash[key] = facet;

  });

  // console.debug('facets', facets);
  // console.debug('hash', hash);

  let list = Object.keys(hash)
    .filter(v => (v !== undefined) && (v !== 'null'))
    .map(key => ({
      ...(hash[key].tag || {}),
      ...hash[key],
      id: isNaN(key) ? key : Number(key),
      label: [
        hash[key].count ? `(${hash[key].count})` : ``,
        hash[key].label
      ].filter(v => !!v).join(' '),
    }))
    .map(tag => ({ ...tag, tag: undefined, parentId: tag.parentId || null }));
  if (list[0] && !parseInt(list[0].id)) {
    list = list.sort((a, b) => String(a.label).localeCompare(b.label));
  }
  list = list.sort((a, b) => parseInt(b.count || 0) - parseInt(a.count || 0));
  // console.debug('suggestions', list);

  return list;

}