
import sanitizeHtml from 'sanitize-html';
import { cssHexToRGB } from '../GenericTools.lib';

/**
 * 
 * @param {string} html 
 * @returns {string}
 */
export default function cleanHtml(options) {
  let { html, sanitize = true } = options;
  html = (typeof options === 'string') ? options : options.html || '';
  if (sanitize) {
    html = sanitizeHtml(html, {
      allowedTags: [
        "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
        "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
        "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
        "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
        "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", 'img', 'font'
      ],
      allowedAttributes: {
        '*': ['style', 'class'],
        'font': ['color', 'background-color'],
        'a': ['href', 'target'],
        'img': ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
        'table': ['border', 'style']
      },
      allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel', 'localhost'],
      allowedSchemesByTag: {
        'img': ['http', 'https', 'data']
      },
      allowProtocolRelative: false,
      parseStyleAttributes: false
    });
  }
  // replacing font html4 tag by span html5 tag
  html = (html
    .replace(/<font/gi, '<span').replace(/<\/font/gi, '</span')
    .replace(/color="/gi, 'style="color:')
    .replace(/style="background-color: .{10,18};" style="/gi, str => str.replace('" style="', ''))
    .replace(/#.{6}"/gi, str => `${cssHexToRGB(str.replace(/#|"/, ''))};"`)
  );
  // reducing empty lines
  html = html.replace(/^(<p><br\s*\/*><\/p>|<br\s*\/*>)*|(<p><br\s*\/*><\/p>|<br\s*\/*>)*$/i, '');
  // minifying code, cleanup break-lines and indentation
  html = html.replace(/ {2,}|\n/g, '').trim();
  // console.debug('cleanHtml', { options, html });
  return html;
}
