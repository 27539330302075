import Store from './Store';

const timers = {};
const dividers = {};
const extra = {};

export const DEBUG_LIB__FLAG_NAME = '10x10__debug';

const debugWrapper = document.createElement('pre');
const time = (id, extraData) => {
  if (!timers[id]) {
    timers[id] = new Date().getTime();
    extra[id] = extraData;
  }
};
const timeEnd = (id) => {
  return new Promise(function (resolve, reject) {
    if (!!timers[id]) {
      const result = new Date().getTime() - timers[id];
      const extraData = extra[id];
      delete timers[id];
      delete extra[id];
      resolve(`${id}${extraData ? `(${extraData})` : ''}: ${result}ms \n${dividers[id] ? '---\n' : ''}`);
    }
  });
};

const message = (msg, clean) => {
  if (Debug.isOn()) {
    if (clean) {
      debugWrapper.textContent = msg;
    } else {
      debugWrapper.textContent = msg + debugWrapper.textContent;
    }
  }
};

const setTime = (id, extraData) => {
  dividers[id] = true;
  Debug.time(id, extraData);
};

const showTimeEnd = (id, clean) => {
  Debug.timeEnd(id).then((res) => Debug.message(res, clean));
};

const init = (em) => {
  debugWrapper.setAttribute('class', 'debug');
  debugWrapper.addEventListener('click', function (ev) {
    Debug.message('', true);
  });
  document.body.appendChild(debugWrapper);
  time('init');
};

const toggle = (em) => {
  return new Promise(function (resolve) {
    const debug = Debug.isOn();
    if (debug) {
      Store.remove(DEBUG_LIB__FLAG_NAME);
    } else {
      Store.set(DEBUG_LIB__FLAG_NAME, true);
    }
    resolve(!debug);
  });
};

const isOn = (em) => !!Store.get(DEBUG_LIB__FLAG_NAME);

const log = isOn() ? Function.prototype.bind.call(console.log, console, 'debug: ') : function () {};

const Debug = {
  time,
  timeEnd,
  message,
  setTime,
  showTimeEnd,
  toggle,
  isOn,
  log,
  init,
};

export default Debug;

export function getCaller() {
  const stack = new Error().stack;
  const caller = stack.split('\n')[4];
  return caller;
}

Debug.init();
