import AppUI from '../../dictionaries/AppUI.dic';
import Core from '../Core';

export default function globalErrorHandler(error) {
  if (Core.isProduction()) {
    Core.showError(AppUI.unexpectedError.message, error);
  }
  else {
    Core.showError(error);
  }
}
