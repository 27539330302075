import copy from "copy-to-clipboard";
import moment from "moment";
import {
  Component,
  Fragment
} from "react";
import Announcement from "../../lib/Announcement";
import Core from "../../lib/Core";
import Definition, {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__LIST_JOBS
} from "../../lib/Definition";
import {
  mapAccount
} from "../../lib/models/account";
import Store from "../../lib/Store";
import {
  getLocation
} from '../../lib/URL.lib';
import {
  showAlert
} from '../Dialogs/AlertDialog';
import Page from '../Layout/Page';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Col from "../Layout/Wrappers/Col";
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import IconButton from '../Layout/Wrappers/IconButton';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import Row from "../Layout/Wrappers/Row";
import TextField from '../Layout/Wrappers/TextField';

class Announcements extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      data: [],
      sortDate: true, // false = ASC; true = DESC;
      sortMethod: this.sortDate
    };
    Store.set("path", getLocation());
    this.fetchAnnouncements();
  }
  copyToClipboard = message => {
    if (
      copy(message, {
        debug: true,
        message: "Press #{key} to copy"
      })
    ) {
      showAlert({ message: 'Copied!', severity: 'success' });
    } else {
      showAlert({ message: 'Fail copy', severity: 'error' });
    }
  };
  fetchAnnouncements() {
    Announcement.getMostRecent({}, data => {
      this.setState(
        state => {
          state.data = data;
          // if state of sort column is defined, invert value for sorting well in #following-sort-call.
          state.sortDate = state.sortDate !== undefined && !state.sortDate;
          state.sortStatus =
            state.sortStatus !== undefined && !state.sortStatus;
          state.sortName = state.sortName !== undefined && !state.sortName;
          return state;
        },
        then => {
          window.scrollTo(0, 0);
          Core.log({ AnnouncementsState: this.state });
          // #following-sort-call.
          this.state.sortMethod();
        }
      );
    });
  }
  sortDate = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a.updatedAt).localeCompare(String(b.updatedAt))
      );
      if (!state.sortDate) {
        state.data = state.data.reverse();
      }
      state.sortDate = !state.sortDate;
      state.sortStatus = undefined;
      state.sortName = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortDate;
      return state;
    });
  sortStatus = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a._status).localeCompare(String(b._status))
      );
      if (state.sortStatus) {
        state.data = state.data.reverse();
      }
      state.sortStatus = !state.sortStatus;
      state.sortDate = undefined;
      state.sortName = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortStatus;
      return state;
    });
  sortName = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a._name).localeCompare(String(b._name))
      );
      if (state.sortName) {
        state.data = state.data.reverse();
      }
      state.sortName = !state.sortName;
      state.sortDate = undefined;
      state.sortStatus = undefined;
      state.sortNote = undefined;
      state.sortMethod = this.sortName;
      return state;
    });
  sortNote = ev =>
    this.setState(state => {
      state.data = state.data.sort((a, b) =>
        String(a.note).localeCompare(String(b.note))
      );
      if (state.sortNote) {
        state.data = state.data.reverse();
      }
      state.sortNote = !state.sortNote;
      state.sortName = undefined;
      state.sortDate = undefined;
      state.sortStatus = undefined;
      state.sortMethod = this.sortNote;
      return state;
    });
  // [TODO: this is not working correctly]
  dateToFromNowDaily = myDate => {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[Last] dddd",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      }
    });
  };

  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    return (
      <Page paper title="Announcements">

        {/** HEADERS */}
        <Row style={{ minWidth: "768px" }}>
          <Col style={{ width: "200px" }}>
            <Button flat
              label="Date"
              className="align-left"
              icon={
                this.state.sortDate === false ? (
                  <i className="material-icons">arrow_drop_up</i>
                ) : (
                  this.state.sortDate === true && (
                    <i className="material-icons">arrow_drop_down</i>
                  )
                )
              }
              onClick={this.sortDate}
            />
          </Col>
          <Col style={{ width: "250px" }}>
            <Button flat
              label="Status"
              className="align-left"
              icon={
                this.state.sortStatus === true ? (
                  <i className="material-icons">arrow_drop_up</i>
                ) : (
                  this.state.sortStatus === false && (
                    <i className="material-icons">arrow_drop_down</i>
                  )
                )
              }
              onClick={this.sortStatus}
            />
          </Col>
          <Col>
            <Button flat
              label="Announcement"
              className="align-left"
              icon={
                this.state.sortName === true ? (
                  <i className="material-icons">arrow_drop_up</i>
                ) : (
                  this.state.sortName === false && (
                    <i className="material-icons">arrow_drop_down</i>
                  )
                )
              }
              onClick={this.sortName}
            />
          </Col>
          <Col>
            <Button flat
              label={
                Core.isAdminOrCoordinator()
                  ? "Note (visible to limited recruiters)"
                  : "Note"
              }
              className="align-left"
              icon={
                this.state.sortNote === true ? (
                  <i className="material-icons">arrow_drop_up</i>
                ) : (
                  this.state.sortNote === false && (
                    <i className="material-icons">arrow_drop_down</i>
                  )
                )
              }
              onClick={this.sortNote}
            />
          </Col>
          <Col style={{ width: "220px" }}>&nbsp;</Col>
        </Row>
        <Divider />
        {/** ITEMS */}
        {this.state.data.map((item, index) => {
          const eventType = Definition.getLabel(
            `announcementJobEventType`,
            item.eventType
          );
          return (
            <Row
              key={item.id}
              style={{ minWidth: "768px", padding: "8px 0" }}
            >
              <Col title="Date" style={{ width: "200px" }}>
                {item._date}
              </Col>
              <Col title="Status" style={{ width: "250px" }}>
                {
                  {
                    Employer: (
                      <Box row noWrap
                        className={`announcement-chip ${{
                          New: "green",
                          Reopened: "yellow",
                          Closed: "red"
                        }[item._eventType]
                          }`}
                      >
                        <i className="material-icons">business</i>
                        <span>{item._status}</span>
                      </Box>
                    ),
                    Job: (
                      <Box row noWrap
                        className={`announcement-chip ${{
                          New: "green",
                          Changed: "yellow",
                          Closed: "red"
                        }[item._eventType]
                          }`}
                      >
                        <i className="material-icons">business_center</i>
                        <span>{item._status}</span>
                      </Box>
                    )
                  }[item.entity]
                }
              </Col>
              <Col title="Announcement">
                {
                  {
                    Employer: Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) ? (
                      <a
                        className=""
                        href={Core.getLink(`/employer/view/${item.employerId}`)}
                      >
                        {item._name}
                      </a>
                    ) : (
                      item._name
                    ),
                    Job: Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }) ? (
                      <NavLink to={`/job/view/${item.jobId}`}>
                        {item._name}
                      </NavLink>
                    ) : (
                      item._name
                    )
                  }[item.entity]
                }
              </Col>
              <Col title="Notes">
                {Core.isAdminOrCoordinator() ? (
                  <TextField small
                    name="note"
                    maxLength="140"
                    rows={1}
                    rowsMax={3}
                    multiLine={true}
                    value={this.state.data[index].note}
                    onChange={(ev, note) =>
                      this.setState(
                        state => {
                          state.data[index].note = note;
                          return state;
                        },
                        then => {
                          clearTimeout(this.timeout);
                          this.timeout = setTimeout(
                            st =>
                              Announcement.update(
                                item.id,
                                this.state.data[index]
                              ),
                            1000
                          );
                        }
                      )
                    }
                    fullWidth
                  />
                ) : (
                  item.note
                )}
              </Col>
              <Col title="Action" style={{ width: "220px" }}>
                {Core.isAdminOrCoordinator() && (
                  <Fragment>
                    <IconButton
                      onClick={ev => {
                        if (item.id) {
                          this.ConfirmDialog.open({
                            message: {
                              Employer: `${item._date} Employer - ${item._eventType
                                } ${item._name}`,
                              Job: `${item._date} Job - ${item._eventType
                                } ${item._name}`
                            }[item.entity],
                            onConfirm: ev =>
                              Announcement.delete(item.id, response =>
                                this.fetchAnnouncements()
                              )
                          });
                        } else {
                          this.setState(state => {
                            state.data.splice(index, 1);
                            return state;
                          });
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                    </IconButton>
                    {!!item.jobId &&
                      /closed/i.test(eventType) && (
                        <IconButton
                          onClick={ev => {
                            const desc = [
                              item.job.jobTitle,
                              "job",
                              eventType + ":",
                              item.note
                            ].join(" ");
                            const sources = [
                              ...item.job.jobSourceList,
                              ...item.job.employer.employerSourceList
                            ]
                              .map(item => {
                                item = mapAccount(item);
                                return [item._name, item.email].join(" ");
                              })
                              .join("\n");
                            this.copyToClipboard(
                              [
                                desc,
                                !!sources.trim().length
                                  ? sources
                                  : "no sources"
                              ].join("\n")
                            );
                          }}
                        >
                          <i className="material-icons">file_copy</i>
                        </IconButton>
                      )}
                  </Fragment>
                )}
              </Col>
            </Row>
          );
        })}
        <ConfirmDialog
          ref={self => (this.ConfirmDialog = self)}
          title="Delete Announcement?"
          message="This action can't be undone."
          actionLabel="Delete"
        />
      </Page>
    );
  }
}

class ConfirmDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false };
  }
  open = ({ message, onConfirm }) => {
    this.setState({ open: true, message, onConfirm });
  };
  submit = id => {
    this.close();
    if (this.props.onConfirm instanceof Function) {
      this.props.onConfirm();
    }
    if (this.state.onConfirm instanceof Function) {
      this.state.onConfirm();
    }
  };
  close = ev => this.setState({ open: false });
  render() {
    const actions = [
      <Button outlined minW120
        label="Cancel"
        onClick={this.close}
      />,
      <Button primary minW120
        label={this.props.actionLabel}
        onClick={this.submit}
      />
    ];
    return (
      <Dialog
        title={this.props.title}
        content={
          <>
            {this.props.message}
            <br />
            {!!this.state.message && `"${this.state.message}"`}
          </>
        }
        actions={actions}
        open={this.state.open}
      />
    );
  }
}

export default Announcements;
