import i18n from 'i18next';
import {
  initReactI18next
} from 'react-i18next';
import {
  TRANSLATIONS_EN_US
} from './en-US/translations';
import {
  TRANSLATIONS_ZH_CN
} from './zh-CN/translations';

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  resources: {
    'en-US': {
      translation: TRANSLATIONS_EN_US,
    },
    'zh-CN': {
      translation: TRANSLATIONS_ZH_CN,
    },
  },
});
export default i18n;
