import copy from "copy-to-clipboard";
import md5 from 'md5';
import ReactDOMServer from "react-dom/server";
import Core from "./Core";
import {
  trimHtml
} from './HTML.lib';
import {
  Str
} from './String.lib';
import cleanHtml from './tools/cleanHtml';
import {
  getHash
} from './URL.lib';
import {
  NOT,
  YES
} from './Boolean.lib';

/**
 *
 * @param {string} value
 * @returns
 */
function capitalize(value = '') {
  return Str(value).replace(/^\s*\w/,
    c => c.toUpperCase()
  );
}

/**
 *
 * @param {string} value Value to encode
 * @returns base64 string
 */
function encodeBase64(value) {
  return window.btoa(value);
}

/**
 * @param {string} value resume url at 10x10
 * @returns {string} filename
 * @samples
 * - http://.../download/filename.pdf
 * - filename.pdf
 */
function getFilenameFromURL(value = '') {
  return value.match(/download\/(.+)$/)[1];
}

/**
 * Returns a new object based in a model.
 *
 * @param {object} model
 * @returns new Model Object
 */
function newModel(model) {
  return JSON.parse(JSON.stringify(model));
}

/**
 * Replace middle content of a long string
 * with 3 dots
 *
 * @param {string} str
 * @returns
 */
function partialCensor(str = '') {
  return `${str.slice(0, 3)}...${str.slice(-3)}`;
}

/**
 * @param {string} value phone to validate
 * @returns boolean
 */
function validatePhone(value = '') {
  return (
    value.replace(/\D/g, '').length >= 10
  );
}

/**
 *
 * @param {string} value email to validate
 * @returns boolean
 */
function validateEmail(value = '') {
  return !!value?.match(
    /^[a-zA-Z0-9.!#µ$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i
  );
}

/**
 *
 * @param {string} value url to validate
 * @returns boolean
 */
function validateURL(value = '') {
  return !!value.match(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );
}

/**
 * @todo document and rename 2021-05-13 µ
 *
 * @param {*} url
 * @returns
 */
function withHttp(url) {
  return !/^http/i.test(url) ? `http://${url}` : url;
}

/**
 *
 * @param {boolean[]} results
 * @returns {number}
 */
function parseBinaryResults(results = []) {
  return parseInt(results.map(n => Number(n)).join(''), 2);
}

/**
 *
 * @param {string} name
 * @returns
 */
const getUnitTestingItemId = name => Number((name?.match(/__.{1}(\d*)/i) || [])[1]);

const getArrayDiff = (a = [], b = []) => b.filter(x => !a.includes(x)).concat(a.filter(x => !b.includes(x)));

const getArrayOfNumberSeries = str => str.split(',').map(s => {
  let res = str;
  const range = s.split('-');
  if (range.length === 2) {
    res = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95].filter(v => range[0] <= v && v <= range[1]).join(',');
  }
  else {
    res = Number(range[0]);
  }
  // console.debug(str, res);
  return res;
}).join(',').split(',').map(v => Number(v));

/**
 * Helper to check if path match with current location
 */
function isLocationPath(path = '') {
  const location = getHash();
  const result = (
    !!location.match(
      new RegExp(path.replace(':id', '\\w+$'), 'i')
    )
  );
  console.debug('µ:isLocationPath', { location, path, result });
  return result;
}

/**
 * 
 * Tries to return the text content from JSX component or html string
 * 
 * Warning: 
 * Don't abuse of using this.
 * Don't used in a list.
 * It is heavy processing data.
 * 
 * @param {*} html 
 * @returns 
 */
function getTextContent(html) {
  if (typeof html === 'string') { return html.replace(/<.+>/g, ''); }
  return ReactDOMServer.renderToString(html).replace(/<.+>/g, '');
}

function chipMatch(result) {

  let className = '';

  if (result === false) {
    className = "bg-red c-white";
  } else if (result === true) {
    className = "border-green";
  } else {
    className = "border-gray";
  }

  return className;

}

function copyString(str) {
  if (
    copy(str, {
      debug: true,
      format: 'text/plain',
      message: "Press #{key} to copy"
    })
  ) {
    Core.showSuccess(`Value copied!`);
  } else {
    Core.showError("Fail copy!");
  }
}

export function getShortURL(url = '') {
  return url.replace(/http(s*):\/\/|www\.|\/.+$|\/$/g, '');
}

export function removeRichTextFormat(value) {
  return cleanHtml(trimHtml(value)).replace(/<\/?(p|ul|ol|li|div|br|strong|hr)(\s\.)?\/?>/gi, '').trim();
}

export function sortWeekDays(data = []) {
  const sorter = {
    "sunday": 0,
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6
  }
  return data.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
}

export function cssHexToRGB(str = '') {
  let aRgbHex = str.match(/.{1,2}/g);
  let aRgb = `rgb(${[
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16)
  ]})`;
  return aRgb;
}

export function stringToBoolean(val) {
  var a = {
    'true': true,
    'false': false
  };
  return a[val];
}

export function getStringDifference(a = '', b = '') {
  a = String(a);
  b = String(b);
  let i = 0;
  let j = 0;
  let result = [];
  let prevIndex = -1;
  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) {
      if (prevIndex === j - 1 && result[result.length - 1]) {
        result[result.length - 1].value += b[j];
      }
      else {
        result.push({ index: j, value: b[j] });
      }
      prevIndex = j;
    }
    else
      i++;
    j++;
  }
  return result;
}

/**
 * 
 * @param {string} a 
 * @param {string} b 
 * @returns {string} b text highlighting differences with HTML tags
 */
export function getHighlightedDifferences(a = '', b = '') {
  a = String(a);
  b = String(b);
  let differences = getStringDifference(a, b);
  let result = [];
  let indexB = 0;
  differences.forEach((difference, index) => {
    result.push(b.slice(indexB, difference.index));
    result.push(`<span style='background-color:#F8E71C;'>${difference.value}</span>`);
    indexB = difference.index + difference.value.length;
    if (index === differences.length - 1) {
      result.push(b.slice(indexB));
    }
  });
  if (!differences.length && a !== b) {
    result.push(b);
    result.push(`<span style='background-color:#F8E71C;'>&nbsp;</span>`);
  }
  return result.join('');
}

export function mapUpdatedSet(model = {}) {
  model.updatedAt = new Date().toISOString();
  model.updatedBy = Core.getUserId();
  return model;
}

export const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  }
}

export function unCamelCase(str = '') {
  return String(str)
    // insert a space between number & upper
    .replace(/(\d)([A-Z])/g, '$1 $2')
    // insert a space between lower & upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // space before last upper in a sequence followed by lower
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    // uppercase the first character
    .replace(/^./, function (str) { return str.toUpperCase(); });
}

export function getGravatarURL(email) {
  return `https://2.gravatar.com/avatar/${md5(email || '')}?d=404`;
}

export function replaceRegExp(string) {
  return String(string || '').replace(/(\\\w)|[.*+?^${}()|[\]\\]/g, '');
}

export function escapeRegExp(string) {
  return String(string || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function evalSameName(a, b) {
  return !!String(a || '').match(
    new RegExp(`^${escapeRegExp(b)}$`, 'i')
  );
}

window.evalSameName = evalSameName;

const GenericTools = {
  capitalize,
  encodeBase64,
  getFilenameFromURL,
  newModel,
  partialCensor,
  validateEmail,
  validatePhone,
  validateURL,
  withHttp,
}

export {
  YES as BOOLEAN,
  capitalize,
  chipMatch,
  copyString,
  GenericTools as default,
  encodeBase64,
  getArrayDiff,
  getArrayOfNumberSeries,
  getFilenameFromURL,
  getTextContent,
  getUnitTestingItemId,
  isLocationPath,
  newModel,
  NOT,
  parseBinaryResults,
  partialCensor,
  GenericTools as tools,
  validateEmail,
  validatePhone,
  validateURL,
  withHttp,
  YES
};
