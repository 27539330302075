
export const SALARY_CURRENCY__USD = 'USD';
export const SALARY_CURRENCY__EUR = 'EUR';
export const SALARY_CURRENCY__OTHER = 'Other';

export const SALARY_CURRENCIES = [
  SALARY_CURRENCY__USD,
  SALARY_CURRENCY__EUR,
  SALARY_CURRENCY__OTHER
];
