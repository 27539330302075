import {
  Component
} from "react";
import AppUI from '../../../../dictionaries/AppUI.dic';
import {
  Arr
} from '../../../../lib/Array.lib';
import {
  MATERIAL_ICON_NAME__EYE
} from '../../../../lib/constants/MaterialIconName.dic';
import Definition from "../../../../lib/Definition";
import {
  Obj
} from '../../../../lib/Object.lib';
import Chips from "../../../Forms/Chips";
import StepHeader from '../../../Forms/StepHeader';
import {
  JOB_SECTION__EMPLOYER__TEAM_STRUCTURE,
  JOB_SECTION__EMPLOYER__TECHNICAL_SKILLS,
  JOB_SECTION__SUMMARY,
  openJobView
} from '../../../Jobs/Card/JobDetails';
import Box from '../../../Layout/Wrappers/Box';
import Col from "../../../Layout/Wrappers/Col";
import Fieldset from '../../../Layout/Wrappers/Fieldset';
import RadioGroup from '../../../Layout/Wrappers/RadioGroup';
import RichTextBox from "../../../Layout/Wrappers/RichTextBox";
import Row from "../../../Layout/Wrappers/Row";
import TextField from '../../../Layout/Wrappers/TextField';
import EmployerUI from '../../EmployerUI.dic';

class Basics extends Component {
  render() {
        const EmployerEditController = this.props.parent;
    const employer = EmployerEditController.state;
    const _firstJobId = Obj(Arr(Obj(employer).jobs)[0]).id;
    return (
      <Box column w100 className='p-1'>

        <StepHeader {...this.props} />

        <Row>
          <Fieldset
            title={EmployerUI.teamCount.title}
            info={AppUI.publicInfo.tooltip}
            infoIconName={MATERIAL_ICON_NAME__EYE}
            infoProps={{
              onClick: openJobView({
                jobId: _firstJobId,
                sectionName: JOB_SECTION__SUMMARY
              })
            }}
            fullWidth={false}
          >
            <TextField
              name="minCompanySize"
                            min="1"
              type="number"
              value={EmployerEditController.state.teamCount}
              onChange={(event, teamCount) => {
                EmployerEditController.setState({ teamCount });
              }}
              fullWidth
            />
          </Fieldset>
        </Row>

        <Fieldset
          title={EmployerUI.teamStructure.title}
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__EMPLOYER__TEAM_STRUCTURE
            })
          }}
        >
          <RichTextBox
            name="teamStructure"
            value={EmployerEditController.state.teamStructure}
            onChange={teamStructure =>
              EmployerEditController.setState({ teamStructure })
            }
          />
        </Fieldset>

        <Row className='mt-3' show={false}>
          <Col fullWidth>
            <label>
              Explain the most exciting and interesting engineering challenges
              you're solving in the next 6 - 18 months?
            </label>
            <RichTextBox
              name="teamChallenge"
              value={EmployerEditController.state.teamChallenge}
              onChange={teamChallenge =>
                EmployerEditController.setState({ teamChallenge })
              }
            />
          </Col>
        </Row>

        <Fieldset
          title='Technology stack'
          info={AppUI.publicInfo.tooltip}
          infoIconName={MATERIAL_ICON_NAME__EYE}
          infoProps={{
            onClick: openJobView({
              jobId: _firstJobId,
              sectionName: JOB_SECTION__EMPLOYER__TECHNICAL_SKILLS
            })
          }}
        >
          <Chips
            name="technicalSkills"
            values={EmployerEditController.state.technicalSkills}
            onChange={technicalSkills => EmployerEditController.setState({ technicalSkills })}
          />
        </Fieldset>

        <Row className='mt-3'>
          <Col fullWidth>
            <label className='tt-unset'>
              US work visa
            </label>
            <RadioGroup
              name="visaTransfer"
              options={Definition.get("visa")}
              value={EmployerEditController.state.visaTransfer}
              onChange={(event, visaTransfer) => EmployerEditController.setState({ visaTransfer })}
            />
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <label>
              Work From Home (WFH) Policy
            </label>
            <RadioGroup
              name="employers__forms__remote"
              options={Definition.get("remote")}
              value={EmployerEditController.state.remote}
              onChange={(event, remote) => EmployerEditController.setState({ remote })}
              className='mt-1'
            />
            <div className='d-flex flex-align-left-center mt-1'>
              <strong className='tt-unset c-black-medium f-sm mr-1'>
                WFH details
              </strong>
              <div className='flex-1'>
                <TextField
                  maxLength={100}
                  placeholder="Specify additional information"
                  value={EmployerEditController.state.remoteExtraInfo}
                  variant="standard"
                  margin="normal"
                  onChange={(event, remoteExtraInfo) =>
                    EmployerEditController.setState({ remoteExtraInfo })
                  }
                  fullWidth
                />
              </div>

            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col fullWidth>
            <label>
              Relocation Provided?
            </label>
            <RadioGroup
              name="relocation"
              options={[
                { id: 0, label: 'Yes' },
                { id: 1, label: 'No' },
                { id: 2, label: 'Unknown' }
              ]}
              value={EmployerEditController.state.relocation}
              onChange={(event, relocation) => EmployerEditController.setState({ relocation })}
            />
            <div className='d-flex flex-align-left-center mt-1'>
              <strong className='tt-unset c-black-medium f-sm mr-1'>
                Relocation Details
              </strong>
              <div className='flex-1'>
                <TextField
                  maxLength={100}
                  placeholder="Specify additional information"
                  value={EmployerEditController.state.relocationExtraInfo}
                  variant="standard"
                  margin="normal"
                  onChange={(event, relocationExtraInfo) =>
                    EmployerEditController.setState({ relocationExtraInfo })
                  }
                  fullWidth
                />
              </div>
            </div>
          </Col>
        </Row>

      </Box>
    );
  }
}

export default Basics;
