import React from 'react';
import { getCompany, saveCompany } from '../../../lib/Company';
import Core from '../../../lib/Core';
import { SUGGESTION_TYPE__COMPANY, SUGGESTION_TYPE__SCHOOL } from '../../../lib/Crunchbase.lib';
import { getSchool, saveSchool } from '../../../lib/School';
import Box from '../../Layout/Wrappers/Box';

export async function addAliasToHistoryEntity({ type = SUGGESTION_TYPE__COMPANY, entityId, name }) {
  let entity = type === SUGGESTION_TYPE__SCHOOL
    ? await getSchool({ id: entityId }).catch(Core.showError)
    : await getCompany({ id: entityId }).catch(Core.showError);
  if (
    entity &&
    !!String(name || '').trim() &&
    (entity.name !== name) &&
    !entity.alternativeNames.includes(name)
  ) {
    let alternativeNames = [
      ...new Set([
        ...entity.alternativeNames,
        name
      ])
    ].map(v => String(v || '').toLowerCase()).filter(v => !!v && v !== String(entity.name || '').toLowerCase());
    if (alternativeNames.join('') !== entity.alternativeNames.join('')) {
      await (
        new Promise(function (resolve, reject) {
          Core.showConfirm({
            title: 'Confirm company alias',
            content: (
              <Box column w100>
                <div>
                  Would you like to add "{name}" as an alias to "{entity.name}"?
                </div>
                <div className='mt-2'>
                  {entity.name} aliases: {alternativeNames.join(', ')}
                </div>
                <div className='mt-2'>
                  Click on "Configure Company" if you'd like to modify the list of aliases.
                </div>
              </Box>
            ),
            async onAccept() {
              entity.alternativeNames.push(name);
              if (type === SUGGESTION_TYPE__SCHOOL) {
                await saveSchool({ school: entity }).catch(Core.showError);
              }
              else {
                await saveCompany({ company: entity }).catch(Core.showError);
              }
              resolve();
            },
            onAcceptLabel: 'Add alias',
            onCancel: resolve
          });
        })
      );
    }
  }
}
