import {
  isString
} from 'lodash';
import {
  useRef
} from 'react';
import ReactQuill, {
  Quill
} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppUI from '../../../dictionaries/AppUI.dic';
import {
  Fun
} from '../../../lib/Function.lib';
import useStateObserved from '../../../lib/hooks/useStateObserved.hook';
import {
  trimHtml
} from '../../../lib/HTML.lib';
import fixQuillHtml from '../../../lib/Html/fixQuillHtml.fun';
import {
  Obj
} from '../../../lib/Object.lib';
import {
  Str
} from '../../../lib/String.lib';
import {
  joinClassName,
  mapWrapper
} from '../Libraries/Theme.lib';
import Box from './Box';
import {
  Html
} from './Html';
import IconButton from './IconButton';
import Typography from './Typography';

// Register a new Block (optional if using a different block element)
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

/**
 * RichTextBox component for handling rich text input and editing.
 *
 * @see https://www.npmjs.com/package/react-quill
 * @see https://github.com/zenoamaro/react-quill
 * 
 * @todo Look for upgrades...
 * @see https://github.com/zenoamaro/react-quill/issues/989
 * @see https://www.npmjs.com/package/react-quill-new
 * @see https://github.com/VaguelySerious/react-quill
 *
 * @returns {JSX.Element|null} - The rendered component or null if acl is false.
 * @param {Object} params - Parameters for the RichTextBox component.
 * @param {boolean} [params.acl=true] - Access control flag, determines if the component should render.
 * @param {boolean} [params.debug=false] - Debug flag for logging additional information.
 * @param {string} [params.placeholder=''] - Placeholder text for the editor.
 * @param {string} [params.value=''] - Initial value of the editor content.
 * @param {string} [params.autoComplete=''] - Autocomplete behavior.
 * @param {boolean} [params.autoFocus=false] - Determines if the editor should autofocus.
 * @param {Function} [params.onChange=Fun] - Callback for when the content changes.
 * @param {Function} [params.onBlur=null] - Callback for when the editor loses focus.
 * @param {Function} [params.onFocus=null] - Callback for when the editor gains focus.
 * @param {Function} [params.onKeyDown=null] - Callback for handling keydown events.
 * @param {Function} [params.onKeyPress=null] - Callback for handling keypress events.
 * @param {string} [params.className=''] - Additional class names for the wrapper.
 * @param {Object} [params.style={}] - Inline styles for the wrapper.
 * @param {boolean} [params.disabled=false] - Flag to disable the editor.
 * @param {boolean} [params.taller=false] - Flag to make the editor taller.
 * @param {boolean} [params.xtaller=false] - Flag to make the editor extra tall.
 * @param {boolean} [params.minH128=false] - Flag to set a minimum height of 128px.
 * @param {boolean} [params.exportOnBlur=true] - Determines if the value should be exported on blur instead on change.
 * @param {boolean} [params.disabledBlurExport=false] - Disables exporting on blur when true.
 * @param {Object} [params.wrapperProps={}] - Additional props for the wrapper element.
 * @param {Object} [params.rtbProps={}] - Additional props for the rich text editor component.
 * @param {...Object} props - Additional properties passed to the component.
 *
 * @returns {JSX.Element|null} - The RichTextBox component or null if acl is false.
 */
export default function RichTextBox({
  acl = true,
  debug = false,
  placeholder = '',
  value: propsValue = '',
  autoComplete = '',
  autoFocus = false,
  onChange = Fun,
  onBlur = null,
  onFocus = null,
  onKeyDown = null,
  onKeyPress = null,
  className = '',
  style = {},
  disabled = false,
  taller = false,
  xtaller = false,
  minH128 = false,
  exportOnBlur = true,
  disabledBlurExport = false,
  wrapperProps = {},
  rtbProps = {},
  ...props
}) {
  propsValue = fixQuillHtml(propsValue);
  const defaultState = { value: propsValue };
  const input = useRef(null);
  const focused = useRef({});
  const [{
    value = '',
    editing = false,
  }, updateState] = useStateObserved({
    state: defaultState,
    observed: defaultState
  });

  if (!acl) { return null; }

  if (disabledBlurExport) {
    exportOnBlur = false;
  }

  const _exportValue = async (event) => {
    const eventValue = (
      (
        isString(event)
      ) ? (
        event
      ) : (
        Str(Obj(Obj(event).target).innerHTML) ||
        value
      )
    );
    const fixedValue = fixQuillHtml(eventValue);
    console.debug(
      'EXPORT...',
      '\n', { propsValue },
      '\n', { eventValue },
      '\n', { fixedValue },
    );
    await updateState({ value: fixedValue });
    if (fixedValue !== propsValue) {
      onChange(fixedValue);
    }
  };

  const _onChange = async (_value) => {
    console.debug('CHANGE', _value);
    if (exportOnBlur) {
      await updateState({ value: _value });
    }
    else {
      await _exportValue({ target: { innerHTML: _value } });
    }
  };

  const _onBlur = async (event) => {
    console.debug('BLUR', event);
    if (exportOnBlur && event.target.classList.contains('ql-editor')) {
      focused.current = false;
      setTimeout(async () => {
        if (focused.current === false) {
          await updateState({ editing: false });
          _exportValue(event);
        }
      }, 111);
    }
    Fun(onBlur)(event);
  };

  const _onFocus = (event) => {
    console.debug('FOCUS', event);
    if (exportOnBlur) {
      focused.current = true;
    }
    Fun(onFocus)(event);
  };

  const _onKeyDown = async (event) => {
    console.debug('KEYDOWN', event);
    if (
      exportOnBlur &&
      !!Str(event.key).match(/enter|tab/i)
    ) {
      await _exportValue(event);
    }
    Fun(onKeyDown)(event);
    Fun(onKeyPress)(event);
  };

  const _toggleEditingMode = async (event) => {
    const editingMode = !editing;
    await updateState({ editing: editingMode });
    if (editingMode) {
      if (input.current) {
        input.current.getEditor().focus();
      }
    }
    else {
      _exportValue(value);
    }
  };

  placeholder = placeholder || (disabled ? AppUI.disabled : AppUI.placeholder.multiline);
  const flags = { debug, disabled };
  const colors = [
    '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
    '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
    '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
    '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
    '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
    '#663d00', '#666600', '#003700', '#002966', '#3d1466', '#536dfe',
    '#009688', '#4f8078'
  ];
  const toggleEditableButton = (
    <Box wAuto hAuto
      className='hover-visible'
      style={{
        position: 'absolute',
        top: editing ? 1 : 0,
        right: editing ? 1 : 0
      }}
      onClick={_toggleEditingMode}
    >
      <IconButton small
        title='Toggle RTB edit mode'
        icon='edit_square'
      />
    </Box>
  );
  return (
    (
      editing
    ) ? (
      <Box column
        {...mapWrapper({
          role: 'RichTextBox__Wrapper__Editing',
          props: wrapperProps,
          assign: {
            onBlur: _onBlur,
            onFocus: _onFocus,
            className: joinClassName([
              'flex-column flex-1 relative hover-border-purple',
              className
            ]),
            style,
          }
        })}
      >
        <ReactQuill
          {...mapWrapper(
            {
              role: 'RichTextBox',
              props: rtbProps,
              assign: {
                ...props,
                value,
                onChange: _onChange,
                onKeyDown: _onKeyDown,
                onKeyPress: _onKeyDown,
                theme: 'snow',
                placeholder,
                modules: {
                  toolbar: [
                    [{ header: '1' }, { header: '2' }],
                    [{ color: colors }, { background: colors }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                    ['clean']
                  ],
                  clipboard: {
                    matchVisual: false
                  }
                },
                formats: [
                  'header',
                  'font',
                  'size',
                  'color',
                  'background',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'indent',
                  'link',
                  'image',
                  'video'
                ],
                readOnly: disabled,
                className: joinClassName([
                  'flex-column flex-1',
                  disabled && 'ql-rtb-disabled',
                  xtaller ? 'ql-xtaller' : taller ? 'ql-taller' : 'ql-normal',
                  minH128 && 'ql-min-h128',
                ])
              },
              flags
            }
          )}
          ref={input}
        />
        {toggleEditableButton}
      </Box>
    ) : (
      <Box column
        {...mapWrapper({
          role: 'RichTextBox__Wrapper__Reading',
          props: wrapperProps,
          assign: {
            className: joinClassName([
              'flex-column flex-1 outlined rounded f-sm hover-border-purple relative bg-white-lighter',
              xtaller ? 'ql-xtaller' : taller ? 'ql-taller' : 'ql-normal',
              minH128 && 'ql-min-h128',
              className
            ]),
            style: {
              minHeight: 36,
              ...style
            },
            onClick: _toggleEditingMode,
          }
        })}
      >
        {
          trimHtml(
            value
          ) ? (
            <Html
              value={propsValue}
              className='scroll-y p-1 ql-reader'
            />
          ) : (
            <Typography className='placeholder p-1'>
              {placeholder}
            </Typography>
          )
        }
        {toggleEditableButton}
      </Box>
    )
  );
}
