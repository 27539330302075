import Fab from '@mui/material/Fab';
import React from 'react';
import {
  THEME__COLOR__PRIMARY
} from '../Libraries/Theme.lib';
import {
  MATERIAL_ICON_NAME__ADD
} from '../../../lib/constants/MaterialIconName.dic';
import StyledTooltip from './StyledTooltip';

export default function FloatingActionButton({
  title,
  icon,
  children,
  color = THEME__COLOR__PRIMARY,
  ...props
}) {
  const _props = {
    children: children || icon || <i className='material-icons'>{MATERIAL_ICON_NAME__ADD}</i>,
    color,
    sx: {
      position: 'fixed',
      bottom: 16,
      right: 16,
    },
    ...props
  }

  return (
    <StyledTooltip title={title}>
      <Fab {..._props} />
    </StyledTooltip>
  );
};
