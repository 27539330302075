import {
  STAGE_SUBMISSION
} from '../../../../dictionaries/Engagement.dic';
import getJobOwnershipTextAndColor from '../../../../lib/GetJobOwnershipTextAndColor';
import {
  getMatchEntities
} from '../MatchList.lib';

export default function notFoundInPermittedJobWithOwnership({
  newEngagement,
  match
}) {
  const {
    candidate = {},
    job = {}
  } = getMatchEntities({ match });
  let { isOwned, iOwnIt, isTbd, iTbdItWithoutEngagement } =
    getJobOwnershipTextAndColor(
      candidate.jobOwnerships,
      job.employerId,
      candidate.id
    );
  let msg = false;
  let isOwnedBySomeone = isOwned.length && !iOwnIt;
  if (isOwnedBySomeone) {
    msg = "Another recruiter has ownership for this candidate for this employer, you aren't  allowed to create this engagement";
  }
  else if (
    (!!isTbd.length || iTbdItWithoutEngagement) &&
    (newEngagement.status === STAGE_SUBMISSION)
  ) {
    msg = 'The ownership for the candidate has not been finalized, so cannot proceed to submission stage';
  }
  return msg;
}
