import React from "react";
import {
  MDASH
} from '../../../lib/Constants';
import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EDIT_JOB
} from '../../../lib/Definition';
import Link from '../../Layout/Wrappers/Link';
import JobHeaderChips from './JobHeaderChips';

export default function JobHeader({
  job = {},
  candidate = {}
}) {
  const {
    employer = {}
  } = job;
  let jobEntities = [];
  if (!!employer._employerHiringDifficulty) {
    jobEntities.push(`(${employer._employerHiringDifficulty})`);
  }
  if (!!job.filters?.Role) {
    jobEntities.push(job.filters.Role);
  }
  if (!!job.addressCity) {
    jobEntities.push(job.addressCity);
  }
  return (
    <div className='flex-column py-05' style={{ minHeight: 120 }}>
      <div className='mt-1 p-05'>
        <strong className='mr-1'>
          {!!employer.id && (
            (employer.name) && Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) ? (
              <Link href={`#/employer/edit/${employer.id}`}>
                {employer.name}
              </Link>
            ) : (
              employer.name
            )
          )}
        </strong>
        <span className='f-md'>
          {!!jobEntities.length ? jobEntities.join(", ") : ''}
        </span>
      </div>
      <div className="d-flex flex-align-left-center f-sm p-05" >
        {!!job.id && (
          Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
            <Link href={`#/job/edit/${job.id}`}>
              <u>{job.jobTitle}</u>
            </Link>
          ) : (
            <u>{job.jobTitle}</u>
          )
        )}
        <span className='mx-1'>{MDASH}</span>
        <span
          className="anchor"
          onClick={() => job.openDetails()}
        >
          Job Details
        </span>
      </div>
      {!!candidate.id &&
        <div className='p-05'>
          <JobHeaderChips
            candidate={candidate}
            job={job}
          />
        </div>
      }
    </div>
  );
}
