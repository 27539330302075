
import { Input } from 'antd';
import { isBoolean, trim } from 'lodash';
import React from "react";
import { Str } from '../../lib/String.lib';

export default function FilterBoolean({
  column: {
    id: columnId,
  },
  filters = {},
  setFilter = () => { },
}) {
  let value = filters[columnId];
  return (
    <div className="d-flex">
      <Input
        className="mr-1"
        style={{ width: 120 }}
        value={isBoolean(value) ? String(value) : Str(value)}
        onChange={(event) => {
          let value = Str(event.target.value);
          let boolean = value.match(/true/i) ? true : value.match(/false/i) ? false : null;
          setFilter({
            columnId,
            value: isBoolean(boolean) ? boolean : (trim(value) || undefined)
          });
        }}
      />
    </div>
  );
}
