import {
  Component
} from "react";
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';

class ConfirmDialog extends Component {
  constructor() {
    super(...arguments);
    this.state = { open: false };
  }
  open = ({ title, message, onConfirm }) => {
    this.setState({ open: true, title, message, onConfirm });
  };
  submit = id => {
    this.close();
    if (this.props.onConfirm instanceof Function) {
      this.props.onConfirm();
    }
    if (this.state.onConfirm instanceof Function) {
      this.state.onConfirm();
    }
  };
  close = ev => this.setState({ open: false });
  render() {
    return (
      <Dialog
        title={this.state.title || this.props.title || 'Confirmation'}
        content={
          <Box alignCenter className='f-lg'>
            {!!this.state.message && this.state.message}
            <br />
            {this.props.message}
          </Box>
        }
        actions={[
          <Button outlined minW120
            label="Cancel"
            onClick={this.close}
          />,
          <Button primary minW120
            label={this.props.actionLabel}
            onClick={this.submit}
          />
        ]}
        open={this.state.open}
        paperStyle={{ width: 512 }}
      />
    );
  }
}

export default ConfirmDialog;
