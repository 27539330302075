import { deleteCompany } from '../components/Company/Company';
import Core from "./Core";
import { NEGATIVE_SIGNALS__CODDING_BOOTCAMP, NEGATIVE_SIGNALS__CONSULTING_BACKGROUND, NEGATIVE_SIGNALS__EMPLOYMENT_GAP, NEGATIVE_SIGNALS__JOB_HOPPER, NEGATIVE_SIGNALS__LARGE_SLOW_COMPANY_TYPE, NEGATIVE_SIGNALS__NON_TECH_COMPANY, NEGATIVE_SIGNALS__POOR_COMMUNICATION, NEGATIVE_SIGNALS__TYPOS, POSITIVE_SIGNALS__ACES_CODING_INTERVIEWS, POSITIVE_SIGNALS__ACM_COMPETITION, POSITIVE_SIGNALS__ACQUIRED_STARTUP, POSITIVE_SIGNALS__EAGLE_SCOUT, POSITIVE_SIGNALS__FAANG, POSITIVE_SIGNALS__FORTUNE_1000, POSITIVE_SIGNALS__FOUNDING_TEAM, POSITIVE_SIGNALS__GITHUB, POSITIVE_SIGNALS__GREAT_GITHUB, POSITIVE_SIGNALS__HACKATHON, POSITIVE_SIGNALS__HACKATHON_WINNER, POSITIVE_SIGNALS__IMPRESSIVE_GITHUB, POSITIVE_SIGNALS__INTERVIEW_TEAM, POSITIVE_SIGNALS__MENTOR, POSITIVE_SIGNALS__OPEN_SOURCE_PROJECT, POSITIVE_SIGNALS__PATENT, POSITIVE_SIGNALS__PRE_SEED_EXPERIENCE, POSITIVE_SIGNALS__PROMOTION, POSITIVE_SIGNALS__PUBLICATIONS, POSITIVE_SIGNALS__PUBLIC_COMPANY, POSITIVE_SIGNALS__SEED_EXPERIENCE, POSITIVE_SIGNALS__SERIES_A_EXPERIENCE, POSITIVE_SIGNALS__SERIES_B_EXPERIENCE, POSITIVE_SIGNALS__SERIES_C_EXPERIENCE, POSITIVE_SIGNALS__SERIES_D_EXPERIENCE, POSITIVE_SIGNALS__SERIES_E_PLUS_EXPERIENCE, POSITIVE_SIGNALS__SIDE_PROJECT, POSITIVE_SIGNALS__SMALL_COMPANY, POSITIVE_SIGNALS__STARTUP_EXPERIENCE, POSITIVE_SIGNALS__STRONG_GITHUB, POSITIVE_SIGNALS__STRONG_TECH_COMPANY, POSITIVE_SIGNALS__TEACH_LEAD, POSITIVE_SIGNALS__TECH_COMPANY, POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY, POSITIVE_SIGNALS__TOP_TIER_VC, POSITIVE_SIGNALS__UNICORN_STARTUP, POSITIVE_SIGNALS__WON_AWARD, POSITIVE_SIGNALS__YCOMBINATOR_STARTUP } from './Definition';
import Http from "./Http";
import getCompanyModel from './models/company.model';
import { mapOrganizationSignals, mapOrganizationTenureSignals, mapTenure, SIGNALS_TYPE__COMPANY } from '../components/Candidates/OrganizationSignals.lib';

export const POSITIVE_COMPANY_SIGNAL_TAG_IDS = [
  POSITIVE_SIGNALS__FAANG,
  POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY,
  POSITIVE_SIGNALS__STRONG_TECH_COMPANY,
  POSITIVE_SIGNALS__TECH_COMPANY,
  POSITIVE_SIGNALS__STARTUP_EXPERIENCE,
  POSITIVE_SIGNALS__TOP_TIER_VC,
  POSITIVE_SIGNALS__UNICORN_STARTUP,
  POSITIVE_SIGNALS__YCOMBINATOR_STARTUP,
  POSITIVE_SIGNALS__ACQUIRED_STARTUP,
  POSITIVE_SIGNALS__SMALL_COMPANY,
  POSITIVE_SIGNALS__FORTUNE_1000,
  POSITIVE_SIGNALS__PUBLIC_COMPANY
];

export const POSITIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED = [
  POSITIVE_SIGNALS__PRE_SEED_EXPERIENCE,
  POSITIVE_SIGNALS__SEED_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_A_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_B_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_C_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_D_EXPERIENCE,
  POSITIVE_SIGNALS__SERIES_E_PLUS_EXPERIENCE,
  POSITIVE_SIGNALS__IMPRESSIVE_GITHUB,
  POSITIVE_SIGNALS__GREAT_GITHUB,
  POSITIVE_SIGNALS__STRONG_GITHUB,
  POSITIVE_SIGNALS__GITHUB,
  POSITIVE_SIGNALS__OPEN_SOURCE_PROJECT,
  POSITIVE_SIGNALS__HACKATHON_WINNER,
  POSITIVE_SIGNALS__HACKATHON,
  POSITIVE_SIGNALS__ACM_COMPETITION,
  POSITIVE_SIGNALS__WON_AWARD,
  POSITIVE_SIGNALS__EAGLE_SCOUT,
  POSITIVE_SIGNALS__SIDE_PROJECT,
  POSITIVE_SIGNALS__MENTOR,
  POSITIVE_SIGNALS__PROMOTION,
  POSITIVE_SIGNALS__INTERVIEW_TEAM,
  POSITIVE_SIGNALS__FOUNDING_TEAM,
  POSITIVE_SIGNALS__TEACH_LEAD,
  // commented following by the moment, we will retake diversity sync after release 2022/08/05 µ
  // POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND, 
  POSITIVE_SIGNALS__ACES_CODING_INTERVIEWS,
  POSITIVE_SIGNALS__PATENT,
  POSITIVE_SIGNALS__PUBLICATIONS
];

export const NEGATIVE_COMPANY_SIGNAL_TAG_IDS = [
  NEGATIVE_SIGNALS__LARGE_SLOW_COMPANY_TYPE,
  NEGATIVE_SIGNALS__NON_TECH_COMPANY,
  NEGATIVE_SIGNALS__CONSULTING_BACKGROUND
];

export const NEGATIVE_COMPANY_SIGNAL_TAG_IDS__EXTENDED = [
  NEGATIVE_SIGNALS__JOB_HOPPER,
  NEGATIVE_SIGNALS__EMPLOYMENT_GAP,
  NEGATIVE_SIGNALS__CODDING_BOOTCAMP,
  NEGATIVE_SIGNALS__POOR_COMMUNICATION,
  NEGATIVE_SIGNALS__TYPOS,
];

export const Company = {

  post: async (data, success) => {
    return Http.post(
      Core.getApi("Company/"),
      data,
      success
    );
  },
  update: async (companyId, data, success) => {
    return Http.patch(
      Core.getApi("Company/" + companyId),
      data,
      success
    );
  },
  get: async (options = {}, cb) => {
    let { where, fields, limit } = options;
    where = Object(where);
    return Http.get(
      Core.getApi("Company"),
      {
        filter: JSON.stringify({ where, fields, limit })
      },
      cb
    );
  },
  delete: async companyId => Http.delete(Core.getApi(`Company/${companyId}`)),
  /**
 * @param {object} options
 * @param {string} options.companyId 
 * @param {string} options.tenureStart "YYYY" | "YYYY-MM" | "YYYY-MM-DD"
 * @param {string} options.tenureEnd "YYYY" | "YYYY-MM" | "YYYY-MM-DD"
 * @param {string} options.description
 * @param {object} options.bulkList [{companyId,tenureStart,tenureEnd}]
 */
  getSignals: async ({ companyId, tenureStart, tenureEnd, description, bulkList = [], reset }) => {
    if (companyId) {
      return Http.post(Core.getApi(`Company/${companyId}/_signals`), reset ? { reset } : { tenureStart, tenureEnd, description });
    }
    else if (bulkList.length) {
      return Http.post(Core.getApi(`Company/_signals`), bulkList).then(mapCompanyTenureSignals);
    }
  }
};
export default Company;

export async function fetchEmploymentHistorySignals({ employmentHistories = [], revisionDate }) {
  if (!employmentHistories.length) { return {}; }
  return Company.getSignals({
    bulkList: employmentHistories
      .filter(({ companyId }) => !!companyId)
      .map(mapTenure)
  });
}

export async function getCompany({
  id,
  name,
  exact = false,
  query
}) {
  if (!id && !name && !query) { return null; }
  let where = (
    id
      ? { id }
      : exact
        ? {
          or: [
            { name: { inq: [name] } },
            { alternativeNames: { inq: [name] } },
          ],
        }
        : {
          or: [
            { name: { like: name, options: 'i' } },
            { alternativeNames: { like: name, options: 'i' } }
          ]
        }
  );
  return Http.get(
    Core.getApi('Company'),
    {
      filter: JSON.stringify(query || { where }),
    }
  ).then(company => company[0]).then(mapCompany);
}

export async function getCompanyDuplicates({
  name,
  exact = false
}) {
  if (!name) { return null; }
  let where = (
    exact
      ? {
        or: [
          { name: { like: `^${name}$`, options: 'i' } },
          { alternativeNames: { like: `^${name}$`, options: 'i' } },
        ],
      }
      : {
        or: [
          { name: { like: name, options: 'i' } },
          { alternativeNames: { like: name, options: 'i' } }
        ]
      }
  );
  return Http.get(
    Core.getApi('Company'),
    {
      filter: JSON.stringify({ where }),
    }
  ).then(mapCompanies);
}

export function mapCompany(company) {
  if (!company) { return null; }
  company = company || getCompanyModel();
  company.alternativeNames = (
    Array.isArray(company.alternativeNames) ? company.alternativeNames : []
  );
  company.signalTags = mapOrganizationSignals({
    signals: company.signalTags || {},
    type: SIGNALS_TYPE__COMPANY
  });
  company.delete = async () => deleteCompany({ companyId: company.id });
  return company;
}

export function mapCompanies(companies) {
  companies = Array.isArray(companies) ? companies : [];
  return companies.map(mapCompany).filter(v => !!v);
}

export function getPositionId({ employerOrgName, title }) {
  return (
    `${employerOrgName || ''}${title ? ` - ${title}` : ''}`.trim()
  )
}

export function evalDefaultPosition({ candidate, employment, position }) {
  return (
    candidate.currentEmployer &&
    (
      getPositionId({
        employerOrgName: employment.employerOrgName,
        title: position.title
      })
      ===
      getPositionId({
        employerOrgName: candidate.currentEmployer,
        title: candidate.currentTitle
      })
    )
  );
}

export async function fixCompanyName({ company = {}, organization = {} }) {
  if (
    company.id
    &&
    organization.id
    &&
    (company.crunchbaseOrganizationId === organization.id)
    &&
    company.name.toLowerCase() !== organization.name.toLowerCase()
  ) {
    company.alternativeNames = [
      ...new Set([
        ...(company.alternativeNames || []).map(v => String(v || '').toLowerCase()),
        String(company.name || '').toLowerCase()
      ])
    ].filter(v => !!v && v !== organization.name);
    company.name = organization.name;
    return Company.update(company.id, company);
  }
  return company;
}

export function mapCompanyTenureSignals(__dicSignals = {}) {
  let hash = {};
  Object.entries(__dicSignals).forEach(([key, value]) =>
    hash[key] = mapOrganizationTenureSignals({
      signals: value,
      type: SIGNALS_TYPE__COMPANY
    })
  );
  return hash;
}

export async function saveCompany({ company, entity }) {
  company = company || entity;
  let signalTags = { ...company.signalTags };
  delete signalTags.automated.entity;
  delete signalTags.automated.tenure;
  delete signalTags.positive;
  delete signalTags.negative;
  return Company.update(company.id, {
    name: company.name,
    description: company.description,
    alternativeNames: company.alternativeNames,
    signalTags,
    crunchbaseOrganizationId: company.crunchbaseOrganizationId,
    updatedBy: Core.getUserId()
  });
}

export async function getCompanyCrunchbaseOrganization(options = {}) {
  let { companyId, where, fields, limit } = options;
  where = Object(where);
  return Http.get(
    Core.getApi(`Company/${companyId}/crunchbaseOrganization`),
    {
      filter: JSON.stringify({ where, fields, limit })
    }
  );
}