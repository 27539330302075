import {
  matchSkillWithText
} from '../../../Candidates/SubmissionNotes.lib';
import {
  evaluateAnyHaves,
  haveDecision
} from './Complex.lib';

export function evaluateChipTag({ have, resumeText }) {
  let isLabelInText = [];
  const valueArray = have.value.split(',');
  if (!!resumeText && !!resumeText.length) {
    isLabelInText = valueArray.map((value) => matchSkillWithText(value, resumeText));
  }
  return have.match = isLabelInText.includes(true);
}

export function evaluateChipTagWithDecision({ have, resumeText }) {
  return evaluateAnyHaves({ decisions: [haveDecision(evaluateChipTag({ have, resumeText }))] });
}
