import Core from '../../../lib/Core';
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__EDIT_EMPLOYER,
  ACCOUNT_ACTION__EDIT_JOB
} from '../../../lib/Definition';
import NavLink from '../../Layout/Wrappers/NavLink';
import Table from '../../Layout/Wrappers/Table';
import StyledTooltip, {
  PLACEMENT__BOTTOM_START, PLACEMENT__RIGHT
} from '../../Layout/Wrappers/StyledTooltip';
import {
  getMatchInfoString
} from '../../Match/Libraries/Match.lib';
import MatchDecisions from '../../Match/List/MatchDecisions';

export function Row1Col1(props) {
  const { engagement } = props;
  return (
    <Table.Cell
      onClick={props.onClick}
      className="first-item blocks cursor-default"
      style={{ minWidth: 300, maxWidth: 300 }}
      colSpan={3}
    >
      <span className="fw-500 c-black-medium f-unset">
        {Core.isAdmin() && (
          <MatchDecisions engagement={engagement}>
            {getMatchInfoString({ engagement })}
          </MatchDecisions>
        )}
      </span>
      <div className="d-flex wrap">
        {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }) ? (
          <StyledTooltip
            title='Candidate | Go to edit Candidate'
            placement={PLACEMENT__BOTTOM_START}
          >
            <NavLink
              to={'/candidate/edit/' + engagement.candidateId}
              className='hover-bg'
            >
              <b>{engagement.candidate._name_rating}</b>
            </NavLink>
          </StyledTooltip>
        ) : (
          <b>{engagement.candidate._name_rating}</b>
        )}
        &nbsp;-&nbsp;
        {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_EMPLOYER }) ? (
          <StyledTooltip
            title='Employer (Employer Stage) | Go to edit Employer'
            placement={PLACEMENT__RIGHT}
          >
            <NavLink
              to={'/employer/edit/' + engagement.job.employerId}
              className='hover-bg'
            >
              <b>{engagement.employer._name_rating}</b>
            </NavLink>
          </StyledTooltip>
        ) : (
          <b>{engagement.employer._name_rating}</b>
        )}
      </div>
      {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) ? (
        <StyledTooltip
          title="Job Title - Role | Go to edit Job"
          placement={PLACEMENT__RIGHT}
        >
          <NavLink
            to={'/job/edit/' + engagement.jobId}
            className='w-100 wrap hover-bg'
          >
            {engagement._jobTag}
          </NavLink>
        </StyledTooltip>
      ) : (
        <span title="Job Title - Role">{engagement._jobTag}</span>
      )}
      {Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }) ? (
        <StyledTooltip
          title={
            `Recruiter Name - Agency Name${!!engagement.candidate?._contactNames?.length
              ? `\nCandidate Recruiters: ${engagement.candidate._contactNames.join(', ')}`
              : ''
            }`
          }
          placement={PLACEMENT__RIGHT}
        >
          <NavLink
            to={`/account/edit/${engagement.recruiter?.id}`}
            className='w-100 wrap hover-bg'
          >
            {engagement._recruiterName} - {engagement._agencyName}
          </NavLink>
        </StyledTooltip>
      ) : (
        <StyledTooltip
          title={
            `Recruiter Name - Agency Name${!!engagement.candidate?._contactNames?.length
              ? `\nCandidate Recruiters: ${engagement.candidate._contactNames.join(', ')}`
              : ''
            }`
          }
          placement={PLACEMENT__RIGHT}
          className='w-100 wrap hover-bg'
        >
          {engagement._recruiterName} - {engagement._agencyName}
        </StyledTooltip>
      )
      }
    </Table.Cell >
  );
}
