import Core from '../../lib/Core';
import { ACCOUNT_ACTION__EDIT_JOB, STATE_ACTIVE } from '../../lib/Definition';
import Job from '../../lib/Job';
import { mapJobs } from '../../lib/models/job';
import { FilterDateRangeColumn } from './FilterDateRangeColumn';
import { FilterLocationsColumn } from './FilterLocationsColumn';
import { FilterNumber } from './FilterNumber';
import { FilterStringColumn } from './FilterStringColumn';
import { moreMenuOptionsJobs } from './3DotsMenu/moreMenuOptionsJobs';
import {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn,
  FilterNumberRangeColumn,
  FilterTagColumn
} from './ReactTable';
import {
  candidateLocationsWarningRender,
  inOfficeRemoteFlagsWarningRender,
  officeLocationsWarningRender
} from './renderLocationColumnWarning.lib';
import {
  REACT_TABLE__COLUMN_ACTION,
  REACT_TABLE__COLUMN_LG,
  REACT_TABLE__COLUMN_RANGE_NUMBER,
  REACT_TABLE__COLUMN_SM,
  REACT_TABLE__COLUMN_XS,
  REACT_TABLE__COLUMN_XXL
} from './useEnhancedReactTable.hook';

export const configJobs = {
  Entity: Job,
  entityMapper: mapJobs,
  searchPath: 'Jobs/_search',
  autocompletePath: 'Jobs/_autocomplete',
  facetsPath: 'Jobs/_facets',
  starredAccessor: 'jobStarreds',
  searchPlaceholder: 'Search by employer name, role, title',
  addNew: () => Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_JOB }) && {
    label: '+ New Job',
    to: '/job/create'
  },
  defaultQuery: {
    filters: [
      {
        state: STATE_ACTIVE,
      },
    ],
    // "sort": { "jobTitle": 1, [Core.isLimited() ? "employer.proxyName" : "employer.name"]: 1 },
    skip: 0,
    limit: 10,
    query: [],
    associations: ['employer', 'jobStarreds'],
  },
  hiddenColumns: ['submissionMethods', 'state'],
  columns: [
    {
      accessor: 'roles',
      Header: 'Roles',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      _accessor: '_roles',
      _categoryKey: 'roles',
    },
    {
      accessor: 'jobTitle',
      Header: 'Title',
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_LG,
      _accessor: '_infoCmp',
      _headerClassName: 'sticky-start bg-cyan-common',
      _cellClassName: 'sticky-start bg-white-common',
    },
    {
      accessor: 'employer.name',
      Header: 'Employer',
      Filter: FilterStringColumn,
      Cell: CellTagColumn,
      disableFilters: false,
      width: REACT_TABLE__COLUMN_LG,
      _accessor: '_employerName',
    },
    {
      accessor: 'technicalSkills',
      Header: 'Technology',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      _accessor: '_technicalSkills',
      _categoryKey: 'technicalSkills',
    },
    {
      accessor: 'visaTransfer',
      Header: 'Visa',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_visaTransfer',
      _categoryKey: 'visa',
    },
    {
      accessor: 'salaryMax',
      Header: 'Jobs offering',
      Filter: FilterNumber,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_salaryMax',
      _maximumValue: 500000,
    },
    {
      accessor: 'minYearsOfExperience',
      Header: 'Years of Experience',
      Filter: FilterNumber,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_yearsOfExperience',
      _maximumValue: 40,
    },
    {
      accessor: 'inOfficeRemoteFlags',
      Header: 'On-site/Remote',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_LG,
      _accessor: '__inOfficeRemoteFlags',
      _categoryKey: 'inOfficeRemote',
      _renderSubFilter: inOfficeRemoteFlagsWarningRender,
    },
    {
      accessor: 'candidateLocations',
      Header: 'WFH Locations',
      // Filter: FilterTagColumn,
      Filter: FilterLocationsColumn,
      Cell: CellTagColumn,
      disableSortBy: true,
      width: REACT_TABLE__COLUMN_XXL,
      _accessor: '__candidateLocations',
      _categoryKey: 'location',
      _renderSubFilter: candidateLocationsWarningRender,
      _facetTagRequired: true,
    },
    {
      accessor: 'officeLocations',
      Header: 'Office Locations',
      // Filter: FilterTagColumn,
      Filter: FilterLocationsColumn,
      Cell: CellTagColumn,
      disableSortBy: true,
      width: REACT_TABLE__COLUMN_XXL,
      _accessor: '__officeLocations',
      _categoryKey: 'location',
      _renderSubFilter: officeLocationsWarningRender,
      _facetTagRequired: true,
    },
    {
      accessor: 'employer.stage',
      Header: 'Stage',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_employeeStage',
      _categoryKey: 'stage',
    },
    {
      accessor: 'employer.employeeCount',
      Header: 'Employees',
      Filter: FilterNumberRangeColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_RANGE_NUMBER,
      _accessor: '_employeeCount',
    },
    {
      accessor: 'desiredEmploymentTypes',
      Header: 'Job Type',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_desiredEmploymentTypes',
      _categoryKey: 'desiredEmploymentType',
    },
    {
      _acl: [Core.isAdminOrTrusted()],
      accessor: 'jobDescriptionPublicURL',
      Header: 'Public Description',
      _accessor: '_jobDescriptionPublicURL'
    },
    {
      _acl: [Core.isAdmin()],
      accessor: 'placementFee',
      Header: 'Placement Fee',
      _accessor: '_placementFee',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      _acl: [Core.isAdmin()],
      accessor: 'guarantee',
      Header: 'Guarantee',
      width: REACT_TABLE__COLUMN_SM,
    },
    {
      accessor: 'updatedAt',
      Header: 'Recent',
      Filter: FilterDateRangeColumn,
      width: REACT_TABLE__COLUMN_XXL,
      disableFilters: false,
      Cell: CellTagColumn,
      _accessor: '_updatedAt',
    },
    {
      _acl: [Core.isAdminOrCoordinator()],
      accessor: 'submissionMethods',
      Header: 'Submission',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_SM,
      _accessor: '_submissionMethods',
    },
    {
      accessor: 'state',
      Header: 'State',
      Filter: FilterTagColumn,
      Cell: CellTagColumn,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_XS,
      _acl: [Core.isAdminOrCoordinator()],
      _accessor: '_state',
      _categoryKey: 'state',
    },
    {
      id: 'actions',
      Header: 'Actions',
      Filter: FilterActionsColumn,
      Cell: CellActionsColumn,
      defaultCanFilter: true,
      disableFilters: false,
      disableSortBy: false,
      width: REACT_TABLE__COLUMN_ACTION,
      _starredAccessor: 'candidateStarreds.starred',
      _headerClassName: 'sticky-end bg-cyan-common c-white-all bl-1',
      _cellClassName: 'sticky-end bg-white-common bl-1 d-flex flex-align-right-center',
    },
  ],
  moreMenuOptions: moreMenuOptionsJobs,
};
