import Core from "../Core";
import axios from 'axios';
import FileSaver from 'file-saver';

async function downloadFile({ url, mimeType, onError }) {
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    const blob = new Blob([response.data], { type: mimeType });
    FileSaver.saveAs(blob, String(url).split("/").pop());
  }
  catch (error) {
    Core.failure({
      source: 'downloadFile.js(tool)',
      exception: error,
      params: { url, mimeType, onError }
    });

    if (onError instanceof Function) {
      onError(error);
    }
  }
}

export default downloadFile;
