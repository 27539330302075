import Core from '../../lib/Core';
import useState from '../../lib/hooks/useState.hook';
import Store from '../../lib/Store';
import {
  trim
} from '../../lib/String.lib';
import {
  getParams
} from '../../lib/URL.lib';
import Button from '../Layout/Wrappers/Button';
import Navigate from '../Layout/Wrappers/Navigate';
import NavLink from '../Layout/Wrappers/NavLink';
import TextField from '../Layout/Wrappers/TextField';
import {
  LoginLayout,
  loginUI
} from './Login';

export default function ResetPassword(props) {
  const [_state, _updateState] = useState({
    email: '',
    password: '',
    confirm: '',
    error: {
      email: false,
      password: false,
      confirm: false,
    }
  });
  if (Core.isLogged()) {
    return <Navigate to="/" />;
  }
  const _doReset = async (event) => {
    try {
      const inputs = { newPassword: _state.password };
      if (!trim(inputs['newPassword'])) {
        _state.error.password = loginUI.errorText;
        await _updateState({ error: _state.error });
        throw Error(_state.error.password);
      }
      if (inputs.newPassword !== _state.confirm) {
        _state.error.confirm = loginUI.errorText;
        await _updateState({ error: _state.error });
        throw Error(_state.error.confirm);
      }
      const { token } = getParams({ pattern: '/reset/password/:token' });
      await Core.resetPassword({ ...inputs, token });
      const email = Store.get('email');
      Core.showSuccess('Your password has been reset successfully', async () => {
        if (email) {
          await Core.login({ email, password: inputs.newPassword });
        }
        else {
          Core.go({ ...props, to: '/login' });
        }
      });
    }
    catch (error) {
      Core.showError(error);
    }
  };
  return (
    <LoginLayout>
      <TextField disabledBlurExport required autoFocus
        placeholder='Password'
        id="password"
        name="password"
        autoComplete="password"
        value={_state.password}
        onChange={async (event, password) => {
          _state.error.password = false;
          await _updateState({ password, error: _state.error });
        }}
        type="password"
        errorText={_state.error.password}
      />
      <TextField disabledBlurExport required
        placeholder='Confirm password'
        name="confirm"
        autoComplete="confirm"
        className='mt-1'
        value={_state.confirm}
        onChange={async (event, confirm) => {
          _state.error.confirm = false;
          await _updateState({ confirm, error: _state.error });
        }}
        type="password"
        errorText={_state.error.confirm}
      />
      <Button fullWidth
        label="RESET PASSWORD"
        className="mt-3"
        onClick={_doReset}
      />
      <NavLink decoration to='login' className='flex-align-center mt-2'>
        Log in
      </NavLink>
    </LoginLayout>
  );

}
