import {
  Arr
} from '../../../../lib/Array.lib';
import {
  Obj
} from '../../../../lib/Object.lib';
import {
  Str
} from '../../../../lib/String.lib';
import formatURL from '../../../../lib/tools/formatURL';

export default function getRemoteCandidateResumePdfUrl(candidate) {
  candidate = Obj(candidate);
  const originalUrl = Str(Obj(Arr(candidate.resumes)[0]).url);
  const parsedUrl = Str(
    candidate.resumePdfUrl
  );
  return formatURL(
    originalUrl.match(/pdf/i)
      ? originalUrl
      : parsedUrl
  );
}
