import Definition from '../../../lib/Definition';
import Job from '../../../lib/Job';
import Chips from '../../Forms/Chips';
import Col from '../../Layout/Wrappers/Col';
import Row from '../../Layout/Wrappers/Row';

export function CandidateRolesFieldset(props) {
  let { CandidateEditController } = props;
  let { state } = CandidateEditController
  let { chipsAvailableRoles = [] } = state;
  const _getAvailableRoles = async () => {
    return new Promise((resolve, reject) => {
      if (!chipsAvailableRoles.length) {
        let allRoles = Definition.get('roles');
        let allRolesIds = allRoles.map((el) => el.id);
        let filters = {
          where: { role: { inq: allRolesIds } },
          fields: ['role', 'state'],
        };
        Job.getAll(
          (response) => {
            let onlyActiveIds = response; //.filter(el=>parseInt(el.state)===1);
            let myRoles = state.roles || [];
            let allAvailableIds = [...onlyActiveIds.map((el) => parseInt(el.role)), ...myRoles];
            let availableIdsUniq = Array.from(new Set(allAvailableIds));
            let filtered = allRoles.filter((el) => availableIdsUniq.includes(parseInt(el.id)));
            CandidateEditController.setState({ chipsAvailableRoles: filtered });
            setTimeout(resolve);
          },
          filters,
          { commonQuery: { include: false } }
        );
      }
      else { resolve(); }
    });
  };
  if (CandidateEditController.scrollToRole) {
    if (CandidateEditController.rolesLabel && !CandidateEditController.rolesLabelScrolled) {
      CandidateEditController.rolesLabelScrolled = true;
      setTimeout(() => { CandidateEditController.rolesLabel.scrollIntoView('smooth'); }, 1111);
    }
  }
  if (!CandidateEditController.step3Loaded) {
    CandidateEditController.step3Loaded = true;
    _getAvailableRoles();
  }
  return (
    <Row className='mt-3'>
      <Col fullWidth>
        <Chips
          name='roles'
          items={state.chipsAvailableRoles}
          isLoading={!!chipsAvailableRoles.length ? false : true}
          label={
            <label ref={(self) => (CandidateEditController.rolesLabel = self)}>
              Roles <b style={{ color: 'orange' }}>*</b>
            </label>
          }
          sub='Please select all the roles your candidate is interested in applying for'
          values={CandidateEditController.state.roles || []}
          onChange={(roles) => {
            CandidateEditController.setStateStore({ roles, errorRoles: '', key: 'roles' });
          }}
          onClickAddChipEvent={async event => {
            return _getAvailableRoles();
          }}
        />
        <div className='cred'>{CandidateEditController.state.errorRoles}</div>
      </Col>
    </Row>
  );
}
