import {
  IN_OFFICE_REMOTE__FULL_WEEK_ID,
  IN_OFFICE_REMOTE__PART_WEEK_ID,
  IN_OFFICE_REMOTE__REMOTE_ONLY_ID
} from "../../lib/Definition";
import { parseBinaryResults } from "../../lib/GenericTools.lib";

export function mapLocationFilters({
  Entity,
  filters = {},
}) {

  let newFilters = { ...filters };

  let {
    inOfficeRemoteFlags = [],
    officeLocations = [],
    candidateLocations = [],
  } = filters;

  const filterId = parseBinaryResults([
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__REMOTE_ONLY_ID),
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__FULL_WEEK_ID),
    inOfficeRemoteFlags.includes(IN_OFFICE_REMOTE__PART_WEEK_ID),
    candidateLocations.length,
    officeLocations.length
  ]);

  // console.debug('locations-filter-id', filterId);

  filterPatterns[filterId].forEach(patternMethod => {
    newFilters = patternMethod({ Entity, filters: newFilters });
  });

  delete newFilters.inOfficeRemoteFlags;
  delete newFilters.candidateLocations;
  delete newFilters.officeLocations;

  return newFilters;

}

export function remotePattern({
  Entity,
  filters = {},
}) {

  // console.debug('filters', filters);

  let {
    inOfficeRemoteFlags = [],
    candidateLocations = [],
  } = filters;
  let newFilters = { ...filters };

  const inOfficeRemoteFlagsMatch = { inOfficeRemoteFlags };
  const inOfficeRemoteFlagsNull = [
    { "inOfficeRemoteFlags": { "$exists": false } },
    { "inOfficeRemoteFlags": null },
    { "inOfficeRemoteFlags": { "$size": 0 } },
  ];

  const candidateLocationMatch = { candidateLocations };
  const candidateLocationNull = [
    { "candidateLocations": { "$exists": false } },
    { "candidateLocations": null },
    { "candidateLocations": { "$size": 0 } },
  ];

  const locationFilters = [
    {
      "$and": [
        inOfficeRemoteFlagsMatch,
        {
          "$or": [
            candidateLocationMatch,
            ...candidateLocationNull
          ]
        }
      ]
    },
    {
      "$and": [
        {
          "$or": [
            inOfficeRemoteFlagsMatch,
            ...inOfficeRemoteFlagsNull
          ]
        },
        candidateLocationMatch,
      ]
    }
  ];
  newFilters['$or'] = newFilters['$or'] || [];
  newFilters['$or'] = [...newFilters['$or'], ...locationFilters];
  return newFilters;
}

export function officePattern({
  Entity,
  filters = {},
}) {
  let {
    inOfficeRemoteFlags = [],
    officeLocations = [],
  } = filters;
  let newFilters = { ...filters };

  const inOfficeRemoteFlagsMatch = { inOfficeRemoteFlags };
  const inOfficeRemoteFlagsNull = [
    { "inOfficeRemoteFlags": { "$exists": false } },
    { "inOfficeRemoteFlags": null },
    { "inOfficeRemoteFlags": { "$size": 0 } },
  ];

  const officeLocationMatch = { officeLocations };
  const officeLocationNull = [
    { "officeLocations": { "$exists": false } },
    { "officeLocations": null },
    { "officeLocations": { "$size": 0 } },
  ];

  const locationFilters = [
    {
      "$and": [
        inOfficeRemoteFlagsMatch,
        {
          "$or": [
            officeLocationMatch,
            ...officeLocationNull
          ]
        }
      ]
    },
    {
      "$and": [
        {
          "$or": [
            inOfficeRemoteFlagsMatch,
            ...inOfficeRemoteFlagsNull
          ]
        },
        officeLocationMatch,
      ]
    }
  ];
  newFilters['$or'] = newFilters['$or'] || [];
  newFilters['$or'] = [...newFilters['$or'], ...locationFilters];
  return newFilters;
}

// filter-pattern pairs
export const filterPatterns = {
  "0": [],
  "1": [],
  "2": [],
  "3": [],
  "4": [],
  "5": [officePattern],
  "6": [],
  "7": [officePattern],
  "8": [],
  "9": [officePattern],
  "10": [],
  "11": [officePattern],
  "12": [],
  "13": [officePattern],
  "14": [],
  "15": [officePattern],
  "16": [],
  "17": [],
  "18": [remotePattern],
  "19": [remotePattern],
  "20": [],
  "21": [officePattern],
  "22": [remotePattern],
  "23": [remotePattern, officePattern],
  "24": [],
  "25": [officePattern],
  "26": [remotePattern],
  "27": [remotePattern, officePattern],
  "28": [],
  "29": [officePattern],
  "30": [remotePattern],
  "31": [remotePattern, officePattern]
};
