import {
  Arr
} from '../../../lib/Array.lib';
import Core from '../../../lib/Core';
import {
  NOT
} from '../../../lib/GenericTools.lib';
import {
  COLLECTION__EMPLOYERS,
  readLoopbackRecord
} from '../../../lib/services/BE/loopback.api';
import Store from '../../../lib/Store';
import {
  getParams
} from '../../../lib/URL.lib';
import Page from '../../Layout/Page';
import IconButton from '../../Layout/Wrappers/IconButton';
import useState from '../../../lib/hooks/useState.hook';
import {
  AdminATSMappingContent
} from './AdminATSMappingContent';

const CONFIG = {
  storageName: 'ten_by_ten__ats_mapping_rules',
}

export function AdminATSMappingPage(props) {
  const { employerId } = getParams({ path: '/admin/ats/mapping/:employerId' });
  const [{
    ready = false,
    error = false,
    busy = false,
    employer = {}
  }, _updateState] = useState();
  setTimeout(async () => {
    if (NOT(ready || error || busy)) {
      await _fetchEmployer();
    }
  });
  const data = Arr(Store.get(CONFIG.storageName));
  async function _fetchEmployer() {
    try {
      await _updateState({ busy: true });
      await _updateState({
        employer: await readLoopbackRecord({
          collection: COLLECTION__EMPLOYERS,
          where: { id: employerId },
          fields: ['id', 'name'],
          limit: 1
        }),
        busy: false,
        ready: true
      });
    }
    catch (error) {
      Core.showError(error);
      await _updateState({ error });
    }
  }
  return (
    <Page paper
      showBack={false}
      title={`Edit ATS Status Mapping for {{EMPLOYER_NAME}}`.replace('{{EMPLOYER_NAME}}', employer.name || 'Employer')}
      contentRight={
        <>
          <IconButton
            color={!!data.length ? 'primary' : 'default'}
            onClick={async event => {
              if (!!data.length) {
                await AdminATSMappingPage.AdminATSMappingContent.appendData({ data, overwrite(record) { record.employerId = employerId; } });
                await _updateState();
              }
            }}
          >
            <i className='material-icons c-inherit'>cloud_download</i>
          </IconButton>
          <IconButton
            color={data[0]?.employerId !== employerId ? 'primary' : 'default'}
            onClick={async event => {
              const data = await AdminATSMappingPage.AdminATSMappingContent.getData();
              console.debug('DATA', data);
              if (!!data.length) {
                Store.set(CONFIG.storageName, data);
                Core.showSuccess('Data saved! Now you can a download the data in another table, just remember to refresh first in order of have the latest version of the storage in memory');
              }
              await _updateState();
            }}
          >
            <i className='material-icons c-inherit'>cloud_upload</i>
          </IconButton>
        </>
      }
    >
      <AdminATSMappingContent
        employerId={employerId}
        reference={self => AdminATSMappingPage.AdminATSMappingContent = self}
      />
    </Page>
  )
}
