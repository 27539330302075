import CssBaseline from '@mui/material/CssBaseline';
import {
  Fragment
} from 'react';
import {
  join
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import Core from '../../lib/Core';
import {
  LoadingMessage
} from '../Layout/Wrappers/Message';
import MultipleSelect from '../Layout/Wrappers/MultipleSelect';
import Paper from '../Layout/Wrappers/Paper';
import Table from '../Layout/Wrappers/Table';
import Bookmarks from './Bookmarks';
import ButtonAddNew from './ButtonAddNew';
import ButtonBulkCopy from './ButtonBulkCopy';
import {
  CellActionsColumn
} from './CellActionsColumn';
import {
  CellTagColumn
} from './CellTagColumn';
import ColumnsSettings from './ColumnsSettings';
import {
  FilterActionsColumn
} from './FilterActionsColumn';
import {
  FilterDefaultColumn
} from './FilterDefaultColumn';
import {
  FilterLocationsColumn
} from './FilterLocationsColumn';
import {
  FilterNumberRangeColumn
} from './FilterNumberRangeColumn';
import {
  FilterTagColumn
} from './FilterTagColumn';
import GlobalChipsBar from './GlobalChipsBar';
import {
  IndeterminateCheckbox
} from './IndeterminateCheckbox';
import TabBarOptions from './TabBarOptions';
import TablePaginationActions from './TablePaginationActions';
import ToggleBlacklisted from './ToggleBlacklisted';
import ToggleLooseMatch from './ToggleLooseMatch';
import ToggleResume from './ToggleResume';
import {
  useAutocomplete
} from './useAutocomplete.hook';
import {
  REACT_TABLE__PAGE_SIZES,
  useEnhancedReactTable,
} from './useEnhancedReactTable.hook';
import Box from '../Layout/Wrappers/Box';

export default function ReactTable(context) {
  const enhancedReactTableContext = useEnhancedReactTable(context);

  const {
    // react-i18next
    // t,

    // react-table-hook
    visibleColumns,
    headerGroups,
    rows,
    prepareRow,
    getTableProps,
    getTableBodyProps,

    // enhanced-hook
    loading,
    latestQuery,
    totalCount,
    globalSearchValue,
    setGlobalSearchValue,
    page,
    setPage,
    pageSize,
    setPageSize,
    setSort,
    invertExpanded,
    renderCellClassName,
    ExpandedView,
    disableBodyRender,
  } = enhancedReactTableContext;

  const { suggestions, fetchSuggestions } = useAutocomplete(
    context.config.autocompletePath
  );

  return (
    <>
      <CssBaseline />
      <Paper className="tanstack-resize bg-main p-0 w-100 h-100 scroll">
        <Table
          {...getTableProps()}
          className="h-100 w-100"
        >
          <Table.Head
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}
          >
            {/** GLOBAL SEARCH */}
            <Table.Row
              style={{
                backgroundColor: 'var(--bgMainCommon)',
              }}
            >
              <Table.Cell
                className="w-100"
                style={{ textAlign: 'left', border: 'none' }}
              >
                <Box row
                  style={{
                    position: 'sticky',
                    left: '1rem',
                    width: 'calc(100vw - 3rem)',
                  }}
                >
                  <MultipleSelect
                    name="filter"
                    placeholder={context.config.searchPlaceholder}
                    multiple="tags"
                    data={suggestions}
                    value={globalSearchValue}
                    className="mr-1"
                    style={{
                      minWidth: 320,
                      width: 480,
                    }}
                    onChange={setGlobalSearchValue}
                    onSearch={fetchSuggestions}
                  />
                  <ToggleResume
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <ToggleLooseMatch
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <ToggleBlacklisted
                    enhancedReactTableContext={enhancedReactTableContext}
                    className="mr-1"
                  />
                  <TabBarOptions context={context} />
                  <div className="d-flex flex-align-right-center ml-auto">
                    <div className='mr-1'>
                      {context.config.ActionBar && (<context.config.ActionBar enhancedReactTableContext={enhancedReactTableContext} />)}
                    </div>
                    <ButtonAddNew
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={context.config.addNew && context.config.addNew() ? 'mr-1' : 'd-none'}
                    />
                    <ButtonBulkCopy
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={'mr-1'}
                    />
                    <Bookmarks
                      enhancedReactTableContext={enhancedReactTableContext}
                      className={'mr-1'}
                    />
                    <ColumnsSettings
                      enhancedReactTableContext={enhancedReactTableContext}
                      className="mr-1"
                    />
                  </div>
                </Box>
              </Table.Cell>
            </Table.Row>

            {/* HEADERS */}
            {headerGroups.map((headerGroup) => {
              let _props = headerGroup.getHeaderGroupProps();
              return (
                <Table.Row {..._props} key={_props.key}>
                  {headerGroup.headers.map((column, index) => {
                    let _props = column.getHeaderProps();
                    if (index === headerGroup.headers.length - 1) {
                      _props.style = {
                        ..._props.style,
                        backgroundColor: '#eee',
                        right: 0,
                      };
                    }
                    _props.className = [
                      'th', // this is required for resize; see index.css .tanstack-resize .th
                      _props.className,
                      column._headerClassName,
                      'py-0',
                    ]
                      .filter((v) => !!v)
                      .join(' ');
                    // console.debug('column', column);
                    return (
                      <Table.Cell {..._props} key={_props.key}>
                        <div
                          className={[
                            'd-flex ant-row-space-between',
                            column.id === 'selection' ? 'py-1' : 'px-1',
                            column.canSort && 'hover-bg pointer',
                          ]
                            .filter((v) => !!v)
                            .join(' ')}
                          title={
                            column.canSort
                              ? `Click to sorting${typeof column.Header === 'string' ? ` by ${column.Header}` : ''}`
                              : typeof column.Header === 'string' ? `${column.Header}` : ''
                          }
                          onClick={(event) =>
                            column.canSort && setSort({ columnId: column.id })
                          }
                        >
                          <span className={column.id !== 'selection' ? 'mr-2 truncate' : ''}>
                            {column.render('Header')}
                          </span>
                          <span className="material-icons">
                            {latestQuery?.sort &&
                              (latestQuery.sort[column.id] === 1
                                ? 'arrow_drop_down'
                                : latestQuery.sort[column.id] === -1
                                  ? 'arrow_drop_up'
                                  : '')}
                          </span>
                        </div>

                        {/* Render the columns filter UI */}
                        {column.canFilter
                          ? column.render('Filter', enhancedReactTableContext)
                          : null}

                        {!column.disableResizing && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                              }`}
                          >
                            &nbsp;
                          </div>
                        )}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}

            <Table.Row className='border-bottom'>
              <Table.Cell
                className="w-100 p-0 pt-1 px-1"
                style={{ textAlign: 'left' }}
              >
                <GlobalChipsBar
                  enhancedReactTableContext={enhancedReactTableContext}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Head>

          {!disableBodyRender && (
            <Table.Body {...getTableBodyProps()}>
              <LoadingMessage show={loading} className='m-0 bg-white'>
                Loading data . . .
              </LoadingMessage>
              {NOT(loading) && rows.map((row, i) => {
                // validating model-name for dynamic loading
                if (!context.config.Entity.name) {
                  Core.showWarning(`Entity has not name set`);
                  return null;
                } else if (!row.original.___model___) {
                  Core.showWarning(`Model has not name set`);
                  return null;
                } else if (
                  context.config.Entity.name !== row.original.___model___
                ) {
                  return null;
                }

                prepareRow(row);
                // console.debug('row', row);
                let _props = row.getRowProps();
                // _props.style.border = 'none';
                let { original: model = {}, isExpanded } = row;
                let _isExpanded = invertExpanded ? !isExpanded : isExpanded;
                let _key = _props.key;
                delete _props.key;
                return (
                  <Fragment key={_key}>
                    <Table.Row
                      {..._props}
                      className={join(['border-bottom bg-hover', _props.className], ' ')}
                    >
                      {row.cells.map((cell) => {
                        let _props = cell.getCellProps();
                        _props.style = {
                          ..._props.style,
                        };
                        // _props.className = cell.column._cellClassName;
                        _props.className = renderCellClassName({
                          model,
                          className: [
                            'bg-white',
                            cell.column._cellClassName,
                          ].join(' '),
                        });
                        return (
                          <Table.Cell {..._props} key={_props.key}>
                            {cell.render('Cell', enhancedReactTableContext)}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                    {_isExpanded && ExpandedView && (
                      <Table.Row {..._props}>
                        <Table.Cell
                          className={renderCellClassName({
                            model,
                            className: 'w-100 bg-main',
                          })}
                          style={{
                            padding: '0 0 1rem'
                          }}
                        >
                          <ExpandedView model={model} />
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Fragment>
                );
              })}
            </Table.Body>
          )}

          <Table.Footer className="bg-main w-100 sticky-bottom">
            <Table.Row className="w-100">
              <Table.Pagination
                rowsPerPageOptions={REACT_TABLE__PAGE_SIZES}
                colSpan={visibleColumns.length}
                count={totalCount}
                rowsPerPage={pageSize}
                page={page - 1}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={(event, page) => setPage(page)}
                onRowsPerPageChange={(event) => setPageSize(event.target.value)}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: 'inline-flex',
                  position: 'sticky',
                  left: 0,
                  border: 'none',
                }}
                labelRowsPerPage={<span>Rows per page:</span>}
              />
            </Table.Row>
          </Table.Footer>
        </Table>
      </Paper>
    </>
  );
}

export {
  CellActionsColumn,
  CellTagColumn,
  FilterActionsColumn,
  FilterDefaultColumn,
  FilterLocationsColumn,
  FilterNumberRangeColumn,
  FilterTagColumn,
  IndeterminateCheckbox,
  useAutocomplete,
};
