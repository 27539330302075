import {
  Drawer as MuiDrawer
} from '@mui/material';

/**
 * 
 * @see https://mui.com/material-ui/api/drawer/
 */
export default function Drawer({
  ...props
}) {
  const _props = {
    ...props
  };
  return (
    <MuiDrawer {..._props} />
  );
}
