import { CANDIDATE_UI } from '../../components/Candidates/CandidateUI.dic';

export const TEMPLATE__CANDIDATE_EDIT__MAKE_COVER__BODY = (`
  <html>
    <head></head>
    <body>
      <table>
        <tr>
          <td>
            SUBJECT - {{FIRST_NAME}}{{LAST_NAME}} processing delay
          </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
          <tr>
            <td style="color: red;">
              {{#if NO_JOBS_PERMITTED_LENGTH}}
                You will not have representation for this candidate until the candidate grants permission to be submitted and you add jobs the “${CANDIDATE_UI.jobsPermitted.title}” section
              {{/if}}
            </td>
        </tr>
        <tr>
          <td>
            <span style="color: red; text-decoration: underline;">
              <strong>
                Key Information Missing:&nbsp;
              </strong>
            </span>
            Processing will be delayed due to missing information.&nbsp;
            <a href="{{{URL}}}">Click here to go back to edit the candidate.</a>
          </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        {{{ROWS}}}
      </table>
    </body>
  </html>
`);

