import {
  Fun
} from '../../../../../../../lib/Function.lib';
import {
  joinClassName,
  THEME__COLOR__ERROR,
  THEME__COLOR__SUCCESS
} from '../../../../../../Layout/Libraries/Theme.lib';
import Box from '../../../../../../Layout/Wrappers/Box';
import Chip from '../../../../../../Layout/Wrappers/Chip';
import Icon from '../../../../../../Layout/Wrappers/Icon';
import Typography from '../../../../../../Layout/Wrappers/Typography';

export default function MatchProperty({
  label = '',
  chipLabel = '',
  evalMatch = Fun,
  chipRender = undefined,
  ...props
}) {
  let isMatching = evalMatch();
  let decisionMark = isMatching ? (
    <Icon mr
      role='MatchProperty__Yes'
      icon='done'
      color={THEME__COLOR__SUCCESS}
    />
  ) : (
    <Icon mr
      role='MatchProperty__No'
      icon='close'
      color={THEME__COLOR__ERROR}
    />
  );
  const chipStyle = (
    isMatching
      ? { border: '1px solid green' }
      : { border: '2px solid red' }
  );
  return (
    <Box row w100 noWrap
      role='MatchProperty'
      className='px-1 mb-05'
    >
      <Box row w100
        role='AdditionalYearsOfExperience'
        className={joinClassName([
          'flex-align-left-top py-05 px-1 rounded bg-purple-hover',
          'bg-white-light',
        ])}
      >
        <Box row w100
          style={{ minHeight: 36 }}
        >
          {decisionMark}
          <Typography mr>
            {label}:
          </Typography>
          {chipRender ? chipRender() :
            <Chip outlined
              label={chipLabel}
              style={chipStyle}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}
