import {
  useState
} from "react";
import AppUI from '../../dictionaries/AppUI.dic';
import AccountLib from "../../lib/Account";
import Employer from "../../lib/Employer";
import {
  EMPLOYER__PRIMARY_CONTACT_NOTES
} from "../../lib/models/employer";
import getEmployerAtsProvider from '../../lib/services/ATS/getEmployerAtsProvider.fun';
import {
  EditCandidateRecruiters
} from "../Candidates/Forms/EditCandidateRecruiters";
import InternalSubmissionInstructions from '../Employers/Edit/Forms/InternalSubmissionInstructions';
import EmployerUI from '../Employers/EmployerUI.dic';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import Fieldset from '../Layout/Wrappers/Fieldset';
import Link from '../Layout/Wrappers/Link';
import RichTextBox from "../Layout/Wrappers/RichTextBox";
import Row from "../Layout/Wrappers/Row";
import {
  FeedbackLog
} from './FeedbackLog';
import StreakNotes from './StreakNotes';
import {
  SubmissionNote
} from './SubmissionNotes';

export default function ContactsAndATS({
  engagement,
  employerTodoReminderRecipients
}) {
  const {
    candidate,
    job
  } = engagement;
  const {
    employer
  } = job;
  const {
    account: recruiter
  } = candidate;
  console.debug({
    candidate,
    job,
    recruiter,
    employer,
    employerTodoReminderRecipients
  });
  const [primaryContactNotes, setPrimaryContactNotes] = useState(employer.primaryContactNotes);
  const [privateNotes, setPrivateNotes] = useState(recruiter.privateNotes);
  const empAtsProvider = getEmployerAtsProvider({ employer });
  return (
    <>

      <Fieldset
        title='Employer Contact Notes'
        subtitle='Who did the intake, role (founder, hiring manager, recruiter, coordinator), best way to contact, how firm is SLA, ok to text call'
        actionBar={
          <div className="d-flex flex-align-left-center">
            <Button outlined small
              className="ml-2"
              onClick={ev => {
                setPrimaryContactNotes(`${EMPLOYER__PRIMARY_CONTACT_NOTES}<br/>${primaryContactNotes}`);
                clearTimeout(ContactsAndATS.timeout);
                ContactsAndATS.timeout = setTimeout(() => Employer.update(employer.id, { primaryContactNotes }), 3000);
              }}
            >
              <span className="capitalize">
                Insert template
              </span>
            </Button>
          </div>
        }
      >
        <RichTextBox
          value={primaryContactNotes}
          onChange={(primaryContactNotes) => {
            setPrimaryContactNotes(primaryContactNotes);
            clearTimeout(ContactsAndATS.timeout);
            ContactsAndATS.timeout = setTimeout(() => Employer.update(employer.id, { primaryContactNotes }), 3000);
          }}
        />
      </Fieldset>

      <StreakNotes
        engagement={engagement}
      />

      <InternalSubmissionInstructions
        title={
          <>
            Employer:
            &nbsp;
            {EmployerUI.internalSubmissionInstructions.title}
          </>
        }
        entity={employer}
      />

      <InternalSubmissionInstructions
        title={
          <>
            Job:
            &nbsp;
            {EmployerUI.internalSubmissionInstructions.title}
          </>
        }
        entity={job}
      />

      <Fieldset
        title='Submission Methods'
        subtitle={
          <Box column className='f-md'>
            {employer._submissionMethods.length ? employer._submissionMethods : <span className="c-black-medium f-sm">Empty List</span>}
            {empAtsProvider._url && (<Link href={empAtsProvider._url}>{empAtsProvider._name}</Link>)}
          </Box>
        }
      />

      <FeedbackLog
        title='Engagement feedback'
        engagement={engagement}
        wrapperProps={{ className: '' }}
      />

      <SubmissionNote
        engagement={engagement}
      />

      <Fieldset
        title='Employer Reminder Email'
        subtitle={
          <div className='f-md'>
            <div>
              To: {employerTodoReminderRecipients.to.map(c => c.name).join(', ')}
            </div>
            {!!employerTodoReminderRecipients.cc?.length && (
              <div>
                Cc: {employerTodoReminderRecipients.cc.map(c => c.name).join(', ')}
              </div>
            )}
          </div>
        }
      />

      <Fieldset
        title='Recruiter Info (10x10 Private Note)'
        subtitle='Account > Process Step > Admin Block'
      >
        <RichTextBox
          name="privateNotes"
          placeholder={AppUI.placeholder.multiline}
          value={privateNotes}
          onChange={privateNotes => {
            setPrivateNotes(privateNotes);
            clearTimeout(ContactsAndATS.timeout);
            ContactsAndATS.timeout = setTimeout(() => AccountLib.update(recruiter.id, { privateNotes }), 3000);
          }}
        />
      </Fieldset>

      <Row className='mt-3 px-2'>
        <EditCandidateRecruiters
          recruiter={recruiter}
          candidate={candidate}
        />
      </Row>

    </>
  );
}
