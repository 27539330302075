import Core from "./Core";
import Engagement from "./Engagement";
import Http from "./Http";
import Job from "./Job";
import {
  mapAccounts
} from './models/account';
import getEmployerModel, {
  mapEmployer,
  mapEmployers
} from "./models/employer";
import {
  QUERY__EMPLOYER__SIMPLE_LIST
} from './queries/EmployerSimpleList.query';
import getStateModel from "./tools/getStateModel";

const cache = {};

const commonQuery = {
  include: [
    { jobs: "employer" },
    {
      relation: "employerStarreds",
      scope: {
        where: { accountId: Core.getUserId() }
      }
    }
  ]
};

const menus = [
  { label: "Roles", key: "roles", field: "_jobRolesKeys", multiple: true },
  { label: "Location", key: "location", field: "_location" },
  {
    label: "Technology",
    key: "technicalSkills",
    field: "_technicalSkillsKeys",
    multiple: true
  },
  { label: "Visa", key: "visa", field: "_visaTransfer" },
  { label: "Stage", key: "stage", field: "_stage" }
];
const more = [{ label: "State", key: "state" }];
const listTabs = ["Name", "Recent", "Starred"];
const listTab = "Name";

export async function getEmployerPendings({ employerId }) {
  return Http.get(
    Core.getApi('Employers/_pendings'),
    { employerId }
  );
}

export async function getEmployerAccounts({ employerId }) {
  let accounts = [];
  let accountEmployerIds = (
    await Http.get(
      Core.getApi(`AccountEmployers`),
      { filter: JSON.stringify({ where: { employerId } }) }
    ) || []
  ).map(({ accountId }) => accountId);
  if (!!accountEmployerIds.length) {
    accounts = await Http.get(
      Core.getApi(`Accounts`),
      { filter: JSON.stringify({ where: { id: { inq: accountEmployerIds } }, fields: ['id', 'firstName', 'lastName', 'email', 'phone'], order: 'email ASC' }) }
    ).then(mapAccounts);
  }
  return Array.isArray(accounts) ? accounts : [];
}

export async function getEmployersList() {
  return Http.get(Core.getApi("Employers"), {
    filter: JSON.stringify(QUERY__EMPLOYER__SIMPLE_LIST)
  });
}

const Employer = {
  name: 'Employer',
  menus,
  more,
  listTabs,
  listTab,
  columns: [
    {
      headers: [
        {
          label: "Employer",
          key: "_infoCmp",
          sortKey: "_name",
          filter: true
        },
        { label: "Flags", key: "_flags" }
      ],
      selected: 0,
      style: { minWidth: 256 }
    },
    {
      headers: [
        { label: "Stage", key: "_stage" },
        { label: "Employees", key: "_employeeCount", sortKey: "employeeCount" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "City", key: "addressCity" },
        { label: "State", key: "_state" }
      ],
      selected: 0
    },
    {
      headers: [
        { label: "Active Jobs", key: "_active", sortKey: "_activeNum" },
        { label: "Jobs Count", key: "_jobsLength", sortKey: "_jobsLengthNum" }
      ],
      selected: 0
    },
    {
      headers: [
        {
          label: "Starred",
          key: "_rowOptionsCmp",
          sortKey: "_starred",
          reverseSort: true,
          hint: "Options"
        }
      ],
      selected: 0,
      style: { width: 124, textAlign: "right" }
    }
  ],
  cleanCache: em =>
    Object.keys(cache).forEach(key => {
      delete cache[key];
    }),
  getActives: success => {
    success = success instanceof Function ? success : function () { };
    if (cache.actives) {
      setTimeout(st => success(cache.actives));
    } else {
      Http.get(
        Core.getApi("Employers"),
        {
          filter: JSON.stringify({ ...commonQuery, where: { state: 1 } })
        },
        function onSuccess(response) {
          cache.actives = mapEmployers(response);
          success(cache.actives);
        }
      );
    }
  },
  getAll: cb => {
    cb = cb instanceof Function ? cb : function () { };
    if (cache.all) {
      setTimeout(st => cb(cache.all));
    } else {
      Http.get(
        Core.getApi("Employers"),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache.all = mapEmployers(response);
          cb(cache.all);
        }
      );
    }
  },
  getAnonName: cb => {
    cb = cb instanceof Function ? cb : function () { };
    Http.get(
      Core.getApi("Employers"),
      {
        filter: JSON.stringify({
          fields: { proxyName: 1 }
        })
      },
      function onSuccess(response) {
        let bigger = 0;
        response.forEach(r => {
          const num = Number(String(r.proxyName).replace(/\D/g, ""));
          bigger = num > bigger ? num : bigger;
        });
        bigger++;
        bigger += "";
        while (bigger.length < 4) {
          bigger = "0" + bigger;
        }
        bigger = "_EMP-" + bigger;
        // Core.log({ response, bigger });
        cb(bigger);
      }
    );
  },
  getWhere: async (where, cb) => {
    cb = cb instanceof Function ? cb : function () { };
    const key = JSON.stringify(where).replace(/\W/g, "");
    if (cache[key]) {
      setTimeout(st => cb(cache[key]));
    } else {
      return Http.get(
        Core.getApi("Employers"),
        {
          filter: JSON.stringify({ ...commonQuery, where: { ...where } })
        },
        function onSuccess(response) {
          cache[key] = mapEmployers(response);
          cb(cache[key]);
        }
      ).then(mapEmployers);
    }
  },
  get: async (employerId, cb) => {
    cb = cb instanceof Function ? cb : function () { };
    if (cache[employerId]) {
      setTimeout(st => cb(cache[employerId]));
      return cache[employerId];
    } else {
      return Http.get(
        Core.getApi("Employers/" + employerId),
        {
          filter: JSON.stringify({ ...commonQuery })
        },
        function onSuccess(response) {
          cache[employerId] = mapEmployer(response);
          cb(cache[employerId]);
        }
      ).then(mapEmployer);
    }
  },
  getPendings: getEmployerPendings,
  post: (employer, success) => {
    Employer.cleanCache();
    Job.cleanCache();
    Engagement.cleanCache();
    Http.post(
      Core.getApi("Employers"),
      getStateModel(employer, getEmployerModel()),
      success
    );
  },
  update: (employerId, employer, success) => {
    Employer.cleanCache();
    Job.cleanCache();
    Engagement.cleanCache();
    return Http.patch(
      Core.getApi("Employers/" + employerId),
      getStateModel(employer, getEmployerModel()),
      response => {
        delete cache[employerId];
        success instanceof Function && success(response);
      }
    );
  },
  getList: success => {
    Http.get(Core.getApi("Employers"), success);
  },
  updateStarred: (employerId, starredId, starred, success) => {
    Employer.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi("EmployerStarred/" + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi("EmployerStarred"),
        {
          // ACTOR
          accountId: Core.getUserId(),

          // ENTITY
          employerId,

          // STARRED FLAG (boolean)
          starred,
        },
        success
      );
    }
  },
  getBlackList: (id, success) => {
    if (Core.getUserRole() === "SysAdmin") {
      Http.get(Core.getApi(`Employers/${id}/employerBlackList`), success);
    } else {
      success([]);
    }
  },
  getWhiteList: (id, success) => {
    if (Core.getUserRole() === "SysAdmin") {
      Http.get(Core.getApi(`Employers/${id}/recruiterWhiteList`), success);
    } else {
      success([]);
    }
  },
  delete: (employerId, success) => {
    Employer.cleanCache();
    Job.cleanCache();
    Engagement.cleanCache();
    Job.getWhere({ employerId }, response => {
      if (!!response.length) {
        response.forEach(job => {
          Job.delete(job.id, () => {
            Http.delete(Core.getApi("Employers/" + employerId), success);
          });
        });
      } else {
        Http.delete(Core.getApi("Employers/" + employerId), success);
      }
    }, { fields: ['id'] });
  }
};

export default Employer;

export const EMPLOYER__SCHEDULING__EMPLOYER_LINK = 'Employer - link';
export const EMPLOYER__SCHEDULING__EMPLOYER_MANUAL = 'Employer - manual';
export const EMPLOYER__SCHEDULING__TEN_BY_TEN = '10by10';
