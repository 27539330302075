import {
  useState
} from 'react';
import Button from '../Layout/Wrappers/Button';
import Dialog from '../Layout/Wrappers/Dialog';

export default function ReactDialog({
  name = '',
  reference = () => null,
  title = 'React dialog',
  children,
  onAcceptLabel = 'Confirm',
  onCancelLabel = 'Cancel',
  leftSide = (<></>),
  actionBar = (<></>),
  actionOptions = [],
  displayActions = true,
  onAccept = async () => null,
  onCancel = async () => null,
  onOpen = async () => null,
  onClose = async () => null,
  paperStyle = {},
  ...props
}) {
  let [state, setState] = useState({});
  let { open = false } = state;
  async function _updateState(update, delay) {
    update = Object(update) === update ? update : {};
    state = { ...state, ...update };
    delay ? setTimeout(() => setState(state), delay) : setState(state);
    return await new Promise((resolve) => setTimeout(() => resolve(state), delay));
  }
  async function _onAccept() {
    console.debug('onAccept', name);
    await _updateState({ open: false });
    return onAccept();
  }
  async function _onCancel() {
    console.debug('onCancel', name);
    await _updateState({ open: false });
    return onCancel();
  }
  let dialogId = `react-dialog-${name}`;
  ReactDialog[name] = {
    name,
    async open() {
      console.debug('open', name);
      await _updateState({ open: true });
      return onOpen();
    },
    async close() {
      console.debug('close', name);
      await _updateState({ open: false });
      return onClose();
    }
  };
  reference(ReactDialog[name]);
  return (
    <Dialog
      {...props}
      onClose={ReactDialog[name].close}
      open={open}
      name={dialogId}
      title={title}
      paperStyle={paperStyle}
      actionBar={
        <>
          {!!leftSide && (
            <div className='d-flex flex-align-left-center mr-auto pl-1'>
              {leftSide}
            </div>
          )}
          {!!actionBar && (
            <div className='d-flex flex-align-left-center'>
              {actionBar}
            </div>
          )}
        </>
      }
      actions={displayActions && !!actionOptions.length ? (
        actionOptions.map((o, index) => {
          return (
            <Button
              variant={o.variant || 'outlined'}
              color={o.color || 'secondary'}
              onClick={event => {
                (o.onClick instanceof Function) && o.onClick();
                _onCancel();
              }}
              style={{ minWidth: 120 }}
              {...(o.buttonProps || {})}
            >
              {o.label || ''}
            </Button>
          );
        })
      ) : (
        [
          <Button outlined minW120
            onClick={_onCancel}
          >
            {onCancelLabel}
          </Button>,
          <Button primary minW120
            onClick={_onAccept}
          >
            {onAcceptLabel}
          </Button>
        ]
      )}
    >
      {children}
    </Dialog >
  );
}
