import {
  REJECTION_REASON__EMPLOYER__COMPANY_PEDIGREE,
  REJECTION_REASON__EMPLOYER__COMPANY_SIZE,
  REJECTION_REASON__EMPLOYER__EDUCATION_CREDENTIALS,
  REJECTION_REASON__EMPLOYER__JOB_HOPPER,
  REJECTION_REASON__EMPLOYER__LEVEL_SENIORITY,
  REJECTION_REASON__EMPLOYER__PROJECT_COMPLEXITY,
  REJECTION_REASON__EMPLOYER__TECHNICAL
} from '../../../dictionaries/Engagement.dic';
import { Str } from '../../../lib/String.lib';

// Rejection Reason (UI Labels) !== (Streak Labels).
// Look for mapRejectionReasonToStreakValue.
// Related to Employer Pendings page.
const _REJECTION_REASON__COMPANY_PEDIGREE = 'Company pedigree';
const _REJECTION_REASON__COMPANY_SIZE = 'Company size';
const _REJECTION_REASON__EDUCATION = 'Education credentials';
const _REJECTION_REASON__JOB_HOPPER = 'Job hopper consulting';
const _REJECTION_REASON__LEVEL_SENIORITY = 'Level seniority';
const _REJECTION_REASON__PROJECT_COMPLEXITY = 'Project complexity';
const _REJECTION_REASON__TECHNICAL = 'Tech stack';

// UI Chips for Rejection Reasons.
// Matrix of items for multiline groups
const REJECTION_REASON_PRESETS = [
  [
    _REJECTION_REASON__PROJECT_COMPLEXITY,
    _REJECTION_REASON__TECHNICAL,
    _REJECTION_REASON__COMPANY_PEDIGREE,
    _REJECTION_REASON__COMPANY_SIZE,
    _REJECTION_REASON__EDUCATION,
    _REJECTION_REASON__LEVEL_SENIORITY,
    _REJECTION_REASON__JOB_HOPPER
  ]
];

export function getRejectionReasonPresets() {
  return JSON.parse(JSON.stringify(REJECTION_REASON_PRESETS));
}

export function mapRejectionReasonTooltip({ value }) {
  return Str(
    {
      [_REJECTION_REASON__PROJECT_COMPLEXITY]: 'Scope and complexity insufficient',
      [_REJECTION_REASON__TECHNICAL]: 'Lacks required tech or industry domain',
      [_REJECTION_REASON__COMPANY_PEDIGREE]: 'Hiring bar reputation',
      [_REJECTION_REASON__COMPANY_SIZE]: 'Company size or funding stage',
      [_REJECTION_REASON__EDUCATION]: 'School reputation or degree lacking',
      [_REJECTION_REASON__LEVEL_SENIORITY]: 'Too senior/junior or title mismatch',
      [_REJECTION_REASON__JOB_HOPPER]: 'Job hopper or consulting background',
    }[value]
  );
}

export function mapRejectionReasonToStreakValue({ value }) {
  return {
    [_REJECTION_REASON__PROJECT_COMPLEXITY]: REJECTION_REASON__EMPLOYER__PROJECT_COMPLEXITY,
    [_REJECTION_REASON__TECHNICAL]: REJECTION_REASON__EMPLOYER__TECHNICAL,
    [_REJECTION_REASON__COMPANY_PEDIGREE]: REJECTION_REASON__EMPLOYER__COMPANY_PEDIGREE,
    [_REJECTION_REASON__COMPANY_SIZE]: REJECTION_REASON__EMPLOYER__COMPANY_SIZE,
    [_REJECTION_REASON__EDUCATION]: REJECTION_REASON__EMPLOYER__EDUCATION_CREDENTIALS,
    [_REJECTION_REASON__LEVEL_SENIORITY]: REJECTION_REASON__EMPLOYER__LEVEL_SENIORITY,
    [_REJECTION_REASON__JOB_HOPPER]: REJECTION_REASON__EMPLOYER__JOB_HOPPER
  }[value];
}
