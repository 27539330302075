import {
  forwardRef,
  useRef
} from 'react';
import ReactDraggable from 'react-draggable';
import {
  mapWrapper
} from '../Libraries/Theme.lib';
import Paper from './Paper';

const Draggable = (
  {
    dialogId,
    wrapperProps = {},
    ...props
  },
  ref
) => {
  const nodeRef = useRef(null);
  return (
    <ReactDraggable
      {...mapWrapper({
        role: 'Draggable',
        props: wrapperProps,
        assign: {
          handle: `#${dialogId}`,
          cancel: '[class*="MuiDialogContent-root"]',
          nodeRef,
        }
      })}
      ref={ref}
    >
      <Paper {...props} ref={nodeRef} />
    </ReactDraggable>
  );
}
export default forwardRef(Draggable);
