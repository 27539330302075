import React from "react";
import loader from "../../assets/images/loader.gif";
import Core from "../../lib/Core";
import Button from '../Layout/Wrappers/Button';

/**
 * 
 * @param {object} props 
 * @param {string} props.label
 * @param {promise} props.onClick 
 * @returns 
 */
function AsyncActionButton(props) {
  const {
    label = "Async Action",
    onClick: onClickPromise = async (event) => { },
    disabled
  } = props;
  const _props = { ...props };
  ['onClick', 'disabled'].forEach(n => (delete _props[n]));
  const [busy, setBusy] = React.useState(false);
  async function onClickButton(event) {
    setBusy(true);
    try { await onClickPromise(event); }
    catch (ex) {
      Core.showError(`Unexpected failure`);
      console.error(ex);
    }
    setBusy(false);
  }
  const _label = (
    busy
      ? <>Wait... <img alt="loading..." height="14" src={loader} /></>
      : label
  );
  const _disabled = (
    disabled || busy
  );
  return (
    <Button
      className="center"
      primary
      fullWidth
      {..._props}
      label={_label}
      disabled={_disabled}
      onClick={onClickButton}
    />
  );
}

export {
  AsyncActionButton, AsyncActionButton as default
};

